import React from 'react';

const ImageAddAlt = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 24}
    height={height || 24}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path fill={color} d="M20 8h-2V6h-2V4h2V2h2v2h2v2h-2v2z"></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M14 4H2v18h18V10h-2v8.197l-3.845-5.767-4.282 4.283-3.105-2.07L4 18.333V6h10V4zm-.155 11.57L16.798 20H5.25l1.982-2.643 2.895 1.93 3.718-3.717z"
      clipRule="evenodd"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M11 10a3 3 0 11-6 0 3 3 0 016 0zm-2 0a1 1 0 11-2 0 1 1 0 012 0z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default ImageAddAlt;
