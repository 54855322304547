import { useEffect, useState, useCallback } from 'react';
import * as React from 'react';
import { AxiosInstance } from 'axios';
import { Icons } from '@density/dust';
import { CoreSpace } from '@densityco/lib-api-types';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { toast } from 'react-toastify';

import { Action } from './actions';
import { State } from './state';
import styles from './styles.module.scss';

import Button from 'components/button';
import SelectField from 'components/select-field';
import { DateAndTimeTextField } from 'components/date-text-field';
import TextField from 'components/text-field';
import Tooltip from 'components/tooltip';
import Popup from 'components/popup';
import { LengthUnit } from 'lib/units';
import { CoreAPI, FloorplanV2Plan } from 'lib/api';
import { Analytics } from 'lib/analytics';

import { FixMe } from 'types/fixme';

type UnitChoice = { id: LengthUnit; label: string };
const UNIT_CHOICES: Array<UnitChoice> = [
  { id: 'feet_and_inches', label: 'ft & in' },
  { id: 'inches', label: 'in' },
  { id: 'meters', label: 'm' },
  { id: 'centimeters', label: 'cm' },
  { id: 'millimeters', label: 'mm' },
];

const SettingsPanel: React.FunctionComponent<{
  state: State;
  plan: FloorplanV2Plan;
  client: AxiosInstance;
  editStatusLoading: boolean;
  onEditStatus: (status: CoreSpace['status']) => Promise<void>;
  dispatch: React.Dispatch<Action>;
}> = ({ state, plan, client, editStatusLoading, onEditStatus, dispatch }) => {
  const [panelOpen, setPanelOpen] = useState<boolean>(false);

  const [floorReloadCounter, setFloorReloadCounter] = useState(0);
  const reloadFloor = useCallback(
    () => setFloorReloadCounter((n) => n + 1),
    [setFloorReloadCounter]
  );

  // Load the full data for the currently focused space (of type floor) when opening the settings
  // panel.
  const [floor, setFloor] = useState<
    | { status: 'pending' }
    | { status: 'loading' }
    | {
        status: 'complete';
        data: CoreSpace & { go_live_date_utc: string | null };
      }
    | {
        status: 'complete-and-updating';
        data: CoreSpace & { go_live_date_utc: string | null };
      }
    | { status: 'error' }
  >({ status: 'pending' as const });
  useEffect(() => {
    if (!panelOpen) {
      setFloor({ status: 'pending' as const });
      return;
    }

    const abortController = new AbortController();
    setFloor({ status: 'loading' as const });
    CoreAPI.getSpace(client, plan.floor.id, abortController.signal)
      .then((response) => {
        setFloor({ status: 'complete' as const, data: response.data });
      })
      .catch((err) => {
        if ((err as FixMe).name === 'CanceledError') {
          return;
        }
        setFloor({ status: 'error' as const });
      });

    return () => {
      abortController.abort();
      setFloor({ status: 'pending' as const });
    };
  }, [panelOpen, client, floorReloadCounter, plan.floor.id]);

  const [goLiveDateUTCValue, setGoLiveDateUTCValue] = useState<string | null>(
    null
  );
  useEffect(() => {
    if (
      floor.status === 'complete' ||
      floor.status === 'complete-and-updating'
    ) {
      setGoLiveDateUTCValue(floor.data.go_live_date_utc);
    } else {
      setGoLiveDateUTCValue(null);
    }
  }, [floor]);

  // When the go live date is changed, propagate that to the core api
  const updateFloorGoLiveDateUTC = useCallback(
    (newGoLiveDateUTC: string | null) => {
      setFloor((floor) => {
        if (floor.status !== 'complete') {
          return { status: 'loading' as const };
        }

        return {
          status: 'complete-and-updating' as const,
          data: floor.data,
        };
      });

      CoreAPI.updateSpace(client, plan.floor.id, {
        go_live_date_utc: newGoLiveDateUTC,
      })
        .then((response) => {
          setFloor({
            status: 'complete' as const,
            data: response.data,
          });

          toast.success('Updated plan live date!');
        })
        .catch(() => {
          setFloor((floor) => {
            if (floor.status !== 'complete-and-updating') {
              return { status: 'error' as const };
            }

            return {
              status: 'complete' as const,
              data: floor.data,
            };
          });
          toast.error('Error updating plan live date!');
        });
    },
    [client, plan.floor.id]
  );

  return (
    <div className={styles.settingsPanel}>
      <Popup
        open={panelOpen}
        onClose={() => setPanelOpen(false)}
        position={{ right: 0, top: 36 }}
        popupWidth={232}
        target={
          <Tooltip
            contents="Plan settings"
            placement="bottom"
            target={
              <Button
                onClick={() => setPanelOpen(!panelOpen)}
                trailingIcon={<Icons.CogGearSettings size={18} />}
                size="medium"
                type="outlined"
                data-cy="settings-panel-button"
              />
            }
          />
        }
      >
        <div className={styles.settingsMenuSection}>
          <div className={styles.settingsMenuRow}>
            <div className={styles.settingsMenuRowLabel}>
              <div className={styles.settingsMenuRowLabelIcon}>
                <Icons.RulerVertical size={16} />
              </div>
              <div className={styles.settingsMenuRowLabelText}>Units</div>
            </div>
            <div className={styles.settingsMenuRowAction}>
              <SelectField
                size="small"
                value={state.displayUnit}
                onChange={(choice) => {
                  dispatch({
                    type: 'settingsPanel.setDisplayUnit',
                    displayUnit: choice.id,
                  });
                }}
                data-cy="settings-panel-unit-select"
                choices={UNIT_CHOICES}
              />
            </div>
          </div>
          <div className={styles.settingsMenuRowHeader}>Space</div>
          <div className={styles.settingsMenuRow}>
            <div className={styles.settingsMenuRowLabel}>
              <div className={styles.settingsMenuRowLabelText}>Status</div>
            </div>
            <div className={styles.settingsMenuRowAction}>
              <SelectField
                size="small"
                value={plan.floor.status}
                width={108}
                disabled={state.locked || editStatusLoading}
                leadingIcon={
                  <div
                    style={{
                      width: dust.Space3,
                      height: dust.Space3,
                      borderRadius: '50%',
                      backgroundColor:
                        plan.floor.status === 'planning'
                          ? dust.Yellow400
                          : dust.Green400,
                      marginLeft: dust.Space2,
                    }}
                  />
                }
                onChange={(choice) =>
                  onEditStatus(choice.id).then(() => reloadFloor())
                }
                choices={[
                  { id: 'planning', label: 'Planning' },
                  { id: 'live', label: 'Live' },
                ]}
                data-cy="settings-panel-status"
              />
            </div>
          </div>
          {plan.floor.status === 'live' ? (
            <div className={styles.settingsMenuRow}>
              <div className={styles.settingsMenuRowAction}>
                {floor.status === 'complete' ||
                floor.status === 'complete-and-updating' ? (
                  <DateAndTimeTextField
                    value={goLiveDateUTCValue}
                    onChange={updateFloorGoLiveDateUTC}
                    disabled={
                      state.locked ||
                      editStatusLoading ||
                      floor.status === 'complete-and-updating'
                    }
                    size="small"
                    width="100%"
                    placeholder="eg, 10/05/22 01:00:00 pm"
                    leadingPrefix="Live at:"
                    data-cy="settings-panel-go-live-date"
                  />
                ) : (
                  <TextField
                    error={floor.status === 'error'}
                    placeholder={
                      floor.status === 'error'
                        ? 'Error loading floor!'
                        : 'Loading floor...'
                    }
                    disabled
                    size="small"
                    width="100%"
                  />
                )}
              </div>
            </div>
          ) : null}
          <div className={styles.settingsMenuRow}>
            <div className={styles.settingsMenuRowLabel}>
              <div className={styles.settingsMenuRowLabelText}>Floor ID</div>
            </div>
            <div className={styles.settingsMenuRowAction}>
              <div className={styles.settingsMenuRowSpaceId}>
                {plan.floor.id}
                <Button
                  size="nano"
                  type="cleared"
                  trailingIcon={<Icons.CopyDuplicate size={14} />}
                  onClick={() => {
                    Analytics.track('Copy Space ID', {
                      spaceType: 'floor',
                      location: 'editor',
                      spaceId: plan.floor.id,
                    });
                    navigator.clipboard.writeText(plan.floor.id);
                    toast.success('Copied to clipboard.');
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.settingsMenuRow}>
            <div className={styles.settingsMenuRowLabel}>
              <div className={styles.settingsMenuRowLabelText}>Building ID</div>
            </div>
            <div className={styles.settingsMenuRowAction}>
              <div className={styles.settingsMenuRowSpaceId}>
                {plan.floor.parent_id}
                <Button
                  size="nano"
                  type="cleared"
                  trailingIcon={<Icons.CopyDuplicate size={14} />}
                  onClick={() => {
                    Analytics.track('Copy Space ID', {
                      spaceType: 'building',
                      location: 'editor',
                      spaceId: plan.floor.id,
                    });
                    navigator.clipboard.writeText(plan.floor.parent_id);
                    toast.success('Copied to clipboard.');
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default SettingsPanel;
