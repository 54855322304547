import { ImageCoordinates } from 'lib/geometry';
import { FloorplanV2Plan } from 'lib/api';

// A measurement represents a line on a floorplan used to scale it properly.
//
// Measurements have two 2d points with units of pixels on an image, plus metadata about the
// distance between those points in meters. This information can be used to compute a pixels per
// meter scale for the image.
type Measurement = {
  pointA: ImageCoordinates;
  pointB: ImageCoordinates;
  userEnteredLength: {
    feetText: string;
    inchesText: string;
  };
  // meters
  computedLength: number;
  // pixels-per-meter
  computedScale: number;
};

const Measurement = {
  fromFloorplanAPIResponse: (plan: FloorplanV2Plan): Measurement => ({
    pointA: ImageCoordinates.create(
      plan.measurement_point_a_x_pixels,
      plan.measurement_point_a_y_pixels
    ),
    pointB: ImageCoordinates.create(
      plan.measurement_point_b_x_pixels,
      plan.measurement_point_b_y_pixels
    ),
    userEnteredLength: {
      feetText: plan.measurement_user_entered_length_feet,
      inchesText: plan.measurement_user_entered_length_inches,
    },
    computedLength: plan.measurement_computed_length_meters, // meters
    computedScale: plan.image_pixels_per_meter, // pixels-per-meter
  }),
  toFloorplanScaleUpdate: (
    measurement: Measurement
  ): Pick<
    FloorplanV2Plan,
    | 'image_pixels_per_meter'
    | 'measurement_point_a_x_pixels'
    | 'measurement_point_a_y_pixels'
    | 'measurement_point_b_x_pixels'
    | 'measurement_point_b_y_pixels'
    | 'measurement_computed_length_meters'
    | 'measurement_user_entered_length_feet'
    | 'measurement_user_entered_length_inches'
  > => ({
    measurement_point_a_x_pixels: measurement.pointA.x,
    measurement_point_a_y_pixels: measurement.pointA.y,
    measurement_point_b_x_pixels: measurement.pointB.x,
    measurement_point_b_y_pixels: measurement.pointB.y,
    measurement_computed_length_meters: measurement.computedLength,
    measurement_user_entered_length_feet:
      measurement.userEnteredLength.feetText,
    measurement_user_entered_length_inches:
      measurement.userEnteredLength.inchesText,
    image_pixels_per_meter: measurement.computedScale,
  }),
};

export default Measurement;
