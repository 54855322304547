import React from "react";

const CloudSecure = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M9,13 L9,12 C9,10.3431458 10.3431458,9 12,9 C13.6568542,9 15,10.3431458 15,12 L15,13 C16.1045695,13 17,13.8954305 17,15 C18.6568542,15 20,13.6568542 20,12 C20,10.6616988 19.1153345,9.49550917 17.8526202,9.12234584 L16.958774,8.85819214 L16.5861853,8.00384098 C15.7970403,6.19432012 14.0082974,5 12,5 C9.47509154,5 7.35388706,6.88316352 7.03994532,9.3632558 L6.86673822,10.7315657 L5.52620502,11.0559784 C4.63845202,11.270817 4,12.0718094 4,13 C4,14.1045695 4.8954305,15 6,15 L7,15 C7,13.8954305 7.8954305,13 9,13 Z M11,13 L13,13 L13,12 C13,11.4477153 12.5522847,11 12,11 C11.4477153,11 11,11.4477153 11,12 L11,13 Z M15,19 L15,15 L9,15 L9,19 L15,19 Z M7,17 L6,17 C3.790861,17 2,15.209139 2,13 C2,11.1161296 3.30232042,9.53643238 5.05577898,9.1120907 C5.4920874,5.66532009 8.43478427,3 12,3 C14.8721287,3 17.3402507,4.7297588 18.4194364,7.20434702 C20.4894782,7.81609565 22,9.7316472 22,12 C22,14.7614237 19.7614237,17 17,17 L17,19 C17,20.1045695 16.1045695,21 15,21 L9,21 C7.8954305,21 7,20.1045695 7,19 L7,17 Z M15,15 L9,15 L15,15 Z M11,16 L13,16 L13,18 L11,18 L11,16 Z"
      />
    </g>
  </svg>
);

export default CloudSecure;
