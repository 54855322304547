// split.io split names
export const SPLITS = {
  FF_OVERRIDES: 'web_oa_ff_overrides',
  DWELL: 'web_oa_dwell',
  ELLIPTICAL_OA_COVERAGE: 'web_oa_elliptical_oa_coverage',
  HEIGHT_MAP: 'web_oa_height_maps',
  AUTOLAYOUT: 'web_oa_autolayout',
  SENSOR_COVERAGE: 'web_oa_sensor_coverage_simulation',
  AREAS_OF_CONCERN: 'web_planner_areas_of_concern',
  WALLS: 'web_planner_walls',
  WALLS_STEP_IN_CREATION_FLOW: 'web_planner_walls_step_in_creation_flow',
  PROJECT_SHARING: 'web_planner_project_sharing',
  LIST_PAGE_DELETE: 'web_planner_list_page_delete',
  SWAP_IMAGE_REGISTRATION: 'web_planner_swap_image_registration',
  FLIP_RENDER_ORDER: 'web_planner_flip_render_order',
  GLOBAL_POINTS: 'web_planner_global_points',
  SPACE_SNAPPING: 'web_planner_space_snapping',
  VALIDATION: 'web_planner_validation',
  MAPS_IMPORT: 'web_planner_maps_import',
  HEATMAP: 'web_planner_heatmap',
  COMBINED_EXPORT_POPUP: 'web_planner_combined_export_popup',
  HIDE_INDIVIDUAL_SENSOR_STREAMING:
    'web_planner_hide_individual_sensor_streaming',
  DISABLE_AUTO_SAVING: 'web_planner_disable_auto_saving',
} as const;

// features to override in split.io localhost mode
export const LOCAL_FEATURES = {
  [SPLITS.FF_OVERRIDES]: 'on',
  [SPLITS.ELLIPTICAL_OA_COVERAGE]: 'on',
  [SPLITS.HEIGHT_MAP]: 'on',
  [SPLITS.AUTOLAYOUT]: 'on',
  [SPLITS.SENSOR_COVERAGE]: 'on',
  [SPLITS.AREAS_OF_CONCERN]: 'off',
  [SPLITS.WALLS]: 'on',
  [SPLITS.WALLS_STEP_IN_CREATION_FLOW]: 'on',
  [SPLITS.PROJECT_SHARING]: 'off',
  [SPLITS.LIST_PAGE_DELETE]: 'off',
  [SPLITS.SWAP_IMAGE_REGISTRATION]: 'on',
  [SPLITS.FLIP_RENDER_ORDER]: 'on',
  [SPLITS.GLOBAL_POINTS]: 'off',
  [SPLITS.SPACE_SNAPPING]: 'off',
  [SPLITS.VALIDATION]: 'off',
  [SPLITS.MAPS_IMPORT]: 'off',
  [SPLITS.HEATMAP]: 'off',
  [SPLITS.HIDE_INDIVIDUAL_SENSOR_STREAMING]: 'off',
  [SPLITS.COMBINED_EXPORT_POPUP]: 'on',
  [SPLITS.DISABLE_AUTO_SAVING]: 'off',
};
