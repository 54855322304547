import React from "react";

const Money = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 Z M12.975,5 L12.975,6.62 C13.8750045,6.79000085 14.6049972,7.1649971 15.165,7.745 C15.7250028,8.3250029 16.0149999,9.01999595 16.035,9.83 L13.83,9.83 C13.7799997,9.37999775 13.5900016,9.01750138 13.26,8.7425 C12.9299983,8.46749863 12.4950027,8.33 11.955,8.33 C11.4549975,8.33 11.0550015,8.43499895 10.755,8.645 C10.4549985,8.85500105 10.305,9.15499805 10.305,9.545 C10.305,9.8250014 10.3949991,10.0424992 10.575,10.1975 C10.7550009,10.3525008 11.0099983,10.4774995 11.34,10.5725 C11.6700016,10.6675005 12.1799965,10.7799994 12.87,10.91 C13.5700035,11.0400007 14.1599976,11.202499 14.64,11.3975 C15.1200024,11.592501 15.5074985,11.887498 15.8025,12.2825 C16.0975015,12.677502 16.245,13.2049967 16.245,13.865 C16.245,14.7150043 15.942503,15.439997 15.3375,16.04 C14.732497,16.640003 13.9450048,17.0099993 12.975,17.15 L12.975,18.725 L11.115,18.725 L11.115,17.12 C10.124995,16.9499992 9.32750302,16.5525031 8.7225,15.9275 C8.11749697,15.3024969 7.81000005,14.5500044 7.8,13.67 L10.005,13.7 C10.0450002,14.2200026 10.2574981,14.6374984 10.6425,14.9525 C11.0275019,15.2675016 11.5399968,15.425 12.18,15.425 C12.7100026,15.425 13.1424983,15.3125011 13.4775,15.0875 C13.8125017,14.8624989 13.98,14.550002 13.98,14.15 C13.98,13.8699986 13.8875009,13.6475008 13.7025,13.4825 C13.5174991,13.3174992 13.2575017,13.1875005 12.9225,13.0925 C12.5874983,12.9974995 12.0700035,12.8800007 11.37,12.74 C10.6799965,12.6099994 10.1000023,12.442501 9.63,12.2375 C9.15999765,12.032499 8.77750147,11.727502 8.4825,11.3225 C8.18749852,10.917498 8.04,10.3800034 8.04,9.71 C8.04,8.909996 8.32249717,8.22500285 8.8875,7.655 C9.45250282,7.08499715 10.1949954,6.7300007 11.115,6.59 L11.115,5 L12.975,5 Z"
      />
    </g>
  </svg>
);

export default Money;
