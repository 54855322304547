import React from "react";

const Keyhole = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M12.3534058,6.90068574 L21.5,5.31123873 L21.5,17.6883137 L12.3503914,16.1018399 C11.2831735,16.9960046 9.93050982,17.5 8.5,17.5 C6.89126121,17.5 5.38087332,16.8626097 4.264124,15.7493947 C3.14271906,14.6315387 2.5,13.1154232 2.5,11.5 C2.5,9.88710666 3.14069962,8.37315824 4.25900281,7.25571645 C5.37617559,6.1394042 6.88873163,5.5 8.5,5.5 C9.93189,5.5 11.2857572,6.00497241 12.3534058,6.90068574 Z M19.5,7.68876127 L11.6999297,9.04421643 L11.3328204,8.67597291 C10.5865034,7.9273502 9.57836198,7.5 8.5,7.5 C7.42434538,7.5 6.41853249,7.9251882 5.67267139,8.67047478 C4.92605176,9.41651931 4.5,10.4232631 4.5,11.5 C4.5,12.5784218 4.927398,13.5866145 5.67609441,14.3329415 C6.42166679,15.0761543 7.42603008,15.5 8.5,15.5 C9.57727511,15.5 10.5844819,15.0735184 11.330617,14.3262355 L11.6974976,13.9587906 L19.5,15.3116863 L19.5,7.68876127 Z"
        transform="rotate(90 12 11.5)"
      />
    </g>
  </svg>
);

export default Keyhole;
