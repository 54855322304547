import PercentField from 'components/percent-field';

const OpacityField: React.FunctionComponent<{
  value: number;
  disabled?: boolean;
  onChange: (value: number) => void;
  'data-cy'?: string;
}> = ({ value, onChange, disabled, 'data-cy': dataCy }) => {
  return (
    <PercentField
      value={value}
      disabled={disabled}
      onChange={onChange}
      data-cy={dataCy}
      minValue={0}
      maxValue={100}
    />
  );
};

export default OpacityField;
