import { Fragment, useEffect, useRef, useCallback } from 'react';
import * as PIXI from 'pixi.js';
import {
  ViewportCoordinates,
  FloorplanCoordinates,
  computeBoundingRegionExtents,
  snapToAngle,
  computePolygonEdges,
} from 'lib/geometry';
import { lineSegmentIntersection2d } from 'lib/algorithm';
import FloorplanCollection from 'lib/floorplan-collection';
import PlanSensor from 'lib/sensor';
import Space from 'lib/space';
import Reference from 'lib/reference';
import PhotoGroup from 'lib/photo-group';
import WallSegment, {
  WALL_SEGMENT_SNAP_THRESHOLD_PIXELS,
} from 'lib/wall-segment';

import { PlacementMode } from 'components/floorplan/placement-mode';
import {
  Layer,
  MetricLabel,
  useFloorplanLayerContext,
  toRawHex,
} from 'components/floorplan';
import { AreaOfConcern } from 'components/editor/state';

import { Gray700 } from '@density/dust/dist/tokens/dust.tokens';
import PolygonalSpaceCreationLayer from './polygonal-space-creation-layer';
import AreaOfConcernCreationLayer from './area-of-concern-creation-layer';
import ReferenceRulerCreationLayer from './reference-ruler-creation-layer';

const PLACEMENT_TOOLTIP_OFFSET_X_PX = 16;

// The object placement layer renders information about objects about to be placed, and facilutates
// rendering a backdrop over top of all other elements so clicks anywhere on the floorplan are
// trapped.
const ObjectPlacementTargetLayer: React.FunctionComponent<{
  placementMode: PlacementMode | null;
  walls: FloorplanCollection<WallSegment>;
  sensors: FloorplanCollection<PlanSensor>;
  onAddSensor: (sensor: PlanSensor) => void;
  onAddAreaOfConcern: (areaOfConcern: AreaOfConcern) => void;
  spaces: FloorplanCollection<Space>;
  onAddSpace: (space: Space) => void;
  onAddReference: (reference: Reference) => void;
  photoGroups: FloorplanCollection<PhotoGroup>;
  onAddPhotoGroup: (photoGroup: PhotoGroup) => void;
}> = ({
  placementMode,
  walls,
  sensors,
  onAddSensor,
  onAddAreaOfConcern,
  spaces,
  onAddSpace,
  onAddReference,
  photoGroups,
  onAddPhotoGroup,
}) => {
  const context = useFloorplanLayerContext();

  const mousePositionRef = useRef<ViewportCoordinates | null>(null);

  // Cache a copy of "placementMode" locally so that state updates can be made quicker and not have
  // to go through the whole flux loop within the editor
  const placementModeRef = useRef<PlacementMode | null>(null);

  // Globally add a way that cypress can interrogate the object placement layer placement mode ref
  // This is used by the planning tab "page object" in the cypress tests
  if (
    process.env.REACT_APP_ENABLE_EDITOR_GET_STATE &&
    process.env.REACT_APP_ENABLE_EDITOR_GET_STATE.toLowerCase() === 'true'
  ) {
    (window as any).editorGetPlacementModeRef = () => placementModeRef;
  }

  const onMouseDownCanvasRef = useRef<((evt: MouseEvent) => void) | null>(null);
  const onMouseMoveCanvasRef = useRef<((evt: MouseEvent) => void) | null>(null);
  const onMouseOutCanvasRef = useRef<((evt: MouseEvent) => void) | null>(null);

  const onMouseDown = useCallback(
    (floorplanCoords: FloorplanCoordinates, evt: MouseEvent) => {
      if (!placementModeRef.current) {
        return;
      }

      switch (placementModeRef.current.type) {
        case 'sensor': {
          const sensorType = placementModeRef.current.sensorType;
          const filteredSensors = PlanSensor.filterByType(sensorType, sensors);
          const sensorName = PlanSensor.generateName(
            sensorType,
            filteredSensors.length
          );
          const planSensor = PlanSensor.create(
            sensorType,
            floorplanCoords,
            PlanSensor.computeDefaultHeight(sensorType),
            0,
            sensorName
          );
          onAddSensor(planSensor);
          return;
        }
        case 'areaofconcern': {
          // If the next point cannot be placed due to a self intersection, then bail
          if (placementModeRef.current.nextPointSelfIntersection) {
            return;
          }

          // If the user clicks on the final point, then finish the polygon!
          if (placementModeRef.current.mouseOverFinalPoint) {
            const areaOfConcern = AreaOfConcern.create(
              placementModeRef.current.vertices
            );
            onAddAreaOfConcern(areaOfConcern);
            return;
          }

          // If not close to the first vertex, add a new vertex
          if (!placementModeRef.current.nextPointPosition) {
            return;
          }
          placementModeRef.current = {
            ...placementModeRef.current,
            vertices: [
              ...placementModeRef.current.vertices,
              placementModeRef.current.nextPointPosition,
            ],
          };
          return;
        }
        case 'space': {
          const spaceName = Space.generateName(
            FloorplanCollection.list(spaces)
          );
          switch (placementModeRef.current.shape) {
            case 'box': {
              const space = Space.createBox(
                floorplanCoords,
                spaceName,
                Space.DEFAULT_BOX_SHAPE.width,
                Space.DEFAULT_BOX_SHAPE.height
              );
              onAddSpace(space);
              return;
            }
            case 'circle': {
              const space = Space.createCircle(
                floorplanCoords,
                spaceName,
                Space.DEFAULT_CIRCLE_SHAPE.radius
              );
              onAddSpace(space);
              return;
            }
            case 'polygon': {
              // If the next point cannot be placed due to a self intersection, then bail
              if (placementModeRef.current.nextPointSelfIntersection) {
                return;
              }

              // If the user clicks on the final point, then finish the polygon!
              if (placementModeRef.current.mouseOverFinalPoint) {
                const space = Space.createPolygon(
                  placementModeRef.current.vertices,
                  spaceName
                );
                onAddSpace(space);
                return;
              }

              // If not close to the first vertex, add a new vertex
              if (!placementModeRef.current.nextPointPosition) {
                return;
              }
              placementModeRef.current = {
                ...placementModeRef.current,
                vertices: [
                  ...placementModeRef.current.vertices,
                  placementModeRef.current.nextPointPosition,
                ],
              };
              return;
            }
            case 'polygon-duplicate': {
              // A default polygon - this will get overridden in the placement.addSpace action with
              // data in state.duplicateSpacePolygonParams
              const vertices = [
                FloorplanCoordinates.create(
                  floorplanCoords.x,
                  floorplanCoords.y - 2
                ),
                FloorplanCoordinates.create(
                  floorplanCoords.x + 2,
                  floorplanCoords.y + 2
                ),
                FloorplanCoordinates.create(
                  floorplanCoords.x - 2,
                  floorplanCoords.y + 2
                ),
              ];

              const space = Space.createPolygon(vertices, spaceName);
              onAddSpace(space);
              return;
            }
            default: {
              return;
            }
          }
        }
        case 'reference': {
          switch (placementModeRef.current.referenceType) {
            case 'ruler': {
              // If starting position is not set, set it on the first click
              if (!placementModeRef.current.positionA) {
                placementModeRef.current = {
                  ...placementModeRef.current,
                  positionA: floorplanCoords,
                };
                return;
              }
              if (!placementModeRef.current.positionB) {
                // Make sure that the mouse has been moved so that position B is assigned
                return;
              }

              const referenceRuler = Reference.createRuler(
                placementModeRef.current.positionA,
                placementModeRef.current.positionB
              );
              onAddReference(referenceRuler);
              return;
            }
            case 'height': {
              if (!placementModeRef.current.mousePosition) {
                return;
              }
              const referenceHeight = Reference.createHeight(
                placementModeRef.current.mousePosition
              );
              onAddReference(referenceHeight);
              return;
            }
            default: {
              return;
            }
          }
        }
        case 'photogroup': {
          const photoGroupName = PhotoGroup.generateName(photoGroups);
          const photoGroup = PhotoGroup.create(photoGroupName, floorplanCoords);
          onAddPhotoGroup(photoGroup);
          return;
        }
      }
    },
    [
      sensors,
      onAddSensor,
      spaces,
      onAddSpace,
      onAddAreaOfConcern,
      onAddReference,
      photoGroups,
      onAddPhotoGroup,
    ]
  );

  const onMouseMove = useCallback(
    (floorplanCoords: FloorplanCoordinates, evt: MouseEvent) => {
      if (!context.viewport.current) {
        return;
      }
      const viewport = context.viewport.current;
      if (!placementModeRef.current) {
        return;
      }

      // Note down the cursor position
      placementModeRef.current = {
        ...placementModeRef.current,
        mousePosition: floorplanCoords,
      };

      // A few special behaviors when placing polygonal spaces:
      if (
        floorplanCoords &&
        placementModeRef.current.type === 'space' &&
        placementModeRef.current.shape === 'polygon'
      ) {
        let position = floorplanCoords;
        let positionViewport = FloorplanCoordinates.toViewportCoordinates(
          position,
          context.floorplan,
          viewport
        );

        placementModeRef.current = {
          type: 'space' as const,
          shape: 'polygon' as const,
          vertices: placementModeRef.current.vertices,
          mousePosition: floorplanCoords,
          nextPointPosition: position,
          mouseOverFinalPoint: false,
          nextPointSelfIntersection:
            placementModeRef.current.nextPointSelfIntersection,
        };

        // Apply any angle snapping, if enabled
        const angleSnappingEnabled = evt.shiftKey;
        if (
          angleSnappingEnabled &&
          placementModeRef.current.vertices.length > 0
        ) {
          const finalVertexViewport =
            FloorplanCoordinates.toViewportCoordinates(
              placementModeRef.current.vertices[
                placementModeRef.current.vertices.length - 1
              ],
              context.floorplan,
              viewport
            );
          const snappedCoordinates = snapToAngle(
            finalVertexViewport,
            positionViewport
          );

          // Update the position to be at the snap result point
          positionViewport = ViewportCoordinates.create(
            snappedCoordinates.x,
            snappedCoordinates.y
          );
          position = ViewportCoordinates.toFloorplanCoordinates(
            positionViewport,
            viewport,
            context.floorplan
          );
        }

        // Snap to walls if they exist
        if (
          !FloorplanCollection.isEmpty(walls) ||
          !FloorplanCollection.isEmpty(spaces)
        ) {
          // Get all wall segments that should be checked for snapping
          const verticesPlusPosition = [
            ...placementModeRef.current.vertices,
            floorplanCoords, // The actual mouse position
            position, // Potentially the snap point position which could be different than the mouse position
          ];
          let [upperLeft, lowerRight] =
            computeBoundingRegionExtents(verticesPlusPosition);
          if (upperLeft && lowerRight) {
            // Pad the bounding box a little on each side so that wall segments within the snap
            // threshold outside of the regular bounding region will be included
            const paddingInPixels =
              WALL_SEGMENT_SNAP_THRESHOLD_PIXELS *
              context.floorplan.scale *
              viewport.zoom;
            upperLeft = FloorplanCoordinates.create(
              upperLeft.x - paddingInPixels,
              upperLeft.y - paddingInPixels
            );
            lowerRight = FloorplanCoordinates.create(
              lowerRight.x + paddingInPixels,
              lowerRight.y + paddingInPixels
            );

            const edgesOfSpaces = FloorplanCollection.list(spaces).flatMap(
              (space) => Space.computeEdgesOfSpace(space)
            );

            const wallSegmentsInRegion =
              WallSegment.computeWallSegmentsInBoundingRegion(
                [
                  ...FloorplanCollection.list(walls),
                  // FIXME: converting each space into "wallsegments" like this seems like a bit of
                  // a bad idea. Come up with a better way of doing this? Like create a more
                  // abstract notion of a "Segment" where a "WalLSegment" is a more specific
                  // version?
                  ...edgesOfSpaces.map(([a, b]) => WallSegment.create(a, b)),
                ],
                upperLeft,
                lowerRight
              );

            const [snapPoint] =
              WallSegment.computeNearestSnapPointInBoundingRegion(
                wallSegmentsInRegion,
                position,
                upperLeft,
                lowerRight,
                context.floorplan,
                viewport,
                WALL_SEGMENT_SNAP_THRESHOLD_PIXELS
              );
            if (snapPoint) {
              position = snapPoint;
              positionViewport = FloorplanCoordinates.toViewportCoordinates(
                snapPoint,
                context.floorplan,
                viewport
              );
            }
          }
        }

        // If the mouse if over the final point, then set a flag
        if (placementModeRef.current.vertices.length > 2) {
          const firstVertex = placementModeRef.current.vertices[0];
          const firstVertexViewport =
            FloorplanCoordinates.toViewportCoordinates(
              firstVertex,
              context.floorplan,
              viewport
            );

          const distanceToFirstVertex = Math.hypot(
            Math.abs(positionViewport.y - firstVertexViewport.y),
            Math.abs(positionViewport.x - firstVertexViewport.x)
          );

          if (distanceToFirstVertex < 8) {
            placementModeRef.current.mouseOverFinalPoint = true;
          }
        }

        // If the mouse intersects a previous line in the polygon, then set a flag
        if (
          placementModeRef.current.vertices.length > 2 &&
          placementModeRef.current.nextPointPosition
        ) {
          const lastVertex =
            placementModeRef.current.vertices[
              placementModeRef.current.vertices.length - 1
            ];

          const testLinePointA = FloorplanCoordinates.toViewportCoordinates(
            lastVertex,
            context.floorplan,
            viewport
          );
          const testLinePointB = FloorplanCoordinates.toViewportCoordinates(
            placementModeRef.current.nextPointPosition,
            context.floorplan,
            viewport
          );

          // Get all edges that make up the polygon being drawn
          const edges = computePolygonEdges(placementModeRef.current.vertices)
            .slice(0, -1)
            .map(([pointA, pointB]) => [
              FloorplanCoordinates.toViewportCoordinates(
                pointA,
                context.floorplan,
                viewport
              ),
              FloorplanCoordinates.toViewportCoordinates(
                pointB,
                context.floorplan,
                viewport
              ),
            ]);

          // Check to see if the new line being drawn intersects with any existing polygon edge
          const intersectingPoints = edges.flatMap((edge, index) => {
            const point = lineSegmentIntersection2d(
              [],
              // The edge is one line segment
              [edge[0].x, edge[0].y],
              [edge[1].x, edge[1].y],
              // And the new edge currently being created is the other line segment
              [testLinePointA.x, testLinePointA.y],
              [testLinePointB.x, testLinePointB.y]
            );

            if (!point) {
              return [];
            }

            // An intersection may be returned for the point where the two edges join, but this is a
            // false positive
            if (
              (point[0] === testLinePointA.x &&
                point[1] === testLinePointA.y) ||
              (point[0] === testLinePointB.x && point[1] === testLinePointB.y)
            ) {
              return [];
            }
            if (
              (edges[edges.length - 1][1].x === point[0] &&
                edges[edges.length - 1][1].y === point[1]) ||
              (edges[edges.length - 1][1].x === point[0] &&
                edges[edges.length - 1][1].y === point[1])
            ) {
              return [];
            }

            return [ViewportCoordinates.create(point[0], point[1])];
          });

          placementModeRef.current.nextPointSelfIntersection =
            intersectingPoints.length > 0
              ? ViewportCoordinates.toFloorplanCoordinates(
                  intersectingPoints[0],
                  viewport,
                  context.floorplan
                )
              : null;
        }

        placementModeRef.current.nextPointPosition = position;
        return;
      }

      // When placing reference rulers, set the end point equal to the mouse position
      if (
        floorplanCoords &&
        placementModeRef.current.type === 'reference' &&
        placementModeRef.current.referenceType === 'ruler' &&
        placementModeRef.current.positionA
      ) {
        const positionA = placementModeRef.current.positionA;
        let positionB = floorplanCoords;

        // Snap point b if shift is held
        if (evt.shiftKey) {
          positionB = snapToAngle(positionA, positionB);
        }

        placementModeRef.current = {
          ...placementModeRef.current,
          positionA,
          positionB,
        };
        return;
      }

      // A few special behaviors when placing areas of concern:
      if (
        floorplanCoords &&
        placementModeRef.current.type === 'areaofconcern'
      ) {
        let position = floorplanCoords;
        let positionViewport = FloorplanCoordinates.toViewportCoordinates(
          position,
          context.floorplan,
          viewport
        );

        placementModeRef.current = {
          type: 'areaofconcern' as const,
          vertices: placementModeRef.current.vertices,
          mousePosition: floorplanCoords,
          nextPointPosition: position,
          mouseOverFinalPoint: false,
          nextPointSelfIntersection:
            placementModeRef.current.nextPointSelfIntersection,
        };

        // Apply any angle snapping, if enabled
        const angleSnappingEnabled = evt.shiftKey;
        if (
          angleSnappingEnabled &&
          placementModeRef.current.vertices.length > 0
        ) {
          const finalVertexViewport =
            FloorplanCoordinates.toViewportCoordinates(
              placementModeRef.current.vertices[
                placementModeRef.current.vertices.length - 1
              ],
              context.floorplan,
              viewport
            );
          const snappedCoordinates = snapToAngle(
            finalVertexViewport,
            positionViewport
          );

          // Update the position to be at the snap result point
          positionViewport = ViewportCoordinates.create(
            snappedCoordinates.x,
            snappedCoordinates.y
          );
          position = ViewportCoordinates.toFloorplanCoordinates(
            positionViewport,
            viewport,
            context.floorplan
          );
        }

        // Snap to walls if they exist
        if (!FloorplanCollection.isEmpty(walls)) {
          // Get all wall segments that should be checked for snapping
          const verticesPlusPosition = [
            ...placementModeRef.current.vertices,
            floorplanCoords, // The actual mouse position
            position, // Potentially the snap point position which could be different than the mouse position
          ];
          let [upperLeft, lowerRight] =
            computeBoundingRegionExtents(verticesPlusPosition);
          if (upperLeft && lowerRight) {
            // Pad the bounding box a little on each side so that wall segments within the snap
            // threshold outside of the regular bounding region will be included
            const paddingInPixels =
              WALL_SEGMENT_SNAP_THRESHOLD_PIXELS *
              context.floorplan.scale *
              viewport.zoom;
            upperLeft = FloorplanCoordinates.create(
              upperLeft.x - paddingInPixels,
              upperLeft.y - paddingInPixels
            );
            lowerRight = FloorplanCoordinates.create(
              lowerRight.x + paddingInPixels,
              lowerRight.y + paddingInPixels
            );

            const wallSegmentsInRegion =
              WallSegment.computeWallSegmentsInBoundingRegion(
                FloorplanCollection.list(walls),
                upperLeft,
                lowerRight
              );

            const [snapPoint] =
              WallSegment.computeNearestSnapPointInBoundingRegion(
                wallSegmentsInRegion,
                position,
                upperLeft,
                lowerRight,
                context.floorplan,
                viewport,
                WALL_SEGMENT_SNAP_THRESHOLD_PIXELS
              );
            if (snapPoint) {
              position = snapPoint;
              positionViewport = FloorplanCoordinates.toViewportCoordinates(
                snapPoint,
                context.floorplan,
                viewport
              );
            }
          }
        }

        // If the mouse if over the final point, then set a flag
        if (placementModeRef.current.vertices.length > 2) {
          const firstVertex = placementModeRef.current.vertices[0];
          const firstVertexViewport =
            FloorplanCoordinates.toViewportCoordinates(
              firstVertex,
              context.floorplan,
              viewport
            );

          const distanceToFirstVertex = Math.hypot(
            Math.abs(positionViewport.y - firstVertexViewport.y),
            Math.abs(positionViewport.x - firstVertexViewport.x)
          );

          if (distanceToFirstVertex < 8) {
            placementModeRef.current.mouseOverFinalPoint = true;
          }
        }

        // If the mouse intersects a previous line in the polygon, then set a flag
        if (
          placementModeRef.current.vertices.length > 2 &&
          placementModeRef.current.nextPointPosition
        ) {
          const lastVertex =
            placementModeRef.current.vertices[
              placementModeRef.current.vertices.length - 1
            ];

          const testLinePointA = FloorplanCoordinates.toViewportCoordinates(
            lastVertex,
            context.floorplan,
            viewport
          );
          const testLinePointB = FloorplanCoordinates.toViewportCoordinates(
            placementModeRef.current.nextPointPosition,
            context.floorplan,
            viewport
          );

          // Get all edges that make up the polygon being drawn
          const edges = computePolygonEdges(placementModeRef.current.vertices)
            .slice(0, -1)
            .map(([pointA, pointB]) => [
              FloorplanCoordinates.toViewportCoordinates(
                pointA,
                context.floorplan,
                viewport
              ),
              FloorplanCoordinates.toViewportCoordinates(
                pointB,
                context.floorplan,
                viewport
              ),
            ]);

          // Check to see if the new line being drawn intersects with any existing polygon edge
          const intersectingPoints = edges.flatMap((edge, index) => {
            const point = lineSegmentIntersection2d(
              [],
              // The edge is one line segment
              [edge[0].x, edge[0].y],
              [edge[1].x, edge[1].y],
              // And the new edge currently being created is the other line segment
              [testLinePointA.x, testLinePointA.y],
              [testLinePointB.x, testLinePointB.y]
            );

            if (!point) {
              return [];
            }

            // An intersection may be returned for the point where the two edges join, but this is a
            // false positive
            if (
              (point[0] === testLinePointA.x &&
                point[1] === testLinePointA.y) ||
              (point[0] === testLinePointB.x && point[1] === testLinePointB.y)
            ) {
              return [];
            }
            if (
              (edges[edges.length - 1][1].x === point[0] &&
                edges[edges.length - 1][1].y === point[1]) ||
              (edges[edges.length - 1][1].x === point[0] &&
                edges[edges.length - 1][1].y === point[1])
            ) {
              return [];
            }

            return [ViewportCoordinates.create(point[0], point[1])];
          });

          placementModeRef.current.nextPointSelfIntersection =
            intersectingPoints.length > 0
              ? ViewportCoordinates.toFloorplanCoordinates(
                  intersectingPoints[0],
                  viewport,
                  context.floorplan
                )
              : null;
        }

        placementModeRef.current.nextPointPosition = position;
        return;
      }
    },
    [context.floorplan, context.viewport, spaces, walls]
  );

  const onPlacementModeActivation = useCallback(() => {
    const isPlacementModeEnabled = placementModeRef.current !== null;
    if (!isPlacementModeEnabled) {
      return;
    }

    if (!context.viewport.current) {
      return;
    }

    const canvasElement = context.app.view;
    const canvasBBox = canvasElement.getBoundingClientRect();

    // Register mouse handlers
    const onMouseDownCanvas = (evt: MouseEvent) => {
      if (!context.viewport.current) {
        return;
      }

      const viewportCoords = ViewportCoordinates.create(
        evt.clientX - canvasBBox.x,
        evt.clientY - canvasBBox.y
      );
      const floorplanCoords = ViewportCoordinates.toFloorplanCoordinates(
        viewportCoords,
        context.viewport.current,
        context.floorplan
      );
      onMouseDown(floorplanCoords, evt);
    };
    const onMouseMoveCanvas = (evt: MouseEvent) => {
      if (!context.viewport.current) {
        return;
      }

      mousePositionRef.current = ViewportCoordinates.create(
        evt.clientX - canvasBBox.x,
        evt.clientY - canvasBBox.y
      );

      const floorplanCoords = ViewportCoordinates.toFloorplanCoordinates(
        mousePositionRef.current,
        context.viewport.current,
        context.floorplan
      );
      onMouseMove(floorplanCoords, evt);
    };
    const onMouseOutCanvas = () => {
      mousePositionRef.current = null;
    };

    canvasElement.addEventListener('mousedown', onMouseDownCanvas);
    canvasElement.addEventListener('mousemove', onMouseMoveCanvas);
    canvasElement.addEventListener('mouseout', onMouseOutCanvas);
    onMouseDownCanvasRef.current = onMouseDownCanvas;
    onMouseMoveCanvasRef.current = onMouseMoveCanvas;
    onMouseOutCanvasRef.current = onMouseOutCanvas;

    const objectPlacementLabel = new MetricLabel('', {
      backgroundColor: toRawHex(Gray700),
      pinHorizontal: 'start',
      textStyle: new PIXI.TextStyle({
        // FIXME: load correct font here
        fontFamily: 'Arial',
        fontSize: 14,
        fontWeight: 'bold',
        fill: '#ffffff',
      }),
      horizontalPaddingPixels: 16,
      verticalPaddingPixels: 9,
      radiusPixels: 32,
    });
    objectPlacementLabel.name = 'object-placement-label';
    context.app.stage.addChild(objectPlacementLabel);

    // A transparent backgrop is rendered overtop of the whole stage
    // to ensure that we have control over the mouse position
    const backdrop = new PIXI.Sprite(PIXI.Texture.WHITE);
    backdrop.name = 'object-placement-backdrop';
    backdrop.width = context.viewport.current.width;
    backdrop.height = context.viewport.current.height;
    backdrop.alpha = 0;
    backdrop.interactive = true;
    backdrop.cursor = 'copy';
    context.app.stage.addChild(backdrop);
  }, [
    onMouseDown,
    onMouseMove,
    context.app.stage,
    context.app.view,
    context.floorplan,
    context.viewport,
  ]);

  const onPlacementModeDeactivation = useCallback(() => {
    const backdrop = context.app.stage.getChildByName(
      'object-placement-backdrop'
    ) as PIXI.Sprite | null;
    if (backdrop) {
      context.app.stage.removeChild(backdrop);
    }
    const objectPlacementLabel = context.app.stage.getChildByName(
      'object-placement-label'
    ) as MetricLabel | null;
    if (objectPlacementLabel) {
      context.app.stage.removeChild(objectPlacementLabel);
    }

    mousePositionRef.current = null;

    const canvasElement = context.app.view;
    if (onMouseDownCanvasRef.current) {
      canvasElement.removeEventListener(
        'mousedown',
        onMouseDownCanvasRef.current
      );
    }
    if (onMouseMoveCanvasRef.current) {
      canvasElement.removeEventListener(
        'mousemove',
        onMouseMoveCanvasRef.current
      );
    }
    if (onMouseOutCanvasRef.current) {
      canvasElement.removeEventListener(
        'mouseout',
        onMouseOutCanvasRef.current
      );
    }
  }, [context.app.stage, context.app.view]);

  useEffect(() => {
    const oldPlacementMode = placementModeRef.current;
    if (oldPlacementMode === null && placementMode) {
      placementModeRef.current = placementMode;
      onPlacementModeActivation();
    } else if (oldPlacementMode && placementMode === null) {
      placementModeRef.current = placementMode;
      onPlacementModeDeactivation();
    } else if (oldPlacementMode && placementMode) {
      // Filter out situations where the old and new placement modes are in fact the same thing
      switch (oldPlacementMode.type) {
        case 'space':
          if (
            placementMode.type === 'space' &&
            oldPlacementMode.shape === placementMode.shape
          ) {
            return;
          }
          break;
        case 'sensor':
          if (
            placementMode.type === 'sensor' &&
            oldPlacementMode.sensorType === placementMode.sensorType
          ) {
            return;
          }
          break;
        case 'areaofconcern':
          if (placementMode.type === 'areaofconcern') {
            return;
          }
          break;
        case 'reference':
          if (
            placementMode.type === 'reference' &&
            oldPlacementMode.referenceType === placementMode.referenceType
          ) {
            return;
          }
          break;
        case 'photogroup':
          if (placementMode.type === 'photogroup') {
            return;
          }
          break;
      }

      // If they differ, then update the state.
      placementModeRef.current = null;
      onPlacementModeDeactivation();
      placementModeRef.current = placementMode;
      onPlacementModeActivation();
    }
  }, [placementMode, onPlacementModeActivation, onPlacementModeDeactivation]);
  useEffect(() => {
    return () => onPlacementModeDeactivation();
  }, [onPlacementModeDeactivation]);

  return (
    <Fragment>
      <Layer
        onAnimationFrame={() => {
          const objectPlacementLabel = context.app.stage.getChildByName(
            'object-placement-label'
          ) as MetricLabel | undefined;
          if (!objectPlacementLabel) {
            return;
          }

          // If the mouse is no longer on the canvas, then hide the label
          if (!mousePositionRef.current) {
            objectPlacementLabel.visible = false;
            return;
          }

          objectPlacementLabel.visible = true;
          objectPlacementLabel.x =
            mousePositionRef.current.x + PLACEMENT_TOOLTIP_OFFSET_X_PX;
          objectPlacementLabel.y = mousePositionRef.current.y;

          const addPlacementText = PlacementMode.computeText(
            placementModeRef.current
          );
          if (addPlacementText) {
            objectPlacementLabel.setText(addPlacementText);
          }
        }}
      />
      <PolygonalSpaceCreationLayer placementModeRef={placementModeRef} />
      <AreaOfConcernCreationLayer placementModeRef={placementModeRef} />
      <ReferenceRulerCreationLayer placementModeRef={placementModeRef} />
    </Fragment>
  );
};

export default ObjectPlacementTargetLayer;
