import { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { Icons } from '@density/dust';

import TextField from 'components/text-field';
import HorizontalForm from 'components/horizontal-form';
import Button from 'components/button';

import { addDragListener } from 'lib/drag';
import { round } from 'lib/math';

function boundRotation(angle: number): number {
  while (angle < 0) {
    angle += 360;
  }
  while (angle >= 360) {
    angle -= 360;
  }
  return angle;
}

const RotationField: React.FunctionComponent<{
  value: number;
  disabled?: boolean;
  rotationSVGGlyph?: React.ReactNode;
  onChange: (rotation: number) => void;
  onBlur?: (rotation: number) => void;
  onRotateRight90: () => void;
  'data-cy'?: string;
}> = ({
  value,
  disabled = false,
  rotationSVGGlyph,
  onChange,
  onBlur,
  onRotateRight90,
  'data-cy': dataCy,
}) => {
  const [rotationText, setRotationText] = useState('');
  const [rotationTextInvalid, setRotationTextInvalid] = useState(false);
  useEffect(() => {
    setRotationText(`${round(value, 1)}`);
    setRotationTextInvalid(false);
  }, [value]);

  const onMouseDownRotator = (evt: React.MouseEvent<SVGGElement>) => {
    evt.preventDefault();
    if (disabled) {
      return;
    }
    let shiftPressed = evt.shiftKey;
    let nextValue = value;
    const onKeyDown = (evt: KeyboardEvent) => {
      shiftPressed = evt.shiftKey;
    };
    const onKeyUp = (evt: KeyboardEvent) => {
      shiftPressed = evt.shiftKey;
    };
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('keyup', onKeyUp);
    addDragListener(
      evt.clientX,
      evt.clientY,
      (dx, dy) => {
        let change: number;
        if (Math.abs(dx) >= Math.abs(dy)) {
          change = dx;
        } else {
          change = -dy;
        }
        if (shiftPressed) {
          change = 0.1 * change;
        }
        nextValue += change;
        if (!shiftPressed) {
          nextValue = Math.round(nextValue);
        }
        onChange(boundRotation(nextValue));
      },
      () => {
        window.removeEventListener('keydown', onKeyDown);
        window.removeEventListener('keyup', onKeyUp);
        if (onBlur) {
          onBlur(boundRotation(nextValue));
        }
      }
    );
  };

  return (
    <HorizontalForm size="medium">
      <div className={styles.inputRotator}>
        <svg
          width={32}
          height={32}
          viewBox={`0 0 40 40`}
          transform={`rotate(${value} 0 0)`}
          onMouseDown={onMouseDownRotator}
          fill="none"
        >
          <rect width={40} height={40} rx={20} fill="#E1E4E8" />
          {rotationSVGGlyph || (
            // By default, show an arrow as the rotation indicator
            <path
              transform="translate(7,7)"
              d="M12,1.58578644 L20.7071068,10.2928932 L19.2928932,11.7071068 L12.9998932,5.41378644 L13,22 L11,22 L10.9998932,5.41378644 L4.70710678,11.7071068 L3.29289322,10.2928932 L12,1.58578644 Z"
              fill={dust.Blue400}
              fillRule="nonzero"
            />
          )}
        </svg>
      </div>
      <TextField
        type="number"
        size="medium"
        trailingSuffix="°"
        width={72}
        disabled={disabled}
        placeholder="ex: 5"
        min={-360}
        max={360}
        step={1}
        error={rotationTextInvalid}
        value={rotationText}
        onChange={(e) => setRotationText(e.currentTarget.value)}
        onBlur={() => {
          const rotation = parseFloat(rotationText);
          if (isNaN(rotation)) {
            setRotationTextInvalid(true);
            return;
          }
          setRotationTextInvalid(false);
          onChange(boundRotation(rotation));
          if (onBlur) {
            onBlur(boundRotation(rotation));
          }
        }}
        data-cy={dataCy}
      />
      <Button
        size="medium"
        type="hollow"
        onClick={() => onRotateRight90()}
        disabled={disabled}
        trailingIcon={<Icons.RotateArrow size={18} color="currentColor" />}
      />
    </HorizontalForm>
  );
};

export default RotationField;
