// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-appScrollView-258fCwHh{box-sizing:border-box;height:100%;width:100%;overflow-y:auto;-webkit-overflow-scrolling:touch}\n", ""]);
// Exports
exports.locals = {
	"appScrollView": "dui-styles-module-appScrollView-258fCwHh"
};
module.exports = exports;
