import { v4 as uuidv4 } from 'uuid';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

import { Meters, LengthUnit } from 'lib/units';
import { degreesToRadians, distance } from 'lib/math';
import { FloorplanV2Sensor, isFloorplanPlanSensorId, Unsaved } from 'lib/api';
import { FloorplanCoordinates, CADCoordinates } from 'lib/geometry';
import FloorplanCollection from 'lib/floorplan-collection';
import { closestPointOnLineSegment } from 'lib/algorithm';
import Floorplan from 'lib/floorplan';
import WallSegment from 'lib/wall-segment';

import { State, ProcessedCADSensorPlacement } from 'components/editor/state';

const FIRST_BUILD_WITH_SENSOR_LOCATE_AND_IMMEDIATE_COMMANDS = 10094;

export type PlanSensorType = 'oa' | 'entry';
export namespace PlanSensorType {
  export function generateDisplayName(sensorType: PlanSensorType) {
    return sensorType === 'oa' ? 'OA' : 'Entry';
  }
}

export enum SensorStatus {
  UNCONFIGURED = 'unconfigured',
  PROVISIONING = 'provisioning',
  ERROR = 'error',
  ONLINE = 'online',
  LOW_POWER = 'low_power',
  ARCHIVED = 'archived',
}

export const getSensorStatusColor = (status?: string) => {
  switch (status) {
    case SensorStatus.ONLINE:
      return dust.Green400;
    case SensorStatus.LOW_POWER:
    case SensorStatus.ERROR:
    case SensorStatus.ARCHIVED:
      return dust.Red400;
    default:
      return dust.Gray400;
  }
};

export type SensorStreamingStatus = 'connecting' | 'connected' | 'disconnected';
export type SensorConnection = {
  status: SensorStreamingStatus;
  serialNumber: string;
};

// This datatype is used to represent the sensor's coverage area when it comes out of the coverage
// area calculation.
//
// It's an array of pairs, where each element in the array represents a vector starting at the
// sensor's location and moving outwards along an angle. This angle is stored at the first element
// of the pair.
//
// The second element in the pair is itself an array of distances from the sensor along that vector
// in which coverage was obstructed. The final item is the final coverage distance.
//
// If you wanted to convert this into a polygon, you'd project the each vector along the given angle
// at the magnitude equal to the final element in the distances array.
export type SensorCoverageIntersectionVectors = Array<[number, Array<number>]>;

type BaseSensorValidation = {
  id: string;
  objectType: 'sensor';
  objectId: PlanSensor['id'];
  severity: 'warning' | 'error';
};
export type SensorValidation =
  | (BaseSensorValidation & {
      severity: 'warning';
      validationType: 'sensor.coverageObstructedDueToCeiling';
    })
  | (BaseSensorValidation & {
      severity: 'warning';
      validationType: 'sensor.positionTooCloseToWall';
      segmentsAndClosestPoint: Array<[WallSegment, FloorplanCoordinates]>;
    })
  | (BaseSensorValidation & {
      severity: 'error';
      validationType: 'sensor.heightTooLow';
    })
  | (BaseSensorValidation & {
      severity: 'error';
      validationType: 'sensor.heightTooHigh';
    });

type PlanSensor = {
  id: string;
  type: PlanSensorType;
  name: string;
  position: FloorplanCoordinates;
  height: number;
  rotation: number;
  // NOTE: nudge is applied after rotation
  dataNudgeX?: number;
  dataNudgeY?: number;
  serialNumber: string | null;
  locked: boolean;
  last_heartbeat?: string | null;
  status?: SensorStatus;
  ipv4?: string | null;
  ipv6?: string | null;
  mac?: string | null;
  os?: string | null;
  notes: string;
  plan_sensor_index?: number | null;
  cadId: string;
  boundingBoxFilter: 'none' | 'cloud' | 'device';
};

namespace PlanSensor {
  export function create(
    type: PlanSensorType,
    position: FloorplanCoordinates,
    height: number,
    rotation: number,
    name: string,
    notes: string = '',
    cadId: string = ''
  ): PlanSensor {
    const id = uuidv4();
    return {
      id,
      type,
      name,
      position,
      height,
      rotation,
      dataNudgeX: 0,
      dataNudgeY: 0,
      serialNumber: null,
      locked: false,
      last_heartbeat: null,
      status: SensorStatus.UNCONFIGURED,
      ipv4: null,
      ipv6: null,
      mac: null,
      os: null,
      notes,
      plan_sensor_index: null,
      cadId,
      boundingBoxFilter: 'none',
    };
  }

  // Given a ProcessedCADSensorPlacement generated from a DXF import, create a corresponding
  // PlanSensor that can be placed on the plan.
  //
  // This is used heavily within the DXF import workflow!
  export function createFromCADSensorPlacement(
    processedCADSensorPlacement: ProcessedCADSensorPlacement,
    planSensors: State['planSensors'],
    floorplan: Floorplan,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnitOrDefault: LengthUnit,
    cadFileScaleOrDefault: number
  ): PlanSensor {
    const filteredSensors = PlanSensor.filterByType(
      processedCADSensorPlacement.type,
      planSensors
    );
    const sensorName = PlanSensor.generateName(
      processedCADSensorPlacement.type,
      filteredSensors.length
    );
    const sensorPosition = CADCoordinates.toFloorplanCoordinates(
      processedCADSensorPlacement.position,
      floorplan,
      floorplanCADOrigin,
      cadFileUnitOrDefault,
      cadFileScaleOrDefault
    );
    const planSensor = PlanSensor.create(
      processedCADSensorPlacement.type,
      sensorPosition,
      processedCADSensorPlacement.height,
      processedCADSensorPlacement.rotation,
      sensorName,
      '',
      processedCADSensorPlacement.cadId
    );
    planSensor.serialNumber = processedCADSensorPlacement.serialNumber;
    planSensor.locked = true;
    return planSensor;
  }

  // Given a PlanSensor, return a copy of the PlanSensor that is detached from the underlying
  // physical sensor (ie, no serial number attached, no metadata, etc)
  export function duplicate(oldSensor: PlanSensor): PlanSensor {
    const id = uuidv4();
    return {
      ...oldSensor,
      id,
      serialNumber: null,
      last_heartbeat: null,
      status: SensorStatus.UNCONFIGURED,
      ipv4: null,
      ipv6: null,
      mac: null,
      os: null,
      plan_sensor_index: null,
    };
  }

  export function filterByType(
    type: PlanSensorType,
    sensors: FloorplanCollection<PlanSensor>
  ) {
    return FloorplanCollection.list(sensors).filter((sensor) => {
      return sensor.type === type;
    });
  }

  // Run sensor checks and returns a list of all validations that do not pass
  export function validate(
    planSensor: Pick<PlanSensor, 'id' | 'height' | 'position' | 'type'>,
    stateWalls: State['walls'],
    stateSensorCoverageIntersectionVectors: State['planSensorCoverageIntersectionVectors']
  ): Array<SensorValidation> {
    const validations: Array<SensorValidation> = [];

    if (planSensor.height < PlanSensor.computeMinHeight(planSensor.type)) {
      validations.push({
        id: uuidv4(),
        objectType: 'sensor',
        objectId: planSensor.id,
        severity: 'error',
        validationType: 'sensor.heightTooLow',
      });
    }

    if (planSensor.height > PlanSensor.computeMaxHeight(planSensor.type)) {
      validations.push({
        id: uuidv4(),
        objectType: 'sensor',
        objectId: planSensor.id,
        severity: 'error',
        validationType: 'sensor.heightTooHigh',
      });
    }

    if (!FloorplanCollection.isEmpty(stateWalls)) {
      const wallSegments = FloorplanCollection.list(stateWalls);
      const wallThresholdInMeters = Meters.fromFeet(3);

      const [upperLeft, lowerRight] =
        PlanSensor.computeAxisAlignedBoundingBox(planSensor);
      const wallSegmentsInBoundingRegion =
        WallSegment.computeWallSegmentsInBoundingRegion(
          wallSegments,
          // Pad the bounding box outwards to take into account the wall distance threshold
          FloorplanCoordinates.create(
            upperLeft.x - wallThresholdInMeters,
            lowerRight.y - wallThresholdInMeters
          ),
          FloorplanCoordinates.create(
            lowerRight.x + wallThresholdInMeters,
            lowerRight.y + wallThresholdInMeters
          )
        );

      const segmentsAndClosestPoint: Array<
        [WallSegment, FloorplanCoordinates]
      > = wallSegmentsInBoundingRegion.flatMap((wallSegment) => {
        const closestPoint = closestPointOnLineSegment(
          planSensor.position,
          wallSegment.positionA,
          wallSegment.positionB
        );

        const distanceToWallSegmentInMeters = distance(
          closestPoint,
          planSensor.position
        );
        if (distanceToWallSegmentInMeters < wallThresholdInMeters) {
          return [[wallSegment, closestPoint]];
        } else {
          return [];
        }
      });

      if (segmentsAndClosestPoint.length > 0) {
        validations.push({
          id: uuidv4(),
          objectType: 'sensor',
          objectId: planSensor.id,
          severity: 'warning',
          validationType: 'sensor.positionTooCloseToWall',
          segmentsAndClosestPoint,
        });
      }
    }

    const vectors = stateSensorCoverageIntersectionVectors.get(planSensor.id);
    if (vectors && vectors !== 'empty' && vectors !== 'loading') {
      // The coverage intersection vector format stores coverage intersections as an array of pairs,
      // where the first item in the pair is an angle, and the second item is an array of magnitudes
      // along that angle where the sensor encountered an obstacle, and the final entry being the
      // boundary of the green coverage area.
      //
      // If a sensor has more than one item in that magnitudes array, then there was a obstruction
      // due to ceiling coverage.
      const isObstructed = Boolean(
        vectors.find(([angle, magnitudes]) => magnitudes.length > 1)
      );
      if (isObstructed) {
        validations.push({
          id: uuidv4(),
          objectType: 'sensor',
          objectId: planSensor.id,
          severity: 'warning',
          validationType: 'sensor.coverageObstructedDueToCeiling',
        });
      }
    }

    return validations;
  }

  // Format the given PlanSensor as a FloorplanV2Sensor, the datatype that the floorplan api uses to
  // represent a PlanSensor internally.
  //
  // If the PlanSensor has a client-generated uuid for its id, the id of the resulting
  // FloorplanV2Sensor will be `undefined`.
  export function toFloorplanSensor(
    sensor: PlanSensor
  ): FloorplanV2Sensor | Unsaved<FloorplanV2Sensor> {
    return {
      id: isFloorplanPlanSensorId(sensor.id) ? sensor.id : undefined,
      sensor_serial_number: sensor.serialNumber,
      sensor_type: sensor.type,
      height_meters: sensor.height,
      centroid_from_origin_x_meters: sensor.position.x,
      centroid_from_origin_y_meters: sensor.position.y,
      rotation: sensor.rotation,
      locked: sensor.locked,
      last_heartbeat: sensor.last_heartbeat,
      status: sensor.status,
      diagnostic_info: {
        ipv4: sensor.ipv4,
        ipv6: sensor.ipv6,
        mac: sensor.mac,
        os: sensor.os,
      },
      notes: sensor.notes,
      // this will send the plan_sensor_index field for saving
      // but it's readonly, so it should be fine
      plan_sensor_index: sensor.plan_sensor_index,
      cad_id: sensor.cadId,
      bounding_box_filter: sensor.boundingBoxFilter,
    };
  }

  // Given a FloorplanV2Sensor, the datatype that the the floorplan api uses to represent a
  // PlanSensor internally, construct a PlanSensor that can be used to represent the sensor in
  // planner.
  export function createFromFloorplanSensor(
    planSensor: FloorplanV2Sensor
  ): PlanSensor {
    const sensor = PlanSensor.create(
      planSensor.sensor_type,
      FloorplanCoordinates.create(
        planSensor.centroid_from_origin_x_meters,
        planSensor.centroid_from_origin_y_meters
      ),
      planSensor.height_meters,
      planSensor.rotation,
      // TODO: Sensor name
      '',
      planSensor.notes
    );
    // FIXME: pass through id and serialNumber in create() instead
    sensor.id = planSensor.id;
    sensor.serialNumber = planSensor.sensor_serial_number || null;
    sensor.locked = planSensor.locked;
    sensor.last_heartbeat = planSensor.last_heartbeat;
    sensor.status = planSensor.status;
    sensor.ipv4 = planSensor.diagnostic_info?.ipv4;
    sensor.ipv6 = planSensor.diagnostic_info?.ipv6;
    sensor.mac = planSensor.diagnostic_info?.mac;
    sensor.os = planSensor.diagnostic_info?.os;
    sensor.plan_sensor_index = planSensor.plan_sensor_index; // this is the name of json coming from backend
    sensor.cadId = planSensor.cad_id || '';
    sensor.boundingBoxFilter = planSensor.bounding_box_filter || 'none';
    return sensor;
  }

  // Given a sensor, return the os build number part os the os version
  export function computeOSBuildNumber(osVersion: string): number | null {
    if (!osVersion) {
      return null;
    }

    const buildNumber = window.parseInt(osVersion.split('-')[0], 10);
    if (isNaN(buildNumber)) {
      return null;
    }

    return buildNumber;
  }

  // Can this sensor be sent a locate command and understand it?
  // (This feature was added in like 2019ish, so pretty much everything can at this point)
  export function supportsLocate(planSensor: PlanSensor): boolean {
    const buildNumber = PlanSensor.computeOSBuildNumber(planSensor.os || '');

    if (!buildNumber) {
      return false;
    }

    return buildNumber >= FIRST_BUILD_WITH_SENSOR_LOCATE_AND_IMMEDIATE_COMMANDS;
  }

  // Given a PlanSensor, return the upper left and lower right coordinates that refers to the axis
  // aligned bounding box around it.
  //
  // This is often helpful when trying to scope a query or computation to only run in an area
  // associated with a sensor's coverage field of view - axis aligned bounding boxes are very fast
  // to compute.
  //
  // NOTE: right now, this doesn't take rotation into account - the bounding box for that reason is
  // larger than it needs to be.
  export function computeAxisAlignedBoundingBox(
    planSensor: Pick<PlanSensor, 'height' | 'position'>
  ): [FloorplanCoordinates, FloorplanCoordinates] {
    const cornerPositions =
      PlanSensor.computeAxisAlignedBoundingBoxCornerPositions(planSensor);
    const smallestXPosition = Math.min(...cornerPositions.map((p) => p.x));
    const smallestYPosition = Math.min(...cornerPositions.map((p) => p.y));
    const largestXPosition = Math.max(...cornerPositions.map((p) => p.x));
    const largestYPosition = Math.max(...cornerPositions.map((p) => p.y));

    return [
      FloorplanCoordinates.create(smallestXPosition, smallestYPosition),
      FloorplanCoordinates.create(largestXPosition, largestYPosition),
    ];
  }

  // Figure out the extents of the bounding region around the sensor coverage area.
  // NOTE: this is probably not the function you want, look for PlanSensor.computeAxisAlignedBoundingBox
  export function computeAxisAlignedBoundingBoxCornerPositions(
    planSensor: Pick<PlanSensor, 'height' | 'position'>
  ): Array<FloorplanCoordinates> {
    const [major] = computeCoverageMajorMinorAxisOA(planSensor.height);
    const cornerPositions = [
      [1, 1],
      [1, -1],
      [-1, 1],
      [-1, -1],
    ].map(([xMultiplier, yMultiplier]) =>
      FloorplanCoordinates.create(
        planSensor.position.x + xMultiplier * major,
        planSensor.position.y + yMultiplier * major
      )
    );

    return cornerPositions;
  }

  // DEPRECATED
  // Given an OA sensor's height, compute the radius that the coverage circle should be rendered at.
  //
  // NOTE: this is deprecated, OA sensors now have a coverage field of view that is an ellipse
  // shape, not a circle. However, in certain customer facing situations, sometimes it is still
  // important to be able to render a field of view shape that is circular and not elliptical, so
  // this has stuck around. Generally though, if you are showing the field of view of an OA sensor,
  // you should use `PlanSensor.computeCoverageMajorMinorAxisOA` instead.
  export function computeCoverageRadiusOA(installHeight: number): number {
    // assumptions for now
    const fovDegrees = 105;
    const requiredSubjectCoverageHeight = 0; // for now...

    // the computed height accounting for the requirement of subject coverage
    const H = installHeight - requiredSubjectCoverageHeight;

    const fovRadians = degreesToRadians(fovDegrees);
    return Math.min(H * Math.tan(fovRadians / 2), Meters.fromFeet(12));
  }

  // Given an OA sensor's height, compute the major and minor axis of the ellipse in meters that
  // represents in a 2D context the coverage of the sensor.
  export function computeCoverageMajorMinorAxisOA(
    installHeightMeters: number
  ): [number, number] {
    if (installHeightMeters < Meters.fromFeet(8)) {
      installHeightMeters = Meters.fromFeet(8);
    }
    if (installHeightMeters > Meters.fromFeet(12)) {
      installHeightMeters = Meters.fromFeet(12);
    }

    // See this document for where these multipliers were found:
    // https://www.notion.so/densityinc/OA1-Technical-Specifications-Frequently-Asked-Questions-FAQ-DRAFT-Data-Sheet-1ef5fb5bd81d443f93fade8c33234a0f#a66c61a854144024bed791cfd8eb6394
    const majorMeters = Math.min(
      installHeightMeters * 1.3,
      Meters.fromFeet(14.3)
    );
    const minorMeters = Math.min(
      installHeightMeters * 0.92,
      Meters.fromFeet(9)
    );
    return [majorMeters, minorMeters];
  }

  // Given an Entry sensor's height, cumpute the radius in meters that the half-circle shape
  // should be rendered at when visualizing the sensor's field of view in 2D.
  export function computeCoverageRadiusEntry(
    installHeightMeters: number
  ): number {
    // this uses the linear scale from the installation documentation:
    // install height     maximum install width
    // 90"                40"
    // 93"                45"
    // 96"                50"
    // 99"                55"
    // 102"               60"
    // 105"               65"
    // 108"               70"
    // 111"               75"
    // 114"               80"
    // 117"               85"
    // 120"               90"
    const heightInches = Meters.toInches(installHeightMeters);
    const installWidth = 40 + (5 * (heightInches - 90)) / 3;
    return Meters.fromInches(installWidth / 2);
  }

  export function computeDefaultHeight(sensorType: PlanSensorType) {
    if (sensorType === 'oa') {
      return Meters.fromFeet(10);
    } else {
      //TODO
      return Meters.fromInches(102);
    }
  }

  export function computeMinHeight(sensorType: PlanSensorType) {
    if (sensorType === 'oa') {
      return Meters.fromFeet(7);
    } else {
      return Meters.fromInches(90);
    }
  }

  export function computeMaxHeight(sensorType: PlanSensorType) {
    if (sensorType === 'oa') {
      return Meters.fromFeet(12);
    } else {
      return Meters.fromInches(120);
    }
  }

  export function generateName(
    sensorType: PlanSensorType,
    numExistingSensors: number
  ) {
    const sensorTypeText = PlanSensorType.generateDisplayName(sensorType);
    return `${sensorTypeText} Sensor ${numExistingSensors + 1}`;
  }
}

export default PlanSensor;
