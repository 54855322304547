import { Update } from 'types/update';

// A floorplan collection represents a set of items to be rendered on a floorplan in a specific
// order.
//
// The internal datastructure is optimized for fetching items by their id, as well as listing them
// out in their render order.
//
// When an item is manipulated in the list, it is moved to the front of the render order.
type FloorplanCollection<T extends { id: string }> = {
  items: ReadonlyMap<T['id'], T>;
  renderOrder: ReadonlyArray<T['id']>;
};

namespace FloorplanCollection {
  export function create<T extends { id: string }>(): FloorplanCollection<T> {
    return {
      items: new Map(),
      renderOrder: [],
    };
  }

  export function fromItems<T extends { id: string }>(
    items: Map<T['id'], T>
  ): FloorplanCollection<T> {
    return {
      items,
      renderOrder: Array.from(items.keys()),
    };
  }

  export function fromArray<T extends { id: string }>(
    list: Array<T>
  ): FloorplanCollection<T> {
    return {
      items: new Map(list.map((item) => [item.id, item])),
      renderOrder: list.map((item) => item.id),
    };
  }

  export function addItem<T extends { id: string }>(
    collection: FloorplanCollection<T>,
    item: T
  ): FloorplanCollection<T> {
    const nextItems = new Map(collection.items);
    nextItems.set(item.id, item);
    const nextRenderOrder = [
      ...collection.renderOrder.filter((id) => id !== item.id),
      item.id,
    ];
    return {
      ...collection,
      items: nextItems,
      renderOrder: nextRenderOrder,
    };
  }

  export function removeItem<T extends { id: string }>(
    collection: FloorplanCollection<T>,
    itemId: string
  ): FloorplanCollection<T> {
    const nextItems = new Map(collection.items);
    nextItems.delete(itemId);
    const nextRenderOrder = collection.renderOrder.filter(
      (id) => id !== itemId
    );
    return {
      ...collection,
      items: nextItems,
      renderOrder: nextRenderOrder,
    };
  }

  export function updateItem<T extends { id: string }>(
    collection: FloorplanCollection<T>,
    itemId: string,
    update: Update<T>
  ): FloorplanCollection<T> {
    const prevItem = collection.items.get(itemId);
    if (!prevItem) {
      console.warn(`Cannot find item with id ${itemId}, skipping update...`);
      return collection;
    }
    const nextItems = new Map(collection.items);
    nextItems.set(itemId, {
      ...prevItem,
      ...update,
    });
    return {
      ...collection,
      items: nextItems,
    };
  }

  export function sendToFront<T extends { id: string }>(
    collection: FloorplanCollection<T>,
    itemId: string
  ): FloorplanCollection<T> {
    const nextRenderOrder = [
      ...collection.renderOrder.filter((id) => id !== itemId),
      itemId,
    ];
    return {
      ...collection,
      renderOrder: nextRenderOrder,
    };
  }

  export function list<T extends { id: string }>(
    collection: FloorplanCollection<T>
  ): Array<T> {
    return Array.from(collection.items.values());
  }

  export function listInRenderOrder<T extends { id: string }>(
    collection: FloorplanCollection<T>
  ) {
    return collection.renderOrder.reduce<Array<T>>((result, id) => {
      const item = collection.items.get(id);
      if (item) {
        result.push(item);
      }
      return result;
    }, []);
  }

  export function isEmpty<T extends { id: string }>(
    collection: FloorplanCollection<T>
  ): boolean {
    return collection.items.size === 0;
  }

  // NOTE: The idea of changing the id of something gives me (Ryan) a bad feeling. But, it's sort of
  // a necessary evil given that entities will initially start out as having client generated uuids
  // and then later be given server generated uuids once the objects are created serverside.
  export function changeId<T extends { id: string }>(
    collection: FloorplanCollection<T>,
    oldId: T['id'],
    newId: T['id']
  ) {
    const prevItem = collection.items.get(oldId);
    if (!prevItem) {
      return collection;
    }
    const nextItems = new Map(collection.items);
    nextItems.delete(oldId);
    nextItems.set(newId, {
      ...prevItem,
      id: newId,
    });
    return {
      ...collection,
      items: nextItems,
      renderOrder: collection.renderOrder.map((i) => (i === oldId ? newId : i)),
    };
  }

  export function map<T extends { id: string }>(
    collection: FloorplanCollection<T>,
    mapper: (item: T) => Update<T>
  ): FloorplanCollection<T> {
    const nextItems = new Map(
      FloorplanCollection.list(collection).map((item) => {
        const update = mapper(item);
        return [item.id, { ...item, ...update }];
      })
    );
    return {
      ...collection,
      items: nextItems,
    };
  }
}

export default FloorplanCollection;
