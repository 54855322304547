import * as React from 'react';

const Lab = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.894 4L14.894 11L19.6087 19.1724C20.0658 20.178 19.6212 21.3637 18.6156 21.8207C18.3557 21.9389 18.0735 22 17.788 22L6 22C4.89543 22 4 21.1046 4 20C4 19.7145 4.06113 19.4323 4.17927 19.1724L8.89399 11L8.89399 4H7.89399V2L15.894 2V4H14.894ZM10.894 10L10.894 11H11.894V13H10.0491L8.31841 16L15.4696 16L12.894 11.5355L12.894 4L10.894 4V5L11.894 5V7H10.894V8H11.894V10H10.894Z"
      fill={color}
    />
  </svg>
);

export default Lab;
