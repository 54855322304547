import { FloorplanCoordinates } from 'lib/geometry';
import { GeoTiffTiepoint } from 'lib/geotiff';
import { FloorplanV2Plan } from 'lib/api';

type HeightMap = {
  url: string;
  objectKey: string;
  position: FloorplanCoordinates;
  rotation: number;
  opacity: number;
  notes: string;
  limits:
    | { enabled: false }
    | { enabled: true; minMeters: number; maxMeters: number };
  geotiffTransformationData:
    | { enabled: false }
    | { enabled: true; tiePoint: GeoTiffTiepoint; scale: number };
};

const HeightMap = {
  fromFloorplanAPIResponse(
    data: Pick<
      FloorplanV2Plan,
      | 'ceiling_raster_key'
      | 'ceiling_raster_url'
      | 'ceiling_raster_floorplan_origin_x'
      | 'ceiling_raster_floorplan_origin_y'
      | 'ceiling_raster_floorplan_angle_degrees'
      | 'ceiling_raster_opacity_percent'
      | 'ceiling_raster_notes'
      | 'ceiling_raster_min_height_limit_meters'
      | 'ceiling_raster_max_height_limit_meters'
    >
  ): HeightMap | null {
    if (!data.ceiling_raster_url || !data.ceiling_raster_key) {
      return null;
    }

    let limits: HeightMap['limits'] = { enabled: false };
    if (
      data.ceiling_raster_min_height_limit_meters > 0 &&
      data.ceiling_raster_max_height_limit_meters > 0
    ) {
      limits = {
        enabled: true,
        minMeters: data.ceiling_raster_min_height_limit_meters,
        maxMeters: data.ceiling_raster_max_height_limit_meters,
      };
    }

    return {
      url: data.ceiling_raster_url,
      objectKey: data.ceiling_raster_key,
      rotation: data.ceiling_raster_floorplan_angle_degrees || 0,
      position: FloorplanCoordinates.create(
        data.ceiling_raster_floorplan_origin_x || 0,
        data.ceiling_raster_floorplan_origin_y || 0
      ),
      opacity: data.ceiling_raster_opacity_percent || 100,
      notes: data.ceiling_raster_notes || '',
      limits,
      geotiffTransformationData: {
        enabled: false,
      },
    };
  },
  toFloorplanAPIResponse(heightMap: HeightMap | null) {
    if (!heightMap) {
      return {
        ceiling_raster_key: null,
        ceiling_raster_floorplan_origin_x: 0,
        ceiling_raster_floorplan_origin_y: 0,
        ceiling_raster_floorplan_angle_degrees: 0,
        ceiling_raster_opacity_percent: 0,
        ceiling_raster_notes: '',
        ceiling_raster_min_height_limit_meters: 0,
        ceiling_raster_max_height_limit_meters: 0,
      };
    }

    return {
      ceiling_raster_key: heightMap.objectKey,
      ceiling_raster_floorplan_origin_x: heightMap.position.x,
      ceiling_raster_floorplan_origin_y: heightMap.position.y,
      ceiling_raster_floorplan_angle_degrees: heightMap.rotation,
      ceiling_raster_opacity_percent: heightMap.opacity,
      ceiling_raster_notes: heightMap.notes,
      ceiling_raster_min_height_limit_meters: heightMap.limits.enabled
        ? heightMap.limits.minMeters
        : 0,
      ceiling_raster_max_height_limit_meters: heightMap.limits.enabled
        ? heightMap.limits.maxMeters
        : 0,
    };
  },
};

export default HeightMap;
