// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-checkbox-3GZ0J1GW{display:none}.dui-styles-module-label-1vj21kKa{box-sizing:border-box;display:flex;flex-direction:row;align-items:center;width:18px;height:18px;border-radius:4px;cursor:pointer;user-select:none;white-space:nowrap;border:1px solid #687380;background-color:transparent}.dui-styles-module-checkbox-3GZ0J1GW:disabled+label{cursor:default;color:#687380}.dui-styles-module-checkbox-3GZ0J1GW+label:before{content:' ';flex-shrink:0;flex-grow:0;display:block;width:18px;height:18px;padding-right:8px}.dui-styles-module-checkbox-3GZ0J1GW:checked+label{background-image:url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Crect width='24' height='24'%3E%3C/rect%3E%3Cpolygon fill='%23ffffff' fill-rule='nonzero' points='9.77 15.766 19.26 5.327 20.74 6.673 9.866 18.634 3.307 12.32 4.693 10.88'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E\");background-size:16px 16px;background-position:0px 0px;background-repeat:no-repeat;cursor:pointer}.dui-styles-module-checkbox-3GZ0J1GW:disabled+label{cursor:default;background-color:#C0C6CC}.dui-styles-module-checkbox-3GZ0J1GW:disabled:checked+label{background-image:url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Crect width='24' height='24'%3E%3C/rect%3E%3Cpolygon fill='%238E9299' fill-rule='nonzero' points='9.77 15.766 19.26 5.327 20.74 6.673 9.866 18.634 3.307 12.32 4.693 10.88'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E\")}\n", ""]);
// Exports
exports.locals = {
	"checkbox": "dui-styles-module-checkbox-3GZ0J1GW",
	"label": "dui-styles-module-label-1vj21kKa"
};
module.exports = exports;
