import {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import classnames from 'classnames';
import { Icons } from '@density/dust';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

import styles from './styles.module.scss';

import Button from 'components/button';
import HorizontalForm from 'components/horizontal-form';

import { Meters } from 'lib/units';
import Space, { SpaceValidation } from 'lib/space';
import { SensorValidation } from 'lib/sensor';

const WarningErrorContext = createContext<null | 'warning' | 'error'>(null);

export const ValidationSectionItem: React.FunctionComponent<{
  name: React.ReactNode;
}> = ({ name, children }) => {
  const [open, setOpen] = useState(children ? true : false);
  const isError = useContext(WarningErrorContext) === 'error';

  return (
    <div className={styles.validationSectionItem}>
      <div
        className={styles.validationSectionItemHeader}
        onClick={() => setOpen(!open)}
      >
        <span className={styles.validationSectionItemHeaderName}>
          <Icons.DangerWarning
            size={18}
            color={isError ? dust.Red400 : dust.Yellow400}
          />
          {name}
        </span>
        {children ? (
          <Button
            type="cleared"
            trailingIcon={
              open ? (
                <Icons.ChevronDown size={18} />
              ) : (
                <Icons.ChevronLeft size={18} />
              )
            }
            size="small"
            width={24}
            height={24}
            onClick={() => setOpen(!open)}
          />
        ) : null}
      </div>
      {open && children ? (
        <div className={styles.validationSectionItemBody}>{children}</div>
      ) : null}
    </div>
  );
};

const ValidationsList: React.FunctionComponent<{
  validations: Array<SpaceValidation | SensorValidation>;
}> = ({ validations }) => {
  const warnings = useMemo(
    () => validations.filter((v) => v.severity === 'warning'),
    [validations]
  );
  const errors = useMemo(
    () => validations.filter((v) => v.severity === 'error'),
    [validations]
  );

  const [warningsOpen, setWarningsOpen] = useState(
    warnings.length > 0 ? true : false
  );
  useEffect(() => {
    if (warnings.length === 0) {
      setWarningsOpen(false);
    } else {
      setWarningsOpen(true);
    }
  }, [warnings.length]);

  const [errorsOpen, setErrorsOpen] = useState(
    errors.length > 0 ? true : false
  );
  useEffect(() => {
    if (errors.length === 0) {
      setErrorsOpen(false);
    } else {
      setErrorsOpen(true);
    }
  }, [errors.length]);

  const warningsMarkup = warnings
    .map((validation) => {
      switch (validation.validationType) {
        case 'space.circularNotRecommended':
          return (
            <ValidationSectionItem name="Circular Space Not Recommended">
              Replace with a rectangular or polygonal space.
            </ValidationSectionItem>
          );
        case 'space.tooManyEdges':
          return (
            <ValidationSectionItem name="Polygon Too Complex">
              Polygon shapes should ideally be regular with less than{' '}
              {Space.MAX_RECOMMENDED_POLYGON_SPACE_EDGES + 1} sides - this
              polygon has {validation.numberOfEdges} sides.
            </ValidationSectionItem>
          );
        case 'sensor.coverageObstructedDueToCeiling':
          return (
            <ValidationSectionItem name="Sensor FOV obstructed">
              This sensor's field of view is partially obstructed by ceiling
              obstacles.
            </ValidationSectionItem>
          );
        case 'sensor.positionTooCloseToWall':
          return (
            <ValidationSectionItem name="Sensor close to wall">
              This sensor's position is very close to a wall.
            </ValidationSectionItem>
          );
        default:
          return (
            <span style={{ color: dust.Red400 }}>
              Unknown validation {validation.validationType}!
            </span>
          );
      }
    })
    .map((v, i) => (
      // TODO: come up with a better keying strategy here, maybe give every validation an id?
      <Fragment key={i}>{v}</Fragment>
    ));
  const hasWarnings = warningsMarkup.length > 0;

  const errorsMarkup = errors
    .map((validation) => {
      switch (validation.validationType) {
        case 'space.intersectsAnotherSpace':
          return (
            <ValidationSectionItem
              name={`Overlaps ${
                validation.intersectedSpaceIds.length === 1
                  ? 'another space'
                  : 'other spaces'
              }`}
            >
              This space overlaps {validation.intersectedSpaceIds.length} other{' '}
              {validation.intersectedSpaceIds.length === 1 ? 'space' : 'spaces'}
              .
            </ValidationSectionItem>
          );
        case 'space.spaceTooClose':
          return (
            <ValidationSectionItem
              name={`Too close to ${
                validation.nearbySpaces.length === 1
                  ? 'another space'
                  : 'other spaces'
              }`}
            >
              This space is very close to {validation.nearbySpaces.length} other{' '}
              {validation.nearbySpaces.length === 1 ? 'space' : 'spaces'}.
            </ValidationSectionItem>
          );
        case 'space.tooSmall':
          const [minWidthFeet, minWidthInches] = Meters.toFeetAndInches(
            Space.MIN_WIDTH
          );
          const [minHeightFeet, minHeightInches] = Meters.toFeetAndInches(
            Space.MIN_HEIGHT
          );
          return (
            <ValidationSectionItem name="Space too small">
              This space is below the {minWidthFeet}' {minWidthInches}" x{' '}
              {minHeightFeet}' {minHeightInches}" min space size.
            </ValidationSectionItem>
          );
        case 'space.circleSpaceTooSmall':
          const [minRadiusFeet, minRadiusInches] = Meters.toFeetAndInches(
            Space.MIN_RADIUS * 2
          );
          return (
            <ValidationSectionItem name="Space too small">
              This space is below the {minRadiusFeet}' {minRadiusInches}" min
              diameter.
            </ValidationSectionItem>
          );
        case 'space.selfIntersects':
          return (
            <ValidationSectionItem name="Space self intersects">
              This space intersects itself. This is not allowed.
            </ValidationSectionItem>
          );
        case 'sensor.positionTooCloseToWall':
          return (
            <ValidationSectionItem name="Sensor too close to wall">
              This sensor is too close to{' '}
              {validation.segmentsAndClosestPoint.length}{' '}
              {validation.segmentsAndClosestPoint.length === 1
                ? 'wall'
                : 'walls'}
              .
            </ValidationSectionItem>
          );
        case 'sensor.heightTooLow':
          return (
            <ValidationSectionItem name="Sensor too low">
              This sensor's height is too low.
            </ValidationSectionItem>
          );
        case 'sensor.heightTooHigh':
          return (
            <ValidationSectionItem name="Sensor too high">
              This sensor's height is too high.
            </ValidationSectionItem>
          );
        default:
          return (
            <span style={{ color: dust.Red400 }}>
              Unknown validation {validation.validationType}!
            </span>
          );
      }
    })
    .map((v, i) => (
      // TODO: come up with a better keying strategy here, maybe give every validation an id?
      <Fragment key={i}>{v}</Fragment>
    ));
  const hasErrors = errorsMarkup.length > 0;

  if (!hasWarnings && !hasErrors) {
    return null;
  }

  return (
    <div className={styles.validations}>
      {hasErrors ? (
        <Fragment>
          <div
            className={classnames(styles.validationsErrorsHeader, {
              [styles.clickable]: hasErrors,
            })}
            onClick={() => {
              if (hasErrors) {
                setErrorsOpen(!errorsOpen);
              }
            }}
          >
            <div className={styles.validationsErrorsName}>
              <div className={styles.validationsErrorsNameDot} />
              Errors
            </div>
            <HorizontalForm size="small">
              <div className={styles.validationsBadge}>
                {errorsMarkup.length}
              </div>
              <Button
                type="cleared"
                trailingIcon={
                  errorsOpen ? (
                    <Icons.ChevronDown size={18} />
                  ) : (
                    <Icons.ChevronLeft size={18} />
                  )
                }
                size="small"
                width={24}
                height={24}
                disabled={!hasErrors}
                onClick={() => setErrorsOpen(!errorsOpen)}
              />
            </HorizontalForm>
          </div>
          <WarningErrorContext.Provider value="error">
            {errorsOpen ? errorsMarkup : null}
          </WarningErrorContext.Provider>
        </Fragment>
      ) : null}

      {hasWarnings ? (
        <Fragment>
          <div
            className={classnames(styles.validationsWarningsHeader, {
              [styles.clickable]: hasWarnings,
            })}
            onClick={() => {
              if (hasWarnings) {
                setWarningsOpen(!warningsOpen);
              }
            }}
          >
            <div className={styles.validationsWarningsName}>
              <div className={styles.validationsWarningsNameDot} />
              Warnings
            </div>
            <HorizontalForm size="small">
              <div className={styles.validationsBadge}>
                {warningsMarkup.length}
              </div>
              <Button
                type="cleared"
                trailingIcon={
                  warningsOpen ? (
                    <Icons.ChevronDown size={18} />
                  ) : (
                    <Icons.ChevronLeft size={18} />
                  )
                }
                size="small"
                width={24}
                height={24}
                disabled={!hasWarnings}
                onClick={() => setWarningsOpen(!warningsOpen)}
              />
            </HorizontalForm>
          </div>
          <WarningErrorContext.Provider value="warning">
            {warningsOpen ? warningsMarkup : null}
          </WarningErrorContext.Provider>
        </Fragment>
      ) : null}
    </div>
  );
};

export default ValidationsList;
