import { useEffect, useRef, useMemo } from 'react';
import * as PIXI from 'pixi.js';
import { Blue400 } from '@density/dust/dist/tokens/dust.tokens';

import {
  ViewportCoordinates,
  FloorplanCoordinates,
  snapToAngle,
} from 'lib/geometry';
import { round, radiansToDegrees, degreesToRadians } from 'lib/math';

import {
  Layer,
  useFloorplanLayerContext,
  addDragHandler,
  toRawHex,
} from 'components/floorplan';
import { FloorplanLayerContextData } from 'components/floorplan/types';

const POINT_RADIUS_PX = 8;
const POINT_EXTENSION_LENGTH_PX = 0;

function computeTargetImageScale(
  imageWidth: number,
  imageHeight: number,
  upperLeft: FloorplanCoordinates,
  lowerRight: FloorplanCoordinates
): number {
  const originalDiagonalLengthInPixels = Math.hypot(imageWidth, imageHeight);
  const actualDiagonalLengthInMeters = Math.hypot(
    lowerRight.x - upperLeft.x,
    lowerRight.y - upperLeft.y
  );
  return actualDiagonalLengthInMeters / originalDiagonalLengthInPixels;
}

function computeTargetImageRotation(
  imageWidth: number,
  imageHeight: number,
  upperLeft: FloorplanCoordinates,
  lowerRight: FloorplanCoordinates
): number {
  const slope = (lowerRight.y - upperLeft.y) / (upperLeft.x - lowerRight.x);
  const initialAngle = Math.atan2(1, slope);

  // Calculate the angle that the diagonal makes
  // If width and height are the same, this will be 45 degrees
  const diagonalAngleInDegrees = radiansToDegrees(
    Math.atan2(imageHeight, imageWidth)
  );

  let angle = radiansToDegrees(initialAngle) - 90;
  // Subtract 45 degrees to normalize for the fact that the slope is the diagonal between two corners
  angle -= diagonalAngleInDegrees;
  // Take into account the sign of the trig function by adding half a turn in two quadrants
  if (lowerRight.x - upperLeft.x <= 0) {
    angle += 180;
  }

  while (angle < 360) {
    angle += 360;
  }
  while (angle >= 360) {
    angle -= 360;
  }

  return angle;
}

function calculateLowerRight(
  imageWidthInMeters: number,
  imageHeightInMeters: number,
  upperLeft: FloorplanCoordinates,
  rotationInDegrees: number,
  scale: number
): FloorplanCoordinates {
  const diagonalLengthInMeters = Math.hypot(
    imageWidthInMeters,
    imageHeightInMeters
  );
  const scaledDiagonalLengthInMeters = diagonalLengthInMeters * scale;

  // Calculate the angle that the diagonal makes
  // If width and height are the same, this will be 45 degrees
  const diagonalAngleInDegrees = radiansToDegrees(
    Math.atan2(imageHeightInMeters, imageWidthInMeters)
  );

  // Calculate the angle the image needs to be rotated
  const angleInRadians = degreesToRadians(
    360 - rotationInDegrees - diagonalAngleInDegrees
  );

  const diagonalOffsetXInMeters =
    Math.cos(angleInRadians) * scaledDiagonalLengthInMeters;
  const diagonalOffsetYInMeters =
    Math.sin(angleInRadians) * scaledDiagonalLengthInMeters;

  return FloorplanCoordinates.create(
    upperLeft.x + diagonalOffsetXInMeters,
    upperLeft.y - diagonalOffsetYInMeters
  );
}

function snapToDiagonalLength(
  imageWidth: number,
  imageHeight: number,
  center: FloorplanCoordinates,
  outer: FloorplanCoordinates,
  scale: number,
  isCenterInUpperLeft = false
): FloorplanCoordinates {
  // If not scalable
  // 1. Figure out the new angle the image was rotated to
  let newDiagonalAngleInDegrees = radiansToDegrees(
    Math.atan2(outer.y - center.y, outer.x - center.x)
  );
  if (!isCenterInUpperLeft) {
    newDiagonalAngleInDegrees += 180;
  }
  while (newDiagonalAngleInDegrees < 360) {
    newDiagonalAngleInDegrees += 360;
  }
  while (newDiagonalAngleInDegrees >= 360) {
    newDiagonalAngleInDegrees -= 360;
  }

  // 2. Figure out the old diagonal length
  const originalDiagonalLengthInPixels = Math.hypot(imageWidth, imageHeight);
  const diagonalLengthInMeters = originalDiagonalLengthInPixels * scale;

  // 3. Project from the point being rotated around along the angle for the proper
  // distance to get the other point
  const offsetX =
    Math.cos(degreesToRadians(newDiagonalAngleInDegrees)) *
    diagonalLengthInMeters;
  const offsetY =
    Math.sin(degreesToRadians(newDiagonalAngleInDegrees)) *
    diagonalLengthInMeters;
  return FloorplanCoordinates.create(
    center.x + (isCenterInUpperLeft ? offsetX : -1 * offsetX),
    center.y + (isCenterInUpperLeft ? offsetY : -1 * offsetY)
  );
}

const ImageRegistrationLayer: React.FunctionComponent<{
  imageWidth: number;
  imageHeight: number;
  createImage: (container: PIXI.Container) => void;
  updateImage?: (
    container: PIXI.Container,
    context: FloorplanLayerContextData
  ) => void;
  removeImage?: (container: PIXI.Container) => void;

  position: FloorplanCoordinates;
  rotationInDegrees: number;
  scale: number;

  isMovable?: boolean;
  isRotatable?: boolean;
  isScalable?: boolean;

  crosshairColor?: string;

  onDragMove?: (
    position: FloorplanCoordinates,
    scale: number,
    rotationInDegrees: number
  ) => void;
}> = ({
  imageWidth,
  imageHeight,
  createImage,
  updateImage = () => {},
  removeImage = () => {},
  position,
  rotationInDegrees,
  scale,
  isMovable = false,
  isRotatable = false,
  isScalable = false,
  crosshairColor = Blue400,
  onDragMove,
}) => {
  const context = useFloorplanLayerContext();

  // Round the scale to a high level of precision to get rid of floating point math uncertainty
  // If this isn't done, many of the useEffects / useCallbacks below will run on every render
  const roundedScale = round(scale, 8);

  const pointTexture = useMemo(() => {
    const gr = new PIXI.Graphics();

    gr.lineStyle({ width: 1, color: toRawHex(crosshairColor) });
    gr.drawCircle(0, 0, POINT_RADIUS_PX);

    const crosshairLength = POINT_RADIUS_PX + POINT_EXTENSION_LENGTH_PX;

    gr.moveTo(-1 * crosshairLength, 0);
    gr.lineTo(crosshairLength, 0);
    gr.moveTo(0, -1 * crosshairLength);
    gr.lineTo(0, crosshairLength);

    return context.app.renderer.generateTexture(gr);
  }, [crosshairColor, context.app]);

  // Store cached upper left and lower right positions
  const upperLeftPosition = useRef<FloorplanCoordinates>(position);
  const lowerRightPosition = useRef<FloorplanCoordinates | null>(null);
  useEffect(() => {
    upperLeftPosition.current = position;
    lowerRightPosition.current = calculateLowerRight(
      imageWidth,
      imageHeight,
      position,
      rotationInDegrees,
      roundedScale
    );
  }, [imageWidth, imageHeight, position, rotationInDegrees, roundedScale]);

  // Create image sprite on component mount
  useEffect(() => {
    const targetImageContainer = new PIXI.Container();
    targetImageContainer.width = imageWidth;
    targetImageContainer.height = imageHeight;

    targetImageContainer.name = 'target-image-container';
    targetImageContainer.interactive = isMovable;
    targetImageContainer.cursor = isMovable ? 'grab' : 'default';

    const callOnDragMove = () => {
      if (!onDragMove) {
        return;
      }
      if (!lowerRightPosition.current) {
        return;
      }
      onDragMove(
        upperLeftPosition.current,
        computeTargetImageScale(
          imageWidth,
          imageHeight,
          upperLeftPosition.current,
          lowerRightPosition.current
        ),
        computeTargetImageRotation(
          imageWidth,
          imageHeight,
          upperLeftPosition.current,
          lowerRightPosition.current
        )
      );
    };

    targetImageContainer.on('mousedown', (evt) => {
      if (!context.viewport.current) {
        return;
      }
      if (!lowerRightPosition.current) {
        return;
      }

      // Get the floorplan coordinate value of where a user clicked.
      const clickedPosition = ViewportCoordinates.toFloorplanCoordinates(
        ViewportCoordinates.create(
          evt.data.originalEvent.clientX,
          evt.data.originalEvent.clientY
        ),
        context.viewport.current,
        context.floorplan
      );

      const originalLowerLeftPosition = lowerRightPosition.current;
      const originalUpperRightPosition = upperLeftPosition.current;

      addDragHandler(
        context,
        clickedPosition,
        evt,
        (newPosition) => {
          if (!lowerRightPosition.current) {
            return;
          }
          targetImageContainer.cursor = 'grabbing';

          const changeInPositionX = newPosition.x - clickedPosition.x;
          const changeInPositionY = newPosition.y - clickedPosition.y;
          lowerRightPosition.current = FloorplanCoordinates.create(
            originalLowerLeftPosition.x + changeInPositionX,
            originalLowerLeftPosition.y + changeInPositionY
          );
          upperLeftPosition.current = FloorplanCoordinates.create(
            originalUpperRightPosition.x + changeInPositionX,
            originalUpperRightPosition.y + changeInPositionY
          );
        },
        () => {
          targetImageContainer.cursor = 'grab';
          callOnDragMove();
        }
      );
    });
    context.app.stage.addChild(targetImageContainer);

    createImage(targetImageContainer);

    const lowerRightPoint = new PIXI.Sprite(pointTexture);
    lowerRightPoint.name = 'lower-left-point';
    lowerRightPoint.anchor.set(0.5);
    lowerRightPoint.interactive = isScalable || isRotatable;
    lowerRightPoint.cursor = isScalable || isRotatable ? 'grab' : 'default';
    lowerRightPoint.on('mousedown', (evt) => {
      if (!lowerRightPosition.current) {
        return;
      }
      addDragHandler(
        context,
        lowerRightPosition.current,
        evt,
        (newPosition, _, evt) => {
          if (!lowerRightPosition.current) {
            return;
          }
          lowerRightPoint.cursor = 'grabbing';

          // When holding shift, snap to 45 degree angles
          if (evt.shiftKey) {
            const diagonalAngleInDegrees = radiansToDegrees(
              Math.atan2(imageHeight, imageWidth)
            );
            newPosition = snapToAngle(
              upperLeftPosition.current,
              newPosition,
              45,
              diagonalAngleInDegrees
            );
          }

          if (!isRotatable) {
            // TODO
          }

          if (!isScalable) {
            newPosition = snapToDiagonalLength(
              imageWidth,
              imageHeight,
              upperLeftPosition.current,
              newPosition,
              roundedScale,
              true
            );
          }

          lowerRightPosition.current = newPosition;
        },
        () => {
          lowerRightPoint.cursor = 'grab';
          callOnDragMove();
        }
      );
    });
    context.app.stage.addChild(lowerRightPoint);

    const upperLeftPoint = new PIXI.Sprite(pointTexture);
    upperLeftPoint.name = 'upper-right-point';
    upperLeftPoint.anchor.set(0.5);
    upperLeftPoint.interactive = isScalable || isRotatable;
    upperLeftPoint.cursor = isScalable || isRotatable ? 'grab' : 'default';
    upperLeftPoint.on('mousedown', (evt) => {
      addDragHandler(
        context,
        upperLeftPosition.current,
        evt,
        (newPosition, _, evt) => {
          if (!lowerRightPosition.current) {
            return;
          }
          upperLeftPoint.cursor = 'grabbing';

          // When holding shift, snap to 45 degree angles
          if (evt.shiftKey) {
            const diagonalAngleInDegrees = radiansToDegrees(
              Math.atan2(imageHeight, imageWidth)
            );
            newPosition = snapToAngle(
              lowerRightPosition.current,
              newPosition,
              45,
              diagonalAngleInDegrees
            );
          }

          if (!isRotatable) {
            // TODO
          }

          if (!isScalable) {
            newPosition = snapToDiagonalLength(
              imageWidth,
              imageHeight,
              lowerRightPosition.current,
              newPosition,
              roundedScale,
              false
            );
          }

          upperLeftPosition.current = newPosition;
        },
        () => {
          upperLeftPoint.cursor = 'grab';
          callOnDragMove();
        }
      );
    });
    context.app.stage.addChild(upperLeftPoint);

    return () => {
      context.app.stage.removeChild(lowerRightPoint);
      context.app.stage.removeChild(upperLeftPoint);
      if (targetImageContainer) {
        context.app.stage.removeChild(targetImageContainer);
      }
      removeImage(targetImageContainer);
    };
    // create-react-app wants `createImage` / `deleteImage` to be a dependency here, but this is
    // called immediately and thats it, and it changes very often.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    context,
    pointTexture,
    isMovable,
    isScalable,
    isRotatable,
    imageHeight,
    imageWidth,
    roundedScale,
    onDragMove,
  ]);

  return (
    <Layer
      onAnimationFrame={() => {
        if (!context.viewport.current) {
          return;
        }
        if (!lowerRightPosition.current) {
          return;
        }

        const lowerRightPositionViewport =
          FloorplanCoordinates.toViewportCoordinates(
            lowerRightPosition.current,
            context.floorplan,
            context.viewport.current
          );

        const upperLeftPositionViewport =
          FloorplanCoordinates.toViewportCoordinates(
            upperLeftPosition.current,
            context.floorplan,
            context.viewport.current
          );

        const lowerRightPoint = context.app.stage.getChildByName(
          'lower-left-point'
        ) as PIXI.Sprite;
        const upperLeftPoint = context.app.stage.getChildByName(
          'upper-right-point'
        ) as PIXI.Sprite;

        lowerRightPoint.x = lowerRightPositionViewport.x;
        lowerRightPoint.y = lowerRightPositionViewport.y;

        upperLeftPoint.x = upperLeftPositionViewport.x;
        upperLeftPoint.y = upperLeftPositionViewport.y;

        const targetImageContainer = context.app.stage.getChildByName(
          'target-image-container'
        ) as PIXI.Container;

        const targetImageContainerScale = computeTargetImageScale(
          imageWidth,
          imageHeight,
          upperLeftPosition.current,
          lowerRightPosition.current
        );

        const angleInDegrees = computeTargetImageRotation(
          imageWidth,
          imageHeight,
          upperLeftPosition.current,
          lowerRightPosition.current
        );

        targetImageContainer.x = upperLeftPositionViewport.x;
        targetImageContainer.y = upperLeftPositionViewport.y;
        targetImageContainer.scale.set(
          targetImageContainerScale *
            context.floorplan.scale *
            context.viewport.current.zoom
        );
        targetImageContainer.angle = angleInDegrees;

        updateImage(targetImageContainer, context);
      }}
    />
  );
};

export default ImageRegistrationLayer;
