// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-dashboardReportGrid-3rR40Z68{display:flex;flex-direction:row;width:100%}.dui-styles-module-dashboardReportGridColumn-4QM607di{flex:1;width:320px;display:flex;flex-direction:column;margin-left:12px;margin-right:12px}.dui-styles-module-dashboardReportGridColumn-4QM607di:first-of-type{margin-left:0px}.dui-styles-module-dashboardReportGridColumn-4QM607di:last-of-type{margin-right:0px}.dui-styles-module-dashboardReportGridCell-tyXaKGLi{margin-top:12px;margin-bottom:12px}\n", ""]);
// Exports
exports.locals = {
	"dashboardReportGrid": "dui-styles-module-dashboardReportGrid-3rR40Z68",
	"dashboardReportGridColumn": "dui-styles-module-dashboardReportGridColumn-4QM607di",
	"dashboardReportGridCell": "dui-styles-module-dashboardReportGridCell-tyXaKGLi"
};
module.exports = exports;
