import {
  Fragment,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  useState,
} from 'react';
import * as React from 'react';
import { v4 as uuid4 } from 'uuid';
import Mousetrap from 'mousetrap';
import robustPointInPolygon from 'robust-point-in-polygon';
import styles from './styles.module.scss';
import { Space2 } from '@density/dust/dist/tokens/dust.tokens';

import { Icons } from '@density/dust';
import AppBar from 'components/app-bar';
import { DarkTheme } from 'components/theme';
import Button from 'components/button';
import Floorplan from 'components/floorplan';
import HorizontalForm from 'components/horizontal-form';
import KeyboardShortcut from 'components/keyboard-shortcut';
import Panel, { PanelBody, PanelTitle } from 'components/panel';
import Popup from 'components/popup';
import FloorplanZoomControls from 'components/floorplan-zoom-controls';

import {
  FloorplanCoordinates,
  ImageCoordinates,
  computeBoundingRegionExtents,
  splitLineSegmentsWithEachOther,
} from 'lib/geometry';
import FloorplanType from 'lib/floorplan';
import { lineSegmentIntersection2d } from 'lib/algorithm';
import FloorplanCollection from 'lib/floorplan-collection';
import { LengthUnit } from 'lib/units';
import WallSegment from 'lib/wall-segment';
import { distance } from 'lib/math';
import { FixMe } from 'types/fixme';

import WallSegmentEditLayer from './wall-segment-edit-layer';
import WallEditorLineSegmentImport, {
  ImageLineSegmentImportActive,
} from './line-segment-import';

const SHOW_LABELBOX_SAMPLE_DATA_CHOICES = false;
// const SHOW_LABELBOX_SAMPLE_DATA_CHOICES = true;

export type WallsEditorTool = 'wall' | 'eraser' | 'doorway';

type ImageLineSegmentImport =
  | { active: false }
  | ({ active: true } & ImageLineSegmentImportActive);

const WallsEditor: React.FunctionComponent<{
  floorplan: FloorplanType;
  floorplanImage: HTMLImageElement;
  wallSegments?: FloorplanCollection<WallSegment>;
  displayUnit: LengthUnit;
  onCancel?: () => void;
  submitInProgress?: boolean;
  onSubmit: (walls: FloorplanCollection<WallSegment>) => void;
  initialImageLineSegmentImport?: ImageLineSegmentImport;
  onDeactivateImageLineSegmentImport?: () => void;
}> = ({
  floorplan,
  floorplanImage,
  wallSegments,
  displayUnit,
  onCancel,
  submitInProgress,
  onSubmit,
  initialImageLineSegmentImport = { active: false },
  onDeactivateImageLineSegmentImport,
}) => {
  const [instructionsOpen, setInstructionsOpen] = useState(true);

  const [tool, setTool] = useState<WallsEditorTool>('wall');
  const [toolHover, setToolHover] = useState<WallsEditorTool | null>(null);
  const [showConfirmNoWalls, setShowConfirmNoWalls] = useState<boolean>(false);

  const [localUndoStack, setLocalUndoStack] = useState<
    Array<FloorplanCollection<WallSegment>>
  >([FloorplanCollection.create()]);
  const [localUndoStackIndex, setLocalUndoStackIndex] = useState(0);

  const appendChangesToUndoStack = useCallback(
    (newWalls: FloorplanCollection<WallSegment>) => {
      setLocalUndoStack((localUndoStack) => [
        ...localUndoStack.slice(0, localUndoStackIndex + 1),
        newWalls,
      ]);
      setLocalUndoStackIndex(localUndoStackIndex + 1);
    },
    [localUndoStackIndex]
  );

  const performUndo = useCallback(() => {
    setLocalUndoStackIndex((i) => Math.max(0, i - 1));
  }, []);

  const performRedo = useCallback(() => {
    setLocalUndoStackIndex((i) => Math.min(localUndoStack.length - 1, i + 1));
  }, [localUndoStack]);

  const walls = useMemo(
    () => localUndoStack[localUndoStackIndex],
    [localUndoStack, localUndoStackIndex]
  );

  // When wall segments are updated outside the component, update the internal state
  useEffect(() => {
    const newWalls = wallSegments || FloorplanCollection.create();
    setLocalUndoStack([newWalls]);
    setLocalUndoStackIndex(0);
  }, [wallSegments]);

  const [labelBoxData, setLabelBoxData] = useState<any>([]);
  useEffect(() => {
    fetch('/labelbox.json')
      .then((response) => response.json())
      .then(setLabelBoxData);
  }, []);

  // Add keyboard shortcuts for changing the active wall editor tool
  useEffect(() => {
    const mousetrap = new Mousetrap();

    mousetrap.bind('1', () => setTool('wall'));
    mousetrap.bind('2', () => setTool('doorway'));
    mousetrap.bind('3', () => setTool('eraser'));
    mousetrap.bind(['command+z', 'ctrl+z'], (e) => {
      e.preventDefault();
      performUndo();
    });
    mousetrap.bind(['command+shift+z', 'ctrl+shift+z'], (e) => {
      e.preventDefault();
      performRedo();
    });

    return () => {
      mousetrap.reset();
    };
  }, [performUndo, performRedo]);

  const changeVertex = useCallback(
    (
      walls: FloorplanCollection<WallSegment>,
      oldPosition: FloorplanCoordinates,
      newPosition: FloorplanCoordinates
    ): FloorplanCollection<WallSegment> => {
      const segmentsWithPositionAMatching = FloorplanCollection.list(
        walls
      ).filter((wall) => {
        return (
          wall.positionA.x === oldPosition.x &&
          wall.positionA.y === oldPosition.y
        );
      });
      const segmentsWithPositionBMatching = FloorplanCollection.list(
        walls
      ).filter((wall) => {
        return (
          wall.positionB.x === oldPosition.x &&
          wall.positionB.y === oldPosition.y
        );
      });

      for (const wallSegment of segmentsWithPositionAMatching) {
        walls = FloorplanCollection.updateItem(walls, wallSegment.id, {
          positionA: newPosition,
        });
      }
      for (const wallSegment of segmentsWithPositionBMatching) {
        walls = FloorplanCollection.updateItem(walls, wallSegment.id, {
          positionB: newPosition,
        });
      }

      return walls;
    },
    []
  );

  const intersectSegmentsInBoundingRegion = useCallback(
    (
      walls: FloorplanCollection<WallSegment>,
      upperLeft: FloorplanCoordinates | null = null,
      lowerRight: FloorplanCoordinates | null = null
    ): FloorplanCollection<WallSegment> => {
      // Pre compute a list of wall segments in the bounding region so that large swaths of the wall
      // segment search space can be avoided.
      //
      // The assumption here is that segments that do not intersect the bounding region of
      // the new segments could never be intersected by these new segments.
      let wallSegmentsInBoundingRegion = FloorplanCollection.list(walls);
      if (upperLeft && lowerRight) {
        wallSegmentsInBoundingRegion =
          WallSegment.computeWallSegmentsInBoundingRegion(
            wallSegmentsInBoundingRegion,
            upperLeft,
            lowerRight
          );
      }
      const wallSegmentIdsInBoundingRegion = wallSegmentsInBoundingRegion.map(
        (s) => s.id
      );

      // Check to see if any segment intersects with any other segment. If it does, split both
      // segments so that they have an explicit point at the intersection they both share.
      const splitWallSegments = splitLineSegmentsWithEachOther(
        FloorplanCollection.list(walls),
        (wallSegment: WallSegment) => [
          wallSegment.positionA,
          wallSegment.positionB,
        ],
        (
          previousWallSegment,
          positionA: FloorplanCoordinates,
          positionB: FloorplanCoordinates
        ) => ({
          ...previousWallSegment,
          id: uuid4(),
          positionA,
          positionB,
        }),

        // Only split segments that are in the bounding region
        // bounding region
        (segmentA, segmentB) =>
          wallSegmentIdsInBoundingRegion.includes(segmentB.id) ||
          wallSegmentIdsInBoundingRegion.includes(segmentA.id)
      );

      // // Order segments from shortest to longest so that the segments which are longest are run
      // // last, meaning that they likely won't be re-adjusted later on by processing future segments
      // const wallSegmentsAndLengthsOrderedFromShortestToLongest = FloorplanCollection.list(walls).map((wallSegment): [WallSegment['id'], number] => {
      //   return [wallSegment.id, distance(wallSegment.positionA, wallSegment.positionB)];
      // }).sort((a, b) => a[1] - b[1]);

      // // Remove all segments that are zero length
      // for (const [wallSegmentId, lengthInMeters] of wallSegmentsAndLengthsOrderedFromShortestToLongest) {
      //   if (lengthInMeters === 0) {
      //     walls = FloorplanCollection.removeItem(walls, wallSegmentId);
      //   }
      // }

      // const wallSegmentIdsOrderedFromShortestToLongest = wallSegmentsAndLengthsOrderedFromShortestToLongest.map(([id]) => id);

      // // Find segments which are close to 90 degrees but not exactly, and snap these segments to be
      // // axis aligned
      // const ANGLE_SNAP_THRESHOLD_DEGREES = 2;
      // for (const wallSegmentId of wallSegmentIdsOrderedFromShortestToLongest) {
      //   const wallSegment = walls.items.get(wallSegmentId);
      //   if (!wallSegment) {
      //     continue;
      //   }

      //   let angleInDegrees = radiansToDegrees(
      //     Math.atan2(
      //       wallSegment.positionB.y - wallSegment.positionA.y,
      //       wallSegment.positionB.x - wallSegment.positionA.x
      //     )
      //   );
      //   let lengthInMeters = distance(wallSegment.positionA, wallSegment.positionB);
      //   let center = FloorplanCoordinates.create(
      //     (wallSegment.positionB.x + wallSegment.positionA.x) / 2,
      //     (wallSegment.positionB.y + wallSegment.positionA.y) / 2
      //   );
      //   let changed = false;

      //   if (angleInDegrees > 360-ANGLE_SNAP_THRESHOLD_DEGREES && angleInDegrees < ANGLE_SNAP_THRESHOLD_DEGREES) {
      //     angleInDegrees = 0;
      //     changed = true;
      //   } else if (angleInDegrees > 90-ANGLE_SNAP_THRESHOLD_DEGREES && angleInDegrees < 90+ANGLE_SNAP_THRESHOLD_DEGREES) {
      //     angleInDegrees = 90;
      //     changed = true;
      //   } else if (angleInDegrees > 180-ANGLE_SNAP_THRESHOLD_DEGREES && angleInDegrees < 180+ANGLE_SNAP_THRESHOLD_DEGREES) {
      //     angleInDegrees = 180;
      //     changed = true;
      //   } else if (angleInDegrees > 270-ANGLE_SNAP_THRESHOLD_DEGREES && angleInDegrees < 270+ANGLE_SNAP_THRESHOLD_DEGREES) {
      //     angleInDegrees = 270;
      //     changed = true;
      //   }

      //   if (!changed) {
      //     continue;
      //   }

      //   // Recompute the endpoint of each segment
      //   const newPositionA = FloorplanCoordinates.create(
      //     center.x - ((lengthInMeters/2) * Math.cos(degreesToRadians(angleInDegrees))),
      //     center.y - ((lengthInMeters/2) * Math.sin(degreesToRadians(angleInDegrees))),
      //   );
      //   const newPositionB = FloorplanCoordinates.create(
      //     center.x + ((lengthInMeters/2) * Math.cos(degreesToRadians(angleInDegrees))),
      //     center.y + ((lengthInMeters/2) * Math.sin(degreesToRadians(angleInDegrees))),
      //   );
      //   walls = changeVertex(walls, wallSegment.positionA, newPositionA);
      //   walls = changeVertex(walls, wallSegment.positionB, newPositionB);
      // }

      return FloorplanCollection.fromArray(splitWallSegments);
    },
    []
  );

  const addSegments = useCallback(
    (
      walls: FloorplanCollection<WallSegment>,
      segmentParams: Array<
        [FloorplanCoordinates, FloorplanCoordinates, boolean]
      >
    ): FloorplanCollection<WallSegment> => {
      const [upperLeft, lowerRight] = computeBoundingRegionExtents(
        segmentParams.flatMap((i) => [i[0], i[1]])
      );
      if (!upperLeft || !lowerRight) {
        return walls;
      }

      // Add new segments
      for (const [positionA, positionB, doorway] of segmentParams) {
        const newWallSegment = WallSegment.create(
          positionA,
          positionB,
          doorway
        );
        walls = FloorplanCollection.addItem(walls, newWallSegment);
      }

      return intersectSegmentsInBoundingRegion(walls, upperLeft, lowerRight);
    },
    [intersectSegmentsInBoundingRegion]
  );

  const clearAndReplaceSegments = useCallback(
    (wallSegments: Array<WallSegment>, computeIntersections = true) => {
      let walls: FloorplanCollection<WallSegment> =
        FloorplanCollection.create();

      for (const segment of wallSegments) {
        walls = FloorplanCollection.addItem(walls, segment);
      }

      // Check to see if any segment intersects with any other segment. If it does, split both
      // segments so that they have an explicit point at the intersection they both share.
      if (computeIntersections) {
        walls = intersectSegmentsInBoundingRegion(walls);
      }

      return walls;
    },
    [intersectSegmentsInBoundingRegion]
  );

  const splitSegment = useCallback(
    (
      walls: FloorplanCollection<WallSegment>,
      segmentId: WallSegment['id'],
      newMidpointPosition: FloorplanCoordinates
    ): FloorplanCollection<WallSegment> => {
      const segment = walls.items.get(segmentId);
      if (!segment) {
        return walls;
      }

      walls = FloorplanCollection.removeItem(walls, segmentId);

      const newSegmentA = WallSegment.create(
        segment.positionA,
        newMidpointPosition,
        segment.isDoorway
      );
      walls = FloorplanCollection.addItem(walls, newSegmentA);

      const newSegmentB = WallSegment.create(
        newMidpointPosition,
        segment.positionB,
        segment.isDoorway
      );
      walls = FloorplanCollection.addItem(walls, newSegmentB);

      return walls;
    },
    []
  );

  const removeSegments = useCallback(
    (
      walls: FloorplanCollection<WallSegment>,
      segments: Array<WallSegment>
    ): FloorplanCollection<WallSegment> => {
      for (const wallSegment of segments) {
        walls = FloorplanCollection.removeItem(walls, wallSegment.id);
      }

      return walls;
    },
    []
  );

  const removeSegmentSectionsInRegion = useCallback(
    (
      walls: FloorplanCollection<WallSegment>,
      positionA: FloorplanCoordinates,
      positionB: FloorplanCoordinates
    ): FloorplanCollection<WallSegment> => {
      const removalRegionVertices: Array<[number, number]> = [
        [positionA.x, positionA.y],
        [positionB.x, positionA.y],
        [positionB.x, positionB.y],
        [positionA.x, positionB.y],
      ];

      const removalRegionEdges: Array<[[number, number], [number, number]]> = [
        [
          [positionA.x, positionA.y],
          [positionB.x, positionA.y],
        ],
        [
          [positionB.x, positionA.y],
          [positionB.x, positionB.y],
        ],
        [
          [positionB.x, positionB.y],
          [positionA.x, positionB.y],
        ],
        [
          [positionA.x, positionB.y],
          [positionA.x, positionA.y],
        ],
      ];

      let wallsList = FloorplanCollection.list(walls);

      const upperLeft = FloorplanCoordinates.create(
        Math.min(positionA.x, positionB.x),
        Math.min(positionA.y, positionB.y)
      );
      const lowerRight = FloorplanCoordinates.create(
        Math.max(positionA.x, positionB.x),
        Math.max(positionA.y, positionB.y)
      );

      // Pre compute a list of wall segments in the bounding region so that large swaths of the wall
      // segment search space can be avoided.
      //
      // The assumption here is that segments that do not intersect the bounding region of
      // the new segments could never be intersected by these new segments.
      let wallSegmentsInBoundingRegion =
        WallSegment.computeWallSegmentsInBoundingRegion(
          wallsList,
          upperLeft,
          lowerRight
        );
      const wallSegmentIdsInBoundingRegion = wallSegmentsInBoundingRegion.map(
        (s) => s.id
      );

      // Step 1: Split all segments where they intersect with the edges of the removal region
      for (let index = 0; index < wallsList.length; index += 1) {
        const segment = wallsList[index];

        // Skip splitting segments that don't at least enter the removal region
        if (!wallSegmentIdsInBoundingRegion.includes(segment.id)) {
          continue;
        }

        for (const edge of removalRegionEdges) {
          const result = lineSegmentIntersection2d(
            [],
            [segment.positionA.x, segment.positionA.y],
            [segment.positionB.x, segment.positionB.y],
            edge[0],
            edge[1]
          );
          if (!result) {
            continue;
          }

          // This line was split by the removal region!
          const splitPoint = FloorplanCoordinates.create(result[0], result[1]);

          // Make sure that the split point isn't one of the end points of the segment
          if (
            segment.positionA.x === splitPoint.x &&
            segment.positionA.y === splitPoint.y
          ) {
            continue;
          }
          if (
            segment.positionB.x === splitPoint.x &&
            segment.positionB.y === splitPoint.y
          ) {
            continue;
          }

          // Remove the old segment
          walls = FloorplanCollection.removeItem(walls, segment.id);

          // And split it at the split point
          const newSegmentA = WallSegment.create(segment.positionA, splitPoint);
          if (distance(newSegmentA.positionA, newSegmentA.positionB) > 1e-6) {
            wallSegmentIdsInBoundingRegion.push(newSegmentA.id);
            walls = FloorplanCollection.addItem(walls, newSegmentA);
          }

          const newSegmentB = WallSegment.create(splitPoint, segment.positionB);
          if (distance(newSegmentB.positionA, newSegmentB.positionB) > 1e-6) {
            wallSegmentIdsInBoundingRegion.push(newSegmentB.id);
            walls = FloorplanCollection.addItem(walls, newSegmentB);
          }

          // Resetting index to -1 means that on the next loop iteration, it will be incremented and
          // the index will be zero. This restarts the line splititng process all over again with
          // the new line.
          index = -1;
          wallsList = FloorplanCollection.list(walls);
          break;
        }
      }

      // Step 2: Remove all segments that are completely within the removal region
      for (let index = 0; index < walls.items.size; index += 1) {
        const segment = FloorplanCollection.list(walls)[index];
        const positionAWithinRegion = robustPointInPolygon(
          removalRegionVertices,
          [segment.positionA.x, segment.positionA.y]
        );
        if (positionAWithinRegion === 1 /* 1 = outside of polygon */) {
          continue;
        }
        const positionBWithinRegion = robustPointInPolygon(
          removalRegionVertices,
          [segment.positionB.x, segment.positionB.y]
        );
        if (positionBWithinRegion === 1 /* 1 = outside of polygon */) {
          continue;
        }

        // Both end points are within the polygon, so delete the segment
        walls = FloorplanCollection.removeItem(walls, segment.id);
        index -= 1;
      }
      return walls;
    },
    []
  );

  // Expose a reference to the floorplan imperative interface globally so that cypress can
  // interrogate the floorplan component when tests are running
  const floorplanRef = useRef<FixMe | null>(null);
  if (
    process.env.REACT_APP_ENABLE_EDITOR_GET_STATE &&
    process.env.REACT_APP_ENABLE_EDITOR_GET_STATE.toLowerCase() === 'true'
  ) {
    (window as any).wallsGetFloorplanRef = () => floorplanRef;
    (window as any).wallsGetWalls = () => walls;
    (window as any).wallsGetActiveTool = () => tool;
  }

  if (!floorplanImage) {
    return null;
  }

  if (initialImageLineSegmentImport.active) {
    return (
      <WallEditorLineSegmentImport
        floorplan={floorplan}
        floorplanImage={floorplanImage}
        displayUnit={displayUnit}
        data={initialImageLineSegmentImport}
        onCancel={onCancel}
        onSubmit={(wallSegments) => {
          const walls = clearAndReplaceSegments(wallSegments, false);
          setLocalUndoStack([walls]);
          setLocalUndoStackIndex(0);

          if (onDeactivateImageLineSegmentImport) {
            onDeactivateImageLineSegmentImport();
          }
        }}
      />
    );
  }

  const isCreating = typeof wallSegments === 'undefined';

  const createWallsTitle = showConfirmNoWalls ? '' : 'Create Walls';

  return (
    <Fragment>
      <DarkTheme>
        <AppBar
          title={isCreating ? createWallsTitle : 'Edit Walls'}
          leftActions={
            <HorizontalForm size="medium">
              <Popup
                open={toolHover === 'wall'}
                onClose={() => setToolHover(null)}
                popupWidth={320}
                target={
                  <Button
                    size="medium"
                    type={tool === 'wall' ? 'filled' : 'cleared'}
                    onClick={() => setTool('wall')}
                    trailingIcon={<Icons.DrawFreehand size={18} />}
                    onMouseEnter={() => setToolHover('wall')}
                    onMouseLeave={() => setToolHover(null)}
                  />
                }
              >
                <KeyboardShortcut>1</KeyboardShortcut>
                <strong>Wall Tool:</strong> Click to draw a polyline, or click
                and drag to draw a rectangle.
              </Popup>
              <Popup
                open={toolHover === 'doorway'}
                onClose={() => setToolHover(null)}
                popupWidth={320}
                target={
                  <Button
                    size="medium"
                    type={tool === 'doorway' ? 'filled' : 'cleared'}
                    onClick={() => setTool('doorway')}
                    trailingIcon={<Icons.DoorEntry size={18} />}
                    onMouseEnter={() => setToolHover('doorway')}
                    onMouseLeave={() => setToolHover(null)}
                  />
                }
              >
                <KeyboardShortcut>2</KeyboardShortcut>
                <strong>Doorway Tool:</strong> Click a vertex to place a doorway
                between pre-existing walls.
              </Popup>
              <Popup
                open={toolHover === 'eraser'}
                onClose={() => setToolHover(null)}
                popupWidth={320}
                target={
                  <Button
                    size="medium"
                    type={tool === 'eraser' ? 'filled' : 'cleared'}
                    onClick={() => setTool('eraser')}
                    trailingIcon={<Icons.EraseDelete size={18} />}
                    onMouseEnter={() => setToolHover('eraser')}
                    onMouseLeave={() => setToolHover(null)}
                  />
                }
              >
                <KeyboardShortcut>3</KeyboardShortcut>
                <strong>Eraser Tool:</strong> Click and drag to delete all
                segments in a region.
              </Popup>
              <span className={styles.seperator} />
              <Button
                size="medium"
                type="cleared"
                trailingIcon={<Icons.Undo size={18} />}
                onClick={() => performUndo()}
                disabled={localUndoStackIndex === 0}
              />
              <Button
                size="medium"
                type="cleared"
                trailingIcon={<Icons.Redo size={18} />}
                onClick={() => performRedo()}
                disabled={localUndoStackIndex === localUndoStack.length - 1}
              />
            </HorizontalForm>
          }
          actions={
            <Fragment>
              {showConfirmNoWalls ? (
                // Show a warning when saving if no walls are specified
                <HorizontalForm size="medium">
                  <em
                    style={{ marginRight: Space2 }}
                    data-cy="walls-empty-warning"
                  >
                    Are you sure? Planner won't work as effectively without
                    annotated walls!
                  </em>
                  <Button
                    size="medium"
                    type="hollow"
                    onClick={() => setShowConfirmNoWalls(false)}
                    disabled={submitInProgress}
                    data-cy="walls-cancel"
                  >
                    Cancel
                  </Button>
                  <Button
                    size="medium"
                    onClick={() => {
                      setShowConfirmNoWalls(false);
                      onSubmit(walls);
                    }}
                    data-cy="walls-submit"
                  >
                    Continue
                  </Button>
                </HorizontalForm>
              ) : (
                <HorizontalForm size="medium">
                  {onCancel ? (
                    <Button
                      size="medium"
                      type="cleared"
                      disabled={submitInProgress}
                      onClick={() => {
                        if (!onCancel) {
                          return;
                        }
                        onCancel();
                      }}
                      data-cy="walls-cancel"
                    >
                      Cancel
                    </Button>
                  ) : null}
                  <Button
                    size="medium"
                    onClick={() => {
                      // If during the creation flow walls weren't entered, then warn the user
                      // because walls are really important!
                      if (isCreating && FloorplanCollection.isEmpty(walls)) {
                        setShowConfirmNoWalls(true);
                        return;
                      }
                      onSubmit(walls);
                    }}
                    disabled={submitInProgress}
                    data-cy="walls-submit"
                  >
                    {submitInProgress ? 'Loading...' : 'Submit'}
                  </Button>
                </HorizontalForm>
              )}
            </Fragment>
          }
        />
      </DarkTheme>

      <div
        style={{ position: 'relative', width: '100%', height: '100%' }}
        data-cy="walls-editor-wrapper"
      >
        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
          <Floorplan
            ref={floorplanRef}
            image={floorplanImage}
            floorplan={floorplan}
            width="100%"
            height="100%"
            lengthUnit={displayUnit}
          >
            <WallSegmentEditLayer
              walls={FloorplanCollection.list(walls)}
              tool={tool}
              onAddWallSegments={(segmentParams) => {
                appendChangesToUndoStack(addSegments(walls, segmentParams));
              }}
              onSplitSegment={(segment, newMidpointPosition) => {
                appendChangesToUndoStack(
                  splitSegment(walls, segment.id, newMidpointPosition)
                );
              }}
              onChangeVertexPosition={(oldPosition, newPosition) => {
                appendChangesToUndoStack(
                  changeVertex(walls, oldPosition, newPosition)
                );
              }}
              onDeleteWallSegments={(segments) => {
                appendChangesToUndoStack(removeSegments(walls, segments));
              }}
              onRemoveSegmentSectionsInRegion={(positionA, positionB) => {
                appendChangesToUndoStack(
                  removeSegmentSectionsInRegion(walls, positionA, positionB)
                );
              }}
            />
          </Floorplan>
        </div>

        {SHOW_LABELBOX_SAMPLE_DATA_CHOICES ? (
          <Panel position="top-right" width={250} top={8}>
            <PanelTitle
              dark
              icon={
                instructionsOpen ? (
                  <Icons.ChevronDown size={18} color="currentColor" />
                ) : (
                  <Icons.ChevronUp size={18} color="currentColor" />
                )
              }
              onClick={() => setInstructionsOpen((s) => !s)}
            >
              Sample Data
            </PanelTitle>
            {instructionsOpen ? (
              <PanelBody>
                <select
                  style={{ width: 150 }}
                  onChange={(event) => {
                    if (event.target.value === '') {
                      appendChangesToUndoStack(clearAndReplaceSegments([]));
                      return;
                    }

                    // Convert the labelbox data into walls
                    const entry = labelBoxData.find(
                      (i: any) => i['ID'] === event.target.value
                    );
                    const polygons = entry['Label']['objects'].map(
                      (i: any) => i.polygon
                    );

                    const wallSegments = polygons.flatMap((polygon: any) => {
                      const segments: Array<WallSegment> = [];

                      let firstPoint: FloorplanCoordinates | null = null;
                      let lastPoint: FloorplanCoordinates | null = null;
                      for (const rawPoint of polygon) {
                        const point = ImageCoordinates.toFloorplanCoordinates(
                          ImageCoordinates.create(rawPoint.x, rawPoint.y),
                          floorplan
                        );
                        if (!lastPoint) {
                          lastPoint = point;
                          firstPoint = point;
                          continue;
                        }
                        segments.push(WallSegment.create(lastPoint, point));
                        lastPoint = point;
                      }
                      if (firstPoint && lastPoint) {
                        segments.push(
                          WallSegment.create(lastPoint, firstPoint)
                        );
                      }

                      return segments;
                    });

                    const walls = clearAndReplaceSegments(wallSegments, false);
                    appendChangesToUndoStack(walls);
                  }}
                >
                  <option value="" key="">
                    Clear
                  </option>
                  {labelBoxData.map((entry: any) => (
                    <option value={entry['ID']} key={entry['ID']}>
                      {entry['ID']}
                    </option>
                  ))}
                </select>
              </PanelBody>
            ) : null}
          </Panel>
        ) : null}

        <FloorplanZoomControls
          onZoomToFitClick={() => {
            if (floorplanRef.current) {
              floorplanRef.current.zoomToFit();
            }
          }}
        />
      </div>
    </Fragment>
  );
};

export default WallsEditor;
