import * as React from 'react';
import { Fragment } from 'react';
import { Icons } from '@density/dust';
import { Blue400 } from '@density/dust/dist/tokens/dust.tokens';

import styles from './styles.module.scss';

import Button from 'components/button';
import AppBar from 'components/app-bar';
import { DarkTheme } from 'components/theme';
import HorizontalForm from 'components/horizontal-form';
import TextField from 'components/text-field';
import Floorplan from 'components/floorplan';
import FloorplanZoomControls from 'components/floorplan-zoom-controls';
import FloorplanScaleLayer from 'components/editor/floorplan-layers/floorplan-scale-layer';
import Panel, { PanelBody, PanelTitle } from 'components/panel';
import { FixMe } from 'types/fixme';
import { ImageCoordinates } from 'lib/geometry';
import { Meters } from 'lib/units';
import Measurement from 'lib/measurement';

const SIDEBAR_WIDTH_IN_PIXELS = 224;
const SIDEBAR_WIDTH_ZOOM_SPACING_IN_PIXELS = 32;

type MeasurementTool = {
  pointA: ImageCoordinates;
  pointB: ImageCoordinates;
};

namespace MeasurementTool {
  export function getLengthInPixels(tool: MeasurementTool) {
    const x = tool.pointB.x - tool.pointA.x;
    const y = tool.pointB.y - tool.pointA.y;
    return Math.hypot(x, y);
  }
}

type Props = {
  image: HTMLImageElement;
  measurement?: Measurement;
  submitInProgress?: boolean;
  onSubmit: (result: Measurement) => void;
  onCancel?: () => void;
};
type State = {
  measurementTool: MeasurementTool;
  feetText: string;
  inchesText: string;
  validationError: null | { message: string };
  measurement?: Measurement;
};
class FloorplanMeasure extends React.Component<Props, State> {
  private floorplanRef: FixMe;

  private viewportContainerRef: React.RefObject<HTMLDivElement> =
    React.createRef();

  constructor(props: Props) {
    super(props);

    this.floorplanRef = React.createRef();

    let measurementTool: MeasurementTool, feetText: string, inchesText: string;

    // populate state with existing measurement (likely coming from the editor)
    if (props.measurement) {
      measurementTool = {
        pointA: props.measurement.pointA,
        pointB: props.measurement.pointB,
      };
      feetText = props.measurement.userEnteredLength.feetText || '0';
      inchesText = props.measurement.userEnteredLength.inchesText || '0';
    }
    // creating a new measurement, populate state with defaults
    else {
      measurementTool = {
        pointA: ImageCoordinates.create(0, 0),
        pointB: ImageCoordinates.create(props.image.width, 0),
      };
      feetText = '0';
      inchesText = '0';
    }

    this.state = {
      measurementTool,
      feetText,
      inchesText,
      validationError: null,
    };
  }

  componentDidMount() {
    this.floorplanRef.current.zoomToFitWithSidebar(
      SIDEBAR_WIDTH_IN_PIXELS + SIDEBAR_WIDTH_ZOOM_SPACING_IN_PIXELS
    );

    if (
      process.env.REACT_APP_ENABLE_EDITOR_GET_STATE &&
      process.env.REACT_APP_ENABLE_EDITOR_GET_STATE.toLowerCase() === 'true'
    ) {
      (window as any).measureGetFloorplanRef = () => this.floorplanRef;
    }
  }

  onSubmitClick = () => {
    const { feetText, inchesText } = this.state;
    const feet = Number(feetText);
    const inches = Number(inchesText);
    if (Number.isNaN(feet)) {
      this.setState({
        validationError: {
          message: 'Feet field must be numeric.',
        },
      });
      return;
    }
    if (Number.isNaN(inches)) {
      this.setState({
        validationError: {
          message: 'Inches field must be numeric.',
        },
      });
      return;
    }
    const computedLength = Meters.fromFeetAndInches(feet, inches);
    if (computedLength === 0) {
      this.setState({
        validationError: {
          message: 'Measured length cannot be zero.',
        },
      });
      return;
    }
    // TODO: what should the minimum allowable length be? For now, it is 2 feet.
    if (computedLength < Meters.fromFeet(2)) {
      this.setState({
        validationError: {
          message: 'Measured length is too short.',
        },
      });
      return;
    }

    const lengthInPixels = MeasurementTool.getLengthInPixels(
      this.state.measurementTool
    );
    const { pointA, pointB } = this.state.measurementTool;

    // pixels-per-meter
    const computedScale = lengthInPixels / computedLength;

    const result: Measurement = {
      pointA,
      pointB,
      userEnteredLength: {
        feetText,
        inchesText,
      },
      computedLength,
      computedScale,
    };

    this.props.onSubmit(result);
  };

  displayComputedLength = () => {
    // FIXME: really duplicative and awful
    const { feetText, inchesText } = this.state;
    const feet = Number(feetText);
    const inches = Number(inchesText);
    if (Number.isNaN(feet)) {
      return 'Feet field must be numeric.';
    }
    if (Number.isNaN(inches)) {
      return 'Inches field must be numeric.';
    }
    const computedLengthMeters = Meters.fromFeetAndInches(feet, inches);
    const [computedFeet, computedInches] =
      Meters.toFeetAndInches(computedLengthMeters);

    return `${computedFeet}' ${computedInches}"`;
  };

  render() {
    const { image } = this.props;
    const { measurementTool, feetText, inchesText } = this.state;

    const feet = Number(feetText);
    const inches = Number(inchesText);
    if (Number.isNaN(feet)) {
      return null;
    }
    if (Number.isNaN(inches)) {
      return null;
    }

    const computedLength = Meters.fromFeetAndInches(feet, inches);
    const lengthInPixels = MeasurementTool.getLengthInPixels(
      this.state.measurementTool
    );

    // pixels-per-meter
    const computedScale = lengthInPixels / computedLength;

    return (
      <Fragment>
        <DarkTheme>
          <AppBar
            title="Scale Floorplan"
            actions={
              <HorizontalForm size="medium">
                {this.props.onCancel ? (
                  <Button
                    size="medium"
                    type="cleared"
                    onClick={this.props.onCancel}
                    disabled={this.props.submitInProgress}
                  >
                    Cancel
                  </Button>
                ) : null}

                <Button
                  type="filled"
                  onClick={this.onSubmitClick}
                  data-cy="measurement-submit"
                  size="medium"
                  disabled={this.props.submitInProgress}
                >
                  {this.props.submitInProgress ? 'Loading...' : 'Submit'}
                </Button>
              </HorizontalForm>
            }
          />
        </DarkTheme>

        <div className={styles.mainView}>
          <div
            ref={this.viewportContainerRef}
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          >
            <Floorplan
              ref={this.floorplanRef}
              image={image}
              floorplan={{
                width: image.width,
                height: image.height,
                origin: ImageCoordinates.create(0, 0),
                // The scale is not really important here, since FloorplanScaleLayer works in ImageCoordinates (which are pixels)
                scale: 1,
                rotation: 0,
              }}
              width="100%"
              height="100%"
              lengthUnit={'feet_and_inches'}
            >
              <FloorplanScaleLayer
                measurement={{
                  pointA: measurementTool.pointA,
                  pointB: measurementTool.pointB,
                  userEnteredLength: { feetText, inchesText },
                  computedLength, // meters
                  computedScale, // pixels-per-meter
                }}
                onEndpointsMoved={(pointA, pointB) => {
                  this.setState((prevState) => {
                    return {
                      measurementTool: {
                        ...prevState.measurementTool,
                        pointA,
                        pointB,
                      },
                    };
                  });
                }}
              />
            </Floorplan>
          </div>

          <Panel position="top-left" width={SIDEBAR_WIDTH_IN_PIXELS}>
            <PanelTitle icon={<Icons.DepartingArrow size={18} />}>
              Scale Floorplan
            </PanelTitle>

            <PanelBody>
              <p>
                Use the{' '}
                <span style={{ color: Blue400, fontWeight: 'bold' }}>
                  Measurement
                </span>{' '}
                tool.
              </p>
              <p>
                Drag the ends to mark a known length &mdash; Ideally, as long as
                possible to reduce error.
              </p>
              <p>Hold shift to snap to 45&deg; angles.</p>
              <p>
                Then, set the scale of the floorplan by entering the length
                below:
              </p>

              <div className={styles.sensorHeightFormGroup}>
                <div className={styles.sensorHeightFormFields}>
                  <div className={styles.inputInlineIcon}>
                    <Icons.Ruler size={18} />
                  </div>
                  <TextField
                    type="number"
                    size="medium"
                    width={50}
                    step={1}
                    value={this.state.feetText}
                    onChange={(evt) => {
                      const feetText = evt.currentTarget.value;
                      this.setState({ feetText });
                    }}
                    data-cy="measurement-feet"
                  />
                  <label className={styles.sensorHeightInputLabel}>ft.</label>
                  <TextField
                    type="number"
                    size="medium"
                    width={40}
                    step={1}
                    value={this.state.inchesText}
                    onChange={(evt) => {
                      const inchesText = evt.currentTarget.value;
                      this.setState({ inchesText });
                    }}
                    data-cy="measurement-inches"
                  />
                  <label className={styles.sensorHeightInputLabel}>in.</label>
                </div>
                {/* .sensorHeightInputFieldss */}
                <div className={styles.sensorHeightValue}>
                  {this.displayComputedLength()}
                </div>
              </div>
              {/* .sensorHeightFormGroup */}
            </PanelBody>

            {this.state.validationError ? (
              <div className={styles.validationErrorText}>
                {this.state.validationError.message}
              </div>
            ) : null}
          </Panel>

          <FloorplanZoomControls
            onZoomToFitClick={() => {
              if (this.floorplanRef.current) {
                this.floorplanRef.current.zoomToFit();
              }
            }}
          />
        </div>
      </Fragment>
    );
  }
}

export default React.memo(FloorplanMeasure);
