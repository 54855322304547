// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-appSidebar-3YIiDKsO{box-sizing:border-box;display:flex;flex-direction:column;flex-shrink:0;flex-grow:0;width:415px;height:100%;border-right:1px solid #E0E3E6;overflow:hidden}.dui-styles-module-appSidebarCollapser-36aZJWBz{flex-shrink:0;flex-grow:0;width:0px;height:100%;overflow:hidden;transition:all 200ms ease-in-out}@media (min-width: 991px){.dui-styles-module-appSidebarCollapser-36aZJWBz.dui-styles-module-visible-3-AdJ3HP{width:415px}}\n", ""]);
// Exports
exports.locals = {
	"appSidebar": "dui-styles-module-appSidebar-3YIiDKsO",
	"appSidebarCollapser": "dui-styles-module-appSidebarCollapser-36aZJWBz",
	"visible": "dui-styles-module-visible-3-AdJ3HP"
};
module.exports = exports;
