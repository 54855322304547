import React from "react";

const Danger = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M12,0.845299462 L21.660254,6.42264973 L21.660254,17.5773503 L12,23.1547005 L2.33974596,17.5773503 L2.33974596,6.42264973 L12,0.845299462 Z M12,3.15470054 L4.33974596,7.57735027 L4.33974596,16.4226497 L12,20.8452995 L19.660254,16.4226497 L19.660254,7.57735027 L12,3.15470054 Z M12,15 C12.8284271,15 13.5,15.6715729 13.5,16.5 C13.5,17.3284271 12.8284271,18 12,18 C11.1715729,18 10.5,17.3284271 10.5,16.5 C10.5,15.6715729 11.1715729,15 12,15 Z M13,6 L13,13 L11,13 L11,6 L13,6 Z"
      />
    </g>
  </svg>
);

export default Danger;
