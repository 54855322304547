import React from "react";

const Refresh = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M12,3 C16.9705627,3 21,7.02943725 21,12 C21,14.7825811 19.7253702,17.3292679 17.6572485,19.0015077 L20,19 L20,21 L14,21 L14,15 L16,15 L16.0007533,17.7459979 C17.8430335,16.4622437 19,14.3382643 19,12 C19,8.13400675 15.8659932,5 12,5 L12,3 Z M10,3 L10,9 L8,9 L8.00022702,6.25325168 C6.15854353,7.53619925 5,9.6601984 5,12 C5,15.8659932 8.13400675,19 12,19 L12,21 C7.02943725,21 3,16.9705627 3,12 C3,9.21647518 4.27547152,6.67044982 6.34209948,4.99897404 L4,5 L4,3 L10,3 Z"
      />
    </g>
  </svg>
);

export default Refresh;
