import { ProcessedCADSensorPlacement } from 'components/editor/state';
import FloorplanType from 'lib/floorplan';
import {
  FloorplanCoordinates,
  CADCoordinates,
  ImageCoordinates,
} from 'lib/geometry';

import { distance } from 'lib/math';
import { LengthUnit, CONVERT_TO_METERS } from 'lib/units';
import PlanSensor from 'lib/sensor';

// How close do two sensors need to be to be considered at the same position?
//
// This value in a perfect world should be zero, but a non zero value allows for a little bit of
// inaccuracy in registration / in the DXF
const CAD_DISTANCE_EQUALITY_THRESHOLD_METERS = 0.05;

export const DEFAULT_CAD_FILE_LENGTH_UNIT: LengthUnit = 'inches';

// A FloorplanChange represents an element of the diff between data currently on the floorplan, and
// data within a CAD file.
type FloorplanSensorAddition = {
  type: 'addition';
  data: ProcessedCADSensorPlacement;
};
type FloorplanSensorDeletion = {
  type: 'deletion';
  data: PlanSensor;
};
type FloorplanSensorModification = {
  type: 'modification';
  data: ProcessedCADSensorPlacement;
  oldData: PlanSensor;
};
type FloorplanSensorNoChange = {
  type: 'no-change';
  data: PlanSensor;
};

export type FloorplanChange =
  | FloorplanSensorAddition
  | FloorplanSensorDeletion
  | FloorplanSensorModification
  | FloorplanSensorNoChange;

// When importing a cad drawing, the import can either use:
// 1. Sensor metadata from the dxf file
// 2. Floorplan metadata from the dxf file
// 3. Both #1 and #2
export type CADImportOperationType = 'floorplan' | 'sensors' | 'both';

// The default CAD origin is in the lower left hand corner of the floorplan.
export function computeDefaultCADOrigin(
  floorplan: FloorplanType
): FloorplanCoordinates {
  const floorplanHeightInMeters = floorplan.height / floorplan.scale;
  return FloorplanCoordinates.create(0, floorplanHeightInMeters);
}

// Given an old floorplan and some metadata, compute the new "floorplan" object that contains scale,
// etc for rendering the new floorplan at it's native resolution.
export function computeNewFloorplanForCAD(
  oldFloorplan: FloorplanType,
  image: HTMLImageElement,
  floorplanCADOrigin: FloorplanCoordinates,
  cadFileUnit: LengthUnit,
  cadFileScale: number,
  imageResizeScale: number // The scale factor applied to resize the image to be 4096 wide at max
): FloorplanType {
  const coordA = CADCoordinates.toFloorplanCoordinates(
    CADCoordinates.create(0, 0),
    oldFloorplan,
    floorplanCADOrigin,
    cadFileUnit,
    cadFileScale
  );
  const coordB = CADCoordinates.toFloorplanCoordinates(
    CADCoordinates.create(1, 0),
    oldFloorplan,
    floorplanCADOrigin,
    cadFileUnit,
    cadFileScale
  );

  const metersPerCADUnit = coordB.x - coordA.x;
  const cadImageScale = metersPerCADUnit * oldFloorplan.scale;
  const scale = oldFloorplan.scale / cadImageScale;

  return {
    width: image.width,
    height: image.height,
    scale: scale * imageResizeScale,
    origin: ImageCoordinates.create(0, 0),
    rotation: 0,
  };
}

// Given a list of sensors on the floorplan and a list of sensor placements in the CAD file, compute
// the difference between the two. This function powers the "diff" seen in the CAD import process.
export function computeFloorplanChanges(
  rawCadData: Array<ProcessedCADSensorPlacement>,
  sensors: Array<PlanSensor>,
  oldFloorplan: FloorplanType,
  floorplanCADOrigin: FloorplanCoordinates,
  operationType: CADImportOperationType,
  cadFileUnit: LengthUnit,
  cadFileScale: number
): Array<FloorplanChange> {
  // If only the floorplan is being swapped, then gray out existing sensors
  if (operationType === 'floorplan') {
    return sensors.map((data) => ({ type: 'no-change' as const, data }));
  }

  const newData = rawCadData.filter((s) => s.cadId.length > 0);
  const newDataCadIds = newData.map((s) => s.cadId);

  const oldData = sensors.filter((s) => s.cadId.length > 0);
  const oldDataCadIds = oldData.map((s) => s.cadId);

  // Get all new rows in `newData` that are not in `oldData` - these are additions.
  const additions = newData.filter((s) => !oldDataCadIds.includes(s.cadId));
  // Vice versa for deletions
  const deletions = oldData.filter((s) => !newDataCadIds.includes(s.cadId));
  // So all other rows must be modifications
  const additionsAndDeletionsCadIds = [
    ...additions.map((s) => s.cadId),
    ...deletions.map((s) => s.cadId),
  ];
  const modifications: Array<ProcessedCADSensorPlacement> = [];
  const unmodified: Array<PlanSensor> = [];
  for (const s of newData) {
    // Filter out additions / deletions
    if (additionsAndDeletionsCadIds.includes(s.cadId)) {
      continue;
    }

    // Find existing sensor row
    const existingSensor = oldData.find((i) => i.cadId === s.cadId);
    if (!existingSensor) {
      // This should be impossible, additions were filtered out above
      continue;
    }

    // Check to see if any fields aren't equal
    let isModification = false;
    for (const key of Object.keys(s)) {
      const castedKey = key as keyof ProcessedCADSensorPlacement;

      // Position needs to be handled specially
      if (castedKey === 'position') {
        const cadCoord = FloorplanCoordinates.toCADCoordinates(
          FloorplanCoordinates.create(
            existingSensor.position.x,
            existingSensor.position.y
          ),
          oldFloorplan,
          floorplanCADOrigin,
          cadFileUnit,
          cadFileScale
        );
        const distanceInCadUnits = distance(cadCoord, s.position);
        const distanceInMeters =
          CONVERT_TO_METERS[cadFileUnit](distanceInCadUnits);

        if (distanceInMeters > CAD_DISTANCE_EQUALITY_THRESHOLD_METERS) {
          isModification = true;
          break;
        }
        continue;
      }

      if (
        JSON.stringify(s[castedKey]) !==
        JSON.stringify(existingSensor[castedKey])
      ) {
        isModification = true;
        break;
      }
    }
    if (isModification) {
      modifications.push(s);
    } else {
      unmodified.push(existingSensor);
    }
  }

  return [
    ...unmodified.map((data) => ({
      type: 'no-change' as const,
      data,
    })),
    ...modifications.map((data) => ({
      type: 'modification' as const,
      data,
      // NOTE: this will always exist, this search is already done above
      oldData: oldData.find((i) => i.cadId === data.cadId) as PlanSensor,
    })),
    ...additions.map((data) => ({ type: 'addition' as const, data })),
    ...deletions.map((data) => ({ type: 'deletion' as const, data })),

    // Existing sensors without a CAD ID should be deleted
    ...sensors
      .filter((s) => s.cadId.length === 0)
      .map((data) => ({ type: 'deletion' as const, data })),
    // New sensors without a CAD ID should be added
    ...rawCadData
      .filter((s) => s.cadId.length === 0)
      .map((data) => ({ type: 'addition' as const, data })),
  ];
}
