import React from "react";

const Control1 = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M18,2 C20.209139,2 22,3.790861 22,6 C22,7.86356525 20.7256022,9.42947895 19.0007613,9.87378648 L19.0007613,14.1262135 C20.7256022,14.570521 22,16.1364348 22,18 C22,20.209139 20.209139,22 18,22 C16.1364348,22 14.570521,20.7256022 14.1262135,19.0007613 L9.87378648,19.0007613 C9.42947895,20.7256022 7.86356525,22 6,22 C3.790861,22 2,20.209139 2,18 C2,16.1360729 3.27489272,14.5699129 5.00024347,14.1259548 L5.00024347,9.87404517 C3.27489272,9.43008706 2,7.86392711 2,6 C2,3.790861 3.790861,2 6,2 C7.86392711,2 9.43008706,3.27489272 9.87404517,5.00024347 L14.1259548,5.00024347 C14.5699129,3.27489272 16.1360729,2 18,2 Z M18,16 C16.8954305,16 16,16.8954305 16,18 C16,19.1045695 16.8954305,20 18,20 C19.1045695,20 20,19.1045695 20,18 C20,16.8954305 19.1045695,16 18,16 Z M6,16 C4.8954305,16 4,16.8954305 4,18 C4,19.1045695 4.8954305,20 6,20 C7.1045695,20 8,19.1045695 8,18 C8,16.8954305 7.1045695,16 6,16 Z M14.1261858,7.00065381 L9.87386215,7.00046755 C9.51202415,8.4055989 8.40585103,9.51184473 7.00076134,9.87378648 L7.00076134,14.1262135 C8.40618726,14.4882419 9.51255353,15.5949306 9.87412177,17.0005412 L14.1259261,17.0003551 C14.4876303,15.5944979 15.5944021,14.4876985 17.0002435,14.1259548 L17.0002435,9.87404517 C15.5947384,9.51238807 14.4881599,8.40603159 14.1261858,7.00065381 Z M6,4 C4.8954305,4 4,4.8954305 4,6 C4,7.1045695 4.8954305,8 6,8 C7.1045695,8 8,7.1045695 8,6 C8,4.8954305 7.1045695,4 6,4 Z M18,4 C16.8954305,4 16,4.8954305 16,6 C16,7.1045695 16.8954305,8 18,8 C19.1045695,8 20,7.1045695 20,6 C20,4.8954305 19.1045695,4 18,4 Z"
      />
    </g>
  </svg>
);

export default Control1;
