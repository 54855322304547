import * as React from 'react';

const Lounge = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 11C21.1046 11 22 11.8954 22 13L22 19L2 19L2 13C2 11.8954 2.89543 11 4 11L4 8C4 6.34315 5.34315 5 7 5L17 5C18.6569 5 20 6.34315 20 8V11ZM6 8V11C7.10457 11 8 11.8954 8 13L16 13C16 11.8954 16.8954 11 18 11V8C18 7.44772 17.5523 7 17 7L7 7C6.44772 7 6 7.44772 6 8ZM16 17V15H8V17H16ZM18 17L20 17L20 13H18L18 17ZM6 13L6 17H4V13H6Z"
      fill={color}
    />
  </svg>
);

export default Lounge;
