import React from "react";

const CallRoom = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M12,2 C13.1045695,2 14,2.8954305 14,4 L14,20 C14,21.1045695 13.1045695,22 12,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,4 C2,2.8954305 2.8954305,2 4,2 L12,2 Z M5,4 L4,4 L4,20 L12,20 L12,4 L10.998,4 L10,5 L6,5 L5,4 Z M18.0104076,5.98959236 L19.4246212,4.5753788 C21.380396,6.53115364 22.5,9.1781947 22.5,12 C22.5,14.7089331 21.468173,17.2568035 19.6552824,19.1866432 L19.4246212,19.4246212 L18.0104076,18.0104076 C19.5948279,16.4259874 20.5,14.2859202 20.5,12 C20.5,9.81798528 19.6752461,7.76886554 18.2222335,6.20905528 L18.0104076,5.98959236 L19.4246212,4.5753788 Z M16.5961941,7.40380592 C17.8066647,8.61427653 18.5,10.2535053 18.5,12 C18.5,13.7464947 17.8066647,15.3857235 16.5961941,16.5961941 L16.5961941,16.5961941 L15.1819805,15.1819805 C16.0210966,14.3428645 16.5,13.2106096 16.5,12 C16.5,10.7893904 16.0210966,9.65713552 15.1819805,8.81801948 L15.1819805,8.81801948 Z"
      />
    </g>
  </svg>
);

export default CallRoom;
