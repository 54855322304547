const DENSITY_AUTH0_LOGOUT_URL = 'https://densityio.auth0.com/v2/logout';
const LOGOUT_RETURN_TO_ROUTE_PATH = '/logout-finish';

export type AuthedUser = {
  token: string;
};

export function getLogoutURL() {
  const returnToUrl = new URL(window.location.href);
  returnToUrl.pathname = LOGOUT_RETURN_TO_ROUTE_PATH;
  const logoutUrl = new URL(DENSITY_AUTH0_LOGOUT_URL);
  logoutUrl.searchParams.append('returnTo', returnToUrl.toString());
  return logoutUrl.toString();
}
