import * as React from 'react';

const OpenCollaborationSpace = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 4C18 2.89543 18.8954 2 20 2C21.1046 2 22 2.89543 22 4C22 5.10457 21.1046 6 20 6C19.8208 6 19.6472 5.97644 19.482 5.93226L15.4462 9.96804C15.7981 10.5636 16 11.2582 16 12C16 13.8638 14.7252 15.4299 13 15.874V18.2676C13.5978 18.6134 14 19.2597 14 20C14 21.1046 13.1046 22 12 22C10.8954 22 10 21.1046 10 20C10 19.2597 10.4022 18.6134 11 18.2676V15.874C9.59439 15.5122 8.4878 14.4056 8.12602 13H5.73244C5.38663 13.5978 4.74028 14 4 14C2.89543 14 2 13.1046 2 12C2 10.8954 2.89543 10 4 10C4.74028 10 5.38663 10.4022 5.73244 11H8.12602C8.4878 9.59439 9.59439 8.4878 11 8.12602V5.73244C10.4022 5.38663 10 4.74028 10 4C10 2.89543 10.8954 2 12 2C13.1046 2 14 2.89543 14 4C14 4.74028 13.5978 5.38663 13 5.73244V8.12602C13.367 8.22048 13.7137 8.36573 14.032 8.55382L18.0677 4.51805C18.0236 4.35282 18 4.17916 18 4ZM12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10Z"
      fill={color}
    />
  </svg>
);

export default OpenCollaborationSpace;
