import { v4 as uuidv4 } from 'uuid';
import {
  createContext,
  useRef,
  useState,
  useMemo,
  useEffect,
  useContext,
} from 'react';

import * as PIXI from 'pixi.js';

import { useFloorplanLayerContext } from './floorplan-layer-context';
import Layer from './layer';
import { FloorplanLayerContextData } from './types';

type ObjectId = string;
type ObjectLayerProps<T extends object, V extends PIXI.Container> = {
  objects: Array<T>;
  extractId: (obj: T) => ObjectId;
  onCreate: (objGetter: () => T) => V | null;
  onUpdate: (
    obj: T,
    geometry: V,
    animationFrame: boolean,
    context: FloorplanLayerContextData
  ) => void;
  onRemove: (obj: T, geometry: V) => void;
};

// Given an array of items, an `ObjectLayer` renders one entity into the pixi application viewport
// per item.
//
// An example use of this layer is rendering sensors on top of a floorplan.
const ObjectLayer = <T extends object, V extends PIXI.Container>({
  objects,
  extractId,
  onCreate,
  onUpdate,
  onRemove,
}: ObjectLayerProps<T, V>) => {
  const context = useFloorplanLayerContext();

  const objectLayerGroupContext = useContext(ObjectLayerGroupContext);
  const objectLayerGroupContextParent = objectLayerGroupContext?.parent;

  // Wrap all object layer entities inside a parent container
  const parentContainerRef = useRef<PIXI.Container | null>(null);
  useEffect(() => {
    const container = new PIXI.Container();
    container.name = `object-layer-${uuidv4()}`;
    parentContainerRef.current = container;

    if (objectLayerGroupContextParent) {
      objectLayerGroupContextParent.addChild(container);
    } else {
      context.app.stage.addChild(container);
    }
    return () => {
      if (objectLayerGroupContextParent) {
        objectLayerGroupContextParent.removeChild(container);
      } else {
        context.app.stage.removeChild(container);
      }
      parentContainerRef.current = null;
    };
  }, [context.app.stage, objectLayerGroupContextParent]);

  // When render order changes, invert the item order in the parent container
  const previousRenderOrderRef = useRef<'forwards' | 'backwards'>('forwards');
  useEffect(() => {
    if (!parentContainerRef.current) {
      return;
    }
    if (!objectLayerGroupContext) {
      return;
    }

    if (
      previousRenderOrderRef.current === objectLayerGroupContext.renderOrder
    ) {
      return;
    }
    previousRenderOrderRef.current = objectLayerGroupContext.renderOrder;

    parentContainerRef.current.children.reverse();
  }, [context.app.stage, objectLayerGroupContext]);

  const objectGeometries = useRef<ReadonlyMap<ObjectId, V>>(new Map());
  const objectReferences = useRef<ReadonlyMap<ObjectId, T>>(new Map());

  const getObjectById = (
    objectId: ObjectId,
    o: ReadonlyMap<ObjectId, T> = objectReferences.current
  ): T => {
    const objOrUndefined = o.get(objectId);
    if (!objOrUndefined) {
      throw new Error(
        'An object should never be in objectGeometries that is not in objectReferences!'
      );
    }
    return objOrUndefined;
  };

  // Initially create each object / cleanup each object when component unmounts
  useEffect(() => {
    objectReferences.current = new Map(
      objects.map((object) => [extractId(object), object])
    );

    const parentContainer = parentContainerRef.current;
    if (!parentContainer) {
      throw new Error('Object Layer parent container is not defined!');
    }

    objectGeometries.current = new Map(
      objects.flatMap((object) => {
        const geometry = onCreate(() => getObjectById(extractId(object)));
        if (!geometry) {
          return [];
        }
        parentContainer.addChild(geometry);
        return [[extractId(object), geometry]];
      })
    );

    return () => {
      for (const [objectId, geometry] of Array.from(
        objectGeometries.current.entries()
      )) {
        parentContainer.removeChild(geometry);
        onRemove(getObjectById(objectId), geometry);
      }
      objectGeometries.current = new Map();
      objectReferences.current = new Map();
    };
    // Don't include all dependencies because this hook should _only_ run on component mount / unmount
    // Keeping these values up to date is the responsibility of the next hook
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.app.stage, objectLayerGroupContextParent]);

  // Whenever `objects` changes, sync the data and the pixi stage using the `onCreate`, `onUpdate`, and
  // `onDelete` functions
  useEffect(() => {
    const parentContainer = parentContainerRef.current;
    if (!parentContainer) {
      throw new Error('Object Layer parent container is not defined!');
    }

    // Cache old object references for "delete" action below
    const oldObjectReferences = objectReferences.current;
    objectReferences.current = new Map(
      objects.map((object) => [extractId(object), object])
    );

    const objectIdsUpdated: Array<string> = [];

    const updatedObjectGeometries: Array<[ObjectId, V]> = Array.from(
      objectGeometries.current.entries()
    ).flatMap(([objectId, geometry]) => {
      const matchingObjectFromProps = objects.find(
        (obj) => extractId(obj) === objectId
      );
      // console.log('UPDATING', objectId, 'WITH', matchingObjectFromProps)
      if (matchingObjectFromProps) {
        // Matching object found, so update
        onUpdate(matchingObjectFromProps, geometry, false, context);
        objectIdsUpdated.push(objectId);
        return [[extractId(matchingObjectFromProps), geometry]];
      } else {
        // No matching object was passed in, so delete
        parentContainer.removeChild(geometry);
        onRemove(getObjectById(objectId, oldObjectReferences), geometry);
        return [];
      }
    });

    const newObjectGeometries: Array<[ObjectId, V]> = objects
      .filter((obj) => !objectIdsUpdated.includes(extractId(obj)))
      .flatMap((newObject) => {
        const geometry = onCreate(() => getObjectById(extractId(newObject)));
        if (!geometry) {
          return [];
        }
        parentContainer.addChild(geometry);
        return [[extractId(newObject), geometry]];
      });

    objectGeometries.current = new Map([
      ...updatedObjectGeometries,
      ...newObjectGeometries,
    ]);
    // Updating when onCreate / onRemove / onUpdate changes would cause the diffing logic to get
    // more complex, so assume they are constant throughout the lifecycle of the component
    // FIXME: rewrite this logic to be able to handle these functions changing.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objects]);

  return (
    <Layer
      onAnimationFrame={() => {
        if (!context.viewport.current) {
          return;
        }

        for (const object of objects) {
          const geometry = objectGeometries.current.get(extractId(object));
          if (!geometry) {
            return;
          }

          onUpdate(object, geometry, true, context);
        }
      }}
    />
  );
};

const ObjectLayerGroupContext = createContext<{
  parent: PIXI.Container;
  renderOrder: 'forwards' | 'backwards';
} | null>(null);

// An ObjectLayerGroup is an organizational grouping of `ObjectLayer`s which can also have its
// rendering order changed using the `renderOrder` prop.
//
// In the Editor component, this is used to render all entities that have area so that they can be
// dynamically swapped to allow for picking items "behind" forwardmost items.
export const ObjectLayerGroup: React.FunctionComponent<{
  renderOrder?: 'forwards' | 'backwards';
}> = ({ renderOrder = 'forwards', children }) => {
  const context = useFloorplanLayerContext();

  // Create a parent container which contains all of the inner `ObjectLayer`s
  const [parentContainer, setParentContainer] = useState<PIXI.Container | null>(
    null
  );
  useEffect(() => {
    const container = new PIXI.Container();
    container.name = `object-layer-group-${uuidv4()}`;
    context.app.stage.addChild(container);
    setParentContainer(container);
    return () => {
      context.app.stage.removeChild(container);
      setParentContainer(null);
    };
  }, [context.app.stage]);

  // When render order changes, invert the item order in the parent container
  const previousRenderOrderRef = useRef<'forwards' | 'backwards'>('forwards');
  useEffect(() => {
    if (!parentContainer) {
      return;
    }

    if (previousRenderOrderRef.current === renderOrder) {
      return;
    }
    previousRenderOrderRef.current = renderOrder;

    parentContainer.children.reverse();
  }, [parentContainer, renderOrder]);

  const value = useMemo(() => {
    if (!parentContainer) {
      return null;
    }
    return { parent: parentContainer, renderOrder };
  }, [parentContainer, renderOrder]);

  if (!value) {
    return null;
  }

  return (
    <ObjectLayerGroupContext.Provider value={value}>
      {children}
    </ObjectLayerGroupContext.Provider>
  );
};

export default ObjectLayer;
