// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-appBar-1gsj6umL{display:flex;align-items:center;justify-content:space-between;flex-grow:0;flex-shrink:0;box-sizing:border-box;height:64px;padding:0 24px;border-bottom:1px solid #E0E3E6;font-family:\"Aeonik\",\"Helvetica\",\"Arial\",sans-serif}.dui-styles-module-appBarSection-1a6U5qnz{display:flex;align-items:center}.dui-styles-module-appBarTitle-RguQtIBf{display:flex;align-items:center;height:32px;font-size:24px;font-weight:500;color:#0D183A;overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.dui-styles-module-contextTransparent-lMkTHSy8.dui-styles-module-appBar-1gsj6umL{border-bottom:none}.dui-styles-module-contextBottomActions-2rT_RiR0.dui-styles-module-appBar-1gsj6umL{border-top:1px solid #E0E3E6;border-bottom:none;background:#F5F6F7;border-bottom-left-radius:4px;border-bottom-right-radius:4px}.dui-styles-module-cardHeader-3N-jQtH- .dui-styles-module-appBarTitle-RguQtIBf{font-size:20px;color:#3A434D}\n", ""]);
// Exports
exports.locals = {
	"appBar": "dui-styles-module-appBar-1gsj6umL",
	"appBarSection": "dui-styles-module-appBarSection-1a6U5qnz",
	"appBarTitle": "dui-styles-module-appBarTitle-RguQtIBf",
	"contextTransparent": "dui-styles-module-contextTransparent-lMkTHSy8",
	"contextBottomActions": "dui-styles-module-contextBottomActions-2rT_RiR0",
	"cardHeader": "dui-styles-module-cardHeader-3N-jQtH-"
};
module.exports = exports;
