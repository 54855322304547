import * as React from 'react';

const OpenWorkspace = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      d="M14 7C14 5.89543 13.1046 5 12 5C10.8954 5 10 5.89543 10 7C10 8.10457 10.8954 9 12 9C13.1046 9 14 8.10457 14 7Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 11.3C15 10.582 14.421 10 13.7067 10H10.2935C10.2223 10 10.1512 10.0059 10.0809 10.0177C9.37634 10.1357 8.90037 10.8055 9.0178 11.5137L9.1905 13H2V22H22V13H14.8285L14.9824 11.5137C14.9941 11.4431 15 11.3716 15 11.3ZM20 15H4V20H20V15Z"
      fill={color}
    />
  </svg>
);

export default OpenWorkspace;
