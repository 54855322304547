import { useState, useMemo, useCallback } from 'react';
import { Icons } from '@density/dust';
import { toast } from 'react-toastify';
import styles from './styles.module.scss';

import { StorageKeys } from 'lib/storage';
import {
  Analytics,
  isDoNotTrackOverrideOff,
  isDoNotTrackOverrideOn,
  isDoNotTrackOn,
} from 'lib/analytics';
import { useAppSelector } from 'redux/store';

import AppBar from 'components/app-bar';
import Button from 'components/button';
import { DarkTheme, LightTheme } from 'components/theme';
import HorizontalForm from 'components/horizontal-form';

const AnalyticsOptInPopup: React.FunctionComponent<{}> = () => {
  const tokenCheckResponse = useAppSelector(
    (state) => state.auth.tokenCheckResponse
  );
  const isInternalDensityUser = useMemo(
    () => tokenCheckResponse?.permissions.includes('impersonate'),
    [tokenCheckResponse]
  );

  const doNotTrackOnWhenPageLoaded = useMemo(() => isDoNotTrackOn(), []);
  const popupInitiallyOpen = useMemo(
    () =>
      Boolean(
        doNotTrackOnWhenPageLoaded &&
          !isDoNotTrackOverrideOn() &&
          !isDoNotTrackOverrideOff()
      ),
    [doNotTrackOnWhenPageLoaded]
  );
  const [open, setOpen] = useState(popupInitiallyOpen);

  // Refresh the analytics provider so that it now sends analytics, overriding the "do not track"
  // flag built into web browsers
  const onEnableAnalytics = useCallback(() => {
    localStorage.setItem(StorageKeys.DO_NOT_TRACK_OVERRIDE, 'true');
    Analytics.init();

    setOpen(false);
    toast.success('Thanks for enabling analytics!');
  }, []);

  const onDismiss = useCallback(() => {
    localStorage.setItem(StorageKeys.DO_NOT_TRACK_OVERRIDE, 'false');
    setOpen(false);
  }, []);

  if (!isInternalDensityUser) {
    return null;
  }

  if (!open && doNotTrackOnWhenPageLoaded && !isDoNotTrackOverrideOn()) {
    return (
      <Button
        size="small"
        onClick={() => setOpen(true)}
        data-cy="analytics-opt-in-hint"
      >
        Enable Analytics...
      </Button>
    );
  }

  if (!open) {
    return null;
  }

  return (
    <div className={styles.wrapper} data-cy="analytics-opt-in-wrapper">
      <LightTheme>
        <AppBar
          title="Enable Analytics"
          actions={
            <DarkTheme>
              <Button
                type="cleared"
                size="medium"
                trailingIcon={<Icons.Close size={24} />}
                onClick={onDismiss}
                data-cy="analytics-opt-in-dismiss"
              />
            </DarkTheme>
          }
        />
      </LightTheme>
      <div className={styles.body}>
        <p>
          It looks like you have the "do not track" setting enabled in your web
          browser, which prevents us from sending analytics to better understand
          how this application is used.
        </p>
        <p>
          These analytics are <strong>really</strong> important to ensure that
          the Maps team is best serving the teams that use Planner. If you are
          curious,{' '}
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.notion.so/densityinc/Planner-Analytics-Plan-b6c49a6331f34e61bbf68123944bd6f7"
          >
            here is a list of all analytics that this application tracks
          </a>
          .
        </p>
      </div>
      <div className={styles.footer}>
        <DarkTheme>
          <HorizontalForm size="medium">
            <Button
              onClick={onDismiss}
              type="cleared"
              data-cy="analytics-opt-in-cancel"
            >
              Close
            </Button>
            <Button
              onClick={onEnableAnalytics}
              data-cy="analytics-opt-in-submit"
            >
              Enable Analytics
            </Button>
          </HorizontalForm>
        </DarkTheme>
      </div>
    </div>
  );
};

export default AnalyticsOptInPopup;
