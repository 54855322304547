(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("prop-types"), require("react-dom"), require("moment-timezone"), require("@densityco/lib-common-types"), require("@densityco/lib-space-helpers"), require("moment"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "prop-types", "react-dom", "moment-timezone", "@densityco/lib-common-types", "@densityco/lib-space-helpers", "moment"], factory);
	else if(typeof exports === 'object')
		exports["@density/ui"] = factory(require("react"), require("prop-types"), require("react-dom"), require("moment-timezone"), require("@densityco/lib-common-types"), require("@densityco/lib-space-helpers"), require("moment"));
	else
		root["@density/ui"] = factory(root["react"], root["prop-types"], root["react-dom"], root["moment-timezone"], root["@densityco/lib-common-types"], root["@densityco/lib-space-helpers"], root["moment"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__55__, __WEBPACK_EXTERNAL_MODULE__78__, __WEBPACK_EXTERNAL_MODULE__79__) {
return 