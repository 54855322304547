import * as React from 'react';

const GamesRoom = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      d="M15 12C15 11.4477 15.4477 11 16 11C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13C15.4477 13 15 12.5523 15 12Z"
      fill={color}
    />
    <path
      d="M14 13C13.4477 13 13 13.4477 13 14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14C15 13.4477 14.5523 13 14 13Z"
      fill={color}
    />
    <path
      d="M18 13C17.4477 13 17 13.4477 17 14C17 14.5523 17.4477 15 18 15C18.5523 15 19 14.5523 19 14C19 13.4477 18.5523 13 18 13Z"
      fill={color}
    />
    <path
      d="M16 15C15.4477 15 15 15.4477 15 16C15 16.5523 15.4477 17 16 17C16.5523 17 17 16.5523 17 16C17 15.4477 16.5523 15 16 15Z"
      fill={color}
    />
    <path
      d="M6 15C5.44772 15 5 14.5523 5 14C5 13.4477 5.44772 13 6 13H7V12C7 11.4477 7.44772 11 8 11C8.55228 11 9 11.4477 9 12V13H10C10.5523 13 11 13.4477 11 14C11 14.5523 10.5523 15 10 15H9V16C9 16.5523 8.55228 17 8 17C7.44772 17 7 16.5523 7 16V15H6Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 4H13V8H19C20.6569 8 22 9.34315 22 11V17C22 18.6569 20.6569 20 19 20H5C3.34315 20 2 18.6569 2 17V11C2 9.34315 3.34315 8 5 8H11V4ZM4 11C4 10.4477 4.44772 10 5 10H19C19.5523 10 20 10.4477 20 11V17C20 17.5523 19.5523 18 19 18H5C4.44772 18 4 17.5523 4 17V11Z"
      fill={color}
    />
  </svg>
);

export default GamesRoom;
