import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Fragment,
  useRef,
  useMemo,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { CoreSpace } from '@densityco/lib-api-types';
import Mousetrap from 'mousetrap';
import { wrap, releaseProxy, proxy, Remote } from 'comlink';
import axios, { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
import { Dialogger } from '@densityco/ui';
import { Icons } from '@density/dust';

import FloorplanMeasure from 'components/floorplan-measure';
import FloorplanImageRegistration from './image-registration';
import WallsEditor, { WallSegmentLayer } from 'components/walls-editor';

import FloorplanObjectsList, {
  LatestDXFStatus,
} from './floorplan-objects-list';
import FocusedSensorPanel from './focused-sensor-panel';
import FocusedAreaOfConcernPanel from './focused-area-of-concern-panel';
import FocusedSpacePanel from './focused-space-panel';
import FocusedPhotoGroupPanel from './focused-photo-group-panel';
import FocusedHeightmapLayerPanel from './focused-heightmap-layer-panel';
import FocusedHeatMapLayerPanel from './focused-heatmap-layer-panel';
import FocusedWallsLayerPanel from './focused-walls-layer-panel';
import SpaceObserver from './space-observer';
import { FixMe } from 'types/fixme';
import {
  State,
  reducer,
  PlanDXF,
  LayerId,
  MutationOptions,
  UndoStack,
} from './state';
import { Action } from './actions';
import styles from './styles.module.scss';
import { generateExportCanvas, storeCanvasToDisk } from './export';
import SettingsPanel from './settings-panel';
import ExportPanel from './export-panel';
import CADImport from './cad-import';
import HeightMapImport from 'components/height-map-editor';

import KeyboardShortcutsMenu from 'components/keyboard-shortcuts-menu';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import Button from 'components/button';
import Tooltip from 'components/tooltip';
import AppBar from 'components/app-bar';
import { DarkTheme } from 'components/theme';
import HorizontalForm from 'components/horizontal-form';
import SyncingIndicator from 'components/syncing-indicator';
import FloorNameDropdown from 'components/floor-name-dropdown';
import Floorplan, { ObjectLayerGroup } from 'components/floorplan';
import SensorsLayer from './floorplan-layers/sensors-layer';
import SpacesLayer from './floorplan-layers/spaces-layer';
import SpaceLabelsLayer from './floorplan-layers/space-labels-layer';
import AreasOfConcernLayer from './floorplan-layers/areas-of-concern-layer';
import ReferenceRulersLayer from './floorplan-layers/reference-rulers-layer';
import ReferenceHeightsLayer from './floorplan-layers/reference-heights-layer';
import PhotoGroupsLayer from './floorplan-layers/photo-groups-layer';
import FloorplanScaleLayer from './floorplan-layers/floorplan-scale-layer';
import AggregatedPointsLayer from './floorplan-layers/aggregated-points-layer';
import AllSensorAggregatedPointsLayer from './floorplan-layers/all-sensor-aggregated-points-layer';
import ObjectPlacementTargetLayer from './floorplan-layers/object-placement-target-layer';
import { HeightMapLayer } from './floorplan-layers/height-map-registration-layer';
import HeatmapLayer from './floorplan-layers/heatmap-layer';
import ObjectMeasureLayer from './floorplan-layers/object-measure-layer';
import { resizeImageToMaxSize } from 'lib/image';
import HeightMap from 'lib/heightmap';
import PhotoGroup, { PhotoGroupPhoto } from 'lib/photo-group';
import { Analytics } from 'lib/analytics';
import Measurement from 'lib/measurement';
import { Seconds } from 'lib/units';
import { Heatmap } from 'lib/heatmap';
import Space, { SpaceValidation } from 'lib/space';
import { ReferenceRuler, ReferenceHeight } from 'lib/reference';
import PlanSensor, {
  SensorValidation,
  SensorCoverageIntersectionVectors,
} from 'lib/sensor';
import FloorplanCollection from 'lib/floorplan-collection';
import WallSegment from 'lib/wall-segment';
import { GeoTiffTiepoint } from 'lib/geotiff';
import {
  PlanEventMessagePlan,
  PlanEventMessageSensor,
  PlanEventMessageSpace,
  PlanEventMessageRuler,
  PlanEventMessageHeight,
  PlanEventMessagePhotoGroup,
  PlanEventMessagePhoto,
} from 'lib/floorplan-event-stream';
import { SPLITS } from 'lib/treatments';
import { FloorplanCoordinates, ViewportCoordinates } from 'lib/geometry';
import { DEFAULT_VIEWPORT } from 'lib/viewport';
import {
  Unsaved,
  CoreAPI,
  FloorplanAPI,
  FloorplanV2Plan,
  FloorplanV2SpaceWrite,
  FloorplanV2Sensor,
  FloorplanV2ReferenceRuler,
  FloorplanV2ReferenceHeight,
  FloorplanV2PhotoGroupWrite,
  FloorplanV2WallSegment,
} from 'lib/api';
import FloorplanEventStream, {
  FloorplanEventStreamStatus,
  PlanEventMessageDXF,
  PlanEventMessageExport,
} from 'lib/floorplan-event-stream';
import { CoverageIntersectionSampleRate } from 'lib/coverage-intersection';
import {
  translate,
  compose,
  rotateDEG,
  decomposeTSR,
} from 'transformation-matrix';
import { useTreatment } from 'contexts/treatments';
import FloorplanType from 'lib/floorplan';
import { useAppSelector } from 'redux/store';

type Props = {
  plan: FloorplanV2Plan;
  planImage: HTMLImageElement | null;
  client: AxiosInstance;

  // onDuplicatePlanComplete: (plan: PersistedData.v1) => void;
  onNavigateBackToList: (newTab: boolean) => void;

  editNameLoading: boolean;
  onEditName: (name: CoreSpace['name']) => void;
  editStatusLoading: boolean;
  onEditStatus: (status: CoreSpace['status']) => Promise<void>;
};

class MutationFailureError extends Error {
  name = 'MutationFailureError';
}

function cacheBustImageUrl(imageSrc: string) {
  if (imageSrc.includes('s3.amazonaws.com')) {
    // In order to ensure chrome doesn't cache the base image, a cache-busting query parameter is
    // needed. See https://github.com/DensityCo/nashi-client/blob/master/src/utils/downloadImage.ts
    // for where I got this technique, or talk to wei-wei for more info.
    const result = new URL(imageSrc);
    result.searchParams.set('nonce', `${new Date().getTime()}`);
    return result.toString();
  } else {
    return imageSrc;
  }
}

const syncSpaceCreateToAPI = async (
  client: AxiosInstance,
  planId: FloorplanV2Plan['id'],
  state: State,
  space: Space,
  spaceId: Space['id'],
  direction: 'forwards' | 'backwards',
  dispatch: (action: Action) => void
) => {
  switch (direction) {
    case 'forwards':
      const spaceFromState = state.spaces.items.get(spaceId);
      if (!spaceFromState) {
        throw new Error(`Cannot find space ${spaceId} to create!`);
      }

      // Create the new area object, and then update the locally generated uuid
      // with a server generated `are_xxx` id
      const floorplanSpaceWrite = Space.toFloorplanSpaceWrite(spaceFromState);
      const response = await FloorplanAPI.createSpace(
        client,
        planId,
        floorplanSpaceWrite
      );
      dispatch({
        type: 'space.changeId',
        oldId: space.id,
        newId: response.data.id,
      });
      dispatch({
        type: 'space.changeId',
        oldId: spaceId,
        newId: response.data.id,
      });
      break;

    case 'backwards':
      await FloorplanAPI.deleteSpace(client, planId, spaceId);
      break;
  }
};

const syncSpaceUpdateToAPI = async (
  client: AxiosInstance,
  planId: FloorplanV2Plan['id'],
  state: State,
  spaceId: Space['id'],
  fields?: Array<keyof Unsaved<FloorplanV2SpaceWrite>>
) => {
  const space = state.spaces.items.get(spaceId);
  if (!space) {
    return;
  }

  const floorplanSpace = Space.toFloorplanSpaceWrite(
    space
  ) as FloorplanV2SpaceWrite;

  let body: Partial<FloorplanV2SpaceWrite> = {};
  if (fields) {
    for (const field of fields) {
      body[field] = floorplanSpace[field] as FixMe;
    }
  } else {
    // By default, send all fields
    body = floorplanSpace;
  }

  await FloorplanAPI.updateSpace(client, planId, spaceId, body);
};

const syncSpaceDeleteToAPI = async (
  client: AxiosInstance,
  planId: FloorplanV2Plan['id'],
  state: State,
  space: Space,
  spaceId: Space['id'],
  direction: 'forwards' | 'backwards',
  dispatch: (action: Action) => void
) => {
  switch (direction) {
    case 'forwards':
      await FloorplanAPI.deleteSpace(client, planId, spaceId);
      break;
    case 'backwards':
      const spaceFromState = state.spaces.items.get(spaceId);
      if (!spaceFromState) {
        throw new Error(`Cannot find space ${spaceId} to delete!`);
      }

      // Create the new area object, and then update the locally generated uuid
      // with a server generated `spc_xxx` id
      const floorplanSpaceWrite = Space.toFloorplanSpaceWrite(spaceFromState);
      const response = await FloorplanAPI.createSpace(
        client,
        planId,
        floorplanSpaceWrite
      );
      dispatch({
        type: 'space.changeId',
        oldId: space.id,
        newId: response.data.id,
      });
      dispatch({
        type: 'space.changeId',
        oldId: spaceId,
        newId: response.data.id,
      });
      break;
  }
};

const syncPlanSensorCreateToAPI = async (
  client: AxiosInstance,
  planId: FloorplanV2Plan['id'],
  state: State,
  sensor: PlanSensor,
  sensorId: PlanSensor['id'],
  direction: 'forwards' | 'backwards',
  dispatch: (action: Action) => void
) => {
  switch (direction) {
    case 'forwards':
      const sensorFromState = state.planSensors.items.get(sensorId);
      if (!sensorFromState) {
        throw new Error(`Cannot find sensor ${sensorId} to create!`);
      }

      // Create the new plan sensor object, and then update the locally generated uuid
      // with a server generated `psr_xxx` id
      const floorplanSensor = PlanSensor.toFloorplanSensor(sensorFromState);
      const response = await FloorplanAPI.createSensor(
        client,
        planId,
        floorplanSensor
      );
      dispatch({
        type: 'sensor.changeId',
        oldId: sensor.id,
        newId: response.data.id,
      });
      dispatch({
        type: 'sensor.changeId',
        oldId: sensorId,
        newId: response.data.id,
      });
      break;

    case 'backwards':
      await FloorplanAPI.deleteSensor(client, planId, sensorId);
      break;
  }
};

const syncPlanSensorUpdateToAPI = async (
  client: AxiosInstance,
  planId: FloorplanV2Plan['id'],
  state: State,
  planSensorId: PlanSensor['id'],
  fields?: Array<keyof Unsaved<FloorplanV2Sensor>>
): Promise<FloorplanV2Sensor | null> => {
  const sensor = state.planSensors.items.get(planSensorId);
  if (!sensor) {
    console.warn(`Cannot update sensor ${planSensorId}, it was not found!`);
    return null;
  }

  const floorplanSensor = PlanSensor.toFloorplanSensor(
    sensor
  ) as FloorplanV2Sensor;

  let body: Partial<FloorplanV2Sensor> = {};
  if (fields) {
    for (const field of fields) {
      body[field] = floorplanSensor[field] as FixMe;
    }
  } else {
    // By default, send all fields
    body = floorplanSensor;
  }

  // NOTE: this will throw an error (rejecting the promise) if the request fails
  try {
    const response = await FloorplanAPI.updateSensor(
      client,
      planId,
      planSensorId,
      body
    );
    return response.data;
  } catch (err: FixMe) {
    // Show a custom toast if the sensor update returns an error during assigning the serial number
    if (err.name === 'AxiosError') {
      if (err.response?.data?.detail) {
        throw new MutationFailureError(err.response.data.detail);
      }
      if (Array.isArray(err.response?.data?.sensor_serial_number)) {
        throw new MutationFailureError(
          err.response.data.sensor_serial_number[0]
        );
      }
    }
    throw err;
  }
};

const syncPlanSensorDeleteToAPI = async (
  client: AxiosInstance,
  planId: FloorplanV2Plan['id'],
  state: State,
  sensor: PlanSensor,
  planSensorId: PlanSensor['id'],
  direction: 'forwards' | 'backwards',
  dispatch: (action: Action) => void
) => {
  switch (direction) {
    case 'forwards':
      await FloorplanAPI.deleteSensor(client, planId, planSensorId);
      break;
    case 'backwards':
      const sensorFromState = state.planSensors.items.get(planSensorId);
      if (!sensorFromState) {
        throw new Error(`Cannot find sensor ${planSensorId} to delete!`);
      }

      // Create the new area object, and then update the locally generated uuid
      // with a server generated `psr_xxx` id
      const floorplanSensor = PlanSensor.toFloorplanSensor(sensorFromState);
      const response = await FloorplanAPI.createSensor(
        client,
        planId,
        floorplanSensor
      );
      dispatch({
        type: 'sensor.changeId',
        oldId: sensor.id,
        newId: response.data.id,
      });
      dispatch({
        type: 'sensor.changeId',
        oldId: planSensorId,
        newId: response.data.id,
      });
      break;
  }
};

const syncAPIReferenceRulerCreateToAPI = async (
  client: AxiosInstance,
  planId: FloorplanV2Plan['id'],
  state: State,
  referenceRuler: ReferenceRuler,
  referenceId: ReferenceRuler['id'],
  direction: 'forwards' | 'backwards',
  dispatch: (action: Action) => void
) => {
  switch (direction) {
    case 'forwards':
      const referenceFromState = state.references.items.get(referenceId);
      if (!referenceFromState) {
        throw new Error(`Cannot find reference ${referenceId} to create!`);
      }
      if (referenceFromState.type !== 'ruler') {
        throw new Error(`Reference ${referenceId} is not of type ruler!`);
      }

      // Create the new reference object, and then update the locally generated uuid
      // with a server generated `rer_xxx` / `reh_xxx` id
      const apiReferenceRuler =
        ReferenceRuler.toFloorplanReferenceRuler(referenceFromState);
      const response = await FloorplanAPI.createReferenceRuler(
        client,
        planId,
        apiReferenceRuler
      );

      dispatch({
        type: 'reference.changeId',
        oldId: referenceRuler.id,
        newId: response.data.id,
      });
      dispatch({
        type: 'reference.changeId',
        oldId: referenceId,
        newId: response.data.id,
      });
      break;

    case 'backwards':
      await FloorplanAPI.deleteReferenceRuler(client, planId, referenceId);
      break;
  }
};

const syncAPIReferenceRulerUpdateToAPI = async (
  client: AxiosInstance,
  planId: FloorplanV2Plan['id'],
  state: State,
  referenceRulerId: FloorplanV2ReferenceRuler['id'],
  fields?: Array<keyof Unsaved<FloorplanV2ReferenceRuler>>
) => {
  const reference = state.references.items.get(referenceRulerId);
  if (!reference) {
    return;
  }
  if (reference.type !== 'ruler') {
    return;
  }

  const apiReferenceRuler = ReferenceRuler.toFloorplanReferenceRuler(
    reference
  ) as FloorplanV2ReferenceRuler;

  let body: Partial<FloorplanV2ReferenceRuler> = {};
  if (fields) {
    for (const field of fields) {
      body[field] = apiReferenceRuler[field] as FixMe;
    }
  } else {
    // By default, send all fields
    body = apiReferenceRuler;
  }

  await FloorplanAPI.updateReferenceRuler(
    client,
    planId,
    referenceRulerId,
    body
  );
};

const syncAPIReferenceRulerDeleteToAPI = async (
  client: AxiosInstance,
  planId: FloorplanV2Plan['id'],
  state: State,
  referenceRuler: ReferenceRuler,
  referenceRulerId: ReferenceRuler['id'],
  direction: 'forwards' | 'backwards',
  dispatch: (action: Action) => void
) => {
  switch (direction) {
    case 'forwards':
      await FloorplanAPI.deleteReferenceRuler(client, planId, referenceRulerId);
      break;
    case 'backwards':
      const referenceRulerFromState =
        state.references.items.get(referenceRulerId);
      if (!referenceRulerFromState) {
        throw new Error(
          `Cannot find reference ruler ${referenceRulerId} to delete!`
        );
      }

      if (referenceRulerFromState.type !== 'ruler') {
        throw new Error(
          `Reference ${referenceRulerId} is not a reference ruler, cannot delete!`
        );
      }

      // Create the new area object, and then update the locally generated uuid
      // with a server generated `rer_xxx` id
      const apiReferenceRuler = ReferenceRuler.toFloorplanReferenceRuler(
        referenceRulerFromState
      );
      const response = await FloorplanAPI.createReferenceRuler(
        client,
        planId,
        apiReferenceRuler
      );
      dispatch({
        type: 'reference.changeId',
        oldId: referenceRuler.id,
        newId: response.data.id,
      });
      dispatch({
        type: 'reference.changeId',
        oldId: referenceRulerId,
        newId: response.data.id,
      });
      break;
  }
};

const syncAPIReferenceHeightCreateToAPI = async (
  client: AxiosInstance,
  planId: FloorplanV2Plan['id'],
  state: State,
  referenceHeight: ReferenceHeight,
  referenceId: ReferenceHeight['id'],
  direction: 'forwards' | 'backwards',
  dispatch: (action: Action) => void
) => {
  switch (direction) {
    case 'forwards':
      const referenceFromState = state.references.items.get(referenceId);
      if (!referenceFromState) {
        throw new Error(
          `Cannot find reference height ${referenceId} to create!`
        );
      }
      if (referenceFromState.type !== 'height') {
        throw new Error(`Reference ${referenceId} is not of type height!`);
      }

      // Create the new reference height object, and then update the locally generated uuid with a
      // server generated `reh_xxx` id
      const apiReferenceHeight =
        ReferenceHeight.toFloorplanReferenceHeight(referenceFromState);
      const response = await FloorplanAPI.createReferenceHeight(
        client,
        planId,
        apiReferenceHeight
      );

      dispatch({
        type: 'reference.changeId',
        oldId: referenceHeight.id,
        newId: response.data.id,
      });
      dispatch({
        type: 'reference.changeId',
        oldId: referenceId,
        newId: response.data.id,
      });
      break;

    case 'backwards':
      await FloorplanAPI.deleteReferenceHeight(client, planId, referenceId);
      break;
  }
};

const syncAPIReferenceHeightUpdateToAPI = async (
  client: AxiosInstance,
  planId: FloorplanV2Plan['id'],
  state: State,
  referenceHeightId: FloorplanV2ReferenceHeight['id'],
  fields?: Array<keyof Unsaved<FloorplanV2ReferenceHeight>>
) => {
  const reference = state.references.items.get(referenceHeightId);
  if (!reference) {
    return;
  }
  if (reference.type !== 'height') {
    return;
  }

  const apiReferenceHeight = ReferenceHeight.toFloorplanReferenceHeight(
    reference
  ) as FloorplanV2ReferenceHeight;

  let body: Partial<FloorplanV2ReferenceHeight> = {};
  if (fields) {
    for (const field of fields) {
      body[field] = apiReferenceHeight[field] as FixMe;
    }
  } else {
    // By default, send all fields
    body = apiReferenceHeight;
  }

  await FloorplanAPI.updateReferenceHeight(
    client,
    planId,
    referenceHeightId,
    body
  );
};

const syncAPIReferenceHeightDeleteToAPI = async (
  client: AxiosInstance,
  planId: FloorplanV2Plan['id'],
  state: State,
  referenceHeight: ReferenceHeight,
  referenceHeightId: ReferenceHeight['id'],
  direction: 'forwards' | 'backwards',
  dispatch: (action: Action) => void
) => {
  switch (direction) {
    case 'forwards':
      await FloorplanAPI.deleteReferenceHeight(
        client,
        planId,
        referenceHeightId
      );
      break;
    case 'backwards':
      const referenceHeightFromState =
        state.references.items.get(referenceHeightId);
      if (!referenceHeightFromState) {
        throw new Error(
          `Cannot find reference height ${referenceHeightId} to delete!`
        );
      }

      if (referenceHeightFromState.type !== 'height') {
        throw new Error(
          `Reference ${referenceHeightId} is not a reference height, cannot delete!`
        );
      }

      // Create the new area object, and then update the locally generated uuid
      // with a server generated `reh_xxx` id
      const apiReferenceHeight = ReferenceHeight.toFloorplanReferenceHeight(
        referenceHeightFromState
      );
      const response = await FloorplanAPI.createReferenceHeight(
        client,
        planId,
        apiReferenceHeight
      );
      dispatch({
        type: 'reference.changeId',
        oldId: referenceHeight.id,
        newId: response.data.id,
      });
      dispatch({
        type: 'reference.changeId',
        oldId: referenceHeightId,
        newId: response.data.id,
      });
      break;
  }
};

const syncSerializedPhotoGroupUpdateToAPI = async (
  client: AxiosInstance,
  planId: FloorplanV2Plan['id'],
  state: State,
  photoGroupId: FloorplanV2PhotoGroupWrite['id'],
  fields?: Array<keyof Unsaved<FloorplanV2PhotoGroupWrite>>
) => {
  const photoGroup = state.photoGroups.items.get(photoGroupId);
  if (!photoGroup) {
    return;
  }

  const serializedPhotoGroup =
    PhotoGroup.toFloorplanPhotoGroupWrite(photoGroup);

  let body: Partial<FloorplanV2PhotoGroupWrite> = {};
  if (fields) {
    for (const field of fields) {
      body[field] = serializedPhotoGroup[field] as FixMe;
    }
  } else {
    // By default, send all fields
    body = serializedPhotoGroup;
  }

  await FloorplanAPI.updatePhotoGroup(client, planId, photoGroupId, body);
};

const syncSerializedPhotoGroupDeleteToAPI = async (
  client: AxiosInstance,
  planId: FloorplanV2Plan['id'],
  state: State,
  photoGroup: PhotoGroup,
  photoGroupId: PhotoGroup['id'],
  direction: 'forwards' | 'backwards',
  dispatch: (action: Action) => void
) => {
  switch (direction) {
    case 'forwards':
      await FloorplanAPI.deletePhotoGroup(client, planId, photoGroupId);
      break;
    case 'backwards':
      const photoGroupFromState = state.photoGroups.items.get(photoGroupId);
      if (!photoGroupFromState) {
        throw new Error(`Cannot find photo group ${photoGroupId} to delete!`);
      }

      // Create the new photo group object, and then update the locally generated uuid
      // with a server generated `ppg_xxx` id
      const photoGroupWrite =
        PhotoGroup.toFloorplanPhotoGroupWrite(photoGroupFromState);
      const response = await FloorplanAPI.createPhotoGroup(
        client,
        planId,
        photoGroupWrite
      );
      dispatch({
        type: 'photoGroup.changeId',
        oldId: photoGroup.id,
        newId: response.data.id,
      });
      dispatch({
        type: 'photoGroup.changeId',
        oldId: photoGroupId,
        newId: response.data.id,
      });
      break;
  }
};

const syncHeightMapToAPI = async (
  client: AxiosInstance,
  planId: FloorplanV2Plan['id'],
  state: State
) => {
  // Generate a matrix containing the geotiff => floorplan coordiantes transform
  let geoTiffPointCloudMatrix = undefined;
  if (
    state.heightMap.enabled &&
    state.heightMap.geotiffTransformationData.enabled
  ) {
    const originTiePoint =
      state.heightMap.geotiffTransformationData.tiePoint[0];
    geoTiffPointCloudMatrix = compose(
      translate(originTiePoint.x, -originTiePoint.y),
      rotateDEG(360 - state.heightMap.rotation),
      translate(-state.heightMap.position.x, -state.heightMap.position.y)
    );
  }

  await FloorplanAPI.updateCeilingRaster(
    client,
    planId,
    state.heightMap.enabled ? state.heightMap.objectKey : null,
    state.heightMap.enabled ? state.heightMap.position.x : 0,
    state.heightMap.enabled ? state.heightMap.position.y : 0,
    state.heightMap.enabled ? state.heightMap.rotation : 0,
    state.heightMap.enabled ? state.heightMap.notes : '',
    state.heightMap.enabled ? state.heightMap.opacity : 100,
    state.heightMap.enabled && state.heightMap.limits.enabled
      ? state.heightMap.limits.minMeters
      : 0,
    state.heightMap.enabled && state.heightMap.limits.enabled
      ? state.heightMap.limits.maxMeters
      : 0,
    state.heightMap.enabled ? geoTiffPointCloudMatrix : undefined,
    state.heightMap.enabled && geoTiffPointCloudMatrix
      ? JSON.stringify(decomposeTSR(geoTiffPointCloudMatrix))
      : undefined
  );
};

const Editor: React.FunctionComponent<Props> = ({
  plan,
  planImage,
  client,
  onNavigateBackToList,
  editNameLoading,
  onEditName,
  editStatusLoading,
  onEditStatus,
}) => {
  const isHeightMapEnabled = useTreatment(SPLITS.HEIGHT_MAP);
  const isSensorCoverageEnabled = useTreatment(SPLITS.SENSOR_COVERAGE);
  const isWallsEnabled = useTreatment(SPLITS.WALLS);
  const isAreasOfConcernEnabled = useTreatment(SPLITS.AREAS_OF_CONCERN);
  const isAutoSavingDisabled = useTreatment(SPLITS.DISABLE_AUTO_SAVING);
  const isFlipRenderOrderEnabled = useTreatment(SPLITS.FLIP_RENDER_ORDER);
  const isValidationEnabled = useTreatment(SPLITS.VALIDATION);
  const isCombinedExportPopupEnabled = useTreatment(
    SPLITS.COMBINED_EXPORT_POPUP
  );

  const tokenCheckResponse = useAppSelector(
    (state) => state.auth.tokenCheckResponse
  );
  const isReadOnly = tokenCheckResponse?.permissions?.includes('core_write')
    ? false
    : true;

  const viewportElementRef = useRef<HTMLDivElement | null>(null);
  const floorplanRef = useRef<FixMe | null>(null);

  const initialState = useMemo(() => {
    const floorplan = FloorplanType.fromFloorplanAPIResponse(plan);
    const measurement = Measurement.fromFloorplanAPIResponse(plan);

    const lastSensorIndices = {
      lastOASensorIndex: plan.last_oa_sensor_index,
      lastEntrySensorIndex: plan.last_entry_sensor_index,
    };

    let state = State.getInitialState(
      floorplan,
      DEFAULT_VIEWPORT,
      planImage,
      plan.image_key,
      measurement,
      lastSensorIndices
    );

    if (
      typeof plan.floorplan_cad_origin_x !== 'undefined' &&
      typeof plan.floorplan_cad_origin_y !== 'undefined'
    ) {
      state.floorplanCADOrigin = FloorplanCoordinates.create(
        plan.floorplan_cad_origin_x,
        plan.floorplan_cad_origin_y
      );
    }

    if (plan.latest_dxf) {
      state.latestDXF = {
        id: plan.latest_dxf.id,
        status: plan.latest_dxf.status,
        createdAt: plan.latest_dxf.created_at,
        parseOptions: plan.latest_dxf.options,
      };
    }
    if (plan.active_dxf_id) {
      state.activeDXFId = plan.active_dxf_id;
    }
    if (plan.active_dxf_full_raster_url) {
      state.activeDXFFullRasterUrl = plan.active_dxf_full_raster_url;
    }

    if (plan.ceiling_raster_url && plan.ceiling_raster_key) {
      let limits: HeightMap['limits'] = { enabled: false };
      if (
        plan.ceiling_raster_min_height_limit_meters > 0 &&
        plan.ceiling_raster_max_height_limit_meters > 0
      ) {
        limits = {
          enabled: true,
          minMeters: plan.ceiling_raster_min_height_limit_meters,
          maxMeters: plan.ceiling_raster_max_height_limit_meters,
        };
      }

      state.heightMap = {
        enabled: true,
        url: plan.ceiling_raster_url,
        objectKey: plan.ceiling_raster_key,
        rotation: plan.ceiling_raster_floorplan_angle_degrees || 0,
        position: FloorplanCoordinates.create(
          plan.ceiling_raster_floorplan_origin_x || 0,
          plan.ceiling_raster_floorplan_origin_y || 0
        ),
        opacity: plan.ceiling_raster_opacity_percent || 100,
        notes: plan.ceiling_raster_notes || '',
        limits,
        geotiffTransformationData: {
          enabled: false,
        },
      };
    } else {
      state.heightMap = { enabled: false };
    }

    if (!FloorplanCollection.isEmpty(state.spaces)) {
      state.objectListType = 'space';
    } else if (!FloorplanCollection.isEmpty(state.planSensors)) {
      state.objectListType = 'sensor';
    } else {
      state.objectListType = isAreasOfConcernEnabled
        ? 'areaofconcern'
        : 'sensor';
    }

    return state;
  }, [plan, planImage, isAreasOfConcernEnabled]);

  // TODO: finish loading objects initially
  useEffect(() => {
    // Load all plan sensors from the floorplan api
    (async () => {
      let page = 1;
      while (true) {
        let response;
        try {
          response = await FloorplanAPI.listSensors(client, plan.id, page);
        } catch (err) {
          console.error('Error loading sensors:', err);
          toast.error('Error loading sensors!');
          break;
        }
        dispatch({
          type: 'sensor.importPageFromAPI',
          responseBody: response.data,
        });
        if (!response.data.next) {
          break;
        }
        page += 1;
      }
    })();

    // Load all spaces from the floorplan api
    (async () => {
      let page = 1;
      while (true) {
        let response;
        try {
          response = await FloorplanAPI.listSpaces(client, plan.id, page);
        } catch (err) {
          console.error('Error loading spaces:', err);
          toast.error('Error loading spaces!');
          break;
        }
        dispatch({
          type: 'space.importPageFromAPI',
          responseBody: response.data,
        });
        if (!response.data.next) {
          break;
        }
        page += 1;
      }
    })();

    // Load all reference rulers from the floorplan api
    (async () => {
      let page = 1;
      while (true) {
        let response;
        try {
          response = await FloorplanAPI.listReferenceRulers(
            client,
            plan.id,
            page
          );
        } catch (err) {
          console.error('Error loading reference rulers:', err);
          toast.error('Error loading reference rulers!');
          break;
        }
        dispatch({
          type: 'reference.importReferenceRulerPageFromAPI',
          responseBody: response.data,
        });
        if (!response.data.next) {
          break;
        }
        page += 1;
      }
    })();

    // Load all reference heights from the floorplan api
    (async () => {
      let page = 1;
      while (true) {
        let response;
        try {
          response = await FloorplanAPI.listReferenceHeights(
            client,
            plan.id,
            page
          );
        } catch (err) {
          console.error('Error loading reference heights:', err);
          toast.error('Error loading reference heights!');
          break;
        }
        dispatch({
          type: 'reference.importReferenceHeightPageFromAPI',
          responseBody: response.data,
        });
        if (!response.data.next) {
          break;
        }
        page += 1;
      }
    })();

    // Load all photo groups from the floorplan api
    (async () => {
      let page = 1;
      while (true) {
        let response;
        try {
          response = await FloorplanAPI.listPhotoGroups(client, plan.id, page);
        } catch (err) {
          console.error('Error loading photo groups:', err);
          toast.error('Error loading photo groups!');
          break;
        }
        dispatch({
          type: 'photoGroup.importPageFromAPI',
          responseBody: response.data,
        });
        if (!response.data.next) {
          break;
        }
        page += 1;
      }
    })();
  }, [client, plan.id]);
  useEffect(() => {
    if (!isWallsEnabled) {
      return;
    }

    // Load all wall segments from the floorplan api
    (async () => {
      let page = 1;
      while (true) {
        let response;
        try {
          response = await FloorplanAPI.listWallSegments(client, plan.id, page);
        } catch (err) {
          console.error('Error loading wall segments:', err);
          toast.error('Error loading wall segments!');
          break;
        }
        const isFinalPage = !response.data.next;
        dispatch({
          type: 'wallSegment.importPageFromAPI',
          responseBody: response.data,
          isFinalPage,
        });
        if (isFinalPage) {
          break;
        }
        page += 1;
      }
    })();
  }, [client, plan.id, isWallsEnabled]);

  const [state, dispatch] = useReducer(reducer, initialState);

  // After fetching the plan data, fetch the page hierarchy data for all spaces within the floor
  //
  // This is used to populate all the "coreSpace" prefixed fields on all "Space" objects within the
  // editor.
  useEffect(() => {
    // FIXME: instead of using this flag, use an abortcontroller here instead.
    let mounted = true;

    dispatch({ type: 'spaceHierarchyData.begin' });

    CoreAPI.spacesHierarchyWithinSpace(client, plan.floor.id)
      .then((response) => {
        if (!mounted) {
          return;
        }
        dispatch({
          type: 'spaceHierarchyData.complete',
          data: response.data,
        });
      })
      .catch((err) => {
        if (!mounted) {
          return;
        }
        dispatch({ type: 'spaceHierarchyData.error' });
      });

    return () => {
      mounted = false;
    };
  }, [client, plan]);

  const onUpdateViewportSize = useCallback(() => {
    const viewportElement = viewportElementRef.current;
    if (!viewportElement) {
      return;
    }
    const bbox = viewportElement.getBoundingClientRect();
    const { width, height } = bbox;

    const viewport = floorplanRef.current.getViewport();
    floorplanRef.current.changeViewport({ ...viewport, width, height });
  }, []);

  const onZoomToFitClick = useCallback(() => {
    if (!floorplanRef.current) {
      return;
    }

    floorplanRef.current.zoomToFit();
  }, []);

  // Update viewport size on first render
  useEffect(() => {
    onUpdateViewportSize();
    onZoomToFitClick();
  }, [onUpdateViewportSize, onZoomToFitClick]);

  // Update the title of the page to include the floor name
  useEffect(() => {
    document.title = `${plan.floor.name} - Density Planner`;
    return () => {
      document.title = 'Density Planner';
    };
  }, [plan.floor.name]);

  // SIDE EFFECT: When the sensor position changes, recompute the coverage extents
  const coverageIntersectionProcessing = useRef(false);
  const coverageIntersectionProcessingWorker = useRef<Worker | null>(null);
  const coverageIntersectionProcessingWorkerWrapped = useRef<Remote<
    import('lib/coverage-intersection-worker').CoverageIntersectionWorker
  > | null>(null);
  useEffect(() => {
    // Don't run the calculation until all walls have been received from the server
    // Otherwise it's possible that the wall data the below calculation relies on might change halfway
    // through it running
    if (!state.wallsFullyPopulated) {
      return;
    }

    const sensorIdsWithoutCoverageVectors = Array.from(
      state.planSensorCoverageIntersectionVectors
    )
      .filter(([sensorId, value]) => {
        // Don't recompute coverage intersection vectors that already exist
        if (value !== 'empty') {
          return false;
        }

        // Don't compute sensor coverage intersection vectors for non oa sensors
        const sensor = state.planSensors.items.get(sensorId);
        if (!sensor) {
          return false;
        }
        if (sensor.type !== 'oa') {
          return false;
        }

        return true;
      })
      .map(([sensorId, _v]) => sensorId);

    // Only start / stop the worker if coverage intersections aren't currently being run
    // Otherwise terminating the worker early will stop it in the middle of calculations
    if (!coverageIntersectionProcessing.current) {
      if (sensorIdsWithoutCoverageVectors.length > 0) {
        // Start up the sensor coverage intersection processing worker
        if (!coverageIntersectionProcessingWorker.current) {
          coverageIntersectionProcessingWorker.current = new Worker(
            new URL('lib/coverage-intersection-worker', import.meta.url),
            {
              name: 'coverage-intersection-worker',
            }
          );
        }
        if (!coverageIntersectionProcessingWorkerWrapped.current) {
          coverageIntersectionProcessingWorkerWrapped.current = wrap(
            coverageIntersectionProcessingWorker.current
          );
        }
      } else {
        // Ensure worker is terminated if coverage extents are disabled
        if (coverageIntersectionProcessingWorkerWrapped.current) {
          coverageIntersectionProcessingWorkerWrapped.current[releaseProxy]();
          coverageIntersectionProcessingWorkerWrapped.current = null;
        }
        if (coverageIntersectionProcessingWorker.current) {
          coverageIntersectionProcessingWorker.current.terminate();
          coverageIntersectionProcessingWorker.current = null;
        }
        return;
      }
    }

    if (!coverageIntersectionProcessingWorkerWrapped.current) {
      return;
    }
    const workerWrapped = coverageIntersectionProcessingWorkerWrapped.current;

    // No work to do? Bail early.
    if (sensorIdsWithoutCoverageVectors.length === 0) {
      return;
    }

    dispatch({
      type: 'sensorCoverageIntersectionVectors.beginCalculating',
      sensorIds: sensorIdsWithoutCoverageVectors,
    });

    const sensorsWithoutCoverageVectors = sensorIdsWithoutCoverageVectors
      .map((sensorId) => state.planSensors.items.get(sensorId))
      .filter((sensor) => sensor) as Array<PlanSensor>;

    (async () => {
      coverageIntersectionProcessing.current = true;

      const coverageIntersectionVectorsBySensor = new Map<
        PlanSensor['id'],
        SensorCoverageIntersectionVectors
      >();
      for (const {
        id,
        position,
        height,
        rotation,
      } of sensorsWithoutCoverageVectors) {
        const result: SensorCoverageIntersectionVectors = await new Promise(
          (resolve) => {
            workerWrapped.oaCoverageIntersectionWorker(
              position,
              height,
              rotation,
              state.heightMap.enabled ? state.heightMap : null,
              FloorplanCollection.list(state.walls),
              CoverageIntersectionSampleRate.FINE,
              proxy(resolve)
            );
          }
        );
        coverageIntersectionVectorsBySensor.set(id, result);
      }

      dispatch({
        type: 'sensorCoverageIntersectionVectors.setResult',
        results: coverageIntersectionVectorsBySensor,
      });

      coverageIntersectionProcessing.current = false;
    })();
  }, [
    state.heightMap,
    state.planSensorCoverageIntersectionVectors,
    state.planSensors,
    state.walls,
    state.wallsFullyPopulated,
    dispatch,
  ]);
  useEffect(() => {
    return () => {
      if (coverageIntersectionProcessingWorkerWrapped.current) {
        coverageIntersectionProcessingWorkerWrapped.current[releaseProxy]();
        coverageIntersectionProcessingWorkerWrapped.current = null;
      }
      if (coverageIntersectionProcessingWorker.current) {
        coverageIntersectionProcessingWorker.current.terminate();
        coverageIntersectionProcessingWorker.current = null;
      }
    };
  }, []);

  // SIDE EFFECT: when an area of concern is created but empty, calculate sensor positions
  const autoLayoutProcessing = useRef(false);
  const autoLayoutProcessingCurrentSensorDone = useRef(false);
  const autoLayoutProcessingWorker = useRef<Worker | null>(null);
  const autoLayoutProcessingWorkerWrapped = useRef<Remote<
    import('lib/auto-layout-worker').AutoLayoutWorker
  > | null>(null);
  useEffect(() => {
    const areasOfConcernThatNeedAutoLayout = FloorplanCollection.list(
      state.areasOfConcern
    ).filter(
      (areaOfConcern) => areaOfConcern.sensorPlacements.type === 'empty'
    );

    if (!autoLayoutProcessing.current) {
      if (areasOfConcernThatNeedAutoLayout.length > 0) {
        // Start up the sensor coverage intersection processing worker
        if (!autoLayoutProcessingWorker.current) {
          autoLayoutProcessingWorker.current = new Worker(
            new URL('lib/auto-layout-worker', import.meta.url),
            {
              name: 'auto-layout-worker',
            }
          );
        }
        if (!autoLayoutProcessingWorkerWrapped.current) {
          autoLayoutProcessingWorkerWrapped.current = wrap(
            autoLayoutProcessingWorker.current
          );
        }
      } else {
        // Ensure worker is terminated if coverage extents are disabled
        if (autoLayoutProcessingWorkerWrapped.current) {
          autoLayoutProcessingWorkerWrapped.current[releaseProxy]();
          autoLayoutProcessingWorkerWrapped.current = null;
        }
        if (autoLayoutProcessingWorker.current) {
          autoLayoutProcessingWorker.current.terminate();
          autoLayoutProcessingWorker.current = null;
        }
        return;
      }
    }

    if (!areasOfConcernThatNeedAutoLayout.length) {
      return;
    }

    if (!autoLayoutProcessingWorkerWrapped.current) {
      return;
    }
    const workerWrapped = autoLayoutProcessingWorkerWrapped.current;

    autoLayoutProcessing.current = true;

    dispatch({
      type: 'areaOfConcern.sensorPlacements.beginCalculating',
      areaOfConcernIds: areasOfConcernThatNeedAutoLayout.map((a) => a.id),
    });

    // Compute the automatically layed out sensors actually within the area of concern
    Promise.all(
      areasOfConcernThatNeedAutoLayout.map((areaOfConcern) => {
        // Compute the new sensor placements
        return new Promise<void>((resolve) => {
          autoLayoutProcessingCurrentSensorDone.current = false;

          workerWrapped.generateSensorPositions(
            areaOfConcern,
            state.heightMap.enabled ? state.heightMap : null,
            FloorplanCollection.list(state.walls),
            proxy((sensorPlacementsRaw, autodetectedRooms, done) => {
              if (autoLayoutProcessingCurrentSensorDone.current) {
                return;
              }

              dispatch({
                type: 'areaOfConcern.sensorPlacements.setData',
                areaOfConcernId: areaOfConcern.id,
                data: sensorPlacementsRaw.map(
                  ([
                    position,
                    heightMeters,
                    angleDegrees,
                    coveragePolygon,
                  ]) => ({
                    positionOffset: [
                      position.x - areaOfConcern.position.x,
                      position.y - areaOfConcern.position.y,
                    ],
                    heightMeters,
                    angleDegrees,
                    coveragePolygon: coveragePolygon.map(([x, y]) => [
                      x - areaOfConcern.position.x,
                      y - areaOfConcern.position.y,
                    ]),
                  })
                ),
                autodetectedRooms: autodetectedRooms.map(
                  ([centerPoint, polygon, sensorPlacements]) => ({
                    centerPoint,
                    polygon,
                    sensorPlacements,
                  })
                ),
                done,
              });
              if (done) {
                autoLayoutProcessingCurrentSensorDone.current = true;
                resolve();
              }
            }),
            proxy((error) => {
              console.error('Error with sensor autolayout:', error);

              dispatch({
                type: 'areaOfConcern.sensorPlacements.failWithError',
                areaOfConcernId: areaOfConcern.id,
                error,
              });

              // If the auto layout process fails, mark it as done so that the next one can run
              autoLayoutProcessingCurrentSensorDone.current = true;
              resolve();
            })
          );
        });
      })
    ).then(() => {
      autoLayoutProcessing.current = false;
    });
  }, [
    state.areasOfConcern,
    state.heightMap,
    state.planSensorCoverageIntersectionVectors,
    state.planSensors,
    state.walls,
    dispatch,
  ]);
  useEffect(() => {
    return () => {
      if (autoLayoutProcessingWorkerWrapped.current) {
        autoLayoutProcessingWorkerWrapped.current[releaseProxy]();
        autoLayoutProcessingWorkerWrapped.current = null;
      }
      if (autoLayoutProcessingWorker.current) {
        autoLayoutProcessingWorker.current.terminate();
        autoLayoutProcessingWorker.current = null;
      }
    };
  }, []);

  // SIDE EFFECT: when plan sensors or spaces are updated, recompute their associated validations
  const validationProcessingWorker = useRef<Worker | null>(null);
  const validationProcessingWorkerWrapped = useRef<Remote<
    import('lib/validation-worker').ValidationWorker
  > | null>(null);
  const validationsProcessing = useRef(false);
  useEffect(() => {
    if (!isValidationEnabled) {
      return;
    }

    const idsWithoutValidations = Array.from(state.validations)
      .filter(([_id, value]) => {
        // Don't recompute validations that already exist
        if (value !== 'empty') {
          return false;
        }
        return true;
      })
      .map(([sensorId, _v]) => sensorId);

    // Only start / stop the worker if validations aren't currently being run
    // Otherwise terminating the worker early will stop it in the middle of calculations
    if (!validationsProcessing.current) {
      if (idsWithoutValidations.length > 0) {
        // Start up the sensor coverage intersection processing worker
        if (!validationProcessingWorker.current) {
          validationProcessingWorker.current = new Worker(
            new URL('lib/validation-worker', import.meta.url),
            {
              name: 'validation-worker',
            }
          );
        }
        if (!validationProcessingWorkerWrapped.current) {
          validationProcessingWorkerWrapped.current = wrap(
            validationProcessingWorker.current
          );
        }
      } else {
        // Ensure worker is terminated if coverage extents are disabled
        if (validationProcessingWorkerWrapped.current) {
          validationProcessingWorkerWrapped.current[releaseProxy]();
          validationProcessingWorkerWrapped.current = null;
        }
        if (validationProcessingWorker.current) {
          validationProcessingWorker.current.terminate();
          validationProcessingWorker.current = null;
        }
        return;
      }
    }

    if (!validationProcessingWorkerWrapped.current) {
      return;
    }
    const workerWrapped = validationProcessingWorkerWrapped.current;

    // No work to do? Bail early.
    if (idsWithoutValidations.length === 0) {
      return;
    }

    dispatch({
      type: 'validations.beginCalculating',
      ids: idsWithoutValidations,
    });

    (async () => {
      validationsProcessing.current = true;

      const validations = new Map<
        string,
        Array<SpaceValidation | SensorValidation>
      >();
      const spacesList = FloorplanCollection.list(state.spaces);
      for (const id of idsWithoutValidations) {
        const planSensor = state.planSensors.items.get(id);
        if (planSensor) {
          const results = await workerWrapped.planSensorValidationWorker(
            planSensor,
            state.walls,
            state.planSensorCoverageIntersectionVectors
          );
          validations.set(id, results);
          continue;
        }

        const space = state.spaces.items.get(id);
        if (space) {
          const results = await workerWrapped.spaceValidationWorker(
            space,
            spacesList
          );
          validations.set(id, results);
          continue;
        }
      }

      dispatch({
        type: 'validations.set',
        validations,
      });

      validationsProcessing.current = false;
    })();
  }, [
    isValidationEnabled,
    state.validations,
    state.planSensors,
    state.spaces,
    state.planSensorCoverageIntersectionVectors,
    state.walls,
  ]);
  useEffect(() => {
    return () => {
      if (validationProcessingWorkerWrapped.current) {
        validationProcessingWorkerWrapped.current[releaseProxy]();
        validationProcessingWorkerWrapped.current = null;
      }
      if (validationProcessingWorker.current) {
        validationProcessingWorker.current.terminate();
        validationProcessingWorker.current = null;
      }
    };
  }, []);

  // SIDE EFFECT: when heatmap layer is enabled, compute heatmap data
  const heatmapStartDate = state.heatmap.enabled
    ? state.heatmap.startDate
    : null;
  const heatmapEndDate = state.heatmap.enabled ? state.heatmap.endDate : null;
  useEffect(() => {
    const abortController = new AbortController();

    const loadAllData = async () => {
      if (!heatmapStartDate || !heatmapEndDate) {
        return;
      }

      // Step 1: Fetch heatmap data from server
      let page = 1;
      let buckets: Array<{
        bucket: string;
        tile_data: Array<[number, number, number]>;
      }> = [];
      while (true) {
        let response;
        dispatch({
          type: 'heatmap.beginLoading',
          page,
          lastBucketTimestamp:
            buckets.length > 0 ? buckets[buckets.length - 1].bucket : null,
        });
        try {
          response = await client.get(
            `v2/floorplans/${plan.id}/heatmap?start_time=${heatmapStartDate}&end_time=${heatmapEndDate}&time_filters=mon%2Btue%2Bwed%2Bthu%2Bfri:0800-1700&consolidated=true&interval=1h&page=${page}&page_size=3`,
            { signal: abortController.signal }
          );
        } catch (err) {
          if ((err as FixMe).name === 'CanceledError') {
            return;
          }
          console.warn('Error fetching heatmap data:', err);
          dispatch({ type: 'heatmap.error' });
          return;
        }

        buckets = [...buckets, ...response.data.results];

        if (response.data.results.length === 0) {
          break;
        }
        page += 1;
      }

      dispatch({ type: 'heatmap.beginComputing' });
      const [globalHeatmapBuffer, globalMax, [minLimit, maxLimit]] =
        Heatmap.computeHeatmapBuffer(buckets, state.floorplan);

      dispatch({
        type: 'heatmap.setResult',
        globalHeatmap: globalHeatmapBuffer,
        globalMax,
        minLimit,
        maxLimit,
      });
    };
    loadAllData();

    return () => {
      abortController.abort();
    };
  }, [
    dispatch,
    client,
    plan,
    heatmapStartDate,
    heatmapEndDate,
    state.floorplan,
  ]);

  // Window resize handling
  useEffect(() => {
    const onResize = () => {
      onUpdateViewportSize();
    };
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [onUpdateViewportSize]);

  // Release expired data
  useEffect(() => {
    const releaseIntervalId = setInterval(() => {
      dispatch({ type: 'algorithm.releaseExpiredAggregateData' });
    }, Seconds.toMilliseconds(10));
    return () => {
      clearInterval(releaseIntervalId);
    };
  }, [dispatch]);

  // Connect to websocket event stream
  const floorplanEventStream = useRef<FloorplanEventStream | null>();
  const [floorplanEventStreamStatus, setFloorplanEventStreamStatus] =
    useState<FloorplanEventStreamStatus>('disconnected');
  useEffect(() => {
    floorplanEventStream.current = new FloorplanEventStream(client, plan.id, {
      reconnectAutomatically: true,
    });
    floorplanEventStream.current.connect();

    floorplanEventStream.current.on('statusChange', (newStatus) => {
      setFloorplanEventStreamStatus(newStatus);
    });

    // If the latest dxf changes, reflect those updates in the interface
    const updateLatestDXF = (message: PlanEventMessageDXF) => {
      if (message.event === 'plan.dxf.deleted') {
        return;
      }
      dispatch({
        type: 'latestDXF.update',
        planDXF: message.plan_dxf,
      });
    };
    floorplanEventStream.current.on(
      'plan.dxf.created',
      (message: PlanEventMessageDXF) => {
        updateLatestDXF(message);
      }
    );
    floorplanEventStream.current.on(
      'plan.dxf.updated',
      (message: PlanEventMessageDXF) => {
        updateLatestDXF(message);
      }
    );
    floorplanEventStream.current.on(
      'plan.export.updated',
      (message: PlanEventMessageExport) => {
        if (message.event === 'plan.export.deleted') {
          return;
        }
        dispatch({
          type: 'export.update',
          planExport: message.plan_export,
        });
      }
    );

    if (!isAutoSavingDisabled) {
      floorplanEventStream.current.on(
        'plan.updated',
        (message: PlanEventMessagePlan) => {
          dispatch({ type: 'websocket.event.plan', message });
        }
      );

      const handleSensorEvent = (message: PlanEventMessageSensor) =>
        dispatch({ type: 'websocket.event.sensor', message });
      floorplanEventStream.current.on('plan.sensor.created', handleSensorEvent);
      floorplanEventStream.current.on('plan.sensor.updated', handleSensorEvent);
      floorplanEventStream.current.on('plan.sensor.deleted', handleSensorEvent);

      const handleSpaceEvent = (message: PlanEventMessageSpace) =>
        dispatch({ type: 'websocket.event.space', message });
      floorplanEventStream.current.on('plan.space.created', handleSpaceEvent);
      floorplanEventStream.current.on('plan.space.updated', handleSpaceEvent);
      floorplanEventStream.current.on('plan.space.deleted', handleSpaceEvent);

      const handleReferenceRulerEvent = (message: PlanEventMessageRuler) =>
        dispatch({ type: 'websocket.event.referenceRuler', message });
      floorplanEventStream.current.on(
        'plan.ruler.created',
        handleReferenceRulerEvent
      );
      floorplanEventStream.current.on(
        'plan.ruler.updated',
        handleReferenceRulerEvent
      );
      floorplanEventStream.current.on(
        'plan.ruler.deleted',
        handleReferenceRulerEvent
      );

      const handleReferenceHeightEvent = (message: PlanEventMessageHeight) =>
        dispatch({ type: 'websocket.event.referenceHeight', message });
      floorplanEventStream.current.on(
        'plan.reference_height.created',
        handleReferenceHeightEvent
      );
      floorplanEventStream.current.on(
        'plan.reference_height.updated',
        handleReferenceHeightEvent
      );
      floorplanEventStream.current.on(
        'plan.reference_height.deleted',
        handleReferenceHeightEvent
      );

      const handlePhotoEvent = (message: PlanEventMessagePhoto) =>
        dispatch({ type: 'websocket.event.photo', message });
      floorplanEventStream.current.on('plan.photo.created', handlePhotoEvent);
      floorplanEventStream.current.on('plan.photo.updated', handlePhotoEvent);
      floorplanEventStream.current.on('plan.photo.deleted', handlePhotoEvent);

      const handlePhotoGroupEvent = (message: PlanEventMessagePhotoGroup) =>
        dispatch({ type: 'websocket.event.photoGroup', message });
      floorplanEventStream.current.on(
        'plan.photo_group.created',
        handlePhotoGroupEvent
      );
      floorplanEventStream.current.on(
        'plan.photo_group.updated',
        handlePhotoGroupEvent
      );
      floorplanEventStream.current.on(
        'plan.photo_group.deleted',
        handlePhotoGroupEvent
      );
    }

    return () => {
      if (floorplanEventStream.current) {
        floorplanEventStream.current.disconnect();
        floorplanEventStream.current = null;
      }
    };
  }, [client, plan.id, isAutoSavingDisabled]);

  // The "mutation" function orchestrates an async action being sent to the floorplan api.
  // 1. The initial action is dispatched as an optimistic update
  // 2. The interface is locked
  // 3. The "transaction" function is run.
  //    If successful:
  //      4. The interface is unlocked.
  //    If unsuccessful:
  //      4. The "rollback" action is applied
  //      5. The interface is unlocked
  //
  // The `objectIds` parameter allows one to pass through all underlying database row ids into the
  // mutation so that if they change, planner can take that into account for subsequent undos /
  // redos. A situation where this is important:
  // 1. A user creates a sensor
  // 2. A user moves that sensor
  // 3. A user deletes that sensor
  // 4. A user presses undo (creating that sensor again). This sensor has a new database generated
  //    id, which does not match the id of the sensor created in step #1
  // 5. A user presses undo again. This is where things get complex - this update needs to apply to
  //    the sensor id from step #4, not the id from step #1
  //
  // The `objectIds` mechanism solves this by allowing the generated actions to be parameterized
  // with the ids that it uses. And when the id of an object changes, the object ids list is also
  // effected.
  const mutationRunning = useRef(false);
  const mutation = useCallback(
    async (options: MutationOptions) => {
      const objectIds = options.objectIds || [];

      if (
        floorplanEventStream.current &&
        floorplanEventStream.current.status !== 'connected'
      ) {
        toast.error('Cannot perform mutation when disconnected from server!');
        return;
      }
      if (mutationRunning.current) {
        toast.error('Another mutation is being saved, please wait...');
        return;
      }
      mutationRunning.current = true;

      const mutationBeginAction = {
        type: 'mutation.begin' as const,
        objectIds,
        options,
      };
      const initialAction = options.initialActionCreator(objectIds);

      dispatch(mutationBeginAction);
      let nextState = reducer(state, mutationBeginAction);
      dispatch(initialAction);
      nextState = reducer(nextState, initialAction);

      // If syncing changes to the server are disabled, then bail out early
      if (isAutoSavingDisabled) {
        dispatch({ type: 'mutation.commit' });
        mutationRunning.current = false;
        return;
      }

      try {
        await options.syncToAPI(nextState, 'forwards', objectIds);
      } catch (err: FixMe) {
        if (err.name === 'MutationFailureError') {
          toast.error(err.message);
        } else {
          toast.error('Error performing action!');
        }
        console.warn('Error applying mutation, rolling back:', err);
        const rollbackAction = options.rollbackActionCreator(objectIds);
        dispatch({ type: 'mutation.rollback' });
        dispatch(rollbackAction);
      }

      dispatch({ type: 'mutation.commit' });
      mutationRunning.current = false;
    },
    [state, isAutoSavingDisabled]
  );

  const onUndoClick = useCallback(() => {
    if (state.savePending || !UndoStack.undoEnabled(state.undoStack)) {
      return;
    }

    mutationRunning.current = true;
    dispatch({ type: 'undoStack.begin' });
    UndoStack.undo(state.undoStack, state, dispatch, isAutoSavingDisabled).then(
      (index) => {
        if (index === state.undoStack.currentIndex) {
          toast.error('Undo failed!');
        }
        dispatch({
          type: 'undoStack.setIndex',
          index,
        });
        mutationRunning.current = false;
      }
    );
  }, [state, dispatch, isAutoSavingDisabled]);

  const onRedoClick = useCallback(() => {
    if (state.savePending || !UndoStack.redoEnabled(state.undoStack)) {
      return;
    }

    mutationRunning.current = true;
    dispatch({ type: 'undoStack.begin' });
    UndoStack.redo(state.undoStack, state, dispatch, isAutoSavingDisabled).then(
      (index) => {
        if (index === state.undoStack.currentIndex) {
          toast.error('Redo failed!');
        }
        dispatch({
          type: 'undoStack.setIndex',
          index,
        });
        mutationRunning.current = false;
      }
    );
  }, [state, dispatch, isAutoSavingDisabled]);

  const onExportClick = useCallback(() => {
    if (!state.floorplanImage) {
      toast.error('Cannot export plan: no floorplan image!');
      return;
    }
    generateExportCanvas(
      state.floorplanImage,
      state.planSensors,
      state.references,
      state.floorplan,
      state.viewport,
      state.measurement,
      state.displayUnit
    ).then((canvas) => {
      storeCanvasToDisk(canvas, plan.floor.name, 'png');
    });
  }, [
    state.displayUnit,
    state.floorplan,
    state.floorplanImage,
    state.measurement,
    state.references,
    state.planSensors,
    state.viewport,
    plan.floor.name,
  ]);

  const onShowRawFloorplanClick = useCallback(() => {
    if (!state.floorplanImage) {
      toast.error('Cannot export plan: no floorplan image!');
      return;
    }
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.href = state.floorplanImage.currentSrc;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [state.floorplanImage]);

  // Register global keyboard shortcuts
  useEffect(() => {
    const mousetrap = new Mousetrap();
    mousetrap.bind('esc', () => dispatch({ type: 'placement.cancel' }));
    mousetrap.bind('1', () => {
      if (state.locked) {
        return;
      }
      dispatch({ type: 'menu.addSensor', sensorType: 'oa' });
    });
    mousetrap.bind('2', () => {
      if (state.locked) {
        return;
      }
      dispatch({ type: 'menu.addSensor', sensorType: 'entry' });
    });
    mousetrap.bind('3', () => {
      if (state.locked) {
        return;
      }
      dispatch({ type: 'menu.addSpace', shape: 'box' });
    });
    mousetrap.bind('4', () => {
      if (state.locked) {
        return;
      }
      dispatch({ type: 'menu.addSpace', shape: 'circle' });
    });
    mousetrap.bind('5', () => {
      if (state.locked) {
        return;
      }
      dispatch({ type: 'menu.addSpace', shape: 'polygon' });
    });
    mousetrap.bind(['del', 'backspace'], () => {
      if (state.locked) {
        return;
      }

      // NOTE: photo groups have a different focusing mechanism so that it can be focused while
      // other things are also focused
      if (state.focusedPhotoGroupId) {
        const focusedPhotoGroup = state.photoGroups.items.get(
          state.focusedPhotoGroupId
        );
        if (!focusedPhotoGroup) {
          return;
        }
        mutation({
          objectIds: [focusedPhotoGroup.id],
          initialActionCreator: ([photoGroupId]) => ({
            type: 'photoGroup.remove',
            id: photoGroupId,
          }),
          rollbackActionCreator: ([photoGroupId]) => ({
            type: 'placement.addPhotoGroup',
            photoGroup: focusedPhotoGroup,
            photoGroupId,
          }),
          syncToAPI: async (state, direction, [photoGroupId]) =>
            syncSerializedPhotoGroupDeleteToAPI(
              client,
              plan.id,
              state,
              focusedPhotoGroup,
              photoGroupId,
              direction,
              dispatch
            ),
        });
        return;
      }

      if (!state.focusedObject) {
        return;
      }
      const focusedObject = state.focusedObject;

      switch (focusedObject.type) {
        case 'sensor':
          const focusedSensor = state.planSensors.items.get(focusedObject.id);
          if (!focusedSensor) {
            return;
          }
          mutation({
            objectIds: [focusedSensor.id],
            initialActionCreator: ([sensorId]) => ({
              type: 'sensor.remove',
              id: sensorId,
            }),
            rollbackActionCreator: ([sensorId]) => ({
              type: 'placement.addSensor',
              sensor: focusedSensor,
              sensorId,
            }),
            syncToAPI: async (state, direction, [sensorId]) =>
              syncPlanSensorDeleteToAPI(
                client,
                plan.id,
                state,
                focusedSensor,
                sensorId,
                direction,
                dispatch
              ),
          });
          break;
        case 'areaofconcern':
          // TODO: update this once areas of concern are saved to the api
          dispatch({ type: 'menu.removeFocusedObject' });
          break;
        case 'space':
          const focusedSpace = state.spaces.items.get(focusedObject.id);
          if (!focusedSpace) {
            return;
          }
          mutation({
            objectIds: [focusedSpace.id],
            initialActionCreator: ([spaceId]) => ({
              type: 'space.remove',
              id: spaceId,
            }),
            rollbackActionCreator: ([spaceId]) => ({
              type: 'placement.addSpace',
              space: focusedSpace,
              spaceId,
            }),
            syncToAPI: async (state, direction, [spaceId]) =>
              syncSpaceDeleteToAPI(
                client,
                plan.id,
                state,
                focusedSpace,
                spaceId,
                direction,
                dispatch
              ),
          });
          break;
      }
    });
    mousetrap.bind('R', () => {
      if (state.locked) {
        return;
      }
      dispatch({ type: 'menu.addReference', referenceType: 'ruler' });
    });
    mousetrap.bind('H', () => {
      if (
        state.locked ||
        !state.heightMap.enabled ||
        !state.planning.showHeights
      ) {
        return;
      }
      dispatch({ type: 'menu.addReference', referenceType: 'height' });
    });
    mousetrap.bind('x', () => dispatch({ type: 'menu.flipRenderOrder' }));
    mousetrap.bind(['command+z', 'ctrl+z'], (e) => {
      if (state.locked) {
        return;
      }
      if (state.wallsEdit.active) {
        return;
      }
      e.preventDefault();
      onUndoClick();
    });
    mousetrap.bind(['command+shift+z', 'ctrl+shift+z'], (e) => {
      if (state.locked) {
        return;
      }
      if (state.wallsEdit.active) {
        return;
      }
      e.preventDefault();
      onRedoClick();
    });
    return () => {
      mousetrap.reset();
    };
  }, [
    client,
    onUndoClick,
    onRedoClick,
    mutation,
    plan.id,
    state.focusedObject,
    state.focusedPhotoGroupId,
    state.photoGroups.items,
    state.planSensors,
    state.spaces,
    state.locked,
    state.wallsEdit.active,
    state.heightMap.enabled,
    state.planning.showHeights,
  ]);

  // When loading the editor as a regular user, lock it by default so that users cannot
  // edit entities until they explicitly unlock it.
  useEffect(() => {
    if (!isAutoSavingDisabled) {
      dispatch({ type: 'lock' });
    }
  }, [isAutoSavingDisabled, dispatch]);

  const onGeoTiffLoaded = useCallback(
    (tiePoint: GeoTiffTiepoint, scale: number) => {
      dispatch({
        type: 'heightMapImport.setGeoTiffOffsets',
        tiePoint,
        scale,
      });
    },
    [dispatch]
  );

  // Globally add a way that cypress can interrogate the editor's state
  // This is used by the planning tab "page object" in the cypress tests
  if (
    process.env.REACT_APP_ENABLE_EDITOR_GET_STATE &&
    process.env.REACT_APP_ENABLE_EDITOR_GET_STATE.toLowerCase() === 'true'
  ) {
    (window as any).editorGetState = () => state;
    (window as any).editorGetFloorplanRef = () => floorplanRef;
  }

  const focusedSensor = State.getFocusedSensor(state);
  const focusedAreaOfConcern = State.getFocusedAreaOfConcern(state);
  const focusedSpace = State.getFocusedSpace(state);
  const focusedPhotoGroup = State.getFocusedPhotoGroup(state);

  const topAppBar = (
    <DarkTheme>
      <AppBar
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              onClick={(e) => onNavigateBackToList(e.metaKey || e.ctrlKey)}
              trailingIcon={
                <Icons.ArrowLeftBack size={18} color={dust.Gray200} />
              }
              size="medium"
              type="cleared"
            />
            <span style={{ marginLeft: 8 }}>
              <FloorNameDropdown
                client={client}
                name={plan.floor.name}
                parentBuildingId={plan.floor.parent_id}
                editNameLoading={editNameLoading}
                onDropdownOpened={() => {
                  Analytics.track('Floor Name Sibling Dropdown Opened', {
                    spaceId: plan.floor.id,
                    parentSpaceId: plan.floor.parent_id,
                  });
                }}
                onEditName={(newName) => {
                  Analytics.track('Edit Floor Name', {
                    spaceId: plan.floor.id,
                  });
                  onEditName(newName);
                }}
                data-cy="floor-name-editable"
              />
            </span>
          </div>
        }
      />
    </DarkTheme>
  );

  let banner: React.ReactNode = null;
  if (isReadOnly) {
    banner = (
      <div className={styles.readOnlyBanner}>
        <Icons.InformationFill size={14} />
        You do not have permission to edit to this plan.
      </div>
    );
  } else if (state.locked) {
    banner = (
      <div className={styles.lockedBanner}>
        This plan is locked.
        <Button
          size="small"
          type="outlined"
          trailingIcon={<Icons.ArrowRightForward size={12} />}
          onClick={() => dispatch({ type: 'unlock' })}
        >
          Unlock and Edit Plan
        </Button>
      </div>
    );
  }

  if (state.scaleEdit.status === 'measuring') {
    return (
      <FloorplanMeasure
        image={state.scaleEdit.floorplanImage}
        measurement={state.measurement}
        onSubmit={async (newMeasurement) => {
          if (state.scaleEdit.status !== 'measuring') {
            return;
          }
          if (!state.measurement) {
            return;
          }

          const oldImage = state.floorplanImage;
          const oldImageKey = state.floorplanImageKey;
          const oldMeasurement = state.measurement;

          const newImage = state.scaleEdit.floorplanImage || oldImage;
          const newImageKey = state.scaleEdit.objectKey || oldImageKey;

          dispatch({ type: 'scaleEdit.submit' });

          // Create a plan update with the image_key from the image which was just uploaded to S3
          mutation({
            initialActionCreator: () => ({
              type: 'scaleAndImage.change',
              measurement: newMeasurement,
              floorplanImage: newImage,
              floorplanImageKey: newImageKey,
            }),
            rollbackActionCreator: () => ({
              type: 'scaleAndImage.change',
              measurement: oldMeasurement,
              floorplanImage: oldImage,
              floorplanImageKey: oldImageKey,
            }),
            syncToAPI: async (state) => {
              const scaleUpdate = state.measurement
                ? Measurement.toFloorplanScaleUpdate(state.measurement)
                : undefined;

              await FloorplanAPI.updateFloorplanImage(
                client,
                plan.id,
                state.floorplanImageKey,
                state.floorplan.width,
                state.floorplan.height,
                scaleUpdate
              );
            },
          });
        }}
        onCancel={() =>
          dispatch({
            type: 'scaleEdit.cancel',
          })
        }
      />
    );
  }
  if (state.scaleEdit.status === 'image_registration') {
    if (!state.measurement) {
      return null;
    }
    if (!state.floorplanImage) {
      return null;
    }
    return (
      <FloorplanImageRegistration
        oldImage={state.floorplanImage}
        newImage={state.scaleEdit.floorplanImage}
        sensors={FloorplanCollection.list(state.planSensors)}
        spaces={FloorplanCollection.list(state.spaces)}
        floorplan={state.floorplan}
        loading={state.scaleEdit.loading}
        onSubmit={async (
          newMeasurement,
          newRotationDegrees,
          newOriginX,
          newOriginY
        ) => {
          if (state.scaleEdit.status !== 'image_registration') {
            return;
          }
          if (!state.measurement) {
            return;
          }

          const oldImage = state.floorplanImage;
          const oldImageKey = state.floorplanImageKey;
          const oldMeasurement = state.measurement;

          const newImage = state.scaleEdit.floorplanImage;
          const newImageKey = state.scaleEdit.objectKey || oldImageKey;

          dispatch({ type: 'scaleEdit.startLoading' });

          // Adjust the image origin_x_pixels/origin_y_pixels and origin_angle_degrees
          // values to offset the image, while leaving the actual floorplan coordinate space
          // unmodified.

          let scaleAndImageAction: Action = {
            type: 'scaleAndImage.change' as const,
            measurement: newMeasurement,
            floorplanImage: newImage,
            floorplanImageKey: newImageKey,
            newOriginX,
            newOriginY,
            newRotationDegrees,
          };

          await mutation({
            initialActionCreator: () => scaleAndImageAction,
            rollbackActionCreator: () => ({
              type: 'scaleAndImage.change' as const,
              measurement: oldMeasurement,
              floorplanImage: oldImage,
              floorplanImageKey: oldImageKey,
              newOriginX: state.floorplan.origin.x,
              newOriginY: state.floorplan.origin.x,
              newRotationDegrees: state.floorplan.rotation,
            }),
            syncToAPI: async (state) => {
              const scaleUpdate = state.measurement
                ? Measurement.toFloorplanScaleUpdate(state.measurement)
                : undefined;

              await FloorplanAPI.updateFloorplanImage(
                client,
                plan.id,
                state.floorplanImageKey,
                state.floorplan.width,
                state.floorplan.height,
                scaleUpdate,
                state.floorplan.origin,
                state.floorplan.rotation
              );
            },
          });

          dispatch({ type: 'scaleEdit.submit' });
        }}
        onCancel={() =>
          dispatch({
            type: 'scaleEdit.cancel',
          })
        }
      />
    );
  }

  if (state.latestDXFEdit.active) {
    if (state.latestDXFEdit.loading) {
      return <LoadingOverlay text="Loading..." />;
    }

    // When called, make a request to the floorplan api to update the options associated with a
    // plandxf and reparse the plandxf
    const onChangeDXFOptions = async (options: PlanDXF['options']) => {
      if (!state.latestDXFEdit.active) {
        return;
      }

      const confirmed = await Dialogger.confirm({
        title: 'Recompute',
        prompt:
          'Adjusting sensor layers requires the file to be reprocessed. Are you sure?',
        confirmText: 'Reprocess',
      });
      if (!confirmed) {
        return;
      }

      dispatch({ type: 'latestDXFEdit.beginAsyncOperation' });

      try {
        await FloorplanAPI.updateAndReprocessDXF(
          client,
          plan.id,
          state.latestDXFEdit.planDXF.id,
          options
        );
      } catch (err) {
        toast.error('Error reprocessing dxf!');
        return;
      }

      dispatch({ type: 'latestDXFEdit.cancel' });
    };

    const onChangeLengthUnit = async (
      newLengthUnit: PlanDXF['length_unit']
    ) => {
      if (!state.latestDXFEdit.active) {
        return;
      }
      const currentLengthUnit = state.latestDXFEdit.cadFileUnit;

      dispatch({ type: 'latestDXFEdit.changeUnit', unit: newLengthUnit });
      try {
        await FloorplanAPI.updateDXF(
          client,
          plan.id,
          state.latestDXFEdit.planDXF.id,
          { length_unit: newLengthUnit }
        );
      } catch (err) {
        toast.error('Error saving scale!');
        dispatch({
          type: 'latestDXFEdit.changeUnit',
          unit: currentLengthUnit,
        });
      }
    };

    const onChangeScale = async (newScale: PlanDXF['scale']) => {
      if (!state.latestDXFEdit.active) {
        return;
      }
      const currentScale = state.latestDXFEdit.cadFileScale;

      dispatch({ type: 'latestDXFEdit.changeScale', scale: newScale });
      try {
        await FloorplanAPI.updateDXF(
          client,
          plan.id,
          state.latestDXFEdit.planDXF.id,
          { scale: newScale }
        );
      } catch (err) {
        toast.error('Error saving scale!');
        dispatch({ type: 'latestDXFEdit.changeScale', scale: currentScale });
      }
    };

    if (state.floorplanImage) {
      return (
        <CADImport
          mode="update"
          image={state.floorplanImage}
          sensors={FloorplanCollection.list(state.planSensors)}
          initialFloorplan={state.floorplan}
          floorplanCADOrigin={state.latestDXFEdit.floorplanCADOrigin}
          planDXF={state.latestDXFEdit.planDXF}
          cadFileUnit={state.latestDXFEdit.cadFileUnit}
          cadFileScale={state.latestDXFEdit.cadFileScale}
          pixelsPerCADUnit={state.latestDXFEdit.pixelsPerCADUnit}
          dxfParseOptions={state.latestDXFEdit.parseOptions}
          operationType={state.latestDXFEdit.operationType}
          displayUnit={state.displayUnit}
          onDragMoveFloorplanCADOrigin={(coords) => {
            dispatch({
              type: 'latestDXFEdit.changeFloorplanCADOrigin',
              coords,
            });
          }}
          onChangeCADFileUnit={onChangeLengthUnit}
          onChangeCADFileScale={onChangeScale}
          onChangeOASensorLayer={(layerName) => {
            if (!state.latestDXFEdit.active) {
              return;
            }
            onChangeDXFOptions({
              ...state.latestDXFEdit.planDXF.options,
              oa: {
                ...state.latestDXFEdit.planDXF.options.oa,
                layer: layerName,
              },
            });
          }}
          onChangeEntrySensorLayer={(layerName) => {
            if (!state.latestDXFEdit.active) {
              return;
            }
            onChangeDXFOptions({
              ...state.latestDXFEdit.planDXF.options,
              entry: {
                ...state.latestDXFEdit.planDXF.options.entry,
                layer: layerName,
              },
            });
          }}
          onChangeOperationType={(operationType) =>
            dispatch({
              type: 'latestDXFEdit.changeOperationType',
              operationType,
            })
          }
          onSubmit={async (
            floorplanChanges,
            _floorplan,
            floorplanCADOrigin,
            cadFileUnitOrDefault,
            cadFileScaleOrDefault,
            pixelsPerCADUnit
          ) => {
            if (!state.latestDXFEdit.active) {
              return;
            }

            if (!state.floorplanImage) {
              throw new Error('Floorplan Image is unset.');
            }

            let imageResizeScale = 1;
            let newBaseImage = state.floorplanImage;

            const oldBaseImageKey = state.floorplanImageKey;
            let newBaseImageKey = oldBaseImageKey;

            // Update the floorplan image, if need be
            if (state.latestDXFEdit.operationType !== 'sensors') {
              dispatch({ type: 'latestDXFEdit.beginAsyncOperation' });

              // Get the full image asset url
              const fullImageAsset = state.latestDXFEdit.planDXF.assets.find(
                (asset) => {
                  return (
                    asset.name === 'full_image' &&
                    asset.content_type === 'image/png'
                  );
                }
              );
              if (!fullImageAsset) {
                throw new Error(
                  `Cannot find full_image asset with content type of image/png for ${state.latestDXFEdit.planDXF.id}!`
                );
              }

              // Wait for full image asset to load
              const fullImageAssetImage = new Image();
              fullImageAssetImage.crossOrigin = 'anonymous';
              fullImageAssetImage.src = cacheBustImageUrl(
                fullImageAsset.object_url
              );
              await new Promise((resolve) =>
                fullImageAssetImage.addEventListener('load', resolve)
              );

              // Resize the image to be 4096 x 4096 at max
              const result = resizeImageToMaxSize(fullImageAssetImage);
              const resizedImage = result[0];
              imageResizeScale = result[1] * pixelsPerCADUnit;

              // Wait for image to load
              await new Promise((resolve) =>
                resizedImage.addEventListener('load', resolve)
              );

              // Upload floorplan image
              let signedUrlResponse;
              try {
                signedUrlResponse = await FloorplanAPI.imageUpload(client, {
                  floor_id: plan.floor.id,
                  ext: 'png',
                  content_type: 'image/png',
                });
              } catch (err) {
                toast.error('Error getting plan image url!');
                return;
              }

              const {
                key: objectKey,
                signed_url: signedUrl,
                get_signed_url: getSignedUrl,
              } = signedUrlResponse.data;
              newBaseImageKey = objectKey;

              const [, imageData] = resizedImage.src.split(',');
              const imageBytesAsString = atob(imageData);
              const byteArray = new Uint8Array(imageBytesAsString.length);
              for (let i = 0; i < imageBytesAsString.length; i++) {
                byteArray[i] = imageBytesAsString.charCodeAt(i);
              }

              const putImageResponse = await axios.put(
                signedUrl,
                byteArray.buffer,
                {
                  headers: {
                    'Content-Type': 'image/png',
                  },
                }
              );

              if (putImageResponse.status !== 200) {
                throw new Error(
                  `Error uploading image, status code was ${putImageResponse.status}`
                );
              }

              // After uploading the image, use the get signed url to construct the new base image
              newBaseImage = new Image();
              newBaseImage.src = getSignedUrl;
              await new Promise((resolve) =>
                newBaseImage.addEventListener('load', resolve)
              );
            }

            const activeDXFId = state.latestDXFEdit.planDXF.id;

            dispatch({ type: 'latestDXFEdit.beginAsyncOperation' });

            try {
              await FloorplanAPI.updatePlanActiveDXFId(
                client,
                plan.id,
                activeDXFId
              );
            } catch (err) {
              toast.error('Error attaching DXF to floorplan!');
              return;
            }

            const action: Action = {
              type: 'latestDXFEdit.applyFloorplanChanges' as const,
              mode: 'update' as const,
              floorplanChanges,
              newBaseImage,
              newBaseImageKey,
              imageResizeScale,
              pixelsPerCADUnit,
              floorplanCADOrigin,
              activeDXFId,
              cadFileUnitOrDefault,
              cadFileScaleOrDefault,
            };

            const rollbackAction = {
              type: 'latestDXFEdit.invertFloorplanChanges' as const,
              oldPlanSensors: state.planSensors,
              floorplanChanges,
              floorplanImage: state.floorplanImage,
              floorplanImageKey: state.floorplanImageKey,
              floorplanCADOrigin: state.floorplanCADOrigin,
              floorplan: state.floorplan,
              measurement: state.measurement,
            };

            // After running the mutation forwards once, store a list of all the object ids that
            // were touched as a result of this mutation
            //
            // This is used to ensure that when undoing the mutation later, these ids can be
            // properly touched
            //
            // FIXME: think through this and the consqences as it relates to id changing
            let lastForwardsResponseIds: Array<string | null> | null = null;

            mutation({
              initialActionCreator: () => action,
              rollbackActionCreator: () => rollbackAction,
              syncToAPI: async (state, direction) => {
                const planUpdates = [];

                planUpdates.push({
                  type: 'plan.update.image' as const,
                  object_id: plan.id,
                  body: {
                    image_key: state.floorplanImageKey,
                    image_width_pixels: state.floorplan.width,
                    image_height_pixels: state.floorplan.height,
                    origin_x_pixels: state.floorplan.origin.x,
                    origin_y_pixels: state.floorplan.origin.y,
                    origin_angle_degrees: state.floorplan.rotation,
                    ...(state.measurement
                      ? Measurement.toFloorplanScaleUpdate(state.measurement)
                      : {}),
                  },
                });

                for (const change of floorplanChanges) {
                  switch (change.type) {
                    case 'addition':
                      if (direction === 'forwards') {
                        const planSensor =
                          PlanSensor.createFromCADSensorPlacement(
                            change.data,
                            state.planSensors,
                            state.floorplan,
                            state.floorplanCADOrigin,
                            cadFileUnitOrDefault,
                            cadFileScaleOrDefault
                          );

                        planUpdates.push({
                          type: 'plan.sensor.create' as const,
                          body: PlanSensor.toFloorplanSensor(planSensor),
                        });
                      } else {
                        // When reversing an addition, look at the list of ids returned from the
                        const id = lastForwardsResponseIds
                          ? lastForwardsResponseIds[
                              floorplanChanges.indexOf(change)
                            ]
                          : null;
                        if (id) {
                          planUpdates.push({
                            type: 'plan.sensor.delete' as const,
                            object_id: id,
                          });
                        } else {
                          console.warn(
                            `Unable to get id for plansensor that was initially created with cad id ${change.data.cadId} - this might mean this sensor will not be removed when the dxf import operation is undone!`
                          );
                        }
                      }
                      break;
                    case 'deletion':
                      if (direction === 'forwards') {
                        planUpdates.push({
                          type: 'plan.sensor.delete' as const,
                          object_id: change.data.id,
                        });
                      } else {
                        planUpdates.push({
                          type: 'plan.sensor.create' as const,
                          body: PlanSensor.toFloorplanSensor(change.data),
                        });
                      }
                      break;
                    case 'modification':
                      const planSensor = state.planSensors.items.get(
                        change.oldData.id
                      );
                      if (!planSensor) {
                        console.warn(
                          `Could not find plan sensor ${change.oldData.id} in state, skipping update...`
                        );
                        continue;
                      }
                      const floorplanSensor =
                        PlanSensor.toFloorplanSensor(planSensor);
                      planUpdates.push({
                        type: 'plan.sensor.update' as const,
                        object_id: change.oldData.id,
                        body: {
                          cad_id: floorplanSensor.cad_id,
                          sensor_serial_number:
                            floorplanSensor.sensor_serial_number,
                          height_meters: floorplanSensor.height_meters,
                          rotation: floorplanSensor.rotation,
                          centroid_from_origin_x_meters:
                            floorplanSensor.centroid_from_origin_x_meters,
                          centroid_from_origin_y_meters:
                            floorplanSensor.centroid_from_origin_y_meters,
                        },
                      });
                      break;
                  }
                }

                const response = await FloorplanAPI.bulk(
                  client,
                  plan.id,
                  planUpdates
                );
                if (direction === 'forwards') {
                  lastForwardsResponseIds = response.data.ids;
                } else {
                  lastForwardsResponseIds = null;
                }
              },
            });
          }}
          onCancel={() => dispatch({ type: 'latestDXFEdit.cancel' })}
        />
      );
    } else {
      return (
        <CADImport
          mode="create"
          cadFileUnit={state.latestDXFEdit.cadFileUnit}
          planDXF={state.latestDXFEdit.planDXF}
          cadFileScale={state.latestDXFEdit.cadFileScale}
          pixelsPerCADUnit={state.latestDXFEdit.pixelsPerCADUnit}
          dxfParseOptions={state.latestDXFEdit.parseOptions}
          displayUnit={state.displayUnit}
          onChangeCADFileUnit={onChangeLengthUnit}
          onChangeCADFileScale={onChangeScale}
          onChangeOASensorLayer={(layerName) => {
            if (!state.latestDXFEdit.active) {
              return;
            }
            onChangeDXFOptions({
              ...state.latestDXFEdit.planDXF.options,
              oa: {
                ...state.latestDXFEdit.planDXF.options,
                layer: layerName,
              },
            });
          }}
          onChangeEntrySensorLayer={(layerName) => {
            if (!state.latestDXFEdit.active) {
              return;
            }
            onChangeDXFOptions({
              ...state.latestDXFEdit.planDXF.options,
              oa: {
                ...state.latestDXFEdit.planDXF.options,
                layer: layerName,
              },
            });
          }}
          onSubmit={async (
            floorplanChanges,
            _floorplan,
            floorplanCADOrigin,
            cadFileUnitOrDefault,
            cadFileScaleOrDefault,
            pixelsPerCADUnit
          ) => {
            if (!state.latestDXFEdit.active) {
              return;
            }

            dispatch({ type: 'latestDXFEdit.beginAsyncOperation' });

            // Get the full image asset url
            const fullImageAsset = state.latestDXFEdit.planDXF.assets.find(
              (asset) => {
                return (
                  asset.name === 'full_image' &&
                  asset.content_type === 'image/png'
                );
              }
            );
            if (!fullImageAsset) {
              throw new Error(
                `Cannot find full_image asset with content type of image/png for ${state.latestDXFEdit.planDXF.id}!`
              );
            }

            // Wait for full image asset to load
            const fullImageAssetImage = new Image();
            fullImageAssetImage.crossOrigin = 'anonymous';
            fullImageAssetImage.src = cacheBustImageUrl(
              fullImageAsset.object_url
            );
            await new Promise((resolve) =>
              fullImageAssetImage.addEventListener('load', resolve)
            );

            // Resize the image to be 4096 x 4096 at max
            const [resizedImage, imageResizeScale] =
              resizeImageToMaxSize(fullImageAssetImage);

            // Wait for resized image to load (this _should_ be fast, as the resized image has a
            // base64 src)
            await new Promise((resolve) =>
              resizedImage.addEventListener('load', resolve)
            );

            // Upload floorplan image
            let signedUrlResponse;
            try {
              signedUrlResponse = await FloorplanAPI.imageUpload(client, {
                floor_id: plan.floor.id,
                ext: 'png',
                content_type: 'image/png',
              });
            } catch (err) {
              toast.error('Error uploading image to floorplan api!');
              return;
            }

            const {
              key: objectKey,
              signed_url: signedUrl,
              get_signed_url: getSignedUrl,
            } = signedUrlResponse.data;

            const [, imageData] = resizedImage.src.split(',');
            const imageBytesAsString = atob(imageData);
            const byteArray = new Uint8Array(imageBytesAsString.length);
            for (let i = 0; i < imageBytesAsString.length; i++) {
              byteArray[i] = imageBytesAsString.charCodeAt(i);
            }

            const putImageResponse = await axios.put(
              signedUrl,
              byteArray.buffer,
              {
                headers: {
                  'Content-Type': 'image/png',
                },
              }
            );

            if (putImageResponse.status !== 200) {
              throw new Error(
                `Error uploading image, status code was ${putImageResponse.status}`
              );
            }

            // After uploading the image, use the get signed url to construct the new base image
            const newBaseImage = new Image();
            newBaseImage.src = getSignedUrl;
            await new Promise((resolve) =>
              newBaseImage.addEventListener('load', resolve)
            );

            const activeDXFId = state.latestDXFEdit.planDXF.id;

            const action = {
              type: 'latestDXFEdit.applyFloorplanChanges' as const,
              mode: 'create' as const,
              floorplanChanges,
              newBaseImage,
              newBaseImageKey: objectKey,
              imageResizeScale,
              pixelsPerCADUnit,
              floorplanCADOrigin,
              activeDXFId,
              cadFileUnitOrDefault,
              cadFileScaleOrDefault,
            };
            dispatch(action);
            const nextState = reducer(state, action);

            // Create a plan update with the image_key from the image we just uploaded to S3
            const updates: Array<
              | { type: 'plan.sensor.create'; body: Unsaved<FloorplanV2Sensor> }
              | {
                  type: 'plan.update.image';
                  object_id: FloorplanV2Plan['id'];
                  body: Partial<
                    Pick<
                      FloorplanV2Plan,
                      | 'image_key'
                      | 'image_width_pixels'
                      | 'image_height_pixels'
                      | 'origin_x_pixels'
                      | 'origin_y_pixels'
                      | 'origin_angle_degrees'
                      | 'image_pixels_per_meter'
                      | 'measurement_point_a_x_pixels'
                      | 'measurement_point_a_y_pixels'
                      | 'measurement_point_b_x_pixels'
                      | 'measurement_point_b_y_pixels'
                      | 'measurement_computed_length_meters'
                      | 'measurement_user_entered_length_feet'
                      | 'measurement_user_entered_length_inches'
                    >
                  >;
                }
            > = [];
            updates.push({
              type: 'plan.update.image' as const,
              object_id: plan.id,
              body: {
                image_key: objectKey,
                image_width_pixels: newBaseImage.width,
                image_height_pixels: newBaseImage.height,
                ...(nextState.measurement
                  ? Measurement.toFloorplanScaleUpdate(nextState.measurement)
                  : {}),
              },
            });

            // Also include all the plan sensor metadata in that update
            const planSensorIds = [];
            for (const planSensor of FloorplanCollection.list(
              nextState.planSensors
            )) {
              updates.push({
                type: 'plan.sensor.create' as const,
                body: PlanSensor.toFloorplanSensor(planSensor),
              });
              planSensorIds.push(planSensor.id);
            }

            let response;
            try {
              response = await FloorplanAPI.bulk(client, plan.id, updates);
            } catch (err) {
              console.error(
                'Error saving sensors and image metadata to floorplan!',
                err
              );
              toast.error(
                'Error saving sensors and image metadata to floorplan!'
              );
              return;
            }

            // After performing the update, update all plan sensor ids to match their expected
            // values which were returned from the server
            for (let i = 0; i < planSensorIds.length; i += 1) {
              dispatch({
                type: 'sensor.changeId',
                oldId: planSensorIds[i],
                newId: response.data.ids[1 + i],
              });
            }

            try {
              await FloorplanAPI.updatePlanActiveDXFId(
                client,
                plan.id,
                activeDXFId
              );
            } catch (err) {
              toast.error('Error attaching DXF to floorplan!');
              return;
            }
          }}
          onCancel={() => dispatch({ type: 'latestDXFEdit.cancel' })}
        />
      );
    }
  }

  if (
    state.latestDXF &&
    !state.activeDXFId &&
    state.measurement &&
    state.measurement.computedScale === 0
  ) {
    return (
      <Fragment>
        {topAppBar}
        <div className={styles.noFloorplanImageMessage}>
          <div className={styles.noFloorplanImageMessageHeader}>
            DXF Processing:
          </div>
          <div className={styles.noFloorplanImageMessageBody}>
            <LatestDXFStatus
              state={{ ...state, activeDXFId: null }}
              client={client}
              plan={plan}
              dispatch={dispatch}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  if (!state.floorplanImage) {
    return (
      <Fragment>
        {topAppBar}
        <div className={styles.noFloorplanImageMessage}>
          <div className={styles.noFloorplanImageMessageHeader}>
            No floorplan image
          </div>
          <div className={styles.noFloorplanImageMessageBody}>
            The floorplan image is missing.
          </div>
        </div>
      </Fragment>
    );
  }

  if (state.heightMapImport.view === 'enabled') {
    return (
      <HeightMapImport
        floorplanImage={state.floorplanImage}
        floorplan={state.floorplan}
        displayUnit={state.displayUnit}
        heightMap={state.heightMapImport}
        onGeoTiffLoaded={onGeoTiffLoaded}
        onCancel={() => dispatch({ type: 'heightMapImport.cancel' })}
        onChangeRegistration={(position, rotation) => {
          dispatch({
            type: 'heightMapImport.changeRegistration',
            position,
            rotation,
          });
        }}
        onChangeBounds={(minMeters, maxMeters) => {
          dispatch({
            type: 'heightMapImport.changeBounds',
            minMeters,
            maxMeters,
          });
        }}
        onRotateRight90={() =>
          dispatch({ type: 'heightMapImport.rotateRight90' })
        }
        onChangeOpacity={(opacity) => {
          dispatch({
            type: 'heightMapImport.changeOpacity',
            opacity,
          });
        }}
        onSubmit={(heightMap) => {
          mutation({
            initialActionCreator: () => ({
              type: 'heightMap.change',
              heightMap,
            }),
            rollbackActionCreator: () => ({
              type: 'heightMap.change',
              heightMap: state.heightMap.enabled ? state.heightMap : null,
            }),
            syncToAPI: async (state) => {
              await syncHeightMapToAPI(client, plan.id, state);
            },
          });
        }}
      />
    );
  }

  if (isWallsEnabled && state.wallsEdit.active) {
    return (
      <WallsEditor
        floorplan={state.floorplan}
        floorplanImage={state.floorplanImage}
        wallSegments={state.wallsEdit.walls}
        displayUnit={state.displayUnit}
        onCancel={() => dispatch({ type: 'wallsEditor.cancel' })}
        onSubmit={(walls) => {
          const objectIds = [];
          const forwardsBulkUpdates: Array<
            | {
                type: 'plan.wall_segment.create';
                body: Unsaved<FloorplanV2WallSegment>;
              }
            | {
                type: 'plan.wall_segment.update';
                object_id: FloorplanV2WallSegment['id'];
                body: Partial<FloorplanV2WallSegment>;
              }
            | {
                type: 'plan.wall_segment.delete';
                object_id: FloorplanV2WallSegment['id'];
              }
          > = [];
          const backwardsBulkUpdates: Array<
            | {
                type: 'plan.wall_segment.create';
                body: Unsaved<FloorplanV2WallSegment>;
              }
            | {
                type: 'plan.wall_segment.update';
                object_id: FloorplanV2WallSegment['id'];
                body: Partial<FloorplanV2WallSegment>;
              }
            | {
                type: 'plan.wall_segment.delete';
                object_id: FloorplanV2WallSegment['id'];
              }
          > = [];

          const oldIds = new Set(state.walls.renderOrder);
          const insertedWallSegmentIds = walls.renderOrder.filter(
            (id) => !oldIds.has(id)
          );
          const insertedWallSegments = insertedWallSegmentIds.map((id) =>
            walls.items.get(id)
          );
          for (const wallSegment of insertedWallSegments) {
            if (!wallSegment) {
              continue;
            }

            const floorplanWallSegment =
              WallSegment.toFloorplanWallSegment(wallSegment);
            forwardsBulkUpdates.push({
              type: 'plan.wall_segment.create',
              body: floorplanWallSegment,
            });
            objectIds.push(wallSegment.id);
            backwardsBulkUpdates.push({
              type: 'plan.wall_segment.delete',
              object_id: wallSegment.id,
            });
          }

          const newIds = new Set(walls.renderOrder);
          const removedWallSegmentIds = state.walls.renderOrder.filter(
            (id) => !newIds.has(id)
          );
          const removedWallSegments = removedWallSegmentIds.map((id) =>
            state.walls.items.get(id)
          );

          for (const wallSegment of removedWallSegments) {
            if (!wallSegment) {
              continue;
            }

            forwardsBulkUpdates.push({
              type: 'plan.wall_segment.delete',
              object_id: wallSegment.id,
            });
            objectIds.push(wallSegment.id);

            const floorplanWallSegment =
              WallSegment.toFloorplanWallSegment(wallSegment);
            backwardsBulkUpdates.push({
              type: 'plan.wall_segment.create',
              body: floorplanWallSegment,
            });
          }

          const updatedWallSegmentIds = state.walls.renderOrder.filter(
            (id) => newIds.has(id) && oldIds.has(id)
          );
          const updatedWallSegmentsBeforeAndAfter = updatedWallSegmentIds.map(
            (id) => [state.walls.items.get(id), walls.items.get(id)]
          );

          for (const [
            wallSegmentBefore,
            wallSegmentAfter,
          ] of updatedWallSegmentsBeforeAndAfter) {
            if (!wallSegmentBefore || !wallSegmentAfter) {
              continue;
            }

            const floorplanWallSegmentBefore =
              WallSegment.toFloorplanWallSegment(
                wallSegmentBefore
              ) as FloorplanV2WallSegment;
            const floorplanWallSegmentAfter =
              WallSegment.toFloorplanWallSegment(
                wallSegmentAfter
              ) as FloorplanV2WallSegment;

            if (
              JSON.stringify(floorplanWallSegmentBefore) ===
              JSON.stringify(floorplanWallSegmentAfter)
            ) {
              continue;
            }

            forwardsBulkUpdates.push({
              type: 'plan.wall_segment.update',
              object_id: wallSegmentBefore.id,
              body: floorplanWallSegmentBefore,
            });
            objectIds.push(wallSegmentBefore.id);

            backwardsBulkUpdates.push({
              type: 'plan.wall_segment.update',
              object_id: wallSegmentAfter.id,
              body: floorplanWallSegmentAfter,
            });
          }

          mutation({
            objectIds,
            initialActionCreator: () => ({
              type: 'wallsEditor.save',
              walls,
            }),
            rollbackActionCreator: () => ({
              type: 'wallsEditor.save',
              walls: state.walls,
            }),
            syncToAPI: async (_state, direction, objectIds) => {
              const updates =
                direction === 'forwards'
                  ? forwardsBulkUpdates
                  : backwardsBulkUpdates;

              const rewrittenUpdates = updates.map((update, index) => {
                if (
                  update.type === 'plan.wall_segment.update' ||
                  update.type === 'plan.wall_segment.delete'
                ) {
                  const objectId = objectIds[index];
                  if (objectId) {
                    return { ...update, object_id: objectId };
                  }
                }
                return update;
              });

              const response = await FloorplanAPI.bulk(
                client,
                plan.id,
                rewrittenUpdates
              );

              dispatch({
                type: 'wallSegment.changeIds',
                oldIds: objectIds,
                newIds: response.data.ids,
              });
            },
          });
        }}
        initialImageLineSegmentImport={state.wallsEdit.imageLineSegmentImport}
        onDeactivateImageLineSegmentImport={() =>
          dispatch({ type: 'wallsEditor.completeImageLineSegmentImport' })
        }
      />
    );
  }

  return (
    <Fragment>
      {topAppBar}
      <div className={styles.editor}>
        {/* MAIN VIEW */}
        <div ref={viewportElementRef} className={styles.editorViewport}>
          <Floorplan
            // NOTE: this key is here to reload the floorplan component when the saving
            // feature flag changes. There are some useEffect dependencies that are not set up
            // correctly at the moment in the ObjectLayer that this works around.
            key={`${isAutoSavingDisabled}`}
            ref={floorplanRef}
            image={state.floorplanImage}
            floorplan={state.floorplan}
            width="100%"
            height="100%"
            lengthUnit={state.displayUnit}
            onClickBackground={(evt) => {
              const position = ViewportCoordinates.create(
                evt.data.global.x,
                evt.data.global.y
              );
              dispatch({
                type: 'viewport.mousedown',
                position,
              });
            }}
          >
            {isHeightMapEnabled &&
            state.heightMap.enabled &&
            state.planning.showCeilingHeightMap ? (
              <HeightMapLayer heightMap={state.heightMap} />
            ) : null}
            {isHeightMapEnabled &&
            state.heatmap.enabled &&
            state.heatmap.data.status === 'complete' &&
            state.planning.showHeatMap ? (
              <HeatmapLayer
                heatmapData={state.heatmap.data.globalHeatmap}
                maxMilliseconds={state.heatmap.data.globalMax}
                minLimit={state.heatmap.data.limits.min}
                maxLimit={state.heatmap.data.limits.max}
                opacity={state.heatmap.opacity}
              />
            ) : null}
            {isWallsEnabled && state.planning.showWalls ? (
              <WallSegmentLayer walls={FloorplanCollection.list(state.walls)} />
            ) : null}
            <ObjectPlacementTargetLayer
              placementMode={state.placementMode}
              walls={state.walls}
              sensors={state.planSensors}
              onAddSensor={(sensor) => {
                mutation({
                  objectIds: [sensor.id],
                  initialActionCreator: ([sensorId]) => ({
                    type: 'placement.addSensor',
                    sensor,
                    sensorId,
                  }),
                  rollbackActionCreator: ([sensorId]) => ({
                    type: 'sensor.remove',
                    id: sensorId,
                  }),
                  syncToAPI: async (state, direction, [sensorId]) => {
                    await syncPlanSensorCreateToAPI(
                      client,
                      plan.id,
                      state,
                      sensor,
                      sensorId,
                      direction,
                      dispatch
                    );
                  },
                });
              }}
              onAddAreaOfConcern={(areaOfConcern) =>
                dispatch({
                  type: 'placement.addAreaOfConcern',
                  areaOfConcern,
                  areaOfConcernId: areaOfConcern.id,
                })
              }
              spaces={state.spaces}
              onAddSpace={(space) => {
                mutation({
                  objectIds: [space.id],
                  initialActionCreator: ([spaceId]) => ({
                    type: 'placement.addSpace',
                    space,
                    spaceId,
                  }),
                  rollbackActionCreator: ([spaceId]) => ({
                    type: 'space.remove',
                    id: spaceId,
                  }),
                  syncToAPI: async (state, direction, [spaceId]) => {
                    await syncSpaceCreateToAPI(
                      client,
                      plan.id,
                      state,
                      space,
                      spaceId,
                      direction,
                      dispatch
                    );
                  },
                });
              }}
              onAddReference={(reference) => {
                mutation({
                  objectIds: [reference.id],
                  initialActionCreator: ([referenceId]) => ({
                    type: 'placement.addReference',
                    reference,
                    referenceId,
                  }),
                  rollbackActionCreator: ([referenceId]) => ({
                    type: 'reference.remove',
                    id: referenceId,
                  }),
                  syncToAPI: async (state, direction, [referenceId]) => {
                    switch (reference.type) {
                      case 'ruler': {
                        await syncAPIReferenceRulerCreateToAPI(
                          client,
                          plan.id,
                          state,
                          reference,
                          referenceId,
                          direction,
                          dispatch
                        );
                        break;
                      }
                      case 'height': {
                        await syncAPIReferenceHeightCreateToAPI(
                          client,
                          plan.id,
                          state,
                          reference,
                          referenceId,
                          direction,
                          dispatch
                        );
                        break;
                      }
                      default: {
                        throw new Error(
                          `Unable to create reference of type ${
                            (reference as FixMe).type
                          } on server!`
                        );
                      }
                    }
                  },
                });
              }}
              photoGroups={state.photoGroups}
              onAddPhotoGroup={(photoGroup) => {
                mutation({
                  objectIds: [photoGroup.id],
                  initialActionCreator: ([photoGroupId]) => ({
                    type: 'placement.addPhotoGroup',
                    photoGroup,
                    photoGroupId,
                  }),
                  rollbackActionCreator: ([photoGroupId]) => ({
                    type: 'photoGroup.remove',
                    id: photoGroupId,
                  }),
                  syncToAPI: async (state, direction, [photoGroupId]) => {
                    switch (direction) {
                      case 'forwards':
                        const photoGroupFromState =
                          state.photoGroups.items.get(photoGroupId);
                        if (!photoGroupFromState) {
                          throw new Error(
                            `Cannot find photo group ${photoGroupId} to create!`
                          );
                        }

                        // Create the new area object, and then update the locally generated uuid
                        // with a server generated `ppg_xxx` id
                        const photoGroupWrite =
                          PhotoGroup.toFloorplanPhotoGroupWrite(
                            photoGroupFromState
                          );
                        const response = await FloorplanAPI.createPhotoGroup(
                          client,
                          plan.id,
                          photoGroupWrite
                        );
                        dispatch({
                          type: 'photoGroup.changeId',
                          oldId: photoGroup.id,
                          newId: response.data.id,
                        });
                        dispatch({
                          type: 'photoGroup.changeId',
                          oldId: photoGroupId,
                          newId: response.data.id,
                        });
                        break;

                      case 'backwards':
                        await FloorplanAPI.deletePhotoGroup(
                          client,
                          plan.id,
                          photoGroupId
                        );
                        break;
                    }
                  },
                });
              }}
            />
            <ObjectMeasureLayer
              focusedObject={state.focusedObject}
              sensors={state.planSensors}
              heightMap={state.heightMap.enabled ? state.heightMap : null}
              displayUnit={state.displayUnit}
            />
            <ObjectLayerGroup renderOrder={state.renderOrder}>
              {isAreasOfConcernEnabled && state.planning.showAreasOfConcern ? (
                <AreasOfConcernLayer
                  areasOfConcern={FloorplanCollection.listInRenderOrder(
                    state.areasOfConcern
                  )}
                  highlightedObject={state.highlightedObject}
                  focusedObject={state.focusedObject}
                  walls={FloorplanCollection.list(state.walls)}
                  onMouseEnter={(areaOfConcern) => {
                    dispatch({
                      type: 'item.graphic.mouseenter',
                      itemType: 'areaofconcern',
                      itemId: areaOfConcern.id,
                    });
                  }}
                  onMouseLeave={(areaOfConcern) => {
                    dispatch({
                      type: 'item.graphic.mouseleave',
                      itemType: 'areaofconcern',
                      itemId: areaOfConcern.id,
                    });
                  }}
                  onMouseDown={(areaOfConcern, evt) => {
                    const viewportPosition =
                      FloorplanCoordinates.toViewportCoordinates(
                        areaOfConcern.position,
                        state.floorplan,
                        state.viewport
                      );
                    dispatch({
                      type: 'item.graphic.mousedown',
                      itemType: 'areaofconcern',
                      itemId: areaOfConcern.id,
                      itemPosition: viewportPosition,
                      altKey: (evt.data.originalEvent as FixMe).altKey,
                    });
                  }}
                  onDragMove={(areaOfConcern, itemPosition) => {
                    dispatch({
                      type: 'item.graphic.dragmove',
                      itemType: 'areaofconcern',
                      itemId: areaOfConcern.id,
                      itemPosition,
                    });
                  }}
                  onDragOrigin={(areaOfConcern, newOrigin) => {
                    dispatch({
                      type: 'areaOfConcern.changeOriginPosition',
                      id: areaOfConcern.id,
                      origin: newOrigin,
                    });
                  }}
                  onResize={(areaOfConcern, newPosition, newVertices) => {
                    dispatch({
                      type: 'areaOfConcern.resize',
                      id: areaOfConcern.id,
                      position: newPosition,
                      vertices: newVertices,
                    });
                  }}
                />
              ) : null}
              <SensorsLayer
                sensors={FloorplanCollection.listInRenderOrder(
                  state.planSensors
                ).filter((sensor) => {
                  // Don't render entry / oa sensors if they should be hidden
                  if (sensor.type === 'oa' && !state.planning.showOASensors) {
                    return false;
                  }
                  if (
                    sensor.type === 'entry' &&
                    !state.planning.showEntrySensors
                  ) {
                    return false;
                  }
                  return true;
                })}
                locked={state.locked || state.savePending}
                highlightedObject={state.highlightedObject}
                focusedObject={state.focusedObject}
                coverageIntersectionEnabled={
                  isSensorCoverageEnabled &&
                  state.planning.showSensorCoverageExtents
                }
                coverageIntersectionVectors={
                  state.planSensorCoverageIntersectionVectors
                }
                hideOpenAreaCoverage={!state.planning.showSensorCoverage}
                hideSensorLabels={!state.planning.showSensorLabels}
                onMouseEnter={(sensor) => {
                  dispatch({
                    type: 'item.graphic.mouseenter',
                    itemType: 'sensor',
                    itemId: sensor.id,
                  });
                }}
                onMouseLeave={(sensor) => {
                  dispatch({
                    type: 'item.graphic.mouseleave',
                    itemType: 'sensor',
                    itemId: sensor.id,
                  });
                }}
                onMouseDown={(sensor, evt) => {
                  const viewportPosition =
                    FloorplanCoordinates.toViewportCoordinates(
                      sensor.position,
                      state.floorplan,
                      state.viewport
                    );
                  dispatch({
                    type: 'item.graphic.mousedown',
                    itemType: 'sensor',
                    itemId: sensor.id,
                    itemPosition: viewportPosition,
                    altKey: (evt.data.originalEvent as FixMe).altKey,
                  });
                }}
                onDragMove={(sensor, itemPosition) => {
                  mutation({
                    objectIds: [sensor.id],
                    initialActionCreator: ([sensorId]) => ({
                      type: 'item.graphic.dragmove',
                      itemType: 'sensor',
                      itemId: sensorId,
                      itemPosition,
                    }),
                    rollbackActionCreator: ([sensorId]) => ({
                      type: 'item.graphic.dragmove',
                      itemType: 'sensor',
                      itemId: sensorId,
                      itemPosition: sensor.position,
                    }),
                    syncToAPI: async (state, _direction, [sensorId]) => {
                      await syncPlanSensorUpdateToAPI(
                        client,
                        plan.id,
                        state,
                        sensorId,
                        [
                          'centroid_from_origin_x_meters',
                          'centroid_from_origin_y_meters',
                        ]
                      );
                    },
                  });
                }}
                onDragEnd={(sensor) => {
                  dispatch({ type: 'item.graphic.dragend' });
                }}
                onDuplicateSensor={(sensor) => {
                  Analytics.track('Alt Drag To Duplicate', {
                    entity: 'sensor',
                    spaceId: plan.floor.id,
                  });

                  mutation({
                    objectIds: [sensor.id],
                    initialActionCreator: ([sensorId]) => ({
                      type: 'sensor.duplicate',
                      sensor: { ...sensor, id: sensorId },
                    }),
                    rollbackActionCreator: ([sensorId]) => ({
                      type: 'sensor.remove',
                      id: sensorId,
                    }),
                    syncToAPI: async (state, direction, [sensorId]) => {
                      await syncPlanSensorCreateToAPI(
                        client,
                        plan.id,
                        state,
                        sensor,
                        sensorId,
                        direction,
                        dispatch
                      );
                    },
                  });
                }}
              />
              {state.planning.showSpaces ? (
                <SpacesLayer
                  spaces={FloorplanCollection.listInRenderOrder(
                    state.spaces
                  ).sort((a, b) => {
                    const aSizeArea = Space.computeAreaInSquareMeters(a);
                    const bSizeArea = Space.computeAreaInSquareMeters(b);
                    if (aSizeArea === null || bSizeArea === null) {
                      return 0;
                    } else if (aSizeArea > bSizeArea) {
                      return -1;
                    } else {
                      return 1;
                    }
                  })}
                  locked={state.locked || state.savePending}
                  validations={state.validations}
                  highlightedObject={state.highlightedObject}
                  focusedObject={state.focusedObject}
                  spaceOccupancy={state.spaceOccupancy}
                  walls={FloorplanCollection.list(state.walls)}
                  onMouseEnter={(space) => {
                    dispatch({
                      type: 'item.graphic.mouseenter',
                      itemType: 'space',
                      itemId: space.id,
                    });
                  }}
                  onMouseLeave={(space) => {
                    dispatch({
                      type: 'item.graphic.mouseleave',
                      itemType: 'space',
                      itemId: space.id,
                    });
                  }}
                  onMouseDown={(space, evt) => {
                    const viewportPosition =
                      FloorplanCoordinates.toViewportCoordinates(
                        space.position,
                        state.floorplan,
                        state.viewport
                      );
                    dispatch({
                      type: 'item.graphic.mousedown',
                      itemType: 'space',
                      itemId: space.id,
                      itemPosition: viewportPosition,
                      altKey: (evt.data.originalEvent as FixMe).altKey,
                    });
                  }}
                  onDragMove={(space, itemPosition) => {
                    mutation({
                      objectIds: [space.id],
                      initialActionCreator: ([spaceId]) => ({
                        type: 'item.graphic.dragmove',
                        itemType: 'space',
                        itemId: spaceId,
                        itemPosition,
                      }),
                      rollbackActionCreator: ([spaceId]) => ({
                        type: 'item.graphic.dragmove',
                        itemType: 'space',
                        itemId: spaceId,
                        itemPosition: space.position,
                      }),
                      syncToAPI: async (state, _direction, [spaceId]) => {
                        return syncSpaceUpdateToAPI(
                          client,
                          plan.id,
                          state,
                          spaceId,
                          space.shape.type === 'circle'
                            ? [
                                'circle_centroid_x_meters',
                                'circle_centroid_y_meters',
                              ]
                            : ['polygon_verticies']
                        );
                      },
                    });
                  }}
                  onResizeBoxSpace={(
                    space,
                    newPosition,
                    newWidth,
                    newHeight
                  ) => {
                    mutation({
                      objectIds: [space.id],
                      initialActionCreator: ([spaceId]) => ({
                        type: 'space.resize.box',
                        id: spaceId,
                        position: newPosition,
                        width: newWidth,
                        height: newHeight,
                      }),
                      rollbackActionCreator: ([spaceId]) => {
                        if (space.shape.type !== 'box') {
                          throw new Error(
                            'Space shape is not a box, cannot rollback!'
                          );
                        }
                        return {
                          type: 'space.resize.box',
                          id: spaceId,
                          position: space.position,
                          width: space.shape.width,
                          height: space.shape.height,
                        };
                      },
                      syncToAPI: async (state, _direction, [spaceId]) =>
                        syncSpaceUpdateToAPI(client, plan.id, state, spaceId, [
                          'polygon_verticies',
                        ]),
                    });
                  }}
                  onResizeCircleSpace={(space, newPosition, newRadius) => {
                    mutation({
                      objectIds: [space.id],
                      initialActionCreator: ([spaceId]) => ({
                        type: 'space.resize.circle',
                        id: spaceId,
                        position: newPosition,
                        radius: newRadius,
                      }),
                      rollbackActionCreator: ([spaceId]) => {
                        if (space.shape.type !== 'circle') {
                          throw new Error(
                            'Space shape is not a circle, cannot rollback!'
                          );
                        }
                        return {
                          type: 'space.resize.circle',
                          id: spaceId,
                          position: space.position,
                          radius: space.shape.radius,
                        };
                      },
                      syncToAPI: async (state, _direction, [spaceId]) =>
                        syncSpaceUpdateToAPI(client, plan.id, state, spaceId, [
                          'circle_centroid_x_meters',
                          'circle_centroid_y_meters',
                          'circle_radius_meters',
                        ]),
                    });
                  }}
                  onResizePolygonSpace={(space, newPosition, newVertices) => {
                    mutation({
                      objectIds: [space.id],
                      initialActionCreator: ([spaceId]) => ({
                        type: 'space.resize.polygon',
                        id: spaceId,
                        position: newPosition,
                        vertices: newVertices,
                      }),
                      rollbackActionCreator: ([spaceId]) => {
                        if (space.shape.type !== 'polygon') {
                          throw new Error(
                            'Space shape is not a polygon, cannot rollback!'
                          );
                        }
                        return {
                          type: 'space.resize.polygon',
                          id: spaceId,
                          position: space.position,
                          vertices: space.shape.vertices,
                        };
                      },
                      syncToAPI: async (state, _direction, [spaceId]) =>
                        syncSpaceUpdateToAPI(client, plan.id, state, spaceId, [
                          'polygon_verticies',
                        ]),
                    });
                  }}
                  onDuplicateSpace={(space) => {
                    Analytics.track('Alt Drag To Duplicate', {
                      entity: 'space',
                      spaceId: plan.floor.id,
                    });

                    mutation({
                      objectIds: [space.id],
                      initialActionCreator: ([spaceId]) => ({
                        type: 'space.duplicate',
                        space: { ...space, id: spaceId },
                      }),
                      rollbackActionCreator: ([spaceId]) => ({
                        type: 'space.remove',
                        id: spaceId,
                      }),
                      syncToAPI: async (state, direction, [spaceId]) => {
                        await syncSpaceCreateToAPI(
                          client,
                          plan.id,
                          state,
                          space,
                          spaceId,
                          direction,
                          dispatch
                        );
                      },
                    });
                  }}
                />
              ) : null}
            </ObjectLayerGroup>
            {state.planning.showRulers ? (
              <ReferenceRulersLayer
                referenceRulers={FloorplanCollection.listInRenderOrder(
                  state.references
                ).filter((r): r is ReferenceRuler => r.type === 'ruler')}
                locked={state.locked || state.savePending}
                highlightedObject={state.highlightedObject}
                onEndpointsMoved={
                  !state.locked
                    ? (
                        reference,
                        positionA,
                        positionB,
                        distanceLabelPosition
                      ) => {
                        mutation({
                          objectIds: [reference.id],
                          initialActionCreator: ([referenceId]) => ({
                            type: 'reference.rulerPosition.change',
                            id: referenceId,
                            positionA,
                            positionB,
                            distanceLabelPosition,
                          }),
                          rollbackActionCreator: ([referenceId]) => ({
                            type: 'reference.rulerPosition.change',
                            id: reference.id,
                            positionA: reference.positionA,
                            positionB: reference.positionB,
                            distanceLabelPosition:
                              reference.distanceLabelPosition,
                          }),
                          syncToAPI: async (state, _direction, [referenceId]) =>
                            syncAPIReferenceRulerUpdateToAPI(
                              client,
                              plan.id,
                              state,
                              referenceId,
                              [
                                'position_a_x_meters',
                                'position_a_y_meters',
                                'position_b_x_meters',
                                'position_b_y_meters',
                                'position_label_x_meters',
                                'position_label_y_meters',
                              ]
                            ),
                        });
                      }
                    : undefined
                }
                onDuplicateRuler={
                  state.locked
                    ? (reference) => {
                        Analytics.track('Alt Drag To Duplicate', {
                          entity: 'ruler',
                          spaceId: plan.floor.id,
                        });

                        mutation({
                          objectIds: [reference.id],
                          initialActionCreator: ([referenceRulerId]) => ({
                            type: 'reference.duplicate',
                            reference: { ...reference, id: referenceRulerId },
                          }),
                          rollbackActionCreator: ([referenceRulerId]) => ({
                            type: 'reference.remove',
                            id: referenceRulerId,
                          }),
                          syncToAPI: async (
                            state,
                            direction,
                            [referenceRulerId]
                          ) => {
                            await syncAPIReferenceRulerCreateToAPI(
                              client,
                              plan.id,
                              state,
                              reference,
                              referenceRulerId,
                              direction,
                              dispatch
                            );
                          },
                        });
                      }
                    : undefined
                }
              />
            ) : null}
            {state.planning.showHeights ? (
              <ReferenceHeightsLayer
                heightMap={state.heightMap.enabled ? state.heightMap : null}
                referenceHeights={FloorplanCollection.listInRenderOrder(
                  state.references
                ).filter((r): r is ReferenceHeight => r.type === 'height')}
                locked={state.locked || state.savePending}
                highlightedObject={state.highlightedObject}
                onPositionChange={
                  !state.locked
                    ? (reference, position) => {
                        mutation({
                          objectIds: [reference.id],
                          initialActionCreator: ([referenceId]) => ({
                            type: 'reference.heightPosition.change',
                            id: referenceId,
                            position,
                          }),
                          rollbackActionCreator: ([referenceId]) => ({
                            type: 'reference.heightPosition.change',
                            id: referenceId,
                            position: reference.position,
                          }),
                          syncToAPI: async (state, _direction, [referenceId]) =>
                            syncAPIReferenceHeightUpdateToAPI(
                              client,
                              plan.id,
                              state,
                              referenceId,
                              ['position_x_meters', 'position_y_meters']
                            ),
                        });
                      }
                    : undefined
                }
                onHeightChange={(reference, heightMetersValue) => {
                  Analytics.track('Move Reference Height', {
                    spaceId: plan.floor.id,
                  });
                  dispatch({
                    type: 'reference.heightPosition.changeHeight',
                    id: reference.id,
                    heightMetersValue,
                  });
                }}
              />
            ) : null}
            {state.planning.showPhotoGroups ? (
              <PhotoGroupsLayer
                photoGroups={FloorplanCollection.listInRenderOrder(
                  state.photoGroups
                )}
                locked={state.locked || state.savePending}
                focusedPhotoGroupId={state.focusedPhotoGroupId}
                highlightedObject={state.highlightedObject}
                onMouseEnter={(photoGroup) => {
                  dispatch({
                    type: 'item.graphic.mouseenter',
                    itemType: 'photogroup',
                    itemId: photoGroup.id,
                  });
                }}
                onMouseLeave={(photoGroup) => {
                  dispatch({
                    type: 'item.graphic.mouseleave',
                    itemType: 'photogroup',
                    itemId: photoGroup.id,
                  });
                }}
                onMouseDown={(photoGroup, evt) => {
                  Analytics.track('Click Photo Group', {
                    spaceId: plan.floor.id,
                  });
                  const viewportPosition =
                    FloorplanCoordinates.toViewportCoordinates(
                      photoGroup.position,
                      state.floorplan,
                      state.viewport
                    );

                  dispatch({
                    type: 'item.graphic.mousedown',
                    itemType: 'photogroup',
                    itemId: photoGroup.id,
                    itemPosition: viewportPosition,
                    altKey: (evt.data.originalEvent as FixMe).altKey,
                  });
                }}
                onDragMove={(photoGroup, itemPosition) => {
                  mutation({
                    objectIds: [photoGroup.id],
                    initialActionCreator: ([photoGroupId]) => ({
                      type: 'photoGroup.dragmove',
                      id: photoGroupId,
                      itemPosition,
                    }),
                    rollbackActionCreator: ([photoGroupId]) => ({
                      type: 'photoGroup.dragmove',
                      id: photoGroupId,
                      itemPosition: photoGroup.position,
                    }),
                    syncToAPI: async (state, _direction, [photoGroupId]) =>
                      syncSerializedPhotoGroupUpdateToAPI(
                        client,
                        plan.id,
                        state,
                        photoGroupId,
                        ['origin_x_pixels', 'origin_y_pixels']
                      ),
                  });
                }}
              />
            ) : null}
            {state.sensorConnections.size > 0 ? (
              <AggregatedPointsLayer
                planId={plan.id}
                sensors={state.planSensors}
                sensorConnections={state.sensorConnections}
                onChangeSensorConnection={(id, sensorConnection) => {
                  dispatch({
                    type: 'sensorConnection.update',
                    id,
                    sensorConnection,
                  });
                }}
              />
            ) : null}
            {state.planning.showSensorPoints ? (
              <AllSensorAggregatedPointsLayer
                planId={plan.id}
                sensors={state.planSensors}
              />
            ) : null}
            {state.planning.showScale ? (
              <FloorplanScaleLayer measurement={state.measurement} />
            ) : null}
            <SpaceLabelsLayer
              spaces={FloorplanCollection.listInRenderOrder(state.spaces)}
              showSpaceName={state.planning.showSpaceNames}
              focusedObject={state.focusedObject}
            />
          </Floorplan>

          {/* FOCUSED SENSOR PANEL */}
          {focusedSensor ? (
            <FocusedSensorPanel
              key={focusedSensor.id}
              state={state}
              sensor={focusedSensor}
              dispatch={dispatch}
              client={client}
              onChangeLinkedSensorSerialNumber={(
                newLinkedSensorSerialNumber
              ) => {
                if (!focusedSensor) {
                  return;
                }

                mutation({
                  objectIds: [focusedSensor.id],
                  initialActionCreator: ([sensorId]) => ({
                    type: 'sensor.changeSerialNumber',
                    id: sensorId,
                    serialNumber: newLinkedSensorSerialNumber,
                  }),
                  rollbackActionCreator: ([sensorId]) => ({
                    type: 'sensor.changeSerialNumber',
                    id: sensorId,
                    serialNumber: focusedSensor.serialNumber,
                  }),
                  syncToAPI: async (state, _direction, [sensorId]) => {
                    let planSensor = await syncPlanSensorUpdateToAPI(
                      client,
                      plan.id,
                      state,
                      sensorId,
                      ['sensor_serial_number']
                    );

                    if (!planSensor) {
                      return;
                    }

                    // After pushing the data the floorplan api, if the plan sensor has an
                    // associated real sensor, then fetch diagnostic info for it
                    if (planSensor.sensor_serial_number) {
                      const sensorDiagnosticsRequest =
                        CoreAPI.getSensorDiagnostics(
                          client,
                          planSensor.sensor_serial_number
                        );

                      sensorDiagnosticsRequest.then((response) => {
                        const sensorDiagnostics = response.data;
                        const networkAddresses =
                          sensorDiagnostics.network_addresses || [];

                        const mac =
                          networkAddresses.length > 0
                            ? networkAddresses[0].mac
                            : null;
                        const ipv4 =
                          networkAddresses.find((n) => n.family === 'ipv4')
                            ?.address || null;
                        const ipv6 =
                          networkAddresses.find((n) => n.family === 'ipv6')
                            ?.address || null;
                        dispatch({
                          type: 'sensor.setDiagnosticMetadata',
                          id: focusedSensor.id,
                          status: sensorDiagnostics.current_status,
                          ipv4,
                          ipv6,
                          mac,
                          last_heartbeat: sensorDiagnostics.last_heartbeat,
                          os: sensorDiagnostics.os?.VERSION_ID || null,
                        });
                      });

                      sensorDiagnosticsRequest.catch((err) => {
                        if (err.response.status === 404) {
                          toast.error(
                            'Error: unable to find a sensor with this serial number!'
                          );
                          return;
                        }
                      });
                    }
                  },
                });
              }}
              onChangeCadId={(newCadId) => {
                if (!focusedSensor) {
                  return;
                }

                mutation({
                  objectIds: [focusedSensor.id],
                  initialActionCreator: ([sensorId]) => ({
                    type: 'sensor.changeCadId',
                    id: sensorId,
                    cadId: newCadId,
                  }),
                  rollbackActionCreator: ([sensorId]) => ({
                    type: 'sensor.changeCadId',
                    id: sensorId,
                    cadId: focusedSensor.cadId,
                  }),
                  syncToAPI: async (state, _direction, [sensorId]) => {
                    await syncPlanSensorUpdateToAPI(
                      client,
                      plan.id,
                      state,
                      sensorId,
                      ['cad_id']
                    );
                  },
                });
              }}
              onChangeSensorHeight={(newHeightMeters) => {
                if (!focusedSensor) {
                  return;
                }

                mutation({
                  objectIds: [focusedSensor.id],
                  initialActionCreator: ([sensorId]) => ({
                    type: 'sensor.changeHeight',
                    id: sensorId,
                    height: newHeightMeters,
                  }),
                  rollbackActionCreator: ([sensorId]) => ({
                    type: 'sensor.changeHeight',
                    id: sensorId,
                    height: focusedSensor.height,
                  }),
                  syncToAPI: async (state, _direction, [sensorId]) => {
                    await syncPlanSensorUpdateToAPI(
                      client,
                      plan.id,
                      state,
                      sensorId,
                      ['height_meters']
                    );
                  },
                });
              }}
              onChangeRotation={(newRotationDegrees) => {
                if (!focusedSensor) {
                  return;
                }

                mutation({
                  objectIds: [focusedSensor.id],
                  initialActionCreator: ([sensorId]) => ({
                    type: 'sensor.changeRotation',
                    id: sensorId,
                    rotation: newRotationDegrees,
                  }),
                  rollbackActionCreator: ([sensorId]) => ({
                    type: 'sensor.changeRotation',
                    id: sensorId,
                    rotation: focusedSensor.rotation,
                  }),
                  syncToAPI: async (state, _direction, [sensorId]) => {
                    await syncPlanSensorUpdateToAPI(
                      client,
                      plan.id,
                      state,
                      sensorId,
                      ['rotation']
                    );
                  },
                });
              }}
              onChangeBoundingBoxFilter={(newBoundingBoxFilter) => {
                if (!focusedSensor) {
                  return;
                }

                mutation({
                  objectIds: [focusedSensor.id],
                  initialActionCreator: ([sensorId]) => ({
                    type: 'sensor.boundingBoxFilter',
                    id: sensorId,
                    boundingBoxFilter: newBoundingBoxFilter,
                  }),
                  rollbackActionCreator: ([sensorId]) => ({
                    type: 'sensor.boundingBoxFilter',
                    id: sensorId,
                    boundingBoxFilter: focusedSensor.boundingBoxFilter,
                  }),
                  syncToAPI: async (state, _direction, [sensorId]) => {
                    await syncPlanSensorUpdateToAPI(
                      client,
                      plan.id,
                      state,
                      sensorId,
                      ['bounding_box_filter']
                    );
                  },
                });
              }}
              onChangeNotes={(newNotes) => {
                if (!focusedSensor) {
                  return;
                }

                mutation({
                  objectIds: [focusedSensor.id],
                  initialActionCreator: ([sensorId]) => ({
                    type: 'sensor.saveNotes',
                    id: sensorId,
                    notes: newNotes,
                  }),
                  rollbackActionCreator: ([sensorId]) => ({
                    type: 'sensor.saveNotes',
                    id: sensorId,
                    notes: focusedSensor.notes,
                  }),
                  syncToAPI: async (state, _direction, [sensorId]) => {
                    await syncPlanSensorUpdateToAPI(
                      client,
                      plan.id,
                      state,
                      sensorId,
                      ['notes']
                    );
                  },
                });
              }}
              onChangeLocked={(newLocked) => {
                if (!focusedSensor) {
                  return;
                }

                mutation({
                  objectIds: [focusedSensor.id],
                  initialActionCreator: ([sensorId]) => ({
                    type: 'sensor.changeLocked',
                    id: sensorId,
                    locked: newLocked,
                  }),
                  rollbackActionCreator: ([sensorId]) => ({
                    type: 'sensor.changeLocked',
                    id: sensorId,
                    locked: focusedSensor.locked,
                  }),
                  syncToAPI: async (state, _direction, [sensorId]) => {
                    await syncPlanSensorUpdateToAPI(
                      client,
                      plan.id,
                      state,
                      sensorId,
                      ['locked']
                    );
                  },
                });
              }}
              onDelete={() => {
                mutation({
                  objectIds: [focusedSensor.id],
                  initialActionCreator: ([sensorId]) => ({
                    type: 'sensor.remove',
                    id: sensorId,
                  }),
                  rollbackActionCreator: ([sensorId]) => ({
                    type: 'placement.addSensor',
                    sensor: focusedSensor,
                    sensorId,
                  }),
                  syncToAPI: async (state, direction, [sensorId]) =>
                    syncPlanSensorDeleteToAPI(
                      client,
                      plan.id,
                      state,
                      focusedSensor,
                      sensorId,
                      direction,
                      dispatch
                    ),
                });
              }}
            />
          ) : null}

          {/* FOCUSED SPACE PANEL */}
          {focusedAreaOfConcern ? (
            <FocusedAreaOfConcernPanel
              key={focusedAreaOfConcern.id}
              state={state}
              areaOfConcern={focusedAreaOfConcern}
              displayUnit={state.displayUnit}
              heightMapExists={state.heightMap.enabled}
              wallsExist={!FloorplanCollection.isEmpty(state.walls)}
              onSwapSensors={(
                areaOfConcern,
                existingPlanSensors,
                newPlanSensors
              ) => {
                mutation({
                  objectIds: [
                    areaOfConcern.id,
                    ...existingPlanSensors.map((i) => i.id),
                    ...newPlanSensors.map((i) => i.id),
                  ],
                  initialActionCreator: ([areaOfConcernId, ...rest]) => {
                    const existingPlanSensorsWithUpdatedIds =
                      existingPlanSensors.slice();
                    const newPlanSensorsWithUpdatedIds = newPlanSensors.slice();

                    let i = 0;
                    for (; i < existingPlanSensors.length; i += 1) {
                      existingPlanSensorsWithUpdatedIds[i] = {
                        ...existingPlanSensorsWithUpdatedIds[i],
                        id: rest[i],
                      };
                    }
                    for (let j = 0; j < newPlanSensors.length; j += 1) {
                      newPlanSensorsWithUpdatedIds[j] = {
                        ...newPlanSensorsWithUpdatedIds[j],
                        id: rest[i + j],
                      };
                    }

                    return {
                      type: 'areaOfConcern.swapSensors',
                      areaOfConcernId,
                      existingPlanSensors: existingPlanSensorsWithUpdatedIds,
                      newPlanSensors: newPlanSensorsWithUpdatedIds,
                    };
                  },
                  rollbackActionCreator: ([areaOfConcernId, ...rest]) => {
                    const existingPlanSensorsWithUpdatedIds =
                      existingPlanSensors.slice();
                    const newPlanSensorsWithUpdatedIds = newPlanSensors.slice();

                    let i = 0;
                    for (; i < existingPlanSensors.length; i += 1) {
                      existingPlanSensorsWithUpdatedIds[i] = {
                        ...existingPlanSensorsWithUpdatedIds[i],
                        id: rest[i],
                      };
                    }
                    for (let j = 0; j < newPlanSensors.length; j += 1) {
                      newPlanSensorsWithUpdatedIds[j] = {
                        ...newPlanSensorsWithUpdatedIds[j],
                        id: rest[i + j],
                      };
                    }

                    return {
                      type: 'areaOfConcern.swapSensors',
                      areaOfConcernId,
                      existingPlanSensors: newPlanSensorsWithUpdatedIds,
                      newPlanSensors: existingPlanSensorsWithUpdatedIds,
                    };
                  },
                  syncToAPI: async (
                    _state,
                    direction,
                    [areaOfConcernId, ...rest]
                  ) => {
                    // Step 1: Rewrite all plan sensors with updated ids
                    const existingPlanSensorsWithUpdatedIds =
                      existingPlanSensors.slice();
                    const newPlanSensorsWithUpdatedIds = newPlanSensors.slice();

                    let i = 0;
                    for (; i < existingPlanSensors.length; i += 1) {
                      existingPlanSensorsWithUpdatedIds[i] = {
                        ...existingPlanSensorsWithUpdatedIds[i],
                        id: rest[i],
                      };
                    }
                    for (let j = 0; j < newPlanSensors.length; j += 1) {
                      newPlanSensorsWithUpdatedIds[j] = {
                        ...newPlanSensorsWithUpdatedIds[j],
                        id: rest[i + j],
                      };
                    }

                    // Step 2: Generate the bulk updates using the rewritten PlanSensors
                    const newPlanSensorUpdates =
                      newPlanSensorsWithUpdatedIds.map((planSensor) => {
                        if (direction === 'forwards') {
                          return {
                            type: 'plan.sensor.create' as const,
                            body: PlanSensor.toFloorplanSensor(planSensor),
                          };
                        } else {
                          return {
                            type: 'plan.sensor.delete' as const,
                            object_id: planSensor.id,
                          };
                        }
                      });
                    const existingPlanSensorUpdates =
                      existingPlanSensorsWithUpdatedIds.map((planSensor) => {
                        if (direction === 'forwards') {
                          return {
                            type: 'plan.sensor.delete' as const,
                            object_id: planSensor.id,
                          };
                        } else {
                          return {
                            type: 'plan.sensor.create' as const,
                            body: PlanSensor.toFloorplanSensor(planSensor),
                          };
                        }
                      });

                    // Step 3: Make the bulk update to create + delete sensors in the area of
                    // concern
                    const sensorIds = [
                      ...newPlanSensorsWithUpdatedIds,
                      ...existingPlanSensorsWithUpdatedIds,
                    ].map((i) => i.id);
                    const updates = [
                      ...newPlanSensorUpdates,
                      ...existingPlanSensorUpdates,
                    ];
                    const response = await FloorplanAPI.bulk(
                      client,
                      plan.id,
                      updates
                    );

                    // Step 4: Update all ids that changed
                    for (let i = 0; i < sensorIds.length; i += 1) {
                      const sensorId = sensorIds[i];
                      const id = response.data.ids[i];
                      if (!id) {
                        continue;
                      }

                      dispatch({
                        type: 'sensor.changeId',
                        oldId: sensorId,
                        newId: id,
                      });
                    }
                  },
                });
              }}
              dispatch={dispatch}
            />
          ) : null}

          {/* FOCUSED SPACE PANEL */}
          {focusedSpace ? (
            <FocusedSpacePanel
              key={focusedSpace.id}
              state={state}
              space={focusedSpace}
              spaceHierarchyDataLoadingStatus={
                state.spaceHierarchyDataLoadingStatus
              }
              client={client}
              displayUnit={state.displayUnit}
              validations={state.validations}
              dispatch={dispatch}
              onChangeName={(newName) => {
                mutation({
                  objectIds: [focusedSpace.id],
                  initialActionCreator: ([spaceId]) => ({
                    type: 'space.changeName',
                    id: spaceId,
                    name: newName,
                  }),
                  rollbackActionCreator: ([spaceId]) => ({
                    type: 'space.changeName',
                    id: spaceId,
                    name: focusedSpace.name,
                  }),
                  syncToAPI: async (state, _direction, [spaceId]) => {
                    await syncSpaceUpdateToAPI(
                      client,
                      plan.id,
                      state,
                      spaceId,
                      ['name']
                    );
                  },
                });
              }}
              onChangeLocked={(newLocked) => {
                mutation({
                  objectIds: [focusedSpace.id],
                  initialActionCreator: ([spaceId]) => ({
                    type: 'space.changeLocked',
                    id: spaceId,
                    locked: newLocked,
                  }),
                  rollbackActionCreator: ([spaceId]) => ({
                    type: 'space.changeLocked',
                    id: spaceId,
                    locked: focusedSpace.locked,
                  }),
                  syncToAPI: async (state, _direction, [spaceId]) => {
                    await syncSpaceUpdateToAPI(
                      client,
                      plan.id,
                      state,
                      spaceId,
                      ['locked']
                    );
                  },
                });
              }}
              onChangeSpaceCapacity={(newCapacity) => {
                Analytics.track('Change Space Metadata', {
                  field: 'capacity',
                  spaceId: plan.floor.id,
                  areaId: focusedSpace.id,
                });

                mutation({
                  objectIds: [focusedSpace.id],
                  initialActionCreator: ([spaceId]) => ({
                    type: 'space.changeCapacity',
                    id: spaceId,
                    capacity: newCapacity,
                  }),
                  rollbackActionCreator: ([spaceId]) => ({
                    type: 'space.changeCapacity',
                    id: spaceId,
                    capacity: focusedSpace.coreSpaceCapacity,
                  }),
                  syncToAPI: async (state, _direction, [spaceId]) => {
                    await syncSpaceUpdateToAPI(
                      client,
                      plan.id,
                      state,
                      spaceId,
                      ['capacity']
                    );
                  },
                });
              }}
              onChangeSpaceFunction={(newFunction) => {
                Analytics.track('Space Metadata Change', {
                  field: 'function',
                  spaceId: plan.floor.id,
                  areaId: focusedSpace.id,
                });

                mutation({
                  objectIds: [focusedSpace.id],
                  initialActionCreator: ([spaceId]) => ({
                    type: 'space.changeFunction',
                    id: spaceId,
                    function: newFunction,
                  }),
                  rollbackActionCreator: ([spaceId]) => ({
                    type: 'space.changeFunction',
                    id: spaceId,
                    function: focusedSpace.coreSpaceFunction,
                  }),
                  syncToAPI: async (state, _direction, [spaceId]) => {
                    await syncSpaceUpdateToAPI(
                      client,
                      plan.id,
                      state,
                      spaceId,
                      ['function']
                    );
                  },
                });
              }}
              onChangeSpaceLabels={(labelsToAdd, labelsToRemove) => {
                Analytics.track('Space Metadata Change', {
                  field: 'labels',
                  spaceId: plan.floor.id,
                  areaId: focusedSpace.id,
                });

                mutation({
                  objectIds: [focusedSpace.id],
                  initialActionCreator: ([spaceId]) => ({
                    type: 'space.changeLabels',
                    id: spaceId,
                    labelsToAdd,
                    labelsToRemove,
                  }),
                  rollbackActionCreator: ([spaceId]) => ({
                    type: 'space.changeLabels',
                    id: spaceId,
                    labelsToAdd: labelsToRemove,
                    labelsToRemove: labelsToAdd,
                  }),
                  syncToAPI: async (state, direction, [spaceId]) => {
                    await syncSpaceUpdateToAPI(
                      client,
                      plan.id,
                      state,
                      spaceId,
                      ['label_ids']
                    );
                  },
                });
              }}
              onDelete={() => {
                mutation({
                  objectIds: [focusedSpace.id],
                  initialActionCreator: ([spaceId]) => ({
                    type: 'space.remove',
                    id: spaceId,
                  }),
                  rollbackActionCreator: ([spaceId]) => ({
                    type: 'placement.addSpace',
                    space: focusedSpace,
                    spaceId,
                  }),
                  syncToAPI: async (state, direction, [spaceId]) =>
                    syncSpaceDeleteToAPI(
                      client,
                      plan.id,
                      state,
                      focusedSpace,
                      spaceId,
                      direction,
                      dispatch
                    ),
                });
              }}
            />
          ) : null}

          {/* FOCUSED PHOTO GROUP PANEL */}
          {focusedPhotoGroup ? (
            <FocusedPhotoGroupPanel
              key={focusedPhotoGroup.id}
              state={state}
              photoGroup={focusedPhotoGroup}
              dispatch={dispatch}
              onChangeName={(newName) => {
                if (!focusedPhotoGroup) {
                  return;
                }

                mutation({
                  objectIds: [focusedPhotoGroup.id],
                  initialActionCreator: ([photoGroupId]) => ({
                    type: 'photoGroup.changeName',
                    id: photoGroupId,
                    name: newName,
                  }),
                  rollbackActionCreator: ([photoGroupId]) => ({
                    type: 'photoGroup.changeName',
                    id: photoGroupId,
                    name: focusedPhotoGroup.name,
                  }),
                  syncToAPI: async (state, _direction, [photoGroupId]) => {
                    await syncSerializedPhotoGroupUpdateToAPI(
                      client,
                      plan.id,
                      state,
                      photoGroupId,
                      ['name']
                    );
                  },
                });
              }}
              onChangeNotes={(newNotes) => {
                if (!focusedPhotoGroup) {
                  return;
                }

                mutation({
                  objectIds: [focusedPhotoGroup.id],
                  initialActionCreator: ([photoGroupId]) => ({
                    type: 'photoGroup.saveNotes',
                    id: photoGroupId,
                    notes: newNotes,
                  }),
                  rollbackActionCreator: ([photoGroupId]) => ({
                    type: 'photoGroup.saveNotes',
                    id: photoGroupId,
                    notes: focusedPhotoGroup.notes,
                  }),
                  syncToAPI: async (state, _direction, [photoGroupId]) => {
                    await syncSerializedPhotoGroupUpdateToAPI(
                      client,
                      plan.id,
                      state,
                      photoGroupId,
                      ['notes']
                    );
                  },
                });
              }}
              onChangeLocked={(newLocked) => {
                if (!focusedPhotoGroup) {
                  return;
                }

                mutation({
                  objectIds: [focusedPhotoGroup.id],
                  initialActionCreator: ([photoGroupId]) => ({
                    type: 'photoGroup.changeLocked',
                    id: photoGroupId,
                    locked: newLocked,
                  }),
                  rollbackActionCreator: ([photoGroupId]) => ({
                    type: 'photoGroup.changeLocked',
                    id: photoGroupId,
                    locked: focusedPhotoGroup.locked,
                  }),
                  syncToAPI: async (state, _direction, [photoGroupId]) => {
                    await syncSerializedPhotoGroupUpdateToAPI(
                      client,
                      plan.id,
                      state,
                      photoGroupId,
                      ['locked']
                    );
                  },
                });
              }}
              onDelete={() => {
                Analytics.track('Delete Photo Group', {
                  spaceId: plan.floor.id,
                });

                mutation({
                  objectIds: [focusedPhotoGroup.id],
                  initialActionCreator: ([photoGroupId]) => ({
                    type: 'photoGroup.remove',
                    id: photoGroupId,
                  }),
                  rollbackActionCreator: ([photoGroupId]) => ({
                    type: 'placement.addPhotoGroup',
                    photoGroup: focusedPhotoGroup,
                    photoGroupId,
                  }),
                  syncToAPI: async (state, direction, [photoGroupId]) =>
                    syncSerializedPhotoGroupDeleteToAPI(
                      client,
                      plan.id,
                      state,
                      focusedPhotoGroup,
                      photoGroupId,
                      direction,
                      dispatch
                    ),
                });
              }}
              onCreatePhoto={async (
                image: HTMLImageElement,
                dataUrl: string,
                file: File
              ) => {
                Analytics.track('Upload Photo', {
                  spaceId: plan.floor.id,
                });

                const [, imageData] = dataUrl.split(',');
                const imageBytesAsString = atob(imageData);
                const byteArray = new Uint8Array(imageBytesAsString.length);
                for (let i = 0; i < imageBytesAsString.length; i++) {
                  byteArray[i] = imageBytesAsString.charCodeAt(i);
                }

                const fileNameWithoutExtension = file.name.replace(
                  /\.[^.]+$/,
                  ''
                );

                const originalPhotoId = uuidv4();
                let createdPhotoId: PhotoGroupPhoto['id'] | null = null;

                mutation({
                  objectIds: [focusedPhotoGroup.id, originalPhotoId],
                  initialActionCreator: ([photoGroupId, photoId]) => ({
                    type: 'photoGroup.photos.add',
                    id: photoGroupId,
                    photo: PhotoGroupPhoto.createFromUploadedPhotoId(
                      photoId,
                      fileNameWithoutExtension,
                      dataUrl
                    ),
                  }),
                  rollbackActionCreator: ([photoGroupId, photoId]) => ({
                    type: 'photoGroup.photos.remove',
                    id: photoGroupId,
                    photoId,
                  }),
                  syncToAPI: async (
                    state,
                    direction,
                    [photoGroupId, photoId]
                  ) => {
                    if (direction === 'forwards' && !createdPhotoId) {
                      // Create a new photo on the server. Note that photos can be orphaned / not
                      // linked to photo groups - so this only has to run the first time this
                      // mutation is executed.
                      //
                      // NOTE: this will throw an error if it fails
                      const imageUploadResponse =
                        await FloorplanAPI.getPhotoGroupUploadImageUrl(
                          client,
                          plan.id,
                          file.name,
                          file.type
                        );
                      createdPhotoId = imageUploadResponse.data.photo_id;
                      const uploadUrl = imageUploadResponse.data.upload_url;

                      // Update the temporary id to be the actual, server generated id
                      dispatch({
                        type: 'photoGroup.photos.changeId',
                        photoGroupId,
                        oldId: photoId,
                        newId: createdPhotoId,
                      });
                      state = State.changePhotoGroupPhotoId(
                        state,
                        photoGroupId,
                        photoId,
                        createdPhotoId
                      );

                      // Upload the photo image to the server
                      // NOTE: this will throw an error if it fails to upload the image, which
                      // `mutation` will catch
                      await axios.put(uploadUrl, byteArray.buffer, {
                        headers: {
                          'Content-Type': file.type,
                        },
                      });
                    }

                    await syncSerializedPhotoGroupUpdateToAPI(
                      client,
                      plan.id,
                      state,
                      photoGroupId,
                      ['photo_ids']
                    );
                  },
                });
              }}
              onChangePhotoName={(
                photo: PhotoGroupPhoto,
                newName: PhotoGroupPhoto['name']
              ) => {
                mutation({
                  objectIds: [focusedPhotoGroup.id, photo.id],
                  initialActionCreator: ([photoGroupId, photoId]) => ({
                    type: 'photoGroup.photos.changeName',
                    id: photoGroupId,
                    photoId,
                    name: newName,
                  }),
                  rollbackActionCreator: ([photoGroupId, photoId]) => ({
                    type: 'photoGroup.photos.changeName',
                    id: photoGroupId,
                    photoId,
                    name: photo.name,
                  }),
                  syncToAPI: async (
                    state,
                    direction,
                    [photoGroupId, photoId]
                  ) => {
                    const photoGroup =
                      state.photoGroups.items.get(photoGroupId);
                    if (!photoGroup) {
                      return;
                    }

                    const photo = PhotoGroup.getPhotoById(photoGroup, photoId);
                    if (!photo) {
                      return;
                    }

                    await FloorplanAPI.updatePhoto(
                      client,
                      plan.id,
                      photoGroup.id,
                      photo.id,
                      { name: newName }
                    );
                  },
                });
              }}
              onDeletePhoto={(photo: PhotoGroupPhoto) => {
                Analytics.track('Delete Photo', {
                  spaceId: plan.floor.id,
                });

                mutation({
                  objectIds: [focusedPhotoGroup.id, photo.id],
                  initialActionCreator: ([photoGroupId, photoId]) => ({
                    type: 'photoGroup.photos.remove',
                    id: photoGroupId,
                    photoId,
                  }),
                  rollbackActionCreator: ([photoGroupId, photoId]) => ({
                    type: 'photoGroup.photos.add',
                    id: photoGroupId,
                    photo: { ...photo, id: photoId },
                  }),
                  syncToAPI: async (state, direction, [photoGroupId]) => {
                    await syncSerializedPhotoGroupUpdateToAPI(
                      client,
                      plan.id,
                      state,
                      photoGroupId,
                      ['photo_ids']
                    );
                  },
                });
              }}
            />
          ) : null}

          {/* FOCUSED HEIGHTMAP LAYER PANEL */}
          {State.isLayerFocused(state, LayerId.HEIGHTMAP) ? (
            <FocusedHeightmapLayerPanel
              state={state}
              client={client}
              dispatch={dispatch}
              plan={plan}
              onChangeOpacity={(newOpacity) => {
                if (!state.heightMap.enabled) {
                  return;
                }
                const oldHeightMapOpacity = state.heightMap.opacity;

                mutation({
                  initialActionCreator: () => ({
                    type: 'heightMap.changeOpacity',
                    opacity: newOpacity,
                  }),
                  rollbackActionCreator: () => ({
                    type: 'heightMap.changeOpacity',
                    opacity: oldHeightMapOpacity,
                  }),
                  syncToAPI: async (state) => {
                    await syncHeightMapToAPI(client, plan.id, state);
                  },
                });
              }}
              onChangeNotes={(newNotes) => {
                if (!state.heightMap.enabled) {
                  return;
                }
                const oldHeightMapNotes = state.heightMap.notes;

                mutation({
                  initialActionCreator: () => ({
                    type: 'heightMap.saveNotes',
                    notes: newNotes,
                  }),
                  rollbackActionCreator: () => ({
                    type: 'heightMap.saveNotes',
                    notes: oldHeightMapNotes,
                  }),
                  syncToAPI: async (state) => {
                    await syncHeightMapToAPI(client, plan.id, state);
                  },
                });
              }}
              onDelete={() => {
                if (!state.heightMap.enabled) {
                  return;
                }
                const oldHeightMap = state.heightMap;

                mutation({
                  initialActionCreator: () => ({
                    type: 'heightMap.change',
                    heightMap: null,
                  }),
                  rollbackActionCreator: () => ({
                    type: 'heightMap.change',
                    heightMap: oldHeightMap,
                  }),
                  syncToAPI: async (state) => {
                    await syncHeightMapToAPI(client, plan.id, state);
                  },
                });
              }}
            />
          ) : null}

          {/* FOCUSED WALLS LAYER PANEL */}
          {isWallsEnabled && State.isLayerFocused(state, LayerId.WALLS) ? (
            <FocusedWallsLayerPanel
              state={state}
              client={client}
              planId={plan.id}
              dispatch={dispatch}
              onDeleteAllWalls={() => {
                const oldWalls = state.walls;

                mutation({
                  initialActionCreator: () => ({
                    type: 'wallsEditor.clear',
                  }),
                  rollbackActionCreator: () => ({
                    type: 'wallsEditor.save',
                    walls: oldWalls,
                  }),
                  syncToAPI: async (state, direction) => {
                    switch (direction) {
                      case 'forwards':
                        await FloorplanAPI.bulk(
                          client,
                          plan.id,
                          FloorplanCollection.list(oldWalls).map((wall) => ({
                            type: 'plan.wall_segment.delete',
                            object_id: wall.id,
                          }))
                        );
                        break;
                      case 'backwards':
                        await FloorplanAPI.bulk(
                          client,
                          plan.id,
                          FloorplanCollection.list(state.walls).map((wall) => ({
                            type: 'plan.wall_segment.create',
                            body: WallSegment.toFloorplanWallSegment(wall),
                          }))
                        );
                        break;
                    }
                  },
                });
              }}
            />
          ) : null}

          {/* FOCUSED HEATMAP LAYER PANEL */}
          {State.isLayerFocused(state, LayerId.HEATMAP) ? (
            <FocusedHeatMapLayerPanel
              state={state}
              dispatch={dispatch}
              plan={plan}
            />
          ) : null}

          {banner}

          <div className={styles.floorplanControls}>
            <HorizontalForm size="medium">
              {state.locked ? (
                <Button
                  trailingIcon={<Icons.LockClosed size={18} />}
                  onClick={() => dispatch({ type: 'unlock' })}
                  size="medium"
                  type="outlined"
                  data-cy="global-unlock-button"
                />
              ) : (
                <Button
                  trailingIcon={<Icons.LockOpen size={18} />}
                  onClick={() => dispatch({ type: 'lock' })}
                  size="medium"
                  type="outlined"
                  data-cy="global-lock-button"
                />
              )}
              <ExportPanel
                state={state}
                dispatch={dispatch}
                client={client}
                plan={plan}
                floorName={plan.floor.name}
              />
              {!isCombinedExportPopupEnabled ? (
                <Tooltip
                  contents="Export Planner Image"
                  placement="bottom"
                  enterDelay={0}
                  target={
                    <Button
                      onClick={onExportClick}
                      trailingIcon={<Icons.ReportChart size={18} />}
                      size="medium"
                      type="outlined"
                    />
                  }
                />
              ) : null}
              {!isCombinedExportPopupEnabled ? (
                <Tooltip
                  contents="Show Raw Floorplan"
                  placement="bottom"
                  enterDelay={0}
                  target={
                    <Button
                      onClick={onShowRawFloorplanClick}
                      trailingIcon={<Icons.ImageAlt size={18} />}
                      size="medium"
                      type="outlined"
                    />
                  }
                />
              ) : null}
              <SettingsPanel
                state={state}
                plan={plan}
                client={client}
                editStatusLoading={editStatusLoading}
                onEditStatus={onEditStatus}
                dispatch={dispatch}
              />
              <SyncingIndicator
                status={
                  state.savePending ? 'saving' : floorplanEventStreamStatus
                }
                undoEnabled={
                  !state.locked && UndoStack.undoEnabled(state.undoStack)
                }
                onUndoClick={onUndoClick}
                redoEnabled={
                  !state.locked && UndoStack.redoEnabled(state.undoStack)
                }
                onRedoClick={onRedoClick}
              />
            </HorizontalForm>
          </div>

          <div className={styles.mapControls}>
            <div className={styles.mapControlsInner}>
              {isFlipRenderOrderEnabled ? (
                <Tooltip
                  contents={
                    state.renderOrder === 'forwards'
                      ? 'Flip Render Order'
                      : 'Reset Render Order'
                  }
                  placement="bottom"
                  enterDelay={0}
                  target={
                    <Button
                      size="medium"
                      type={
                        state.renderOrder === 'backwards'
                          ? 'filled'
                          : 'outlined'
                      }
                      active={state.renderOrder === 'backwards'}
                      onClick={() => {
                        Analytics.track('Flip Render Order', {
                          spaceId: plan.floor.id,
                        });
                        dispatch({ type: 'menu.flipRenderOrder' });
                      }}
                      trailingIcon={<Icons.SwapVerticalArrow size={18} />}
                      data-cy="flip-render-order"
                    />
                  }
                />
              ) : null}
              <KeyboardShortcutsMenu.Controller
                render={(props) => {
                  return <KeyboardShortcutsMenu {...props} inEditor />;
                }}
              />
              <Tooltip
                contents="Zoom to fit"
                placement="bottom"
                enterDelay={0}
                target={
                  <Button
                    size="medium"
                    type="outlined"
                    onClick={onZoomToFitClick}
                    data-cy="zoom-to-fit"
                    trailingIcon={<Icons.ZoomToFit size={18} />}
                  />
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.sidebar}>
          {/* OBJECTS PANEL */}
          <FloorplanObjectsList
            state={state}
            client={client}
            plan={plan}
            dispatch={dispatch}
            onDeleteReference={(reference) => {
              mutation({
                objectIds: [reference.id],
                initialActionCreator: ([referenceId]) => ({
                  type: 'reference.remove',
                  id: referenceId,
                }),
                rollbackActionCreator: ([referenceId]) => ({
                  type: 'placement.addReference',
                  reference,
                  referenceId,
                }),
                syncToAPI: async (state, direction, [referenceId]) => {
                  switch (reference.type) {
                    case 'ruler':
                      return syncAPIReferenceRulerDeleteToAPI(
                        client,
                        plan.id,
                        state,
                        reference,
                        referenceId,
                        direction,
                        dispatch
                      );
                    case 'height':
                      return syncAPIReferenceHeightDeleteToAPI(
                        client,
                        plan.id,
                        state,
                        reference,
                        referenceId,
                        direction,
                        dispatch
                      );
                  }
                },
              });
            }}
            onChangeReferenceEnabled={(reference, newEnabledValue) => {
              mutation({
                objectIds: [reference.id],
                initialActionCreator: ([referenceId]) => ({
                  type: 'reference.changeEnabled',
                  id: referenceId,
                  enabled: newEnabledValue,
                }),
                rollbackActionCreator: ([referenceId]) => ({
                  type: 'reference.changeEnabled',
                  id: referenceId,
                  enabled: reference.enabled,
                }),
                syncToAPI: async (state, _direction, [referenceId]) => {
                  switch (reference.type) {
                    case 'ruler':
                      return syncAPIReferenceRulerUpdateToAPI(
                        client,
                        plan.id,
                        state,
                        referenceId,
                        ['enabled']
                      );
                    case 'height':
                      return syncAPIReferenceHeightUpdateToAPI(
                        client,
                        plan.id,
                        state,
                        referenceId,
                        ['enabled']
                      );
                  }
                },
              });
            }}
          />
        </div>

        {/* KEYBOARD SHORTCUTS MENU */}
        {Array.from(state.spaces.items.values()).map((space) => {
          return (
            <SpaceObserver
              key={space.id}
              space={space}
              data={state.aggregatedPointsData}
              dispatch={dispatch}
            />
          );
        })}

        {/* TODO: uncomment when fully implemented */}
        {/* <InternalTool>
          <CapturePanel
            capture={state.capture}
            sensors={state.sensors.items}
            dispatch={dispatch}
            client={client}
          />
        </InternalTool> */}
      </div>
    </Fragment>
  );
};

export default Editor;
