import * as React from 'react';

const RestroomWomen = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 9C13.933 9 15.5 7.433 15.5 5.5C15.5 3.567 13.933 2 12 2C10.067 2 8.5 3.567 8.5 5.5C8.5 7.433 10.067 9 12 9ZM12 7C11.1716 7 10.5 6.32843 10.5 5.5C10.5 4.67157 11.1716 4 12 4C12.8284 4 13.5 4.67157 13.5 5.5C13.5 6.32843 12.8284 7 12 7Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.72147 11.6712C8.8822 10.7068 9.71658 10 10.6943 10H13.3057C14.2834 10 15.1178 10.7068 15.2785 11.6712L17 22H7L8.72147 11.6712ZM14.6391 20L13.3057 12L10.6943 12L9.36092 20H14.6391Z"
      fill={color}
    />
  </svg>
);

export default RestroomWomen;
