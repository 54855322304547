import * as React from 'react';

const Retail = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3H2V5H4V16H19.2352L22.3602 6H6V3ZM6 14V8H19.6398L17.7648 14H6Z"
      fill={color}
    />
    <path
      d="M10 19C10 20.1046 9.10457 21 8 21C6.89543 21 6 20.1046 6 19C6 17.8954 6.89543 17 8 17C9.10457 17 10 17.8954 10 19Z"
      fill={color}
    />
    <path
      d="M18 19C18 20.1046 17.1046 21 16 21C14.8954 21 14 20.1046 14 19C14 17.8954 14.8954 17 16 17C17.1046 17 18 17.8954 18 19Z"
      fill={color}
    />
  </svg>
);

export default Retail;
