import React from "react";

const Code = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M4.41421356,12 L8.70710678,16.2928932 L7.29289322,17.7071068 L2.29289322,12.7071068 L2.29289322,11.2928932 L7.29289322,6.29289322 L8.70710678,7.70710678 L4.41421356,12 Z M15.2928932,7.70710678 L16.7071068,6.29289322 L21.7071068,11.2928932 L21.7071068,12.7071068 L16.7071068,17.7071068 L15.2928932,16.2928932 L19.5857864,12 L15.2928932,7.70710678 Z M13.0238129,2.78306954 L14.9761871,3.21693046 L10.9761871,21.2169305 L9.02381294,20.7830695 L13.0238129,2.78306954 Z"
      />
    </g>
  </svg>
);

export default Code;
