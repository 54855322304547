import * as React from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

const MiddleEllipsis: React.FunctionComponent<{
  text: string;
  numberOfTrailingCharacters?: number;
  inFlexParent?: boolean;
}> = ({ text, numberOfTrailingCharacters, inFlexParent }) => {
  let splitPoint = Math.floor(text.length / 2) + 1;

  if (typeof numberOfTrailingCharacters === 'number') {
    splitPoint = text.length - numberOfTrailingCharacters;
    if (splitPoint < 0) {
      splitPoint = 0;
    }
  }

  // Make sure the split point doesn't fall on whitespace
  while (
    splitPoint > 0 &&
    splitPoint < text.length - 1 &&
    (text[splitPoint - 1].match(/\s/) ||
      text[splitPoint].match(/\s/) ||
      text[splitPoint + 1].match(/\s/))
  ) {
    splitPoint += 1;
  }

  const left = text.slice(0, splitPoint);
  const right = text.slice(splitPoint);

  return (
    <div
      className={classnames(styles.middleEllipsis, {
        [styles.inFlexParent]: inFlexParent,
      })}
    >
      <span className={styles.left}>{left}</span>
      <span className={styles.right}>{right}</span>
    </div>
  );
};

export default MiddleEllipsis;
