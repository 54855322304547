import { useCallback, useEffect } from 'react';
import * as React from 'react';

import { isPositionInsideSpace } from '../../lib/algorithm';

import { Action } from './actions';
import { AggregatedData, MappedPoint } from './state';
import Space from 'lib/space';

import { Seconds } from 'lib/units';

function computeConfidence(datapointsInSpace: Array<MappedPoint>) {
  const THRESHOLD_PER_SECOND = 24;
  return Math.min(1, datapointsInSpace.length / THRESHOLD_PER_SECOND);
}

const SpaceObserverAnalysis: React.FunctionComponent<{
  space: Space;
  data: Array<MappedPoint>;
  onConfidenceChange: (confidence: number) => void;
}> = ({ space, data, onConfidenceChange }) => {
  useEffect(() => {
    const now = Seconds.fromMilliseconds(Date.now());
    const datapointsInSpace = data.filter((point) => {
      // get all the datapoints from the last second that are inside the space
      return (
        point.timestamp > now - 1 &&
        isPositionInsideSpace(point.floorplanPosition, space)
      );
    });
    const confidence = computeConfidence(datapointsInSpace);
    onConfidenceChange(confidence);
  }, [data, space, onConfidenceChange]);

  return null;
};

const SpaceObserver: React.FunctionComponent<{
  space: Space;
  data: AggregatedData;
  dispatch: React.Dispatch<Action>;
}> = ({ space, data, dispatch }) => {
  const onConfidenceChange = useCallback(
    (confidence: number) => {
      dispatch({
        type: 'algorithm.spaceOccupancyConfidenceChange',
        id: space.id,
        confidence,
        timestamp: Seconds.fromMilliseconds(Date.now()),
      });
    },
    [space.id, dispatch]
  );

  return (
    <SpaceObserverAnalysis
      space={space}
      data={data}
      onConfidenceChange={onConfidenceChange}
    />
  );
};

export default SpaceObserver;
