import { useState, useMemo, useEffect } from 'react';
import moment from 'moment';

import TextField, { TextFieldProps } from 'components/text-field';

const GUESSED_TIME_ZONE = moment.tz.guess();

const DEFAULT_FORMAT = 'MM/DD/YY HH:mm:ss a';

type AddedProps = {
  value: string | null;
  timeZone?: string;
  format?: string;
  placeholder?: string;
  validate?: (timestampAsString: string) => boolean;
  onChange: (timestampAsString: string | null) => void;
  'data-cy'?: string;
};

type Props = AddedProps &
  Partial<Omit<TextFieldProps, 'ref' | keyof AddedProps>>;

export const DateAndTimeTextField: React.FunctionComponent<Props> = ({
  value,
  onChange,
  timeZone = GUESSED_TIME_ZONE,
  format = DEFAULT_FORMAT,
  placeholder,
  validate = () => true,
  'data-cy': dataCy,
  ...rest
}) => {
  const [dateText, setDateText] = useState('');
  const [dateTextInvalid, setDateTextInvalid] = useState(false);

  // FIXME: this is really bad, but somehow I need to calculate the length of the user input that
  // should result in a fully formed date
  const formatLength = useMemo(
    () => format.replace('a', 'am').replace('A', 'AM').length,
    [format]
  );

  useEffect(() => {
    setDateTextInvalid(false);

    if (value === null) {
      setDateText('');
    } else {
      const date = moment.utc(value).tz(timeZone);
      if (date.isValid()) {
        setDateText(date.format(format));
      } else {
        setDateText(value);
        setDateTextInvalid(true);
      }
    }
  }, [value, format, timeZone, formatLength]);

  return (
    <TextField
      width={160}
      {...rest}
      type="text"
      placeholder={placeholder || format}
      value={dateText}
      error={dateTextInvalid}
      onChange={(e) => {
        setDateText(e.currentTarget.value);

        if (e.currentTarget.value.length === 0) {
          setDateTextInvalid(false);
          return;
        }

        let parsed = moment.tz(e.currentTarget.value, format, timeZone);

        const valid = validate(parsed.format());
        if (!valid) {
          setDateTextInvalid(true);
          return;
        }

        if (
          e.currentTarget.value.length !== formatLength ||
          !parsed.isValid()
        ) {
          setDateTextInvalid(true);
          return;
        }
        setDateTextInvalid(false);
      }}
      onBlur={() => {
        if (dateText.length === 0) {
          onChange(null);
          return;
        }

        let parsed = moment.tz(dateText, format, timeZone);

        const valid = validate(parsed.format());
        if (!valid) {
          setDateTextInvalid(true);
          return;
        }

        if (dateText.length !== formatLength || !parsed.isValid()) {
          setDateTextInvalid(true);
          return;
        }
        setDateTextInvalid(false);
        onChange(parsed.format());
      }}
      data-cy={dataCy}
    />
  );
};

const DateTextField: React.FunctionComponent<Props> = ({
  onChange,
  format,
  ...rest
}) => (
  <DateAndTimeTextField
    {...rest}
    onChange={(timestamp) =>
      timestamp ? onChange(timestamp.split('T')[0]) : onChange(null)
    }
    format={format || 'MM/DD/YYYY'}
  />
);

export default DateTextField;
