import { useState, useEffect } from 'react';

import { FloorplanCoordinates } from 'lib/geometry';

import Button from 'components/button';
import HorizontalForm from 'components/horizontal-form';
import Tooltip from 'components/tooltip';
import { Icons } from '@density/dust';
import TextField from 'components/text-field';

// A custom input control which handles selecting both the X and Y values for an aribtrary position
// There's also a "refresh" icon to reset these values back to their default
const FloorplanCoordinatesField: React.FunctionComponent<{
  value: FloorplanCoordinates;
  onChange: (coords: FloorplanCoordinates) => void;
  disabled?: boolean;
  computeDefaultValue?: () => FloorplanCoordinates;
  units?: string;
  'data-cy'?: string;
}> = ({
  value,
  onChange,
  disabled = false,
  computeDefaultValue,
  units = 'm',
  'data-cy': dataCy,
}) => {
  const [xValue, setXValue] = useState(`${value.x}`);
  const [yValue, setYValue] = useState(`${value.y}`);
  useEffect(() => {
    setXValue(`${value.x}`);
    setYValue(`${value.y}`);
  }, [value]);

  const [xInvalid, setXInvalid] = useState(false);
  const [yInvalid, setYInvalid] = useState(false);

  const onBlur = () => {
    const x = window.parseFloat(xValue);
    if (isNaN(x)) {
      setXInvalid(true);
      return;
    }

    const y = window.parseFloat(yValue);
    if (isNaN(y)) {
      setXInvalid(true);
      return;
    }

    setXInvalid(false);
    setYInvalid(false);
    onChange(FloorplanCoordinates.create(x, y));
  };

  return (
    <HorizontalForm size="medium">
      <TextField
        size="medium"
        width={72}
        placeholder="X"
        type="number"
        value={xValue}
        onChange={(e) => setXValue(e.currentTarget.value)}
        onBlur={onBlur}
        error={xInvalid}
        trailingSuffix={units}
        disabled={disabled}
        data-cy={dataCy ? `${dataCy}-x` : undefined}
      />
      <TextField
        size="medium"
        width={72}
        placeholder="Y"
        type="number"
        value={yValue}
        onChange={(e) => setYValue(e.currentTarget.value)}
        onBlur={onBlur}
        error={yInvalid}
        trailingSuffix={units}
        disabled={disabled}
        data-cy={dataCy ? `${dataCy}-y` : undefined}
      />
      {computeDefaultValue ? (
        <Tooltip
          contents="Reset to default"
          placement="bottom"
          target={
            <Button
              size="medium"
              type="cleared"
              trailingIcon={<Icons.RefreshArrowSync size={18} />}
              onClick={() => onChange(computeDefaultValue())}
              disabled={disabled}
              data-cy={dataCy ? `${dataCy}-reset` : undefined}
            />
          }
        />
      ) : null}
    </HorizontalForm>
  );
};

export default FloorplanCoordinatesField;
