import { useRef, useState, useEffect } from 'react';
import styles from './styles.module.scss';

const RangeExtentsSlider: React.FunctionComponent<{
  rangeMinValue: number;
  rangeMaxValue: number;
  minRangeWidth?: number;
  minValue: number;
  maxValue: number;
  'data-cy'?: string;
  markerValueFormatter?: (value: number) => React.ReactNode;
  onChange: (minValue: number, maxValue: number) => void;
}> = ({
  rangeMinValue,
  rangeMaxValue,
  minRangeWidth = 0,
  minValue,
  maxValue,
  onChange,
  'data-cy': dataCy,
  markerValueFormatter,
  children,
}) => {
  const bar = useRef<HTMLDivElement | null>(null);

  const [barDragging, setBarDragging] = useState(false);

  const [workingMinValue, setWorkingMinValue] = useState(minValue);
  useEffect(() => setWorkingMinValue(minValue), [minValue]);

  const [workingMaxValue, setWorkingMaxValue] = useState(maxValue);
  useEffect(() => setWorkingMaxValue(maxValue), [maxValue]);

  const range = rangeMaxValue - rangeMinValue;
  const left = ((workingMinValue - rangeMinValue) / range) * 100;
  const right = ((rangeMaxValue - workingMaxValue) / range) * 100;

  return (
    <div className={styles.extentsSlider} data-cy={`${dataCy}-slider`}>
      <div
        className={styles.extentsSliderBar}
        ref={bar}
        data-cy={`${dataCy}-slider-bar`}
      >
        <div
          className={styles.extentsSliderBarInner}
          style={{
            left: `${left}%`,
            right: `${right}%`,
            cursor: barDragging ? 'grabbing' : 'grab',
          }}
          onMouseDown={(event) => {
            if (!bar.current) {
              return;
            }
            event.preventDefault();
            setBarDragging(true);

            const barBBox = bar.current.getBoundingClientRect();

            const initialX = event.clientX - barBBox.left;
            const initialWorkingMinValue = workingMinValue;
            const initialWorkingMaxValue = workingMaxValue;

            let newWorkingMinValue = workingMinValue;
            let newWorkingMaxValue = workingMaxValue;

            const onMouseMove = (event: MouseEvent) => {
              if (!event.buttons) {
                return;
              }

              const x = event.clientX - barBBox.left;
              const percentageChange = (x - initialX) / barBBox.width;

              newWorkingMinValue =
                initialWorkingMinValue + range * percentageChange;
              newWorkingMaxValue =
                initialWorkingMaxValue + range * percentageChange;

              // Ensure that either move wouldn't put the bar out of bounds of the track
              if (newWorkingMinValue < rangeMinValue) {
                newWorkingMinValue = rangeMinValue;
                newWorkingMaxValue =
                  rangeMinValue +
                  (initialWorkingMaxValue - initialWorkingMinValue);
              }
              if (newWorkingMaxValue > rangeMaxValue) {
                newWorkingMinValue =
                  rangeMaxValue -
                  (initialWorkingMaxValue - initialWorkingMinValue);
                newWorkingMaxValue = rangeMaxValue;
              }

              setWorkingMinValue(newWorkingMinValue);
              setWorkingMaxValue(newWorkingMaxValue);
              onChange(newWorkingMinValue, newWorkingMaxValue);
            };
            window.addEventListener('mousemove', onMouseMove);

            const onMouseUp = () => {
              window.removeEventListener('mousemove', onMouseMove);
              window.removeEventListener('mouseup', onMouseUp);
              setBarDragging(false);
              onChange(newWorkingMinValue, newWorkingMaxValue);
            };
            window.addEventListener('mouseup', onMouseUp);
          }}
        >
          {children}
        </div>
      </div>

      <div
        className={styles.extentsSliderMarker}
        style={{ left: `${left}%`, transform: 'translateX(-12px)' }}
        onMouseDown={(event) => {
          if (!bar.current) {
            return;
          }
          event.preventDefault();

          const barBBox = bar.current.getBoundingClientRect();

          let newWorkingMinValue = workingMinValue;

          const onMouseMove = (event: MouseEvent) => {
            if (!event.buttons) {
              return;
            }

            let percentage = (event.clientX - barBBox.left) / barBBox.width;
            if (percentage < 0) {
              percentage = 0;
            }
            if (percentage > 1) {
              percentage = 1;
            }

            newWorkingMinValue = rangeMinValue + percentage * range;

            // Ensure that the new width isn't too small
            if (workingMaxValue - newWorkingMinValue < minRangeWidth) {
              newWorkingMinValue = workingMaxValue - minRangeWidth;
            }

            setWorkingMinValue(newWorkingMinValue);
            onChange(newWorkingMinValue, workingMaxValue);
          };
          window.addEventListener('mousemove', onMouseMove);

          const onMouseUp = () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
            onChange(newWorkingMinValue, workingMaxValue);
          };
          window.addEventListener('mouseup', onMouseUp);
        }}
        data-cy={`${dataCy}-slider-marker-min`}
      >
        <svg width="24" height="20" fill="none" viewBox="0 0 16 19">
          <g filter="url(#filter0_dd_6742_53766)">
            <path
              fill="#fff"
              d="M4 7.623V12a2 2 0 002 2h4a2 2 0 002-2V7.623a2 2 0 00-.63-1.458l-2-1.879a2 2 0 00-2.74 0l-2 1.88A2 2 0 004 7.622z"
            ></path>
            <path
              stroke="#313840"
              strokeWidth="2"
              d="M4 7.623V12a2 2 0 002 2h4a2 2 0 002-2V7.623a2 2 0 00-.63-1.458l-2-1.879a2 2 0 00-2.74 0l-2 1.88A2 2 0 004 7.622z"
            ></path>
          </g>
          <defs>
            <filter
              id="filter0_dd_6742_53766"
              width="16"
              height="18.256"
              x="0"
              y="0.744"
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              ></feColorMatrix>
              <feOffset dy="1"></feOffset>
              <feGaussianBlur stdDeviation="1"></feGaussianBlur>
              <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.174902 0 0 0 0 0.2 0 0 0 0.06 0"></feColorMatrix>
              <feBlend
                in2="BackgroundImageFix"
                mode="multiply"
                result="effect1_dropShadow_6742_53766"
              ></feBlend>
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              ></feColorMatrix>
              <feOffset dy="1"></feOffset>
              <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
              <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.174902 0 0 0 0 0.2 0 0 0 0.1 0"></feColorMatrix>
              <feBlend
                in2="effect1_dropShadow_6742_53766"
                mode="multiply"
                result="effect2_dropShadow_6742_53766"
              ></feBlend>
              <feBlend
                in="SourceGraphic"
                in2="effect2_dropShadow_6742_53766"
                result="shape"
              ></feBlend>
            </filter>
          </defs>
        </svg>
        {markerValueFormatter ? (
          <div
            className={styles.extentsSliderMarkerLabel}
            style={{ left: left < 10 ? '50%' : '0%' }}
          >
            {markerValueFormatter(workingMinValue)}
          </div>
        ) : null}
      </div>

      <div
        className={styles.extentsSliderMarker}
        style={{ right: `${right}%`, transform: 'translateX(12px)' }}
        onMouseDown={(event) => {
          if (!bar.current) {
            return;
          }
          event.preventDefault();

          const barBBox = bar.current.getBoundingClientRect();

          let newWorkingMaxValue = workingMaxValue;

          const onMouseMove = (event: MouseEvent) => {
            if (!event.buttons) {
              return;
            }

            let percentage = (event.clientX - barBBox.left) / barBBox.width;
            if (percentage < 0) {
              percentage = 0;
            }
            if (percentage > 1) {
              percentage = 1;
            }

            newWorkingMaxValue = rangeMinValue + percentage * range;

            // Ensure that the new width isn't too small
            if (newWorkingMaxValue - workingMinValue < minRangeWidth) {
              newWorkingMaxValue = workingMinValue + minRangeWidth;
            }

            setWorkingMaxValue(newWorkingMaxValue);
            onChange(workingMinValue, newWorkingMaxValue);
          };
          window.addEventListener('mousemove', onMouseMove);

          const onMouseUp = () => {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
            onChange(workingMinValue, newWorkingMaxValue);
          };
          window.addEventListener('mouseup', onMouseUp);
        }}
        data-cy={`${dataCy}-slider-marker-max`}
      >
        <svg width="24" height="20" fill="none" viewBox="0 0 16 19">
          <g filter="url(#filter0_dd_6742_53766)">
            <path
              fill="#fff"
              d="M4 7.623V12a2 2 0 002 2h4a2 2 0 002-2V7.623a2 2 0 00-.63-1.458l-2-1.879a2 2 0 00-2.74 0l-2 1.88A2 2 0 004 7.622z"
            ></path>
            <path
              stroke="#313840"
              strokeWidth="2"
              d="M4 7.623V12a2 2 0 002 2h4a2 2 0 002-2V7.623a2 2 0 00-.63-1.458l-2-1.879a2 2 0 00-2.74 0l-2 1.88A2 2 0 004 7.622z"
            ></path>
          </g>
          <defs>
            <filter
              id="filter0_dd_6742_53766"
              width="16"
              height="18.256"
              x="0"
              y="0.744"
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              ></feColorMatrix>
              <feOffset dy="1"></feOffset>
              <feGaussianBlur stdDeviation="1"></feGaussianBlur>
              <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.174902 0 0 0 0 0.2 0 0 0 0.06 0"></feColorMatrix>
              <feBlend
                in2="BackgroundImageFix"
                mode="multiply"
                result="effect1_dropShadow_6742_53766"
              ></feBlend>
              <feColorMatrix
                in="SourceAlpha"
                result="hardAlpha"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              ></feColorMatrix>
              <feOffset dy="1"></feOffset>
              <feGaussianBlur stdDeviation="1.5"></feGaussianBlur>
              <feColorMatrix values="0 0 0 0 0.152941 0 0 0 0 0.174902 0 0 0 0 0.2 0 0 0 0.1 0"></feColorMatrix>
              <feBlend
                in2="effect1_dropShadow_6742_53766"
                mode="multiply"
                result="effect2_dropShadow_6742_53766"
              ></feBlend>
              <feBlend
                in="SourceGraphic"
                in2="effect2_dropShadow_6742_53766"
                result="shape"
              ></feBlend>
            </filter>
          </defs>
        </svg>
        {markerValueFormatter ? (
          <div
            className={styles.extentsSliderMarkerLabel}
            style={{ right: right < 10 ? '50%' : '0%' }}
          >
            {markerValueFormatter(workingMaxValue)}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default RangeExtentsSlider;
