import { Fragment, useState, useRef, useMemo, useEffect } from 'react';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import * as PIXI from 'pixi.js';
import {
  applyToPoint,
  Matrix,
  compose,
  translate,
  rotateDEG,
} from 'transformation-matrix';

import { Icons } from '@density/dust';
import Floorplan, {
  BigImage,
  toRawHex,
  ObjectLayer,
} from 'components/floorplan';
import { DarkTheme } from 'components/theme';
import AppBar from 'components/app-bar';
import Button from 'components/button';
import HorizontalForm from 'components/horizontal-form';
import FormLabel from 'components/form-label';
import Panel, { PanelTitle, PanelBody } from 'components/panel';
import FloorplanCoordinatesField from 'components/floorplan-coordinates-field';
import ScaleField from 'components/scale-field';
import RotationField from 'components/rotation-field';
import { FloorplanCoordinates, ImageCoordinates } from 'lib/geometry';
import { Meters } from 'lib/units';
import { round, modulo } from 'lib/math';
import FloorplanType from 'lib/floorplan';
import Measurement from 'lib/measurement';
import Space from 'lib/space';
import ImageRegistrationLayer from './floorplan-layers/image-registration-layer';
import SensorsLayer from './floorplan-layers/sensors-layer';
import SpacesLayer from './floorplan-layers/spaces-layer';
import { FixMe } from 'types/fixme';

import PlanSensor from 'lib/sensor';

type FloorplanImageRegistrationProps = {
  oldImage: HTMLImageElement;
  newImage: HTMLImageElement;
  sensors: Array<PlanSensor>;
  spaces: Array<Space>;
  loading: boolean;
  floorplan: FloorplanType;
  onSubmit: (
    newMeasurement: Measurement,
    newOriginX: number,
    newOriginY: number,
    newRotationDegrees: number,
    oldToNewTransformation: Matrix
  ) => void;
  onCancel?: () => void;
};

// When set to true, show a secondary floorplan to the right of the regular floorplan panel that
// shows the result of the floorplan transformation
// This is really helpful (IMO) when debugging / troubleshooting a problem
const IMAGE_REGISTRATION_SHOW_RESULT = false;

const FloorplanImageRegistration: React.FunctionComponent<FloorplanImageRegistrationProps> =
  ({
    oldImage,
    newImage,
    sensors,
    spaces,
    loading,
    floorplan,
    onSubmit,
    onCancel,
  }) => {
    const newImageRef = useRef<BigImage | null>(null);
    useEffect(() => {
      const imageSprite = new BigImage(newImage.src);
      imageSprite.name = 'image-registration-new-image';

      // Apply blend mode and make floorplan grayscale
      // ref: https://github.com/pixijs/pixijs/issues/1598#issuecomment-284810464
      let colorMatrix = new PIXI.filters.ColorMatrixFilter();
      colorMatrix.desaturate();
      colorMatrix.blendMode = PIXI.BLEND_MODES.MULTIPLY;
      imageSprite.filters = [colorMatrix];

      newImageRef.current = imageSprite;

      // return () => {
      //   imageSprite.destroy({texture: true});
      // };
    }, [newImage.src]);

    const initialScale = useMemo(() => {
      return oldImage.width / newImage.width;
    }, [newImage.width, oldImage.width]);

    const [[position, rotation, scale], setTransform] = useState([
      FloorplanCoordinates.create(0, 0),
      0,
      initialScale,
    ]);

    const newFloorplan = useMemo(
      () => ({
        width: newImage.width,
        height: newImage.height,
        origin: ImageCoordinates.create(0, 0),
        scale: floorplan.scale / scale,
        rotation: floorplan.rotation,
      }),
      [newImage, floorplan.scale, floorplan.rotation, scale]
    );

    // This matrix transforms from old floorplan coordinates to new floorplan
    // coordinates based off the specified transformation.
    const matrix = useMemo(() => {
      const rotationRounded = round(rotation, 1);
      return compose(
        rotateDEG(-1 * rotationRounded),
        translate(
          (-1 * position.x) / floorplan.scale,
          (-1 * position.y) / floorplan.scale
        )
      );
    }, [position, floorplan.scale, rotation]);

    // NOTE: memoize this so that the ImageRegistrationLayer has a stable reference for the
    // `position` prop
    const imageRegistrationLayerPosition = useMemo(
      () =>
        FloorplanCoordinates.create(
          position.x / floorplan.scale,
          position.y / floorplan.scale
        ),
      [position, floorplan.scale]
    );

    const floorplanRef = useRef<FixMe | null>(null);
    if (
      process.env.REACT_APP_ENABLE_EDITOR_GET_STATE &&
      process.env.REACT_APP_ENABLE_EDITOR_GET_STATE.toLowerCase() === 'true'
    ) {
      (window as any).registerGetFloorplanRef = () => floorplanRef;
    }

    return (
      <Fragment>
        <DarkTheme>
          <AppBar
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {onCancel ? (
                  <Button
                    onClick={() => onCancel()}
                    trailingIcon={
                      <Icons.ArrowLeftBack size={18} color={dust.Gray200} />
                    }
                    size="medium"
                    type="cleared"
                  />
                ) : null}
                <span style={{ marginLeft: 8 }}>Register New Floorplan</span>
              </div>
            }
            actions={
              <HorizontalForm size="medium">
                {onCancel ? (
                  <Button
                    size="medium"
                    type="cleared"
                    onClick={() => onCancel()}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button
                  size="medium"
                  onClick={async () => {
                    const rotationRounded = round(rotation, 1);

                    const originXPixels =
                      -1 * position.x * (newFloorplan.scale / floorplan.scale);
                    const originYPixels =
                      -1 * position.y * (newFloorplan.scale / floorplan.scale);

                    const newComputedLength =
                      newImage.width / newFloorplan.scale;
                    const [newFeet, newInches] =
                      Meters.toFeetAndInches(newComputedLength);

                    // Generate a measurement line going across the top of the image
                    const measurementLineMatrix = rotateDEG(rotationRounded);
                    const [pointAX, pointAY] = applyToPoint(
                      measurementLineMatrix,
                      [0, 0]
                    );
                    const [pointBX, pointBY] = applyToPoint(
                      measurementLineMatrix,
                      [newImage.width, 0]
                    );

                    const newMeasurement: Measurement = {
                      pointA: ImageCoordinates.create(pointAX, pointAY),
                      pointB: ImageCoordinates.create(pointBX, pointBY),
                      userEnteredLength: {
                        feetText: `${newFeet}`,
                        inchesText: `${newInches}`,
                      },
                      computedLength: newComputedLength,
                      computedScale: newFloorplan.scale,
                    };

                    onSubmit(
                      newMeasurement,
                      rotationRounded,
                      originXPixels,
                      originYPixels,
                      matrix
                    );
                  }}
                  data-cy="floorplan-image-registration-submit"
                  disabled={loading}
                >
                  {loading ? 'Saving Registration...' : 'Save Registration'}
                </Button>
              </HorizontalForm>
            }
          />
        </DarkTheme>
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              display: 'flex',
              gap: 4,
            }}
          >
            <Floorplan
              image={oldImage}
              floorplan={floorplan}
              ref={floorplanRef}
              width={IMAGE_REGISTRATION_SHOW_RESULT ? '50%' : '100%'}
              height="100%"
              lengthUnit="feet_and_inches"
            >
              {/* Render the sensors on the old floorplan for context */}
              <SensorsLayer sensors={sensors} />

              {/* Render the spaces on the old floorplan for context */}
              <SpacesLayer spaces={spaces} />

              {/* Allow positioning the new floorplan image on top of the old floorplan */}
              <ImageRegistrationLayer
                imageWidth={newImage.width}
                imageHeight={newImage.height}
                createImage={(container) => {
                  if (!newImageRef.current) {
                    return;
                  }
                  container.addChild(newImageRef.current);
                }}
                updateImage={(container, context) => {
                  if (!context.viewport.current) {
                    return;
                  }
                  if (!newImageRef.current) {
                    return;
                  }

                  // Did the new image ref change? If so, remove the old value and add in the new value.
                  if (!container.children.includes(newImageRef.current)) {
                    const previousNewImage = container.getChildByName(
                      'image-registration-new-image'
                    );
                    if (previousNewImage) {
                      container.removeChild(previousNewImage);
                    }
                    container.addChild(newImageRef.current);
                  }
                }}
                removeImage={(container) => {
                  if (newImageRef.current) {
                    container.removeChild(newImageRef.current);
                  }
                  container.destroy({ children: true });
                }}
                position={imageRegistrationLayerPosition}
                rotationInDegrees={rotation}
                scale={scale / floorplan.scale}
                isMovable
                isRotatable
                isScalable
                onDragMove={(position, scale, rotation) => {
                  // Transform both the position and scale from old floorplan coordinates into pixel
                  // offsets / scales
                  setTransform([
                    FloorplanCoordinates.create(
                      position.x * floorplan.scale,
                      position.y * floorplan.scale
                    ),
                    rotation,
                    scale * floorplan.scale,
                  ]);
                }}
              />
            </Floorplan>
            {IMAGE_REGISTRATION_SHOW_RESULT ? (
              <Floorplan
                image={newImage}
                floorplan={newFloorplan}
                width="50%"
                height="100%"
                lengthUnit="feet_and_inches"
              >
                {/* Show a dot at each new sensor location */}
                <ObjectLayer
                  objects={sensors}
                  extractId={(sensor) => sensor.id}
                  onCreate={() => new PIXI.Graphics()}
                  onUpdate={(sensor, graphics, _update, context) => {
                    if (!context.viewport.current) {
                      return;
                    }
                    graphics.clear();

                    const [x, y] = applyToPoint(matrix, [
                      sensor.position.x,
                      sensor.position.y,
                    ]);

                    const viewportPos =
                      FloorplanCoordinates.toViewportCoordinates(
                        FloorplanCoordinates.create(x, y),
                        context.floorplan,
                        context.viewport.current
                      );

                    graphics.beginFill(toRawHex(dust.Blue400), 1);
                    graphics.drawCircle(viewportPos.x, viewportPos.y, 4);
                    graphics.endFill();
                  }}
                  onRemove={(_item, graphics) => graphics.destroy()}
                />
              </Floorplan>
            ) : null}
          </div>
          <Panel position="top-left">
            <PanelTitle icon={<Icons.CogGearSettings size={18} />}>
              Registration Settings
            </PanelTitle>
            <PanelBody>
              <FormLabel
                label="Offset"
                input={
                  <FloorplanCoordinatesField
                    value={position}
                    units="px" // FIXME: there probably needs to be something like a `ViewportCoordinatesField`...
                    computeDefaultValue={() =>
                      FloorplanCoordinates.create(0, 0)
                    }
                    onChange={(newPosition) =>
                      setTransform([newPosition, rotation, scale])
                    }
                  />
                }
              />
              <FormLabel
                label="Rotation"
                input={
                  <RotationField
                    value={rotation}
                    onChange={(newRotation) =>
                      setTransform([position, newRotation, scale])
                    }
                    onRotateRight90={() => {
                      const newRotation = modulo(rotation + 90, 360);
                      setTransform([position, newRotation, scale]);
                    }}
                  />
                }
              />
              <FormLabel
                label="Scale"
                input={
                  <ScaleField
                    value={scale}
                    onChange={(scale) =>
                      setTransform([position, rotation, scale])
                    }
                    computeDefaultValue={() => 1}
                  />
                }
              />
            </PanelBody>
          </Panel>
        </div>
      </Fragment>
    );
  };

export default FloorplanImageRegistration;
