import * as React from 'react';

const EventSpace = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      d="M5.64787 9.13369C5.86996 8.81148 6 8.42093 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 8.74028 2.4022 9.38663 3 9.73244L3 18H2V20H6V18H5L5 11.1455C9.277 14.1621 14.9471 14.2686 19 11.2492V18H18V20H22V18H21V9.73244C21.5978 9.38663 22 8.74028 22 8C22 6.89543 21.1046 6 20 6C18.8954 6 18 6.89543 18 8C18 8.43984 18.142 8.84652 18.3826 9.17671C14.9126 12.2269 9.53673 12.2321 5.64787 9.13369Z"
      fill={color}
    />
  </svg>
);

export default EventSpace;
