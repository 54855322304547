import * as React from 'react';
import { Fragment, useEffect, useMemo, useState, useCallback } from 'react';
import { Icons } from '@density/dust';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import Fuse from 'fuse.js';
import { TIME_ZONE_CHOICES } from '@densityco/lib-time-helpers';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  CoreSpace,
  CoreSpaceType,
  CoreSpaceStatus,
  CoreSpaceSizeAreaUnit,
  CoreOrganization,
} from '@densityco/lib-api-types';

import { useAppSelector } from 'redux/store';
import styles from './styles.module.scss';

import Button from 'components/button';
import TextField from 'components/text-field';
import SelectField from 'components/select-field';
import AppBar from 'components/app-bar';
import HorizontalForm from 'components/horizontal-form';
import Modal from 'components/modal';
import FormLabel from 'components/form-label';
import { CADFileUploader } from 'components/image-uploader';
import FillCenter from 'components/fill-center';
import ErrorMessage from 'components/error-message';
import { LightTheme } from 'components/theme';

import {
  FloorplanAPI,
  CoreAPI,
  HelixAPI,
  HelixFolioSummary,
  HelixSpace,
  HelixFolio,
  HelixPointCloud,
  HelixPointCloudDerivative,
} from 'lib/api';
import { BuildingFloorplan } from 'lib/building';
import parseGeoTiff, { GeoTiffTiepoint } from 'lib/geotiff';
import { ImageCoordinates } from 'lib/geometry';
import Measurement from 'lib/measurement';
import { Meters } from 'lib/units';
import { resizeImageToMaxSize } from 'lib/image';

const GUESSED_TIME_ZONE = moment.tz.guess();

function applyFuzzySearch<T extends object>(
  collection: Array<T>,
  searchText: string,
  fns: Array<(item: T, index: number) => string>
): Array<[T, number]> {
  if (searchText.length === 0) {
    return collection.map((item, index) => [item, index]);
  }

  const collectionWithIndex = collection.map((item, index) => ({
    item,
    index,
  }));

  // NOTE: I'm really not satisfied with this fuse.js library. `fuzzy`, my preferred library, wasn't
  // working and I couldn't figure out why (was always just returning an empty array).
  const fuse = new Fuse(collectionWithIndex, {
    threshold: 0.1,
    includeScore: true,
    keys: ['bogus string needed here to trigger getFn below'],
    getFn: ({ item, index }) => fns.map((fn) => fn(item, index)),
  });

  return fuse.search(searchText).map((i) => [i.item.item, i.item.index]);
}

type FloorplanCreatorView =
  | { status: 'maps_import_token' }
  | { status: 'maps_import_select_folio' }
  | { status: 'maps_import_select_space' }
  | { status: 'maps_import_select_point_cloud' }
  | { status: 'maps_import_generating_image' }
  | { status: 'form'; allowBackToMapsImport: boolean }
  | { status: 'creating_floor' }
  | { status: 'downloading_heightmap'; fileDownloadPercent?: number }
  | { status: 'uploading_heightmap'; fileUploadPercent?: number }
  | { status: 'uploading_image'; fileUploadPercent?: number }
  | { status: 'uploading_dxf'; fileUploadPercent?: number }
  | { status: 'upload_dxf_error' }
  | { status: 'creating_plan' };

export type NewFloorplanModalParams =
  | {
      visible: true;
      mapsImport?: boolean;
      parentBuildingId: CoreSpace['id'];
      existingFloorMetadata: Partial<BuildingFloorplan> | null;
      initiallyLoadedFloorplanImage: HTMLImageElement | null;
      initiallyLoadedFloorplanImageScale: number | null;
    }
  | {
      visible: false;
      mapsImport: false;
      parentBuildingId: null;
      existingFloorMetadata: null;
      initiallyLoadedFloorplanImage: null;
      initiallyLoadedFloorplanImageScale: null;
    };

type NewFloorplanModalProps = NewFloorplanModalParams & {
  onDismiss: () => void;
};

type FloorplanImageData =
  | { status: 'upload' }
  | {
      status: 'image_selected';
      image: HTMLImageElement;
      scale: number;
    }
  | {
      status: 'dxf_selected';
      sourceFile: File;
      dataUrl: string;
    };

// This function handles uploading a dxf or raster image and creating a floorplan associated with a
// space of type floor
async function uploadFloorplanImageData(
  densityAPIClient: AxiosInstance,
  floorId: CoreSpace['id'],
  floorplanImage: FloorplanImageData,
  heightMapGeotiffSrc: string | null,
  changeView: (view: FloorplanCreatorView) => void
) {
  let ceilingRasterKey: string | null = null;
  let ceilingRasterFloorplanOriginX: number | null = null;
  let ceilingRasterFloorplanOriginY: number | null = null;
  let ceilingRasterFloorplanAngleDegrees: number | null = null;
  let measurement: Measurement = {
    pointA: ImageCoordinates.create(0, 0),
    pointB: ImageCoordinates.create(0, 0),
    computedLength: 0,
    userEnteredLength: {
      feetText: '',
      inchesText: '',
    },
    computedScale: 0,
  };

  if (heightMapGeotiffSrc) {
    const heightMapRawDataResponse = await axios.get<string>(
      heightMapGeotiffSrc,
      {
        headers: {
          Accept: 'image/tiff',
        },
        responseType: 'arraybuffer',
        onDownloadProgress: (event) => {
          if (typeof event.total === 'undefined') {
            return;
          }
          const percent = (event.loaded / event.total) * 100;
          changeView({
            status: 'downloading_heightmap',
            fileDownloadPercent: percent,
          });
        },
      }
    );

    if (heightMapRawDataResponse.status !== 200) {
      throw new Error(
        `Error downloading heightmap, received a ${heightMapRawDataResponse.status}`
      );
    }

    const signedUrlResponse = (
      await FloorplanAPI.imageUpload(densityAPIClient, {
        floor_id: floorId,
        ext: 'tiff',
        content_type: 'image/tiff',
      })
    ).data;

    const {
      key: objectKey,
      signed_url: signedUrl,
      get_signed_url: getSignedUrl,
    } = signedUrlResponse;

    const putImageResponse = await axios.put(
      signedUrl,
      heightMapRawDataResponse.data,
      {
        headers: {
          'Content-Type': 'image/tiff',
        },
        onUploadProgress: (event) => {
          if (typeof event.total === 'undefined') {
            return;
          }
          const percent = (event.loaded / event.total) * 100;
          changeView({
            status: 'uploading_heightmap',
            fileUploadPercent: percent,
          });
        },
      }
    );

    if (putImageResponse.status !== 200) {
      throw new Error(
        `Error uploading heightmap, received a ${putImageResponse.status}`
      );
    }

    ceilingRasterKey = objectKey;
    ceilingRasterFloorplanOriginX = 0;
    ceilingRasterFloorplanOriginY = 0;
    ceilingRasterFloorplanAngleDegrees = 0;

    if (floorplanImage.status === 'image_selected') {
      const parsed = await parseGeoTiff(getSignedUrl);

      const computedScale = (1 / parsed.scale) * floorplanImage.scale;
      const computedLength = floorplanImage.image.width / computedScale;
      const [feet, inches] = Meters.toFeetAndInches(computedLength);
      measurement = {
        pointA: ImageCoordinates.create(0, 0),
        pointB: ImageCoordinates.create(floorplanImage.image.width, 0),
        userEnteredLength: {
          feetText: `${feet}`,
          inchesText: `${inches}`,
        },
        computedLength,
        computedScale,
      };

      const tiePoints: GeoTiffTiepoint = parsed.geotiffImage.getTiePoints();
      if (tiePoints.length > 0) {
        // From https://github.com/DensityCo/planner/commit/7ec8c6799c001870bc48ca10ee4394234f641890#diff-d462aeab1b27f5c4a42905d5f742de8f71d44f61ab765c568d07f0d01e116017R301
        ceilingRasterFloorplanOriginX = tiePoints[0].x;
        ceilingRasterFloorplanOriginY = -1 * tiePoints[0].y;
      }
    }
  }

  switch (floorplanImage.status) {
    case 'image_selected': {
      changeView({ status: 'uploading_image' });

      const image = floorplanImage.image;
      if (!image.src.startsWith('data:image/png;base64,')) {
        throw new Error(
          'Floorplan image src is not a base64 encoded data url, this is invalid!'
        );
      }
      const imageData = image.src.split(',')[1];

      const signedUrlResponse = (
        await FloorplanAPI.imageUpload(densityAPIClient, {
          floor_id: floorId,
          ext: 'png',
          content_type: 'image/png',
        })
      ).data;

      const { key: objectKey, signed_url: signedUrl } = signedUrlResponse;

      const imageBytesAsString = atob(imageData);
      const byteArray = new Uint8Array(imageBytesAsString.length);
      for (let i = 0; i < imageBytesAsString.length; i++) {
        byteArray[i] = imageBytesAsString.charCodeAt(i);
      }

      const putImageResponse = await axios.put(signedUrl, byteArray.buffer, {
        headers: {
          'Content-Type': 'image/png',
        },
        onUploadProgress: (event) => {
          if (typeof event.total === 'undefined') {
            return;
          }
          const percent = (event.loaded / event.total) * 100;
          changeView({ status: 'uploading_image', fileUploadPercent: percent });
        },
      });

      if (putImageResponse.status !== 200) {
        throw new Error(
          `Error uploading image, received a ${putImageResponse.status}`
        );
      }

      changeView({ status: 'creating_plan' });

      let response;
      try {
        response = await FloorplanAPI.createFloorplan(densityAPIClient, {
          floor_id: floorId,
          image_key: objectKey,
          image_width_pixels: image.width,
          image_height_pixels: image.height,
          origin_x_pixels: 0,
          origin_y_pixels: 0,

          ...Measurement.toFloorplanScaleUpdate(measurement),

          ceiling_raster_key: ceilingRasterKey,
          ceiling_raster_floorplan_origin_x:
            ceilingRasterFloorplanOriginX !== null
              ? ceilingRasterFloorplanOriginX
              : undefined,
          ceiling_raster_floorplan_origin_y:
            ceilingRasterFloorplanOriginY !== null
              ? ceilingRasterFloorplanOriginY
              : undefined,
          ceiling_raster_floorplan_angle_degrees:
            ceilingRasterFloorplanAngleDegrees !== null
              ? ceilingRasterFloorplanAngleDegrees
              : undefined,
        });
      } catch (err) {
        throw new Error(`Error creating plan: ${err}`);
      }

      return response.data;
    }

    case 'dxf_selected': {
      changeView({ status: 'uploading_dxf' });

      // Read file contents as an array buffer
      let fileContents;
      const file = floorplanImage.sourceFile;
      fileContents = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
          if (!e.target) {
            reject(new Error('DXF file event target is empty!'));
            return;
          }
          const fileContents = e.target.result;
          if (!fileContents || typeof fileContents === 'string') {
            reject(new Error('DXF file contents is not an arraybuffer!'));
            return;
          }

          resolve(fileContents);
        };
        reader.onerror = () => {
          toast.error('Error reading DXF file!');
          reject(new Error('Unable to read dxf file!'));
        };
        reader.readAsArrayBuffer(file);
      });

      // Create a new file upload url
      let signedUrlResponse: AxiosResponse<{ key: string; signed_url: string }>;
      try {
        signedUrlResponse = await FloorplanAPI.imageUpload(densityAPIClient, {
          floor_id: floorId,
          ext: 'dxf',
          content_type: 'application/dxf',
        });
      } catch (err) {
        throw new Error(`Error creating file upload url: ${err}`);
      }

      const { key: objectKey, signed_url: signedUrl } = signedUrlResponse.data;

      // And then PUT the file contents to that upload url to create the file on s3
      try {
        await axios.put(signedUrl, fileContents, {
          headers: {
            'Content-Type': 'application/dxf',
          },
          onUploadProgress: (event) => {
            if (typeof event.total === 'undefined') {
              return;
            }
            const percent = (event.loaded / event.total) * 100;
            changeView({ status: 'uploading_dxf', fileUploadPercent: percent });
          },
        });
      } catch (err) {
        throw new Error(`Error uploading file to upload url: ${err}`);
      }

      // Create a new, empty plan attached to the floor
      let response;
      try {
        response = await FloorplanAPI.createFloorplan(densityAPIClient, {
          floor_id: floorId,
          image_key: '',
          image_width_pixels: 0,
          image_height_pixels: 0,
          origin_x_pixels: 0,
          origin_y_pixels: 0,

          ...Measurement.toFloorplanScaleUpdate(measurement),

          ceiling_raster_key: ceilingRasterKey,
          ceiling_raster_floorplan_origin_x:
            ceilingRasterFloorplanOriginX !== null
              ? ceilingRasterFloorplanOriginX
              : undefined,
          ceiling_raster_floorplan_origin_y:
            ceilingRasterFloorplanOriginY !== null
              ? ceilingRasterFloorplanOriginY
              : undefined,
          ceiling_raster_floorplan_angle_degrees:
            ceilingRasterFloorplanAngleDegrees !== null
              ? ceilingRasterFloorplanAngleDegrees
              : undefined,
        });
      } catch (err) {
        throw new Error(`Error creating plan: ${err}`);
      }
      const plan = response.data;

      // Finally, create the dxf on the serverside using the newly uploaded file
      try {
        await FloorplanAPI.createAndProcessDXF(
          densityAPIClient,
          plan.id,
          objectKey
        );
      } catch (err) {
        throw new Error(`Error sending dxf fiel to be processed: ${err}`);
      }

      return plan;
    }

    default: {
      throw new Error(
        `Unknown floorplan image data status of ${floorplanImage.status}!`
      );
    }
  }
}

const NewFloorplanModal: React.FunctionComponent<NewFloorplanModalProps> = ({
  visible,
  mapsImport = false,
  parentBuildingId,
  existingFloorMetadata,
  initiallyLoadedFloorplanImage,
  initiallyLoadedFloorplanImageScale,
  onDismiss,
}) => {
  const history = useHistory();
  const { organizationId } =
    useParams<{ organizationId: CoreOrganization['id'] }>();

  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  const [view, setView] = useState<FloorplanCreatorView>({
    status: 'form',
    allowBackToMapsImport: false,
  });

  const [name, setName] = useState('');
  const [capacity, setCapacity] = useState('');
  const [capacityValid, setCapacityValid] = useState(true);
  const [size, setSize] = useState('');
  const [sizeValid, setSizeValid] = useState(true);
  const [rent, setRent] = useState('');
  const [rentValid, setRentValid] = useState(true);
  const [timeZone, setTimeZone] = useState(GUESSED_TIME_ZONE);

  const [floorplanImage, setFloorplanImage] = useState<FloorplanImageData>({
    status: 'upload',
  });
  const [heightMapGeotiffSrc, setHeightMapGeotiffSrc] = useState<string | null>(
    null
  );

  const [foliosSearchText, setFolioSearchText] = useState('');
  const [selectedFolioId, setSelectedFolioId] = useState<
    HelixFolio['id'] | null
  >(null);
  const [selectedFolioSpaceId, setSelectedFolioSpaceId] = useState<
    HelixSpace['id'] | null
  >(null);
  const [selectedPointCloudId, setSelectedPointCloudId] = useState<
    HelixPointCloud['id'] | null
  >(null);

  const shouldHideAllFormFields =
    existingFloorMetadata &&
    existingFloorMetadata.name &&
    typeof existingFloorMetadata.capacity !== 'undefined' &&
    existingFloorMetadata.timeZone;

  // When hiding the modal, reset the form state
  useEffect(() => {
    if (!visible) {
      return;
    }

    // If in maps import mode, show that interface. Otherwise, show the regular creation form.
    if (mapsImport) {
      setView({ status: 'maps_import_token' });
    } else {
      setView({ status: 'form', allowBackToMapsImport: false });
    }

    if (existingFloorMetadata && existingFloorMetadata.name) {
      setName(existingFloorMetadata?.name || '');
    } else {
      setName('');
    }

    if (
      existingFloorMetadata &&
      typeof existingFloorMetadata.capacity === 'number'
    ) {
      setCapacity(`${existingFloorMetadata.capacity}`);
    } else {
      setCapacity('');
    }

    if (existingFloorMetadata && existingFloorMetadata.timeZone) {
      setTimeZone(existingFloorMetadata.timeZone);
    } else {
      setTimeZone(GUESSED_TIME_ZONE);
    }

    if (initiallyLoadedFloorplanImage) {
      setFloorplanImage({
        status: 'image_selected',
        image: initiallyLoadedFloorplanImage,
        scale: initiallyLoadedFloorplanImageScale || 1,
      });
    } else {
      setFloorplanImage({ status: 'upload' });
    }
    setHeightMapGeotiffSrc(null);

    // Reset the maps import fields when closing the modal
    setFolioSearchText('');
    setSelectedFolioId(null);
    setSelectedFolioSpaceId(null);
    setSelectedPointCloudId(null);
  }, [
    existingFloorMetadata,
    initiallyLoadedFloorplanImage,
    initiallyLoadedFloorplanImageScale,
    visible,
    mapsImport,
  ]);

  const onCreateFloor = useCallback(
    async (view: FloorplanCreatorView) => {
      if (!densityAPIClient) {
        return;
      }

      if (view.status !== 'form') {
        return;
      }
      const originalAllowBackToMapsImport = view.allowBackToMapsImport;

      // Step 1: Create the new space type floor, if required
      let floorId;
      if (existingFloorMetadata && existingFloorMetadata.spaceId) {
        floorId = existingFloorMetadata.spaceId;
      } else {
        setView({ status: 'creating_floor' });

        let parsedCapacity = null;
        if (capacity.length > 0) {
          parsedCapacity = parseFloat(capacity);
          if (isNaN(parsedCapacity)) {
            return;
          }
        }

        let parsedSize = null;
        if (size.length > 0) {
          parsedSize = parseFloat(size);
          if (isNaN(parsedSize)) {
            return;
          }
        }

        let parsedRent = null;
        if (rent.length > 0) {
          parsedRent = parseFloat(rent);
          if (isNaN(parsedRent)) {
            return;
          }
        }

        try {
          const response = await CoreAPI.createSpace(densityAPIClient, {
            name: name,
            time_zone: timeZone,
            size_area: parsedSize,
            size_area_unit:
              parsedSize !== null ? CoreSpaceSizeAreaUnit.SQUARE_FEET : null,
            annual_rent: parsedRent,
            capacity: parsedCapacity,
            space_type: CoreSpaceType.FLOOR,
            status: CoreSpaceStatus.PLANNING, // all floorplans created in planner should be set to planning mode
            parent_id: parentBuildingId,
          });
          floorId = response.data.id;
        } catch (err) {
          toast.error('Error creating floor.');
          console.warn(`Error creating floor: ${err}`);
          setView({
            status: 'form',
            allowBackToMapsImport: originalAllowBackToMapsImport,
          });
          return;
        }
      }

      // Step 2: Associate the floorplan with the new space
      let plan;
      try {
        plan = await uploadFloorplanImageData(
          densityAPIClient,
          floorId,
          floorplanImage,
          heightMapGeotiffSrc,
          (newView) => setView(newView)
        );
      } catch (err) {
        console.warn('Error creating or uploading floorplan:', err);
        toast.error('Error creating or uploading floorplan');
        setView({
          status: 'form',
          allowBackToMapsImport: originalAllowBackToMapsImport,
        });
        return;
      }

      toast.success('Plan created.');
      let path = `/${organizationId}/floorplans/${plan.id}`;
      if (heightMapGeotiffSrc) {
        path += '?setupstep=heightmap';
      }
      history.push(path);
    },
    [
      history,
      densityAPIClient,
      parentBuildingId,
      existingFloorMetadata,
      heightMapGeotiffSrc,
      name,
      timeZone,
      capacity,
      size,
      rent,
      floorplanImage,
      organizationId,
    ]
  );

  const onUploadFloorplanImage = useCallback((image: HTMLImageElement) => {
    setFloorplanImage({ status: 'image_selected', image, scale: 1 });
  }, []);

  const onUploadFloorplanCADFile = useCallback(
    (sourceFile: File, dataUrl: string) => {
      setFloorplanImage({ status: 'dxf_selected', sourceFile, dataUrl });
    },
    []
  );

  const [rawHelixToken, setRawHelixToken] = useState('');
  const helixToken = rawHelixToken.replace(/^[Bb]earer\s*/, '');

  const [folios, setFolios] = useState<
    | { status: 'pending' }
    | { status: 'loading'; abortController: AbortController }
    | { status: 'complete'; data: Array<HelixFolioSummary> }
    | { status: 'error' }
  >({ status: 'pending' });
  useEffect(() => {
    if (!helixToken) {
      return;
    }
    if (view.status === 'maps_import_token') {
      return;
    }
    const abortController = new AbortController();
    setFolios({ status: 'loading', abortController });
    HelixAPI.getFolios(helixToken, abortController.signal)
      .then((response) => {
        setFolios({ status: 'complete', data: response.data });
      })
      .catch((err) => {
        if (err.name === 'CanceledError') {
          return;
        }
        setFolios({ status: 'error' });
      });

    return () => {
      abortController.abort();
      setFolios({ status: 'pending' });
    };
  }, [helixToken, view.status]);

  const [selectedFolio, setSelectedFolio] = useState<
    | { status: 'pending' }
    | { status: 'loading'; abortController: AbortController }
    | {
        status: 'complete';
        data: HelixFolio;
        pointClouds: Array<HelixPointCloud>;
      }
    | { status: 'error' }
  >({ status: 'pending' });
  useEffect(() => {
    if (!helixToken) {
      return;
    }
    if (!selectedFolioId) {
      setSelectedFolio({ status: 'pending' });
      return;
    }

    const abortController = new AbortController();
    setSelectedFolio({ status: 'loading', abortController });
    Promise.all([
      HelixAPI.getFolio(helixToken, selectedFolioId, abortController.signal),
      HelixAPI.getFolioPointClouds(
        helixToken,
        selectedFolioId,
        abortController.signal
      ),
    ])
      .then(([folioResponse, pointCloudsResponse]) => {
        setSelectedFolio({
          status: 'complete',
          data: folioResponse.data,
          pointClouds: pointCloudsResponse.data,
        });
      })
      .catch((err) => {
        if (err.name === 'CanceledError') {
          return;
        }
        setSelectedFolio({ status: 'error' });
      });

    return () => {
      abortController.abort();
    };
  }, [helixToken, selectedFolioId]);

  const selectedFolioSpace = useMemo(() => {
    if (!selectedFolioSpaceId) {
      return null;
    }
    if (selectedFolio.status !== 'complete') {
      return null;
    }
    return (
      selectedFolio.data.spaces.find((s) => s.id === selectedFolioSpaceId) ||
      null
    );
  }, [selectedFolio, selectedFolioSpaceId]);

  const selectedPointCloud = useMemo(() => {
    if (selectedFolio.status !== 'complete') {
      return null;
    }
    return (
      selectedFolio.pointClouds.find((p) => p.id === selectedPointCloudId) ||
      null
    );
  }, [selectedFolio, selectedPointCloudId]);

  const [selectedPointCloudOriginalImage, setSelectedPointCloudOriginalImage] =
    useState<HTMLImageElement | null>(null);
  const [
    selectedPointCloudOriginalImageScale,
    setSelectedPointCloudOriginalImageScale,
  ] = useState<number>(1);
  useEffect(() => {
    if (!helixToken) {
      setSelectedPointCloudOriginalImage(null);
      setSelectedPointCloudOriginalImageScale(1);
      return;
    }
    if (!selectedPointCloud) {
      setSelectedPointCloudOriginalImage(null);
      setSelectedPointCloudOriginalImageScale(1);
      return;
    }

    if (!selectedPointCloud.thumbnails) {
      setSelectedPointCloudOriginalImage(null);
      setSelectedPointCloudOriginalImageScale(1);
      return;
    }

    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      // Resize the image to be 4096px wide
      const [resizedImage, scale] = resizeImageToMaxSize(image);
      resizedImage.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = resizedImage.width;
        canvas.height = resizedImage.height;

        const ctx = canvas.getContext('2d');
        if (!ctx) {
          throw new Error('Unable to get canvas context!');
        }

        // Invert the colors in this image
        // ref: https://stackoverflow.com/a/70097101/4115328
        ctx.filter = 'invert(1)';

        ctx.drawImage(resizedImage, 0, 0);

        const base64Image = new Image();
        base64Image.src = canvas.toDataURL('image/png');
        setSelectedPointCloudOriginalImage(base64Image);
        setSelectedPointCloudOriginalImageScale(scale);
      };
    };
    image.src = `https://api.helix.re/v1/folios/${selectedPointCloud.thumbnails.original}?access_token=${helixToken}`;
  }, [helixToken, selectedPointCloud]);

  const [pointCloudDerivatives, setPointCloudDerivatives] = useState<
    | { status: 'pending' }
    | { status: 'loading'; abortController: AbortController }
    | { status: 'complete'; data: Array<HelixPointCloudDerivative> }
    | { status: 'error' }
  >({ status: 'pending' });
  useEffect(() => {
    if (!helixToken) {
      setPointCloudDerivatives({ status: 'pending' });
      return;
    }
    if (!selectedFolioId) {
      setPointCloudDerivatives({ status: 'pending' });
      return;
    }
    if (!selectedPointCloudId) {
      setPointCloudDerivatives({ status: 'pending' });
      return;
    }

    const abortController = new AbortController();
    setPointCloudDerivatives({ status: 'loading', abortController });
    HelixAPI.getFolioPointCloudDerivatives(
      helixToken,
      selectedFolioId,
      selectedPointCloudId,
      abortController.signal
    )
      .then((response) => {
        setPointCloudDerivatives({ status: 'complete', data: response.data });
      })
      .catch((err) => {
        if (err.name === 'CanceledError') {
          return;
        }
        setPointCloudDerivatives({ status: 'error' });
      });

    return () => {
      abortController.abort();
    };
  }, [helixToken, selectedFolioId, selectedPointCloudId]);

  const mostRecentlyCreatedHeightMapDerivative = useMemo(() => {
    if (pointCloudDerivatives.status !== 'complete') {
      return null;
    }
    const heightMap = pointCloudDerivatives.data
      .sort((a, b) => a.createdAt - b.createdAt)
      .find((derivative) => derivative.type === 'CEILING');
    if (!heightMap) {
      return null;
    }
    return heightMap;
  }, [pointCloudDerivatives]);

  useEffect(() => {
    if (view.status !== 'maps_import_generating_image') {
      return;
    }
    if (selectedFolio.status !== 'complete') {
      return;
    }
    if (!selectedPointCloudOriginalImage) {
      return;
    }

    setName(selectedFolio.data.config.name);
    setFloorplanImage({
      status: 'image_selected',
      image: selectedPointCloudOriginalImage,
      scale: selectedPointCloudOriginalImageScale,
    });
    setHeightMapGeotiffSrc(
      mostRecentlyCreatedHeightMapDerivative
        ? `${mostRecentlyCreatedHeightMapDerivative.attachment.downloadLink}?access_token=${helixToken}`
        : null
    );
    setView({ status: 'form', allowBackToMapsImport: true });
  }, [
    view.status,
    helixToken,
    selectedFolio,
    selectedPointCloudOriginalImage,
    selectedPointCloudOriginalImageScale,
    mostRecentlyCreatedHeightMapDerivative,
  ]);

  switch (view.status) {
    case 'maps_import_token':
      return (
        <Modal
          visible={visible}
          onBlur={onDismiss}
          onEscape={onDismiss}
          width={480}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              minHeight: 200,
            }}
          >
            <AppBar title="Enter Helix API Token" />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                flexShrink: 1,
                padding: dust.Space3,
              }}
            >
              <p>
                The Helix API token is used to communiate with Density Maps and
                fetch information on digital twins.
              </p>
              <TextField
                value={rawHelixToken}
                onChange={(e) => setRawHelixToken(e.currentTarget.value)}
                leadingIcon={<Icons.LockClosed size={14} />}
                placeholder="Paste Token Here"
              />
            </div>

            <AppBar
              actions={
                <HorizontalForm size="medium">
                  <Button
                    size="medium"
                    type="hollow"
                    onClick={() => onDismiss()}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="medium"
                    disabled={rawHelixToken.length === 0}
                    onClick={() =>
                      setView({ status: 'maps_import_select_folio' })
                    }
                  >
                    Next
                  </Button>
                </HorizontalForm>
              }
            />
          </div>
        </Modal>
      );
    case 'maps_import_select_folio':
      const filteredFolios =
        folios.status === 'complete'
          ? applyFuzzySearch<HelixFolioSummary>(folios.data, foliosSearchText, [
              (folio) => folio.name,
            ]).map(([folio]) => folio)
          : [];

      return (
        <Modal
          visible={visible}
          onBlur={onDismiss}
          onEscape={onDismiss}
          width={480}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', height: 500 }}
          >
            <LightTheme>
              <AppBar
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      onClick={() => setView({ status: 'maps_import_token' })}
                      trailingIcon={<Icons.ArrowLeftBack size={18} />}
                      size="medium"
                      type="cleared"
                    />
                    <span style={{ marginLeft: 8 }}>Select Maps Twin</span>
                  </div>
                }
                actions={
                  <TextField
                    type="text"
                    leadingIcon={<Icons.SearchMagnifier size={14} />}
                    size="medium"
                    value={foliosSearchText}
                    onChange={(e) => setFolioSearchText(e.currentTarget.value)}
                    placeholder="Search"
                  />
                }
              />

              {folios.status === 'loading' ? (
                <FillCenter>Loading Twins...</FillCenter>
              ) : null}

              {folios.status === 'error' ? (
                <FillCenter>
                  <ErrorMessage>
                    Error loading twins! Is your token valid?
                  </ErrorMessage>
                </FillCenter>
              ) : null}

              {selectedFolio.status === 'complete' &&
              filteredFolios.length === 0 ? (
                <FillCenter>
                  <ErrorMessage>No twins found.</ErrorMessage>
                </FillCenter>
              ) : null}

              {folios.status === 'complete' ? (
                <div
                  style={{
                    overflowY: 'auto',
                    padding: 8,
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                >
                  <ul style={{ margin: 0 }}>
                    {filteredFolios.map((folio) => (
                      <li
                        key={folio.id}
                        onClick={() => {
                          setSelectedFolioId(folio.id);
                          setView({ status: 'maps_import_select_space' });
                        }}
                      >
                        {folio.name}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}

              <AppBar
                actions={
                  <HorizontalForm size="medium">
                    <Button
                      size="medium"
                      type="hollow"
                      onClick={() => onDismiss()}
                    >
                      Cancel
                    </Button>
                  </HorizontalForm>
                }
              />
            </LightTheme>
          </div>
        </Modal>
      );
    case 'maps_import_select_space':
      return (
        <Modal
          visible={visible}
          onBlur={onDismiss}
          onEscape={onDismiss}
          width={480}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', height: 500 }}
          >
            <LightTheme>
              <AppBar
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      onClick={() => {
                        setView({ status: 'maps_import_select_folio' });
                        setSelectedFolioId(null);
                      }}
                      trailingIcon={<Icons.ArrowLeftBack size={18} />}
                      size="medium"
                      type="cleared"
                    />
                    <span style={{ marginLeft: 8 }}>Select Twin Space</span>
                  </div>
                }
              />

              {selectedFolio.status === 'loading' ? (
                <FillCenter>Loading Twin Spaces...</FillCenter>
              ) : null}

              {selectedFolio.status === 'error' ? (
                <FillCenter>
                  <ErrorMessage>Error loading twin spaces!</ErrorMessage>
                </FillCenter>
              ) : null}

              {selectedFolio.status === 'complete' &&
              selectedFolio.data.spaces.length === 0 ? (
                <FillCenter>
                  <ErrorMessage>No twin spaces found.</ErrorMessage>
                </FillCenter>
              ) : null}

              {selectedFolio.status === 'complete' ? (
                <div
                  style={{
                    overflowY: 'auto',
                    flexGrow: 1,
                    flexShrink: 1,
                    padding: 8,
                  }}
                >
                  <ul style={{ margin: 0 }}>
                    {selectedFolio.data.spaces.map((helixSpace) => {
                      return (
                        <li
                          key={helixSpace.id}
                          onClick={() => {
                            setSelectedFolioSpaceId(helixSpace.id);
                            setView({
                              status: 'maps_import_select_point_cloud',
                            });
                          }}
                        >
                          {helixSpace.id} - {helixSpace.name} -{' '}
                          {moment
                            .utc(helixSpace.scanDate)
                            .local()
                            .format('MM/DD/YYYY hh:mm a')}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}

              <AppBar
                actions={
                  <HorizontalForm size="medium">
                    <Button
                      size="medium"
                      type="hollow"
                      onClick={() => onDismiss()}
                    >
                      Cancel
                    </Button>
                  </HorizontalForm>
                }
              />
            </LightTheme>
          </div>
        </Modal>
      );
    case 'maps_import_select_point_cloud':
      return (
        <Modal
          visible={visible}
          onBlur={onDismiss}
          onEscape={onDismiss}
          width={480}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', height: 500 }}
          >
            <LightTheme>
              <AppBar
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      onClick={() => {
                        setView({ status: 'maps_import_select_space' });
                        setSelectedPointCloudId(null);
                      }}
                      trailingIcon={<Icons.ArrowLeftBack size={18} />}
                      size="medium"
                      type="cleared"
                    />
                    <span style={{ marginLeft: 8 }}>Select Point Cloud</span>
                  </div>
                }
              />

              {selectedFolio.status === 'loading' ? (
                <FillCenter>Loading point clouds...</FillCenter>
              ) : null}

              {selectedFolio.status === 'error' ? (
                <FillCenter>
                  <ErrorMessage>Error loading point clouds!</ErrorMessage>
                </FillCenter>
              ) : null}

              {selectedFolio.status === 'complete' &&
              selectedFolio.pointClouds.length === 0 ? (
                <FillCenter>
                  <ErrorMessage>No point clouds found.</ErrorMessage>
                </FillCenter>
              ) : null}

              {selectedFolio.status === 'complete' && selectedFolioSpace ? (
                <div
                  style={{
                    overflowY: 'auto',
                    flexGrow: 1,
                    flexShrink: 1,
                    padding: 8,
                  }}
                >
                  <ul style={{ margin: 0 }}>
                    {selectedFolio.pointClouds.map((pointCloud) => {
                      if (pointCloud.state === 'FAILURE') {
                        return null;
                      }
                      return (
                        <li
                          key={pointCloud.id}
                          onClick={() => {
                            setSelectedPointCloudId(pointCloud.id);
                            setView({ status: 'maps_import_generating_image' });
                          }}
                        >
                          {pointCloud.thumbnails ? (
                            <img
                              src={`https://api.helix.re/v1/folios/${pointCloud.thumbnails.small}?access_token=${helixToken}`}
                              style={{ filter: 'invert(1)' }}
                              alt=""
                              width={64}
                            />
                          ) : null}
                          {pointCloud.id} - {pointCloud.name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}

              <AppBar
                actions={
                  <HorizontalForm size="medium">
                    <Button
                      size="medium"
                      type="hollow"
                      onClick={() => onDismiss()}
                    >
                      Cancel
                    </Button>
                  </HorizontalForm>
                }
              />
            </LightTheme>
          </div>
        </Modal>
      );
    case 'maps_import_generating_image':
      let generatingImageMessage = 'Generating Image...';
      if (pointCloudDerivatives.status === 'error') {
        generatingImageMessage =
          'Error: Fetching point cloud derivative failed!';
      } else if (!selectedPointCloud) {
        generatingImageMessage = 'Error: Point cloud was not found!';
      } else if (!selectedPointCloud.thumbnails) {
        generatingImageMessage =
          'Error: Point cloud missing a ghost map image!';
      } else if (pointCloudDerivatives.status === 'loading') {
        generatingImageMessage = 'Loading point cloud derivative...';
      }
      return (
        <Modal
          visible={visible}
          onBlur={onDismiss}
          onEscape={onDismiss}
          width={480}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', height: 500 }}
          >
            <LightTheme>
              <AppBar
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      trailingIcon={<Icons.ArrowLeftBack size={18} />}
                      size="medium"
                      type="cleared"
                      onClick={() => {
                        setSelectedPointCloudId(null);
                        setView({ status: 'maps_import_select_point_cloud' });
                      }}
                    />
                    <span style={{ marginLeft: 8 }}>Generating Image</span>
                  </div>
                }
              />

              <FillCenter>{generatingImageMessage}</FillCenter>
            </LightTheme>
          </div>
        </Modal>
      );

    case 'form':
    case 'creating_floor':
      const formDisabled = view.status === 'creating_floor';
      const formCanBeSubmitted =
        name.length > 0 && floorplanImage.status !== 'upload';
      const backButton = (
        <Button
          onClick={() => {
            setSelectedPointCloudId(null);
            setView({ status: 'maps_import_select_point_cloud' });
          }}
          trailingIcon={<Icons.ArrowLeftBack size={18} />}
          size="medium"
          type="cleared"
        />
      );
      return (
        <Modal
          visible={visible}
          onBlur={onDismiss}
          onEscape={onDismiss}
          width={480}
        >
          {shouldHideAllFormFields ? (
            <AppBar
              title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {view.status === 'form' && view.allowBackToMapsImport
                    ? backButton
                    : null}
                  <span style={{ marginLeft: 8 }}>
                    Add floorplan to {existingFloorMetadata.name}
                  </span>
                </div>
              }
            />
          ) : (
            <AppBar
              title={
                <Fragment>
                  {view.status === 'form' && view.allowBackToMapsImport ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {backButton}
                      <span style={{ marginLeft: 8 }}>Summary</span>
                    </div>
                  ) : (
                    'New floorplan'
                  )}
                </Fragment>
              }
            />
          )}
          <div
            className={styles.addFloorplanModalWrapper}
            data-cy="new-floorplan-modal-wrapper"
          >
            {!shouldHideAllFormFields ? (
              <Fragment>
                <FormLabel
                  label="Name"
                  required
                  input={
                    <TextField
                      size="medium"
                      placeholder="eg. Seattle Floor 05"
                      value={name}
                      onChange={(event) => setName(event.currentTarget.value)}
                      disabled={formDisabled}
                      data-cy="new-floorplan-modal-name"
                    />
                  }
                />
                <FormLabel
                  label="Capacity"
                  input={
                    <TextField
                      type="number"
                      min="0"
                      step="1"
                      size="medium"
                      placeholder="eg. 50"
                      trailingSuffix={capacity === '1' ? 'person' : 'people'}
                      value={capacity}
                      error={!capacityValid}
                      onChange={(event) =>
                        setCapacity(event.currentTarget.value)
                      }
                      disabled={formDisabled}
                      onBlur={() => {
                        if (capacity.length === 0) {
                          setCapacityValid(true);
                          return;
                        }
                        const parsed = parseInt(capacity);
                        if (isNaN(parsed) || parsed < 0) {
                          setCapacityValid(false);
                          return;
                        }
                        setCapacityValid(true);
                      }}
                      data-cy="new-floorplan-modal-capacity"
                    />
                  }
                />
                <HorizontalForm size="medium">
                  <FormLabel
                    label="Size"
                    input={
                      <TextField
                        type="number"
                        min="0"
                        step="1"
                        size="medium"
                        placeholder="eg. 10,000"
                        trailingSuffix="sq. ft"
                        value={size}
                        error={!sizeValid}
                        onChange={(event) => setSize(event.currentTarget.value)}
                        disabled={formDisabled}
                        onBlur={() => {
                          if (size.length === 0) {
                            setSizeValid(true);
                            return;
                          }
                          const parsed = parseInt(size);
                          if (isNaN(parsed) || parsed < 0) {
                            setSizeValid(false);
                            return;
                          }
                          setSizeValid(true);
                        }}
                        data-cy="new-floorplan-modal-size"
                      />
                    }
                  />
                  <FormLabel
                    label="Rent"
                    input={
                      <TextField
                        type="number"
                        min="0"
                        step="1"
                        size="medium"
                        placeholder="eg. 150,000"
                        leadingPrefix="$"
                        trailingSuffix="per year"
                        value={rent}
                        error={!rentValid}
                        onChange={(event) => setRent(event.currentTarget.value)}
                        disabled={formDisabled}
                        onBlur={() => {
                          if (rent.length === 0) {
                            setRentValid(true);
                            return;
                          }
                          const parsed = parseInt(rent);
                          if (isNaN(parsed) || parsed < 0) {
                            setRentValid(false);
                            return;
                          }
                          setRentValid(true);
                        }}
                        data-cy="new-floorplan-modal-rent"
                      />
                    }
                  />
                </HorizontalForm>
                <FormLabel
                  label="Time Zone"
                  input={
                    <SelectField
                      size="medium"
                      value={timeZone}
                      onChange={(choice) => setTimeZone(choice.id)}
                      choices={TIME_ZONE_CHOICES.map((choice) => ({
                        id: choice.id,
                        label: choice.label
                          .replace(/\//g, ' - ')
                          .replace(/_/g, ' '),
                      }))}
                      disabled={formDisabled}
                      data-cy="new-floorplan-modal-time-zone"
                    />
                  }
                />
              </Fragment>
            ) : null}
            <FormLabel
              label="Floorplan"
              required
              input={
                <CADFileUploader
                  onUploadImage={onUploadFloorplanImage}
                  onUploadCADFile={onUploadFloorplanCADFile}
                >
                  {(trigger, processing) => {
                    if (processing) {
                      return (
                        <div className={styles.addFloorplanImage}>
                          Processing...
                        </div>
                      );
                    }

                    switch (floorplanImage.status) {
                      case 'image_selected':
                        return (
                          <button
                            className={styles.addFloorplanImage}
                            disabled={
                              view.status === 'form' &&
                              view.allowBackToMapsImport
                            }
                            onClick={trigger}
                            style={{
                              backgroundImage: `url(${floorplanImage.image.src})`,
                            }}
                          ></button>
                        );
                      case 'dxf_selected':
                        return (
                          <button
                            className={styles.addFloorplanImage}
                            onClick={trigger}
                          >
                            {floorplanImage.sourceFile.name} selected
                          </button>
                        );
                      default:
                        return (
                          <button
                            className={styles.addFloorplanImage}
                            onClick={trigger}
                          >
                            <span>Click to upload a floorplan</span>
                            <span className={styles.addFloorplanImageFileTypes}>
                              (png, jpg, pdf, or dxf supported)
                            </span>
                          </button>
                        );
                    }
                  }}
                </CADFileUploader>
              }
            />
            {heightMapGeotiffSrc ? (
              <FormLabel
                label="Height Map"
                input={
                  <div className={styles.heightMapSelected}>
                    Height map selected
                  </div>
                }
              />
            ) : null}
          </div>
          <AppBar
            actions={
              <HorizontalForm size="medium">
                <Button
                  size="medium"
                  type="cleared"
                  disabled={formDisabled}
                  onClick={onDismiss}
                  data-cy="new-floorplan-modal-cancel"
                >
                  Cancel
                </Button>
                <Button
                  size="medium"
                  onClick={() => onCreateFloor(view)}
                  disabled={
                    formDisabled ||
                    !formCanBeSubmitted ||
                    !capacityValid ||
                    !sizeValid ||
                    !rentValid
                  }
                  data-cy="new-floorplan-modal-submit"
                >
                  {formDisabled ? 'Loading...' : null}
                  {!formDisabled && shouldHideAllFormFields
                    ? 'Add Floorplan'
                    : null}
                  {!formDisabled && !shouldHideAllFormFields ? 'Create' : null}
                </Button>
              </HorizontalForm>
            }
          />
        </Modal>
      );
    case 'downloading_heightmap':
      return (
        <Modal visible={visible} width={320}>
          <AppBar title="Downloading heightmap..." />
          <div className={styles.addFloorplanModalWrapper}>
            <div className={styles.progressBarWrapper}>
              <div
                className={styles.progressBarInner}
                style={{
                  width: `${view.fileDownloadPercent || 0}%`,
                }}
              />
            </div>
            <div className={styles.progressBarPercent}>
              {Math.round(view.fileDownloadPercent || 0)}%
            </div>
          </div>
        </Modal>
      );
    case 'uploading_heightmap':
      return (
        <Modal visible={visible} width={320}>
          <AppBar title="Uploading heightmap..." />
          <div className={styles.addFloorplanModalWrapper}>
            <div className={styles.progressBarWrapper}>
              <div
                className={styles.progressBarInner}
                style={{
                  width: `${view.fileUploadPercent || 0}%`,
                }}
              />
            </div>
            <div className={styles.progressBarPercent}>
              {Math.round(view.fileUploadPercent || 0)}%
            </div>
          </div>
        </Modal>
      );
    case 'uploading_image':
    case 'uploading_dxf':
      return (
        <Modal visible={visible} width={320}>
          <AppBar title="Uploading floorplan image..." />
          <div className={styles.addFloorplanModalWrapper}>
            <div
              className={styles.progressBarWrapper}
              data-cy="new-floorplan-upload-progress-bar"
            >
              <div
                className={styles.progressBarInner}
                style={{
                  width: `${view.fileUploadPercent || 0}%`,
                }}
              />
            </div>
            <div className={styles.progressBarPercent}>
              {Math.round(view.fileUploadPercent || 0)}%
            </div>
          </div>
        </Modal>
      );
    case 'creating_plan':
      return <div>Creating Plan...</div>;
    case 'upload_dxf_error':
      return <div>Error uploading dxf!</div>;
  }
};

export default NewFloorplanModal;
