import * as React from "react";

const EditorCircle = ({ width, height, color }) => (
    <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 4.58179C6.99416 5.39362 5.39362 6.99416 4.58179 9H2V15H4.58179C5.39363 17.0058 6.99416 18.6064 9 19.4182V22H15V19.4182C17.0058 18.6064 18.6064 17.0058 19.4182 15H22V9H19.4182C18.6064 6.99416 17.0058 5.39363 15 4.58179V2H9V4.58179ZM13 4H11V6H13V4ZM9 6.80251V8H15V6.80252C15.9107 7.32952 16.6705 8.08927 17.1975 9H16V15H17.1975C16.6705 15.9107 15.9107 16.6705 15 17.1975V16H9V17.1975C8.08927 16.6705 7.32952 15.9107 6.80252 15H8V9H6.80251C7.32951 8.08926 8.08926 7.32951 9 6.80251ZM13 18H11V20H13V18ZM18 11H20V13H18V11ZM6 11H4V13H6V11Z"
        fill={color}
      />
    </svg>
  );

export default EditorCircle;

