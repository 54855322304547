import * as React from 'react';

const Theater = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7911 4H12.1851C13.4138 8.89291 15.6103 12.0664 18.7807 13.4034L15.557 22H22L22 2L2 2L2 22H8.443L5.21603 13.3947C8.37542 12.0526 10.5651 8.88234 11.7911 4ZM4 9.69866C4.78739 8.47789 5.41341 6.58168 5.84029 4L4 4L4 9.69866ZM5.60196 10.9026C7.44046 9.63019 8.8157 7.34926 9.72455 4L7.86599 4C7.39409 7.02852 6.65243 9.33283 5.60196 10.9026ZM16.1252 4L14.2516 4C15.1651 7.36622 16.5497 9.65326 18.4022 10.9218C17.3449 9.3517 16.5991 7.04092 16.1252 4ZM18.1509 4C18.5794 6.59126 19.2085 8.49195 20 9.71222V4H18.1509ZM5.557 20H4L4 15.848L5.557 20ZM18.443 20H20V15.848L18.443 20Z"
      fill={color}
    />
  </svg>
);

export default Theater;
