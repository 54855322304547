import * as React from 'react';

const WellnessRoom = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      d="M15 7C15 8.65685 13.6569 10 12 10C10.3432 10 9.00002 8.65685 9.00002 7C9.00002 5.34315 10.3432 4 12 4C13.6569 4 15 5.34315 15 7Z"
      fill={color}
    />
    <path
      d="M9.00002 13.7457L7.50766 15.0248C6.59655 15.8058 5.20761 15.6142 4.54197 14.6157L3.16797 12.5547L4.83207 11.4453L6.20607 13.5063L8.56836 11.4815C8.93085 11.1708 9.39252 11 9.86994 11H14.1301C14.6075 11 15.0692 11.1708 15.4317 11.4815L17.794 13.5063L19.168 11.4453L20.8321 12.5547L19.4581 14.6157C18.7924 15.6142 17.4035 15.8058 16.4924 15.0248L15 13.7456V18H19C19.5523 18 20 18.4477 20 19C20 19.5523 19.5523 20 19 20H5.00002C4.44773 20 4.00002 19.5523 4.00002 19C4.00002 18.4477 4.44773 18 5.00002 18H9.00002V13.7457Z"
      fill={color}
    />
  </svg>
);

export default WellnessRoom;
