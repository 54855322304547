// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-formLabel-ZcjY_Z_N{display:flex;flex-direction:column;color:#3A434D;margin-bottom:16px}.dui-styles-module-formLabelLabel-3YRcS8iB{display:flex;align-items:center;justify-content:space-between;margin-bottom:10px;font-size:16px;font-weight:normal;user-select:none;color:#3A434D;position:relative}.dui-styles-module-formLabel-ZcjY_Z_N:first-child .dui-styles-module-formLabelLabel-3YRcS8iB{margin-top:0px}.dui-styles-module-formLabel-ZcjY_Z_N:last-child,.dui-styles-module-formLabel-ZcjY_Z_N:last-of-type{margin-bottom:10px}.dui-styles-module-formLabel-ZcjY_Z_N>span{flex:1;height:34px}.dui-styles-module-formLabelInfoIcon-3qGzq44s{font-family:\"Densicons\";font-size:18px;position:absolute;top:-4px;right:0px}.dui-styles-module-formLabelRequired-2NEGneWz{color:#0D183A;font-size:12px;font-weight:500}\n", ""]);
// Exports
exports.locals = {
	"formLabel": "dui-styles-module-formLabel-ZcjY_Z_N",
	"formLabelLabel": "dui-styles-module-formLabelLabel-3YRcS8iB",
	"formLabelInfoIcon": "dui-styles-module-formLabelInfoIcon-3qGzq44s",
	"formLabelRequired": "dui-styles-module-formLabelRequired-2NEGneWz"
};
module.exports = exports;
