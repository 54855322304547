import React from "react";

const ImageAlt = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 24}
    height={height || 24}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M12 9a3 3 0 11-6 0 3 3 0 016 0zm-2 0a1 1 0 11-2 0 1 1 0 012 0z"
      clipRule="evenodd"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M3 3h18v18H3V3zm2 2v12.333l2.768-3.69 3.105 2.07 4.282-4.283L19 17.197V5H5zm12.798 14H6.25l1.982-2.643 2.895 1.93 3.718-3.717L17.798 19z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default ImageAlt;
