import React from "react";

const ClockReset = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M19.9962056,11.7507991 C19.864553,7.44787317 16.3348652,3.999975 12.0000125,3.999975 C9.24445189,3.999975 6.75016611,5.40595353 5.30010759,7.6247586 L8.625,7.625 L8.625,9.625 L2,9.625 L2,3 L4,3 L3.99960626,5.99756805 C5.84924496,3.53408462 8.7821523,1.999975 12.0000125,1.999975 C17.5227972,1.999975 22.0000125,6.47719025 22.0000125,11.999975 L22.0000125,11.999975 C22,13.8116318 21.5166645,15.5549474 20.6141366,17.0818423 C18.8272238,20.104938 15.5774881,22 12,22 C7.5415101,22 3.65865966,19.0565872 2.41082702,14.8451638 C2.17812193,14.0597863 2.04250775,13.2454649 2.00850993,12.4159562 L2,12 L4,12 C4,12.7803405 4.11144046,13.5446723 4.32842281,14.2769857 C5.326291,17.6447814 8.43321556,20 12,20 C14.8629603,20 17.4617722,18.4845199 18.8924178,16.0641566 C19.5584899,14.9372971 19.9386291,13.6643142 19.9931708,12.333781 L20,12 Z M16.5,11 L16.5,13 L13,13 L13,17.625 L11,17.625 L11,11 L16.5,11 Z"
      />
    </g>
  </svg>
);

export default ClockReset;
