import * as React from 'react';

const Library = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 7H22L22 20L2 20L2 7H3V5L21 5V7ZM13 7L13 18L19 18L19 7L13 7ZM5 7L5 18H11L11 7L5 7ZM6 8L10 8V10H6V8ZM6 11H10V13H6V11ZM6 14L10 14V16L6 16V14ZM14 8L18 8V10L14 10V8ZM14 11L18 11V13L14 13V11ZM14 14H18V16H14V14Z"
      fill={color}
    />
  </svg>
);

export default Library;
