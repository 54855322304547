import React from "react";

const Share1 = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M13.0129689,7.3244575 C13.0043783,7.21743821 13,7.10923041 13,7 C13,4.790861 14.790861,3 17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 C15.6123615,11 14.3897563,10.2934084 13.6723564,9.22039715 L10.7242528,10.5376349 C10.9022498,10.9905902 11,11.4838797 11,12 C11,12.5403524 10.8928555,13.0556799 10.6986527,13.5258961 L13.6138114,14.8697636 C14.3220337,13.7463486 15.5738573,13 17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 C14.790861,21 13,19.209139 13,17 C13,16.930082 13.0017939,16.860583 13.0053381,16.7915464 L9.45771636,15.156118 C8.7795823,15.684946 7.92658355,16 7,16 C4.790861,16 3,14.209139 3,12 C3,9.790861 4.790861,8 7,8 C7.95212436,8 8.82655135,8.33266196 9.51339292,8.88809783 L13.0129689,7.3244575 L13.0129689,7.3244575 Z M17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 Z M7,10 C5.8954305,10 5,10.8954305 5,12 C5,13.1045695 5.8954305,14 7,14 C8.1045695,14 9,13.1045695 9,12 C9,10.8954305 8.1045695,10 7,10 Z M17,5 C15.8954305,5 15,5.8954305 15,7 C15,8.1045695 15.8954305,9 17,9 C18.1045695,9 19,8.1045695 19,7 C19,5.8954305 18.1045695,5 17,5 Z"
      />
    </g>
  </svg>
);

export default Share1;
