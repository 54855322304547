import styles from './styles.module.scss';
import { Icons } from '@density/dust';

import Button from 'components/button';
import Tooltip from 'components/tooltip';
import KeyboardShortcutsMenu from 'components/keyboard-shortcuts-menu';

const FloorplanZoomControls: React.FunctionComponent<{
  onZoomToFitClick: () => void;
  leftActions?: React.ReactNode;
  inEditor?: boolean;
}> = ({ onZoomToFitClick, leftActions, inEditor = false }) => {
  return (
    <div className={styles.wrapper}>
      {leftActions}
      <KeyboardShortcutsMenu.Controller
        render={(props) => {
          return <KeyboardShortcutsMenu {...props} inEditor={inEditor} />;
        }}
      />
      <Tooltip
        contents="Zoom to fit"
        placement="bottom"
        enterDelay={0}
        target={
          <Button
            size="medium"
            type="outlined"
            onClick={onZoomToFitClick}
            trailingIcon={<Icons.ZoomToFit size={18} />}
          />
        }
      />
    </div>
  );
};

export default FloorplanZoomControls;
