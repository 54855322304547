// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-wrapper-wtCM3gc1{display:flex;flex-direction:column;position:relative}.dui-styles-module-wrapper-wtCM3gc1.dui-styles-module-aboveBackdrop-2D2asglp{z-index:99999}.dui-styles-module-tagWrapper-nwFAtMI6{display:flex;margin-top:16px;height:30px;overflow-y:auto;-webkit-overflow-scrolling:touch;padding-bottom:22px;margin-bottom:-12px}.dui-styles-module-tag-2ensxU56{display:flex;align-items:center;flex-grow:0;flex-shrink:0;font-size:14px;height:28px;padding-left:8px;padding-right:1px;margin-right:8px;cursor:default;user-select:none;border-radius:4px;color:#fff;background-color:#0D183A;border:1px solid #0D183A;transition:all 150ms ease-in-out}.dui-styles-module-tag-2ensxU56.dui-styles-module-focus-3aJNdWjM{background-color:#0D183A}.dui-styles-module-tagLabel-2kZwV7ac{transform:translate(0px, -1px)}.dui-styles-module-closeIcon-AEPiKv-_{margin-left:12px;margin-top:2px;cursor:pointer}.dui-styles-module-closeIcon-AEPiKv-_:hover{opacity:0.8}.dui-styles-module-closeIcon-AEPiKv-_:active{opacity:1}.dui-styles-module-dropdown-3WXHKBSv{position:absolute;list-style-type:none;padding-left:0px;left:0px;right:0px;top:30px;padding-top:8px;padding-bottom:8px;border:1px solid #C0C6CC;border-radius:4px;background-color:#fff}.dui-styles-module-dropdownItem-3hW7yoJi{line-height:32px;padding-left:16px;padding-right:16px;font-size:16px;cursor:pointer;user-select:none}.dui-styles-module-dropdownItem-3hW7yoJi.dui-styles-module-focused-NxgavJhA{background-color:#F5F6F7}.dui-styles-module-dropdownItem-3hW7yoJi.dui-styles-module-disabled-1TwGq4aS{color:#687380;cursor:default}.dui-styles-module-noTags-3MdPAx8m{display:flex;align-items:center;font-size:16px;font-style:italic;color:#929BA6;user-select:none;cursor:default}.dui-styles-module-backdrop-2taGK10J{position:fixed;top:0px;bottom:0px;left:0px;right:0px;z-index:9999}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "dui-styles-module-wrapper-wtCM3gc1",
	"aboveBackdrop": "dui-styles-module-aboveBackdrop-2D2asglp",
	"tagWrapper": "dui-styles-module-tagWrapper-nwFAtMI6",
	"tag": "dui-styles-module-tag-2ensxU56",
	"focus": "dui-styles-module-focus-3aJNdWjM",
	"tagLabel": "dui-styles-module-tagLabel-2kZwV7ac",
	"closeIcon": "dui-styles-module-closeIcon-AEPiKv-_",
	"dropdown": "dui-styles-module-dropdown-3WXHKBSv",
	"dropdownItem": "dui-styles-module-dropdownItem-3hW7yoJi",
	"focused": "dui-styles-module-focused-NxgavJhA",
	"disabled": "dui-styles-module-disabled-1TwGq4aS",
	"noTags": "dui-styles-module-noTags-3MdPAx8m",
	"backdrop": "dui-styles-module-backdrop-2taGK10J"
};
module.exports = exports;
