import * as React from 'react';
import { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { wrap, releaseProxy, proxy, Remote } from 'comlink';
import { AxiosInstance } from 'axios';
import { Fragment } from 'react';
import { Dialogger } from '@densityco/ui';
import { Icons } from '@density/dust';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

import { Action } from './actions';
import { State, PlanDXF } from './state';
import styles from './styles.module.scss';

import FloorplanCollection from 'lib/floorplan-collection';
import { SVGWallParserResult } from 'lib/svg-wall-parser-worker';
import { FloorplanAPI, FloorplanV2Plan } from 'lib/api';

import Button from 'components/button';
import HorizontalForm from 'components/horizontal-form';
import Panel, { PanelTitle, PanelBody, PanelActions } from 'components/panel';
import ContentMessage from 'components/content-message';

const FocusedWallsLayerPanel: React.FunctionComponent<{
  state: State;
  client: AxiosInstance;
  planId: FloorplanV2Plan['id'];
  dispatch: React.Dispatch<Action>;
  onDeleteAllWalls: () => void;
}> = ({ state, client, planId, dispatch, onDeleteAllWalls }) => {
  const [activeDXF, setActiveDXF] = useState<
    | { status: 'pending' }
    | { status: 'loading' }
    | { status: 'complete'; dxf: PlanDXF }
    | { status: 'fetching_walls'; percent: number }
    | { status: 'parsing_walls'; percent: number }
    | { status: 'error' }
  >({ status: 'pending' });

  useEffect(() => {
    if (!state.activeDXFId) {
      return;
    }

    setActiveDXF({ status: 'loading' });
    FloorplanAPI.getDXF(client, planId, state.activeDXFId)
      .then((response) => {
        setActiveDXF({ status: 'complete', dxf: response.data });
      })
      .catch(() => setActiveDXF({ status: 'error' }));

    return () => {
      setActiveDXF({ status: 'pending' });
    };
  }, [client, planId, state.activeDXFId]);

  // Extract the url for the dxf's thumbnail and full svg file
  // The former is shown in the button, and the latter is used to get the wall data
  const [thumbnailImageUrl, svgFullImageUrl] = useMemo(() => {
    if (activeDXF.status !== 'complete') {
      return [null, null];
    }

    const thumbnailImage = activeDXF.dxf.assets.find(
      (asset) => asset.name === 'thumbnail_image'
    );
    if (!thumbnailImage) {
      return [null, null];
    }

    const svgFullImage = activeDXF.dxf.assets.find(
      (asset) =>
        asset.name === 'full_image' && asset.content_type === 'image/svg'
    );
    if (!svgFullImage) {
      return [null, null];
    }

    return [thumbnailImage.object_url, svgFullImage.object_url];
  }, [activeDXF]);

  const svgWallParserWorkerWorker = useRef<Worker | null>(null);
  const svgWallParserWorkerWorkerWrapped = useRef<Remote<
    import('lib/svg-wall-parser-worker').SVGWallParserWorker
  > | null>(null);

  const onImportWallsFromSVGAssociatedWithDXF = useCallback(async () => {
    if (!svgFullImageUrl) {
      return;
    }

    // Set up wall parser worker
    if (!svgWallParserWorkerWorker.current) {
      svgWallParserWorkerWorker.current = new Worker(
        new URL('lib/svg-wall-parser-worker', import.meta.url),
        {
          name: 'svg-wall-parser-worker',
        }
      );
    }
    if (!svgWallParserWorkerWorkerWrapped.current) {
      svgWallParserWorkerWorkerWrapped.current = wrap(
        svgWallParserWorkerWorker.current
      );
    }

    if (!svgWallParserWorkerWorkerWrapped.current) {
      return;
    }
    const workerWrapped = svgWallParserWorkerWorkerWrapped.current;

    // Extract walls out of the svg by processing lines, rects, and paths within
    const result: SVGWallParserResult | null = await new Promise((resolve) => {
      workerWrapped.svgWallParserWorker(
        svgFullImageUrl,
        proxy((percent: number) =>
          setActiveDXF({ status: 'fetching_walls', percent })
        ),
        proxy((percent: number) =>
          setActiveDXF({ status: 'parsing_walls', percent })
        ),
        proxy(resolve)
      );
    });

    if (!result) {
      setActiveDXF({ status: 'error' });
      return;
    }

    dispatch({
      type: 'wallsEditor.beginWithImageLineSegments',
      strokeColors: result.strokeColors,
      layers: result.layers,
      lineSegments: result.lineSegments,
      imageWidth: result.imageWidth,
      imageHeight: result.imageHeight,
    });

    // Ensure worker is terminated if coverage extents are disabled
    if (svgWallParserWorkerWorkerWrapped.current) {
      svgWallParserWorkerWorkerWrapped.current[releaseProxy]();
      svgWallParserWorkerWorkerWrapped.current = null;
    }
    if (svgWallParserWorkerWorker.current) {
      svgWallParserWorkerWorker.current.terminate();
      svgWallParserWorkerWorker.current = null;
    }
  }, [dispatch, svgFullImageUrl]);

  // When the component unmounts, terminate the svg wall parser worker if it is running
  useEffect(() => {
    return () => {
      // Ensure worker is terminated if coverage extents are disabled
      if (svgWallParserWorkerWorkerWrapped.current) {
        svgWallParserWorkerWorkerWrapped.current[releaseProxy]();
        svgWallParserWorkerWorkerWrapped.current = null;
      }
      if (svgWallParserWorkerWorker.current) {
        svgWallParserWorkerWorker.current.terminate();
        svgWallParserWorkerWorker.current = null;
      }
    };
  }, []);

  let mainButtons: Array<React.ReactNode> = [
    <Button
      key="manually"
      size="medium"
      type={activeDXF.status === 'pending' ? 'filled' : 'hollow'}
      width="100%"
      onClick={() => dispatch({ type: 'wallsEditor.begin' })}
      leadingIcon={<Icons.PencilOutlineEditor size={14} />}
      disabled={state.locked}
      data-cy="focused-walls-draw-manually"
    >
      &nbsp;Draw manually...
    </Button>,
  ];

  if (activeDXF.status === 'pending') {
    mainButtons.push(
      <Button
        key="dxf"
        size="medium"
        disabled
        data-cy="focused-walls-dxf-import"
      >
        Import from DXF...
      </Button>
    );
  } else if (activeDXF.status === 'error') {
    mainButtons.push(
      <Button
        key="dxf"
        size="medium"
        type="hollow"
        danger
        disabled
        data-cy="focused-walls-dxf-import"
      >
        Error fetching DXF!
      </Button>
    );
  } else {
    mainButtons.unshift(
      <Fragment key="dxf">
        {activeDXF.status === 'loading' || activeDXF.status === 'complete' ? (
          <Button
            size="large"
            width="100%"
            onClick={onImportWallsFromSVGAssociatedWithDXF}
            leadingIcon={
              <div
                style={{
                  backgroundColor: '#fff',
                  width: 32,
                  height: 32,
                  flexShrink: 0,
                  border: `1px solid ${dust.Gray900}`,
                  borderRadius: dust.Radius100,
                  marginRight: dust.Space4,
                  backgroundImage: `url(${thumbnailImageUrl})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                }}
              />
            }
            disabled={state.locked}
            data-cy="focused-walls-dxf-import"
          >
            Import from DXF...
          </Button>
        ) : null}
      </Fragment>
    );
  }

  mainButtons.push(
    <Button
      key="cloudplan"
      size="medium"
      disabled
      data-cy="focused-walls-cloud-plan"
    >
      Generate from Cloud Plan...
    </Button>
  );

  if (
    activeDXF.status === 'fetching_walls' ||
    activeDXF.status === 'parsing_walls'
  ) {
    return (
      <Panel position="top-left">
        <PanelTitle>Loading Walls</PanelTitle>
        <PanelBody>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: 150,
            }}
          >
            <div
              style={{
                marginBottom: dust.Space2,
                fontSize: dust.TextScale5,
              }}
            >
              {activeDXF.status === 'parsing_walls'
                ? 'Processing wall data'
                : 'Fetching wall data'}
            </div>
            <div
              style={{
                marginBottom: dust.Space2,
                fontSize: dust.TextScale4,
              }}
            >
              This may take a few minutes...
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                width: '80%',
                marginTop: dust.Space4,
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: 4,
                  backgroundColor: dust.Gray300,
                  borderRadius: 2,
                  overflow: 'hidden',
                }}
              >
                <div
                  style={{
                    height: '100%',
                    width: `${activeDXF.percent}%`,
                    backgroundColor: dust.Blue400,
                  }}
                />
              </div>
              <div
                style={{
                  fontSize: dust.TextScale4,
                  color: dust.Gray500,
                  marginTop: dust.Space3,
                  userSelect: 'none',
                }}
              >
                {Math.round(activeDXF.percent)}%
              </div>
            </div>
          </div>
        </PanelBody>
        <PanelActions
          right={
            <Button
              type="cleared"
              size="medium"
              onClick={() => dispatch({ type: 'layers.dismiss' })}
            >
              Done
            </Button>
          }
        />
      </Panel>
    );
  }

  return (
    <Panel position="top-left">
      <PanelBody>
        <div className={styles.wallsPanelWrapper} data-cy="focused-wall-panel">
          {state.walls.items.size === 0 ? (
            <Fragment>
              <ContentMessage
                header="Walls Not Annotated"
                content="Walls have not been associated with this plan yet."
                messageType="normal"
              />

              {mainButtons[0]}
              <div className={styles.wallsPanelOrBox}>OR</div>
              {mainButtons.slice(1)}
            </Fragment>
          ) : (
            <Fragment>
              <ContentMessage
                header="Walls Configured"
                content="This plan has had annotated walls drawn."
                messageType="normal"
              />
              <Button
                size="medium"
                width="100%"
                onClick={() => dispatch({ type: 'wallsEditor.begin' })}
                disabled={state.locked}
                leadingIcon={<Icons.PencilOutlineEditor size={14} />}
                data-cy="focused-walls-edit"
              >
                Edit Walls
              </Button>
            </Fragment>
          )}
        </div>
      </PanelBody>

      <PanelActions
        left={
          <HorizontalForm size="small">
            {!FloorplanCollection.isEmpty(state.walls) ? (
              <Button
                type="cleared"
                size="medium"
                disabled={state.locked}
                onClick={() => {
                  Dialogger.confirm({
                    title: 'Walls',
                    prompt:
                      "Are you sure you would like to delete this plan's walls?",
                  }).then((ok) => {
                    if (!ok) {
                      return;
                    }
                    onDeleteAllWalls();
                  });
                }}
                trailingIcon={<Icons.Trash size={18} />}
                danger
                data-cy="focused-walls-delete-button"
              />
            ) : null}
          </HorizontalForm>
        }
        right={
          <Button
            type="cleared"
            size="medium"
            onClick={() => dispatch({ type: 'layers.dismiss' })}
            data-cy="focused-walls-dismiss"
          >
            Done
          </Button>
        }
      />
    </Panel>
  );
};

export default FocusedWallsLayerPanel;
