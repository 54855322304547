import React from 'react';

const PersonFull = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 24}
    height={height || 24}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M14 4a2 2 0 11-4 0 2 2 0 014 0zM9 22V9H4V7h16v2h-5v13h-2v-6h-2v6H9z"
    ></path>
  </svg>
)

export default PersonFull;
