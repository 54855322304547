import React from "react";

const Security3 = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M12,1.94590745 L22.2136693,5.35046389 L18.8662448,17.6243539 L12,22.2018504 L5.13375522,17.6243539 L1.78633067,5.35046389 L12,1.94590745 Z M14.5,13 L9.5,13 C8.28540659,13 7.27311546,13.866159 7.04711229,15.0144913 C7.01620211,15.1715473 7,16.3338815 7,16.5 L7,16.5 L12,20 L17,16.5 C17,16.3356654 16.984144,15.1750342 16.9538784,15.0195528 C16.7298576,13.8687051 15.7163773,13 14.5,13 L14.5,13 Z M12,6 C10.3431458,6 9,7.34314575 9,9 C9,10.6568542 10.3431458,12 12,12 C13.6568542,12 15,10.6568542 15,9 C15,7.34314575 13.6568542,6 12,6 Z"
      />
    </g>
  </svg>
);

export default Security3;
