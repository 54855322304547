import React from "react";

const Cog = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M13,2 L13.7306261,4.18768812 C14.6578689,4.39217281 15.5247315,4.75752811 16.3004517,5.2529919 L18.363961,4.22182541 L19.7781746,5.63603897 L18.7468861,7.69935727 C19.2425864,8.47539934 19.6080677,9.34266547 19.8125278,10.2703534 L22,11 L22,13 L19.8123101,13.730634 C19.6078242,14.657878 19.2424672,15.5247415 18.7470014,16.3004621 L19.7781746,18.363961 L18.363961,19.7781746 L16.3006275,18.7468958 C15.5245829,19.2425952 14.6573143,19.608075 13.7296238,19.8125329 L13,22 L11,22 L10.2693739,19.8123119 C9.34213107,19.6078272 8.4752685,19.2424719 7.69954829,18.7470081 L5.63603897,19.7781746 L4.22182541,18.363961 L5.25311391,16.3006427 C4.75741362,15.5246007 4.39193229,14.6573345 4.18747216,13.7296466 L2,13 L2,11 L4.18728414,10.2712068 C4.39170979,9.34319001 4.75726738,8.47562365 5.2531256,7.69933897 L4.22182541,5.63603897 L5.63603897,4.22182541 L7.69948271,5.25303378 C8.47577866,4.75719164 9.34335439,4.39165205 10.2713786,4.18724628 L11,2 L13,2 Z M12,6 C8.6862915,6 6,8.6862915 6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 Z M12,10 C13.1045695,10 14,10.8954305 14,12 C14,13.1045695 13.1045695,14 12,14 C10.8954305,14 10,13.1045695 10,12 C10,10.8954305 10.8954305,10 12,10 Z"
      />
    </g>
  </svg>
);

export default Cog;
