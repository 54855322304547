import React from 'react';

const FolderImage = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 24}
    height={height || 24}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M10.5 3l2 2H22v16H2V3h8.5zM20 7v12H4V7h16z"
      clipRule="evenodd"
    ></path>
    <path
      fill={color}
      d="M5.669 15.027l2.508-3.768 2.815 2.114L14.874 9l3.484 5.888L19 16H5l.669-.973z"
    ></path>
  </svg>
);

export default FolderImage;
