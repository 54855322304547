import React from "react";

const SoundOn = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M14,3 L14,21 L11.5857864,21 L6.58578644,16 L2,16 L2,8 L6.58578644,8 L11.5857864,3 L14,3 Z M19.4246212,4.5753788 C21.380396,6.53115364 22.5,9.1781947 22.5,12 C22.5,14.8218053 21.380396,17.4688464 19.4246212,19.4246212 L18.0104076,18.0104076 C19.5948279,16.4259874 20.5,14.2859202 20.5,12 C20.5,9.71407981 19.5948279,7.57401263 18.0104076,5.98959236 L19.4246212,4.5753788 Z M12,5.41421356 L7.41421356,10 L4,10 L4,14 L7.41421356,14 L12,18.5857864 L12,5.41421356 Z M16.5961941,7.40380592 C17.8066647,8.61427653 18.5,10.2535053 18.5,12 C18.5,13.7464947 17.8066647,15.3857235 16.5961941,16.5961941 L15.1819805,15.1819805 C16.0210966,14.3428645 16.5,13.2106096 16.5,12 C16.5,10.7893904 16.0210966,9.65713552 15.1819805,8.81801948 L16.5961941,7.40380592 Z"
      />
    </g>
  </svg>
);

export default SoundOn;
