import * as React from 'react';

const Servery = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 15L17 15V13.9C16.6769 13.9656 16.3425 14 16 14V12C17.6569 12 19 10.6569 19 9C19 7.34315 17.6569 6 16 6C15.1764 6 14.4081 6.33216 13.8459 6.91193L12.4101 5.5197C12.9768 4.93521 13.6719 4.50097 14.4367 4.24957C13.892 3.49278 13.0036 3 12 3C10.3431 3 9 4.34315 9 6H8C6.34315 6 5 7.34315 5 9C5 10.0911 5.58657 11.0786 6.51804 11.6092C6.96567 11.8642 7.47178 12 8 12V14C7.66075 14 7.32609 13.966 7 13.8998L7 15ZM7 17V19.7778L17 19.7778L17 17H7ZM19 13.0004V22L5 22L5 13.0006C3.75942 12.07 3 10.6009 3 9C3 6.4414 4.92181 4.33166 7.40068 4.03555C8.16401 2.25068 9.93595 1 12 1C14.064 1 15.836 2.25068 16.5993 4.03555C19.0782 4.33166 21 6.4414 21 9C21 10.6358 20.2144 12.0882 19 13.0004Z"
      fill={color}
    />
  </svg>
);

export default Servery;
