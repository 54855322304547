import { useEffect } from 'react';
import * as PIXI from 'pixi.js';
import { FloorplanCoordinates } from 'lib/geometry';

import {
  Layer,
  useFloorplanLayerContext,
  PlacementMode,
  toRawHex,
} from 'components/floorplan';

import { Teal400, Red400, White } from '@density/dust/dist/tokens/dust.tokens';

// Creating polygonal spaces requires the in flight space to be rendered to the screen during the
// creation process, and this layer facilitates that
const AreaOfConcernCreationLayer: React.FunctionComponent<{
  placementModeRef: React.RefObject<PlacementMode | null>;
}> = ({ placementModeRef }) => {
  const context = useFloorplanLayerContext();

  useEffect(() => {
    const shape = new PIXI.Graphics();
    shape.name = 'area-of-concern-creation-shape';
    context.app.stage.addChild(shape);
    return () => {
      context.app.stage.removeChild(shape);
    };
  }, [context.app]);

  return (
    <Layer
      onAnimationFrame={() => {
        if (!context.viewport.current) {
          return;
        }
        const viewport = context.viewport.current;

        const shape = context.app.stage.getChildByName(
          'area-of-concern-creation-shape'
        ) as PIXI.Graphics;
        shape.visible = false;

        if (!placementModeRef.current) {
          return;
        }
        if (placementModeRef.current.type !== 'areaofconcern') {
          return;
        }
        if (placementModeRef.current.vertices.length === 0) {
          return;
        }
        shape.clear();
        shape.visible = true;

        // Generate vertex coordinate positions
        const viewportVertices = placementModeRef.current.vertices.map((v) =>
          FloorplanCoordinates.toViewportCoordinates(
            v,
            context.floorplan,
            viewport
          )
        );

        // Add the next point position indicated by the cursor to the geometry
        if (placementModeRef.current.nextPointPosition) {
          if (placementModeRef.current.mouseOverFinalPoint) {
            viewportVertices.push(viewportVertices[0]);
          } else {
            const nextPointPositionViewport =
              FloorplanCoordinates.toViewportCoordinates(
                placementModeRef.current.nextPointPosition,
                context.floorplan,
                viewport
              );
            viewportVertices.push(nextPointPositionViewport);
          }
        }

        // Draw filled polygon shape
        if (!placementModeRef.current.nextPointSelfIntersection) {
          shape.beginFill(toRawHex(Teal400), 0.12);
        }
        shape.lineStyle({ width: 1, color: toRawHex(Teal400) });
        shape.moveTo(viewportVertices[0].x, viewportVertices[0].y);
        viewportVertices.slice(1).forEach((vertex) => {
          shape.lineTo(vertex.x, vertex.y);
        });
        shape.endFill();

        // Draw vertices
        shape.beginFill(toRawHex(White));
        viewportVertices.forEach((viewportVertex) => {
          shape.drawRect(viewportVertex.x - 4, viewportVertex.y - 4, 8, 8);
        });
        shape.endFill();

        // Draw self intersection marker if a self intersection exists
        if (placementModeRef.current.nextPointSelfIntersection) {
          const selfIntersectionViewportCoords =
            FloorplanCoordinates.toViewportCoordinates(
              placementModeRef.current.nextPointSelfIntersection,
              context.floorplan,
              viewport
            );
          shape.beginFill(toRawHex(Red400));
          shape.lineStyle({ width: 0 });
          shape.drawCircle(
            selfIntersectionViewportCoords.x,
            selfIntersectionViewportCoords.y,
            8
          );
          shape.endFill();

          shape.lineStyle({ width: 2, color: toRawHex(White) });
          shape.moveTo(
            selfIntersectionViewportCoords.x - 4,
            selfIntersectionViewportCoords.y - 4
          );
          shape.lineTo(
            selfIntersectionViewportCoords.x + 4,
            selfIntersectionViewportCoords.y + 4
          );
          shape.moveTo(
            selfIntersectionViewportCoords.x - 4,
            selfIntersectionViewportCoords.y + 4
          );
          shape.lineTo(
            selfIntersectionViewportCoords.x + 4,
            selfIntersectionViewportCoords.y - 4
          );
        }
      }}
    />
  );
};

export default AreaOfConcernCreationLayer;
