import * as React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

type TopLeftPanelProps = {
  position: 'top-left';
  left?: React.ReactText;
  top?: React.ReactText;
  width?: React.ReactText;
  height?: React.ReactText;
};

type TopRightPanelProps = {
  position: 'top-right';
  right?: React.ReactText;
  top?: React.ReactText;
  width?: React.ReactText;
  height?: React.ReactText;
};

type BottomLeftPanelProps = {
  position: 'bottom-left';
  left?: React.ReactText;
  bottom?: React.ReactText;
  width?: React.ReactText;
  height?: React.ReactText;
};

type BottomRightPanelProps = {
  position: 'bottom-right';
  right?: React.ReactText;
  bottom?: React.ReactText;
  width?: React.ReactText;
  height?: React.ReactText;
};

type PanelProps =
  | TopLeftPanelProps
  | TopRightPanelProps
  | BottomLeftPanelProps
  | BottomRightPanelProps;

const Panel: React.FunctionComponent<PanelProps> = (props) => {
  let style: React.CSSProperties = { width: props.width, height: props.height };
  switch (props.position) {
    case 'top-left':
      style.top = props.top;
      style.left = props.left;
      break;
    case 'top-right':
      style.top = props.top;
      style.right = props.right;
      break;
    case 'bottom-left':
      style.bottom = props.bottom;
      style.left = props.left;
      break;
    case 'bottom-right':
      style.bottom = props.bottom;
      style.right = props.right;
      break;
  }

  return (
    <div
      className={classnames(styles.panelWrapper, {
        [styles.topLeft]: props.position === 'top-left',
        [styles.topRight]: props.position === 'top-right',
        [styles.bottomLeft]: props.position === 'bottom-left',
        [styles.bottomRight]: props.position === 'bottom-right',
      })}
      style={style}
    >
      <div className={styles.panel}>{props.children}</div>
    </div>
  );
};

export const PanelActions: React.FunctionComponent<{
  left?: React.ReactNode;
  right?: React.ReactNode;
}> = ({ left, right }) => (
  <div className={styles.panelActions}>
    <div className={styles.panelActionsLeft}>{left}</div>
    <div className={styles.panelActionsRight}>{right}</div>
  </div>
);

export const PanelHeader: React.FunctionComponent = ({ children }) => (
  <div className={styles.panelHeader}>{children}</div>
);

export const PanelTitle: React.FunctionComponent<{
  icon?: React.ReactNode;
  dark?: boolean;
  onClick?: () => void;
}> = ({ icon, dark, onClick, children }) => (
  <div
    className={classnames(styles.panelTitle, {
      [styles.clickable]: onClick,
      [styles.dark]: dark,
    })}
    onClick={onClick}
  >
    {icon ? <div className={styles.panelTitleIcon}>{icon}</div> : null}
    <div className={styles.panelTitleText}>{children}</div>
  </div>
);

export const PanelBody: React.FunctionComponent = ({ children }) => (
  <div className={styles.panelBody}>{children}</div>
);

export const PanelHeaderWell: React.FunctionComponent = ({ children }) => (
  <div className={styles.panelHeaderWell}>{children}</div>
);

export const PanelBodySection: React.FunctionComponent = ({ children }) => (
  <div className={styles.panelBodySection}>{children}</div>
);

export default Panel;
