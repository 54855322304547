import { useCallback, useEffect, useRef } from 'react';
import * as PIXI from 'pixi.js';

import { ViewportCoordinates, FloorplanCoordinates } from 'lib/geometry';
import { ParsedGeoTiff, GeoTiffTiepoint } from 'lib/geotiff';
import HeightMap from 'lib/heightmap';

import {
  Layer,
  useFloorplanLayerContext,
  isWithinViewport,
} from 'components/floorplan';
import { FloorplanLayerContextData } from 'components/floorplan/types';
import { useParseGeoTiff } from 'components/height-map-editor';
import ImageRegistrationLayer from './image-registration-layer';

// A PIXI.js renderer for a geotiff.
//
// Ideally, one would specify a url to a cloud optimized geotiff, but this also works with regular
// geotiffs, albeit quite a bit slower.
class PIXIGeoTiff extends PIXI.Container {
  parsedResults: ParsedGeoTiff;
  limits:
    | { enabled: false }
    | { enabled: true; minMeters: number; maxMeters: number };
  opacity: number;
  abortController: AbortController;

  imageWidth: number;
  imageHeight: number;
  tileSizeInPx: number;
  initialLevelOfDetail: number;

  shaderUniforms: {
    uOpacity: number;
    uMinHeightMeters: number;
    uMaxHeightMeters: number;
    uMinLimitMeters: number;
    uMaxLimitMeters: number;
  };

  tileMetadata: {
    [name: string]: {
      x: number;
      y: number;
      levelOfDetail: number;
      tileSizeInPx: number;
      imageWidth: number;
      beingOverwritten: boolean;
    };
  };

  constructor(
    parsedResults: ParsedGeoTiff,
    limits?:
      | { enabled: false }
      | { enabled: true; minMeters: number; maxMeters: number },
    opacity?: number,
    abortController?: AbortController
  ) {
    super();
    this.parsedResults = parsedResults;
    this.limits = limits || { enabled: false };
    this.opacity = typeof opacity !== 'undefined' ? opacity : 100;
    this.abortController = abortController || new AbortController();

    this.imageWidth = this.parsedResults.geotiffImage.getWidth();
    this.imageHeight = this.parsedResults.geotiffImage.getHeight();

    this.tileMetadata = {};

    // At least as of mid july 2022, heightmap geotiffs have a tile size of 512 with 3 overview
    // layers.
    //
    // So starting at 2048 means that subdividing once wil result in 1024 wide layers, and
    // subdividing again will be 512 wide layers.
    this.tileSizeInPx = this.parsedResults.geotiffImage.getTileWidth() * 4;
    this.initialLevelOfDetail = 0.25;

    // If the image isn't tiled, then render the whole thing at the maximum level of detail
    // This case should be hit with url-like things like base64 urls
    const isWholeGeotiffInMemory =
      !this.parsedResults.geotiffImage.isTiled ||
      this.parsedResults.geotiffImage?.source?.arrayBuffer;
    if (isWholeGeotiffInMemory) {
      this.initialLevelOfDetail = 1;
    }

    // Set up all shader uniforms in preparation for defining the shader
    this.shaderUniforms = {
      // NOTE: these are dummy values to make typescript happy, the real values are set in
      // `this.updateLimits`
      uOpacity: 1,
      uMinHeightMeters: 0,
      uMaxHeightMeters: 0,
      uMinLimitMeters: 0,
      uMaxLimitMeters: 0,
    };
    this.updateLimitsAndOpacity(this.limits, this.opacity);

    // Information about PIXI built in shader uniforms:
    // https://github.com/pixijs/pixijs/wiki/v5-Creating-filters
    const filter = new PIXI.Filter(
      undefined,
      `
      varying vec2 vTextureCoord;

      uniform sampler2D uSampler;
      uniform float uMinHeightMeters;
      uniform float uMaxHeightMeters;
      uniform float uMinLimitMeters;
      uniform float uMaxLimitMeters;
      uniform float uOpacity;

      // Define color band stop points in the gradient
      // COLOR_A = Smallest height value
      // COLOR_D = Largest height value
      const vec3 COLOR_A = vec3(0.10980392156862745, 0.16470588235294117, 0.34901960784313724); // #1C2A59
      const vec3 COLOR_B = vec3(0.10588235294117647, 0.27450980392156865, 0.5764705882352941); // #1B4693
      const vec3 COLOR_C = vec3(0.7372549019607844, 0.2196078431372549, 0.396078431372549); // #BC3865
      const vec3 COLOR_D = vec3(1, 0.7725490196078432, 0.5882352941176471); // #FFC596

      const float ONE_THIRD = 1.0 / 3.0;
      const float TWO_THIRDS = 2.0 / 3.0;

      void main(void) {
        vec4 source = texture2D(uSampler, vTextureCoord);

        float original = source.r;

        // Ignore empty heightmap areas
        if (original == 0.0) {
          gl_FragColor = vec4(0, 0, 0, 0);
          // Uncomment the below to highlight voids in the heightmap:
          // gl_FragColor = vec4(0, 1.0, 0, uOpacity);
          return;
        }

        // Back-compute height using known min and max value in the geotiff
        float heightMeters = (original * (uMaxHeightMeters - uMinHeightMeters)) + uMinHeightMeters;

        // Then normalize that height value within the specified limits
        float ratio = (heightMeters - uMinLimitMeters) / (uMaxLimitMeters - uMinLimitMeters);

        // Pixels that are out of bounds of the height limits should be invisible
        if (ratio < 0.0 || ratio > 1.0) {
          gl_FragColor = vec4(0, 0, 0, 0);
          return;
        }

        // Mix colors according to gradient scale
        vec3 result;
        if (ratio < ONE_THIRD) {
          result = mix(COLOR_A, COLOR_B, ratio);
        } else if (ratio < TWO_THIRDS) {
          result = mix(COLOR_B, COLOR_C, (ratio - ONE_THIRD) * 3.0);
        } else {
          result = mix(COLOR_C, COLOR_D, (ratio - TWO_THIRDS) * 3.0);
        }

        gl_FragColor = vec4(result, uOpacity);
      }
    `,
      this.shaderUniforms
    );
    this.filters = [filter];

    this.drawTiles(
      0,
      0,
      this.imageWidth,
      this.imageHeight,
      this.tileSizeInPx,
      this.initialLevelOfDetail
    );
  }

  async drawTiles(
    upperLeftXInPx: number,
    upperLeftYInPx: number,
    widthInPx: number,
    heightInPx: number,
    tileSizeInPx: number,
    levelOfDetail: number
  ) {
    // If there's a bigger tile in this area, mark it as being replaced
    const existingTile = this.getChildByName(
      `tile-${upperLeftXInPx}-${upperLeftYInPx}-${widthInPx}`
    );
    if (existingTile) {
      this.tileMetadata[existingTile.name].beingOverwritten = true;
    }

    const newTiles: Array<PIXI.Sprite> = [];

    // Populate all the new tiles within this region
    for (
      let x = upperLeftXInPx;
      x < upperLeftXInPx + widthInPx;
      x += tileSizeInPx
    ) {
      for (
        let y = upperLeftYInPx;
        y < upperLeftYInPx + heightInPx;
        y += tileSizeInPx
      ) {
        const result = await this.parsedResults.getTileImage(
          x,
          y,
          tileSizeInPx,
          tileSizeInPx,
          levelOfDetail,
          this.abortController.signal
        );

        if (!result) {
          continue;
        }

        const [bytes, scaledWidthInPixels, scaledHeightInPixels] = result;

        // Convert the pixel data into a pixi.js texture.
        const texture = PIXI.Texture.fromBuffer(
          bytes as unknown as Uint8Array,
          scaledWidthInPixels,
          scaledHeightInPixels
        );

        const tile = new PIXI.Sprite(texture);
        tile.name = `tile-${x}-${y}-${tileSizeInPx}`;
        tile.x = x;
        tile.y = y;
        tile.width = tileSizeInPx;
        tile.height = tileSizeInPx;
        // NOTE: uncomment the below line for debugging, it will tint each tile a random color
        // tile.tint = Math.floor(Math.random() * 0xffffff);

        this.tileMetadata[tile.name] = {
          x,
          y,
          tileSizeInPx,
          imageWidth: scaledWidthInPixels,
          levelOfDetail,
          beingOverwritten: false,
        };
        this.addChild(tile);
        newTiles.push(tile);
      }
      if (this.abortController.signal.aborted) {
        continue;
      }
    }

    // If the operation aborted early, delete all the new tiles
    if (this.abortController.signal.aborted) {
      for (const tile of newTiles) {
        this.removeChild(tile);
      }

      // Delete the old tile in this region
      if (existingTile) {
        this.removeChild(existingTile);
        delete this.tileMetadata[existingTile.name];
      }
    }
  }

  // When called, update the uniforms passed into the shader to control how the heightmap is
  // rendered.
  updateLimitsAndOpacity(
    limits:
      | { enabled: false }
      | { enabled: true; minMeters: number; maxMeters: number },
    opacity: number = 100
  ) {
    this.limits = limits;
    this.opacity = opacity;

    this.shaderUniforms.uOpacity = this.opacity / 100;

    this.shaderUniforms.uMinHeightMeters = this.parsedResults.smallestValue;
    this.shaderUniforms.uMaxHeightMeters = this.parsedResults.largestValue;

    if (!this.limits.enabled) {
      // If limits aren't enabled, set them to be the extents of the geotiff
      this.shaderUniforms.uMinLimitMeters = this.parsedResults.smallestValue;
      this.shaderUniforms.uMaxLimitMeters = this.parsedResults.largestValue;
      return;
    }

    this.shaderUniforms.uMinLimitMeters = this.limits.minMeters;
    this.shaderUniforms.uMaxLimitMeters = this.limits.maxMeters;
  }

  // Every frame, use the viewport position to show and hide tile to avoid rendering unused tiles
  // This actually makes a substantial performance difference
  updateTileVisibility(context: FloorplanLayerContextData) {
    if (!context.viewport.current) {
      return;
    }

    for (const tile of this.children) {
      const tileMetadata = this.tileMetadata[tile.name];
      if (!tileMetadata) {
        continue;
      }

      const bounds = tile.getGlobalPosition(undefined, true);

      const tileSizeInPxViewport =
        tileMetadata.tileSizeInPx *
        this.parsedResults.scale *
        context.floorplan.scale *
        context.viewport.current.zoom;

      // Figure out if the center of the tile is not in the viewport and hide it if so
      tile.renderable = isWithinViewport(
        context,
        ViewportCoordinates.create(
          bounds.x + tileSizeInPxViewport / 2,
          bounds.y + tileSizeInPxViewport / 2
        ),
        -1 * 2 * tileSizeInPxViewport
      );
      if (!tile.renderable) {
        continue;
      }

      // Don't try to split up tiles that are already as detailed as they can be
      if (tileMetadata.levelOfDetail >= 1) {
        continue;
      }
      // Don't try to split up tiles that are currently being split up
      if (tileMetadata.beingOverwritten) {
        continue;
      }

      if (tileSizeInPxViewport > tileMetadata.imageWidth) {
        const smallestTileWidthPx =
          this.parsedResults.geotiffImage.getTileWidth();

        // Each new tile is sized to be a quarter of the area of the old tile.
        let newTileSizeInPx = Math.floor(tileMetadata.tileSizeInPx / 2);
        if (newTileSizeInPx < smallestTileWidthPx) {
          newTileSizeInPx = smallestTileWidthPx;
        }
        const newTileSizeInPxViewport =
          tileMetadata.tileSizeInPx *
          this.parsedResults.scale *
          context.floorplan.scale *
          context.viewport.current.zoom;

        let newLevelOfDetail = tileMetadata.levelOfDetail;
        while (newTileSizeInPx / newLevelOfDetail > newTileSizeInPxViewport) {
          newLevelOfDetail *= 2;
        }
        if (newLevelOfDetail >= 1) {
          newLevelOfDetail = 1;
        }

        this.drawTiles(
          tileMetadata.x,
          tileMetadata.y,
          tileMetadata.tileSizeInPx,
          tileMetadata.tileSizeInPx,
          newTileSizeInPx,
          newLevelOfDetail
        );
      }
    }
  }

  destroy() {
    // Stop the geotiff deserialization / image drawing if it's in progress
    this.abortController.abort();

    // NOTE: it's really important "true" is here, without it, the tile textures won't be freed when
    // the geotiff is destroyed and this will cause a memory leak
    super.destroy(true);
  }
}

export const HeightMapLayer: React.FunctionComponent<{
  heightMap: HeightMap;
}> = ({ heightMap }) => {
  const context = useFloorplanLayerContext();
  const geotiffParseResults = useParseGeoTiff(heightMap.url);

  useEffect(() => {
    const container = new PIXI.Container();
    container.name = 'height-map-layer-container';

    // Add this layer right above the base image layer
    context.app.stage.addChildAt(container, 1);

    return () => {
      context.app.stage.removeChild(container);
    };
  }, [context.app.stage]);

  // Once the parse results come back, render the geotiff
  useEffect(() => {
    if (geotiffParseResults.status !== 'complete') {
      return;
    }

    const container = context.app.stage.getChildByName(
      'height-map-layer-container'
    ) as PIXI.Container;

    const heightMapSprite = new PIXIGeoTiff(geotiffParseResults);
    heightMapSprite.name = 'ceiling-raster';
    container.addChild(heightMapSprite);

    return () => {
      container.removeChild(heightMapSprite);
      heightMapSprite.destroy();
    };
  }, [
    geotiffParseResults,
    context.floorplan,
    context.viewport,
    context.app.stage,
  ]);

  // When the opacity or limits change, update the shader within the PIXIGeoTiff
  useEffect(() => {
    const container = context.app.stage.getChildByName(
      'height-map-layer-container'
    ) as PIXI.Container;
    const heightMapSprite = container.getChildByName(
      'ceiling-raster'
    ) as PIXIGeoTiff;

    if (!heightMapSprite) {
      return;
    }

    heightMapSprite.updateLimitsAndOpacity(heightMap.limits, heightMap.opacity);
  }, [
    context.app.stage,
    geotiffParseResults,
    heightMap.limits,
    heightMap.opacity,
  ]);

  if (geotiffParseResults.status !== 'complete') {
    return null;
  }

  return (
    <Layer
      onAnimationFrame={() => {
        if (!context.viewport.current) {
          return;
        }

        const container = context.app.stage.getChildByName(
          'height-map-layer-container'
        ) as PIXI.Container;
        const heightMapSprite = container.getChildByName(
          'ceiling-raster'
        ) as PIXIGeoTiff;

        const positionViewport = FloorplanCoordinates.toViewportCoordinates(
          heightMap.position,
          context.floorplan,
          context.viewport.current
        );

        // Hide tiles not currently in the viewport
        heightMapSprite.updateTileVisibility(context);

        heightMapSprite.x = positionViewport.x;
        heightMapSprite.y = positionViewport.y;
        heightMapSprite.angle = heightMap.rotation;
        heightMapSprite.scale.set(
          geotiffParseResults.scale *
            context.floorplan.scale *
            context.viewport.current.zoom
        );
      }}
    />
  );
};

const HeightMapRegistrationLayer: React.FunctionComponent<{
  url: string;

  position: FloorplanCoordinates;
  rotationInDegrees: number;
  // NOTE: this scale value is in addition to the intrinsic geotiff scale, and allows the heightmap
  // editor to be used to scale the floorplan image. When in doubt, this should default to zero
  additionalScale?: number;
  opacity: number;

  limits:
    | { enabled: false }
    | { enabled: true; minMeters: number; maxMeters: number };

  onGeoTiffLoaded?: (tiePoint: GeoTiffTiepoint, scale: number) => void;

  onDragMove?: (
    position: FloorplanCoordinates,
    additionalScale: number,
    rotationInDegrees: number
  ) => void;
}> = ({
  url,
  position,
  additionalScale,
  rotationInDegrees,
  onGeoTiffLoaded,
  opacity,
  limits,
  onDragMove,
}) => {
  const context = useFloorplanLayerContext();
  const geotiffParseResults = useParseGeoTiff(url);

  const geoTiffSprite = useRef<PIXIGeoTiff | null>(null);
  useEffect(() => {
    if (geotiffParseResults.status !== 'complete') {
      return;
    }

    if (onGeoTiffLoaded) {
      const tiePoint = geotiffParseResults.geotiffImage.getTiePoints();
      if (!isNaN(geotiffParseResults.baseHeightValue)) {
        tiePoint[0].z = geotiffParseResults.baseHeightValue;
      }

      onGeoTiffLoaded(
        tiePoint,
        geotiffParseResults.geotiffImage.getFileDirectory().ModelPixelScale[0]
      );
    }

    geoTiffSprite.current = new PIXIGeoTiff(geotiffParseResults);

    return () => {
      if (geoTiffSprite.current) {
        geoTiffSprite.current.destroy();
        geoTiffSprite.current = null;
      }
    };
  }, [geotiffParseResults, onGeoTiffLoaded]);

  // When the limits or opacity changes, update the shader within the PIXIGeoTiff
  useEffect(() => {
    if (geotiffParseResults.status !== 'complete') {
      return;
    }
    if (!geoTiffSprite.current) {
      return;
    }

    geoTiffSprite.current.updateLimitsAndOpacity(limits, opacity);
  }, [geotiffParseResults, limits, opacity]);

  // When the limits or opacity changes, update the shader within the PIXIGeoTiff
  useEffect(() => {
    if (geotiffParseResults.status !== 'complete') {
      return;
    }
    if (!geoTiffSprite.current) {
      return;
    }

    geoTiffSprite.current.updateLimitsAndOpacity(limits, opacity);
  }, [geotiffParseResults, limits, opacity]);

  const onDragMoveWithAdjustedScale = useCallback(
    (position: FloorplanCoordinates, scale: number, rotation: number) => {
      if (!onDragMove) {
        return;
      }
      if (geotiffParseResults.status !== 'complete') {
        return;
      }
      return onDragMove(position, scale / geotiffParseResults.scale, rotation);
    },
    [onDragMove, geotiffParseResults]
  );

  if (geotiffParseResults.status !== 'complete') {
    return null;
  }

  const imageWidth = geotiffParseResults.geotiffImage.getWidth();
  const imageHeight = geotiffParseResults.geotiffImage.getHeight();

  return (
    <ImageRegistrationLayer
      imageWidth={imageWidth}
      imageHeight={imageHeight}
      createImage={(container) => {
        if (geoTiffSprite.current) {
          container.addChild(geoTiffSprite.current);
        }
      }}
      updateImage={(container) => {
        if (geoTiffSprite.current) {
          geoTiffSprite.current.updateTileVisibility(context);
        }
      }}
      removeImage={(container) => {
        if (geoTiffSprite.current) {
          container.removeChild(geoTiffSprite.current);
        }
      }}
      position={position}
      isMovable
      rotationInDegrees={rotationInDegrees}
      isRotatable
      isScalable={typeof additionalScale !== 'undefined'}
      scale={
        geotiffParseResults.scale *
        (typeof additionalScale === 'undefined' ? 1 : additionalScale)
      }
      onDragMove={onDragMoveWithAdjustedScale}
    />
  );
};

export default HeightMapRegistrationLayer;
