// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-datePicker-3-xFZibz.dui-styles-module-mouseMode-3f2VGT6o :focus{outline:none}.dui-styles-module-datePicker-3-xFZibz .DayPicker-Caption{color:#3F465F}.dui-styles-module-datePicker-3-xFZibz .DayPicker-Weekday{color:#9FA3AF}.dui-styles-module-datePicker-3-xFZibz .DayPicker-Day{border-radius:4px !important;font-weight:500 !important;color:#3F465F}.dui-styles-module-datePicker-3-xFZibz .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover{background-color:#EFF0F2 !important}.dui-styles-module-datePicker-3-xFZibz .DayPicker-Day--disabled{color:#CFD1D7}.dui-styles-module-datePicker-3-xFZibz .DayPicker-Day--today{font-weight:bold !important;color:#1A79FF}.dui-styles-module-datePicker-3-xFZibz .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside){background-color:#1A79FF !important;color:#fff}.dui-styles-module-datePickerIcon-3sXmYw_h{height:20px;cursor:pointer}.dui-styles-module-datePickerIcon-3sXmYw_h:hover{opacity:0.9}.dui-styles-module-datePickerIcon-3sXmYw_h:active{opacity:1}.dui-styles-module-datePickerIconDisabled-3GuCvcbD{cursor:default}.dui-styles-module-datePickerIconDisabled-3GuCvcbD:hover{opacity:1}.dui-styles-module-datePickerIconLeft-3xnncuCr{margin-right:14px}.dui-styles-module-datePickerIconRight-1ZD0Kl8f{margin-left:14px}\n", ""]);
// Exports
exports.locals = {
	"datePicker": "dui-styles-module-datePicker-3-xFZibz",
	"mouseMode": "dui-styles-module-mouseMode-3f2VGT6o",
	"datePickerIcon": "dui-styles-module-datePickerIcon-3sXmYw_h",
	"datePickerIconDisabled": "dui-styles-module-datePickerIconDisabled-3GuCvcbD",
	"datePickerIconLeft": "dui-styles-module-datePickerIconLeft-3xnncuCr",
	"datePickerIconRight": "dui-styles-module-datePickerIconRight-1ZD0Kl8f"
};
module.exports = exports;
