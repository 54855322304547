import React from "react";

const Security2 = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M12,1.94590745 L22.2136693,5.35046389 L18.8662448,17.6243539 L12,22.2018504 L5.13375522,17.6243539 L1.78633067,5.35046389 L12,1.94590745 Z M12,4.05409255 L4.21366933,6.64953611 L6.56633067,15.2789074 L6.58910227,15.1774165 C6.89767552,13.9245579 7.9720365,13 9.25,13 L14.75,13 C16.0624586,13 17.1601749,13.9751432 17.4342001,15.2795747 L19.7863307,6.64953611 L12,4.05409255 Z M12,6 C13.6568542,6 15,7.34314575 15,9 C15,10.6568542 13.6568542,12 12,12 C10.3431458,12 9,10.6568542 9,9 C9,7.34314575 10.3431458,6 12,6 Z"
      />
    </g>
  </svg>
);

export default Security2;
