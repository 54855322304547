import { useCallback } from 'react';
import { toast } from 'react-toastify';
import * as React from 'react';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Dialogger } from '@densityco/ui';
import { Icons } from '@density/dust';
import { Green400 } from '@density/dust/dist/tokens/dust.tokens';

import { Action } from './actions';
import { State } from './state';
import styles from './styles.module.scss';
import { FloorplanAPI, FloorplanV2Plan } from 'lib/api';
import HeightMap from 'lib/heightmap';

import Button from 'components/button';
import HorizontalForm from 'components/horizontal-form';
import Tooltip from 'components/tooltip';
import Panel, { PanelBody, PanelActions } from 'components/panel';
import NotesBox from 'components/notes-box';
import OpacityField from 'components/opacity-field';
import ContentMessage from 'components/content-message';

const FocusedHeightmapLayerPanel: React.FunctionComponent<{
  state: State;
  dispatch: React.Dispatch<Action>;
  client: AxiosInstance;
  plan: FloorplanV2Plan;
  onChangeOpacity: (opacity: HeightMap['opacity']) => void;
  onChangeNotes: (notes: HeightMap['notes']) => void;
  onDelete: () => void;
}> = ({
  state,
  dispatch,
  client,
  plan,
  onChangeOpacity,
  onChangeNotes,
  onDelete,
}) => {
  const onImportHeightMapClick = useCallback(() => {
    const inputTypeFile = document.createElement('input');
    inputTypeFile.type = 'file';
    inputTypeFile.accept = 'image/tiff';
    inputTypeFile.setAttribute(
      'data-cy',
      'focused-height-map-upload-button-file'
    );
    inputTypeFile.style.display = 'none';
    document.body.appendChild(inputTypeFile);

    inputTypeFile.addEventListener('change', (e) => {
      // Once a file is selected, remove the dummy input[type=file]
      document.body.removeChild(inputTypeFile);

      if (!e.srcElement) {
        return;
      }

      const target = e.target as HTMLInputElement;
      if (!target.files) {
        return;
      }

      const file = target.files[0];
      if (!file) {
        return;
      }

      dispatch({ type: 'heightMapImport.startUpload', fileName: file.name });

      const reader = new FileReader();
      reader.onload = async (e) => {
        if (!e.target) {
          return;
        }
        const dataUrl = e.target.result;
        if (!dataUrl || dataUrl instanceof ArrayBuffer) {
          return;
        }

        let signedUrlResponse;
        try {
          signedUrlResponse = (
            await FloorplanAPI.imageUpload(client, {
              floor_id: plan.floor.id,
              ext: 'tiff',
              content_type: 'image/tiff',
            })
          ).data;
        } catch (err) {
          toast.error('Error uploading image.');
          dispatch({ type: 'heightMapImport.cancel' });
          return;
        }

        const [, imageData] = dataUrl.split(',');
        const {
          key: objectKey,
          signed_url: signedUrl,
          get_signed_url: getSignedUrl,
        } = signedUrlResponse;

        const imageBytesAsString = atob(imageData);
        const byteArray = new Uint8Array(imageBytesAsString.length);
        for (let i = 0; i < imageBytesAsString.length; i++) {
          byteArray[i] = imageBytesAsString.charCodeAt(i);
        }

        let putImageResponse: AxiosResponse;
        try {
          putImageResponse = await axios.put(signedUrl, byteArray.buffer, {
            headers: {
              'Content-Type': 'image/tiff',
            },
            onUploadProgress: (event) => {
              if (typeof event.total === 'undefined') {
                return;
              }
              const percent = (event.loaded / event.total) * 100;
              dispatch({ type: 'heightMapImport.uploadProgress', percent });
            },
          });
        } catch (err) {
          toast.error('Error uploading image.');
          dispatch({ type: 'heightMapImport.cancel' });
          return;
        }

        if (putImageResponse.status !== 200) {
          toast.error('Error uploading image.');
          dispatch({ type: 'heightMapImport.cancel' });
          return;
        }

        dispatch({
          type: 'heightMapImport.finishUpload',
          objectKey,
          url: getSignedUrl,
        });
      };
      reader.onerror = () => {
        toast.error('Error reading height map file!');
        dispatch({ type: 'heightMapImport.cancel' });
      };
      reader.readAsDataURL(file);
    });
    inputTypeFile.click();
  }, [client, dispatch, plan.floor.id]);

  let body: React.ReactNode = null;

  switch (state.heightMapImport.view) {
    case 'disabled':
      if (state.heightMap.enabled) {
        body = (
          <div className={styles.heightMapPanelWrapper}>
            <div className={styles.heightMapPanelOpacity}>
              Opacity:
              <OpacityField
                value={state.heightMap.opacity}
                onChange={(opacity) => onChangeOpacity(opacity)}
                data-cy="focused-height-map-opacity-text"
                disabled={state.locked}
              />
            </div>

            <NotesBox
              notes={state.heightMap.notes}
              onNotesEdited={(notes) => onChangeNotes(notes)}
              disabled={state.locked}
              data-cy="focused-height-map-notes"
            />

            <Button
              size="medium"
              width="100%"
              onClick={() =>
                dispatch({ type: 'heightMapImport.beginRegistration' })
              }
              disabled={state.locked}
              leadingIcon={<Icons.PencilOutlineEditor size={14} />}
              data-cy="focused-height-map-edit-button"
            >
              Editor
            </Button>
          </div>
        );
      } else {
        body = (
          <div className={styles.heightMapPanelWrapper}>
            <ContentMessage
              header="Height Map Not Linked"
              content="A Density Maps heightmap has not been linked to this plan yet."
              messageType="normal"
            />
            {/*
            <Button
              width="100%"
              size="medium"
              leadingIcon={<Icons.GlobeWorldEarthMap size={14} />}
            >
              Request from Maps
            </Button>
            */}

            <Button
              onClick={onImportHeightMapClick}
              width="100%"
              size="medium"
              leadingIcon={<Icons.CloudUpload size={18} />}
              disabled={state.locked}
              data-cy="focused-height-map-upload-locally-button"
            >
              Upload Locally
            </Button>
          </div>
        );
      }
      break;
    case 'uploading-image':
      body = (
        <ContentMessage
          header="Uploading"
          content={state.heightMapImport.fileName}
          messageType="loading"
          loadingStep={
            typeof state.heightMapImport.fileUploadPercent === 'number'
              ? Math.round(state.heightMapImport.fileUploadPercent)
              : 0
          }
        />
      );
      break;
    case 'ready':
      body = (
        <div className={styles.heightMapPanelWrapper}>
          <ContentMessage
            icon={<Icons.CheckCircle size={16} color={Green400} />}
            header="Import Complete"
            content="Use the editor to change color mapping and align the heightmap with the rest of the layers."
            messageType="normal"
          />
          <Button
            onClick={() => dispatch({ type: 'heightMapImport.edit' })}
            width="100%"
            size="medium"
            leadingIcon={<Icons.PencilOutlineEditor size={14} />}
            data-cy="focused-height-map-initial-edit-button"
            disabled={state.locked}
          >
            Editor
          </Button>
        </div>
      );
      break;
    case 'enabled':
      break;
  }

  const heightMapHasNeverBeenUploaded =
    state.heightMapImport.view === 'disabled' && !state.heightMap.enabled;
  const isReuploadVisible =
    !heightMapHasNeverBeenUploaded &&
    state.heightMapImport.view !== 'uploading-image';

  return (
    <Panel position="top-left">
      <PanelBody>{body}</PanelBody>

      <PanelActions
        left={
          <HorizontalForm size="small">
            {state.heightMap.enabled ? (
              <Button
                type="cleared"
                size="medium"
                onClick={() => {
                  Dialogger.confirm({
                    title: 'Height Map',
                    prompt:
                      'Are you sure you would like to delete this height map?',
                  }).then((ok) => {
                    if (!ok) {
                      return;
                    }
                    onDelete();
                  });
                }}
                trailingIcon={<Icons.Trash size={18} />}
                danger
                disabled={state.locked}
                data-cy="focused-height-map-delete-button"
              />
            ) : null}
            {isReuploadVisible ? (
              <Tooltip
                contents="Re-upload Height Map"
                placement="bottom-start"
                enterDelay={0}
                target={
                  <Button
                    type="cleared"
                    size="medium"
                    onClick={onImportHeightMapClick}
                    trailingIcon={<Icons.CloudUpload size={18} />}
                    data-cy="focused-height-map-reupload-button"
                    disabled={state.locked}
                  />
                }
              />
            ) : null}
          </HorizontalForm>
        }
        right={
          <div>
            <Button
              type="cleared"
              size="medium"
              onClick={() => dispatch({ type: 'layers.dismiss' })}
              data-cy="focused-height-map-dismiss"
            >
              Done
            </Button>
          </div>
        }
      />
    </Panel>
  );
};

export default FocusedHeightmapLayerPanel;
