import { v4 as uuidv4 } from 'uuid';
import {
  isFloorplanPhotoGroupId,
  FloorplanV2PhotoGroupWrite,
  FloorplanV2PhotoGroup,
  isFloorplanPhotoGroupPhotoId,
  Unsaved,
} from 'lib/api';
import { FloorplanCoordinates } from 'lib/geometry';
import FloorplanCollection from 'lib/floorplan-collection';
import { FixMe } from 'types/fixme';

type PhotoGroup = {
  id: string;
  name: string;
  notes: string;
  locked: boolean;
  position: FloorplanCoordinates;
  photos: Array<PhotoGroupPhoto>;

  // Used for internal bookkeeping
  // NOTE: get rid of these once the floorplan api v2 code is fully rolled out!
  operationToPerform: null | 'create' | 'update';
  photoIdsToDelete: Array<PhotoGroupPhoto['id']>;
};
const PhotoGroup = {
  create(name: PhotoGroup['name'], position: FloorplanCoordinates): PhotoGroup {
    const id = uuidv4();
    return {
      id,
      position,
      name,
      locked: false,
      notes: '',
      photos: [],

      // Used for internal bookkeeping
      // NOTE: get rid of these once the floorplan api v2 code is fully rolled out!
      operationToPerform: 'create',
      photoIdsToDelete: [],
    };
  },
  generateName(existingPhotoGroups: FloorplanCollection<PhotoGroup>): string {
    // Looks for the highest photo group named "PGX" and returns PG(X+1)
    let maxNumFound = 0;
    for (let photoGroup of FloorplanCollection.list(existingPhotoGroups)) {
      let result = photoGroup.name.match(/^Group (\d+)$/);
      if (result && result.length === 2) {
        // the second result should be the capture group which is the number
        const spaceNum = parseInt(result[1], 10);
        if (spaceNum > maxNumFound) {
          maxNumFound = spaceNum;
        }
      }
    }
    return `Group ${maxNumFound + 1}`;
  },
  computeOperationAfterModification(
    existingOperation: PhotoGroup['operationToPerform']
  ): PhotoGroup['operationToPerform'] {
    if (existingOperation === 'create') {
      return 'create';
    } else {
      return 'update';
    }
  },
  resetOperationAfterSave(photoGroup: PhotoGroup): PhotoGroup {
    return {
      ...photoGroup,
      operationToPerform: null,
      photos: photoGroup.photos.map((photo) =>
        PhotoGroupPhoto.resetOperationAfterSave(photo)
      ),
    };
  },

  appendPhoto(photoGroup: PhotoGroup, photo: PhotoGroupPhoto): PhotoGroup {
    return {
      ...photoGroup,
      photos: [...photoGroup.photos, photo],
      operationToPerform: PhotoGroup.computeOperationAfterModification(
        photoGroup.operationToPerform
      ),
    };
  },
  removePhoto(
    photoGroup: PhotoGroup,
    photoId: PhotoGroupPhoto['id']
  ): PhotoGroup {
    let photoIdsToDelete = photoGroup.photoIdsToDelete;
    if (isFloorplanPhotoGroupPhotoId(photoId)) {
      // Before marking the photo for deletion, make sure that the photo has indeed been uploaded to
      // the server first.
      const photo = photoGroup.photos.find((photo) => photo.id === photoId);
      if (!photo) {
        return photoGroup;
      }
      const photoLinkedToPhotoGroupOnServer = !photo.image.dirty;
      if (photoLinkedToPhotoGroupOnServer) {
        photoIdsToDelete = [...photoIdsToDelete, photoId];
      }
    }

    return {
      ...photoGroup,
      photoIdsToDelete,
      photos: photoGroup.photos.filter((photo) => photo.id !== photoId),
    };
  },
  getPhotoById(
    photoGroup: PhotoGroup,
    photoId: PhotoGroupPhoto['id']
  ): PhotoGroupPhoto | null {
    const photo = photoGroup.photos.find((photo) => photo.id === photoId);
    return photo || null;
  },

  lock(photoGroup: PhotoGroup): PhotoGroup {
    return {
      ...photoGroup,
      locked: true,
      operationToPerform: PhotoGroup.computeOperationAfterModification(
        photoGroup.operationToPerform
      ),
    };
  },
  unlock(photoGroup: PhotoGroup): PhotoGroup {
    return {
      ...photoGroup,
      locked: false,
      operationToPerform: PhotoGroup.computeOperationAfterModification(
        photoGroup.operationToPerform
      ),
    };
  },
  changeName(photoGroup: PhotoGroup, name: string): PhotoGroup {
    return {
      ...photoGroup,
      name,
      operationToPerform: PhotoGroup.computeOperationAfterModification(
        photoGroup.operationToPerform
      ),
    };
  },
  changeNotes(photoGroup: PhotoGroup, notes: string): PhotoGroup {
    return {
      ...photoGroup,
      notes,
      operationToPerform: PhotoGroup.computeOperationAfterModification(
        photoGroup.operationToPerform
      ),
    };
  },

  toFloorplanPhotoGroupWrite(
    photoGroup: PhotoGroup
  ): FloorplanV2PhotoGroupWrite | Unsaved<FloorplanV2PhotoGroupWrite> {
    const result: FixMe = {
      name: photoGroup.name,
      notes: photoGroup.notes,
      photo_ids: photoGroup.photos.map((photo) => photo.id),
      locked: photoGroup.locked,
      origin_x_pixels: photoGroup.position.x,
      origin_y_pixels: photoGroup.position.y,
    };
    if (isFloorplanPhotoGroupId(photoGroup.id)) {
      result.id = photoGroup.id;
    }
    return result;
  },

  createFromFloorplanPhotoGroup(
    serializedPhotoGroup: FloorplanV2PhotoGroup
  ): PhotoGroup {
    const photoGroup = PhotoGroup.create(
      serializedPhotoGroup.name,
      FloorplanCoordinates.create(
        serializedPhotoGroup.origin_x_pixels,
        serializedPhotoGroup.origin_y_pixels
      )
    );
    photoGroup.id = serializedPhotoGroup.id;
    photoGroup.locked = serializedPhotoGroup.locked;
    photoGroup.notes = serializedPhotoGroup.notes;
    photoGroup.operationToPerform = null;

    for (const serializedPhotoGroupPhoto of serializedPhotoGroup.photos) {
      const photo = PhotoGroupPhoto.createFromImageUrl(
        serializedPhotoGroupPhoto.id,
        serializedPhotoGroupPhoto.name,
        serializedPhotoGroupPhoto.url
      );
      photoGroup.photos.push(photo);
    }

    return photoGroup;
  },
};

export default PhotoGroup;

export type PhotoGroupPhoto = {
  id: string;
  name: string;
  image: { dirty: true; dataUrl: string } | { dirty: false; url: string };
  // NOTE: photos are created initially when uploading the image to the server, and only ever need
  // to be "updated" when their name changes.
  operationToPerform: null | 'update';
};
export const PhotoGroupPhoto = {
  createFromUploadedPhotoId(
    id: string,
    name: string,
    photoDataUrl: string
  ): PhotoGroupPhoto {
    return {
      id,
      name,
      image: { dirty: true, dataUrl: photoDataUrl },
      operationToPerform: 'update',
    };
  },
  createFromImageUrl(id: string, name: string, url: string): PhotoGroupPhoto {
    return {
      id,
      name,
      image: { dirty: false, url },
      operationToPerform: null,
    };
  },
  updateName(photo: PhotoGroupPhoto, name: string): PhotoGroupPhoto {
    return { ...photo, name, operationToPerform: 'update' };
  },
  resetOperationAfterSave(photo: PhotoGroupPhoto): PhotoGroupPhoto {
    return { ...photo, operationToPerform: null };
  },
};
