import classnames from 'classnames';
import styles from './styles.module.scss';

const TabBar: React.FunctionComponent<{
  value: string;
  choices: Array<{ id: string; label: React.ReactNode }>;
  onChange: (choiceId: string) => void;
  'data-cy'?: string;
}> = ({ value, choices, onChange, 'data-cy': dataCy }) => (
  <div className={styles.tabBar} data-cy={dataCy}>
    {choices.map((choice) => (
      <button
        className={classnames(styles.tab, {
          [styles.active]: value === choice.id,
        })}
        onClick={() => onChange(choice.id)}
        key={choice.id}
        data-cy={`${dataCy}-${choice.id}`}
      >
        {choice.label}
      </button>
    ))}
  </div>
);

export default TabBar;
