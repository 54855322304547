import * as React from 'react';

const RestroomMen = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6394 9C13.5724 9 15.1394 7.433 15.1394 5.5C15.1394 3.567 13.5724 2 11.6394 2C9.70639 2 8.13939 3.567 8.13939 5.5C8.13939 7.433 9.70639 9 11.6394 9ZM11.6394 7C10.811 7 10.1394 6.32843 10.1394 5.5C10.1394 4.67157 10.811 4 11.6394 4C12.4678 4 13.1394 4.67157 13.1394 5.5C13.1394 6.32843 12.4678 7 11.6394 7Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.67151 10.0272C8.78018 10.0091 8.89015 10 9.00031 10H14.2785C15.383 10 16.2785 10.8954 16.2785 12C16.2785 12.1102 16.2694 12.2201 16.2513 12.3288L14.6394 22H8.63939L7.02752 12.3288C6.84593 11.2393 7.58197 10.2088 8.67151 10.0272ZM12.9451 20L14.2785 12L9.00031 12L10.3336 20H12.9451Z"
      fill={color}
    />
  </svg>
);

export default RestroomMen;
