// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-percentageBarContainer-SI_cxxf7{font-family:\"Aeonik\",\"Helvetica\",\"Arial\",sans-serif;display:flex;flex-direction:row;width:100%;color:#0D183A;line-height:43px}.dui-styles-module-percentageBar-2-18N-Bi{display:none;background-color:#E0E3E6;width:100%;height:4px;flex:1;margin-top:19.5px}@media (min-width: 767px){.dui-styles-module-percentageBar-2-18N-Bi{display:inline-block}}.dui-styles-module-percentageBarColoredSection-3nouw8uC{width:0%;background-color:#0D183A;height:4px;transition:all 250ms ease-in-out}.dui-styles-module-percentageBarText-3PwmqMxc{width:32px;margin-right:8px;font-weight:bold;font-size:14px}@media (min-width: 767px){.dui-styles-module-percentageBarText-3PwmqMxc{text-align:right;width:48px}}.dui-styles-module-percentageBarTextDisabled-2q3NuxPp{color:#C0C6CC;font-size:18px}.dui-styles-module-contextListView-1HvG4sVW.dui-styles-module-percentageBar-2-18N-Bi{height:9px;border-radius:5px;margin-top:17.5px}.dui-styles-module-contextListView-1HvG4sVW.dui-styles-module-percentageBarColoredSection-3nouw8uC{background-color:#1A79FF;height:9px;border-radius:5px}.dui-styles-module-contextListView-1HvG4sVW.dui-styles-module-percentageBarColoredSection-3nouw8uC.dui-styles-module-isSubOnePercent-OYQiy9_A{border-top-left-radius:9px;border-bottom-left-radius:9px}.dui-styles-module-contextListView-1HvG4sVW.dui-styles-module-percentageBarText-3PwmqMxc{color:#4E5966;font-weight:500;font-size:14px}\n", ""]);
// Exports
exports.locals = {
	"percentageBarContainer": "dui-styles-module-percentageBarContainer-SI_cxxf7",
	"percentageBar": "dui-styles-module-percentageBar-2-18N-Bi",
	"percentageBarColoredSection": "dui-styles-module-percentageBarColoredSection-3nouw8uC",
	"percentageBarText": "dui-styles-module-percentageBarText-3PwmqMxc",
	"percentageBarTextDisabled": "dui-styles-module-percentageBarTextDisabled-2q3NuxPp",
	"contextListView": "dui-styles-module-contextListView-1HvG4sVW",
	"isSubOnePercent": "dui-styles-module-isSubOnePercent-OYQiy9_A"
};
module.exports = exports;
