import moment from 'moment';
import { Meters } from 'lib/units';
import Floorplan from 'lib/floorplan';

// The size of each sample in the heatmap in the x and y dimension
export const BUCKET_SIZE_METERS = Meters.fromInches(6);

export const SMALLEST_MAX_MILLISECONDS_VALUE = 350 * 1000; /* milliseconds */

export type Heatmap = {
  startDate: string | null;
  endDate: string | null;
  opacity: number;
  data:
    | { status: 'pending' }
    | {
        status: 'loading';
        page: number;
        lastBucketTimestamp: string | null;
      }
    | { status: 'computing-heatmap' }
    | {
        status: 'complete';
        globalHeatmap: Uint32Array;
        globalMax: number;
        limits: { min: number; max: number };
      }
    | { status: 'error' };
};

export const Heatmap = {
  create(): Heatmap {
    const startDate = moment().subtract(1, 'day').startOf('day').format();
    const endDate = moment().endOf('day').format();

    return {
      startDate,
      endDate,
      opacity: 100,
      data: { status: 'pending' as const },
    };
  },
  changeStartDate(heatmap: Heatmap, startDate: string | null): Heatmap {
    return {
      ...heatmap,
      startDate,
      data:
        startDate && heatmap.endDate && startDate !== heatmap.startDate
          ? { status: 'pending' as const }
          : heatmap.data,
    };
  },
  changeEndDate(heatmap: Heatmap, endDate: string | null): Heatmap {
    return {
      ...heatmap,
      endDate,
      data:
        heatmap.startDate && endDate && endDate !== heatmap.endDate
          ? { status: 'pending' as const }
          : heatmap.data,
    };
  },
  changeOpacity(heatmap: Heatmap, opacity: number): Heatmap {
    return { ...heatmap, opacity };
  },

  // Given an array of raw heatmap buckets from the heatmap endpoint, return a large memory
  // buffers containing the heatmap data aggregated together.
  //
  // Return a global heatmap buffer of all bucket data combined together. This is what is
  // rendered by default. In addition, return the max value of this buffer so that the buffer can
  // be rendered assuming a continuous scale from 0 to the max value.
  computeHeatmapBuffer(
    buckets: Array<{
      bucket: string;
      tile_data: Array<[number, number, number]>;
    }>,
    floorplan: Floorplan
  ): [Uint32Array, number, [number, number]] {
    const planWidthInBuckets = Math.ceil(
      floorplan.width / floorplan.scale / BUCKET_SIZE_METERS
    );
    const planHeightInBuckets = Math.ceil(
      floorplan.height / floorplan.scale / BUCKET_SIZE_METERS
    );

    const globalHeatmapBuffer = new Uint32Array(
      planWidthInBuckets * planHeightInBuckets
    );
    let individualMax = -Infinity;
    for (const bucket of buckets) {
      for (const [x, y, millseconds] of bucket.tile_data) {
        globalHeatmapBuffer[y * planWidthInBuckets + x] += millseconds;
        individualMax = Math.max(individualMax, millseconds);
      }
    }
    let globalMax = globalHeatmapBuffer.reduce((a, b) => Math.max(a, b), 0);

    return [
      globalHeatmapBuffer,
      globalMax,
      [0, Math.max(individualMax, SMALLEST_MAX_MILLISECONDS_VALUE)],
    ];
  },
};
