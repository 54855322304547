import * as React from 'react';

const PhoneBooth = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      d="M4.92893 9.17156C4.14788 8.39051 4.14788 7.12419 4.92893 6.34314L5.98959 5.28248L10.2322 9.52512L9.17157 10.5858L13.4142 14.8284L14.4749 13.7678L18.7175 18.0104L17.6568 19.0711C16.8758 19.8521 15.6095 19.8521 14.8284 19.0711L4.92893 9.17156Z"
      fill={color}
    />
    <path
      d="M6.34314 4.92892L10.5858 9.17156L12 7.75735L7.75735 3.51471L6.34314 4.92892Z"
      fill={color}
    />
    <path
      d="M14.8284 13.4142L19.0711 17.6568L20.4853 16.2426L16.2426 12L14.8284 13.4142Z"
      fill={color}
    />
  </svg>
);

export default PhoneBooth;
