import * as React from 'react';
import {
  useTreatments as useSplitTreatments,
  SplitFactory,
} from '@splitsoftware/splitio-react';
import { createLocalStorageStateHook } from 'use-local-storage-state';

import { SPLIT_KEY, NODE_ENV } from '../config';

import { LOCAL_FEATURES, SPLITS } from 'lib/treatments';
import { FF_OVERRIDES } from 'lib/local-storage';
import { useAppSelector } from 'redux/store';

const UNKNOWN_IDENTIFIER = 'unknown';
const DEFAULT_ON_VALUE = 'on';

export const useFFOverrides = createLocalStorageStateHook<{
  [key: string]: 'on' | 'off';
}>(FF_OVERRIDES, {});

type TreatmentsProviderProps = {
  splitKey?: string;
  // split has a narrower definition of children
  children: JSX.Element;
};

export const TreatmentsProvider: React.FC<TreatmentsProviderProps> = (
  props
) => {
  return (
    <SplitFactory
      config={{
        core: {
          authorizationKey: SPLIT_KEY,
          key: props.splitKey || UNKNOWN_IDENTIFIER,
        },
        features: NODE_ENV === 'development' ? LOCAL_FEATURES : undefined,
      }}
    >
      {props.children}
    </SplitFactory>
  );
};

const useOverrideTreatment = (feature?: string) => {
  const [fFOverrides] = useFFOverrides();

  const overrideValue = feature ? fFOverrides[feature] : undefined;

  return overrideValue;
};

export const useTreatment = (featureName: string) => {
  const user = useAppSelector((state) => state.user);
  const tokenCheckOrganizationId = useAppSelector(
    (state) => state.auth.tokenCheckResponse?.organization?.id
  );
  const tokenCheckUserEmail = useAppSelector(
    (state) => state.auth.tokenCheckResponse?.user?.email
  );

  const organizationId = user?.data?.organization.id;

  const treatments = React.useMemo(() => {
    if (featureName) {
      return { [featureName]: DEFAULT_ON_VALUE };
    }

    return {};
  }, [featureName]);

  const features = React.useMemo(() => {
    return Object.keys(treatments);
  }, [treatments]);

  const treatmentResults = useSplitTreatments(features, {
    organizationId: organizationId || UNKNOWN_IDENTIFIER,
    originalOrganizationId: tokenCheckOrganizationId || UNKNOWN_IDENTIFIER,
    tokenCheckUserEmail: tokenCheckUserEmail || UNKNOWN_IDENTIFIER,
  });

  const ffOverrideTreatmentResults = useSplitTreatments([SPLITS.FF_OVERRIDES], {
    organizationId: organizationId || UNKNOWN_IDENTIFIER,
    originalOrganizationId: tokenCheckOrganizationId || UNKNOWN_IDENTIFIER,
    tokenCheckUserEmail: tokenCheckUserEmail || UNKNOWN_IDENTIFIER,
  });

  const isFFOverrideOn =
    ffOverrideTreatmentResults[SPLITS.FF_OVERRIDES].treatment ===
    DEFAULT_ON_VALUE;

  const overrideValue = useOverrideTreatment(featureName);
  if (isFFOverrideOn && overrideValue) {
    return overrideValue === DEFAULT_ON_VALUE;
  }

  return features.every((f) => treatmentResults[f].treatment === treatments[f]);
};

// only used by TreatmentsOverrides
export const useTreatments = (featureNames: string[]) => {
  const user = useAppSelector((state) => state.user);
  const tokenCheckOrganizationId = useAppSelector(
    (state) => state.auth.tokenCheckResponse?.organization?.id
  );
  const tokenCheckEmail = useAppSelector(
    (state) => state.auth.tokenCheckResponse?.user?.email
  );

  const organizationId = user?.data?.organization.id;

  const treatments = React.useMemo(() => {
    return featureNames.reduce((acc, curr) => {
      acc[curr] = DEFAULT_ON_VALUE;

      return acc;
    }, {} as { [key: string]: 'on' | 'off' });
  }, [featureNames]);

  const features = React.useMemo(() => {
    return Object.keys(treatments);
  }, [treatments]);

  const treatmentParams = {
    organizationId: organizationId || UNKNOWN_IDENTIFIER,
    originalOrganizationId: tokenCheckOrganizationId || UNKNOWN_IDENTIFIER,
    email: tokenCheckEmail || UNKNOWN_IDENTIFIER,
  };

  const treatmentResults = useSplitTreatments(features, treatmentParams);

  return features.reduce((acc, curr) => {
    acc[curr] = treatmentResults[curr].treatment as 'on' | 'off';

    return acc;
  }, {} as { [key: string]: 'on' | 'off' });
};
