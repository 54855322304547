import { useEffect, useMemo, useCallback, useRef, Fragment } from 'react';
import * as PIXI from 'pixi.js';
import * as d3 from 'd3';
import { FloorplanCoordinates } from 'lib/geometry';
import { Seconds } from 'lib/units';
import FloorplanCollection from 'lib/floorplan-collection';
import PlanSensor, { SensorConnection } from 'lib/sensor';
import { AggregatedData, MappedPoint } from 'components/editor/state';
import { FloorplanTargetInfo } from 'components/track-visualizer';
import SensorDataStreamOALive from 'components/sensor-data-stream-oa-live';
import { FloorplanV2Plan } from 'lib/api';
import { Meters } from 'lib/units';

import {
  Layer,
  useFloorplanLayerContext,
  toRawHex,
} from 'components/floorplan';

import { OALiveSocketMessage } from 'lib/oa-live-socket';

import { Blue400, Blue700 } from '@density/dust/dist/tokens/dust.tokens';

// NOTE: Change these to affect square spacing and corner radius
// --------------------------------------------------------------
// This is the space between squares as a percentage of grid step
const AGGREGATED_POINTS_LAYER_SQUARE_SPACING = 0.25;
// This is corner radius as a percentage of resulting square size
const AGGREGATED_POINTS_LAYER_SQUARE_CORNER_RADIUS = 1 / 3;

const SensorLiveDataHandler: React.FunctionComponent<{
  sensor: PlanSensor;
  sensorConnections: Map<PlanSensor['id'], SensorConnection>;
  planId: FloorplanV2Plan['id'];

  onConnectionOpen: (sensor: PlanSensor) => void;
  onConnectionClose: (sensor: PlanSensor) => void;
  onConnectionError: (sensor: PlanSensor) => void;
  onMessage: (sensor: PlanSensor, message: string) => void;
}> = ({
  sensor,
  sensorConnections,
  planId,
  onConnectionOpen,
  onConnectionClose,
  onConnectionError,
  onMessage,
}) => {
  const onOpenHandler = useCallback(
    () => onConnectionOpen(sensor),
    [onConnectionOpen, sensor]
  );
  const onCloseHandler = useCallback(
    () => onConnectionClose(sensor),
    [onConnectionClose, sensor]
  );
  const onErrorHandler = useCallback(
    () => onConnectionError(sensor),
    [onConnectionError, sensor]
  );
  const onMessageHandler = useCallback(
    (message: string) => onMessage(sensor, message),
    [onMessage, sensor]
  );

  const connection = sensorConnections.get(sensor.id) || null;
  if (!connection) {
    return null;
  }
  if (connection.status === 'disconnected') {
    return null;
  }

  return (
    <SensorDataStreamOALive
      key={sensor.id}
      planId={planId}
      sensorSerial={connection.serialNumber}
      onOpen={onOpenHandler}
      onClose={onCloseHandler}
      onError={onErrorHandler}
      onMessage={onMessageHandler}
    />
  );
};

// The aggregated points layer renders little squares indicating where points have been received by
// an oa sensor when it is "streaming" mode and data from the sensor is being sent to the frontend
// app over a websocket.
const AggregatedPointsLayer: React.FunctionComponent<{
  gridSize?: number;
  colorScaleDomain?: [number, number];
  snrThreshold?: number;

  planId: FloorplanV2Plan['id'];
  sensors: FloorplanCollection<PlanSensor>;
  sensorConnections: Map<PlanSensor['id'], SensorConnection>;
  onChangeSensorConnection: (
    sensorId: PlanSensor['id'],
    sensorConnections: SensorConnection
  ) => void;
}> = ({
  gridSize = Meters.fromInches(6),
  colorScaleDomain = [3, 8],
  snrThreshold = 0,
  planId,
  sensors,
  sensorConnections,
  onChangeSensorConnection,
}) => {
  const context = useFloorplanLayerContext();

  const aggregatedPointsData = useRef<AggregatedData>([]);
  const aggregatedTracksData = useRef<Array<FloorplanTargetInfo>>([]);

  const onConnectionOpen = useCallback((sensor: PlanSensor) => {
    if (!sensor.serialNumber) {
      throw new Error(
        `Cannot open a sensor conenction for a sensor that does not have a serial number attached (${sensor.id})`
      );
    }

    onChangeSensorConnection(sensor.id, {
      serialNumber: sensor.serialNumber,
      status: 'connected',
    });

    aggregatedPointsData.current = [];
    aggregatedTracksData.current = [];
    // FIXME: I'm unable to wrap `onChangeSensorConnection` at a higher level in `useCallback`, so if
    // I add that as a dependency, it causes the component to constantly rerender. Fix this once the
    // `Editor` component is no longer a class component.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConnectionClose = useCallback((sensor: PlanSensor) => {
    if (!sensor.serialNumber) {
      throw new Error(
        `Cannot close a sensor conenction for a sensor that does not have a serial number attached (${sensor.id})`
      );
    }

    onChangeSensorConnection(sensor.id, {
      serialNumber: sensor.serialNumber,
      status: 'disconnected',
    });

    aggregatedPointsData.current = [];
    aggregatedTracksData.current = [];
    // FIXME: I'm unable to wrap `onChangeSensorConnection` at a higher level in `useCallback`, so if
    // I add that as a dependency, it causes the component to constantly rerender. Fix this once the
    // `Editor` component is no longer a class component.
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onConnectionError = onConnectionClose;

  const onMessage = useCallback((sensor: PlanSensor, message: string) => {
    const sensorSerial = sensor.serialNumber;
    if (!sensorSerial) {
      throw new Error(`Missing serial number for sensor: ${sensor.id}`);
    }

    const sensorId = sensor.id;
    const timestamp = Seconds.fromMilliseconds(Date.now());

    const payload: OALiveSocketMessage = JSON.parse(message);

    const mappedPoints: Array<MappedPoint> = [];
    const mappedTracks: Array<FloorplanTargetInfo> = [];

    if (payload.message_type === 'targets') {
      payload.targets.forEach((target) => {
        mappedTracks.push({
          timestamp,
          position: FloorplanCoordinates.create(target.x, target.y),
          sensorSerial,
        });
      });
    } else if (payload.message_type === 'points') {
      payload.points.forEach((point) => {
        mappedPoints.push({
          isSimulated: false,
          sensorId,
          timestamp,
          floorplanPosition: FloorplanCoordinates.create(point.x, point.y),
        });
      });
    }

    aggregatedPointsData.current =
      aggregatedPointsData.current.concat(mappedPoints);
    aggregatedTracksData.current =
      aggregatedTracksData.current.concat(mappedTracks);
  }, []);

  const colorScale = useMemo(() => {
    return d3
      .scaleSequential(d3.interpolateRgb(Blue400, Blue700))
      .clamp(true)
      .domain(colorScaleDomain);
  }, [colorScaleDomain]);

  // Create a graphics element that will be drawn to in order to display aggregated points
  useEffect(() => {
    const aggregatedPointsLayer = new PIXI.Graphics();
    aggregatedPointsLayer.name = 'aggregated-points-layer';
    aggregatedPointsLayer.x = 0;
    aggregatedPointsLayer.y = 0;

    const intervalId = setInterval(() => {
      if (aggregatedPointsData.current.length === 0) {
        return;
      }
      aggregatedPointsData.current = AggregatedData.releaseExpiredData(
        aggregatedPointsData.current
      );
    }, 100);

    context.app.stage.addChild(aggregatedPointsLayer);
    return () => {
      context.app.stage.removeChild(aggregatedPointsLayer);
      clearInterval(intervalId);
    };
  }, [context.app.stage]);

  return (
    <Fragment>
      {Array.from(sensors.items.values()).map((sensor) => (
        <SensorLiveDataHandler
          sensor={sensor}
          sensorConnections={sensorConnections}
          planId={planId}
          onConnectionOpen={onConnectionOpen}
          onConnectionClose={onConnectionClose}
          onConnectionError={onConnectionError}
          onMessage={onMessage}
        />
      ))}
      <Layer
        onAnimationFrame={() => {
          if (!context.viewport.current) {
            return;
          }
          const viewport = context.viewport.current;

          const aggregatedPointsLayer = context.app.stage.getChildByName(
            'aggregated-points-layer'
          ) as PIXI.Graphics;

          const scale = context.floorplan.scale * viewport.zoom;

          // Compute aggregated points buckets based off of raw point data
          const aggregatedPoints = new Map<number, Map<number, number>>();
          const now = Seconds.fromMilliseconds(Date.now());
          const data = aggregatedPointsData.current.filter((point) => {
            const isRecent = point.timestamp > now - 0.333;
            const isValid = point.isSimulated
              ? true
              : (point.sensorPoint?.snr || 0) >= snrThreshold;
            return isRecent && isValid;
          });
          for (const point of data) {
            const gridCoords = FloorplanCoordinates.toGridCoordinates(
              point.floorplanPosition,
              gridSize
            );
            let row = aggregatedPoints.get(gridCoords.y);
            if (typeof row === 'undefined') {
              row = new Map();
              aggregatedPoints.set(gridCoords.y, row);
            }
            const count = row.get(gridCoords.x);
            if (typeof count === 'undefined') {
              row.set(gridCoords.x, 1);
            } else {
              row.set(gridCoords.x, count + 1);
            }
          }

          // --------------------------------------------------------------
          // Don't try to tweak these
          const gridStepPixels = scale * gridSize;
          const squareComputedCornerOffset =
            (gridStepPixels * AGGREGATED_POINTS_LAYER_SQUARE_SPACING) / 2;
          const squareComputedSize =
            gridStepPixels - 2 * squareComputedCornerOffset;
          const squareComputedRadius =
            squareComputedSize * AGGREGATED_POINTS_LAYER_SQUARE_CORNER_RADIUS;

          aggregatedPointsLayer.clear();

          // Aggregated points display
          aggregatedPoints.forEach((row, y) => {
            row.forEach((count, x) => {
              // Make sure value is within the domain
              if (count < colorScaleDomain[0] || count > colorScaleDomain[1]) {
                return;
              }

              const pos = FloorplanCoordinates.toViewportCoordinates(
                FloorplanCoordinates.create(x * gridSize, y * gridSize),
                context.floorplan,
                viewport
              );

              const color = d3.rgb(colorScale(count) || Blue400).formatHex();
              aggregatedPointsLayer.beginFill(toRawHex(color));

              aggregatedPointsLayer.drawRoundedRect(
                pos.x + squareComputedCornerOffset,
                pos.y + squareComputedCornerOffset,
                squareComputedSize,
                squareComputedSize,
                squareComputedRadius
              );
            });
          });
        }}
      />
    </Fragment>
  );
};

export default AggregatedPointsLayer;
