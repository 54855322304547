import { useState, useEffect } from 'react';

import TextField from 'components/text-field';

import { round } from 'lib/math';

const PercentField: React.FunctionComponent<{
  value: number;
  disabled?: boolean;
  onChange?: (value: number) => void;
  'data-cy'?: string;
  maxValue?: number;
  minValue?: number;
}> = ({
  value,
  onChange,
  disabled,
  'data-cy': dataCy,
  maxValue = 100,
  minValue = 0,
}) => {
  const [opacityText, setOpacityText] = useState('');
  useEffect(() => {
    setOpacityText(`${round(value, 2)}`);
  }, [value]);
  const [opacityInvalid, setOpacityInvalid] = useState(false);

  return (
    <TextField
      type="number"
      value={opacityText}
      error={opacityInvalid}
      onChange={(e) => {
        setOpacityText(e.currentTarget.value);

        let opacity = parseFloat(e.currentTarget.value);
        if (isNaN(opacity)) {
          setOpacityInvalid(true);
          return;
        }
        setOpacityInvalid(false);
      }}
      trailingSuffix="%"
      width={85}
      size="medium"
      onBlur={() => {
        let opacity = parseFloat(opacityText);
        if (isNaN(opacity)) {
          setOpacityInvalid(true);
          return;
        }
        if (opacity > maxValue) {
          opacity = maxValue;
        }
        if (opacity < minValue) {
          opacity = minValue;
        }
        setOpacityInvalid(false);
        if (onChange) {
          onChange(opacity);
        }
      }}
      disabled={disabled}
      data-cy={dataCy}
    />
  );
};

export default PercentField;
