// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes dui-styles-module-dashboardModalBackdropAnimateIn-3onMX5q1{from{opacity:0}to{opacity:1}}@keyframes dui-styles-module-dashboardModalBackdropAnimateOut-_nlSQqCT{from{opacity:1}to{opacity:0}}@keyframes dui-styles-module-dashboardModalDialogAnimateIn-1Pf2CLTz{from{transform:translate(0, 20px) scale(0.98)}to{transform:translate(0, 0) scale(1)}}@keyframes dui-styles-module-dashboardModalDialogAnimateOut-weKSXzw1{from{transform:translate(0, 0) scale(1)}to{transform:translate(0, 20px) scale(0.98)}}.dui-styles-module-dashboardModalBackdrop-2nHxsGPC{position:fixed;top:0px;left:0px;bottom:0px;right:0px;display:flex;align-items:center;padding-top:8px;padding-left:0px;padding-right:0px;padding-bottom:16px;opacity:0;pointer-events:none;animation:dui-styles-module-dashboardModalBackdropAnimateOut-_nlSQqCT 0.1s ease-out;background-color:rgba(255,255,255,0.95);overflow-y:auto;-webkit-overflow-scrolling:touch;z-index:999}.dui-styles-module-dashboardModalBackdrop-2nHxsGPC.dui-styles-module-visible-3Z4d168X{pointer-events:auto;opacity:1;animation:dui-styles-module-dashboardModalBackdropAnimateIn-3onMX5q1 0.1s ease-in}.dui-styles-module-dashboardModalBackdrop-2nHxsGPC>.dui-styles-module-dashboardModalDialog-12YY1rhx{animation:dui-styles-module-dashboardModalDialogAnimateOut-weKSXzw1 0.1s ease-out}.dui-styles-module-dashboardModalBackdrop-2nHxsGPC.dui-styles-module-visible-3Z4d168X>.dui-styles-module-dashboardModalDialog-12YY1rhx{animation:dui-styles-module-dashboardModalDialogAnimateIn-1Pf2CLTz 0.1s ease-in}.dui-styles-module-dashboardModalDialog-12YY1rhx{background-color:#fff;border:1px solid #C0C6CC;margin:auto;width:auto;height:auto;border-radius:4px;box-shadow:8px 8px 0px #F3F3F5}.dui-styles-module-dashboardModalDialog-12YY1rhx:focus{outline:none}\n", ""]);
// Exports
exports.locals = {
	"dashboardModalBackdrop": "dui-styles-module-dashboardModalBackdrop-2nHxsGPC",
	"dashboardModalBackdropAnimateOut": "dui-styles-module-dashboardModalBackdropAnimateOut-_nlSQqCT",
	"visible": "dui-styles-module-visible-3Z4d168X",
	"dashboardModalBackdropAnimateIn": "dui-styles-module-dashboardModalBackdropAnimateIn-3onMX5q1",
	"dashboardModalDialog": "dui-styles-module-dashboardModalDialog-12YY1rhx",
	"dashboardModalDialogAnimateOut": "dui-styles-module-dashboardModalDialogAnimateOut-weKSXzw1",
	"dashboardModalDialogAnimateIn": "dui-styles-module-dashboardModalDialogAnimateIn-1Pf2CLTz"
};
module.exports = exports;
