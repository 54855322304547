import * as React from 'react';

const Restroom = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1391 5.5C10.1391 7.433 8.57207 9 6.63907 9C4.70607 9 3.13907 7.433 3.13907 5.5C3.13907 3.567 4.70607 2 6.63907 2C8.57207 2 10.1391 3.567 10.1391 5.5ZM5.13907 5.5C5.13907 6.32843 5.81064 7 6.63907 7C7.4675 7 8.13907 6.32843 8.13907 5.5C8.13907 4.67157 7.4675 4 6.63907 4C5.81064 4 5.13907 4.67157 5.13907 5.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6395 9C19.5725 9 21.1395 7.433 21.1395 5.5C21.1395 3.567 19.5725 2 17.6395 2C15.7065 2 14.1395 3.567 14.1395 5.5C14.1395 7.433 15.7065 9 17.6395 9ZM17.6395 7C16.8111 7 16.1395 6.32843 16.1395 5.5C16.1395 4.67157 16.8111 4 17.6395 4C18.468 4 19.1395 4.67157 19.1395 5.5C19.1395 6.32843 18.468 7 17.6395 7Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6717 10.0272C14.7803 10.0091 14.8903 10 15.0005 10L20.2786 10C21.3832 10 22.2786 10.8954 22.2786 12C22.2786 12.1102 22.2695 12.2201 22.2514 12.3288L20.6395 22H14.6395L13.0277 12.3288C12.8461 11.2393 13.5821 10.2088 14.6717 10.0272ZM18.9453 20L20.2786 12L15.0005 12L16.3338 20H18.9453Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33332 10H7.94481C8.92249 10 9.75687 10.7068 9.9176 11.6712L11.6391 22H1.63907L3.36053 11.6712C3.52126 10.7068 4.35564 10 5.33332 10ZM9.27815 20L7.94481 12L5.33332 12L3.99999 20H9.27815Z"
      fill={color}
    />
  </svg>
);

export default Restroom;
