// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-infoPopupContainer-2i4lBSs3{outline:none;position:relative}.dui-styles-module-infoPopupIcon-1oCgGHQ4{cursor:pointer;margin-bottom:20px}.dui-styles-module-infoPopupIconVisible-1trAxC4W{padding-bottom:20px}.dui-styles-module-infoPopupStockTarget-1Ji8P-Du{vertical-align:top;vertical-align:-moz-middle-with-baseline;vertical-align:-webkit-baseline-middle;user-select:none;margin-left:5px;margin-right:5px}.dui-styles-module-infoPopupWrapper-gv06BNwW{position:absolute;top:0px;left:0px}.dui-styles-module-infoPopupPopup-3XJTR2ax{background-color:#fff;padding:20px;border:1px solid #C0C6CC;border-radius:4px;font-family:\"Aeonik\",\"Helvetica\",\"Arial\",sans-serif;font-size:16px;font-weight:normal;color:#0D183A;position:absolute;top:0px;left:0px;display:block;width:300px;box-shadow:0px 2px 8px #E6E7EB;transition:all 200ms ease-in-out;opacity:0;pointer-events:none;user-select:none;z-index:999}.dui-styles-module-infoPopupPopupVisible-27n2SLO9{pointer-events:auto;user-select:auto;opacity:1}.dui-styles-module-infoPopupPopupSingleLine-3o8XMQT1{padding-top:5px;padding-bottom:5px;white-space:nowrap;width:auto}.dui-styles-module-infoPopupCardWellHighlight-1IPmZvd9{border-bottom:1px dotted #0D183A}.dui-styles-module-card-well-dark-1ehOQ_gA .dui-styles-module-info-popup-card-well-highlight-2RbMKRxe{border-bottom:1px dotted #C0C6CC}\n", ""]);
// Exports
exports.locals = {
	"infoPopupContainer": "dui-styles-module-infoPopupContainer-2i4lBSs3",
	"infoPopupIcon": "dui-styles-module-infoPopupIcon-1oCgGHQ4",
	"infoPopupIconVisible": "dui-styles-module-infoPopupIconVisible-1trAxC4W",
	"infoPopupStockTarget": "dui-styles-module-infoPopupStockTarget-1Ji8P-Du",
	"infoPopupWrapper": "dui-styles-module-infoPopupWrapper-gv06BNwW",
	"infoPopupPopup": "dui-styles-module-infoPopupPopup-3XJTR2ax",
	"infoPopupPopupVisible": "dui-styles-module-infoPopupPopupVisible-27n2SLO9",
	"infoPopupPopupSingleLine": "dui-styles-module-infoPopupPopupSingleLine-3o8XMQT1",
	"infoPopupCardWellHighlight": "dui-styles-module-infoPopupCardWellHighlight-1IPmZvd9",
	"card-well-dark": "dui-styles-module-card-well-dark-1ehOQ_gA",
	"info-popup-card-well-highlight": "dui-styles-module-info-popup-card-well-highlight-2RbMKRxe"
};
module.exports = exports;
