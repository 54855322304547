import { ImageCoordinates } from 'lib/geometry';
import { FloorplanV2Plan } from './api';

type Floorplan = {
  width: number;
  height: number;
  origin: ImageCoordinates;
  scale: number;
  rotation: number;
};

const Floorplan = {
  fromFloorplanAPIResponse(plan: FloorplanV2Plan) {
    const floorplan: Floorplan = {
      width: plan.image_width_pixels,
      height: plan.image_height_pixels,
      scale: plan.image_pixels_per_meter,
      origin: ImageCoordinates.create(
        plan.origin_x_pixels,
        plan.origin_y_pixels
      ),
      rotation: plan.origin_angle_degrees || 0,
    };

    return floorplan;
  },
};

export default Floorplan;
