import React from "react";

const Heart = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M20,8.5 C20,6.56700338 18.4329966,5 16.5,5 C14.5670034,5 13,6.56700338 13,8.5 L11,8.5 C11,6.56700338 9.43299662,5 7.5,5 C5.56700338,5 4,6.56700338 4,8.5 C4,11.0178948 6.65549852,14.8012654 12.0196187,19.6733498 C17.1781364,15.238109 20,11.1346516 20,8.5 Z M16.5,3 C19.5375661,3 22,5.46243388 22,8.5 C22,12.0051031 18.6343912,16.773049 12.6401844,21.7682213 L11.9789535,22.319247 L11.3356362,21.7474093 C5.13175648,16.2328496 2,11.8831878 2,8.5 C2,5.46243388 4.46243388,3 7.5,3 C9.36015777,3 11.0046326,3.92344845 12,5.33692087 C12.9953674,3.92344845 14.6398422,3 16.5,3 Z"
      />
    </g>
  </svg>
);

export default Heart;
