import {
  Fragment,
  useRef,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import * as React from 'react';
import { Icons } from '@density/dust';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { toast } from 'react-toastify';
import * as PIXI from 'pixi.js';
import styles from './styles.module.scss';
import classnames from 'classnames';
import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { ProcessedCADSensorPlacement, PlanDXF } from './state';

import Button from 'components/button';
import FormLabel from 'components/form-label';
import HorizontalForm from 'components/horizontal-form';
import FloorplanCoordinatesField from 'components/floorplan-coordinates-field';
import ScaleField from 'components/scale-field';
import SelectField from 'components/select-field';
import TabBar from 'components/tab-bar';
import Floorplan, {
  toRawHex,
  ObjectLayer,
  useFloorplanLayerContext,
  isWithinViewport,
  BigImage,
} from 'components/floorplan';
import FloorplanZoomControls from 'components/floorplan-zoom-controls';
import ImageRegistrationLayer from './floorplan-layers/image-registration-layer';
import Panel, { PanelHeaderWell } from 'components/panel';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import AppBar from 'components/app-bar';
import { DarkTheme } from 'components/theme';
import { ParseDXFOptions, EMPTY_LAYER_NAME } from 'lib/dxf';
import { round, degreesToRadians } from 'lib/math';
import { LengthUnit, displayLength } from 'lib/units';
import FloorplanType from 'lib/floorplan';
import {
  FloorplanCoordinates,
  CADCoordinates,
  ViewportCoordinates,
  ImageCoordinates,
} from 'lib/geometry';
import PlanSensor from 'lib/sensor';
import {
  FloorplanChange,
  computeDefaultCADOrigin,
  computeFloorplanChanges,
  CADImportOperationType,
  DEFAULT_CAD_FILE_LENGTH_UNIT,
} from 'lib/cad';
import { FloorplanV2Plan, FloorplanAPI } from 'lib/api';

import { useTreatment } from 'contexts/treatments';
import { SPLITS } from 'lib/treatments';
import { FixMe } from 'types/fixme';

import { ReactComponent as CADImportLoaderSvg } from 'img/cad-import-loader.svg';

// This floorplan layer shows the new base image from the cad file, so it can be overlayed on top of
// the existing floorplan image
const FloorplanCADNewBaseImageLayer: React.FunctionComponent<{
  image: HTMLImageElement;
  grayscale?: boolean;
  floorplanCADOrigin: FloorplanCoordinates;
  cadFileUnit: LengthUnit;
  cadFileScale: number;
  pixelsPerCADUnit: number;
  onDragMove: (position: FloorplanCoordinates) => void;
}> = ({
  image,
  grayscale = true,
  floorplanCADOrigin,
  cadFileUnit,
  cadFileScale,
  pixelsPerCADUnit,
  onDragMove,
}) => {
  const context = useFloorplanLayerContext();

  const coordA = CADCoordinates.toFloorplanCoordinates(
    CADCoordinates.create(0, 0),
    context.floorplan,
    floorplanCADOrigin,
    cadFileUnit,
    cadFileScale
  );
  const coordB = CADCoordinates.toFloorplanCoordinates(
    CADCoordinates.create(1, 0),
    context.floorplan,
    floorplanCADOrigin,
    cadFileUnit,
    cadFileScale
  );
  const metersPerCADUnit = coordB.x - coordA.x;

  // Round the scale to a high level of precision to get rid of floating point math uncertainty
  // If this isn't done, many of the useEffects / useCallbacks in ImageRegistrationLayer will
  // run on every render
  const cadImageScale = round(
    metersPerCADUnit * (1 / pixelsPerCADUnit) * context.floorplan.scale,
    8
  );

  // The ImageRegistrationLayer needs an upper left position, but the cad origin is in the lower
  // left.
  const upperLeftPosition = FloorplanCoordinates.create(
    floorplanCADOrigin.x,
    floorplanCADOrigin.y -
      (image.height * cadImageScale) / context.floorplan.scale
  );

  const onDragMoveCallback = useCallback(
    (upperLeftPosition) => {
      const lowerLeftPosition = FloorplanCoordinates.create(
        upperLeftPosition.x,
        upperLeftPosition.y +
          (image.height * cadImageScale) / context.floorplan.scale
      );
      onDragMove(lowerLeftPosition);
    },
    [image.height, cadImageScale, context.floorplan.scale, onDragMove]
  );

  const bigImageRef = useRef<BigImage | null>(null);
  useEffect(() => {
    const imageSprite = new BigImage(image.src);
    imageSprite.name = 'new-base-layer-image';

    // Apply blend mode and make floorplan grayscale
    // ref: https://github.com/pixijs/pixijs/issues/1598#issuecomment-284810464
    let colorMatrix = new PIXI.filters.ColorMatrixFilter();
    if (grayscale) {
      colorMatrix.desaturate();
    }
    colorMatrix.blendMode = PIXI.BLEND_MODES.MULTIPLY;
    imageSprite.filters = [colorMatrix];

    bigImageRef.current = imageSprite;
  }, [image.src, grayscale]);

  return (
    <ImageRegistrationLayer
      imageWidth={image.width}
      imageHeight={image.height}
      createImage={(container) => {
        if (!bigImageRef.current) {
          return;
        }
        container.addChild(bigImageRef.current);
      }}
      removeImage={(container) => {
        if (bigImageRef.current) {
          container.removeChild(bigImageRef.current);
        }
        container.destroy({ children: true });
      }}
      position={upperLeftPosition}
      rotationInDegrees={0}
      scale={cadImageScale / context.floorplan.scale}
      isMovable
      onDragMove={onDragMoveCallback}
    />
  );
};

const SENSOR_FOCUSED_OUTLINE_WIDTH_PX = 4;

// A layer that shows all sensors that will be added or removed to the plan during the dxf import
// process.
// Sensors are shown in green (additions), red (deletions), yellow (modifications), grey (no change)
export const FloorplanSensorDiffLayer: React.FunctionComponent<{
  floorplanChanges: Array<FloorplanChange>;
  floorplanCADOrigin: FloorplanCoordinates;
  cadFileUnit: LengthUnit;
  cadFileScale: number;
}> = ({ floorplanChanges, floorplanCADOrigin, cadFileUnit, cadFileScale }) => {
  const context = useFloorplanLayerContext();

  const ellipticalOACoverageEnabled = useTreatment(
    SPLITS.ELLIPTICAL_OA_COVERAGE
  );

  const sensorMajorMinorInMeters = useMemo(() => {
    const sensorMajorMinorInMeters: { [sensorId: string]: [number, number] } =
      {};

    for (const change of floorplanChanges) {
      if (change.data.type === 'oa' && ellipticalOACoverageEnabled) {
        sensorMajorMinorInMeters[change.data.cadId] =
          PlanSensor.computeCoverageMajorMinorAxisOA(change.data.height);
      } else if (change.data.type === 'oa' && !ellipticalOACoverageEnabled) {
        // NOTE: This is the old radius-based oa sensor coverage rendering, which is deprecated
        const radiusMeters = PlanSensor.computeCoverageRadiusOA(
          change.data.height
        );
        sensorMajorMinorInMeters[change.data.cadId] = [
          radiusMeters,
          radiusMeters,
        ];
      } else {
        const radiusMeters = PlanSensor.computeCoverageRadiusEntry(
          change.data.height
        );
        sensorMajorMinorInMeters[change.data.cadId] = [
          radiusMeters,
          radiusMeters,
        ];
      }
    }

    return sensorMajorMinorInMeters;
  }, [floorplanChanges, ellipticalOACoverageEnabled]);

  return (
    <ObjectLayer
      objects={floorplanChanges}
      extractId={(change) => change.data.cadId}
      onCreate={(getSensor) => {
        const sensorGraphic = new PIXI.Container();

        const coverageArea = new PIXI.Graphics();
        coverageArea.name = 'coverage-area';
        sensorGraphic.addChild(coverageArea);

        return sensorGraphic;
      }}
      onUpdate={(change: FloorplanChange, sensorGraphic) => {
        if (!context.viewport.current) {
          return;
        }

        const majorMinorMeters = sensorMajorMinorInMeters[change.data.cadId];
        if (!majorMinorMeters) {
          return;
        }
        const [majorMeters, minorMeters] = majorMinorMeters;
        const majorPixels =
          majorMeters * context.floorplan.scale * context.viewport.current.zoom;
        const minorPixels =
          minorMeters * context.floorplan.scale * context.viewport.current.zoom;

        let color: number, viewportCoords: ViewportCoordinates;
        if (change.type === 'addition') {
          color = toRawHex(dust.Green400);
          viewportCoords = CADCoordinates.toViewportCoordinates(
            change.data.position,
            floorplanCADOrigin,
            cadFileUnit,
            cadFileScale,
            context.floorplan,
            context.viewport.current
          );
        } else if (change.type === 'modification') {
          color = toRawHex(dust.Yellow400);
          viewportCoords = CADCoordinates.toViewportCoordinates(
            change.data.position,
            floorplanCADOrigin,
            cadFileUnit,
            cadFileScale,
            context.floorplan,
            context.viewport.current
          );
        } else if (change.type === 'deletion') {
          color = toRawHex(dust.Red400);
          viewportCoords = FloorplanCoordinates.toViewportCoordinates(
            change.data.position,
            context.floorplan,
            context.viewport.current
          );
        } else {
          // no-change
          color = toRawHex(dust.Gray400);
          viewportCoords = FloorplanCoordinates.toViewportCoordinates(
            change.data.position,
            context.floorplan,
            context.viewport.current
          );
        }

        // Hide sensors that are not on the screen
        sensorGraphic.renderable = isWithinViewport(
          context,
          viewportCoords,
          -1 * majorPixels
        );
        if (!sensorGraphic.renderable) {
          return;
        }

        sensorGraphic.x = viewportCoords.x;
        sensorGraphic.y = viewportCoords.y;

        // Draw main sensor coverage area
        const coverageArea = sensorGraphic.getChildByName(
          'coverage-area'
        ) as PIXI.Graphics;
        coverageArea.clear();
        coverageArea.lineStyle({
          width: 1,
          color,
          alignment: change.data.type === 'oa' ? 0 : 1,
        });
        coverageArea.beginFill(color, 0.12);
        switch (change.data.type) {
          case 'oa':
            // OA coverage area is a circle
            coverageArea.drawEllipse(0, 0, minorPixels, majorPixels);
            break;
          case 'entry':
            // Entry coverage area is a half-circle
            coverageArea.moveTo(0, 0);
            const startAngle = degreesToRadians(change.data.rotation);
            const endAngle = startAngle + Math.PI;
            coverageArea.arc(0, 0, majorPixels, startAngle, endAngle, true);
            coverageArea.lineTo(0, 0);
            break;
        }
        coverageArea.endFill();

        // Draw inset shadow used to indicate that the sensor is selected
        coverageArea.lineStyle({
          width: SENSOR_FOCUSED_OUTLINE_WIDTH_PX,
          color,
          alpha: 0.2,
          join: PIXI.LINE_JOIN.ROUND,
          alignment: change.data.type === 'oa' ? 0 : 1,
        });
        if (change.data.type === 'oa') {
          coverageArea.drawEllipse(0, 0, minorPixels, majorPixels);
        } else {
          coverageArea.moveTo(0, 0);
          const startAngle = degreesToRadians(change.data.rotation);
          const endAngle = startAngle + Math.PI;
          coverageArea.arc(0, 0, majorPixels, startAngle, endAngle, true);
          coverageArea.lineTo(0, 0);
        }
      }}
      onRemove={(change, coverageArea) => {
        coverageArea.destroy(true);
      }}
    />
  );
};

export const uploadDXFFile = (
  floorId: FloorplanV2Plan['floor']['id'],
  planId: FloorplanV2Plan['id'],
  client: AxiosInstance,
  file: File,
  onBeginUpload: () => void = () => {},
  onUploadProgress: (percent: number) => void = () => {},
  onUploadError: (err: Error) => void = () => {},
  onUploadComplete: (planDXF: PlanDXF) => void = () => {}
) => {
  onBeginUpload();

  // Read file contents
  const reader = new FileReader();
  reader.onload = async (e) => {
    if (!e.target) {
      return;
    }
    const fileContents = e.target.result;
    if (!fileContents || typeof fileContents === 'string') {
      return;
    }

    // Upload DXF
    let signedUrlResponse: AxiosResponse<{ key: string; signed_url: string }>;
    try {
      signedUrlResponse = await FloorplanAPI.imageUpload(client, {
        floor_id: floorId,
        ext: 'dxf',
        content_type: 'application/dxf',
      });
    } catch (err) {
      toast.error('Error uploading DXF!');
      return;
    }
    if (signedUrlResponse.status !== 201) {
      toast.error('Error uploading DXF!');
      return;
    }

    const { key: objectKey, signed_url: signedUrl } = signedUrlResponse.data;

    try {
      await axios.put(signedUrl, fileContents, {
        headers: {
          'Content-Type': 'application/dxf',
        },
        onUploadProgress: (event) => {
          if (typeof event.total === 'undefined') {
            return;
          }
          const percent = (event.loaded / event.total) * 100;
          onUploadProgress(percent);
        },
      });
    } catch (err) {
      console.warn('Error uploading dxf:', err);
      onUploadError(err as Error);
      return;
    }

    // Create the dxf on the serverside using the newly uploaded file
    let createPlanDXFResponse;
    try {
      createPlanDXFResponse = await FloorplanAPI.createAndProcessDXF(
        client,
        planId,
        objectKey
      );
    } catch (err) {
      console.warn('Error creating DXF!', err);
      onUploadError(err as Error);
      return;
    }
    onUploadComplete(createPlanDXFResponse.data);
  };
  reader.onerror = () => {
    toast.error('Error reading DXF file!');
  };
  reader.readAsArrayBuffer(file);
};

const DiffOperationIndicator: React.FunctionComponent<{
  type: FloorplanChange['type'];
}> = ({ type }) => {
  const [Icon, className] = {
    addition: [Icons.Plus, styles.addition],
    deletion: [Icons.Close, styles.deletion],
    modification: [Icons.SwapHorizontalArrow, styles.modification],
    'no-change': [Icons.Minus, styles.noChange],
  }[type];

  return (
    <div className={classnames(styles.operationTypeIndicator, className)}>
      <Icon size={12} />
    </div>
  );
};

const CADImportLoader: React.FunctionComponent = () => (
  <div className={styles.cadImportLoader}>
    <CADImportLoaderSvg />
    <p>Loading your plan. Hang tight...</p>
  </div>
);

const CADImportOperationLabel: React.FunctionComponent<{
  operationType: CADImportOperationType;
}> = ({ operationType }) => (
  <Fragment>
    <div style={{ paddingRight: 4, height: 18 }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="19"
        fill="none"
        viewBox="0 0 18 19"
      >
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M12.75 8.75a3 3 0 110-6 3 3 0 010 6zm1.5-3a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z"
          clipRule="evenodd"
          opacity={operationType === 'floorplan' ? 0.3 : 1}
        ></path>
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M8.25 2.75h-6v13.5h13.5v-6h-7.5v-7.5zm-4.5 12V4.25h3v7.5h7.5v3H3.75z"
          clipRule="evenodd"
          opacity={operationType === 'sensors' ? 0.3 : 1}
        ></path>
      </svg>
    </div>
    {operationType === 'both' ? 'Everything' : null}
    {operationType === 'floorplan' ? 'Floorplan Only' : null}
    {operationType === 'sensors' ? 'Sensors Only' : null}
  </Fragment>
);

// This component renders the side panel in the DXF import process
const CADImportPanel: React.FunctionComponent<{
  mode: 'update' | 'create';
  floorplan: FloorplanType;
  floorplanChanges: Array<FloorplanChange>;
  layerNames: Array<string>;
  frozenLayerNames: Array<string>;
  displayUnit: LengthUnit;
  loading: boolean;

  floorplanCADOrigin: FloorplanCoordinates;
  onChangeFloorplanCADOrigin: ((coords: FloorplanCoordinates) => void) | null;
  cadFileUnit: LengthUnit;
  onChangeCADFileUnit: (newUnit: LengthUnit) => void;
  cadFileScale: number;
  pixelsPerCADUnit: number;
  onChangeCADFileScale: (newScale: number) => void;
  oaSensorLayer: string | null;
  onChangeOASensorLayer: (newLayer: string) => void;
  entrySensorLayer: string | null;
  onChangeEntrySensorLayer: (newLayer: string) => void;
  operationType: CADImportOperationType;
  onChangeOperationType?: (operationType: CADImportOperationType) => void;
}> = ({
  mode,
  floorplan,
  floorplanChanges,
  layerNames,
  frozenLayerNames,
  displayUnit,
  loading,
  floorplanCADOrigin,
  onChangeFloorplanCADOrigin,
  cadFileUnit,
  onChangeCADFileUnit,
  cadFileScale,
  onChangeCADFileScale,
  oaSensorLayer,
  onChangeOASensorLayer,
  entrySensorLayer,
  onChangeEntrySensorLayer,
  operationType,
  onChangeOperationType,
}) => {
  const layerChoices = [
    { id: EMPTY_LAYER_NAME, label: 'Empty' },
    ...layerNames.map((layerName) => ({
      id: layerName,
      label: layerName,
    })),
    ...frozenLayerNames.map((layerName) => ({
      id: layerName,
      label: layerName,
      disabled: true,
    })),
  ];

  return (
    <Panel position="top-left" width={600}>
      <PanelHeaderWell>
        <div className={styles.cadImportControlList}>
          <FormLabel
            label="X / Y Units:"
            input={
              <SelectField
                size="medium"
                width={120}
                choices={[
                  { id: 'feet_and_inches', label: 'Feet' },
                  { id: 'inches', label: 'Inches' },
                  { id: 'meters', label: 'Meters' },
                  { id: 'centimeters', label: 'Centimeters' },
                  { id: 'millimeters', label: 'Millimeters' },
                ]}
                value={cadFileUnit}
                onChange={(choice) => onChangeCADFileUnit(choice.id)}
                disabled={loading}
              />
            }
          />
          <FormLabel
            label="CAD Scale"
            input={
              <ScaleField
                value={cadFileScale}
                disabled={loading}
                onChange={(newValue) => onChangeCADFileScale(newValue)}
              />
            }
          />
          {onChangeFloorplanCADOrigin ? (
            <FormLabel
              label="CAD Origin"
              input={
                <FloorplanCoordinatesField
                  value={floorplanCADOrigin}
                  disabled={loading}
                  computeDefaultValue={() => computeDefaultCADOrigin(floorplan)}
                  onChange={onChangeFloorplanCADOrigin}
                  data-cy="cad-import-floorplan-cad-origin"
                />
              }
            />
          ) : null}
          <FormLabel
            label="OA Sensor Layer"
            input={
              <SelectField
                value={oaSensorLayer}
                choices={layerChoices}
                onChange={(layer: { id: string }) => {
                  onChangeOASensorLayer(layer.id);
                }}
                width={150}
                disabled={loading}
                size="medium"
                data-cy="cad-import-oa-layer-name"
              />
            }
          />
          <FormLabel
            label="Entry Sensor Layer"
            input={
              <SelectField
                value={entrySensorLayer}
                choices={layerChoices}
                onChange={(layer: { id: string }) =>
                  onChangeEntrySensorLayer(layer.id)
                }
                width={150}
                disabled={loading}
                size="medium"
                data-cy="cad-import-entry-layer-name"
              />
            }
          />
        </div>
      </PanelHeaderWell>

      {mode === 'update' ? (
        <TabBar
          choices={[
            {
              id: 'both',
              label: <CADImportOperationLabel operationType="both" />,
            },
            {
              id: 'floorplan',
              label: <CADImportOperationLabel operationType="floorplan" />,
            },
            {
              id: 'sensors',
              label: <CADImportOperationLabel operationType="sensors" />,
            },
          ]}
          data-cy="cad-import-operation-type"
          // disabled={loading}
          value={operationType}
          onChange={(choiceId) => {
            onChangeOperationType?.(choiceId as CADImportOperationType);
          }}
        />
      ) : null}

      <div
        style={{ position: 'relative', overflowY: 'auto' }}
        data-cy="cad-import-diff-table"
      >
        <table
          className={styles.diffTable}
          style={{ opacity: loading ? 0.5 : 1 }}
        >
          <thead>
            <tr>
              <th className={styles.diffHeader}></th>
              <th className={styles.diffHeader}>ID</th>
              <th className={styles.diffHeader}>Sensor SN</th>
              <th className={styles.diffHeader}>Type</th>
              <th className={styles.diffHeader}>X Pos.</th>
              <th className={styles.diffHeader}>Y Pos.</th>
              <th className={styles.diffHeader}>Ht.</th>
              <th className={styles.diffHeader}>Rot.</th>
            </tr>
          </thead>
          <tbody>
            {floorplanChanges
              .sort((a, b) => a.data.cadId.localeCompare(b.data.cadId))
              .map((change) => {
                const renderCellForField = (
                  template: (
                    row: ProcessedCADSensorPlacement | PlanSensor
                  ) => React.ReactNode,
                  checkIfModified: (
                    newData: ProcessedCADSensorPlacement,
                    oldData: PlanSensor
                  ) => boolean
                ) => {
                  switch (change.type) {
                    case 'no-change':
                    case 'addition':
                    case 'deletion':
                      return (
                        <div style={{ height: 24 }}>
                          {template(change.data)}
                        </div>
                      );
                    case 'modification':
                      if (!change.oldData) {
                        return;
                      }
                      if (checkIfModified(change.data, change.oldData)) {
                        return (
                          <div>
                            {template(change.data)}
                            <br />
                            <span
                              style={{
                                color: dust.Gray300,
                                textDecoration: 'line-through',
                              }}
                            >
                              {template(change.oldData)}
                            </span>
                          </div>
                        );
                      } else {
                        return (
                          <div style={{ height: 24 }}>
                            {template(change.data)}
                          </div>
                        );
                      }
                  }
                };

                return (
                  <tr
                    className={classnames(styles.diffRow, {
                      [styles.addition]: change.type === 'addition',
                      [styles.deletion]: change.type === 'deletion',
                      [styles.modification]: change.type === 'modification',
                      [styles.noChange]: change.type === 'no-change',
                    })}
                    data-cy={`cad-import-diff-row-${change.type}`}
                    key={change.data.cadId}
                  >
                    <td>
                      <DiffOperationIndicator type={change.type} />
                    </td>
                    <td>{change.data.cadId}</td>
                    <td>
                      {renderCellForField(
                        (n) => n.serialNumber || 'Empty',
                        (oldData, newData) =>
                          oldData.serialNumber !== newData.serialNumber
                      )}
                    </td>
                    <td>
                      {renderCellForField(
                        (n) => (n.type === 'oa' ? 'OA' : 'Entry'),
                        (oldData, newData) => oldData.type !== newData.type
                      )}
                    </td>
                    <td>
                      {renderCellForField(
                        (row) => {
                          if (row.position.type === 'floorplan-coordinates') {
                            const cadCoord =
                              FloorplanCoordinates.toCADCoordinates(
                                row.position,
                                floorplan,
                                floorplanCADOrigin,
                                cadFileUnit,
                                cadFileScale
                              );
                            return displayLength(cadCoord.x, displayUnit);
                          } else {
                            return displayLength(row.position.x, displayUnit);
                          }
                        },
                        (newData, oldData) => {
                          const cadCoord =
                            FloorplanCoordinates.toCADCoordinates(
                              oldData.position,
                              floorplan,
                              floorplanCADOrigin,
                              cadFileUnit,
                              cadFileScale
                            );
                          return newData.position.x !== cadCoord.x;
                        }
                      )}
                    </td>
                    <td>
                      {renderCellForField(
                        (row) => {
                          if (row.position.type === 'floorplan-coordinates') {
                            const cadCoord =
                              FloorplanCoordinates.toCADCoordinates(
                                row.position,
                                floorplan,
                                floorplanCADOrigin,
                                cadFileUnit,
                                cadFileScale
                              );
                            return displayLength(cadCoord.y, displayUnit);
                          } else {
                            return displayLength(row.position.y, displayUnit);
                          }
                        },
                        (newData, oldData) => {
                          const cadCoord =
                            FloorplanCoordinates.toCADCoordinates(
                              oldData.position,
                              floorplan,
                              floorplanCADOrigin,
                              cadFileUnit,
                              cadFileScale
                            );
                          return newData.position.y !== cadCoord.y;
                        }
                      )}
                    </td>
                    <td>
                      {renderCellForField(
                        (n) => displayLength(n.height, displayUnit),
                        (oldData, newData) => oldData.height !== newData.height
                      )}
                    </td>
                    <td>
                      {renderCellForField(
                        (n) => `${n.rotation} deg.`,
                        (oldData, newData) =>
                          oldData.rotation !== newData.rotation
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        {loading ? <CADImportLoader /> : null}
        {floorplanChanges.length === 0 ? (
          <div className={styles.diffTableEmpty}>
            <Icons.DeviceEntrySideIsometricAngle size={24} />
            No sensors found in DXF!
          </div>
        ) : null}
      </div>
    </Panel>
  );
};

// This component renders a legend in the lower right hand corner clarifying the sensor diff colors
const CADImportLegend: React.FunctionComponent<{
  changes: Array<FloorplanChange>;
}> = ({ changes }) => {
  // Compute the number of each type of change shown to the user
  const counts = useMemo(() => {
    let counts = { addition: 0, deletion: 0, modification: 0, 'no-change': 0 };

    for (let item of changes) {
      counts[item.type] += 1;
    }

    return counts;
  }, [changes]);

  return (
    <Panel position="top-right" width={150} top={8}>
      <table className={styles.legendTable}>
        <tbody>
          {counts.addition > 0 ? (
            <tr>
              <td>
                <DiffOperationIndicator type="addition" />
              </td>
              <td>Added</td>
              <td>
                <span className={styles.tag}>{counts.addition}</span>
              </td>
            </tr>
          ) : null}
          {counts.deletion > 0 ? (
            <tr>
              <td>
                <DiffOperationIndicator type="deletion" />
              </td>
              <td>Removed</td>
              <td>
                <span className={styles.tag}>{counts.deletion}</span>
              </td>
            </tr>
          ) : null}
          {counts.modification > 0 ? (
            <tr>
              <td>
                <DiffOperationIndicator type="modification" />
              </td>
              <td>Changed</td>
              <td>
                <span className={styles.tag}>{counts.modification}</span>
              </td>
            </tr>
          ) : null}
          {counts['no-change'] > 0 ? (
            <tr>
              <td>
                <DiffOperationIndicator type="no-change" />
              </td>
              <td>Unchanged</td>
              <td>
                <span className={styles.tag}>{counts['no-change']}</span>
              </td>
            </tr>
          ) : null}
          <tr className={styles.totalRow}>
            <td></td>
            <td>Total Sensors</td>
            <td>
              <span className={styles.tag}>{changes.length}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </Panel>
  );
};

type CADImportCreationProps = {
  mode: 'create';
  cadFileUnit: LengthUnit | null;
  planDXF: PlanDXF;
  onChangeCADFileUnit: (unit: LengthUnit) => void;
  cadFileScale: number;
  onChangeCADFileScale: (newScale: number) => void;
  dxfParseOptions: ParseDXFOptions;
  displayUnit: LengthUnit;
  pixelsPerCADUnit: number;

  onChangeOASensorLayer: (layerName: string) => void;
  onChangeEntrySensorLayer: (layerName: string) => void;
  onSubmit: (
    changes: Array<FloorplanChange>,
    floorplan: FloorplanType,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnitOrDefault: LengthUnit,
    cadFileScaleOrDefault: number,
    pixelsPerCADUnit: number
  ) => void;
  onCancel: () => void;
};

type CADImportUpdateProps = {
  mode: 'update';
  image: HTMLImageElement;
  sensors: Array<PlanSensor>;
  initialFloorplan: FloorplanType;
  floorplanCADOrigin: FloorplanCoordinates;
  planDXF: PlanDXF;
  cadFileUnit: LengthUnit | null;
  onChangeCADFileUnit: (unit: LengthUnit) => void;
  cadFileScale: number;
  onChangeCADFileScale: (newScale: number) => void;
  dxfParseOptions: ParseDXFOptions;
  pixelsPerCADUnit: number;
  displayUnit: LengthUnit;

  onDragMoveFloorplanCADOrigin: (coords: FloorplanCoordinates) => void;
  onChangeOASensorLayer: (layerName: string) => void;
  onChangeEntrySensorLayer: (layerName: string) => void;
  operationType: CADImportOperationType;
  onChangeOperationType: (operationType: CADImportOperationType) => void;
  onSubmit: (
    changes: Array<FloorplanChange>,
    floorplan: FloorplanType,
    floorplanCADOrigin: FloorplanCoordinates,
    cadFileUnitOrDefault: LengthUnit,
    cadFileScaleOrDefault: number,
    pixelsPerCADUnit: number
  ) => void;
  onCancel: () => void;
};

const CADImport: React.FunctionComponent<
  CADImportCreationProps | CADImportUpdateProps
> = (props) => {
  const [newBaseImage, setNewBaseImage] = useState<HTMLImageElement | null>(
    null
  );

  const floorplanRef = useRef<FixMe | null>(null);

  // When the component mounts, fetch the dxf's raster image.
  useEffect(() => {
    const image = new Image();
    for (const asset of props.planDXF.assets) {
      if (asset.name === 'full_image' && asset.content_type === 'image/png') {
        image.src = asset.object_url;
        image.onload = () => {
          setNewBaseImage(image);
        };
        break;
      }
    }
  }, [props.planDXF]);

  if (!newBaseImage) {
    return <LoadingOverlay text="Loading base image..." />;
  }

  const oaSensorLayer =
    typeof props.dxfParseOptions?.oa?.layer !== 'undefined'
      ? props.dxfParseOptions.oa.layer
      : props.planDXF.default_openarea_sensor_layer || EMPTY_LAYER_NAME;
  const entrySensorLayer =
    typeof props.dxfParseOptions?.entry?.layer !== 'undefined'
      ? props.dxfParseOptions.entry.layer
      : props.planDXF.default_entry_sensor_layer || EMPTY_LAYER_NAME;
  const cadFileUnit: LengthUnit =
    props.cadFileUnit ||
    props.planDXF.length_unit ||
    DEFAULT_CAD_FILE_LENGTH_UNIT;
  const cadFileScale = props.cadFileScale;

  const floorplan =
    props.mode === 'create'
      ? (() => {
          const initialFloorplan = {
            width: newBaseImage.width,
            height: newBaseImage.height,
            scale: 1,
            origin: ImageCoordinates.create(0, 0),
            rotation: 0,
          };

          // Calcualte the initial scale for the floorplan
          const coordA = CADCoordinates.toFloorplanCoordinates(
            CADCoordinates.create(0, 0),
            initialFloorplan,
            FloorplanCoordinates.create(0, 0),
            cadFileUnit,
            cadFileScale
          );
          const coordB = CADCoordinates.toFloorplanCoordinates(
            CADCoordinates.create(1, 0),
            initialFloorplan,
            FloorplanCoordinates.create(0, 0),
            cadFileUnit,
            cadFileScale
          );
          const metersPerCADUnit = coordB.x - coordA.x;
          const ratio = metersPerCADUnit / props.pixelsPerCADUnit;
          const cadImageScale = ratio * initialFloorplan.scale;

          return { ...initialFloorplan, scale: 1 / cadImageScale };
        })()
      : props.initialFloorplan;

  const floorplanCADOriginOrDefault =
    props.mode === 'create'
      ? computeDefaultCADOrigin(floorplan)
      : props.floorplanCADOrigin;

  const operationTypeOrDefault =
    props.mode === 'create' ? 'both' : props.operationType;

  const sensorPlacements: Array<ProcessedCADSensorPlacement> =
    props.planDXF.sensor_placements.map((rawSensorPlacement) => ({
      serialNumber: rawSensorPlacement.data_tag.serial_number,
      height: rawSensorPlacement.data_tag.height_meters,
      rotation: rawSensorPlacement.rotation,
      position: CADCoordinates.create(
        rawSensorPlacement.position.x,
        rawSensorPlacement.position.y
      ),
      type: rawSensorPlacement.sensor_type,
      cadId: rawSensorPlacement.data_tag.cad_id,
    }));

  const floorplanChanges = computeFloorplanChanges(
    sensorPlacements,
    props.mode === 'create' ? [] : props.sensors,
    floorplan,
    floorplanCADOriginOrDefault,
    operationTypeOrDefault,
    cadFileUnit,
    cadFileScale
  );

  const numberOfRealFloorplanChanges = floorplanChanges.filter(
    (i) => i.type !== 'no-change'
  ).length;

  let submitText = props.mode === 'create' ? 'Create' : 'Update';
  let submitDisabled = false;
  if (numberOfRealFloorplanChanges === 0) {
    if (props.mode === 'create') {
      // Empty floorplan uploaded to the creation process
      submitText = 'Create floorplan';
    } else if (operationTypeOrDefault === 'floorplan') {
      // Only modifying the floorplan, no sensors
      submitText = 'Swap floorplan';
    } else {
      submitText = 'No changes found';
      submitDisabled = true;
    }
  } else if (numberOfRealFloorplanChanges >= 1) {
    submitText += numberOfRealFloorplanChanges === 1 ? ' sensor' : ' sensors';
    if (operationTypeOrDefault !== 'sensors') {
      submitText += ' & floorplan';
    }
  }

  return (
    <Fragment>
      <DarkTheme>
        <AppBar
          title={
            props.mode === 'create' ? 'Create Floorplan' : 'Preview Changes'
          }
          actions={
            <HorizontalForm size="medium">
              <Button
                size="medium"
                type="cleared"
                data-cy="cad-import-cancel"
                onClick={props.onCancel}
              >
                Cancel
              </Button>
              <Button
                size="medium"
                data-cy="cad-import-submit"
                onClick={() => {
                  if (!submitDisabled) {
                    props.onSubmit(
                      floorplanChanges,
                      floorplan,
                      floorplanCADOriginOrDefault,
                      cadFileUnit,
                      cadFileScale,
                      props.pixelsPerCADUnit
                    );
                  }
                }}
                disabled={submitDisabled}
              >
                {submitText}
              </Button>
            </HorizontalForm>
          }
        />
      </DarkTheme>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
          <Floorplan
            ref={floorplanRef}
            image={props.mode === 'create' ? newBaseImage : props.image}
            floorplan={floorplan}
            width="100%"
            height="100%"
            lengthUnit={'feet_and_inches'}
          >
            {props.mode === 'update' ? (
              <Fragment>
                {props.operationType !== 'sensors' ? (
                  <FloorplanCADNewBaseImageLayer
                    image={newBaseImage}
                    floorplanCADOrigin={props.floorplanCADOrigin}
                    cadFileUnit={cadFileUnit}
                    cadFileScale={cadFileScale}
                    pixelsPerCADUnit={props.pixelsPerCADUnit}
                    onDragMove={props.onDragMoveFloorplanCADOrigin}
                  />
                ) : null}
              </Fragment>
            ) : null}
            <FloorplanSensorDiffLayer
              floorplanChanges={floorplanChanges}
              floorplanCADOrigin={floorplanCADOriginOrDefault}
              cadFileUnit={cadFileUnit}
              cadFileScale={cadFileScale}
            />
          </Floorplan>
        </div>

        <CADImportPanel
          mode={props.mode}
          floorplan={floorplan}
          floorplanChanges={floorplanChanges}
          loading={false}
          layerNames={props.planDXF.layer_names}
          frozenLayerNames={props.planDXF.frozen_layer_names}
          oaSensorLayer={oaSensorLayer}
          onChangeOASensorLayer={props.onChangeOASensorLayer}
          entrySensorLayer={entrySensorLayer}
          onChangeEntrySensorLayer={props.onChangeEntrySensorLayer}
          displayUnit={props.displayUnit}
          floorplanCADOrigin={floorplanCADOriginOrDefault}
          onChangeFloorplanCADOrigin={
            props.mode === 'update' ? props.onDragMoveFloorplanCADOrigin : null
          }
          cadFileUnit={cadFileUnit}
          onChangeCADFileUnit={props.onChangeCADFileUnit}
          cadFileScale={cadFileScale}
          pixelsPerCADUnit={props.pixelsPerCADUnit}
          onChangeCADFileScale={props.onChangeCADFileScale}
          operationType={operationTypeOrDefault}
          onChangeOperationType={
            props.mode === 'update' ? props.onChangeOperationType : undefined
          }
        />
        <CADImportLegend changes={floorplanChanges} />

        <FloorplanZoomControls
          onZoomToFitClick={() => {
            if (floorplanRef.current) {
              floorplanRef.current.zoomToFitWithSidebar(600);
            }
          }}
        />
      </div>
    </Fragment>
  );
};

export default CADImport;
