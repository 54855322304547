import React from "react";

const BabyChanging = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M9,9 L9,13 L11,13 L11,15 L7,15 L7,11.6007811 L4,15.3507811 L4,22 L2,22 L2,14.6492189 L6.51937515,9 L9,9 Z M22,20 L22,22 L8,22 L8,20 L22,20 Z M15.7071068,13.2928932 L18.4142136,16 L15,19.4142136 L13,17.4142136 L11.7071068,18.7071068 L10.2928932,17.2928932 L13,14.5857864 L15,16.5857864 L15.5857864,16 L14.2928932,14.7071068 L15.7071068,13.2928932 Z M19.5,12 C20.3284271,12 21,12.6715729 21,13.5 C21,14.3284271 20.3284271,15 19.5,15 C18.6715729,15 18,14.3284271 18,13.5 C18,12.6715729 18.6715729,12 19.5,12 Z M11.5,2 C13.4329966,2 15,3.56700338 15,5.5 C15,7.43299662 13.4329966,9 11.5,9 C9.56700338,9 8,7.43299662 8,5.5 C8,3.56700338 9.56700338,2 11.5,2 Z M11.5,4 C10.6715729,4 10,4.67157288 10,5.5 C10,6.32842712 10.6715729,7 11.5,7 C12.3284271,7 13,6.32842712 13,5.5 C13,4.67157288 12.3284271,4 11.5,4 Z"
      />
    </g>
  </svg>
);

export default BabyChanging;
