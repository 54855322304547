import React from 'react';

const CleanMagicSparkle = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 24}
    height={height || 24}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M7.5 10c0-1.437.588-2.287 1.292-2.794C9.522 6.68 10.413 6.5 11 6.5v-1c-1.437 0-2.287-.588-2.794-1.292C7.68 3.478 7.5 2.587 7.5 2h-1c0 1.437-.588 2.287-1.292 2.794C4.478 5.32 3.587 5.5 3 5.5v1c1.437 0 2.287.588 2.794 1.292.526.73.706 1.621.706 2.208h1zM14 10.5c.587 0 1.478-.18 2.208-.706C16.912 9.287 17.5 8.437 17.5 7h1c0 .587.18 1.478.706 2.208.507.704 1.357 1.292 2.794 1.292v1c-.587 0-1.478.18-2.208.706-.704.507-1.292 1.357-1.292 2.794h-1c0-.587-.18-1.478-.706-2.208-.507-.704-1.357-1.292-2.794-1.292v-1zM5 17.5c.587 0 1.478-.18 2.208-.706C7.912 16.287 8.5 15.437 8.5 14h1c0 .587.18 1.478.706 2.208.507.704 1.357 1.292 2.794 1.292v1c-.587 0-1.478.18-2.208.706C10.088 19.713 9.5 20.563 9.5 22h-1c0-.587-.18-1.478-.706-2.208C7.287 19.088 6.437 18.5 5 18.5v-1z"
    ></path>
  </svg>
)

export default CleanMagicSparkle;
