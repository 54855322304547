import React from "react";

const Move = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M13,15 L12.9997864,18.8777864 L14.2928932,17.5857864 L15.7071068,19 L12,22.7071068 L8.29289322,19 L9.70710678,17.5857864 L10.9997864,18.8777864 L11,15 L13,15 Z M5.29289322,8.29289322 L6.70710678,9.70710678 L5.41378644,10.9997864 L9,11 L9,13 L5.41378644,12.9997864 L6.70710678,14.2928932 L5.29289322,15.7071068 L1.58578644,12 L5.29289322,8.29289322 Z M19,8.29289322 L22.7071068,12 L19,15.7071068 L17.5857864,14.2928932 L18.8777864,12.9997864 L15,13 L15,11 L18.8777864,10.9997864 L17.5857864,9.70710678 L19,8.29289322 Z M12,1.58578644 L15.7071068,5.29289322 L14.2928932,6.70710678 L12.9997864,5.41378644 L13,9 L11,9 L10.9997864,5.41378644 L9.70710678,6.70710678 L8.29289322,5.29289322 L12,1.58578644 Z"
      />
    </g>
  </svg>
);

export default Move;
