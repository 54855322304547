import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  loadCookieData,
  saveCookieData,
  TokenProvider,
  TokenProviderData,
} from '@densityco/lib-common-auth';
import { CoreOrganization, CoreUser } from '@densityco/lib-api-types';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

import { createDensityAPIClient, DensityAuthHeaders } from 'lib/client';
import { StorageKeys } from 'lib/storage';
import { useAppDispatch } from 'redux/store';
import { setAuthState } from 'redux/features/auth/auth-slice';
import LoadingOverlay from 'components/loading-overlay/loading-overlay';
import FillCenter from 'components/fill-center/fill-center';
import ErrorMessage from 'components/error-message/error-message';
import { FixMe } from 'types/fixme';

// Just create the headers ourselves instead of using the unstable headers within
// the TokenProviderData (which are recomputed each render and so unstable)
export const getAuthHeaders = (
  token: string,
  xImpersonateUserHeader?: string
) => {
  const authHeaders: DensityAuthHeaders = {
    Authorization: `Bearer ${token}`,
    ...(xImpersonateUserHeader
      ? { 'X-Impersonate-User': xImpersonateUserHeader }
      : undefined),
  };

  return authHeaders;
};

const AutoImpersonationWrapper: React.FunctionComponent<{
  tokenProviderData: TokenProviderData;
  organizationId?: CoreOrganization['id'];
  children: (newTokenProviderData: TokenProviderData) => FixMe;
}> = ({ tokenProviderData, organizationId, children }) => {
  const [newTokenProviderData, setNewTokenProviderData] = useState<
    | { status: 'pending' }
    | { status: 'loading' }
    | { status: 'error'; message: string }
    | { status: 'complete'; data: TokenProviderData }
  >({ status: 'pending' });

  useEffect(() => {
    const cookieData = loadCookieData();

    // If no organization is present, then disable impersonation flat out
    if (!organizationId) {
      cookieData.impersonate = { enabled: false };
      saveCookieData(cookieData);
      setNewTokenProviderData({
        status: 'complete',
        data: { ...tokenProviderData, xImpersonateUserHeader: undefined },
      });
      return;
    }

    // If the correct organization is already active, then do nothing - we're already good
    if (
      cookieData.impersonate.enabled &&
      cookieData.impersonate.organizationId === organizationId
    ) {
      setNewTokenProviderData({ status: 'complete', data: tokenProviderData });
      return;
    }

    // If this is the organization that the current user is in, then turn off impersonation - this
    // is our "home organization"
    if (
      tokenProviderData.tokenCheckResponse &&
      tokenProviderData.tokenCheckResponse.organization &&
      tokenProviderData.tokenCheckResponse.organization.id === organizationId
    ) {
      cookieData.impersonate = { enabled: false };
      saveCookieData(cookieData);
      setNewTokenProviderData({
        status: 'complete',
        data: { ...tokenProviderData, xImpersonateUserHeader: undefined },
      });
      return;
    }

    // Finally, fetch the users in the organization and pick the one with the highest privileges to
    // impersonate
    setNewTokenProviderData({ status: 'loading' });
    fetch(
      `${cookieData.environment.coreHost}/v2/users?organization_id=${organizationId}`,
      {
        headers: {
          Authorization: `Bearer ${tokenProviderData.token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Unable to fetch users in organization ${organizationId}`
          );
        }
        return response.json();
      })
      .then((userList: Array<CoreUser>) => {
        const firstOwnerUser = userList.find((user) => user.role === 'owner');
        if (!firstOwnerUser) {
          throw new Error(
            `No owner users could be found in organization ${organizationId}. Please create at least one to use this tool.`
          );
        }

        cookieData.impersonate = {
          enabled: true,
          id: firstOwnerUser.id,
          email: firstOwnerUser.email,
          name: firstOwnerUser.full_name,
          role: firstOwnerUser.role,
          organizationId: firstOwnerUser.organization.id,
          organizationName: firstOwnerUser.organization.name,
        };

        saveCookieData(cookieData);
        setNewTokenProviderData({
          status: 'complete',
          data: {
            ...tokenProviderData,
            xImpersonateUserHeader: firstOwnerUser.id,
          },
        });
      })
      .catch((error) => {
        setNewTokenProviderData({
          status: 'error' as const,
          message: error.message,
        });
      });
  }, [organizationId, tokenProviderData]);

  switch (newTokenProviderData.status) {
    case 'pending':
    case 'loading':
      return (
        <div
          style={{
            color: dust.Gray400,
            backgroundColor: dust.Gray800,
            height: '100%',
            width: '100%',
          }}
        >
          <div
            style={{
              width: '100%',
              height: 41,
              backgroundColor: dust.Gray900,
              borderBottom: `1px solid ${dust.Gray700}`,
            }}
          />
          <div
            style={{
              width: '100%',
              height: 48,
              backgroundColor: dust.Gray800,
              borderBottom: `1px solid ${dust.Gray900}`,
            }}
          />
          <div
            style={{
              width: '100%',
              height: 64,
              borderBottom: `1px solid ${dust.Gray700}`,
            }}
          />
          <FillCenter>Switching organization...</FillCenter>
        </div>
      );
    case 'error':
      return (
        <FillCenter>
          <ErrorMessage>{newTokenProviderData.message}</ErrorMessage>
        </FillCenter>
      );
    case 'complete':
      return children(newTokenProviderData.data);
  }
};

// This is pretty gross, but working around the mutable state of TokenProviderData
// by memoizing based on the inner values (which are stable values)
const SessionWrapper: React.FC<{
  tokenProviderData: TokenProviderData;
}> = ({ tokenProviderData, children }) => {
  // These values are reliable
  const { token, tokenCheckResponse, xImpersonateUserHeader } =
    tokenProviderData;

  // Here is the memoization...
  const densityAPIClient = React.useMemo(() => {
    return createDensityAPIClient(
      getAuthHeaders(token, xImpersonateUserHeader)
    );
  }, [token, xImpersonateUserHeader]); // <-- if these change, the value is re-computed

  const dispatch = useAppDispatch();

  // NOTE: sync context value to redux auth slice
  React.useEffect(() => {
    if (tokenCheckResponse === null) {
      throw new Error('TokenCheckResponse may not be null');
    }
    dispatch(
      setAuthState({
        densityAPIClient,
        tokenCheckResponse,
      })
    );
  }, [dispatch, densityAPIClient, tokenCheckResponse]);

  // make sure density api client exists before rendering any children
  if (!densityAPIClient) {
    return null;
  }

  return <>{children}</>;
};

const AuthWrapper: React.FunctionComponent<{
  organizationId?: CoreOrganization['id'];
}> = ({ organizationId, children }) => {
  return (
    <TokenProvider
      fallback={<LoadingOverlay text="Verifying user info..." />}
      error={
        <FillCenter>
          <ErrorMessage>Failed to verify user info.</ErrorMessage>
        </FillCenter>
      }
      tokenLocalStorageKey={StorageKeys.SESSION_TOKEN}
      loginHost={loadCookieData().environment.coreHost}
    >
      {(tokenProviderData) => {
        return (
          <AutoImpersonationWrapper
            tokenProviderData={tokenProviderData}
            organizationId={organizationId}
          >
            {(newTokenProviderData) => {
              return (
                <SessionWrapper tokenProviderData={newTokenProviderData}>
                  {children}
                </SessionWrapper>
              );
            }}
          </AutoImpersonationWrapper>
        );
      }}
    </TokenProvider>
  );
};

export default AuthWrapper;
