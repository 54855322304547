// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-inputStackItem-3Sd3jmq9{position:relative}.dui-styles-module-inputStackItem-3Sd3jmq9:first-child input{border-top-left-radius:4px;border-top-right-radius:4px}.dui-styles-module-inputStackItem-3Sd3jmq9:last-child input{border-bottom-left-radius:4px;border-bottom-right-radius:4px;border-bottom-color:#C0C6CC}.dui-styles-module-inputStackItem-3Sd3jmq9 input{display:block;font-family:\"Aeonik\",\"Helvetica\",\"Arial\",sans-serif;font-size:16px;background-color:#fff;width:calc(100% - 40px);height:18px;padding:20px;margin-bottom:0px;border-radius:0px;-webkit-appearance:none;outline:none;border:1px solid #CBCFD6;border-bottom-color:transparent}.dui-styles-module-inputStackItem-3Sd3jmq9 input:focus{border-color:#0D183A}.dui-styles-module-inputStackItem-3Sd3jmq9 input::placeholder{color:#C0C6CC}.dui-styles-module-inputStackItemFocus-3edXO2di+.dui-styles-module-inputStackItem-3Sd3jmq9 input{border-top-color:transparent}.dui-styles-module-inputStackItem-3Sd3jmq9:after{content:\" \";display:block;background-color:#0D183A;position:absolute;top:0px;right:20px;width:8px;height:8px;border-radius:50%;margin-top:26px;margin-bottom:52px}.dui-styles-module-inputStackItem-3Sd3jmq9.dui-styles-module-focus-3rQEfyz3:after{background-color:#C0C6CC}.dui-styles-module-inputStackItemInvalid-2eBiZ6s1 input:focus{border-color:#FF5548}.dui-styles-module-inputStackItemInvalid-2eBiZ6s1.dui-styles-module-focus-3rQEfyz3+.dui-styles-module-inputStackItemInvalid-2eBiZ6s1 input{border-top-color:#FF5548 !important}.dui-styles-module-inputStackItemInvalid-2eBiZ6s1:after{background-color:#FF5548}\n", ""]);
// Exports
exports.locals = {
	"inputStackItem": "dui-styles-module-inputStackItem-3Sd3jmq9",
	"inputStackItemFocus": "dui-styles-module-inputStackItemFocus-3edXO2di",
	"focus": "dui-styles-module-focus-3rQEfyz3",
	"inputStackItemInvalid": "dui-styles-module-inputStackItemInvalid-2eBiZ6s1"
};
module.exports = exports;
