import React from "react";

const PersonRemove = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M16,14 L16,16 L7,16 C6.44771525,16 6,16.4477153 6,17 L6,22 L4,22 L4,17 C4,15.3431458 5.34314575,14 7,14 L16,14 Z M20.4142136,16.1715729 L21.8284271,17.5857864 L20.414,19 L21.8284271,20.4142136 L20.4142136,21.8284271 L19,20.414 L17.5857864,21.8284271 L16.1715729,20.4142136 L17.586,19 L16.1715729,17.5857864 L17.5857864,16.1715729 L19,17.586 L20.4142136,16.1715729 Z M12,2 C14.7614237,2 17,4.23857625 17,7 C17,9.76142375 14.7614237,12 12,12 C9.23857625,12 7,9.76142375 7,7 C7,4.23857625 9.23857625,2 12,2 Z M12,4 C10.3431458,4 9,5.34314575 9,7 C9,8.65685425 10.3431458,10 12,10 C13.6568542,10 15,8.65685425 15,7 C15,5.34314575 13.6568542,4 12,4 Z"
      />
    </g>
  </svg>
);

export default PersonRemove;
