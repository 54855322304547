import React from "react";

const Help = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 Z M11.808,14.816 C12.1920019,14.816 12.5093321,14.9359988 12.76,15.176 C13.0106679,15.4160012 13.136,15.7226648 13.136,16.096 C13.136,16.4693352 13.0080013,16.7786654 12.752,17.024 C12.4959987,17.2693346 12.1813352,17.392 11.808,17.392 C11.4346648,17.392 11.1200013,17.2693346 10.864,17.024 C10.6079987,16.7786654 10.48,16.4693352 10.48,16.096 C10.48,15.7226648 10.6053321,15.4160012 10.856,15.176 C11.1066679,14.9359988 11.4239981,14.816 11.808,14.816 Z M11.968,6 C12.7466706,6 13.4213305,6.12799872 13.992,6.384 C14.5626695,6.64000128 15.0026651,6.99733104 15.312,7.456 C15.6213349,7.91466896 15.776,8.4373304 15.776,9.024 C15.776,9.7173368 15.6346681,10.263998 15.352,10.664 C15.0693319,11.064002 14.6560027,11.4506648 14.112,11.824 C13.6319976,12.1440016 13.2933343,12.4213322 13.096,12.656 C12.8986657,12.8906678 12.8,13.1679984 12.8,13.488 L12.8,13.696 L10.688,13.696 L10.688,13.248 C10.688,12.639997 10.8239986,12.165335 11.096,11.824 C11.3680014,11.482665 11.7973304,11.141335 12.384,10.8 C12.7573352,10.5653322 13.0293325,10.3253346 13.2,10.08 C13.3706675,9.83466544 13.456,9.5520016 13.456,9.232 C13.456,8.84799808 13.322668,8.5360012 13.056,8.296 C12.789332,8.0559988 12.4320022,7.936 11.984,7.936 C11.4719974,7.936 11.0613349,8.11466488 10.752,8.472 C10.4426651,8.82933512 10.288,9.3013304 10.288,9.888 L8,9.888 C8,9.17332976 8.15733176,8.52000296 8.472,7.928 C8.78666824,7.33599704 9.24266368,6.8666684 9.84,6.52 C10.4373363,6.1733316 11.1466626,6 11.968,6 Z"
      />
    </g>
  </svg>
);

export default Help;
