import classnames from 'classnames';
import styles from './styles.module.scss';

import { useTheme } from 'components/theme';

const KeyboardShortcut: React.FunctionComponent = ({ children }) => {
  const theme = useTheme();
  return (
    <div
      className={classnames(styles.keyboardShortcut, {
        [styles.dark]: theme === 'dark',
      })}
    >
      {children}
    </div>
  );
};

export default KeyboardShortcut;
