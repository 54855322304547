import { useRef, useMemo, useEffect } from 'react';
import * as PIXI from 'pixi.js';
import debounce from 'lodash.debounce';

import { FloorplanCoordinates } from 'lib/geometry';
import HeightMap from 'lib/heightmap';
import { LengthUnit, displayLength } from 'lib/units';
import { ReferenceHeight } from 'lib/reference';
import { ParsedGeoTiff, GEOTIFF_NO_DATA } from 'lib/geotiff';
import { useParseGeoTiff } from 'components/height-map-editor';

import {
  ObjectLayer,
  MetricLabel,
  useFloorplanLayerContext,
  isWithinViewport,
  addDragHandler,
  toRawHex,
} from 'components/floorplan';

import { Gray700, Gray900 } from '@density/dust/dist/tokens/dust.tokens';

const REFERERENCE_HEIGHT_ARROW_HEAD_SIZE_PX = 12;

async function queryExactHeightAtPoint(
  position: FloorplanCoordinates,
  heightMap: HeightMap,
  geotiffParseResults: ParsedGeoTiff,
  abortController?: AbortController
): Promise<number | null> {
  // Get the height at the exact position specified
  const heightMapPosition = FloorplanCoordinates.toHeightMapCoordinates(
    position,
    heightMap,
    geotiffParseResults.scale
  );

  const heightMeters = await geotiffParseResults.getHeightAtPoint(
    Math.round(heightMapPosition.x),
    Math.round(heightMapPosition.y),
    abortController ? abortController.signal : undefined
  );

  // If the user clicks outside the geotiff, disregard that point
  if (heightMeters === GEOTIFF_NO_DATA) {
    return null;
  }

  return heightMeters;
}

function renderHeightForDisplay(
  referenceHeightMeters: ReferenceHeight['heightMeters'],
  displayUnit: LengthUnit
): string {
  switch (referenceHeightMeters.step) {
    case 'empty':
    case 'loading':
      return '...';
    case 'complete':
      if (
        typeof referenceHeightMeters.value !== 'number' ||
        referenceHeightMeters.value === 0
      ) {
        return 'No Height';
      }
      return displayLength(referenceHeightMeters.value, displayUnit);
  }
}

const ReferenceHeightLayer: React.FunctionComponent<{
  referenceHeights: Array<ReferenceHeight & { distanceLabelText?: string }>;
  locked?: boolean;
  heightMap: HeightMap | null;
  highlightedObject?: {
    type:
      | 'sensor'
      | 'areaofconcern'
      | 'space'
      | 'photogroup'
      | 'reference'
      | 'layer';
    id: string;
  } | null;
  pointColor?: number;
  pointColorHighlighted?: number;
  pointColorHeightMapEmpty?: number;
  heightMapEmptyOpacity?: number;
  visibilityZoomThreshold?: number;
  onPositionChange?: (
    referenceHeight: ReferenceHeight,
    position: FloorplanCoordinates
  ) => void;
  onHeightChange?: (
    referenceHeight: ReferenceHeight,
    heightMetersValue: number | null
  ) => void;
}> = ({
  referenceHeights,
  locked = false,
  heightMap,
  highlightedObject = null,
  pointColor = toRawHex(Gray700),
  pointColorHighlighted = toRawHex(Gray900),
  heightMapEmptyOpacity = 0.5,
  visibilityZoomThreshold = 0.25,
  onPositionChange = null,
  onHeightChange = null,
}) => {
  const context = useFloorplanLayerContext();
  const geotiffParseResults = useParseGeoTiff(heightMap ? heightMap.url : null);

  // Cache the "arrow" sprite texture shown below the label of the reference height
  const [arrowTexture, arrowHighlightedTexture] = useMemo(() => {
    return [pointColor, pointColorHighlighted].map((color) => {
      const gr = new PIXI.Graphics();
      gr.lineStyle({ width: 0 });
      gr.beginFill(color);

      gr.moveTo(REFERERENCE_HEIGHT_ARROW_HEAD_SIZE_PX / 2, 0);
      gr.lineTo(
        REFERERENCE_HEIGHT_ARROW_HEAD_SIZE_PX,
        REFERERENCE_HEIGHT_ARROW_HEAD_SIZE_PX
      );
      gr.lineTo(0, REFERERENCE_HEIGHT_ARROW_HEAD_SIZE_PX);
      gr.lineTo(REFERERENCE_HEIGHT_ARROW_HEAD_SIZE_PX / 2, 0);

      return context.app.renderer.generateTexture(gr);
    });
  }, [context.app, pointColor, pointColorHighlighted]);

  // Cache the length of each reference ruler as it would be rendered to the user
  // Computing this on each frame is quite expensive it turns out
  const referenceDisplayHeights = useMemo(() => {
    const referenceDisplayHeights: { [referenceId: string]: string } = {};

    for (const reference of referenceHeights) {
      referenceDisplayHeights[reference.id] = renderHeightForDisplay(
        reference.heightMeters,
        context.lengthUnit
      );
    }

    return referenceDisplayHeights;
  }, [referenceHeights, context.lengthUnit]);

  const focusedReferenceHeight = useRef<{
    id: ReferenceHeight['id'];
    position: FloorplanCoordinates;
    heightMeters: ReferenceHeight['heightMeters'];
  } | null>(null);

  // FIXME: this probably is not the best approach to this, but because `onCreate` is not updated
  // within ObjectLayer due to dependency issues in the useEffect, it's possible that invocations of
  // `onDragMove` and friends within `onCreate` might be holding onto older references of these
  // functions from a previous render. So, cache the latest versions here in a ref so that the
  // latest version can always be called.
  //
  // The proper way to address this is by fixing the dependency issues within the `ObjectLayer`, but
  // this is a larger problem because the `onCreate` / `onUpdate` / `onRemove` function references
  // change every render and moving away from this is a large project across all layers.
  const latestOnPositionChange = useRef(onPositionChange);
  useEffect(() => {
    latestOnPositionChange.current = onPositionChange;
  }, [onPositionChange]);

  const latestOnHeightChange = useRef(onHeightChange);
  useEffect(() => {
    latestOnHeightChange.current = onHeightChange;
  }, [onHeightChange]);

  const latestLocked = useRef(locked);
  useEffect(() => {
    latestLocked.current = locked;
  }, [locked]);

  // When the focused reference height's position changes, update the height that is associated with
  // it
  const abortController = useRef<AbortController | null>(null);
  const onCalculateHeightAtReferenceHeight = useMemo(() => {
    return debounce(
      (referenceHeight: ReferenceHeight, position: FloorplanCoordinates) => {
        if (geotiffParseResults.status !== 'complete' || !heightMap) {
          return;
        }

        // If there's a request already in progress, abort it!
        if (abortController.current) {
          abortController.current.abort();
        }
        abortController.current = new AbortController();

        queryExactHeightAtPoint(
          position,
          heightMap,
          geotiffParseResults,
          abortController.current
        ).then((heightMetersValue) => {
          abortController.current = null;

          // Assuming that the focused reference height hasn't changed while the height map lookup was
          // running, update the value
          if (
            focusedReferenceHeight.current &&
            focusedReferenceHeight.current.id === referenceHeight.id
          ) {
            focusedReferenceHeight.current.heightMeters = {
              step: 'complete',
              value: heightMetersValue,
            };
          }

          if (latestOnHeightChange.current) {
            latestOnHeightChange.current(referenceHeight, heightMetersValue);
          }
        });
      },
      250
    );

    // I'm unable to put onHeightChange in this hook because I can't use `useCallback` in the parent
    // component (Editor) because it's a class component.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geotiffParseResults, heightMap]);

  // If any reference heights have empty height values in them, compute those
  useEffect(() => {
    for (const referenceHeight of referenceHeights) {
      if (referenceHeight.heightMeters.step !== 'empty') {
        continue;
      }

      onCalculateHeightAtReferenceHeight(
        referenceHeight,
        referenceHeight.position
      );
    }
  }, [referenceHeights, onCalculateHeightAtReferenceHeight]);

  return (
    <ObjectLayer
      objects={referenceHeights}
      extractId={(referenceHeight) => referenceHeight.id}
      onCreate={(getReferenceHeight) => {
        const container = new PIXI.Container();
        container.on('mousedown', (evt) => {
          if (!context.viewport.current) {
            return;
          }

          focusedReferenceHeight.current = {
            id: getReferenceHeight().id,
            position: getReferenceHeight().position,
            heightMeters: getReferenceHeight().heightMeters,
          };

          addDragHandler(
            context,
            getReferenceHeight().position,
            evt,
            (newPosition, rawMousePosition) => {
              if (!context.viewport.current) {
                return;
              }
              if (!focusedReferenceHeight.current) {
                return;
              }
              container.cursor = 'grabbing';

              focusedReferenceHeight.current.position = newPosition;

              focusedReferenceHeight.current.heightMeters = { step: 'loading' };
              onCalculateHeightAtReferenceHeight(
                getReferenceHeight(),
                newPosition
              );
            },
            () => {
              if (!focusedReferenceHeight.current) {
                return;
              }

              focusedReferenceHeight.current.heightMeters = { step: 'loading' };
              onCalculateHeightAtReferenceHeight(
                getReferenceHeight(),
                focusedReferenceHeight.current.position
              );

              container.cursor = 'grab';

              if (latestOnPositionChange.current) {
                latestOnPositionChange.current(
                  getReferenceHeight(),
                  focusedReferenceHeight.current.position
                );
              }
              focusedReferenceHeight.current = null;
            }
          );
        });

        const arrow = new PIXI.Sprite(arrowTexture);
        arrow.name = 'arrow';
        arrow.angle = 180;
        arrow.anchor.set(0.5, 0);
        arrow.x = 0;
        arrow.y = 1;
        container.addChild(arrow);

        const lengthLabel = new MetricLabel(
          getReferenceHeight().distanceLabelText ||
            referenceDisplayHeights[getReferenceHeight().id],
          { backgroundColor: pointColor }
        );
        lengthLabel.name = 'length-label';
        lengthLabel.x = 0;
        lengthLabel.y = -1 * (2 * REFERERENCE_HEIGHT_ARROW_HEAD_SIZE_PX - 4);
        container.addChild(lengthLabel);

        return container;
      }}
      onUpdate={(referenceHeight, container) => {
        if (!context.viewport.current) {
          return;
        }
        const isMovable =
          !latestLocked.current && latestOnPositionChange.current !== null;

        // Movable reference heights get a "grab" cursor.
        container.interactive = isMovable;
        container.cursor = isMovable ? 'grab' : 'default';

        // Hide reference rulers that are disabled
        container.renderable =
          referenceHeight.enabled &&
          // Show the height label only if not super zoomed out
          // This speeds up floorplan rendering noticably
          context.viewport.current.zoom > visibilityZoomThreshold;
        if (!container.renderable) {
          return;
        }

        const isHighlighted =
          highlightedObject &&
          highlightedObject.type === 'reference' &&
          highlightedObject.id === referenceHeight.id;

        const isBeingDragged =
          focusedReferenceHeight.current &&
          focusedReferenceHeight.current.id === referenceHeight.id;
        const position =
          isBeingDragged && focusedReferenceHeight.current
            ? focusedReferenceHeight.current.position
            : referenceHeight.position;

        const positionCoords = FloorplanCoordinates.toViewportCoordinates(
          position,
          context.floorplan,
          context.viewport.current
        );

        // Don't render reference lines that are outside of the viewport
        container.renderable = isWithinViewport(context, positionCoords, 0);
        if (!container.renderable) {
          return;
        }
        container.x = positionCoords.x;
        container.y = positionCoords.y;
        container.alpha = heightMap ? 1 : heightMapEmptyOpacity;

        const arrow = container.getChildByName('arrow') as PIXI.Sprite | null;
        if (!arrow) {
          return;
        }

        // If the arrow is highlighted then use the differently colored texture
        arrow.texture = isHighlighted ? arrowHighlightedTexture : arrowTexture;

        const lengthLabel = container.getChildByName(
          'length-label'
        ) as MetricLabel | null;
        if (!lengthLabel) {
          return;
        }

        const newLabelBackgroundColor = isHighlighted
          ? pointColorHighlighted
          : pointColor;
        if (newLabelBackgroundColor !== lengthLabel.options.backgroundColor) {
          lengthLabel.options.backgroundColor = newLabelBackgroundColor;
          lengthLabel.redrawTextBounds();
        }

        // If a custom text string is provided, then use that
        if (referenceHeight.distanceLabelText) {
          lengthLabel.setText(referenceHeight.distanceLabelText);

          // Otherwise if it's being dragged, render the value based off of the
          // focusedReferenceHeight ref
        } else if (isBeingDragged && focusedReferenceHeight.current) {
          lengthLabel.setText(
            renderHeightForDisplay(
              focusedReferenceHeight.current.heightMeters,
              context.lengthUnit
            )
          );

          // Default to the cached measurement values
        } else {
          lengthLabel.setText(referenceDisplayHeights[referenceHeight.id]);
        }
      }}
      onRemove={(referenceHeight, container) => {
        const lengthLabel = container.getChildByName(
          'length-label'
        ) as MetricLabel;
        lengthLabel.destroy();

        // NOTE: The arrow texture is shared between all reference lines, so when cleaning them
        // up, don't destroy the underlying texture
        const arrow = container.getChildByName('arrow') as PIXI.Sprite;
        arrow.destroy({ texture: false, baseTexture: false });
      }}
    />
  );
};

export default ReferenceHeightLayer;
