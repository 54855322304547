import React from "react";

const Integrations2 = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M9.5,2 C12.8843427,2 15.7451256,4.24162622 16.678598,7.32112804 C19.7583738,8.25487439 22,11.1156573 22,14.5 C22,18.6421356 18.6421356,22 14.5,22 C11.1156573,22 8.25487439,19.7583738 7.32140198,16.678872 C4.24162622,15.7451256 2,12.8843427 2,9.5 C2,5.35786438 5.35786438,2 9.5,2 Z M16.998366,9.59887466 L16.9961464,9.74273105 C16.8680175,13.7725256 13.5609173,17 9.5,17 L9.59887466,16.998366 C10.508932,18.7800922 12.3620036,20 14.5,20 C17.5375661,20 20,17.5375661 20,14.5 C20,12.3620036 18.7800922,10.508932 16.998366,9.59887466 Z M14.5,9 C11.4624339,9 9,11.4624339 9,14.5 C9,14.6612078 9.00693562,14.8207957 9.02052474,14.9784815 C9.17920434,14.9930644 9.33879222,15 9.5,15 C12.5375661,15 15,12.5375661 15,9.5 C15,9.33879222 14.9930644,9.17920434 14.9794753,9.02151848 C14.8207957,9.00693562 14.6612078,9 14.5,9 Z M9.5,4 C6.46243388,4 4,6.46243388 4,9.5 C4,11.6379964 5.21990783,13.491068 7.00163397,14.4011253 C7.05424076,10.3038933 10.3912876,7 14.5,7 L14.4011253,7.00163397 C13.491068,5.21990783 11.6379964,4 9.5,4 Z"
      />
    </g>
  </svg>
);

export default Integrations2;
