import { css } from '@emotion/react';
import * as React from 'react';

const FillCenter: React.FC = (props) => {
  return (
    <div
      css={css`
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
      `}
    >
      {props.children}
    </div>
  );
};

export default FillCenter;
