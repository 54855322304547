import * as React from 'react';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { List as ReactMovableList } from 'react-movable';
import { Icons } from '@density/dust';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

import { Action } from './actions';
import { State } from './state';
import styles from './styles.module.scss';

import Button from 'components/button';
import TextField from 'components/text-field';
import HorizontalForm from 'components/horizontal-form';
import { DarkTheme } from 'components/theme';
import NotesBox from 'components/notes-box';
import ImageUploader from 'components/image-uploader';
import Panel, {
  PanelHeader,
  PanelBody,
  PanelBodySection,
  PanelActions,
} from 'components/panel';

import PhotoGroup, { PhotoGroupPhoto } from 'lib/photo-group';

const Photo: React.FunctionComponent<{
  photoGroup: PhotoGroup;
  photo: PhotoGroupPhoto;
  canBeDragged: boolean;
  dragging: boolean;
  onChangeName: (name: PhotoGroupPhoto['name']) => void;
  onDelete: () => void;
}> = ({
  photoGroup,
  photo,
  canBeDragged,
  dragging,
  onChangeName,
  onDelete,
}) => {
  const [workingName, setWorkingName] = useState(photo.name);
  useEffect(() => setWorkingName(photo.name), [photo.name]);

  return (
    <div
      className={classnames(styles.photo, {
        [styles.draggable]: canBeDragged,
        [styles.dragging]: dragging,
      })}
      style={{
        backgroundImage: `url(${
          photo.image.dirty ? photo.image.dataUrl : photo.image.url
        })`,
      }}
      data-cy={`photo-${photo.id}`}
    >
      <div className={styles.photoImageShadow} />
      <DarkTheme>
        <div className={styles.photoControls}>
          <div className={styles.photoControlsName}>
            <TextField
              value={workingName}
              onChange={(e) => setWorkingName(e.currentTarget.value)}
              width="100%"
              size="small"
              data-cy="photo-name"
              onBlur={() => onChangeName(workingName)}
            />
          </div>
          <div className={styles.photoControlsActions}>
            <HorizontalForm size="small">
              <Button
                size="small"
                type="cleared"
                width={24}
                trailingIcon={<Icons.OpenExternalArrow size={14} />}
                onClick={() => {
                  if (photo.image.dirty) {
                    // Chrome and safari at least seem to have problems opening a very long data url
                    // with `window.open`. So instead, open an empty window and append an image tag
                    // containing the data url.
                    const popup = window.open();
                    if (!popup) {
                      return;
                    }
                    const imageOnPopup = popup.document.createElement('img');
                    imageOnPopup.src = photo.image.dataUrl;
                    popup.document.body.appendChild(imageOnPopup);
                    popup.document.title = 'Unsaved Photo';
                  } else {
                    // If there's a plain URL available, then just open it!
                    window.open(photo.image.url, '_blank');
                  }
                }}
              />
              <Button
                type="cleared"
                size="small"
                width={24}
                trailingIcon={<Icons.Trash size={18} />}
                onClick={() => onDelete()}
                data-cy="delete-photo"
              />
            </HorizontalForm>
          </div>
        </div>
      </DarkTheme>
    </div>
  );
};

const FocusedPhotoGroupPanel: React.FunctionComponent<{
  state: State;
  photoGroup: PhotoGroup;
  dispatch: React.Dispatch<Action>;
  onChangeName: (name: PhotoGroup['name']) => void;
  onChangeNotes: (notes: PhotoGroup['notes']) => void;
  onChangeLocked: (locked: PhotoGroup['locked']) => void;
  onDelete: () => void;
  onCreatePhoto: (image: HTMLImageElement, dataUrl: string, file: File) => void;
  onChangePhotoName: (
    photo: PhotoGroupPhoto,
    newName: PhotoGroupPhoto['name']
  ) => void;
  onDeletePhoto: (photo: PhotoGroupPhoto) => void;
}> = ({
  state,
  photoGroup,
  dispatch,
  onChangeName,
  onChangeNotes,
  onChangeLocked,
  onDelete,
  onCreatePhoto,
  onChangePhotoName,
  onDeletePhoto,
}) => {
  const [workingName, setWorkingName] = useState(photoGroup.name);
  useEffect(() => setWorkingName(photoGroup.name), [photoGroup.name]);

  return (
    <div data-cy="focused-photo-group-panel" data-cy-locked={photoGroup.locked}>
      <Panel position="top-right" right={16}>
        <PanelHeader>
          <div
            className={classnames([
              styles.inputInline,
              styles.panelHeaderTextboxActionWrapper,
            ])}
          >
            <TextField
              type="text"
              size="medium"
              value={workingName}
              placeholder={photoGroup.name}
              disabled={state.locked || photoGroup.locked}
              onChange={(evt) => setWorkingName(evt.currentTarget.value)}
              leadingIcon={<Icons.FolderImage size={18} color={dust.Blue400} />}
              width="100%"
              data-cy="photo-group-name"
              onBlur={() => onChangeName(workingName)}
            />
          </div>
        </PanelHeader>

        <PanelBody>
          <PanelBodySection>
            <NotesBox
              notes={photoGroup.notes}
              disabled={state.locked || photoGroup.locked}
              onNotesEdited={(notes) => onChangeNotes(notes)}
              data-cy="photo-group-notes"
            />
          </PanelBodySection>

          {!state.locked ? (
            <PanelBodySection>
              <ImageUploader onUploadImage={onCreatePhoto}>
                {(trigger) =>
                  photoGroup.photos.length === 0 ? (
                    <div onClick={trigger} className={styles.photoEmptyButton}>
                      <Icons.ImageAddAlt />
                      &nbsp;Take / Upload a photo
                    </div>
                  ) : (
                    <Button
                      size="medium"
                      type="outlined"
                      leadingIcon={
                        <Icons.ImageAddAlt size={18} color={dust.Blue400} />
                      }
                      onClick={trigger}
                      width="100%"
                    >
                      Take / Upload a photo
                    </Button>
                  )
                }
              </ImageUploader>
            </PanelBodySection>
          ) : null}

          <PanelBodySection>
            {/* TODO: uncomment the below once the dragging interface is supported by the backend */}
            {/* {photoGroup.locked ? ( */}
            {true || photoGroup.locked ? (
              photoGroup.photos.map((photo) => (
                <div style={{ marginBottom: 8 }} key={photo.id}>
                  <Photo
                    photo={photo}
                    photoGroup={photoGroup}
                    canBeDragged={false}
                    dragging={false}
                    onChangeName={(name) => onChangePhotoName(photo, name)}
                    onDelete={() => onDeletePhoto(photo)}
                  />
                </div>
              ))
            ) : (
              <ReactMovableList
                values={photoGroup.photos}
                onChange={({ oldIndex, newIndex }) => {
                  dispatch({
                    type: 'photoGroup.photos.reorder',
                    id: photoGroup.id,
                    oldIndex,
                    newIndex,
                  });
                }}
                renderList={({ children, props }) => (
                  <div {...props}>{children}</div>
                )}
                renderItem={({ value, props, isDragged }) => (
                  <div {...props} style={{ ...props.style, marginBottom: 8 }}>
                    <Photo
                      photo={value}
                      photoGroup={photoGroup}
                      canBeDragged={true}
                      dragging={isDragged}
                      onChangeName={(name) => onChangePhotoName(value, name)}
                      onDelete={() => onDeletePhoto(value)}
                    />
                  </div>
                )}
              />
            )}
          </PanelBodySection>

          {!state.locked ? (
            <PanelBodySection>
              <div className={styles.photoUploadHint}>
                Not sure what kind of photos to take?{' '}
                <a
                  href="https://support.density.io/hc/en-us/articles/4405398293275"
                  rel="noreferrer"
                  target="_blank"
                >
                  Read the guidelines.
                </a>
              </div>
            </PanelBodySection>
          ) : null}
        </PanelBody>

        <PanelActions
          left={
            <HorizontalForm size="medium">
              <Button
                type="cleared"
                size="medium"
                trailingIcon={<Icons.Trash size={18} />}
                danger
                disabled={state.locked || photoGroup.locked}
                onClick={() => onDelete()}
                data-cy="delete-photo-group"
              />
              <Button
                type="cleared"
                size="medium"
                trailingIcon={
                  photoGroup.locked ? (
                    <Icons.LockClosed
                      size={18}
                      color={!state.locked ? dust.Yellow400 : 'currentColor'}
                    />
                  ) : (
                    <Icons.LockOpen size={18} />
                  )
                }
                onClick={() => onChangeLocked(!photoGroup.locked)}
                disabled={state.locked}
                data-cy="lock-button"
              />
              {/*
              <Button
                type="cleared"
                size="medium"
                trailingIcon={<Icons.CopyAlt size={18} />}
                onClick={() =>
                  dispatch({ type: 'menu.duplicatePhotoGroup', id: photoGroup.id })
                }
              />
              */}
            </HorizontalForm>
          }
          right={
            <Button
              type="cleared"
              size="medium"
              onClick={() =>
                dispatch({ type: 'photoGroup.dismiss', id: photoGroup.id })
              }
            >
              Done
            </Button>
          }
        />
      </Panel>
    </div>
  );
};

export default FocusedPhotoGroupPanel;
