// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-switch-1I8OO4xl label{outline:0;display:inline-block;width:40px;height:24px;position:relative;cursor:pointer;user-select:none;background-color:#C0C6CC;border-radius:24px;transition:all 200ms ease}.dui-styles-module-switch-1I8OO4xl label:after{position:relative;display:block;content:\"\";width:22px;height:22px;box-sizing:border-box;left:0px;top:1px;border-radius:50%;background-color:#fff;border:2px solid #C0C6CC;transition:all 200ms ease}.dui-styles-module-switch-1I8OO4xl input[type=checkbox]{display:none}.dui-styles-module-switch-1I8OO4xl input[type=checkbox]:checked+label{background-color:#1A79FF}.dui-styles-module-switch-1I8OO4xl input[type=checkbox]:checked+label:after{left:18px;border-color:#1A79FF}.dui-styles-module-switch-1I8OO4xl.dui-styles-module-disabled-1zgO9OiU input[type=checkbox]+label,.dui-styles-module-switch-1I8OO4xl.dui-styles-module-disabled-1zgO9OiU input[type=checkbox]:checked+label{pointer-events:none;background-color:#E0E3E6}.dui-styles-module-switch-1I8OO4xl.dui-styles-module-disabled-1zgO9OiU input[type=checkbox]:checked+label:after{border-color:#E0E3E6}\n", ""]);
// Exports
exports.locals = {
	"switch": "dui-styles-module-switch-1I8OO4xl",
	"disabled": "dui-styles-module-disabled-1zgO9OiU"
};
module.exports = exports;
