import * as React from 'react';

const DeskOccupied = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.70668 7C9.42096 7 10 7.58203 10 8.3C10 8.3716 9.99411 8.44309 9.9824 8.51372L9.72492 11H14.9995V9H11.9996V4H19.9993V9H16.9994V11H22V13H19.9993V22H17.9993V19.5H5.99983V22H4.00075V13H2V11H4.30669L4.0178 8.51372C3.90037 7.80552 4.37634 7.13572 5.0809 7.01769C5.15117 7.00592 5.22228 7 5.29352 7H8.70668ZM17.9993 13V17.5H5.99983V13H17.9993Z"
      fill={color}
    />
    <path
      d="M7 2C8.10457 2 9 2.89543 9 4C9 5.10457 8.10457 6 7 6C5.89543 6 5 5.10457 5 4C5 2.89543 5.89543 2 7 2Z"
      fill={color}
    />
  </svg>
);

export default DeskOccupied;
