import { Fragment, useEffect, useMemo } from 'react';
import * as React from 'react';
import * as PIXI from 'pixi.js';
import { Teal400, Gray900 } from '@density/dust/dist/tokens/dust.tokens';

import {
  Layer,
  ObjectLayer,
  useFloorplanLayerContext,
  toRawHex,
  isWithinViewport,
} from 'components/floorplan';
import { FloorplanLayerContextData } from 'components/floorplan/types';

import {
  FloorplanCoordinates,
  ViewportCoordinates,
  computeLineSegmentsInBoundingRegion,
} from 'lib/geometry';
import { distance, radiansToDegrees } from 'lib/math';
import WallSegment from 'lib/wall-segment';

const WALL_SEGMENT_LINE_WIDTH_PX = 1;

const WALL_SEGMENT_DOORWAY_LINE_WIDTH_PX = WALL_SEGMENT_LINE_WIDTH_PX;
const WALL_SEGMENT_DOORWAY_END_MARK_HEIGHT_PX = 16;
const WALL_SEGMENT_DOORWAY_RAW_COLOR = toRawHex(Teal400);

function generateDoorwayEndpointTexture(app: FloorplanLayerContextData['app']) {
  const gr = new PIXI.Graphics();
  gr.lineStyle({
    width: WALL_SEGMENT_DOORWAY_LINE_WIDTH_PX,
    color: WALL_SEGMENT_DOORWAY_RAW_COLOR,
    cap: PIXI.LINE_CAP.ROUND,
  });

  // Draw crosshairs in endpoint
  gr.moveTo(0, -1 * (WALL_SEGMENT_DOORWAY_END_MARK_HEIGHT_PX / 2));
  gr.lineTo(0, WALL_SEGMENT_DOORWAY_END_MARK_HEIGHT_PX / 2);

  return app.renderer.generateTexture(gr);
}

type RenderableDoorway = {
  id: WallSegment['id'];
  lengthInMeters: number;
  center: FloorplanCoordinates;
  angleInDegrees: number;
};

function generateRenderableDoorway(
  wallSegment: WallSegment & { isDoorway: true }
): RenderableDoorway {
  return {
    id: wallSegment.id,
    lengthInMeters: distance(wallSegment.positionA, wallSegment.positionB),
    center: FloorplanCoordinates.create(
      (wallSegment.positionB.x + wallSegment.positionA.x) / 2,
      (wallSegment.positionB.y + wallSegment.positionA.y) / 2
    ),
    angleInDegrees: radiansToDegrees(
      Math.atan2(
        wallSegment.positionB.y - wallSegment.positionA.y,
        wallSegment.positionB.x - wallSegment.positionA.x
      )
    ),
  };
}

// When given a list of wall segments, the wall segment layer renders them on the floorplan. Any wal
// segments which have a type of "doorway" are rendered in a special way.
const WallSegmentLayer: React.FunctionComponent<{
  walls: Array<WallSegment>;
}> = ({ walls }) => {
  const context = useFloorplanLayerContext();

  const nonDoorwayWallSegments = useMemo(() => {
    return walls.filter((segment) => !segment.isDoorway);
  }, [walls]);

  // Generate a texture that is drawn at the end of each wall segment that is a doorway. This helps
  // make these visually distinct from regular wall segments.
  const doorwayEndpointTexture = useMemo(
    () => generateDoorwayEndpointTexture(context.app),
    [context.app]
  );

  // Generate a list of all doorways to render on the layer.
  const doorways = useMemo(() => {
    const doorwayWallSegments = walls.filter(
      (segment): segment is WallSegment & { isDoorway: true } =>
        segment.isDoorway
    );
    return doorwayWallSegments.map(generateRenderableDoorway);
  }, [walls]);

  // Create all the pixi objects that this layer needs to work
  useEffect(() => {
    const container = new PIXI.Container();
    container.name = 'wall-segment-layer';

    // This graphics element has all of the wall segments drawn to it
    const wallLines = new PIXI.Graphics();
    wallLines.name = 'wall-segment-lines';
    container.addChild(wallLines);

    // Add this layer right above the base image layer and height map layer
    context.app.stage.addChildAt(container, 1);

    return () => {
      context.app.stage.removeChild(container);
    };
  }, [context.app.stage]);

  return (
    <Fragment>
      <ObjectLayer
        objects={doorways}
        extractId={(doorway) => doorway.id}
        onCreate={() => {
          const container = new PIXI.Container();

          const left = new PIXI.Sprite(doorwayEndpointTexture);
          left.name = 'left';
          left.anchor.set(0.5, 0.5);
          container.addChild(left);

          const right = new PIXI.Sprite(doorwayEndpointTexture);
          right.name = 'right';
          right.angle = 180;
          right.anchor.set(0.5, 0.5);
          container.addChild(right);

          const middle = new PIXI.Sprite(PIXI.Texture.WHITE);
          middle.tint = WALL_SEGMENT_DOORWAY_RAW_COLOR;
          middle.name = 'middle';
          middle.height = WALL_SEGMENT_DOORWAY_LINE_WIDTH_PX;
          middle.anchor.set(0.5, 0.5);
          container.addChild(middle);

          return container;
        }}
        onUpdate={(doorway, container) => {
          if (!context.viewport.current) {
            return;
          }

          const left = container.getChildByName('left') as
            | PIXI.Graphics
            | undefined;
          if (!left) {
            return;
          }

          const right = container.getChildByName('right') as
            | PIXI.Graphics
            | undefined;
          if (!right) {
            return;
          }

          const middle = container.getChildByName('middle') as
            | PIXI.Graphics
            | undefined;
          if (!middle) {
            return;
          }

          const viewportCoords = FloorplanCoordinates.toViewportCoordinates(
            doorway.center,
            context.floorplan,
            context.viewport.current
          );

          const doorwayLengthInPixels =
            doorway.lengthInMeters *
            context.floorplan.scale *
            context.viewport.current.zoom;

          // Only render doorways that are in the screen bounds
          container.renderable = isWithinViewport(
            context,
            viewportCoords,
            -1 * (doorwayLengthInPixels / 2)
          );
          if (!container.renderable) {
            return;
          }

          // Ensure the doorway is positioned at the right location and at the right angle
          container.x = viewportCoords.x;
          container.y = viewportCoords.y;
          container.angle = doorway.angleInDegrees;

          // Adjust the doorway sprite width to be the proper width
          middle.width = doorwayLengthInPixels;

          // Adjust the sprites on either end of the doorway to take into account the proper width
          // of the doorway
          left.x = -1 * (doorwayLengthInPixels / 2);
          right.x = doorwayLengthInPixels / 2;
        }}
        onRemove={(doorway, container) => {
          const middle = container.getChildByName('middle') as
            | PIXI.Sprite
            | undefined;
          if (middle) {
            middle.destroy();
          }

          // NOTE: The endpoint texture is shared between all doorway elements, so when cleaning them
          // up, don't destroy the underlying texture
          const left = container.getChildByName('left') as
            | PIXI.Sprite
            | undefined;
          if (left) {
            left.destroy({ texture: false, baseTexture: false });
          }
          const right = container.getChildByName('right') as
            | PIXI.Sprite
            | undefined;
          if (right) {
            right.destroy({ texture: false, baseTexture: false });
          }
        }}
      />
      <Layer
        onAnimationFrame={() => {
          if (!context.viewport.current) {
            return;
          }
          const viewport = context.viewport.current;

          const container = context.app.stage.getChildByName(
            'wall-segment-layer'
          ) as PIXI.Container | null;
          if (!container) {
            return;
          }

          const wallLines = container.getChildByName(
            'wall-segment-lines'
          ) as PIXI.Graphics | null;
          if (!wallLines) {
            return;
          }

          // Draw all wall lines
          const linesToRender = computeLineSegmentsInBoundingRegion(
            nonDoorwayWallSegments.map(
              (segment): [ViewportCoordinates, ViewportCoordinates] => {
                const positionAViewport =
                  FloorplanCoordinates.toViewportCoordinates(
                    segment.positionA,
                    context.floorplan,
                    viewport
                  );
                const positionBViewport =
                  FloorplanCoordinates.toViewportCoordinates(
                    segment.positionB,
                    context.floorplan,
                    viewport
                  );
                return [positionAViewport, positionBViewport];
              }
            ),
            ViewportCoordinates.create(0, 0),
            ViewportCoordinates.create(viewport.width, viewport.height)
          );

          wallLines.clear();
          wallLines.lineStyle({
            width: WALL_SEGMENT_LINE_WIDTH_PX,
            color: toRawHex(Gray900),
            join: PIXI.LINE_JOIN.ROUND,
            cap: PIXI.LINE_CAP.ROUND,
          });
          for (const [a, b] of linesToRender) {
            wallLines.moveTo(a.x, a.y);
            wallLines.lineTo(b.x, b.y);
          }
        }}
      />
    </Fragment>
  );
};

export default WallSegmentLayer;
