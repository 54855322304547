import * as React from 'react';

const Cafe = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      d="M9 3V10H8V3L6 3L6 10H5L5 3L3 3L3 12H6L6 21H8L8 12L11 12L11 3L9 3Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 3C19.2091 3 21 4.79086 21 7V9C21 10.8638 19.7252 12.4299 18 12.874L18 21H16L16 12.874C14.2748 12.4299 13 10.8638 13 9L13 7C13 4.79086 14.7909 3 17 3ZM17 11C18.1046 11 19 10.1046 19 9V7C19 5.89543 18.1046 5 17 5C15.8954 5 15 5.89543 15 7L15 9C15 10.1046 15.8954 11 17 11Z"
      fill={color}
    />
  </svg>
);

export default Cafe;
