import * as React from 'react';

const Gym = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      d="M5 6H7L7 11L17 11V6H19V8L21 8V11H22V13H21V16H19L19 18L17 18V13L7 13L7 18H5V16H3L3 13H2L2 11H3L3 8H5V6Z"
      fill={color}
    />
  </svg>
);

export default Gym;
