import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CoreOrganization } from '@densityco/lib-api-types';
import { CoreSpace } from '@densityco/lib-api-types';

import Editor from 'components/editor/editor';
import { useAppSelector } from 'redux/store';
import { FloorplanV2Plan } from 'lib/api';

const FloorPlanning: React.FC<{
  plan: FloorplanV2Plan;
  planImage: HTMLImageElement | null;
  onEditName: (name: CoreSpace['name']) => void;
  editNameLoading: boolean;
  onEditStatus: (status: CoreSpace['status']) => Promise<void>;
  editStatusLoading: boolean;
}> = ({
  plan,
  planImage,
  onEditName,
  editNameLoading,
  onEditStatus,
  editStatusLoading,
}) => {
  const history = useHistory();
  const params = useParams<{ organizationId?: CoreOrganization['id'] }>();

  const densityAPIClient = useAppSelector(
    (state) => state.auth.densityAPIClient
  );

  if (!densityAPIClient) {
    return null;
  }

  return (
    <Editor
      client={densityAPIClient}
      plan={plan}
      planImage={planImage}
      onNavigateBackToList={(newTab) => {
        const path = `/${params.organizationId}/buildings`;
        if (newTab) {
          window.open(`${window.location.origin}${path}`);
        } else {
          history.push(path);
        }
      }}
      editNameLoading={editNameLoading}
      onEditName={onEditName}
      editStatusLoading={editStatusLoading}
      onEditStatus={onEditStatus}
    />
  );
};

export default FloorPlanning;
