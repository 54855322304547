// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-appFrame-2VNPDDbG{display:flex;height:100%;width:100%;background-color:#fff;overflow:hidden}\n", ""]);
// Exports
exports.locals = {
	"appFrame": "dui-styles-module-appFrame-2VNPDDbG"
};
module.exports = exports;
