import * as React from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';

import { useTheme } from 'components/theme';

const AppBar: React.FunctionComponent<{
  title?: React.ReactNode;
  actions?: React.ReactNode;
  leftActions?: React.ReactNode;
}> = ({ title, actions, leftActions }) => {
  const theme = useTheme();
  return (
    <div
      className={classnames(styles.appBar, {
        [styles.dark]: theme === 'dark',
      })}
    >
      {leftActions}
      <div className={styles.title}>{title}</div>
      {actions}
    </div>
  );
};

export default AppBar;
