import React from 'react';

const ImageUploadAlt = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 24}
    height={height || 24}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M19 2.586l3.707 3.707-1.414 1.414L20 6.414V11h-2V6.414l-1.293 1.293-1.414-1.414L19 2.586z"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M14 4H2v18h18v-9h-2v5.197l-3.845-5.766-4.282 4.282-3.105-2.07L4 18.333V6h10V4zm-.155 11.57L16.798 20H5.25l1.982-2.643 2.895 1.93 3.718-3.717z"
      clipRule="evenodd"
    ></path>
    <path
      fill={color}
      fillRule="evenodd"
      d="M11 10a3 3 0 11-6 0 3 3 0 016 0zm-2 0a1 1 0 11-2 0 1 1 0 012 0z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default ImageUploadAlt;
