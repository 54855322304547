import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { useTheme } from 'components/theme';
import styles from './styles.module.scss';

type PopupListItemBaseProps = {
  leadingIcon?: React.ReactNode;
  active?: boolean;
  danger?: boolean;
  disabled?: boolean;
  minWidth?: React.ReactText;
  size?: 'small' | 'medium';
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  'data-cy'?: string;
};
type PopupListItemNormalProps = PopupListItemBaseProps & { type?: 'normal' };
type PopupListItemATagProps = PopupListItemBaseProps & {
  type: 'atag';
  href: string;
  target?: string;
};
type PopupListItemLinkProps = PopupListItemBaseProps & {
  type: 'link';
  to: string;
};

type PopupListItemProps =
  | PopupListItemNormalProps
  | PopupListItemATagProps
  | PopupListItemLinkProps;

const PopupListItem: React.FunctionComponent<PopupListItemProps> = (props) => {
  const theme = useTheme();
  switch (props.type) {
    case 'link':
      return (
        <Link
          className={classnames(styles.listItem, {
            [styles.small]: props.size === 'small',
            [styles.dark]: theme === 'dark',
            [styles.active]: props.active,
            [styles.danger]: props.danger,
            [styles.disabled]: props.disabled,
          })}
          to={props.to}
          style={{ minWidth: props.minWidth }}
          onClick={() => props.onClick && props.onClick()}
          onMouseEnter={() => props.onMouseEnter && props.onMouseEnter()}
          onMouseLeave={() => props.onMouseLeave && props.onMouseLeave()}
          data-cy={props['data-cy']}
        >
          {props.leadingIcon ? (
            <div className={styles.listItemIcon}>{props.leadingIcon}</div>
          ) : null}
          {props.children}
        </Link>
      );
    case 'atag':
      return (
        <a
          className={classnames(styles.listItem, {
            [styles.small]: props.size === 'small',
            [styles.dark]: theme === 'dark',
            [styles.active]: props.active,
            [styles.danger]: props.danger,
            [styles.disabled]: props.disabled,
          })}
          href={props.href}
          target={props.target}
          style={{ minWidth: props.minWidth }}
          onClick={() => props.onClick && props.onClick()}
          onMouseEnter={() => props.onMouseEnter && props.onMouseEnter()}
          onMouseLeave={() => props.onMouseLeave && props.onMouseLeave()}
          data-cy={props['data-cy']}
        >
          {props.leadingIcon ? (
            <div className={styles.listItemIcon}>{props.leadingIcon}</div>
          ) : null}
          {props.children}
        </a>
      );
    case 'normal':
    default:
      const DivOrButton = props.onClick ? 'button' : 'div';
      return (
        <DivOrButton
          className={classnames(styles.listItem, {
            [styles.small]: props.size === 'small',
            [styles.dark]: theme === 'dark',
            [styles.active]: props.active,
            [styles.danger]: props.danger,
            [styles.disabled]: props.disabled,
          })}
          style={{ minWidth: props.minWidth }}
          onClick={() => props.onClick && props.onClick()}
          onMouseEnter={() => props.onMouseEnter && props.onMouseEnter()}
          onMouseLeave={() => props.onMouseLeave && props.onMouseLeave()}
          data-cy={props['data-cy']}
        >
          {props.leadingIcon ? (
            <div className={styles.listItemIcon}>{props.leadingIcon}</div>
          ) : null}
          {props.children}
        </DivOrButton>
      );
  }
};

export default PopupListItem;
