import React from "react";

const Listening = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M12,14.2686012 C13.5976809,14.2686012 14.9036609,15.5175212 14.9949073,17.0923284 L15,17.2686012 L15,22.2686012 L13,22.2686012 L13,17.2686012 C13,16.7557653 12.6139598,16.333094 12.1166211,16.2753289 L12,16.2686012 L2,16.2686012 L2,14.2686012 L12,14.2686012 Z M17.2216451,2 L18.7783549,3.255649 C17.6518828,4.65220982 17,6.56296578 17,8.61394011 C17,10.5509714 17.5814634,12.3629314 18.5950343,13.7347741 L18.7783549,13.9722312 L17.2216451,15.2278802 C15.8034893,13.4697002 15,11.1145653 15,8.61394011 C15,6.11331494 15.8034893,3.75818004 17.2216451,2 Z M20.137284,4.57669864 L21.7783549,5.71988982 C21.2627474,6.46005351 20.9578194,7.494962 20.9578194,8.61394011 C20.9578194,9.73291821 21.2627474,10.7678267 21.7783549,11.5079904 L21.7783549,11.5079904 L20.137284,12.6511816 C19.3805776,11.5649163 18.9578194,10.1300986 18.9578194,8.61394011 C18.9578194,7.09778166 19.3805776,5.66296387 20.137284,4.57669864 L20.137284,4.57669864 Z M7,2.26860119 C9.76142375,2.26860119 12,4.50717744 12,7.26860119 C12,10.0300249 9.76142375,12.2686012 7,12.2686012 C4.23857625,12.2686012 2,10.0300249 2,7.26860119 C2,4.50717744 4.23857625,2.26860119 7,2.26860119 Z M7,4.26860119 C5.34314575,4.26860119 4,5.61174694 4,7.26860119 C4,8.92545544 5.34314575,10.2686012 7,10.2686012 C8.65685425,10.2686012 10,8.92545544 10,7.26860119 C10,5.61174694 8.65685425,4.26860119 7,4.26860119 Z"
      />
    </g>
  </svg>
);

export default Listening;
