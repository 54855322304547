import * as React from 'react';
import { Fragment, useEffect, useState, useMemo } from 'react';
import { AxiosInstance } from 'axios';
import classnames from 'classnames';
import { Icons } from '@density/dust';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { CoreSpaceLabel } from '@densityco/lib-api-types';

import { Action } from './actions';
import { State } from './state';
import styles from './styles.module.scss';
import { toast } from 'react-toastify';

import {
  displayLength,
  LengthUnit,
  displayCircularArea,
  displayBoxArea,
  displayPolygonalArea,
} from 'lib/units';
import { useTreatment } from 'contexts/treatments';
import Button from 'components/button';
import TextField from 'components/text-field';
import SelectField from 'components/select-field';
import MultiTagField from 'components/multi-tag-field';
import HorizontalForm from 'components/horizontal-form';
import FillCenter from 'components/fill-center';
import Panel, { PanelHeader, PanelBody, PanelActions } from 'components/panel';
import ValidationsList from 'components/validations-list';

import { CoreAPI, isFloorplanSpaceId } from 'lib/api';
import Space, { SpaceValidation } from 'lib/space';
import { SensorValidation } from 'lib/sensor';
import { SPLITS } from 'lib/treatments';

const FocusedSpacePanel: React.FunctionComponent<{
  state: State;
  space: Space;
  client: AxiosInstance;
  displayUnit: LengthUnit;
  dispatch: React.Dispatch<Action>;
  spaceHierarchyDataLoadingStatus: 'pending' | 'loading' | 'complete' | 'error';
  validations: Map<
    string,
    'empty' | 'loading' | Array<SpaceValidation | SensorValidation>
  >;
  onChangeName: (name: Space['name']) => void;
  onChangeLocked: (locked: Space['locked']) => void;
  onChangeSpaceCapacity: (capacity: Space['coreSpaceCapacity']) => void;
  onChangeSpaceFunction: (spaceFunction: Space['coreSpaceFunction']) => void;
  onChangeSpaceLabels: (
    labelsToAdd: Array<Pick<CoreSpaceLabel, 'id' | 'name'>>,
    labelsToRemove: Array<Pick<CoreSpaceLabel, 'id' | 'name'>>
  ) => void;
  onDelete: () => void;
}> = ({
  state,
  space,
  client,
  displayUnit,
  dispatch,
  spaceHierarchyDataLoadingStatus,
  validations,
  onChangeName,
  onChangeLocked,
  onChangeSpaceCapacity,
  onChangeSpaceFunction,
  onChangeSpaceLabels,
  onDelete,
}) => {
  const isValidationEnabled = useTreatment(SPLITS.VALIDATION);

  const [workingName, setWorkingName] = useState(space.name);
  useEffect(() => setWorkingName(space.name), [space.name]);

  const [workingCapacity, setWorkingCapacity] = useState('');
  const [workingCapacityError, setWorkingCapacityError] = useState(false);
  useEffect(() => {
    if (space.coreSpaceCapacity) {
      setWorkingCapacity(`${space.coreSpaceCapacity}`);
    } else {
      setWorkingCapacity('');
    }
  }, [space.coreSpaceCapacity]);

  // Fetch a list of all space functions to use in the dropdown
  const [spaceFunctions, setSpaceFunctions] = useState<
    | { status: 'pending' }
    | { status: 'loading'; abortController: AbortController }
    | {
        status: 'complete';
        data: Array<{
          function: string;
          function_display_name: string;
          program: string;
          program_display_name: string;
        }>;
      }
    | { status: 'error' }
  >(
    // FIXME: this cached space functions thing is really gross and probably is going to break
    // someday... but the idea is to somehow cache this data so that when the second focused panel
    // opens, it won't need to refetch this data
    (window as any).CACHED_SPACE_FUNCTIONS
      ? { status: 'complete', data: (window as any).CACHED_SPACE_FUNCTIONS }
      : { status: 'pending' }
  );
  useEffect(() => {
    if (spaceFunctions.status !== 'pending') {
      return;
    }
    const abortController = new AbortController();

    setSpaceFunctions({ status: 'loading', abortController });
    CoreAPI.getSpaceFunctions(client, abortController.signal)
      .then((response) => {
        (window as any).CACHED_SPACE_FUNCTIONS = response.data;
        setSpaceFunctions({
          status: 'complete',
          data: response.data,
        });
      })
      .catch((err) => {
        if (err.name === 'CanceledError') {
          return;
        }
        setSpaceFunctions({ status: 'error' });
      });

    return () => {
      abortController.abort();
      setSpaceFunctions({ status: 'pending' });
    };
    // FIXME: Ignore dependencies on this because adding one on spaceFunctions.status will cause this
    // hook to run way too often, and I can't figure out an easy way to work around this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  // Fetch a list of space labels to use for autocompletion
  const [spaceLabels, setSpaceLabels] = useState<
    | { status: 'pending' }
    | { status: 'loading'; abortController: AbortController }
    | {
        status: 'complete';
        data: Array<Pick<CoreSpaceLabel, 'id' | 'name'>>;
      }
    | { status: 'error' }
  >(
    // FIXME: this cached space labels thing is really gross and probably is going to break
    // someday... but the idea is to somehow cache this data so that when the second focused panel
    // opens, it won't need to refetch this data
    (window as any).CACHED_SPACE_LABELS
      ? { status: 'complete', data: (window as any).CACHED_SPACE_LABELS }
      : { status: 'pending' }
  );
  useEffect(() => {
    if (spaceLabels.status !== 'pending') {
      return;
    }
    const abortController = new AbortController();

    setSpaceLabels({ status: 'loading', abortController });
    CoreAPI.getSpaceLabels(client, abortController.signal)
      .then((response) => {
        const data = response.data.sort((a, b) => a.name.localeCompare(b.name));

        (window as any).CACHED_SPACE_LABELS = data;
        setSpaceLabels({ status: 'complete', data });
      })
      .catch((err) => {
        if (err.name === 'CanceledError') {
          return;
        }
        setSpaceLabels({ status: 'error' });
      });

    return () => {
      abortController.abort();
      setSpaceLabels({ status: 'pending' });
    };
    // FIXME: Ignore dependencies on this because adding one on spaceLabels.status will cause this
    // hook to run way too often, and I can't figure out an easy way to work around this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client]);

  const spaceValidations = useMemo(() => {
    const spaceValidations = validations.get(space.id);
    if (
      !spaceValidations ||
      spaceValidations === 'empty' ||
      spaceValidations === 'loading'
    ) {
      return [];
    }
    return spaceValidations.filter(
      (v): v is SpaceValidation => v.objectType === 'space'
    );
  }, [space, validations]);

  let circleDiameter: string | null = null;
  let boxWidth: string | null = null;
  let boxHeight: string | null = null;
  let coverageArea: string | null = null;

  if (space.shape.type === 'circle') {
    circleDiameter = displayLength(space.shape.radius * 2, displayUnit);
    coverageArea = displayCircularArea(space.shape.radius, displayUnit);
  }

  if (space.shape.type === 'box') {
    boxWidth = displayLength(space.shape.width, displayUnit);
    boxHeight = displayLength(space.shape.height, displayUnit);
    coverageArea = displayBoxArea(
      space.shape.width,
      space.shape.height,
      displayUnit
    );
  }

  if (space.shape.type === 'polygon') {
    coverageArea = displayPolygonalArea(space.shape.vertices, displayUnit);
  }

  return (
    <div data-cy="focused-space-panel">
      <Panel position="top-left" width={320}>
        <PanelHeader>
          <TextField
            type="text"
            size="medium"
            value={workingName}
            placeholder="eg: Conference Room 3"
            disabled={state.locked || space.locked}
            onChange={(evt) => setWorkingName(evt.currentTarget.value)}
            leadingIcon={
              <Icons.SpaceTypeSpace size={18} color={dust.Blue400} />
            }
            width="100%"
            onBlur={() => onChangeName(workingName)}
            data-cy="space-name"
          />
        </PanelHeader>

        <PanelBody>
          {spaceHierarchyDataLoadingStatus !== 'error' ? (
            <Fragment>
              <HorizontalForm size="medium">
                <div className={styles.focusedSpaceMetadataField}>
                  <label htmlFor="space-capacity">Capacity:</label>
                  <TextField
                    id="space-capacity"
                    size="medium"
                    type="number"
                    min="0"
                    step="1"
                    disabled={
                      state.locked ||
                      space.locked ||
                      spaceHierarchyDataLoadingStatus === 'loading'
                    }
                    error={workingCapacityError}
                    value={workingCapacity}
                    onChange={(e) => setWorkingCapacity(e.currentTarget.value)}
                    trailingSuffix={
                      <span className={styles.focusedSpaceMetadataFieldSuffix}>
                        {workingCapacity === '1' ? 'person' : 'people'}
                      </span>
                    }
                    width={100}
                    onBlur={() => {
                      if (workingCapacity === '') {
                        setWorkingCapacityError(false);
                        onChangeSpaceCapacity(null);
                        return;
                      }

                      const parsedCapacity = window.parseInt(workingCapacity);
                      if (isNaN(parsedCapacity)) {
                        setWorkingCapacityError(true);
                        return;
                      }
                      if (parsedCapacity < 0) {
                        setWorkingCapacityError(true);
                        return;
                      }

                      setWorkingCapacityError(false);
                      onChangeSpaceCapacity(parsedCapacity);
                    }}
                    data-cy="space-capacity"
                  />
                </div>
                <div className={styles.focusedSpaceMetadataField}>
                  <label htmlFor="space-function">Function:</label>
                  {spaceFunctions.status === 'complete' ? (
                    <SelectField
                      id="space-function"
                      placeholder="Select Function"
                      size="medium"
                      value={space.coreSpaceFunction || ''}
                      onChange={(choice) => onChangeSpaceFunction(choice.id)}
                      disabled={
                        state.locked ||
                        space.locked ||
                        spaceHierarchyDataLoadingStatus === 'loading'
                      }
                      choices={
                        spaceFunctions.status === 'complete'
                          ? [
                              { id: '', label: 'No function' },
                              ...spaceFunctions.data.map(
                                (rawSpaceFunction) => ({
                                  id: rawSpaceFunction.function,
                                  label: rawSpaceFunction.function_display_name,
                                })
                              ),
                            ]
                          : []
                      }
                      width={200}
                      data-cy="space-function"
                    />
                  ) : (
                    <TextField
                      error={spaceFunctions.status === 'error'}
                      placeholder={
                        spaceFunctions.status === 'error'
                          ? 'Error loading space functions'
                          : 'Loading space functions...'
                      }
                      disabled
                      size="medium"
                      width={200}
                    />
                  )}
                </div>
              </HorizontalForm>

              <div className={styles.focusedSpaceMetadataField}>
                <label htmlFor="space-capacity">Labels:</label>
                {spaceLabels.status === 'complete' ? (
                  <MultiTagField
                    placeholder="eg: Task Chair"
                    disabled={
                      state.locked ||
                      space.locked ||
                      spaceHierarchyDataLoadingStatus === 'loading'
                    }
                    value={space.coreSpaceLabels.map((l) => ({
                      id: l.id,
                      label: l.name,
                    }))}
                    options={spaceLabels.data.map((l) => ({
                      id: l.id,
                      label: l.name,
                    }))}
                    data-cy="space-labels"
                    // When a new option is selected, create it on the server
                    onCreateOption={async (newLabelName) => {
                      let newLabel: CoreSpaceLabel;
                      try {
                        const response = await CoreAPI.createSpaceLabel(
                          client,
                          newLabelName
                        );
                        newLabel = response.data;
                      } catch (err) {
                        toast.error('Error creating label!');
                        console.error('Error creating label:', err);
                        return;
                      }

                      // Add the new label to the global list of space labels used in the
                      // autocomplete
                      setSpaceLabels((labelsData) => {
                        if (labelsData.status !== 'complete') {
                          return labelsData;
                        }

                        const data = [...labelsData.data, newLabel].sort(
                          (a, b) => a.name.localeCompare(b.name)
                        );

                        (window as any).CACHED_SPACE_LABELS = data;
                        return { status: 'complete', data };
                      });

                      onChangeSpaceLabels([newLabel], []);
                    }}
                    popupMaxHeight={72}
                    onChange={(choices) => {
                      const choiceIds = choices.map((l) => l.id);
                      const existingLabelIds = space.coreSpaceLabels.map(
                        (l) => l.id
                      );

                      // Any labels in the new list but not in the old list should be added
                      const labelsToAdd: Array<
                        Pick<CoreSpaceLabel, 'id' | 'name'>
                      > = [];
                      for (const choiceId of choiceIds) {
                        if (existingLabelIds.includes(choiceId)) {
                          continue;
                        }

                        const label = spaceLabels.data.find(
                          (l) => l.id === choiceId
                        );
                        if (!label) {
                          continue;
                        }

                        labelsToAdd.push(label);
                      }

                      // Any labels in the old list but not in the new list should be removed
                      const labelsToRemove: Array<
                        Pick<CoreSpaceLabel, 'id' | 'name'>
                      > = [];
                      for (const existingLabelId of existingLabelIds) {
                        if (choiceIds.includes(existingLabelId)) {
                          continue;
                        }

                        const label = spaceLabels.data.find(
                          (l) => l.id === existingLabelId
                        );
                        if (!label) {
                          continue;
                        }

                        labelsToRemove.push(label);
                      }

                      onChangeSpaceLabels(labelsToAdd, labelsToRemove);
                    }}
                  />
                ) : (
                  <TextField
                    error={spaceLabels.status === 'error'}
                    placeholder={
                      spaceLabels.status === 'error'
                        ? 'Error loading space labels'
                        : 'Loading space labels...'
                    }
                    disabled
                    size="medium"
                    width="100%"
                  />
                )}
              </div>
            </Fragment>
          ) : (
            <div style={{ height: 100, color: dust.Red400 }}>
              <FillCenter>Error loading space details!</FillCenter>
            </div>
          )}

          {isValidationEnabled ? (
            <ValidationsList validations={spaceValidations} />
          ) : null}

          <div className={styles.spaceConfigMeta}>
            {space.shape.type === 'circle' ? (
              <div className={styles.sensorConfigMetaRow}>
                <span>Diameter:&nbsp;</span>
                <span className={styles.muted}>{circleDiameter}</span>
              </div>
            ) : null}
            {space.shape.type === 'box' ? (
              <div className={styles.sensorConfigMetaRow}>
                <span>Width x Height:&nbsp;</span>
                <span className={styles.muted}>
                  {boxWidth} x {boxHeight}
                </span>
              </div>
            ) : null}
            {space.shape.type === 'polygon' ? (
              <div className={styles.sensorConfigMetaRow}>
                <span>Sides:&nbsp;</span>
                <span className={styles.muted}>
                  {space.shape.vertices.length}
                </span>
              </div>
            ) : null}
            <div className={styles.sensorConfigMetaRow}>
              <span>Area:&nbsp;</span>
              <span className={styles.muted}>{coverageArea}</span>
            </div>
            <div
              className={classnames(styles.sensorConfigMetaRow, {
                [styles.dimmed]: isFloorplanSpaceId(space.id) === null,
              })}
            >
              <span>ID:&nbsp;</span>
              {isFloorplanSpaceId(space.id) ? (
                <HorizontalForm size="small">
                  {space.id}
                  <Button
                    size="nano"
                    type="cleared"
                    trailingIcon={<Icons.CopyDuplicate size={14} />}
                    onClick={() => {
                      navigator.clipboard.writeText(space.id);
                      toast.success('Copied to clipboard.');
                    }}
                  />
                </HorizontalForm>
              ) : (
                'Unsaved'
              )}
            </div>
          </div>
        </PanelBody>

        <PanelActions
          left={
            <HorizontalForm size="medium">
              <Button
                type="cleared"
                size="medium"
                trailingIcon={<Icons.Trash size={18} />}
                danger
                disabled={state.locked || space.locked}
                onClick={() => onDelete()}
                data-cy="space-delete-button"
              />
              <Button
                type="cleared"
                size="medium"
                data-cy={
                  space.locked ? 'space-unlock-button' : 'space-lock-button'
                }
                trailingIcon={
                  space.locked ? (
                    <Icons.LockClosed
                      size={18}
                      color={!state.locked ? dust.Yellow400 : 'currentColor'}
                    />
                  ) : (
                    <Icons.LockOpen size={18} />
                  )
                }
                onClick={() => {
                  onChangeLocked(!space.locked);
                }}
                disabled={state.locked}
              />
              <Button
                type="cleared"
                size="medium"
                data-cy="space-duplicate-button"
                trailingIcon={
                  <Icons.CopyPlusDuplicate size={18} color="currentColor" />
                }
                onClick={() =>
                  dispatch({ type: 'menu.duplicateSpace', id: space.id })
                }
                disabled={state.locked}
              />
            </HorizontalForm>
          }
          right={
            <Button
              type="cleared"
              size="medium"
              onClick={() => dispatch({ type: 'space.dismiss', id: space.id })}
              data-cy="space-dismiss"
            >
              Done
            </Button>
          }
        />
      </Panel>
    </div>
  );
};

export default FocusedSpacePanel;
