import { ImageVector, ViewportCoordinates } from './geometry';
import { degreesToRadians, radiansToDegrees } from './math';

export const DEFAULT_VIEWPORT: Viewport = {
  width: 1,
  height: 1,
  top: 0,
  left: 0,
  zoom: 1,
};

export interface Viewport {
  width: number;
  height: number;
  zoom: number;
  top: number;
  left: number;
}

interface BoxSize {
  width: number;
  height: number;
}

type BoxSizeWithRotation = BoxSize & { rotation: number };

function generateBoundingBox({
  width,
  height,
  rotation,
}: BoxSizeWithRotation): [[number, number], [number, number]] {
  const diagonalAngleDegrees = radiansToDegrees(Math.atan2(height, width));
  const diagonalDistance = Math.hypot(width, height);

  const topLeftX = 0;
  const topLeftY = 0;

  const topRightX = width * Math.cos(degreesToRadians(rotation));
  const topRightY = width * Math.sin(degreesToRadians(rotation));

  const bottomRightX =
    diagonalDistance *
    Math.cos(degreesToRadians(rotation + diagonalAngleDegrees));
  const bottomRightY =
    diagonalDistance *
    Math.sin(degreesToRadians(rotation + diagonalAngleDegrees));

  const bottomLeftX = height * Math.cos(degreesToRadians(rotation + 90));
  const bottomLeftY = height * Math.sin(degreesToRadians(rotation + 90));

  const minX = Math.min(topLeftX, topRightX, bottomRightX, bottomLeftX);
  const minY = Math.min(topLeftY, topRightY, bottomRightY, bottomLeftY);
  const maxX = Math.max(topLeftX, topRightX, bottomRightX, bottomLeftX);
  const maxY = Math.max(topLeftY, topRightY, bottomRightY, bottomLeftY);

  return [
    [minX, minY],
    [maxX, maxY],
  ];
}

export namespace Viewport {
  export function getSVGTransformString(viewport: Readonly<Viewport>) {
    const scale = `scale(${viewport.zoom}, ${viewport.zoom})`;
    const translate = `translate(${-viewport.left}, ${-viewport.top})`;
    return `${scale} ${translate}`;
  }

  export function getMinZoom<V extends BoxSize, S extends BoxSize>(
    viewport: Readonly<V>,
    itemToFit: Readonly<S>
  ) {
    const widthFactorNeeded = viewport.width / Math.abs(itemToFit.width);
    const heightFactorNeeded = viewport.height / Math.abs(itemToFit.height);
    return Math.min(widthFactorNeeded, heightFactorNeeded);
  }

  export function zoomToFit<V extends BoxSize, S extends BoxSizeWithRotation>(
    viewport: Readonly<V>,
    itemToFit: Readonly<S>
  ): Viewport {
    const [upperLeft, lowerRight] = generateBoundingBox(itemToFit);
    const width = lowerRight[0] - upperLeft[0];
    const height = lowerRight[1] - upperLeft[1];

    const zoom = getMinZoom(viewport, { width, height });
    const widthDifference = viewport.width - zoom * width;
    const heightDifference = viewport.height - zoom * height;
    let top = -(heightDifference / zoom) / 2;
    let left = -(widthDifference / zoom) / 2;
    left += upperLeft[0];
    top += upperLeft[1];
    return {
      ...viewport,
      zoom,
      top,
      left,
    };
  }

  export function zoomToFitWithSidebar<
    V extends BoxSize,
    S extends BoxSizeWithRotation
  >(viewport: Readonly<V>, itemToFit: Readonly<S>, sidebarWidth: number) {
    // Calculate zoom based on a viewport that subtracts the sidebar width
    const adjustedViewport: BoxSize = {
      width: viewport.width - sidebarWidth,
      height: viewport.height,
    };

    const result = zoomToFit(adjustedViewport, itemToFit);

    return {
      ...result,
      width: viewport.width,
      left: result.left - sidebarWidth / result.zoom,
    };
  }

  export function zoomWithMouseWheel(
    viewport: Readonly<Viewport>,
    position: ViewportCoordinates,
    mouseDeltaY: number
  ): Viewport {
    // limit scroll wheel sensitivity for mouse users
    const limit = 8;
    const scrollDelta = Math.max(-limit, Math.min(limit, mouseDeltaY));

    const nextZoomFactor = viewport.zoom + viewport.zoom * scrollDelta * -0.01;

    const targetX = viewport.left + position.x / viewport.zoom;
    const targetY = viewport.top + position.y / viewport.zoom;

    const top = targetY - position.y / nextZoomFactor;
    const left = targetX - position.x / nextZoomFactor;

    return {
      ...viewport,
      zoom: nextZoomFactor,
      top,
      left,
    };
  }

  export function dragWithMouse(
    viewport: Readonly<Viewport>,
    mouseDeltaX: number,
    mouseDeltaY: number
  ): Viewport {
    const { zoom, top, left } = viewport;
    return {
      ...viewport,
      top: top - mouseDeltaY / zoom,
      left: left - mouseDeltaX / zoom,
    };
  }

  export function pan(viewport: Readonly<Viewport>, delta: ImageVector) {
    return {
      ...viewport,
      top: viewport.top + delta.y,
      left: viewport.left + delta.x,
    };
  }
}
