import React from "react";

const Integrations1 = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      fill={color}
      d="M15,5 C18.8659932,5 22,8.13400675 22,12 C22,15.8659932 18.8659932,19 15,19 C13.9265078,19 12.9094545,18.7583563 12.000267,18.3264957 C11.0912936,18.7581785 10.073887,19 9,19 C5.13400675,19 2,15.8659932 2,12 C2,8.13400675 5.13400675,5 9,5 C10.073887,5 11.0912936,5.24182151 12.0007361,5.6739809 C12.9094545,5.24164373 13.9265078,5 15,5 Z M9,7 C6.23857625,7 4,9.23857625 4,12 C4,14.7614237 6.23857625,17 9,17 C9.34323778,17 9.67839764,16.9654144 10.0021848,16.8995379 C8.76295568,15.6367691 8,13.9074835 8,12 C8,10.0925165 8.76295568,8.36323091 10.0003472,7.10066301 C9.67839764,7.03458565 9.34323778,7 9,7 Z M15,7 C14.6567622,7 14.3216024,7.03458565 13.9978152,7.10046211 C15.2370443,8.36323091 16,10.0925165 16,12 C16,13.9074835 15.2370443,15.6367691 13.9996528,16.899337 C14.3216024,16.9654144 14.6567622,17 15,17 C17.7614237,17 20,14.7614237 20,12 C20,9.23857625 17.7614237,7 15,7 Z M12.0011995,8.00053506 L11.9180384,8.06252705 C10.7503261,8.97781003 10,10.401281 10,12 C10,13.6352792 10.7850361,15.0872034 11.9988005,15.9994649 C13.2145305,15.0880049 14,13.6357306 14,12 C14,10.3647208 13.2149639,8.91279657 12.0011995,8.00053506 Z"
    />
  </svg>
);

export default Integrations1;
