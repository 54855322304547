import { FloorplanCoordinates, ImageCoordinates } from 'lib/geometry';
import Measurement from 'lib/measurement';
import { ReferenceRuler } from 'lib/reference';

import { useFloorplanLayerContext, toRawHex } from 'components/floorplan';

import { Blue400 } from '@density/dust/dist/tokens/dust.tokens';

import ReferenceRulersLayer from './reference-rulers-layer';

// THe floorplan scale layer renders the scale line both in the floorplan measurement workflow, as
// well as on the main planning view. If the onEndpointsMoved prop is not provided, the line is
// read only.
const FloorplanScaleLayer: React.FunctionComponent<{
  measurement: Measurement | undefined;
  onEndpointsMoved?: (
    positionA: ImageCoordinates,
    positionB: ImageCoordinates
  ) => void;
}> = ({ measurement, onEndpointsMoved }) => {
  const context = useFloorplanLayerContext();

  if (!measurement) {
    return null;
  }

  const positionA = ImageCoordinates.toFloorplanCoordinates(
    measurement.pointA,
    context.floorplan
  );
  const positionB = ImageCoordinates.toFloorplanCoordinates(
    measurement.pointB,
    context.floorplan
  );

  return (
    <ReferenceRulersLayer
      referenceRulers={[
        {
          id: 'scale',
          type: 'ruler',
          positionA,
          positionB,
          distanceLabelPosition: ReferenceRuler.calculateCenterPoint({
            positionA,
            positionB,
          }),
          enabled: true,
          distanceLabelText: onEndpointsMoved ? 'Measurement' : undefined,
        },
      ]}
      onEndpointsMoved={
        onEndpointsMoved
          ? (r, positionA, positionB) => {
              const positionAImage = FloorplanCoordinates.toImageCoordinates(
                positionA,
                context.floorplan
              );
              const positionBImage = FloorplanCoordinates.toImageCoordinates(
                positionB,
                context.floorplan
              );
              onEndpointsMoved(positionAImage, positionBImage);
            }
          : undefined
      }
      rulerColor={toRawHex(Blue400)}
      // Always show custom "measurement" label, no matter how far in or out the user zooms
      labelVisibilityZoomThreshold={0}
    />
  );
};

export default FloorplanScaleLayer;
