import React, { Fragment } from 'react';
import classnames from 'classnames';
import styles from './styles.module.scss';

import { useTheme } from 'components/theme';

export type PopupProps = {
  target: React.ReactNode;
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  popupWidth?: number | string;
  onMouseEnter?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;

  position?: {
    top?: number;
    bottom?: number;
    left?: number;
    right?: number;
  };
  tabFocusable?: boolean;
  noPadding?: boolean;
};

const Popup: React.FunctionComponent<PopupProps> = (props) => {
  const {
    target,
    children,
    open,
    onClose,
    onMouseEnter,
    onMouseLeave,
    popupWidth,
    position = { top: 40, left: 0 },
    tabFocusable = true,
    noPadding = false,
  } = props;

  const theme = useTheme();

  let { top, left, bottom, right } = position;

  return (
    <Fragment>
      {open ? (
        <div
          className={styles.backdrop}
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClose();
          }}
        />
      ) : null}

      <div className={styles.wrapper} tabIndex={tabFocusable ? 0 : -1}>
        {/* Target */}
        <span
          className={classnames(styles.target, {
            [styles.focus]: open,
          })}
          onMouseEnter={(e) => !open && onMouseEnter && onMouseEnter(e)}
          onMouseLeave={(e) => onMouseLeave && onMouseLeave(e)}
          data-test-id="target"
        >
          {target}
        </span>

        {/* Popup */}
        <div
          className={classnames(styles.popup, {
            [styles.open]: open,
            [styles.padding]: !noPadding,
            [styles.dark]: theme === 'dark',
          })}
          style={{ width: popupWidth, top, bottom, left, right }}
          aria-hidden={!open}
        >
          {children}
        </div>
      </div>
    </Fragment>
  );
};

export default Popup;
