import { useCallback, useState } from 'react';
import classnames from 'classnames';
import * as React from 'react';
import { Icons } from '@density/dust';

import styles from './styles.module.scss';

import KeyboardShortcut from 'components/keyboard-shortcut';
import { useTreatment } from 'contexts/treatments';
import { SPLITS } from 'lib/treatments';

type KeyboardShortcutEntry = {
  icon: typeof Icons.AccuracyTarget;
  title: string;
  shortcut: string;
  availableOutsideEditor: boolean;
};

const ENTRIES: Array<KeyboardShortcutEntry> = [
  {
    icon: Icons.PanHand,
    title: 'Pan Floor',
    shortcut: 'space + drag',
    availableOutsideEditor: true,
  },
  {
    icon: Icons.Ruler,
    title: 'Measure',
    shortcut: 'option',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.CopyDuplicate,
    title: 'Duplicate',
    shortcut: 'alt + drag',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.ZoomIn,
    title: 'Zoom In/Out',
    shortcut: 'cmd + scroll',
    availableOutsideEditor: true,
  },
  {
    icon: Icons.DeviceEntrySideIsometricAngle,
    title: 'Add OA Sensor',
    shortcut: '1',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.DeviceEntryTopFront,
    title: 'Add Entry Sensor',
    shortcut: '2',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.EditorSquare,
    title: 'Add Box Space',
    shortcut: '3',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.EditorCircle,
    title: 'Add Circle Space',
    shortcut: '4',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.DrawFreehand,
    title: 'Add Polygon Space',
    shortcut: '5',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.Ruler,
    title: 'Add Reference Ruler',
    shortcut: 'R',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.RulerVertical,
    title: 'Add Reference Height',
    shortcut: 'H',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.SwapVerticalArrow,
    title: 'Flip Render Order',
    shortcut: 'x',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.Undo,
    title: 'Undo',
    shortcut: 'cmd+z',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.Redo,
    title: 'Redo',
    shortcut: 'cmd+shift+z',
    availableOutsideEditor: false,
  },
  {
    icon: Icons.Trash,
    title: 'Delete object',
    shortcut: 'backspace',
    availableOutsideEditor: false,
  },
];

export type Props = {
  open: boolean;
  onMenuToggle: () => void;
  entries?: Array<KeyboardShortcutEntry>;
  inEditor?: boolean;
};

type ComponentWithController<P> = React.FunctionComponent<P> & {
  Controller: React.FunctionComponent<{
    render: (controlledProps: P) => JSX.Element;
  }>;
};

const KeyboardShortcutsMenu: ComponentWithController<Props> = ({
  open,
  onMenuToggle,
  entries = ENTRIES,
  inEditor = false,
}) => {
  const isFlipRenderOrderEnabled = useTreatment(SPLITS.FLIP_RENDER_ORDER);

  const small = !inEditor && !open;

  return (
    <div className={classnames(styles.container, { [styles.small]: small })}>
      <div className={classnames(styles.menu, { [styles.open]: open })}>
        {entries.map((entry, index) => {
          if (
            entry.title === 'Flip Render Order' &&
            !isFlipRenderOrderEnabled
          ) {
            return null;
          }

          const IconComponent = entry.icon;
          return (
            <div key={index} className={styles.entry}>
              <IconComponent size={16} />
              <div className={styles.title}>{entry.title}</div>
              <KeyboardShortcut>{entry.shortcut}</KeyboardShortcut>
            </div>
          );
        })}
      </div>
      <div
        className={classnames(styles.menuToggle, { [styles.small]: small })}
        onClick={onMenuToggle}
      >
        <Icons.Keyboard size={16} />
        {!small ? (
          <div className={styles.title}>Keyboard Shortcuts</div>
        ) : (
          <div style={{ width: 4 }} />
        )}
        <Icons.ChevronToggleExpand size={18} />
      </div>
    </div>
  );
};

KeyboardShortcutsMenu.Controller = ({ render }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [open, setOpen] = useState<boolean>(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const onMenuToggle = useCallback(() => {
    return setOpen(!open);
  }, [open]);
  return render({ open, onMenuToggle });
};

export default KeyboardShortcutsMenu;
