import { DOMParser } from 'xmldom';
import QuickLRU from 'quick-lru';
import { wrap, releaseProxy, Remote } from 'comlink';
import GeoTiff, { GeoTIFFImage, Pool, fromUrl, fromArrayBuffer } from 'geotiff';
import { FixMe } from 'types/fixme';

export const GEOTIFF_NO_DATA = -9999;

// Generate a pool of workers for geotiff.js to use for processing.
// After a timeout, termiante the pool so it's not running in the background and wasting cpu cycles
let pool: Pool | null = null;
let poolLastUsed: Date | null = null;
function startPool() {
  poolLastUsed = new Date();
  if (pool) {
    return;
  }

  pool = new Pool(2);

  function interval() {
    if (!pool || !poolLastUsed) {
      return;
    }

    const timeSinceLastUsed = new Date().getTime() - poolLastUsed.getTime();
    if (timeSinceLastUsed > 30000) {
      pool.destroy();
      pool = null;
      poolLastUsed = null;
      return;
    }
    setTimeout(interval, 30000);
  }
  setTimeout(interval, 30000);
}

// Instantiate a webworker for bulk pixel processing
// After a timeout, termiante the worker so it's not running in the background and wasting cpu cycles
let geoTiffProcessingWorker: Worker | null = null;
let geoTiffProcessingWorkerWrapped: Remote<
  import('./geotiff-pixel-processing-worker').GeoTiffPixelProcessingWorker
> | null = null;
let geoTiffProcessingLastUsed: Date | null = null;
function startGeoTiffProcessingWorker() {
  geoTiffProcessingLastUsed = new Date();
  if (geoTiffProcessingWorker && geoTiffProcessingWorkerWrapped) {
    return;
  }

  geoTiffProcessingWorker = new Worker(
    new URL('./geotiff-pixel-processing-worker', import.meta.url),
    {
      name: 'geotiff-pixel-processing-worker',
    }
  );
  geoTiffProcessingWorkerWrapped = wrap(geoTiffProcessingWorker);

  function interval() {
    if (
      !geoTiffProcessingLastUsed ||
      !geoTiffProcessingWorker ||
      !geoTiffProcessingWorkerWrapped
    ) {
      return;
    }

    const timeSinceLastUsed =
      new Date().getTime() - geoTiffProcessingLastUsed.getTime();
    if (timeSinceLastUsed > 30000) {
      geoTiffProcessingWorkerWrapped[releaseProxy]();
      geoTiffProcessingWorker.terminate();

      geoTiffProcessingWorker = null;
      geoTiffProcessingWorkerWrapped = null;
      geoTiffProcessingLastUsed = null;
      return;
    }
    setTimeout(interval, 30000);
  }
  setTimeout(interval, 30000);
}

export type GeoTiffTiepoint = {
  i: number;
  j: number;
  k: number;
  x: number;
  y: number;
  z: number;
}[];

export type ParsedGeoTiff = {
  geotiff: GeoTiff;
  geotiffImage: GeoTIFFImage;
  getTileImage: (
    x: number,
    y: number,
    width: number,
    height: number,
    scale: number,
    signal?: AbortController['signal']
  ) => Promise<[Uint8ClampedArray, number, number] | null>;
  getHeightAtPoint: (
    x: number,
    y: number,
    signal?: AbortController['signal']
  ) => Promise<number | null>;
  getHeightInRegion: (
    x: number,
    y: number,
    width: number,
    height: number,
    signal?: AbortController['signal']
  ) => Promise<(x: number, y: number) => number>;
  scale: number;
  smallestValue: number;
  largestValue: number;
  baseHeightValue: number;
};

async function getArrayBuffer(url: string) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(
      `Unable to fetch ${url}: ${response.status} ${await response.text()}`
    );
  }

  return response.arrayBuffer();
}

async function getGeoTiff(url: string, forceFetchFullFile: boolean) {
  if (forceFetchFullFile) {
    return fromArrayBuffer(await getArrayBuffer(url));
  }

  try {
    // Creating the geotiff directly from the URL will allow for range requests and other fancy
    // techniques to make the interface faster
    const urlSource = await fromUrl(url);

    // If the geotiff isn't tiled, then force fetching the whole thing
    // Trying to use a non cloud optimized geotiff with fromUrl results in the browser trying to
    // fetch lots of data and eventually the browser terminating all those requests
    if (!(await urlSource.getImage()).isTiled) {
      return fromArrayBuffer(await getArrayBuffer(url));
    }

    return urlSource;
  } catch (err) {
    // But if that won't work, fall back to fetching the whole file in one go
    return fromArrayBuffer(await getArrayBuffer(url));
  }
}

const parseGeoTiffLRUCache = new QuickLRU<string, ParsedGeoTiff>({
  maxSize: 1,
});

export default async function parseGeoTiff(
  geoTiffUrl: string,
  forceFetchFullFile = false
): Promise<ParsedGeoTiff> {
  // Cache geotiffs that were recently used because parsing them from scratch takes a long time
  const lastGeoTiff = parseGeoTiffLRUCache.get(geoTiffUrl);
  if (lastGeoTiff) {
    return lastGeoTiff;
  }

  const geotiff = await getGeoTiff(geoTiffUrl, forceFetchFullFile);

  // NOTE: A geotiff can seemingly contain mutltiple images. The below reads the first one in the
  // file.
  const image = await geotiff.getImage();

  // Read some custom metadata in the geotiff to determine the smallest and largest values in the
  // whole image
  // NOTE: the below values are defaults in case smallest / largest values aren't specified.
  let smallestValue = 1;
  let largestValue = 4;
  let baseHeightValue = 0;
  const metadata = image.getFileDirectory();
  if (metadata.GDAL_METADATA) {
    const parser = new DOMParser();
    const metadataDoc = parser.parseFromString(
      metadata.GDAL_METADATA,
      'text/xml'
    );

    for (const node of Array.from(metadataDoc.getElementsByTagName('Item'))) {
      const name = node.getAttribute('name');
      switch (name) {
        case 'MINBANDVALUE':
          smallestValue = parseFloat((node.childNodes[0] as FixMe)?.data);
          break;
        case 'MAXBANDVALUE':
          largestValue = parseFloat((node.childNodes[0] as FixMe)?.data);
          break;
        case 'ZOFFSET':
          baseHeightValue = parseFloat((node.childNodes[0] as FixMe)?.data);
          break;
      }
    }
  }

  const resolution = image.getResolution();
  if (Math.abs(resolution[0]) !== Math.abs(resolution[1])) {
    throw new Error(
      `GEOTiff resolution is not identical in both x and y axes! Resolution was ${JSON.stringify(
        resolution
      )}`
    );
  }
  const scale = Math.abs(resolution[0]);

  // Given a height map bounding box, return a Uint8ClampedArray representing the height data inside
  const getTileImageCache = new QuickLRU<string, Float32Array>({ maxSize: 16 });
  const getTileImage = async (
    x: number,
    y: number,
    width: number,
    height: number,
    scale: number,
    signal?: AbortController['signal']
  ): Promise<[Uint8ClampedArray, number, number] | null> => {
    startPool();
    if (!pool) {
      throw new Error('GeoTiff processing pool will not start!');
    }

    startGeoTiffProcessingWorker();
    if (!geoTiffProcessingWorkerWrapped) {
      throw new Error('GeoTiff processing worker will not start!');
    }

    const scaledWidthInPixels = Math.ceil(width * scale);
    const scaledHeightInPixels = Math.ceil(height * scale);

    const canvas = document.createElement('canvas');
    canvas.width = scaledWidthInPixels;
    canvas.height = scaledHeightInPixels;

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      throw new Error(`Unable to create canvas context!`);
    }

    // Get data within tile... first check the cache though
    const key = `${x},${y},${width},${height},${scaledWidthInPixels},${scaledHeightInPixels}`;
    let floatRasterData = getTileImageCache.get(key);
    if (!floatRasterData) {
      let rawData;
      try {
        rawData = await image.readRasters({
          window: [x, y, x + width, y + height],
          width: scaledWidthInPixels * 4,
          height: scaledHeightInPixels,
          pool,
          signal,
        });
      } catch (err: FixMe) {
        if (err.name === 'AbortError' || err.name === 'AggregateError') {
          console.warn('GeoTiff request in getTileImage aborted:', err);
          return null;
        }
        throw err;
      }
      floatRasterData = rawData[0] as Float32Array;
      getTileImageCache.set(key, floatRasterData);
    }

    const uint8RasterData =
      await geoTiffProcessingWorkerWrapped.geoTiffPixelProcessingWorker(
        floatRasterData,
        scaledWidthInPixels,
        scaledHeightInPixels,
        smallestValue,
        largestValue
      );

    return [uint8RasterData, scaledWidthInPixels, scaledHeightInPixels];
  };

  // Given an x and y coordiante in pixels, return the height at that coordinate
  const getHeightAtPoint = async (
    x: number,
    y: number,
    signal?: AbortController['signal']
  ) => {
    startPool();
    if (!pool) {
      throw new Error('GeoTiff processing pool will not start!');
    }

    let rawData;
    try {
      rawData = await image.readRasters({
        window: [x, y, x + 1, y + 1],
        pool,
        signal,
      });
    } catch (err: FixMe) {
      if (err.name === 'AbortError' || err.name === 'AggregateError') {
        console.warn('GeoTiff request in getHeightAtPoint aborted:', err);
        return null;
      }
      throw err;
    }

    return (rawData[0] as Float32Array)[0];
  };

  // Given an x and y coordiante in pixels, return the height at that coordinate
  const getHeightInRegion = async (
    x: number,
    y: number,
    width: number,
    height: number,
    signal?: AbortController['signal']
  ) => {
    if (x < 0) {
      x = 0;
    }
    if (x + width > image.getWidth()) {
      width = image.getWidth() - x;
    }
    if (y < 0) {
      y = 0;
    }
    if (y + height > image.getHeight()) {
      height = image.getHeight() - y;
    }

    const chunkSize = 1024;
    const chunks: Array<Array<Float32Array>> = [];

    for (let i = x; i < x + width; i += chunkSize) {
      const col = [];
      for (let j = y; j < y + height; j += chunkSize) {
        let floatRasterData: Float32Array | null = null;
        for (let retryCount = 0; retryCount < 3; retryCount += 1) {
          startPool();
          if (!pool) {
            throw new Error('GeoTiff processing pool will not start!');
          }

          try {
            let rawData = await image.readRasters({
              window: [i, j, i + chunkSize, j + chunkSize],
              pool,
              signal,
            });
            floatRasterData = rawData[0] as Float32Array;
          } catch (err: FixMe) {
            if (err.name === 'AbortError' || err.name === 'AggregateError') {
              await new Promise((r) => setTimeout(r, 500));
              continue;
            }
            throw err;
          }
          break;
        }
        if (!floatRasterData) {
          throw new Error(`Requesting data for ${i},${j} failed.`);
        }
        col.push(floatRasterData);
      }
      chunks.push(col);
    }

    return (sampleX: number, sampleY: number) => {
      const indexX = sampleX - x;
      const indexY = sampleY - y;

      // If the index is not in the data, return "no data"
      if (indexX < 0 || indexY < 0 || indexX > width || indexY > height) {
        return GEOTIFF_NO_DATA;
      }

      // Get the chunk that this height is within
      const chunkX = Math.floor(indexX / chunkSize);
      const chunkY = Math.floor(indexY / chunkSize);
      const chunkCol = chunks[chunkX];
      if (typeof chunkCol === 'undefined') {
        return GEOTIFF_NO_DATA;
      }
      const chunk = chunkCol[chunkY];
      if (typeof chunk === 'undefined') {
        return GEOTIFF_NO_DATA;
      }

      return chunk[(indexY % chunkSize) * chunkSize + (indexX % chunkSize)];
    };
  };

  const parsedGeoTiff = {
    geotiff,
    geotiffImage: image,
    getTileImage,
    getHeightAtPoint,
    getHeightInRegion,
    scale,
    smallestValue,
    largestValue,
    baseHeightValue,
  };
  parseGeoTiffLRUCache.set(geoTiffUrl, parsedGeoTiff);
  return parsedGeoTiff;
}
