import Creatable from 'react-select/creatable';
import * as dust from '@density/dust/dist/tokens/dust.tokens';

const MultiTagField: React.FunctionComponent<{
  placeholder?: string;
  disabled?: boolean;
  options?: Array<{ id: string; label: string }>;
  popupMaxHeight?: React.ReactText;
  value: Array<{ id: string; label: string }>;
  onChange: (choices: Array<{ id: string; label: string }>) => void;
  onCreateOption?: (newOptionText: string) => void;
  'data-cy'?: string;
}> = ({
  disabled,
  options = [],
  popupMaxHeight,
  placeholder,
  value,
  onChange,
  onCreateOption,
  'data-cy': dataCy,
}) => (
  <div data-cy={dataCy}>
    <Creatable
      placeholder={placeholder}
      isDisabled={disabled}
      value={value.map((i) => ({ value: i.id, label: i.label }))}
      onChange={(choices) =>
        onChange(choices.map((i) => ({ id: i.value, label: i.label })))
      }
      onCreateOption={onCreateOption}
      components={{
        DropdownIndicator: () => null,
        IndicatorsContainer: () => null,
        IndicatorSeparator: () => null,
      }}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          minHeight: '32px',
          borderColor: state.isFocused ? dust.Blue400 : dust.Gray200,
          caretColor: dust.Blue400,
          boxShadow: state.isFocused ? `0px 0px 0px 2px ${dust.Blue200}` : '',
          transition: 'none',
          borderRadius: dust.Radius200,
          cursor: 'text',
          fontSize: '14px',
          '&:hover': {
            borderColor: state.isFocused ? dust.Blue400 : dust.Gray200,
          },
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          color: dust.Gray500,
          fontSize: '14px',
        }),
        input: (baseStyles) => ({
          ...baseStyles,
          fontSize: '14px',
        }),
        valueContainer: (baseStyles, state) => ({
          ...baseStyles,
          paddingLeft: state.hasValue ? dust.Space1 : dust.Space3,
          paddingRight: state.hasValue ? dust.Space1 : dust.Space3,
        }),
        multiValue: (baseStyles) => ({
          ...baseStyles,
          borderRadius: dust.Radius200,
          backgroundColor: dust.Gray200,
          fontSize: '14px',
          fontWeight: dust.FontWeightMedium,
          cursor: 'default',
        }),
        multiValueRemove: (baseStyles) => ({
          ...baseStyles,
          borderTopRightRadius: dust.Radius200,
          borderBottomRightRadius: dust.Radius200,
          borderTopLeftRadius: '0',
          borderBottomLeftRadius: '0',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: dust.Gray300,
            color: dust.Red700,
          },
        }),
        menu: (baseStyles, state) => ({
          ...baseStyles,
          display: state.options.length > 0 ? 'block' : 'none',
          borderRadius: dust.Radius200,
          border: `1px solid ${dust.Gray200}`,
          boxShadow: 'none',
        }),
        menuList: (baseStyles) => ({
          ...baseStyles,
          maxHeight: popupMaxHeight || baseStyles.maxHeight,
          overflow: 'auto',
          padding: dust.Space2,
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          fontSize: '14px',
          cursor: 'pointer',
          backgroundColor: state.isFocused ? dust.Gray100 : 'transparent',
          borderRadius: dust.Radius200,
          paddingLeft: dust.Space3,
          paddingRight: dust.Space3,
          paddingTop: dust.Space2,
          paddingBottom: dust.Space2,
          '&:active': {
            backgroundColor: dust.Gray200,
          },
        }),
      }}
      isMulti
      options={options.map((i) => ({ value: i.id, label: i.label }))}
    />
  </div>
);

export default MultiTagField;
