// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-skeleton-3xWwC_ir{display:inline-block;width:100%;height:8px;border-radius:2px;background-color:#C0C6CC}\n", ""]);
// Exports
exports.locals = {
	"skeleton": "dui-styles-module-skeleton-3xWwC_ir"
};
module.exports = exports;
