import React from 'react';

const FolderImageAdd = ({ width, height, color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || 24}
    height={height || 24}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M12.5 5l-2-2H2v18h20V11h-2v8H4V7h10V5h-1.5z"
    ></path>
    <path
      fill={color}
      d="M8.177 11.26L5.67 15.026 5 16h14l-.642-1.112L14.874 9l-3.882 4.373-2.815-2.114zM20 9h-2V7h-2V5h2V3h2v2h2v2h-2v2z"
    ></path>
  </svg>
);

export default FolderImageAdd;
