import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { CoreSpaceHierarchyNode } from '@densityco/lib-api-types';
import { arrayMove } from 'react-movable';
import robustPointInPolygon from 'robust-point-in-polygon';

import { Action } from './actions';

import Floorplan from 'lib/floorplan';
import { PlacementMode } from 'components/floorplan';
import { LengthUnit, Meters, Seconds } from 'lib/units';
import { Viewport } from 'lib/viewport';
import { isFloorplanPhotoGroupId } from 'lib/api';
import WallSegment from 'lib/wall-segment';
import PlanSensor, {
  SensorCoverageIntersectionVectors,
  SensorConnection,
  SensorValidation,
} from 'lib/sensor';
import Space, { SpaceValidation } from 'lib/space';
import { Heatmap } from 'lib/heatmap';
import HeightMap from 'lib/heightmap';
import Reference, { ReferenceRuler, ReferenceHeight } from 'lib/reference';
import PhotoGroup, { PhotoGroupPhoto } from 'lib/photo-group';
import { DEFAULT_PIXELS_PER_CAD_UNIT, ParseDXFOptions } from 'lib/dxf';
import {
  FloorplanCoordinates,
  CADCoordinates,
  ImageCoordinates,
  ViewportCoordinates,
  calculatePolygonCentroid,
  computeBoundingRegionExtents,
} from 'lib/geometry';
import { modulo } from 'lib/math';
import { PointCloud } from 'lib/pointcloud';
import FloorplanCollection from 'lib/floorplan-collection';
import { FloorplanTargetInfo } from 'components/track-visualizer';
import {
  computeDefaultCADOrigin,
  computeNewFloorplanForCAD,
  CADImportOperationType,
} from 'lib/cad';
import Measurement from 'lib/measurement';

import { Update } from 'types/update';

export type MutationOptions = {
  objectIds?: Array<string>;
  initialActionCreator: (objectIds: Array<string>) => Action;
  rollbackActionCreator: (objectIds: Array<string>) => Action;
  syncToAPI: (
    state: State,
    direction: 'forwards' | 'backwards',
    objectIds: Array<string>
  ) => Promise<void>;
};

export type UndoStackItem = {
  id: string;
  createdAt: string;

  forwardsActionCreator: (objectIds: Array<string>) => Action;
  backwardsActionCreator: (objectIds: Array<string>) => Action;
  syncToAPI: (
    state: State,
    direction: 'forwards' | 'backwards',
    objectIds: Array<string>
  ) => Promise<void>;
  objectIds: Array<string>;
};
export const UndoStackItem = {
  create(
    forwardsActionCreator: UndoStackItem['forwardsActionCreator'],
    backwardsActionCreator: UndoStackItem['backwardsActionCreator'],
    syncToAPI: UndoStackItem['syncToAPI'],
    objectIds: Array<string> = []
  ) {
    return {
      id: uuidv4(),
      createdAt: moment.utc().format(),

      forwardsActionCreator,
      backwardsActionCreator,
      syncToAPI,
      objectIds,
    };
  },
};

export type UndoStack = {
  stack: Array<UndoStackItem>;
  currentIndex: number;
  loading: boolean;
  updatedAt: string | null;
};
export const UndoStack = {
  create(): UndoStack {
    return {
      stack: [],
      currentIndex: -1,
      loading: false,
      updatedAt: null,
    };
  },

  list(undoStack: UndoStack): Array<UndoStackItem> {
    return undoStack.stack;
  },

  count(undoStack: UndoStack): number {
    return undoStack.stack.length;
  },

  append(undoStack: UndoStack, item: UndoStackItem): UndoStack {
    const newStack = undoStack.stack.slice(0, undoStack.currentIndex + 1);
    return {
      ...undoStack,
      stack: [...newStack, item],
      currentIndex: undoStack.currentIndex + 1,
    };
  },

  removeLastItem(undoStack: UndoStack): UndoStack {
    return {
      ...undoStack,
      stack: undoStack.stack.slice(0, -1),
    };
  },

  undoEnabled(undoStack: UndoStack) {
    if (undoStack.loading) {
      return false;
    }
    return undoStack.stack.length > 0 ? undoStack.currentIndex >= 0 : false;
  },
  redoEnabled(undoStack: UndoStack) {
    if (undoStack.loading) {
      return false;
    }
    return undoStack.currentIndex < undoStack.stack.length - 1;
  },

  // If an id that is in an undo stack item changes, update the undo stack
  changeObjectId(
    undoStack: UndoStack,
    oldId: string,
    newId: string
  ): UndoStack {
    return {
      ...undoStack,
      stack: undoStack.stack.map((item) => {
        let wasUpdated = false;

        const newObjectIds = item.objectIds.map((objectId) => {
          if (objectId === oldId) {
            wasUpdated = true;
            return newId;
          } else {
            return objectId;
          }
        });

        if (wasUpdated) {
          return { ...item, objectIds: newObjectIds };
        } else {
          return item;
        }
      }),
    };
  },

  // Perform a relative move by "amount" in the undo stack
  async go(
    undoStack: UndoStack,
    initialState: State,
    dispatch: (action: Action) => void,
    amount: number,
    revertIfFailed = true,
    skipSyncToAPI = false
  ): Promise<number> {
    if (amount === 0) {
      return undoStack.currentIndex;
    }

    let state = initialState;

    const isMovingForwards = amount > 0;
    const startIndex = Math.max(
      Math.min(
        undoStack.currentIndex + (isMovingForwards ? 1 : 0),
        undoStack.stack.length - 1
      ),
      -1
    );
    const endIndex = Math.max(
      Math.min(undoStack.currentIndex + amount, undoStack.stack.length - 1),
      -1
    );

    let opCount = 0;

    // Move from the current index in the direction indicated by `amount`
    const loop = async (i: number): Promise<number> => {
      // console.log('LOOP ITERATION', i);
      const currentItem = state.undoStack.stack[i];
      // console.log('    ->', isMovingForwards ? 'forwards' : 'backwards', currentItem);

      const action = isMovingForwards
        ? currentItem.forwardsActionCreator(currentItem.objectIds)
        : currentItem.backwardsActionCreator(currentItem.objectIds);

      dispatch(action);
      // console.log('ACTION', action);
      state = reducer(state, action);

      let result = Promise.resolve();
      if (!skipSyncToAPI) {
        result = currentItem.syncToAPI(
          state,
          isMovingForwards ? 'forwards' : 'backwards',
          currentItem.objectIds
        );
      }

      return result
        .then(() => {
          opCount += 1;

          // console.log('OP:', isMovingForwards, i, startIndex, endIndex);
          isMovingForwards ? (i += 1) : (i -= 1);

          if (isMovingForwards ? i > endIndex : i <= endIndex) {
            // console.log('DONE!', i);
            return Promise.resolve(endIndex);
          }
          // console.log('NEXT LOOP!', i);
          return loop(i);
        })
        .catch(async (err) => {
          // Undo the optimistic update action that was applied
          const invertedAction = isMovingForwards
            ? currentItem.backwardsActionCreator(currentItem.objectIds)
            : currentItem.forwardsActionCreator(currentItem.objectIds);

          dispatch(invertedAction);
          state = reducer(state, invertedAction);

          if (revertIfFailed) {
            // Perform a "go" in the opposite direction to undo
            await UndoStack.go(
              { ...undoStack, currentIndex: i },
              state,
              dispatch,
              isMovingForwards ? -1 * opCount : opCount,
              false,
              skipSyncToAPI
            );
            return undoStack.currentIndex;
          } else {
            throw new Error(
              `Failed to run syncToAPI on undo stack item at index ${i}: ${err}`
            );
          }
        });
    };

    return loop(startIndex);
  },

  async undo(
    undoStack: UndoStack,
    initialState: State,
    dispatch: (action: Action) => void,
    skipSyncToAPI = false
  ) {
    return UndoStack.go(
      undoStack,
      initialState,
      dispatch,
      -1,
      true,
      skipSyncToAPI
    );
  },
  async redo(
    undoStack: UndoStack,
    initialState: State,
    dispatch: (action: Action) => void,
    skipSyncToAPI = false
  ) {
    return UndoStack.go(
      undoStack,
      initialState,
      dispatch,
      1,
      true,
      skipSyncToAPI
    );
  },

  // Perform an absolute move to an index in the undo stack
  async to(
    undoStack: UndoStack,
    initialState: State,
    dispatch: (action: Action) => void,
    index: number,
    skipSyncToAPI = false
  ) {
    const relativeOffset = index - undoStack.currentIndex;
    return UndoStack.go(
      undoStack,
      initialState,
      dispatch,
      relativeOffset,
      true,
      skipSyncToAPI
    );
  },
};

export type MappedPoint = {
  timestamp: number;
  sensorId: PlanSensor['id'];
  floorplanPosition: FloorplanCoordinates;
} & (
  | {
      isSimulated: false;
      sensorPoint?: PointCloud.Point;
    }
  | {
      isSimulated: true;
    }
);

export type AggregatedData = Array<MappedPoint>;
export namespace AggregatedData {
  const MAX_AGE = 10; // seconds

  export function releaseExpiredData(
    data: Array<MappedPoint>
  ): Array<MappedPoint> {
    const now = Seconds.fromMilliseconds(Date.now());
    return data.filter((point) => {
      return now - point.timestamp < MAX_AGE;
    });
  }
}

export type AreaOfConcernSensorPlacementData = {
  positionOffset: [number, number];
  heightMeters: number;
  angleDegrees: number;
  coveragePolygon: Array<[number, number]>;
};

export type AreaOfConcern = {
  id: string;
  position: FloorplanCoordinates;
  vertices: Array<FloorplanCoordinates>;
  locked: boolean;

  sensorsEnabled: boolean;

  minimumExclusiveArea: number;
  sensorHeight: number;
  sensorBaseAngleDegrees: number;
  originPosition: FloorplanCoordinates;
  cadIdPrefix: string;
  safetyFactorPercentage: number;

  coverageIntersectionHeightMapEnabled: boolean;
  coverageIntersectionWallsEnabled: boolean;
  smallRoomMode: boolean;

  sensorPlacements:
    | { type: 'empty' }
    | {
        type: 'loading';
        startTimestamp: number;
        data: Array<AreaOfConcernSensorPlacementData>;
        autodetectedRooms: Array<{
          centerPoint: FloorplanCoordinates;
          sensorPlacements: Array<FloorplanCoordinates>;
          polygon: Array<FloorplanCoordinates>;
        }>;
      }
    | {
        type: 'done';
        data: Array<AreaOfConcernSensorPlacementData>;
        autodetectedRooms: Array<{
          centerPoint: FloorplanCoordinates;
          sensorPlacements: Array<FloorplanCoordinates>;
          polygon: Array<FloorplanCoordinates>;
        }>;
        ellapsedMilliseconds: number;
      }
    | { type: 'failed'; error: Error };
};

export const AreaOfConcern = {
  create(vertices: Array<FloorplanCoordinates>): AreaOfConcern {
    const id = uuidv4();

    const position = AreaOfConcern.generateInitialOriginPosition(vertices);

    return {
      id,
      vertices,
      position,
      locked: false,

      sensorsEnabled: false,

      minimumExclusiveArea: 4,
      sensorHeight: Meters.fromFeetAndInches(8, 0),
      sensorBaseAngleDegrees: 0,
      originPosition: position,
      cadIdPrefix: '',
      safetyFactorPercentage: 65,

      coverageIntersectionHeightMapEnabled: false,
      coverageIntersectionWallsEnabled: false,
      smallRoomMode: false,

      sensorPlacements: { type: 'empty' },
    };
  },

  generateInitialOriginPosition(
    vertices: Array<FloorplanCoordinates>
  ): FloorplanCoordinates {
    // Given a polygon, generate a possible origin position to use when generating sensors within an
    // area of concern

    // The best origin position is the center of the axis aligned bounding box the vertices make up
    const [upperLeft, lowerRight] = computeBoundingRegionExtents(vertices);
    if (!upperLeft || !lowerRight) {
      throw new Error(
        `Unable to compute bounding box for ${vertices.length} vertices!`
      );
    }
    const centerOfBoundingBox = FloorplanCoordinates.create(
      upperLeft.x + (lowerRight.x - upperLeft.x) / 2,
      upperLeft.y + (lowerRight.y - upperLeft.y) / 2
    );
    let originPosition = centerOfBoundingBox;

    while (true) {
      // Check to see if the origin position is within the area of concern. If so - that's the
      // final origin position!
      const isOriginInsideAreaOfConcern =
        robustPointInPolygon(
          vertices.map((v) => [v.x, v.y]),
          [originPosition.x, originPosition.y]
        ) !== 1; /* 1 = outside polygon */
      if (isOriginInsideAreaOfConcern) {
        return originPosition;
      }

      // If not, pick a random origin position within the polygon bounding box
      originPosition = FloorplanCoordinates.create(
        Math.random() * (lowerRight.x - upperLeft.x) + upperLeft.x,
        Math.random() * (lowerRight.y - upperLeft.y) + upperLeft.y
      );
    }
  },

  edges(
    vertices: Array<FloorplanCoordinates>
  ): Array<[FloorplanCoordinates, FloorplanCoordinates]> {
    if (vertices.length <= 1) {
      return [];
    } else if (vertices.length === 2) {
      return [[vertices[0], vertices[1]]];
    } else {
      const vertexPairs: Array<[FloorplanCoordinates, FloorplanCoordinates]> =
        [];
      for (let i = 0, j = 1; j < vertices.length; i++, j++) {
        vertexPairs.push([vertices[i], vertices[j]]);
      }
      vertexPairs.push([vertices[vertices.length - 1], vertices[0]]);
      return vertexPairs;
    }
  },
};

interface LastSensorIndices {
  lastOASensorIndex?: number;
  lastEntrySensorIndex?: number;
}

export type ProcessedCADSensorPlacement = Pick<
  PlanSensor,
  'serialNumber' | 'height' | 'rotation' | 'type' | 'cadId'
> & {
  position: CADCoordinates;
};

type PlanDXFSensorPlacementDataTag = {
  height_meters: number;
  position: { x: number; y: number };
  cad_id: string;
  serial_number: string | null;
};

type PlanDXFSensorPlacement = {
  data_tag: PlanDXFSensorPlacementDataTag;
  sensor_type: 'entry' | 'oa';
  position: { x: number; y: number };
  rotation: number;
};

export type PlanDXF = {
  id: string;
  status:
    | 'created'
    | 'downloading'
    | 'parsing_dxf'
    | 'processing_dxfs'
    | 'processing_images'
    | 'complete'
    | 'error';
  format: 'autocad' | 'vectorworks' | 'plain';
  options: ParseDXFOptions;
  default_openarea_sensor_layer: string;
  default_entry_sensor_layer: string;
  layer_names: Array<string>;
  frozen_layer_names: Array<string>;
  length_unit: LengthUnit;
  scale: number;
  extent_min_x: number;
  extent_min_y: number;
  extent_max_x: number;
  extent_max_y: number;
  dxf_version: string;
  dxf_header: { [key: string]: string };
  started_processing_at: string | null;
  completed_processing_at: string | null;
  created_at: string;
  updated_at: string;
  sensor_placements: Array<PlanDXFSensorPlacement>;
  assets: Array<PlanDXFAsset>;
};

export type PlanDXFAsset = {
  id: string;
  name: string;
  content_type: string;
  object_key: string;
  object_url: string;
  layer_name: string | null;
  pixels_per_unit: number | null;
};

export type PlanExport = {
  id: string;
  status:
    | 'created'
    | 'fetching'
    | 'processing'
    | 'uploading'
    | 'complete'
    | 'error';
  options: {
    include_coverage?: boolean;
    include_walls?: boolean;
    use_base_dxf?: boolean;
    new_dxf_length_units?: LengthUnit;
  };
  content_type: 'application/dxf';
  started_processing_at: string | null;
  completed_processing_at: string | null;
  created_at: string;
  updated_at: string;
  exported_object_key: string;
  exported_object_url: string;
};

export enum LayerId {
  HEIGHTMAP = 'heightmap',
  WALLS = 'walls',
  HEATMAP = 'heatmap',
}

export type SensorPoint = {
  frameNumber: number;
  x: number;
  y: number;
  z: number;
  velocity: number;
  snr: number;
  noise: number;
};

export type TimestampedFrameInfo = {
  timestamp: number;
  frame: {
    number: number;
    buffer: ArrayBuffer;
  };
};

export type State = {
  locked: boolean;
  unsavedModifications: boolean;
  undoStack: UndoStack;
  renderOrder: 'forwards' | 'backwards';
  floorplan: Floorplan;
  floorplanImage: HTMLImageElement | null;
  floorplanImageKey: string | null;
  floorplanCADOrigin: FloorplanCoordinates;
  spaceHierarchyDataLoadingStatus: 'pending' | 'loading' | 'error' | 'complete';
  heightMap: { enabled: false } | ({ enabled: true } & HeightMap);
  heightMapUpdated: boolean;
  walls: FloorplanCollection<WallSegment>;
  wallsFullyPopulated: boolean;
  viewport: Viewport;
  spaces: FloorplanCollection<Space>;
  areasOfConcern: FloorplanCollection<AreaOfConcern>;
  planSensors: FloorplanCollection<PlanSensor>;
  sensorConnections: Map<PlanSensor['id'], SensorConnection>;
  planSensorCoverageIntersectionVectors: Map<
    PlanSensor['id'],
    'empty' | 'loading' | SensorCoverageIntersectionVectors
  >;
  lastSensorIndices?: {
    lastOASensorIndex?: number;
    lastEntrySensorIndex?: number;
  };
  references: FloorplanCollection<Reference>;
  photoGroups: FloorplanCollection<PhotoGroup>;
  photoGroupIdsToDelete: Array<PhotoGroup['id']>;
  validations: Map<
    string,
    'empty' | 'loading' | Array<SpaceValidation | SensorValidation>
  >;
  duplicateSensorParams: { rotation: number; height: number } | null;
  duplicateSpaceBoxParams: { width: number; height: number } | null;
  duplicateSpaceCircleParams: { radius: number } | null;
  duplicateSpacePolygonParams: {
    vertices: Array<FloorplanCoordinates>;
    originalPosition: FloorplanCoordinates;
  } | null;
  objectListType:
    | 'sensor'
    | 'areaofconcern'
    | 'space'
    | 'reference'
    | 'photogroup'
    | 'layer';
  placementMode: null | PlacementMode;
  highlightedObject: null | {
    type:
      | 'sensor'
      | 'areaofconcern'
      | 'space'
      | 'photogroup'
      | 'reference'
      | 'layer';
    id: string;
  };
  focusedObject: null | {
    type: 'sensor' | 'areaofconcern' | 'space' | 'layer';
    id: string;
  };
  // The focused photo group is kept track of seperately because a photo group can be focused at the
  // same time as a sensor / space
  focusedPhotoGroupId: null | string;
  spaceOccupancy: ReadonlyMap<
    Space['id'],
    {
      occupied: boolean;
      confidence: number;
      dwellTime: number;
      lastUpdate: number;
    }
  >;
  aggregatedPointsData: AggregatedData;
  aggregatedTracksData: Array<FloorplanTargetInfo>;
  planning: {
    showSensors: boolean;
    showOASensors: boolean;
    showSensorLabels: boolean;
    showSensorCoverage: boolean;
    showSensorCoverageExtents: boolean;
    showSensorPoints: boolean;
    showEntrySensors: boolean;

    showAreasOfConcern: boolean;
    showSpaces: boolean;
    showSpaceNames: boolean;
    showRulers: boolean;
    showHeights: boolean;
    showScale: boolean;
    showPhotoGroups: boolean;
    showCeilingHeightMap: boolean;
    showWalls: boolean;
    showHeatMap: boolean;
  };
  measurement?: Measurement;
  displayUnit: LengthUnit;
  // The below is duplicating `PlanState.status` but there doesn't seem to be a great way of
  // accessing that state in the Editor component
  savePending: boolean;
  lastSavedAt: string;
  boundingBoxFilter: 'none';

  scaleEdit:
    | { status: 'inactive' }
    | { status: 'fetching_image_upload_url' }
    | {
        status: 'uploading';
        fileUploadPercent: number;
      }
    | {
        status: 'measuring';
        floorplanImage: HTMLImageElement;
        floorplan: Floorplan;
        objectKey?: string;
      }
    | {
        status: 'image_registration';
        floorplanImage: HTMLImageElement;
        floorplan: Floorplan;
        objectKey?: string;
        loading: boolean;
      };

  latestDXF:
    | { status: 'uploading'; fileUploadPercent?: number }
    | { status: 'upload_error' }
    | {
        status: PlanDXF['status'];
        id: PlanDXF['id'];
        createdAt: PlanDXF['created_at'];
        parseOptions: PlanDXF['options'];
      }
    | null;
  latestDXFEdit:
    | { active: false }
    | {
        active: true;
        planDXF: PlanDXF;
        cadFileUnit: LengthUnit;
        cadFileScale: number;
        pixelsPerCADUnit: number;
        floorplanCADOrigin: FloorplanCoordinates;
        operationType: CADImportOperationType;
        parseOptions: ParseDXFOptions;
        loading: boolean;
      };
  activeDXFId: PlanDXF['id'] | null;
  activeDXFFullRasterUrl: PlanDXFAsset['object_url'] | null;

  activeExport:
    | { status: 'requesting' }
    | { status: 'request-failed' }
    | PlanExport
    | { status: 'saving' }
    | { status: 'saving-complete' }
    | { status: 'saving-failed' }
    | null;

  heightMapImport:
    | { view: 'disabled' }
    | { view: 'uploading-image'; fileName: string; fileUploadPercent?: number }
    | ({ view: 'ready' } & HeightMap)
    | ({ view: 'enabled' } & HeightMap);

  wallsEdit:
    | { active: false }
    | {
        active: true;
        imageLineSegmentImport:
          | { active: false }
          | {
              active: true;
              strokeColors: Array<string>;
              layers: Array<string>;
              lineSegments: Array<{
                id: string;
                strokeColors: Array<string>;
                layers: Array<string>;
                positionA: ImageCoordinates;
                positionB: ImageCoordinates;
              }>;
              imageWidth: number;
              imageHeight: number;
            };
        walls: FloorplanCollection<WallSegment>;
      };

  heatmap: { enabled: false } | ({ enabled: true } & Heatmap);
};

export namespace State {
  export function getInitialState(
    floorplan: Floorplan,
    viewport: Viewport,
    floorplanImage: HTMLImageElement | null,
    floorplanImageKey: string | null,
    measurement?: Measurement,
    lastSensorIndices?: LastSensorIndices
  ): State {
    return {
      locked: false,
      unsavedModifications: false,
      undoStack: UndoStack.create(),
      renderOrder: 'forwards',
      floorplan,
      floorplanImage,
      floorplanImageKey,
      floorplanCADOrigin: computeDefaultCADOrigin(floorplan),
      spaceHierarchyDataLoadingStatus: 'pending',
      heightMap: { enabled: false },
      heightMapUpdated: false,
      walls: FloorplanCollection.create(),
      wallsFullyPopulated: false,
      viewport,
      objectListType: 'sensor',
      duplicateSensorParams: null,
      duplicateSpaceBoxParams: null,
      duplicateSpaceCircleParams: null,
      duplicateSpacePolygonParams: null,
      planSensors: FloorplanCollection.create(),
      sensorConnections: new Map(),
      planSensorCoverageIntersectionVectors: new Map(),
      lastSensorIndices,
      spaces: FloorplanCollection.create(),
      areasOfConcern: FloorplanCollection.create(),
      references: FloorplanCollection.create(),
      photoGroups: FloorplanCollection.create(),
      photoGroupIdsToDelete: [],
      validations: new Map(),
      placementMode: null,
      highlightedObject: null,
      focusedObject: null,
      focusedPhotoGroupId: null,
      spaceOccupancy: new Map(),
      aggregatedPointsData: [],
      aggregatedTracksData: [],
      planning: {
        showSensors: true,
        showOASensors: true,
        showSensorLabels: true,
        showSensorCoverage: true,
        showSensorCoverageExtents: false,
        showSensorPoints: false,
        showEntrySensors: true,
        showAreasOfConcern: true,
        showSpaces: true,
        showSpaceNames: false,
        showRulers: true,
        showHeights: true,
        showScale: true,
        showPhotoGroups: true,
        showCeilingHeightMap: false,
        showWalls: false,
        showHeatMap: false,
      },
      measurement,
      displayUnit: 'feet_and_inches',
      savePending: false,
      lastSavedAt: moment.utc().format(),
      boundingBoxFilter: 'none',
      scaleEdit: { status: 'inactive' },
      latestDXF: null,
      latestDXFEdit: { active: false },
      activeDXFId: null,
      activeDXFFullRasterUrl: null,
      activeExport: null,
      heightMapImport: { view: 'disabled' },
      wallsEdit: { active: false },
      heatmap: { enabled: false },
    };
  }

  export function setViewport(state: State, viewport: Viewport): State {
    return {
      ...state,
      viewport,
    };
  }

  export function updateViewport(
    state: State,
    update: Partial<Viewport>
  ): State {
    return {
      ...state,
      viewport: {
        ...state.viewport,
        ...update,
      },
    };
  }

  export function focusLayer(state: State, layerId: LayerId): State {
    return {
      ...state,
      objectListType: 'layer',
      focusedObject: {
        type: 'layer',
        id: layerId,
      },
    };
  }

  export function isLayerFocused(state: State, layerId: LayerId) {
    return Boolean(
      state.focusedObject &&
        state.focusedObject.type === 'layer' &&
        state.focusedObject.id === layerId
    );
  }

  export function highlightLayer(state: State, layerId: LayerId): State {
    return {
      ...state,
      objectListType: 'layer',
      highlightedObject: {
        type: 'layer',
        id: layerId,
      },
    };
  }

  export function isLayerHighlighted(state: State, layerId: LayerId) {
    return Boolean(
      state.highlightedObject &&
        state.highlightedObject.type === 'layer' &&
        state.highlightedObject.id === layerId
    );
  }

  export function addAreaOfConcern(
    state: State,
    areaOfConcern: AreaOfConcern,
    addedWithUserInteraction = true
  ): State {
    return {
      ...state,
      areasOfConcern: FloorplanCollection.addItem(
        state.areasOfConcern,
        areaOfConcern
      ),
      focusedObject: addedWithUserInteraction
        ? {
            type: 'areaofconcern',
            id: areaOfConcern.id,
          }
        : state.focusedObject,
      unsavedModifications: addedWithUserInteraction,
    };
  }

  export function addSpace(
    state: State,
    space: Space,
    addedWithUserInteraction = true
  ): State {
    const nextSpaceOccupancy = new Map(state.spaceOccupancy);
    nextSpaceOccupancy.set(space.id, {
      occupied: false,
      confidence: 0,
      dwellTime: 0,
      lastUpdate: Seconds.fromMilliseconds(Date.now()),
    });

    // Put a placeholder value to tell downstream code that validations for this sensor need to
    // be computed
    const nextValidations = new Map(state.validations);
    nextValidations.set(space.id, 'empty');

    return {
      ...state,
      spaces: FloorplanCollection.addItem(state.spaces, space),
      focusedObject: addedWithUserInteraction
        ? {
            type: 'space',
            id: space.id,
          }
        : state.focusedObject,
      validations: nextValidations,
      spaceOccupancy: nextSpaceOccupancy,
      unsavedModifications: addedWithUserInteraction,
    };
  }

  export function lockSpace(
    state: State,
    id: Space['id'],
    locked: boolean
  ): State {
    const space = state.spaces.items.get(id);
    if (typeof space === 'undefined') {
      throw new Error(`Space with id ${id} not found`);
    }

    return {
      ...state,
      spaces: FloorplanCollection.updateItem(state.spaces, id, { locked }),
      unsavedModifications: true,
    };
  }

  export function updateSpace(
    state: State,
    id: Space['id'],
    update: Update<Space> | ((prev: Space) => Update<Space>),
    ignoreLocked = false
  ): State {
    const space = state.spaces.items.get(id);
    if (typeof space === 'undefined') {
      console.warn(`Cannot find space with id ${id}, skipping update...`);
      return state;
    }

    // disallow state updates for locked spaces
    if (!ignoreLocked && space.locked) {
      return state;
    }

    if (typeof update === 'function') {
      return updateSpace(state, id, update(space));
    }

    const nextValidations = new Map(state.validations);
    nextValidations.set(space.id, 'empty');

    return {
      ...state,
      spaces: FloorplanCollection.updateItem(state.spaces, id, update),
      validations: nextValidations,
      unsavedModifications: true,
    };
  }

  export function changeSpaceId(
    state: State,
    oldId: Space['id'],
    newId: Space['id']
  ): State {
    // When a space id is saved to the server, migrate it's old uuid to a new server-generated
    // id.

    // 1. Change ids in the floorplan collection
    state = {
      ...state,
      spaces: FloorplanCollection.changeId(state.spaces, oldId, newId),
      // unsavedModifications: true, // TODO: I don't think this should be set?
    };

    // Update the focused / highlghted states
    if (State.isSpaceHighlighted(state, oldId)) {
      state = State.highlightItem(state, 'space', newId);
    }
    if (State.isSpaceFocused(state, oldId)) {
      state = State.focusSpace(state, newId);
    }

    // Move `spaceOccupancy` data from old id to new id
    const spaceOccupancyItemValue = state.spaceOccupancy.get(oldId);
    if (spaceOccupancyItemValue) {
      const nextSpaceOccupancy = new Map(state.spaceOccupancy);
      nextSpaceOccupancy.delete(oldId);
      nextSpaceOccupancy.set(newId, spaceOccupancyItemValue);
      state.spaceOccupancy = nextSpaceOccupancy;
    }

    // Move `velidations` data from old id to new id
    const sensorValidations = state.validations.get(oldId);
    if (sensorValidations) {
      const nextValidations = new Map(state.validations);
      nextValidations.delete(oldId);
      nextValidations.set(newId, sensorValidations);
      state.validations = nextValidations;
    }

    // Update undo list
    state = {
      ...state,
      undoStack: UndoStack.changeObjectId(state.undoStack, oldId, newId),
    };

    return state;
  }

  export function focusAreaOfConcern(
    state: State,
    id: AreaOfConcern['id']
  ): State {
    return {
      ...state,
      spaces: FloorplanCollection.sendToFront(state.spaces, id),
      objectListType: 'areaofconcern',
      focusedObject: {
        type: 'areaofconcern',
        id,
      },
    };
  }

  export function updateAreaOfConcern(
    state: State,
    id: AreaOfConcern['id'],
    update:
      | Update<AreaOfConcern>
      | ((prev: AreaOfConcern) => Update<AreaOfConcern>),
    ignoreLocked = false
  ): State {
    const areaOfConcern = state.areasOfConcern.items.get(id);
    if (typeof areaOfConcern === 'undefined') {
      console.warn(
        `Cannot find area of concern with id ${id}, skipping update...`
      );
      return state;
    }

    // disallow state updates for locked area of concern
    if (!ignoreLocked && areaOfConcern.locked) {
      return state;
    }

    if (typeof update === 'function') {
      return updateAreaOfConcern(
        state,
        id,
        update(areaOfConcern),
        ignoreLocked
      );
    }

    return {
      ...state,
      areasOfConcern: FloorplanCollection.updateItem(
        state.areasOfConcern,
        id,
        update
      ),
      unsavedModifications: true,
    };
  }

  export function lockAreaOfConcern(
    state: State,
    id: AreaOfConcern['id'],
    locked: boolean
  ): State {
    const areasOfConcern = state.areasOfConcern.items.get(id);
    if (typeof areasOfConcern === 'undefined') {
      throw new Error(`area of concern with id ${id} not found`);
    }

    return {
      ...state,
      areasOfConcern: FloorplanCollection.updateItem(state.areasOfConcern, id, {
        locked,
      }),
      unsavedModifications: true,
    };
  }

  export function removeAreaOfConcern(
    state: State,
    id: AreaOfConcern['id']
  ): State {
    const areaOfConcern = state.areasOfConcern.items.get(id);
    if (typeof areaOfConcern === 'undefined') {
      console.warn(
        `Cannot find area of concern with id ${id}, skipping update...`
      );
      return state;
    }

    // disallow removal if locked
    if (areaOfConcern.locked) {
      return state;
    }

    return {
      ...state,
      areasOfConcern: FloorplanCollection.removeItem(state.areasOfConcern, id),
      focusedObject: null,
      unsavedModifications: true,
    };
  }

  export function highlightItem(
    state: State,
    itemType: 'sensor' | 'areaofconcern' | 'space' | 'reference' | 'photogroup',
    id: string
  ) {
    return {
      ...state,
      highlightedObject: {
        type: itemType,
        id,
      },
    };
  }

  export function highlightSpace(state: State, id: Space['id']): State {
    return {
      ...state,
      highlightedObject: {
        type: 'space',
        id,
      },
    };
  }

  export function focusSpace(state: State, id: Space['id']): State {
    return {
      ...state,
      spaces: FloorplanCollection.sendToFront(state.spaces, id),
      objectListType: 'space',
      focusedObject: {
        type: 'space',
        id,
      },
    };
  }

  export function removeSpace(
    state: State,
    id: Space['id'],
    ignoreLocked = false
  ): State {
    const space = state.spaces.items.get(id);
    if (typeof space === 'undefined') {
      console.warn(`Cannot find space with id ${id}, skipping update...`);
      return state;
    }

    // disallow removal if locked
    if (!ignoreLocked && space.locked) {
      return state;
    }

    const nextSpaceOccupancy = new Map(state.spaceOccupancy);
    nextSpaceOccupancy.delete(id);

    const nextValidations = new Map(state.validations);
    nextValidations.delete(id);

    return {
      ...state,
      spaces: FloorplanCollection.removeItem(state.spaces, id),
      focusedObject: null,
      spaceOccupancy: nextSpaceOccupancy,
      validations: nextValidations,
      unsavedModifications: true,
    };
  }

  export function updateSensorIndices(
    state: State,
    indices: LastSensorIndices
  ): State {
    return {
      ...state,
      lastSensorIndices: indices,
    };
  }

  export function addSensor(
    state: State,
    sensor: PlanSensor,
    addedWithUserInteraction = true
  ): State {
    const sensorIsOA = sensor.type === 'oa';

    const nextOASensorIndex = state.lastSensorIndices?.lastOASensorIndex
      ? state.lastSensorIndices?.lastOASensorIndex + 1
      : 1;
    const nextEntrySensorIndex = state.lastSensorIndices?.lastEntrySensorIndex
      ? state.lastSensorIndices?.lastEntrySensorIndex + 1
      : 1;
    const planSensorIndex = addedWithUserInteraction
      ? sensorIsOA
        ? nextOASensorIndex
        : nextEntrySensorIndex
      : sensor.plan_sensor_index;
    sensor = {
      ...sensor,
      plan_sensor_index: planSensorIndex,
    };

    // Put a placeholder value to tell downstream code that coverage vectors for this sensor need to
    // be computed
    const nextSensorCoverageIntersectionVectors = new Map(
      state.planSensorCoverageIntersectionVectors
    );
    nextSensorCoverageIntersectionVectors.set(sensor.id, 'empty');

    // Put a placeholder value to tell downstream code that validations for this sensor need to
    // be computed
    const nextValidations = new Map(state.validations);
    nextValidations.set(sensor.id, 'empty');

    return {
      ...state,
      planSensorCoverageIntersectionVectors:
        nextSensorCoverageIntersectionVectors,
      validations: nextValidations,
      lastSensorIndices: addedWithUserInteraction
        ? {
            lastOASensorIndex: sensorIsOA
              ? (planSensorIndex as number | undefined)
              : state.lastSensorIndices?.lastOASensorIndex,
            lastEntrySensorIndex: !sensorIsOA
              ? (planSensorIndex as number | undefined)
              : state.lastSensorIndices?.lastEntrySensorIndex,
          }
        : state.lastSensorIndices,
      planSensors: FloorplanCollection.addItem(state.planSensors, sensor),
      focusedObject: addedWithUserInteraction
        ? {
            type: 'sensor',
            id: sensor.id,
          }
        : state.focusedObject,
      unsavedModifications: addedWithUserInteraction,
    };
  }

  export function removeSensor(
    state: State,
    id: PlanSensor['id'],
    ignoreLocked = false
  ): State {
    const sensor = state.planSensors.items.get(id);
    if (typeof sensor === 'undefined') {
      console.warn(`Cannot find sensor with id ${id}, skipping update...`);
      return state;
    }

    // disallow removal if locked
    if (!ignoreLocked && sensor.locked) {
      return state;
    }

    const nextSensorConnections = state.sensorConnections;
    nextSensorConnections.delete(id);

    const nextSensorCoverageIntersectionVectors = new Map(
      state.planSensorCoverageIntersectionVectors
    );
    nextSensorCoverageIntersectionVectors.delete(sensor.id);

    const nextValidations = new Map(state.validations);
    nextValidations.delete(sensor.id);

    return {
      ...state,
      planSensors: FloorplanCollection.removeItem(state.planSensors, id),
      sensorConnections: nextSensorConnections,
      planSensorCoverageIntersectionVectors:
        nextSensorCoverageIntersectionVectors,
      validations: nextValidations,
      focusedObject: null,
      unsavedModifications: true,
    };
  }

  export function highlightSensor(state: State, id: PlanSensor['id']): State {
    return {
      ...state,
      highlightedObject: {
        type: 'sensor',
        id,
      },
    };
  }

  export function focusSensor(state: State, id: PlanSensor['id']): State {
    return {
      ...state,
      planSensors: FloorplanCollection.sendToFront(state.planSensors, id),
      objectListType: 'sensor',
      focusedObject: {
        type: 'sensor',
        id,
      },
    };
  }

  export function lockSensor(
    state: State,
    id: PlanSensor['id'],
    locked: boolean
  ): State {
    const sensor = state.planSensors.items.get(id);
    if (typeof sensor === 'undefined') {
      throw new Error(`Sensor with id ${id} not found`);
    }

    return {
      ...state,
      planSensors: FloorplanCollection.updateItem(state.planSensors, id, {
        locked,
      }),
      unsavedModifications: true,
    };
  }

  export function updateSensor(
    state: State,
    id: PlanSensor['id'],
    update: Update<PlanSensor> | ((prev: PlanSensor) => Update<PlanSensor>),
    ignoreLocked = false
  ): State {
    const sensor = state.planSensors.items.get(id);
    if (typeof sensor === 'undefined') {
      console.warn(`Cannot find sensor with id ${id}, skipping update...`);
      return state;
    }

    // disallow state updates for locked sensors
    if (!ignoreLocked && sensor.locked) {
      return state;
    }

    if (typeof update === 'function') {
      return updateSensor(state, id, update(sensor), ignoreLocked);
    }

    // If any positional attributes about the sensor change, then recompute the sensor coverage
    // vectors and validations
    let nextSensorCoverageIntersectionVectors =
      state.planSensorCoverageIntersectionVectors;
    let nextValidations = state.validations;
    if (
      typeof update.height !== 'undefined' ||
      typeof update.rotation !== 'undefined' ||
      typeof update.position !== 'undefined'
    ) {
      nextSensorCoverageIntersectionVectors = new Map(
        state.planSensorCoverageIntersectionVectors
      );
      nextSensorCoverageIntersectionVectors.set(sensor.id, 'empty');

      nextValidations = new Map(state.validations);
      nextValidations.set(sensor.id, 'empty');
    }

    return {
      ...state,
      planSensors: FloorplanCollection.updateItem(
        state.planSensors,
        id,
        update
      ),
      planSensorCoverageIntersectionVectors:
        nextSensorCoverageIntersectionVectors,
      validations: nextValidations,
      unsavedModifications: true,
    };
  }

  export function updateOrCreateSensorConnection(
    state: State,
    id: PlanSensor['id'],
    update: SensorConnection
  ): State {
    const sensor = state.planSensors.items.get(id);
    if (typeof sensor === 'undefined') {
      console.warn(`Cannot find sensor with id ${id}, skipping update...`);
      return state;
    }

    const nextSensorConnections = new Map(state.sensorConnections);
    nextSensorConnections.set(id, update);

    return {
      ...state,
      sensorConnections: nextSensorConnections,
      unsavedModifications: true,
    };
  }

  export function changeSensorId(
    state: State,
    oldId: PlanSensor['id'],
    newId: PlanSensor['id']
  ): State {
    // When a sensor id is saved to the server, migrate it's old uuid to a new server-generated
    // id.

    // 1. Change ids in the floorplan collection
    state = {
      ...state,
      planSensors: FloorplanCollection.changeId(
        state.planSensors,
        oldId,
        newId
      ),
      // unsavedModifications: true, // TODO: I don't think this should be set?
    };

    // Update the focused / highlghted states
    if (State.isSensorHighlighted(state, oldId)) {
      state = State.highlightItem(state, 'sensor', newId);
    }
    if (State.isSensorFocused(state, oldId)) {
      state = State.focusSensor(state, newId);
    }

    // Move `sensorCoverageIntersectionVectors` data from old id to new id
    const sensorCoverageIntersectionVectorsItemValue =
      state.planSensorCoverageIntersectionVectors.get(oldId);
    if (sensorCoverageIntersectionVectorsItemValue) {
      const nextSensorCoverageIntersectionVectors = new Map(
        state.planSensorCoverageIntersectionVectors
      );
      nextSensorCoverageIntersectionVectors.delete(oldId);
      nextSensorCoverageIntersectionVectors.set(
        newId,
        sensorCoverageIntersectionVectorsItemValue
      );
      state.planSensorCoverageIntersectionVectors =
        nextSensorCoverageIntersectionVectors;
    }

    // Move `velidations` data from old id to new id
    const sensorValidations = state.validations.get(oldId);
    if (sensorValidations) {
      const nextValidations = new Map(state.validations);
      nextValidations.delete(oldId);
      nextValidations.set(newId, sensorValidations);
      state.validations = nextValidations;
    }

    // Update undo list
    state = {
      ...state,
      undoStack: UndoStack.changeObjectId(state.undoStack, oldId, newId),
    };

    return state;
  }

  export function addReference(
    state: State,
    reference: Reference,
    addedWithUserInteraction = true
  ): State {
    return {
      ...state,
      references: FloorplanCollection.addItem(state.references, reference),
      unsavedModifications: addedWithUserInteraction,
    };
  }

  export function removeReference(state: State, id: Reference['id']): State {
    return {
      ...state,
      references: FloorplanCollection.removeItem(state.references, id),
      unsavedModifications: true,
    };
  }

  export function updateReference<T extends ReferenceRuler | ReferenceHeight>(
    state: State,
    id: Reference['id'],
    update: Update<T> | ((prev: T) => Update<T>)
  ): State {
    if (typeof update === 'function') {
      const reference = state.references.items.get(id) as T;

      if (typeof reference === 'undefined') {
        console.warn(`Cannot find reference with id ${id}, skipping update...`);
        return state;
      }
      return {
        ...state,
        unsavedModifications: true,
        references: FloorplanCollection.updateItem(
          state.references,
          id,
          update(reference)
        ),
      };
    }
    return {
      ...state,
      references: FloorplanCollection.updateItem(state.references, id, update),
      unsavedModifications: true,
    };
  }

  export function changeReferenceId(
    state: State,
    oldId: Reference['id'],
    newId: Reference['id']
  ): State {
    // When a reference id is saved to the server, migrate it's old uuid to a new server-generated
    // id.

    // 1. Change ids in the floorplan collection
    state = {
      ...state,
      references: FloorplanCollection.changeId(state.references, oldId, newId),
      // unsavedModifications: true, // TODO: I don't think this should be set?
    };

    // Update the highlghted states
    if (State.isReferenceHighlighted(state, oldId)) {
      state = State.highlightItem(state, 'reference', newId);
    }
    // NOTE: references cannot be focused

    // Update undo list
    state = {
      ...state,
      undoStack: UndoStack.changeObjectId(state.undoStack, oldId, newId),
    };

    return state;
  }

  export function addPhotoGroup(
    state: State,
    photoGroup: PhotoGroup,
    addedWithUserInteraction = true
  ): State {
    return {
      ...state,
      photoGroups: FloorplanCollection.addItem(state.photoGroups, photoGroup),
      unsavedModifications: addedWithUserInteraction,
    };
  }

  export function focusPhotoGroup(state: State, id: PhotoGroup['id']): State {
    return {
      ...state,
      photoGroups: FloorplanCollection.sendToFront(state.photoGroups, id),
      objectListType: 'photogroup',
      focusedPhotoGroupId: id,
    };
  }

  export function removePhotoGroup(
    state: State,
    id: PhotoGroup['id'],
    ignoreLocked = false
  ): State {
    const photoGroup = state.photoGroups.items.get(id);
    if (typeof photoGroup === 'undefined') {
      console.warn(`Cannot find photo group with id ${id}, skipping delete...`);
      return state;
    }

    // disallow removal if locked
    if (!ignoreLocked && photoGroup.locked) {
      return state;
    }

    let photoGroupIdsToDelete = state.photoGroupIdsToDelete;
    if (isFloorplanPhotoGroupId(id)) {
      photoGroupIdsToDelete = [...photoGroupIdsToDelete, id];
    }

    return {
      ...state,
      photoGroups: FloorplanCollection.removeItem(state.photoGroups, id),
      photoGroupIdsToDelete,
      focusedPhotoGroupId: null,
      unsavedModifications: true,
    };
  }

  export function updatePhotoGroup(
    state: State,
    id: Reference['id'],
    update: Update<PhotoGroup> | ((prev: PhotoGroup) => Update<PhotoGroup>),
    ignoreLocked = false
  ): State {
    const photoGroup = state.photoGroups.items.get(id) as PhotoGroup;

    // disallow state updates for locked photo groups
    if (!ignoreLocked && photoGroup.locked) {
      return state;
    }

    if (typeof update === 'function') {
      if (typeof photoGroup === 'undefined') {
        console.warn(
          `Cannot find photo group with id ${id}, skipping update...`
        );
        return state;
      }
      return {
        ...state,
        photoGroups: FloorplanCollection.updateItem(
          state.photoGroups,
          id,
          update(photoGroup)
        ),
        unsavedModifications: true,
      };
    }
    return {
      ...state,
      photoGroups: FloorplanCollection.updateItem(
        state.photoGroups,
        id,
        update
      ),
      unsavedModifications: true,
    };
  }

  export function lockPhotoGroup(state: State, id: PhotoGroup['id']): State {
    return State.updatePhotoGroup(
      state,
      id,
      (photoGroup) => PhotoGroup.lock(photoGroup),
      true
    );
  }

  export function unlockPhotoGroup(state: State, id: PhotoGroup['id']): State {
    return State.updatePhotoGroup(
      state,
      id,
      (photoGroup) => PhotoGroup.unlock(photoGroup),
      true
    );
  }

  export function appendPhotoToPhotoGroup(
    state: State,
    id: PhotoGroup['id'],
    fileName: string,
    photoDataUrl: string,
    uploadedPhotoId: string
  ): State {
    return State.updatePhotoGroup(
      state,
      id,
      (photoGroup) => {
        const photo = PhotoGroupPhoto.createFromUploadedPhotoId(
          uploadedPhotoId,
          fileName,
          photoDataUrl
        );
        return PhotoGroup.appendPhoto(photoGroup, photo);
      },
      true
    );
  }
  export function removePhotoFromPhotoGroup(
    state: State,
    id: PhotoGroup['id'],
    photoId: PhotoGroupPhoto['id']
  ): State {
    return State.updatePhotoGroup(
      state,
      id,
      (photoGroup) => {
        return PhotoGroup.removePhoto(photoGroup, photoId);
      },
      true
    );
  }
  export function changePhotoNameInPhotoGroup(
    state: State,
    id: PhotoGroup['id'],
    photoId: PhotoGroupPhoto['id'],
    name: PhotoGroupPhoto['name']
  ): State {
    return State.updatePhotoGroup(
      state,
      id,
      (photoGroup) => {
        const existingPhoto = PhotoGroup.getPhotoById(photoGroup, photoId);
        if (!existingPhoto) {
          return photoGroup;
        }

        const updatedPhoto = PhotoGroupPhoto.updateName(existingPhoto, name);

        return {
          ...photoGroup,
          photos: photoGroup.photos.map((photo) =>
            photo.id === updatedPhoto.id ? updatedPhoto : photo
          ),
        };
      },
      true
    );
  }
  export function reorderPhotosInPhotoGroup(
    state: State,
    id: PhotoGroup['id'],
    oldIndex: number,
    newIndex: number
  ): State {
    return State.updatePhotoGroup(
      state,
      id,
      (photoGroup) => {
        return {
          ...photoGroup,
          photos: arrayMove(photoGroup.photos, oldIndex, newIndex),
        };
      },
      true
    );
  }

  export function changePhotoGroupId(
    state: State,
    oldId: PhotoGroup['id'],
    newId: PhotoGroup['id']
  ): State {
    // When a photo group is saved to the server, migrate it's old uuid to a new server-generated
    // id.

    // 1. Change ids in the floorplan collection
    state = {
      ...state,
      photoGroups: FloorplanCollection.changeId(
        state.photoGroups,
        oldId,
        newId
      ),
      unsavedModifications: true,
    };

    // Update the focused / highlghted states
    if (State.isPhotoGroupHighlighted(state, oldId)) {
      state = State.highlightItem(state, 'photogroup', newId);
    }
    if (State.isPhotoGroupFocused(state, oldId)) {
      state = State.focusPhotoGroup(state, newId);
    }

    // Update undo list
    state = {
      ...state,
      undoStack: UndoStack.changeObjectId(state.undoStack, oldId, newId),
    };

    return state;
  }

  export function changePhotoGroupPhotoId(
    state: State,
    photoGroupId: PhotoGroup['id'],
    oldId: PhotoGroupPhoto['id'],
    newId: PhotoGroupPhoto['id']
  ): State {
    // When a photo group photo is saved to the server, migrate it's old uuid to a new
    // server-generated id.

    // 1. Change ids in the photoGroups floorplan collection
    state = State.updatePhotoGroup(
      state,
      photoGroupId,
      (photoGroup) => {
        const existingPhoto = PhotoGroup.getPhotoById(photoGroup, oldId);
        if (!existingPhoto) {
          return photoGroup;
        }

        return {
          ...photoGroup,
          photos: photoGroup.photos.map((photo) =>
            photo.id === oldId ? { ...photo, id: newId } : photo
          ),
        };
      },
      true
    );

    // 2. Update undo list
    state = {
      ...state,
      undoStack: UndoStack.changeObjectId(state.undoStack, oldId, newId),
    };

    return state;
  }

  export function resetAllPhotoGroupOperationAfterSave(state: State): State {
    // Loop through every photo group and reset the operation
    const nextPhotoGroupsItems = new Map(state.photoGroups.items);
    Array.from(nextPhotoGroupsItems.entries()).forEach(([id, photoGroup]) => {
      nextPhotoGroupsItems.set(
        id,
        PhotoGroup.resetOperationAfterSave(photoGroup)
      );
    });

    return {
      ...state,
      photoGroups: {
        ...state.photoGroups,
        items: nextPhotoGroupsItems,
      },
    };
  }

  export function updatePhotoGroupPhotoImageUrl(
    state: State,
    photoGroupPhotoUrls: { [photoId: string]: string }
  ): State {
    // Ensure that photos within photo groups have the most up to date "image.url" property and
    // "image.dirty" is set to false once the plan is saved and the most up to date image urls are
    // available
    const newPhotoGroupItems = new Map(state.photoGroups.items);
    FloorplanCollection.list(state.photoGroups).forEach((photoGroup) => {
      photoGroup.photos.forEach((photo, photoIndex) => {
        const newPhotoUrl = photoGroupPhotoUrls[photo.id];
        if (newPhotoUrl) {
          // Update the url of the photo within the photo group without mutating the state
          const newPhotos = [...photoGroup.photos];
          newPhotos[photoIndex] = {
            ...photo,
            image: { dirty: false, url: newPhotoUrl },
          };
          const newPhotoGroup = {
            ...photoGroup,
            photos: newPhotos,
          };
          newPhotoGroupItems.set(photoGroup.id, newPhotoGroup);
        }
      });
    });

    return {
      ...state,
      photoGroups: {
        ...state.photoGroups,
        items: newPhotoGroupItems,
      },
    };
  }

  export function unhighlight(state: State): State {
    return {
      ...state,
      highlightedObject: null,
    };
  }

  export function blurFocusedObject(state: State): State {
    return {
      ...state,
      focusedObject: null,
    };
  }

  export function blurPhotoGroup(state: State): State {
    return {
      ...state,
      focusedPhotoGroupId: null,
    };
  }

  export function blurAll(state: State): State {
    return {
      ...state,
      focusedObject: null,
      focusedPhotoGroupId: null,
    };
  }

  export function isAreaOfConcernHighlighted(
    state: State,
    areaOfConcernId: AreaOfConcern['id']
  ) {
    return Boolean(
      state.highlightedObject &&
        state.highlightedObject.type === 'areaofconcern' &&
        state.highlightedObject.id === areaOfConcernId
    );
  }

  export function isAreaOfConcernFocused(
    state: State,
    areaOfConcernId: AreaOfConcern['id']
  ) {
    return Boolean(
      state.focusedObject &&
        state.focusedObject.type === 'areaofconcern' &&
        state.focusedObject.id === areaOfConcernId
    );
  }

  export function isSpaceHighlighted(state: State, spaceId: Space['id']) {
    return Boolean(
      state.highlightedObject &&
        state.highlightedObject.type === 'space' &&
        state.highlightedObject.id === spaceId
    );
  }

  export function isSpaceFocused(state: State, spaceId: Space['id']) {
    return Boolean(
      state.focusedObject &&
        state.focusedObject.type === 'space' &&
        state.focusedObject.id === spaceId
    );
  }

  export function isSensorHighlighted(
    state: State,
    sensorId: PlanSensor['id']
  ) {
    return Boolean(
      state.highlightedObject &&
        state.highlightedObject.type === 'sensor' &&
        state.highlightedObject.id === sensorId
    );
  }

  export function isSensorFocused(state: State, sensorId: PlanSensor['id']) {
    return Boolean(
      state.focusedObject &&
        state.focusedObject.type === 'sensor' &&
        state.focusedObject.id === sensorId
    );
  }

  export function isPhotoGroupHighlighted(
    state: State,
    photoGroupId: PhotoGroup['id']
  ) {
    return Boolean(
      state.highlightedObject &&
        state.highlightedObject.type === 'photogroup' &&
        state.highlightedObject.id === photoGroupId
    );
  }

  export function isPhotoGroupFocused(
    state: State,
    photoGroupId: PhotoGroup['id']
  ) {
    return state.focusedPhotoGroupId === photoGroupId;
  }

  export function getFocusedPhotoGroup(state: State) {
    if (!state.focusedPhotoGroupId) {
      return null;
    }
    return state.photoGroups.items.get(state.focusedPhotoGroupId) || null;
  }

  export function isReferenceEnabled(
    state: State,
    referenceId: Reference['id']
  ) {
    const reference = state.references.items.get(referenceId);
    if (!reference) {
      return false;
    }

    // Height references are disabled when there is not a height map uploaded
    if (reference.type === 'height' && !state.heightMap.enabled) {
      return false;
    }

    return reference.enabled;
  }

  export function isReferenceHighlighted(
    state: State,
    referenceId: Reference['id']
  ) {
    return Boolean(
      state.highlightedObject &&
        state.highlightedObject.type === 'reference' &&
        state.highlightedObject.id === referenceId
    );
  }

  export function getFocusedSensor(state: State) {
    return (
      (state.focusedObject && state.focusedObject.type === 'sensor'
        ? state.planSensors.items.get(state.focusedObject?.id)
        : null) || null
    );
  }

  export function getFocusedAreaOfConcern(state: State) {
    return (
      (state.focusedObject && state.focusedObject.type === 'areaofconcern'
        ? state.areasOfConcern.items.get(state.focusedObject?.id)
        : null) || null
    );
  }

  export function getFocusedSpace(state: State) {
    return (
      (state.focusedObject && state.focusedObject.type === 'space'
        ? state.spaces.items.get(state.focusedObject?.id)
        : null) || null
    );
  }

  export function isCaptureAllowed(state: State): boolean {
    // make sure at least one of the sensors has a serial number
    return Array.from(state.planSensors.items.values()).some(
      (sensor) => sensor.serialNumber
    );
  }

  export function addWallSegments(
    state: State,
    wallSegments: Array<WallSegment>,
    addedWithUserInteraction = true
  ): State {
    let nextWalls = state.walls;
    for (const wallSegment of wallSegments) {
      nextWalls = FloorplanCollection.addItem(nextWalls, wallSegment);
    }
    return {
      ...state,
      walls: nextWalls,
      unsavedModifications: addedWithUserInteraction,

      // Invalidate sensor coverage now that the walls have changed
      planSensorCoverageIntersectionVectors: new Map(
        FloorplanCollection.list(state.planSensors).map((sensor) => [
          sensor.id,
          'empty',
        ])
      ),

      // Invalidate all validations now that the walls have changed
      validations: new Map(
        Array.from(state.validations).map(([id]) => [id, 'empty'])
      ),

      // Invalidate area of concern sensor placements
      areasOfConcern: FloorplanCollection.map(
        state.areasOfConcern,
        (areaOfConcern) => ({
          ...areaOfConcern,
          sensorPlacements: { type: 'empty' },
        })
      ),
    };
  }

  export function changeWallSegmentId(
    state: State,
    oldId: WallSegment['id'],
    newId: WallSegment['id']
  ): State {
    return {
      ...state,
      walls: FloorplanCollection.changeId(state.walls, oldId, newId),
      undoStack: UndoStack.changeObjectId(state.undoStack, oldId, newId),
    };
  }
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'mutation.begin': {
      const item = UndoStackItem.create(
        action.options.initialActionCreator,
        action.options.rollbackActionCreator,
        action.options.syncToAPI,
        action.objectIds
      );

      return {
        ...state,
        undoStack: UndoStack.append(state.undoStack, item),
        savePending: true,
      };
    }
    case 'mutation.commit': {
      return {
        ...state,
        savePending: false,
        lastSavedAt: moment.utc().format(),
      };
    }
    case 'mutation.rollback': {
      return {
        ...state,
        savePending: false,
        undoStack: UndoStack.removeLastItem(state.undoStack),
      };
    }
    case 'undoStack.begin': {
      return {
        ...state,
        savePending: true,
        undoStack: { ...state.undoStack, loading: true },
      };
    }
    case 'undoStack.setIndex': {
      return {
        ...state,
        lastSavedAt: moment.utc().format(),
        savePending: false,
        undoStack: {
          ...state.undoStack,
          currentIndex: action.index,
          loading: false,
        },
      };
    }
    case 'viewport.mousedown': {
      if (state.focusedObject) {
        return State.blurFocusedObject(state);
      }
      return State.blurAll(state);
    }
    case 'placement.cancel': {
      const nextState = {
        ...state,
        placementMode: null,
      };
      return nextState;
    }
    case 'placement.change': {
      return { ...state, placementMode: action.placementMode };
    }
    case 'placement.addSensor': {
      const sensor = { ...action.sensor, id: action.sensorId };
      if (state.duplicateSensorParams) {
        sensor.height = state.duplicateSensorParams.height;
        sensor.rotation = state.duplicateSensorParams.rotation;
      }
      const nextState = State.addSensor(state, sensor);
      return {
        ...nextState,
        duplicateSensorParams: null,
        // disable placement mode once the new object is placed
        placementMode: null,
        unsavedModifications: true,
      };
    }
    case 'placement.addAreaOfConcern': {
      const areaOfConcern = {
        ...action.areaOfConcern,
        id: action.areaOfConcernId,
        coverageIntersectionHeightMapEnabled: state.heightMap.enabled,
        coverageIntersectionWallsEnabled: !FloorplanCollection.isEmpty(
          state.walls
        ),
      };
      const nextState = State.addAreaOfConcern(state, areaOfConcern);
      return {
        ...nextState,
        duplicateSensorParams: null,
        // disable placement mode once the new object is placed
        placementMode: null,
        unsavedModifications: true,
      };
    }
    case 'placement.addSpace': {
      const space = { ...action.space, id: action.spaceId };
      switch (space.shape.type) {
        case 'box':
          if (state.duplicateSpaceBoxParams) {
            space.shape.width = state.duplicateSpaceBoxParams.width;
            space.shape.height = state.duplicateSpaceBoxParams.height;
          }
          break;
        case 'circle':
          if (state.duplicateSpaceCircleParams) {
            space.shape.radius = state.duplicateSpaceCircleParams.radius;
          }
          break;
        case 'polygon':
          if (state.duplicateSpacePolygonParams) {
            const originalPosition =
              state.duplicateSpacePolygonParams.originalPosition;
            // Given the vertices of the previous polygon, offset them all so that they are
            // relative to the coordinate that the user clicked on
            space.shape.vertices =
              state.duplicateSpacePolygonParams.vertices.map((vertex) => {
                const normalized = FloorplanCoordinates.create(
                  vertex.x - originalPosition.x,
                  vertex.y - originalPosition.y
                );
                return FloorplanCoordinates.create(
                  normalized.x + space.position.x,
                  normalized.y + space.position.y
                );
              });
          }
          break;
      }

      const nextState = State.addSpace(state, space);
      return {
        ...nextState,
        duplicateSpaceBoxParams: null,
        duplicateSpaceCircleParams: null,
        duplicateSpacePolygonParams: null,

        // disable placement mode once the new object is placed
        placementMode: null,
        unsavedModifications: true,
      };
    }
    case 'placement.addReference': {
      const nextState = State.addReference(state, {
        ...action.reference,
        id: action.referenceId,
      });
      return {
        ...nextState,

        // disable placement mode once the new object is placed
        placementMode: null,
        unsavedModifications: true,
      };
    }
    case 'placement.addPhotoGroup': {
      const nextState = State.addPhotoGroup(state, {
        ...action.photoGroup,
        id: action.photoGroupId,
      });
      return {
        ...nextState,

        // disable placement mode once the new object is placed
        placementMode: null,
        unsavedModifications: true,
      };
    }
    case 'sensor.remove': {
      return State.removeSensor(state, action.id);
    }
    case 'sensor.rotateRight90': {
      return State.updateSensor(state, action.id, (sensor: PlanSensor) => {
        return {
          rotation: Number(modulo(sensor.rotation + 90, 360).toFixed(1)),
        };
      });
    }
    case 'sensor.changeLocked': {
      return State.lockSensor(state, action.id, action.locked);
    }
    case 'sensor.saveNotes': {
      const id = action.id;
      const sensor = state.planSensors.items.get(id);
      if (typeof sensor === 'undefined') {
        throw new Error(`Sensor with id ${id} not found`);
      }

      return {
        ...state,
        planSensors: FloorplanCollection.updateItem(state.planSensors, id, {
          notes: action.notes,
        }),
        unsavedModifications: true,
      };
    }
    case 'sensor.changeHeight': {
      return State.updateSensor(state, action.id, (sensor) => {
        const nextHeight = Math.min(
          PlanSensor.computeMaxHeight(sensor.type),
          Math.max(PlanSensor.computeMinHeight(sensor.type), action.height)
        );
        return {
          height: nextHeight,
        };
      });
    }
    case 'sensor.changeRotation': {
      const rotation = Number(modulo(action.rotation, 360).toFixed(1));
      return State.updateSensor(state, action.id, {
        rotation,
      });
    }
    case 'sensor.changeCadId': {
      return State.updateSensor(state, action.id, {
        cadId: action.cadId,
      });
    }
    case 'sensor.changeSerialNumber': {
      let nextState = State.updateSensor(state, action.id, {
        serialNumber: action.serialNumber,

        // Reset diagnostic data
        status: undefined,
        last_heartbeat: undefined,
        mac: undefined,
        ipv4: undefined,
        ipv6: undefined,
        os: undefined,
      });

      // When changing the serial number, stop streaming data
      const existingConnection = state.sensorConnections.get(action.id);
      if (!existingConnection) {
        console.warn(`Sensor with id ${action.id} does not have a connection`);
        return nextState;
      }

      const nextConnection = {
        ...existingConnection,
        status: 'disconnected',
      } as const;

      return State.updateOrCreateSensorConnection(
        nextState,
        action.id,
        nextConnection
      );
    }
    case 'sensor.boundingBoxFilter': {
      return State.updateSensor(state, action.id, {
        // I'm unsure what state we should manipulate here
        boundingBoxFilter: action.boundingBoxFilter,
      });
    }
    case 'sensor.setDiagnosticMetadata': {
      return State.updateSensor(state, action.id, {
        status: action.status,
        last_heartbeat: action.last_heartbeat,
        mac: action.mac,
        ipv4: action.ipv4,
        ipv6: action.ipv6,
        os: action.os,
      });
    }
    case 'sensor.dismiss': {
      return State.blurFocusedObject(state);
    }
    case 'sensor.changeId': {
      return State.changeSensorId(state, action.oldId, action.newId);
    }
    case 'sensor.duplicate': {
      const nextState = State.addSensor(state, action.sensor);
      return State.focusSensor(nextState, action.sensor.id);
    }
    case 'sensor.importPageFromAPI': {
      let nextState = state;
      for (const floorplanSensor of action.responseBody.results) {
        const planSensor =
          PlanSensor.createFromFloorplanSensor(floorplanSensor);
        nextState = State.addSensor(nextState, planSensor, false);
      }
      return nextState;
    }

    // case 'sensor.address.submit': {
    //   return State.updateSensor(state, action.id, {
    //     connection: {
    //       type: 'local',
    //       localIPAddress: action.address,
    //       status: 'connecting',
    //     },
    //   });
    // }

    case 'sensor.menu.unlink': {
      // TODO: implement
      return state;
    }

    case 'sensor.menu.link': {
      // TODO: implement
      return state;
    }

    case 'sensor.menu.connect': {
      return State.updateOrCreateSensorConnection(state, action.id, {
        serialNumber: action.serialNumber,
        status: 'connecting',
      });
    }

    case 'sensor.menu.disconnect': {
      const existingConnection = state.sensorConnections.get(action.id);
      if (!existingConnection) {
        console.warn(`Sensor with id ${action.id} does not have a connection`);
        return state;
      }

      const nextConnection = {
        ...existingConnection,
        status: 'disconnected',
      } as const;

      return State.updateOrCreateSensorConnection(
        state,
        action.id,
        nextConnection
      );
    }

    case 'sensorConnection.update': {
      return State.updateOrCreateSensorConnection(
        state,
        action.id,
        action.sensorConnection
      );
    }

    case 'space.dismiss': {
      return State.blurFocusedObject(state);
    }

    case 'space.duplicate': {
      const nextState = State.addSpace(state, action.space);
      return State.focusSpace(nextState, action.space.id);
    }

    case 'space.resize.box': {
      return State.updateSpace(state, action.id, (space) => {
        if (space.shape.type !== 'box') {
          return space;
        }

        return {
          ...space,
          position: action.position,
          shape: {
            ...space.shape,
            width: action.width,
            height: action.height,
          },
        };
      });
    }

    case 'space.resize.circle': {
      return State.updateSpace(state, action.id, (space) => {
        if (space.shape.type !== 'circle') {
          return space;
        }

        return {
          ...space,
          position: action.position,
          shape: {
            ...space.shape,
            radius: action.radius,
          },
        };
      });
    }

    case 'space.resize.polygon': {
      return State.updateSpace(state, action.id, (space) => {
        if (space.shape.type !== 'polygon') {
          return space;
        }

        return {
          ...space,
          position: action.position,
          shape: {
            ...space.shape,
            vertices: action.vertices,
          },
        };
      });
    }

    case 'space.remove': {
      return State.removeSpace(state, action.id);
    }

    case 'space.changeLocked': {
      return State.lockSpace(state, action.id, action.locked);
    }

    case 'space.changeCapacity': {
      return State.updateSpace(state, action.id, {
        coreSpaceCapacity: action.capacity,
      });
    }

    case 'space.changeFunction': {
      return State.updateSpace(state, action.id, {
        coreSpaceFunction: action.function,
      });
    }

    case 'space.changeLabels': {
      return State.updateSpace(state, action.id, (space) => {
        let coreSpaceLabels = space.coreSpaceLabels;

        for (const label of action.labelsToAdd) {
          coreSpaceLabels = [...space.coreSpaceLabels, label];
        }

        for (const label of action.labelsToRemove) {
          coreSpaceLabels = coreSpaceLabels.filter((l) => l.id !== label.id);
        }

        return {
          ...space,
          coreSpaceLabels,
        };
      });
    }

    case 'space.changeName': {
      return State.updateSpace(state, action.id, {
        name: action.name,
      });
    }

    case 'space.polygon.addVertex': {
      const vertexPosition = ViewportCoordinates.toFloorplanCoordinates(
        action.position,
        state.viewport,
        state.floorplan
      );

      return State.updateSpace(state, action.id, (space) => {
        if (space.shape.type !== 'polygon') {
          return space;
        }

        const newVertices = [
          ...space.shape.vertices.slice(0, action.polygonVertexIndex),
          vertexPosition,
          ...space.shape.vertices.slice(action.polygonVertexIndex),
        ];

        return {
          ...space,
          position: calculatePolygonCentroid(newVertices),
          shape: { ...space.shape, vertices: newVertices },
        };
      });
    }

    case 'space.polygon.removeVertex': {
      return State.updateSpace(state, action.id, (space) => {
        if (space.shape.type !== 'polygon') {
          return space;
        }

        // Don't allow deleting vertices if the polygon is already a triangle
        // Polygons should always have an area and not be linear
        if (space.shape.vertices.length <= 3) {
          return space;
        }

        // Remove the vertex at the given index
        const verticesCopy = space.shape.vertices.slice();
        verticesCopy.splice(action.polygonVertexIndex, 1);

        return {
          ...space,
          position: calculatePolygonCentroid(verticesCopy),
          shape: {
            ...space.shape,
            vertices: verticesCopy,
          },
        };
      });
    }

    case 'space.changeId': {
      return State.changeSpaceId(state, action.oldId, action.newId);
    }
    case 'space.importPageFromAPI': {
      let nextState = state;
      for (const floorplanSpace of action.responseBody.results) {
        const planSpace = Space.createFromFloorplanSpace(floorplanSpace);
        nextState = State.addSpace(nextState, planSpace, false);
      }
      return nextState;
    }

    case 'areaOfConcern.resize': {
      return State.updateAreaOfConcern(state, action.id, (areaOfConcern) => {
        // Check to see if the origin position is within the area of concern. If not, move it to be
        // the center of the area of concern.
        let originPosition = areaOfConcern.originPosition;
        const isOriginInsideAreaOfConcern =
          robustPointInPolygon(
            areaOfConcern.vertices.map((v) => [v.x, v.y]),
            [originPosition.x, originPosition.y]
          ) !== 1; /* 1 = outside polygon */
        if (!isOriginInsideAreaOfConcern) {
          originPosition = AreaOfConcern.generateInitialOriginPosition(
            areaOfConcern.vertices
          );
        }

        return {
          ...areaOfConcern,
          position: action.position,
          vertices: action.vertices,
          originPosition,

          // Recompute sensor placements when an area of concern is moved
          sensorPlacements: { type: 'empty' },
        };
      });
    }

    case 'areaOfConcern.lock': {
      return State.lockAreaOfConcern(state, action.id, true);
    }
    case 'areaOfConcern.unlock': {
      return State.lockAreaOfConcern(state, action.id, false);
    }

    case 'areaOfConcern.remove': {
      return State.removeAreaOfConcern(state, action.id);
    }

    case 'areaOfConcern.dismiss': {
      return State.blurFocusedObject(state);
    }

    case 'areaOfConcern.changeSensorsEnabled': {
      return State.updateAreaOfConcern(state, action.id, (areaOfConcern) => {
        return {
          ...areaOfConcern,
          sensorsEnabled: action.enabled,
        };
      });
    }

    case 'areaOfConcern.changeOriginPosition': {
      return State.updateAreaOfConcern(state, action.id, (areaOfConcern) => {
        if (areaOfConcern.sensorPlacements.type === 'loading') {
          return areaOfConcern;
        }

        return {
          ...areaOfConcern,
          originPosition: action.origin,

          // Recompute sensor placements when an area of concern is moved
          sensorPlacements: { type: 'empty' },
        };
      });
    }

    case 'areaOfConcern.changeMinimumExclusiveArea': {
      return State.updateAreaOfConcern(state, action.id, (areaOfConcern) => {
        if (areaOfConcern.sensorPlacements.type === 'loading') {
          return areaOfConcern;
        }

        return {
          ...areaOfConcern,
          minimumExclusiveArea: action.value,

          // Recompute sensor placements when an area of concern is moved
          sensorPlacements: { type: 'empty' },
        };
      });
    }

    case 'areaOfConcern.changeSafetyFactorPercentage': {
      return State.updateAreaOfConcern(state, action.id, (areaOfConcern) => {
        if (areaOfConcern.sensorPlacements.type === 'loading') {
          return areaOfConcern;
        }

        return {
          ...areaOfConcern,
          safetyFactorPercentage: action.value,

          // Recompute sensor placements when an area of concern is moved
          sensorPlacements: { type: 'empty' },
        };
      });
    }

    case 'areaOfConcern.changeSensorHeight': {
      return State.updateAreaOfConcern(state, action.id, (areaOfConcern) => {
        if (areaOfConcern.sensorPlacements.type === 'loading') {
          return areaOfConcern;
        }

        return {
          ...areaOfConcern,
          sensorHeight: action.height,

          // Recompute sensor placements when an area of concern is moved
          sensorPlacements: { type: 'empty' },
        };
      });
    }

    case 'areaOfConcern.changeSensorBaseAngle': {
      return State.updateAreaOfConcern(state, action.id, (areaOfConcern) => {
        if (areaOfConcern.sensorPlacements.type === 'loading') {
          return areaOfConcern;
        }

        return {
          ...areaOfConcern,
          sensorBaseAngleDegrees: action.angle,

          // Recompute sensor placements when an area of concern is moved
          sensorPlacements: { type: 'empty' },
        };
      });
    }

    case 'areaOfConcern.changeCadIdPrefix': {
      return State.updateAreaOfConcern(state, action.id, (areaOfConcern) => {
        return {
          ...areaOfConcern,
          cadIdPrefix: action.prefix,

          // NOTE: DO NOT recompute sensor placements when the cad id prefix changes, because that
          // doesn't effect the resulting sensor positions.
        };
      });
    }

    case 'areaOfConcern.changeCoverageIntersectionHeightMapEnabled': {
      return State.updateAreaOfConcern(state, action.id, (areaOfConcern) => {
        return {
          ...areaOfConcern,
          coverageIntersectionHeightMapEnabled: action.enabled,

          // Recompute sensor placements when the height map flag changes
          sensorPlacements: { type: 'empty' },
        };
      });
    }

    case 'areaOfConcern.changeCoverageIntersectionWallsEnabled': {
      return State.updateAreaOfConcern(state, action.id, (areaOfConcern) => {
        return {
          ...areaOfConcern,
          coverageIntersectionWallsEnabled: action.enabled,

          // Recompute sensor placements when the wall segments flag changes
          sensorPlacements: { type: 'empty' },
        };
      });
    }

    case 'areaOfConcern.changeSmallRoomMode': {
      return State.updateAreaOfConcern(state, action.id, (areaOfConcern) => {
        return {
          ...areaOfConcern,
          smallRoomMode: action.enabled,

          // Recompute sensor placements when the wall segments flag changes
          sensorPlacements: { type: 'empty' },
        };
      });
    }

    case 'areaOfConcern.sensorPlacements.beginCalculating': {
      const now = performance.now();

      let nextState = state;
      for (const areaOfConcernId of action.areaOfConcernIds) {
        nextState = State.updateAreaOfConcern(
          nextState,
          areaOfConcernId,
          (areaOfConcern) => {
            return {
              ...areaOfConcern,
              sensorPlacements: {
                type: 'loading',
                data: [],
                autodetectedRooms: [],
                startTimestamp: now,
              },
            };
          },
          true
        );
      }
      return nextState;
    }

    case 'areaOfConcern.sensorPlacements.setData': {
      // Reject updates for areas that no longer exist
      if (!state.areasOfConcern.items.has(action.areaOfConcernId)) {
        return state;
      }

      const now = performance.now();

      return State.updateAreaOfConcern(
        state,
        action.areaOfConcernId,
        (areaOfConcern) => {
          if (!action.done) {
            return {
              ...areaOfConcern,
              sensorPlacements: {
                type: 'loading',
                data: action.data,
                autodetectedRooms: action.autodetectedRooms,
                startTimestamp:
                  areaOfConcern.sensorPlacements.type === 'loading'
                    ? areaOfConcern.sensorPlacements.startTimestamp
                    : performance.now(),
              },
            };
          }

          let ellapsedMilliseconds = 0;
          switch (areaOfConcern.sensorPlacements.type) {
            case 'loading':
              ellapsedMilliseconds =
                now - areaOfConcern.sensorPlacements.startTimestamp;
              break;
            case 'failed':
              return areaOfConcern;
            case 'done':
              ellapsedMilliseconds =
                areaOfConcern.sensorPlacements.ellapsedMilliseconds;
              break;
          }

          return {
            ...areaOfConcern,
            sensorPlacements: {
              type: 'done',
              data: action.data,
              autodetectedRooms: action.autodetectedRooms,
              ellapsedMilliseconds,
            },
          };
        },
        true
      );
    }
    case 'areaOfConcern.sensorPlacements.failWithError': {
      // Reject updates for areas that no longer exist
      if (!state.areasOfConcern.items.has(action.areaOfConcernId)) {
        return state;
      }

      return State.updateAreaOfConcern(
        state,
        action.areaOfConcernId,
        (areaOfConcern) => {
          return {
            ...areaOfConcern,
            sensorPlacements: {
              type: 'failed',
              error: action.error,
            },
          };
        },
        true
      );
    }

    case 'areaOfConcern.swapSensors': {
      let nextState = state;

      // Delete all sensors that are within the area of concern
      for (const { id } of action.existingPlanSensors) {
        nextState = State.removeSensor(nextState, id, true);
      }

      // Add in all new plan sensors that were created during the swap process
      for (const planSensor of action.newPlanSensors) {
        nextState = State.addSensor(nextState, planSensor, false);
      }

      return State.updateAreaOfConcern(
        nextState,
        action.areaOfConcernId,
        (areaOfConcern) => ({
          ...areaOfConcern,
          // Disable sensor placements in area of concern
          sensorsEnabled: false,
        })
      );
    }

    case 'reference.changeEnabled': {
      return State.updateReference<ReferenceRuler | ReferenceHeight>(
        state,
        action.id,
        (reference) => {
          return {
            enabled: action.enabled,
          };
        }
      );
    }

    case 'reference.remove': {
      return State.removeReference(state, action.id);
    }

    case 'reference.duplicate': {
      return State.addReference(state, action.reference);
    }

    case 'reference.heightPosition.change': {
      return State.updateReference<ReferenceHeight>(
        state,
        action.id,
        (reference) => {
          let update: Update<ReferenceHeight> = {};
          update.position = action.position;
          update.heightMeters = { step: 'loading' as const };
          return update;
        }
      );
    }

    case 'reference.heightPosition.changeHeight': {
      return State.updateReference<ReferenceHeight>(
        state,
        action.id,
        (reference) => {
          let update: Update<ReferenceHeight> = {};
          update.heightMeters = {
            step: 'complete' as const,
            value: action.heightMetersValue,
          };
          return update;
        }
      );
    }

    case 'reference.rulerPosition.change': {
      return State.updateReference<ReferenceRuler>(
        state,
        action.id,
        (reference) => {
          // Clear the cached cursor position
          let update: Update<ReferenceRuler> = {};

          update.positionA = action.positionA;
          update.positionB = action.positionB;
          update.distanceLabelPosition = action.distanceLabelPosition;

          // Move distance marker to keep it in the center if needed
          if (reference.currentIsDistanceLockedToCenter) {
            update.distanceLabelPosition =
              ReferenceRuler.calculateCenterPoint(reference);
          }

          return update;
        }
      );
    }

    case 'reference.changeId': {
      return State.changeReferenceId(state, action.oldId, action.newId);
    }

    case 'reference.importReferenceRulerPageFromAPI': {
      let nextState = state;
      for (const floorplanReferenceRuler of action.responseBody.results) {
        const referenceRuler = ReferenceRuler.createFromFloorplanReferenceRuler(
          floorplanReferenceRuler
        );
        nextState = State.addReference(nextState, referenceRuler, false);
      }
      return nextState;
    }
    case 'reference.importReferenceHeightPageFromAPI': {
      let nextState = state;
      for (const floorplanReferenceHeight of action.responseBody.results) {
        const referenceHeight =
          ReferenceHeight.createFromFloorplanReferenceHeight(
            floorplanReferenceHeight
          );
        nextState = State.addReference(nextState, referenceHeight, false);
      }
      return nextState;
    }

    case 'settingsPanel.setDisplayUnit': {
      return {
        ...state,
        displayUnit: action.displayUnit,
        unsavedModifications: true,
      };
    }

    case 'photoGroup.remove': {
      return State.removePhotoGroup(state, action.id);
    }

    case 'photoGroup.dragmove': {
      return State.updatePhotoGroup(
        state,
        action.id,
        (photoGroup) => {
          return {
            ...photoGroup,
            position: action.itemPosition,
          };
        },
        true
      );
    }

    case 'photoGroup.changeLocked': {
      if (action.locked) {
        return State.lockPhotoGroup(state, action.id);
      } else {
        return State.unlockPhotoGroup(state, action.id);
      }
    }

    case 'photoGroup.changeName': {
      return State.updatePhotoGroup(state, action.id, (photoGroup) =>
        PhotoGroup.changeName(photoGroup, action.name)
      );
    }

    case 'photoGroup.saveNotes': {
      return State.updatePhotoGroup(state, action.id, (photoGroup) =>
        PhotoGroup.changeNotes(photoGroup, action.notes)
      );
    }

    case 'photoGroup.dismiss': {
      return State.blurPhotoGroup(state);
    }

    case 'photoGroup.changeId': {
      return State.changePhotoGroupId(state, action.oldId, action.newId);
    }

    case 'photoGroup.photos.changeId': {
      return State.changePhotoGroupPhotoId(
        state,
        action.photoGroupId,
        action.oldId,
        action.newId
      );
    }

    case 'photoGroup.photos.append': {
      return State.appendPhotoToPhotoGroup(
        state,
        action.id,
        action.fileName,
        action.photoDataUrl,
        action.uploadedPhotoId
      );
    }
    case 'photoGroup.photos.add': {
      return State.updatePhotoGroup(
        state,
        action.id,
        (photoGroup) => {
          return PhotoGroup.appendPhoto(photoGroup, action.photo);
        },
        true
      );
    }
    case 'photoGroup.photos.remove': {
      return State.removePhotoFromPhotoGroup(state, action.id, action.photoId);
    }
    case 'photoGroup.photos.changeName': {
      return State.changePhotoNameInPhotoGroup(
        state,
        action.id,
        action.photoId,
        action.name
      );
    }
    case 'photoGroup.photos.updateWithUploadUrl': {
      return State.updatePhotoGroup(
        state,
        action.id,
        (photoGroup) => {
          const existingPhoto = PhotoGroup.getPhotoById(
            photoGroup,
            action.photoId
          );
          if (!existingPhoto) {
            return photoGroup;
          }

          const updatedPhoto = {
            ...existingPhoto,
            image: {
              dirty: false as const,
              url: action.uploadUrl,
            },
          };

          return {
            ...photoGroup,
            photos: photoGroup.photos.map((photo) =>
              photo.id === updatedPhoto.id ? updatedPhoto : photo
            ),
          };
        },
        true
      );
    }
    case 'photoGroup.photos.reorder': {
      return State.reorderPhotosInPhotoGroup(
        state,
        action.id,
        action.oldIndex,
        action.newIndex
      );
    }
    case 'photoGroup.importPageFromAPI': {
      let nextState = state;
      for (const floorplanPhotoGroup of action.responseBody.results) {
        const photoGroup =
          PhotoGroup.createFromFloorplanPhotoGroup(floorplanPhotoGroup);
        nextState = State.addPhotoGroup(nextState, photoGroup, false);
      }
      return nextState;
    }

    case 'scaleEdit.begin': {
      return {
        ...state,
        scaleEdit: {
          status: 'fetching_image_upload_url',
        },
      };
    }

    case 'scaleEdit.beginUploading': {
      if (state.scaleEdit.status !== 'fetching_image_upload_url') {
        return state;
      }

      return {
        ...state,
        scaleEdit: {
          status: 'uploading',
          fileUploadPercent: 0,
        },
      };
    }

    case 'scaleEdit.uploadProgress': {
      if (state.scaleEdit.status !== 'uploading') {
        return state;
      }
      return {
        ...state,
        scaleEdit: {
          ...state.scaleEdit,
          fileUploadPercent: action.percent,
        },
      };
    }

    case 'scaleEdit.edit': {
      if (
        state.scaleEdit.status !== 'uploading' &&
        state.scaleEdit.status !== 'inactive'
      ) {
        return state;
      }

      const image = action.image || state.floorplanImage;
      if (!image) {
        return state;
      }

      return {
        ...state,
        scaleEdit: {
          status: action.openIntoMeasureMode
            ? 'measuring'
            : 'image_registration',
          floorplanImage: image,
          floorplan: {
            ...state.floorplan,
            width: image.width,
            height: image.height,
          },
          objectKey: action.objectKey,
          loading: false,
        },
      };
    }

    case 'scaleEdit.startLoading': {
      if (state.scaleEdit.status !== 'image_registration') {
        return state;
      }

      return {
        ...state,
        scaleEdit: {
          ...state.scaleEdit,
          loading: true,
        },
      };
    }

    case 'scaleEdit.error': {
      if (state.scaleEdit.status !== 'image_registration') {
        return state;
      }

      return {
        ...state,
        scaleEdit: {
          ...state.scaleEdit,
          loading: false,
        },
      };
    }

    case 'scaleEdit.cancel': {
      return {
        ...state,
        scaleEdit: { status: 'inactive' },
      };
    }

    case 'scaleEdit.submit': {
      if (state.scaleEdit.status === 'inactive') {
        return state;
      }
      return {
        ...state,
        scaleEdit: { status: 'inactive' },
      };
    }

    case 'scaleAndImage.change': {
      let nextState = state;
      if (
        // If new origin parameters were specified, then offset the floorplan by these
        typeof action.newOriginX !== 'undefined' &&
        typeof action.newOriginY !== 'undefined' &&
        typeof action.newRotationDegrees !== 'undefined'
      ) {
        nextState = {
          ...nextState,
          floorplan: {
            ...nextState.floorplan,
            origin: ImageCoordinates.create(
              action.newOriginX,
              action.newOriginY
            ),
            rotation: modulo(action.newRotationDegrees, 360),
          },
        };
      }

      const image = action.floorplanImage || state.floorplanImage;
      const imageKey = action.floorplanImageKey || state.floorplanImageKey;
      return {
        ...nextState,
        floorplanImage: image,
        floorplanImageKey: imageKey,
        measurement: action.measurement,
        floorplan: {
          ...nextState.floorplan,
          width: image ? image.width : 0,
          height: image ? image.height : 0,
          scale: action.measurement.computedScale,
        },
        unsavedModifications: true,
      };
    }

    case 'menu.showSensors': {
      return {
        ...state,
        objectListType: 'sensor',
      };
    }
    case 'menu.showAreasOfConcern': {
      return {
        ...state,
        objectListType: 'areaofconcern',
      };
    }
    case 'menu.showSpaces': {
      return {
        ...state,
        objectListType: 'space',
      };
    }
    case 'menu.showReferences': {
      return {
        ...state,
        objectListType: 'reference',
      };
    }
    case 'menu.showPhotoGroups': {
      return {
        ...state,
        objectListType: 'photogroup',
      };
    }
    case 'menu.showLayers': {
      return {
        ...state,
        objectListType: 'layer',
      };
    }

    case 'menu.addSensor': {
      // Disable placing a sensor if the mode is already active
      if (
        state.placementMode &&
        state.placementMode.type === 'sensor' &&
        state.placementMode.sensorType === action.sensorType
      ) {
        return {
          ...state,
          placementMode: null,
        };
      }

      return {
        ...state,
        placementMode: {
          type: 'sensor',
          sensorType: action.sensorType,
          mousePosition: null,
        },
      };
    }
    case 'menu.addAreaOfConcern': {
      // Disable placing an area of concern if the mode is already active
      if (state.placementMode && state.placementMode.type === 'areaofconcern') {
        return {
          ...state,
          placementMode: null,
        };
      }

      return {
        ...state,
        placementMode: {
          type: 'areaofconcern',
          vertices: [],
          mouseOverFinalPoint: false,
          nextPointSelfIntersection: null,
          mousePosition: null,
          nextPointPosition: null,
        },
      };
    }
    case 'menu.addSpace': {
      // Disable placing a space if the mode is already active
      if (
        state.placementMode &&
        state.placementMode.type === 'space' &&
        state.placementMode.shape === action.shape
      ) {
        return {
          ...state,
          placementMode: null,
        };
      }

      let nextState: State;
      if (action.shape === 'polygon') {
        nextState = {
          ...state,
          placementMode: {
            type: 'space',
            shape: action.shape,
            vertices: [],
            mouseOverFinalPoint: false,
            nextPointSelfIntersection: null,
            mousePosition: null,
            nextPointPosition: null,
          },
        };
      } else {
        nextState = {
          ...state,
          placementMode: {
            type: 'space',
            shape: action.shape,
            mousePosition: null,
          },
        };
      }
      return nextState;
    }
    case 'menu.addReference': {
      // Disable placing a reference if the mode is already active
      if (
        state.placementMode &&
        state.placementMode.type === 'reference' &&
        state.placementMode.referenceType === action.referenceType
      ) {
        return {
          ...state,
          placementMode: null,
        };
      }

      switch (action.referenceType) {
        case 'ruler':
          return {
            ...state,
            placementMode: {
              type: 'reference',
              referenceType: 'ruler',
              positionA: null,
              positionB: null,
              mousePosition: null,
            },
          };
        case 'height':
          return {
            ...state,
            placementMode: {
              type: 'reference',
              referenceType: 'height',
              mousePosition: null,
            },
          };
        default:
          return state;
      }
    }
    case 'menu.addPhotoGroup': {
      // Disable placing a photo group if the mode is already active
      if (state.placementMode && state.placementMode.type === 'photogroup') {
        return {
          ...state,
          placementMode: null,
        };
      }

      return {
        ...state,
        placementMode: {
          type: 'photogroup',
          mousePosition: null,
        },
      };
    }
    case 'menu.removeFocusedObject': {
      if (state.focusedPhotoGroupId) {
        return State.removePhotoGroup(state, state.focusedPhotoGroupId);
      }

      if (!state.focusedObject) {
        return state;
      }

      switch (state.focusedObject.type) {
        case 'sensor':
          return State.removeSensor(state, state.focusedObject.id);
        case 'areaofconcern':
          return State.removeAreaOfConcern(state, state.focusedObject.id);
        case 'space':
          return State.removeSpace(state, state.focusedObject.id);
        default:
          return state;
      }
    }
    case 'menu.duplicateSensor': {
      const sensor = state.planSensors.items.get(action.id);
      if (typeof sensor === 'undefined') {
        throw new Error(`Sensor with id ${action.id} not found`);
      }
      return {
        ...state,
        duplicateSensorParams: {
          height: sensor.height,
          rotation: sensor.rotation,
        },
        placementMode: {
          type: 'sensor',
          sensorType: sensor.type,
          mousePosition: null,
        },
      };
    }

    case 'menu.duplicateSpace': {
      const space = state.spaces.items.get(action.id);
      if (typeof space === 'undefined') {
        throw new Error(`Space with id ${action.id} not found`);
      }

      let update: Partial<
        Pick<
          State,
          | 'duplicateSpaceBoxParams'
          | 'duplicateSpaceCircleParams'
          | 'duplicateSpacePolygonParams'
        >
      > = {};

      if (space.shape.type === 'box') {
        update = {
          duplicateSpaceBoxParams: {
            width: space.shape.width,
            height: space.shape.height,
          },
        };
      }
      if (space.shape.type === 'circle') {
        update = {
          duplicateSpaceCircleParams: {
            radius: space.shape.radius,
          },
        };
      }
      if (space.shape.type === 'polygon') {
        update = {
          duplicateSpacePolygonParams: {
            vertices: space.shape.vertices,
            originalPosition: space.position,
          },
        };
      }
      return {
        ...state,
        placementMode: {
          type: 'space',
          shape:
            space.shape.type === 'polygon'
              ? 'polygon-duplicate'
              : space.shape.type,
          mousePosition: null,
        },
        ...update,
      };
    }

    case 'menu.flipRenderOrder': {
      return {
        ...state,
        renderOrder:
          state.renderOrder === 'forwards' ? 'backwards' : 'forwards',
      };
    }

    case 'menu.planning.toggleSensors': {
      // Deselect focused sensor if one is focused
      if (state.focusedObject && state.focusedObject.type === 'sensor') {
        state = State.blurFocusedObject(state);
      }

      const newShowSensors = !state.planning.showSensors;

      return {
        ...state,
        planning: {
          ...state.planning,
          showSensors: newShowSensors,
          showEntrySensors: newShowSensors,
          showOASensors: newShowSensors,
          showSensorLabels: newShowSensors,
          showSensorCoverage: newShowSensors,
          showSensorCoverageExtents: false,
          showSensorPoints: false,
        },
      };
    }

    case 'menu.planning.toggleEntrySensors': {
      // Deselect focused sensor if one is focused
      if (state.focusedObject && state.focusedObject.type === 'sensor') {
        state = State.blurFocusedObject(state);
      }

      return {
        ...state,
        planning: {
          ...state.planning,
          showSensors: true,
          showEntrySensors: !state.planning.showEntrySensors,
        },
      };
    }

    case 'menu.planning.toggleOASensors': {
      // Deselect focused sensor if one is focused
      if (state.focusedObject && state.focusedObject.type === 'sensor') {
        state = State.blurFocusedObject(state);
      }

      const newShowOASensors = !state.planning.showOASensors;
      return {
        ...state,
        planning: {
          ...state.planning,
          showSensors: true,
          showOASensors: newShowOASensors,
          showSensorLabels: newShowOASensors,
          showSensorCoverage: newShowOASensors,
          showSensorCoverageExtents: false,
          showSensorPoints: false,
        },
      };
    }

    case 'menu.planning.toggleSensorLabels': {
      return {
        ...state,
        planning: {
          ...state.planning,
          showSensors: true,
          showOASensors: true,
          showSensorLabels: !state.planning.showSensorLabels,
        },
      };
    }

    case 'menu.planning.toggleSensorCoverage': {
      return {
        ...state,
        planning: {
          ...state.planning,
          showSensors: true,
          showOASensors: true,
          showSensorCoverage: !state.planning.showSensorCoverage,
        },
      };
    }

    case 'menu.planning.toggleSensorCoverageExtents': {
      return {
        ...state,
        planning: {
          ...state.planning,
          showSensors: true,
          showOASensors: true,
          showSensorCoverageExtents: !state.planning.showSensorCoverageExtents,
        },
      };
    }

    case 'menu.planning.toggleSensorPoints': {
      return {
        ...state,
        planning: {
          ...state.planning,
          showSensors: true,
          showOASensors: true,
          showSensorPoints: !state.planning.showSensorPoints,
        },
      };
    }

    case 'menu.planning.toggleAreasOfConcern': {
      // Deselect focused area of concern if one is focused
      if (state.focusedObject && state.focusedObject.type === 'areaofconcern') {
        state = State.blurFocusedObject(state);
      }

      return {
        ...state,
        planning: {
          ...state.planning,
          showAreasOfConcern: !state.planning.showAreasOfConcern,
        },
      };
    }

    case 'menu.planning.toggleSpaces': {
      // Deselect focused space / area if one is focused
      if (state.focusedObject && state.focusedObject.type === 'space') {
        state = State.blurFocusedObject(state);
      }

      return {
        ...state,
        planning: {
          ...state.planning,
          showSpaces: !state.planning.showSpaces,
          showSpaceNames: false,
        },
      };
    }

    case 'menu.planning.toggleSpaceNames': {
      return {
        ...state,
        planning: {
          ...state.planning,
          showSpaceNames: !state.planning.showSpaceNames,
        },
      };
    }

    case 'menu.planning.togglePhotoGroups': {
      state = State.blurPhotoGroup(state);
      return {
        ...state,
        planning: {
          ...state.planning,
          showPhotoGroups: !state.planning.showPhotoGroups,
        },
      };
    }

    case 'menu.planning.toggleCeilingHeightMap': {
      return {
        ...state,
        planning: {
          ...state.planning,
          showCeilingHeightMap: !state.planning.showCeilingHeightMap,
        },
      };
    }

    case 'menu.planning.toggleWalls': {
      return {
        ...state,
        planning: {
          ...state.planning,
          showWalls: !state.planning.showWalls,
        },
      };
    }

    case 'menu.planning.toggleHeatMap': {
      const newShowHeatMap = !state.planning.showHeatMap;

      // Turn on the heatmap if this is the first time the layer is being shown
      let nextHeatmap = state.heatmap;
      if (newShowHeatMap && !nextHeatmap.enabled) {
        nextHeatmap = { ...Heatmap.create(), enabled: true };
      }

      return {
        ...state,
        planning: {
          ...state.planning,
          showHeatMap: newShowHeatMap,
        },
        heatmap: nextHeatmap,
      };
    }

    case 'menu.planning.toggleRulers': {
      return {
        ...state,
        planning: {
          ...state.planning,
          showRulers: !state.planning.showRulers,
        },
      };
    }

    case 'menu.planning.toggleHeights': {
      return {
        ...state,
        planning: {
          ...state.planning,
          showHeights: !state.planning.showHeights,
        },
      };
    }

    case 'menu.planning.toggleScale': {
      return {
        ...state,
        planning: {
          ...state.planning,
          showScale: !state.planning.showScale,
        },
      };
    }

    case 'sensorList.item.mouseenter': {
      return State.highlightSensor(state, action.id);
    }
    case 'sensorList.item.mouseleave': {
      return State.unhighlight(state);
    }
    case 'spaceList.item.mouseenter': {
      return State.highlightSpace(state, action.id);
    }
    case 'spaceList.item.mouseleave': {
      return State.unhighlight(state);
    }
    case 'algorithm.releaseExpiredAggregateData': {
      return {
        ...state,
        aggregatedPointsData: AggregatedData.releaseExpiredData(
          state.aggregatedPointsData
        ),
      };
    }

    case 'algorithm.spaceOccupancyConfidenceChange': {
      const { confidence } = action;

      const RISING_THRESHOLD = 0.75;
      const FALLING_THRESHOLD = 0.25;

      const prevValue = state.spaceOccupancy.get(action.id);
      if (typeof prevValue === 'undefined') {
        throw new Error(`No spaceOccupancy entry for id ${action.id}`);
      }

      const nextValue = {
        ...prevValue,
        confidence,
        lastUpdate: action.timestamp,
      };

      const timeDelta = action.timestamp - prevValue.lastUpdate;

      if (prevValue.occupied) {
        if (confidence < FALLING_THRESHOLD) {
          nextValue.occupied = false;
        }
        nextValue.dwellTime += timeDelta;
      } else {
        if (confidence > RISING_THRESHOLD) {
          nextValue.occupied = true;
        }
      }

      const nextSpaceOccupancy = new Map(state.spaceOccupancy);
      nextSpaceOccupancy.set(action.id, nextValue);

      return {
        ...state,
        spaceOccupancy: nextSpaceOccupancy,
      };
    }

    case 'item.menu.mouseenter':
    case 'item.graphic.mouseenter': {
      const { itemType } = action;
      if (
        itemType === 'sensor' ||
        itemType === 'areaofconcern' ||
        itemType === 'space' ||
        itemType === 'reference' ||
        itemType === 'photogroup'
      ) {
        return State.highlightItem(state, itemType, action.itemId);
      }
      return state;
    }

    case 'item.menu.mouseleave':
    case 'item.graphic.mouseleave': {
      const { itemType } = action;
      if (
        itemType === 'sensor' ||
        itemType === 'areaofconcern' ||
        itemType === 'space' ||
        itemType === 'reference' ||
        itemType === 'photogroup'
      ) {
        return State.unhighlight(state);
      }
      return state;
    }

    case 'item.menu.mousedown': {
      const { itemType } = action;
      if (itemType === 'sensor') {
        return State.focusSensor(state, action.itemId);
      }
      if (itemType === 'areaofconcern') {
        return State.focusAreaOfConcern(state, action.itemId);
      }
      if (itemType === 'space') {
        return State.focusSpace(state, action.itemId);
      }
      if (itemType === 'photogroup') {
        return State.focusPhotoGroup(state, action.itemId);
      }
      return state;
    }

    case 'item.graphic.mousedown': {
      let { itemType, itemId, itemPosition } = action;

      const position = ViewportCoordinates.toFloorplanCoordinates(
        itemPosition,
        state.viewport,
        state.floorplan
      );

      let nextState = state;

      if (itemType === 'sensor') {
        nextState = State.focusSensor(state, action.itemId);
      }
      if (itemType === 'areaofconcern') {
        nextState = State.focusAreaOfConcern(state, action.itemId);
      }
      if (itemType === 'space') {
        nextState = State.focusSpace(state, action.itemId);
      }
      if (itemType === 'photogroup') {
        nextState = State.focusPhotoGroup(state, action.itemId);
      }
      if (itemType === 'reference') {
        nextState = State.updateReference<ReferenceRuler>(
          state,
          action.itemId,
          (reference) => {
            // Cache the "isDistanceLockedToCenter" value so that the label reference point stays
            // pinned to the center if needed when the whole reference line moves
            const centerPoint = ReferenceRuler.calculateCenterPoint(reference);
            const isDistanceLockedToCenter =
              ReferenceRuler.isDistanceLockedToCenter(
                state.floorplan,
                state.viewport,
                reference.distanceLabelPosition,
                centerPoint
              );
            return {
              currentIsDistanceLockedToCenter: isDistanceLockedToCenter,
            };
          }
        );
      }

      if (itemType === 'reference' && action.altKey) {
        const reference = state.references.items.get(itemId);
        if (!reference) {
          throw new Error(
            'Reference duplicate dragged but original did not exist!'
          );
        }

        let newReference: Reference;
        if (reference.type === 'ruler') {
          newReference = Reference.createRuler(
            reference.positionA,
            reference.positionB
          );
        } else if (reference.type === 'height') {
          newReference = Reference.createHeight(position);
        } else {
          return state;
        }
        nextState = State.addReference(state, newReference);
        itemId = newReference.id;
      }

      return {
        ...nextState,

        // Reset render order when a graphic is selected
        renderOrder: 'forwards',
      };
    }

    case 'item.graphic.dragmove': {
      const position = action.itemPosition;

      const nextState = state;

      const { itemType, itemId } = action;
      if (itemType === 'sensor') {
        return State.updateSensor(nextState, itemId, {
          position,
        });
      }
      if (itemType === 'space') {
        return State.updateSpace(nextState, itemId, (space) => {
          if (space.shape.type !== 'polygon') {
            return { ...space, position };
          }

          // Polygonal spaces should have all their vertices translated the same amount as the
          // center
          const positionDeltaX = position.x - space.position.x;
          const positionDeltaY = position.y - space.position.y;
          return {
            ...space,
            position,
            shape: {
              ...space.shape,
              vertices: space.shape.vertices.map((v) =>
                FloorplanCoordinates.create(
                  v.x + positionDeltaX,
                  v.y + positionDeltaY
                )
              ),
            },
          };
        });
      }
      if (itemType === 'areaofconcern') {
        return State.updateAreaOfConcern(nextState, itemId, (areaOfConcern) => {
          // Areas of concern should have all their vertices translated the same amount
          // as the center
          const positionDeltaX = position.x - areaOfConcern.position.x;
          const positionDeltaY = position.y - areaOfConcern.position.y;

          return {
            ...areaOfConcern,
            position,
            originPosition: FloorplanCoordinates.create(
              areaOfConcern.originPosition.x + positionDeltaX,
              areaOfConcern.originPosition.y + positionDeltaY
            ),
            vertices: areaOfConcern.vertices.map((v) =>
              FloorplanCoordinates.create(
                v.x + positionDeltaX,
                v.y + positionDeltaY
              )
            ),

            // Recalculate sensor placements
            sensorPlacements: { type: 'empty' },
          };
        });
      }
      if (itemType === 'reference') {
        const reference = state.references.items.get(itemId);
        if (!reference) {
          return state;
        }

        switch (reference.type) {
          case 'ruler': {
            const previousPosition = action.itemPosition;
            const dx = position.x - previousPosition.x;
            const dy = position.y - previousPosition.y;

            const ruler = state.references.items.get(itemId) as
              | ReferenceRuler
              | undefined;

            if (!ruler) {
              return state;
            }
            const positionA = {
              ...ruler.positionA,
              x: ruler.positionA.x + dx,
              y: ruler.positionA.y + dy,
            };
            const positionB = {
              ...ruler.positionB,
              x: ruler.positionB.x + dx,
              y: ruler.positionB.y + dy,
            };

            // Move distance marker to keep it in the center if needed
            let distanceLabelPosition = ruler.distanceLabelPosition;
            if (reference.currentIsDistanceLockedToCenter) {
              const centerPoint = ReferenceRuler.calculateCenterPoint(ruler);
              distanceLabelPosition = {
                ...distanceLabelPosition,
                x: centerPoint.x,
                y: centerPoint.y,
              };
            }

            return State.updateReference<ReferenceRuler>(nextState, itemId, {
              positionA,
              positionB,
              distanceLabelPosition,
            });
          }
          case 'height': {
            return State.updateReference<ReferenceHeight>(nextState, itemId, {
              position,
            });
          }
        }
      }
      if (itemType === 'photogroup') {
        return State.updatePhotoGroup(nextState, itemId, (photoGroup) => {
          return {
            position,
            operationToPerform: PhotoGroup.computeOperationAfterModification(
              photoGroup.operationToPerform
            ),
          };
        });
      }
      return nextState;
    }

    case 'item.graphic.dragend': {
      return state;
    }

    case 'save.pending': {
      return { ...state, savePending: true };
    }

    case 'save.error': {
      // FIXME: for now this has no effect
      return { ...state, savePending: false };
    }

    case 'save.success': {
      return action.newState;
    }

    case 'latestDXF.uploadBegin': {
      let nextState = State.blurFocusedObject(state);
      nextState = State.blurPhotoGroup(nextState);
      return {
        ...nextState,
        latestDXF: {
          status: 'uploading',
        },
      };
    }
    case 'latestDXF.uploadProgress': {
      if (!state.latestDXF || state.latestDXF.status !== 'uploading') {
        return state;
      }
      return {
        ...state,
        latestDXF: {
          ...state.latestDXF,
          fileUploadPercent: action.fileUploadPercent,
        },
      };
    }
    case 'latestDXF.uploadComplete': {
      if (!state.latestDXF) {
        return state;
      }
      return {
        ...state,
        latestDXF: {
          status: 'created',
          id: action.planDXF.id,
          createdAt: action.planDXF.created_at,
          parseOptions: action.planDXF.options,
        },
      };
    }
    case 'latestDXF.uploadError': {
      return {
        ...state,
        latestDXF: {
          status: 'upload_error',
        },
      };
    }
    case 'latestDXF.update': {
      if (
        state.latestDXF &&
        state.latestDXF.status !== 'uploading' &&
        state.latestDXF.status !== 'upload_error' &&
        state.latestDXF.id === action.planDXF.id
      ) {
        return {
          ...state,
          latestDXF: {
            status: action.planDXF.status,
            id: action.planDXF.id,
            createdAt: action.planDXF.created_at,
            parseOptions: action.planDXF.options,
          },
        };
      }
      return state;
    }
    case 'latestDXF.edit': {
      if (
        !state.latestDXF ||
        state.latestDXF.status === 'uploading' ||
        state.latestDXF.status === 'upload_error'
      ) {
        return state;
      }

      let nextState = State.blurFocusedObject(state);
      nextState = State.blurPhotoGroup(nextState);

      return {
        ...nextState,
        latestDXFEdit: {
          active: true,
          planDXF: action.planDXF,
          cadFileUnit: action.planDXF.length_unit,
          cadFileScale: action.planDXF.scale || 1,
          pixelsPerCADUnit:
            action.fullImageAsset.pixels_per_unit ||
            DEFAULT_PIXELS_PER_CAD_UNIT,
          floorplanCADOrigin: state.floorplanCADOrigin,
          operationType:
            action.planDXF.sensor_placements.length > 0 ? 'both' : 'floorplan',
          parseOptions: state.latestDXF.parseOptions,
          loading: false,
        },
      };
    }
    case 'latestDXFEdit.changeUnit': {
      if (!state.latestDXFEdit.active) {
        return state;
      }
      return {
        ...state,
        latestDXFEdit: {
          ...state.latestDXFEdit,
          cadFileUnit: action.unit,
        },
      };
    }
    case 'latestDXFEdit.changeScale': {
      if (!state.latestDXFEdit.active) {
        return state;
      }
      return {
        ...state,
        latestDXFEdit: {
          ...state.latestDXFEdit,
          cadFileScale: action.scale,
        },
      };
    }
    case 'latestDXFEdit.changeOASensorLayer': {
      if (!state.latestDXFEdit.active) {
        return state;
      }
      return {
        ...state,
        latestDXFEdit: {
          ...state.latestDXFEdit,
          parseOptions: {
            ...state.latestDXFEdit.parseOptions,
            oa: {
              ...state.latestDXFEdit.parseOptions.oa,
              layer: action.layerName,
            },
          },
        },
      };
    }
    case 'latestDXFEdit.changeEntrySensorLayer': {
      if (!state.latestDXFEdit.active) {
        return state;
      }
      return {
        ...state,
        latestDXFEdit: {
          ...state.latestDXFEdit,
          parseOptions: {
            ...state.latestDXFEdit.parseOptions,
            entry: {
              ...state.latestDXFEdit.parseOptions.entry,
              layer: action.layerName,
            },
          },
        },
      };
    }
    case 'latestDXFEdit.changeFloorplanCADOrigin': {
      if (!state.latestDXFEdit.active) {
        return state;
      }
      return {
        ...state,
        latestDXFEdit: {
          ...state.latestDXFEdit,
          floorplanCADOrigin: action.coords,
        },
      };
    }
    case 'latestDXFEdit.changeOperationType': {
      if (!state.latestDXFEdit.active) {
        return state;
      }
      return {
        ...state,
        latestDXFEdit: {
          ...state.latestDXFEdit,
          operationType: action.operationType,
        },
      };
    }
    case 'latestDXFEdit.beginAsyncOperation': {
      if (!state.latestDXFEdit.active) {
        return state;
      }
      return {
        ...state,
        latestDXFEdit: {
          ...state.latestDXFEdit,
          loading: true,
        },
      };
    }
    case 'latestDXFEdit.cancel': {
      return {
        ...state,
        latestDXFEdit: {
          active: false,
        },
      };
    }
    case 'latestDXFEdit.applyFloorplanChanges': {
      const cadFileUnitOrDefault = action.cadFileUnitOrDefault;
      const cadFileScaleOrDefault = action.cadFileScaleOrDefault;

      let newFloorplan: Floorplan;
      if (action.mode === 'create') {
        const initialFloorplan = {
          width: action.newBaseImage.width,
          height: action.newBaseImage.height,
          scale: 1,
          origin: ImageCoordinates.create(0, 0),
          rotation: 0,
        };

        // Calcualte the initial scale for the floorplan
        const coordA = CADCoordinates.toFloorplanCoordinates(
          CADCoordinates.create(0, 0),
          initialFloorplan,
          FloorplanCoordinates.create(0, 0),
          cadFileUnitOrDefault,
          cadFileScaleOrDefault
        );
        const coordB = CADCoordinates.toFloorplanCoordinates(
          CADCoordinates.create(1, 0),
          initialFloorplan,
          FloorplanCoordinates.create(0, 0),
          cadFileUnitOrDefault,
          cadFileScaleOrDefault
        );
        const metersPerCADUnit = coordB.x - coordA.x;
        const ratio = metersPerCADUnit / action.pixelsPerCADUnit;
        const cadImageScale = ratio * initialFloorplan.scale;

        newFloorplan = {
          ...initialFloorplan,
          scale: (1 / cadImageScale) * action.imageResizeScale,
        };
      } else {
        newFloorplan = computeNewFloorplanForCAD(
          state.floorplan,
          action.newBaseImage,
          state.floorplanCADOrigin,
          cadFileUnitOrDefault,
          cadFileScaleOrDefault,
          action.imageResizeScale
        );
      }

      const newFloorplanCADOrigin = computeDefaultCADOrigin(newFloorplan);

      let nextState = state;

      // Offset all sensors by the change floorplan cad origin so that they align with the dxf's new
      // coordinate system.
      //
      // NOTE: If the `operationType` is `both` or `sensors`, then all the sensors will be overriden
      // later so this doesn't really do anything.
      const floorplanCADOrigin = action.floorplanCADOrigin;
      for (const sensor of Array.from(state.planSensors.items.values())) {
        nextState = State.updateSensor(
          nextState,
          sensor.id,
          (oldSensor) => {
            return {
              ...oldSensor,
              position: FloorplanCoordinates.create(
                oldSensor.position.x +
                  (newFloorplanCADOrigin.x - floorplanCADOrigin.x),
                oldSensor.position.y +
                  (newFloorplanCADOrigin.y - floorplanCADOrigin.y)
              ),
            };
          },
          true
        );
      }

      for (let change of action.floorplanChanges) {
        switch (change.type) {
          case 'addition':
            const planSensor = PlanSensor.createFromCADSensorPlacement(
              change.data,
              state.planSensors,
              newFloorplan,
              newFloorplanCADOrigin,
              cadFileUnitOrDefault,
              cadFileScaleOrDefault
            );
            nextState = State.addSensor(nextState, planSensor, true);
            break;
          case 'deletion':
            nextState = State.removeSensor(nextState, change.data.id, true);
            break;
          case 'modification':
            const changeData = change.data;
            nextState = State.updateSensor(
              nextState,
              change.oldData.id,
              (sensor: PlanSensor) => {
                return {
                  ...sensor,
                  ...changeData,
                  position: CADCoordinates.toFloorplanCoordinates(
                    changeData.position,
                    newFloorplan,
                    newFloorplanCADOrigin,
                    cadFileUnitOrDefault,
                    cadFileScaleOrDefault
                  ),
                };
              },
              true
            );
            break;
          case 'no-change':
            break;
        }
      }

      const measurementDistanceInPixels = action.newBaseImage.width;
      const measurementDistanceInMeters =
        measurementDistanceInPixels / newFloorplan.scale;
      const [feet, inches] = Meters.toFeetAndInches(
        measurementDistanceInMeters
      );

      const newMeasurement = {
        pointA: ImageCoordinates.create(0, 0),
        pointB: ImageCoordinates.create(measurementDistanceInPixels, 0),
        userEnteredLength: {
          feetText: `${feet}`,
          inchesText: `${inches}`,
        },
        computedLength: measurementDistanceInMeters,
        computedScale: newFloorplan.scale,
      };

      return {
        ...nextState,
        floorplanImage: action.newBaseImage,
        floorplanImageKey: action.newBaseImageKey,
        floorplanCADOrigin: newFloorplanCADOrigin,
        floorplan: newFloorplan,
        measurement: newMeasurement,
        activeDXFId: action.activeDXFId,
        latestDXFEdit: {
          active: false,
        },
      };
    }
    case 'latestDXFEdit.invertFloorplanChanges': {
      let nextState = state;
      for (let change of action.floorplanChanges) {
        switch (change.type) {
          case 'addition':
            // NOTE: when inverting an addition, there is unfortunately not a place where the id was
            // stored for the sensor that was created.
            //
            // Look up a sensor that has the same CAD ID and assume that is the sensor the was
            // created.
            const cadId = change.data.cadId;
            const sensorWithCadId = FloorplanCollection.list(
              state.planSensors
            ).find((sensor) => sensor.cadId === cadId);
            if (sensorWithCadId) {
              nextState = State.removeSensor(
                nextState,
                sensorWithCadId.id,
                true
              );
            }
            break;
          case 'deletion':
            const sensor = action.oldPlanSensors.items.get(change.data.id);
            if (sensor) {
              nextState = State.addSensor(nextState, sensor, true);
            }
            break;
          case 'modification':
            nextState = State.updateSensor(
              nextState,
              change.oldData.id,
              (sensor: PlanSensor) => {
                const oldPlanSensor = action.oldPlanSensors.items.get(
                  sensor.id
                );
                if (!oldPlanSensor) {
                  return sensor;
                }
                return {
                  ...sensor,
                  cadId: oldPlanSensor.cadId,
                  serialNumber: oldPlanSensor.serialNumber,
                  height: oldPlanSensor.height,
                  rotation: oldPlanSensor.rotation,
                  position: oldPlanSensor.position,
                };
              },
              true
            );
            break;
          case 'no-change':
            break;
        }
      }

      return {
        ...nextState,
        floorplanImage: action.floorplanImage,
        floorplanImageKey: action.floorplanImageKey,
        floorplanCADOrigin: action.floorplanCADOrigin,
        floorplan: action.floorplan,
        measurement: action.measurement,
      };
    }

    case 'export.begin': {
      return { ...state, activeExport: { status: 'requesting' } };
    }
    case 'export.beginFailed': {
      return { ...state, activeExport: { status: 'request-failed' } };
    }
    case 'export.update': {
      if (action.force) {
        return { ...state, activeExport: action.planExport };
      }

      // For non forced updates, the plan export id of the incoming update must match the plan
      // export id in the store for the active export
      if (
        state.activeExport &&
        state.activeExport.status !== 'requesting' &&
        state.activeExport.status !== 'request-failed' &&
        state.activeExport.status !== 'saving' &&
        state.activeExport.status !== 'saving-complete' &&
        state.activeExport.status !== 'saving-failed' &&
        state.activeExport.id === action.planExport.id
      ) {
        return { ...state, activeExport: action.planExport };
      }

      return state;
    }
    case 'export.saving': {
      return { ...state, activeExport: { status: 'saving' } };
    }
    case 'export.savingComplete': {
      return { ...state, activeExport: { status: 'saving-complete' } };
    }
    case 'export.savingFailed': {
      return { ...state, activeExport: { status: 'saving-failed' } };
    }
    case 'export.reset': {
      return { ...state, activeExport: null };
    }

    case 'layers.heightMap.focus': {
      const nextState = State.focusLayer(state, LayerId.HEIGHTMAP);
      if (nextState.heightMap.enabled) {
        return {
          ...nextState,
          planning: { ...nextState.planning, showCeilingHeightMap: true },
        };
      }
      return nextState;
    }

    case 'layers.heightMap.mouseenter': {
      return State.highlightLayer(state, LayerId.HEIGHTMAP);
    }

    case 'layers.heightMap.mouseleave': {
      return State.unhighlight(state);
    }

    case 'layers.walls.focus': {
      const nextState = State.focusLayer(state, LayerId.WALLS);
      if (!FloorplanCollection.isEmpty(nextState.walls)) {
        return {
          ...nextState,
          planning: { ...nextState.planning, showWalls: true },
        };
      }
      return nextState;
    }

    case 'layers.walls.mouseenter': {
      return State.highlightLayer(state, LayerId.WALLS);
    }

    case 'layers.walls.mouseleave': {
      return State.unhighlight(state);
    }

    case 'layers.heatmap.focus': {
      const nextState = State.focusLayer(state, LayerId.HEATMAP);

      let nextHeatmap = state.heatmap;
      if (!nextHeatmap.enabled) {
        nextHeatmap = { ...Heatmap.create(), enabled: true };
      }

      return {
        ...nextState,
        planning: { ...nextState.planning, showHeatMap: true },
        heatmap: nextHeatmap,
      };
    }

    case 'layers.heatmap.mouseenter': {
      return State.highlightLayer(state, LayerId.HEATMAP);
    }

    case 'layers.heatmap.mouseleave': {
      return State.unhighlight(state);
    }

    case 'layers.dismiss': {
      return State.blurFocusedObject(state);
    }

    case 'heatmap.setStartDate': {
      if (!state.heatmap.enabled) {
        return state;
      }
      return {
        ...state,
        heatmap: {
          enabled: true,
          ...Heatmap.changeStartDate(state.heatmap, action.startDate),
        },
      };
    }
    case 'heatmap.setEndDate': {
      if (!state.heatmap.enabled) {
        return state;
      }
      return {
        ...state,
        heatmap: {
          enabled: true,
          ...Heatmap.changeEndDate(state.heatmap, action.endDate),
        },
      };
    }
    case 'heatmap.changeOpacity': {
      if (!state.heatmap.enabled) {
        return state;
      }
      return {
        ...state,
        heatmap: {
          enabled: true,
          ...Heatmap.changeOpacity(state.heatmap, action.opacity),
        },
      };
    }
    case 'heatmap.beginLoading': {
      if (!state.heatmap.enabled) {
        return state;
      }
      return {
        ...state,
        heatmap: {
          ...state.heatmap,
          data: {
            status: 'loading',
            page: action.page,
            lastBucketTimestamp: action.lastBucketTimestamp,
          },
        },
      };
    }
    case 'heatmap.beginComputing': {
      if (!state.heatmap.enabled) {
        return state;
      }
      return {
        ...state,
        heatmap: {
          ...state.heatmap,
          data: { status: 'computing-heatmap' },
        },
      };
    }
    case 'heatmap.setResult': {
      if (!state.heatmap.enabled) {
        return state;
      }
      return {
        ...state,
        heatmap: {
          ...state.heatmap,
          data: {
            status: 'complete',
            globalHeatmap: action.globalHeatmap,
            globalMax: action.globalMax,
            limits: {
              min: typeof action.minLimit !== 'undefined' ? action.minLimit : 0,
              max:
                typeof action.maxLimit !== 'undefined'
                  ? action.maxLimit
                  : action.globalMax,
            },
          },
        },
      };
    }
    case 'heatmap.changeLimits': {
      if (!state.heatmap.enabled) {
        return state;
      }
      if (state.heatmap.data.status !== 'complete') {
        return state;
      }
      return {
        ...state,
        heatmap: {
          ...state.heatmap,
          data: {
            ...state.heatmap.data,
            limits: {
              min: action.min,
              max: action.max,
            },
          },
        },
      };
    }
    case 'heatmap.error': {
      if (!state.heatmap.enabled) {
        return state;
      }
      return {
        ...state,
        heatmap: {
          ...state.heatmap,
          data: { status: 'error' },
        },
      };
    }
    case 'heatmap.cancel': {
      if (!state.heatmap.enabled) {
        return state;
      }
      return {
        ...state,
        heatmap: {
          ...state.heatmap,
          data: { status: 'pending' },
        },
      };
    }

    case 'heightMap.saveNotes': {
      if (!state.heightMap.enabled) {
        return state;
      }
      return {
        ...state,
        heightMap: {
          ...state.heightMap,
          notes: action.notes,
        },
      };
    }

    case 'heightMap.changeOpacity': {
      if (!state.heightMap.enabled) {
        return state;
      }
      return {
        ...state,
        heightMap: {
          ...state.heightMap,
          opacity: action.opacity,
        },
      };
    }

    case 'heightMap.change': {
      return {
        ...state,
        heightMapImport: { view: 'disabled' },
        heightMap:
          action.heightMap !== null
            ? { enabled: true, ...action.heightMap }
            : { enabled: false },

        // Show the height map after saving
        planning: {
          ...state.planning,
          showCeilingHeightMap: action.heightMap !== null,
        },

        // Mark the height map as changed so clicking "save" will update it
        heightMapUpdated: true,

        // Invalidate sensor coverage now that the height map no longer exists
        planSensorCoverageIntersectionVectors: new Map(
          FloorplanCollection.list(state.planSensors).map((sensor) => [
            sensor.id,
            'empty',
          ])
        ),

        // Invalidate all validations now that the height map no longer exists
        validations: new Map(
          Array.from(state.validations).map(([id]) => [id, 'empty'])
        ),

        // Clear the heights stores in all ReferenceHeights, as the height map changed position so
        // they all need to be recalculated
        references: FloorplanCollection.map(state.references, (reference) => {
          if (reference.type !== 'height') {
            return reference;
          }

          return { ...reference, heightMeters: { step: 'empty' } };
        }),

        // Set coverage intersection height map flag on areas of concern so that the height map
        // will be taken into account
        areasOfConcern: FloorplanCollection.map(
          state.areasOfConcern,
          (areaOfConcern) => ({
            ...areaOfConcern,
            coverageIntersectionHeightMapEnabled: action.heightMap !== null,
          })
        ),
      };
    }

    case 'heightMapImport.startUpload': {
      return {
        ...state,
        heightMapImport: {
          view: 'uploading-image',
          fileName: action.fileName,
        },
      };
    }

    case 'heightMapImport.uploadProgress': {
      if (state.heightMapImport.view !== 'uploading-image') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          fileUploadPercent: action.percent,
        },
      };
    }

    case 'heightMapImport.finishUpload': {
      return {
        ...state,
        heightMapImport: {
          view: 'ready',
          objectKey: action.objectKey,
          url: action.url,
          position: FloorplanCoordinates.create(0, 0),
          rotation: 0,
          opacity: 100,
          notes: '',
          limits: { enabled: false },
          geotiffTransformationData: {
            enabled: false,
          },
        },
      };
    }

    case 'heightMapImport.edit': {
      if (state.heightMapImport.view !== 'ready') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          view: 'enabled',
        },
      };
    }

    case 'heightMapImport.beginRegistration': {
      if (!state.heightMap.enabled) {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          view: 'enabled',
          ...state.heightMap,
        },
      };
    }

    case 'heightMapImport.changeRegistration': {
      if (state.heightMapImport.view !== 'enabled') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          position: action.position,
          rotation: action.rotation,
        },
      };
    }

    case 'heightMapImport.changePosition': {
      if (state.heightMapImport.view !== 'enabled') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          position: action.position,
        },
      };
    }

    case 'heightMapImport.setGeoTiffOffsets': {
      if (state.heightMapImport.view !== 'enabled') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          geotiffTransformationData: {
            enabled: true,
            tiePoint: action.tiePoint,
            scale: action.scale,
          },
        },
      };
    }

    case 'heightMapImport.changeRotation': {
      if (state.heightMapImport.view !== 'enabled') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          rotation: action.rotation,
        },
      };
    }

    case 'heightMapImport.rotateRight90': {
      if (state.heightMapImport.view !== 'enabled') {
        return state;
      }

      let newRotation = state.heightMapImport.rotation + 90;
      if (newRotation >= 360) {
        newRotation -= 360;
      }

      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          rotation: newRotation,
        },
      };
    }

    case 'heightMapImport.changeBounds': {
      if (state.heightMapImport.view !== 'enabled') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          limits: {
            enabled: true,
            minMeters: action.minMeters,
            maxMeters: action.maxMeters,
          },
        },
      };
    }

    case 'heightMapImport.changeOpacity': {
      if (state.heightMapImport.view !== 'enabled') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          opacity: action.opacity,
        },
      };
    }

    case 'heightMapImport.cancel': {
      if (state.heightMapImport.view !== 'enabled') {
        return state;
      }
      return {
        ...state,
        heightMapImport: {
          ...state.heightMapImport,
          view: state.heightMap ? 'disabled' : 'ready',
        },
      };
    }

    case 'sensorCoverageIntersectionVectors.beginCalculating': {
      const nextSensorCoverageIntersectionVectors = new Map(
        state.planSensorCoverageIntersectionVectors
      );
      for (const id of action.sensorIds) {
        nextSensorCoverageIntersectionVectors.set(id, 'loading');
      }

      return {
        ...state,
        planSensorCoverageIntersectionVectors:
          nextSensorCoverageIntersectionVectors,
      };
    }

    case 'sensorCoverageIntersectionVectors.setResult': {
      let nextSensorCoverageIntersectionVectors = new Map(
        state.planSensorCoverageIntersectionVectors
      );

      for (const [sensorId, result] of Array.from(action.results)) {
        // If the update is not in progress, then reject the result
        // An example situation where this could happen: a user moves a sensor while it's already
        // calculating
        if (
          state.planSensorCoverageIntersectionVectors.get(sensorId) !==
          'loading'
        ) {
          continue;
        }

        nextSensorCoverageIntersectionVectors.set(sensorId, result);
      }

      return {
        ...state,
        planSensorCoverageIntersectionVectors:
          nextSensorCoverageIntersectionVectors,
      };
    }

    case 'wallsEditor.begin': {
      return {
        ...state,
        wallsEdit: {
          active: true,
          imageLineSegmentImport: { active: false },
          walls: state.walls,
        },
      };
    }

    case 'wallsEditor.beginWithImageLineSegments': {
      return {
        ...state,
        wallsEdit: {
          active: true,
          imageLineSegmentImport: {
            active: true,
            strokeColors: action.strokeColors,
            layers: action.layers,
            lineSegments: action.lineSegments,
            imageWidth: action.imageWidth,
            imageHeight: action.imageHeight,
          },
          walls: state.walls,
        },
      };
    }
    case 'wallsEditor.completeImageLineSegmentImport': {
      if (!state.wallsEdit.active) {
        return state;
      }
      if (!state.wallsEdit.imageLineSegmentImport.active) {
        return state;
      }
      return {
        ...state,
        wallsEdit: {
          ...state.wallsEdit,
          imageLineSegmentImport: { active: false },
        },
      };
    }

    case 'wallsEditor.cancel': {
      return {
        ...state,
        wallsEdit: { active: false },
      };
    }

    case 'wallsEditor.save': {
      // if (!state.wallsEdit.active) {
      //   return state;
      // }

      return {
        ...state,
        walls: action.walls,
        wallsEdit: { active: false },

        // Invalidate sensor coverage now that the walls have changed
        planSensorCoverageIntersectionVectors: new Map(
          FloorplanCollection.list(state.planSensors).map((sensor) => [
            sensor.id,
            'empty',
          ])
        ),

        // Invalidate all validations now that the walls have changed
        validations: new Map(
          Array.from(state.validations).map(([id]) => [id, 'empty'])
        ),

        // Invalidate area of concern sensor placements
        areasOfConcern: FloorplanCollection.map(
          state.areasOfConcern,
          (areaOfConcern) => ({
            ...areaOfConcern,
            sensorPlacements: { type: 'empty' },
          })
        ),

        planning: {
          ...state.planning,
          // If there are still walls, make sure walls are visible after saving them
          showWalls: !FloorplanCollection.isEmpty(action.walls),

          // If all walls were removed and there is no heightmap, disable sensor coverage extents rendering
          showSensorCoverageExtents:
            state.planning.showSensorCoverageExtents &&
            action.walls.items.size > 0 &&
            state.heightMap.enabled,
        },
      };
    }

    case 'wallsEditor.clear': {
      return {
        ...state,
        walls: FloorplanCollection.create(),

        // Invalidate sensor coverage now that the walls have changed
        planSensorCoverageIntersectionVectors: new Map(
          FloorplanCollection.list(state.planSensors).map((sensor) => [
            sensor.id,
            'empty',
          ])
        ),

        // Invalidate all validations now that the walls have changed
        validations: new Map(
          Array.from(state.validations).map(([id]) => [id, 'empty'])
        ),

        // Invalidate area of concern sensor placements
        areasOfConcern: FloorplanCollection.map(
          state.areasOfConcern,
          (areaOfConcern) => ({
            ...areaOfConcern,
            sensorPlacements: { type: 'empty' },
          })
        ),

        planning: {
          ...state.planning,
          // Make sure walls are hidden after clearing them all
          showWalls: false,

          // Continue showing the sensor coverage extents if there is a height map uploaded
          showSensorCoverageExtents:
            state.planning.showSensorCoverageExtents && state.heightMap.enabled,
        },
      };
    }

    case 'wallSegment.importPageFromAPI': {
      const nextState = State.addWallSegments(
        state,
        action.responseBody.results.map((w) =>
          WallSegment.createFromFloorplanWallSegment(w)
        ),
        false
      );

      if (action.isFinalPage) {
        return { ...nextState, wallsFullyPopulated: true };
      }
      return nextState;
    }

    case 'wallSegment.changeIds': {
      if (action.oldIds.length !== action.newIds.length) {
        return state;
      }

      for (let i = 0; i < action.oldIds.length; i += 1) {
        const oldId = action.oldIds[i];
        if (!oldId) {
          continue;
        }
        const newId = action.newIds[i];
        if (!newId) {
          continue;
        }
        state = State.changeWallSegmentId(state, oldId, newId);
      }

      return state;
    }

    case 'websocket.event.plan': {
      switch (action.message.event) {
        case 'plan.updated': {
          if (!action.message.plan) {
            return state;
          }

          let nextState = state;

          const floorplan = Floorplan.fromFloorplanAPIResponse(
            action.message.plan
          );
          const measurement = Measurement.fromFloorplanAPIResponse(
            action.message.plan
          );
          const heightMap = HeightMap.fromFloorplanAPIResponse(
            action.message.plan
          );

          // NOTE: only update the image if the image key changes! This is important
          // because if the image is updated constantly, it will have to constantly reload
          // which results in a lot of white flashing in the floorplan component.
          if (
            action.message.plan.image_key !== state.floorplanImageKey &&
            action.message.plan.image_url
          ) {
            const newFloorplanImage = new Image();
            newFloorplanImage.src = action.message.plan.image_url;

            nextState = {
              ...nextState,
              floorplanImage: newFloorplanImage,
              floorplanImageKey: action.message.plan.image_key,
            };
          }

          return {
            ...nextState,
            floorplan,
            measurement,
            floorplanCADOrigin: FloorplanCoordinates.create(
              action.message.plan.floorplan_cad_origin_x,
              action.message.plan.floorplan_cad_origin_y
            ),
            heightMap: heightMap
              ? { enabled: true, ...heightMap }
              : { enabled: false },
          };
        }
        default:
          return state;
      }
    }

    case 'websocket.event.sensor': {
      switch (action.message.event) {
        case 'plan.sensor.created': {
          if (!action.message.plan_sensor) {
            return state;
          }
          const sensor = PlanSensor.createFromFloorplanSensor(
            action.message.plan_sensor
          );
          return State.addSensor(state, sensor, false);
        }
        case 'plan.sensor.updated':
          if (!action.message.plan_sensor) {
            return state;
          }
          const sensorUpdate = PlanSensor.createFromFloorplanSensor(
            action.message.plan_sensor
          );
          return State.updateSensor(
            state,
            action.message.plan_sensor_id,
            sensorUpdate,
            true
          );
        case 'plan.sensor.deleted':
          return State.removeSensor(state, action.message.plan_sensor_id, true);
        default:
          return state;
      }
    }

    case 'websocket.event.space': {
      switch (action.message.event) {
        case 'plan.space.created': {
          if (!action.message.space) {
            return state;
          }
          const space = Space.createFromFloorplanSpace(action.message.space);
          return State.addSpace(state, space, false);
        }
        case 'plan.space.updated':
          if (!action.message.space) {
            return state;
          }
          const spaceUpdate = Space.createFromFloorplanSpace(
            action.message.space
          );
          return State.updateSpace(
            state,
            action.message.space_id,
            spaceUpdate,
            true
          );
        case 'plan.space.deleted':
          return State.removeSpace(state, action.message.space_id, true);
        default:
          return state;
      }
    }

    case 'websocket.event.referenceRuler': {
      switch (action.message.event) {
        case 'plan.ruler.created': {
          if (!action.message.plan_reference_ruler) {
            return state;
          }
          const reference = ReferenceRuler.createFromFloorplanReferenceRuler(
            action.message.plan_reference_ruler
          );
          return State.addReference(state, reference, false);
        }
        case 'plan.ruler.updated':
          if (!action.message.plan_reference_ruler) {
            return state;
          }
          const referenceUpdate =
            ReferenceRuler.createFromFloorplanReferenceRuler(
              action.message.plan_reference_ruler
            );
          return State.updateReference(
            state,
            action.message.plan_reference_ruler_id,
            referenceUpdate
          );
        case 'plan.ruler.deleted':
          return State.removeReference(
            state,
            action.message.plan_reference_ruler_id
          );
        default:
          return state;
      }
    }

    case 'websocket.event.referenceHeight': {
      switch (action.message.event) {
        case 'plan.reference_height.created': {
          if (!action.message.plan_reference_height) {
            return state;
          }
          const reference = ReferenceHeight.createFromFloorplanReferenceHeight(
            action.message.plan_reference_height
          );
          return State.addReference(state, reference, false);
        }
        case 'plan.reference_height.updated':
          if (!action.message.plan_reference_height) {
            return state;
          }
          const referenceUpdate =
            ReferenceHeight.createFromFloorplanReferenceHeight(
              action.message.plan_reference_height
            );
          return State.updateReference(
            state,
            action.message.plan_reference_height_id,
            referenceUpdate
          );
        case 'plan.reference_height.deleted':
          return State.removeReference(
            state,
            action.message.plan_reference_height_id
          );
        default:
          return state;
      }
    }

    case 'websocket.event.photoGroup': {
      switch (action.message.event) {
        case 'plan.photo_group.created': {
          if (!action.message.plan_photo_group) {
            return state;
          }
          const photoGroup = PhotoGroup.createFromFloorplanPhotoGroup(
            action.message.plan_photo_group
          );
          return State.addPhotoGroup(state, photoGroup, false);
        }
        case 'plan.photo_group.updated': {
          if (!action.message.plan_photo_group) {
            return state;
          }
          const photoGroupUpdate = PhotoGroup.createFromFloorplanPhotoGroup(
            action.message.plan_photo_group
          );
          return State.updatePhotoGroup(
            state,
            action.message.plan_photo_group_id,
            photoGroupUpdate,
            true
          );
        }
        case 'plan.photo_group.deleted': {
          return State.removePhotoGroup(
            state,
            action.message.plan_photo_group_id,
            true
          );
        }
        default:
          return state;
      }
    }

    case 'websocket.event.photo': {
      switch (action.message.event) {
        case 'plan.photo.created': {
          if (!action.message.photo) {
            return state;
          }

          const photo = PhotoGroupPhoto.createFromImageUrl(
            action.message.photo.id,
            action.message.photo.name,
            action.message.photo.url
          );

          return State.updatePhotoGroup(
            state,
            action.message.plan_photo_group_id,
            (photoGroup) => {
              const existingPhoto = PhotoGroup.getPhotoById(
                photoGroup,
                action.message.plan_photo_id
              );
              if (!existingPhoto) {
                return photoGroup;
              }

              return {
                ...photoGroup,
                photos: [...photoGroup.photos, photo],
              };
            },
            true
          );
        }
        case 'plan.photo.updated': {
          if (!action.message.photo) {
            return state;
          }

          const photoUpdate = PhotoGroupPhoto.createFromImageUrl(
            action.message.photo.id,
            action.message.photo.name,
            action.message.photo.url
          );

          return State.updatePhotoGroup(
            state,
            action.message.plan_photo_group_id,
            (photoGroup) => {
              const existingPhoto = PhotoGroup.getPhotoById(
                photoGroup,
                action.message.plan_photo_id
              );
              if (!existingPhoto) {
                return photoGroup;
              }

              return {
                ...photoGroup,
                photos: photoGroup.photos.map((photo) =>
                  photo.id === action.message.plan_photo_id
                    ? { ...photo, name: photoUpdate.name }
                    : photo
                ),
              };
            },
            true
          );
        }
        case 'plan.photo.deleted': {
          return State.updatePhotoGroup(
            state,
            action.message.plan_photo_group_id,
            (photoGroup) => {
              const existingPhoto = PhotoGroup.getPhotoById(
                photoGroup,
                action.message.plan_photo_id
              );
              if (!existingPhoto) {
                return photoGroup;
              }

              return {
                ...photoGroup,
                photos: photoGroup.photos.filter(
                  (photo) => photo.id !== action.message.plan_photo_id
                ),
              };
            },
            true
          );
        }
        default:
          return state;
      }
    }

    case 'spaceHierarchyData.begin': {
      return { ...state, spaceHierarchyDataLoadingStatus: 'loading' };
    }
    case 'spaceHierarchyData.complete': {
      // Step 1: Unroll the hierarchy into a flat space list
      const spaceMetadata = new Map<
        CoreSpaceHierarchyNode['id'],
        CoreSpaceHierarchyNode
      >();
      const traverse = (node: CoreSpaceHierarchyNode) => {
        spaceMetadata.set(node.id, node);
        for (const child of node.children || []) {
          traverse(child);
        }
      };
      traverse(action.data);

      // Step 2: Update each space in the state using the hierarchy data
      const spaces = FloorplanCollection.map(state.spaces, (space) => {
        if (!space.id) {
          return space;
        }

        const node = spaceMetadata.get(space.id);
        if (!node) {
          return space;
        }

        return {
          ...space,
          coreSpaceCapacity: node.capacity,
          // FIXME: the CoreSpaceHierarchyNode seems to be missing the function key?
          coreSpaceFunction: (node as any)['function'],
          // FIXME: the CoreSpaceHierarchyNode seems to be missing the labels key?
          coreSpaceLabels: (node as any).labels,
          coreSpaceMetadataLabelIdsToAdd: [],
          coreSpaceMetadataLabelIdsToRemove: [],
        };
      });

      return { ...state, spaces, spaceHierarchyDataLoadingStatus: 'complete' };
    }
    case 'spaceHierarchyData.error': {
      return { ...state, spaceHierarchyDataLoadingStatus: 'error' };
    }

    case 'validations.beginCalculating': {
      const validationsCopy = new Map(state.validations);
      for (const id of action.ids) {
        validationsCopy.set(id, 'loading');
      }
      return { ...state, validations: validationsCopy };
    }

    case 'validations.set': {
      const validationsCopy = new Map(state.validations);
      for (const [k, v] of Array.from(action.validations)) {
        validationsCopy.set(k, v);
      }
      return { ...state, validations: validationsCopy };
    }

    case 'lock': {
      return { ...state, locked: true, placementMode: null };
    }
    case 'unlock': {
      return { ...state, locked: false };
    }

    default: {
      return state;
    }
  }
};
