import * as React from 'react';

const Kitchen = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path d="M2 2L2 22H4L4 4L12 4V6H14V4L18 4V2L2 2Z" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 8L15.4914 8C16.3844 8 17.1691 8.59195 17.4144 9.45056L17.5714 10H19.5585C20.4193 10 21.1836 10.5509 21.4558 11.3675L22.1225 13.3675C22.4718 14.4154 21.9055 15.5481 20.8576 15.8974L19.5585 12L18.1429 12L19.5432 16.9011C20.1501 19.0253 18.9201 21.2392 16.796 21.8461C16.4386 21.9482 16.0687 22 15.6971 22L10.3029 22C8.09378 22 6.30292 20.2091 6.30292 18C6.30292 17.6283 6.35472 17.2585 6.45682 16.9011L9 8ZM10.5086 10L8.79432 16H17.2057L15.4914 10H10.5086Z"
      fill={color}
    />
  </svg>
);

export default Kitchen;
