// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-dialogConfirm-15G2t9q1{display:flex;flex-direction:column}.dui-styles-module-dialogConfirmContent-2y0Rf6y9{padding:32px}.dui-styles-module-dialogPromptContent-2VJJb1_S{padding:32px}.dui-styles-module-dialogPromptLabel-3kBnX33c{padding-bottom:16px;font-size:16px}\n", ""]);
// Exports
exports.locals = {
	"dialogConfirm": "dui-styles-module-dialogConfirm-15G2t9q1",
	"dialogConfirmContent": "dui-styles-module-dialogConfirmContent-2y0Rf6y9",
	"dialogPromptContent": "dui-styles-module-dialogPromptContent-2VJJb1_S",
	"dialogPromptLabel": "dui-styles-module-dialogPromptLabel-3kBnX33c"
};
module.exports = exports;
