import React from 'react';
import propTypes from 'prop-types';

const DensityMark: React.FC<any> = ({size, color}) => {
  return (
    <svg version="1.1" height={size || 300} x="0px" y="0px" viewBox="0 0 211 216.5">
      <path fill={color || '#211d1d'} d="M84.9,119.8c4.5-2.7,8.3-6.5,10.9-11c2.8-4.7,4.4-10.2,4.4-16.1v-21c0-1.1,0.9-2,2-2h9.5c1.1,0,2,0.9,2,2v21
      c0,5.9,1.6,11.4,4.4,16.1c2.7,4.5,6.4,8.3,10.9,11l18.7,10.8c1,0.6,1.3,1.8,0.7,2.7l-4.7,8.2c-0.6,1-1.8,1.3-2.7,0.7l-18.3-10.6
      c-4.6-2.6-10-4.1-15.7-4.1c-5.7,0-11,1.5-15.7,4.1l-18.3,10.6c-1,0.6-2.2,0.2-2.7-0.7l-4.7-8.2c-0.6-1-0.2-2.2,0.7-2.7L84.9,119.8z"
      />
    </svg>
  );
}

DensityMark.displayName = 'DensityMark';
DensityMark.propTypes = {
  size: propTypes.number,
  color: propTypes.string,
};
export default DensityMark;

export const DensityLogo: React.FC<any> = ({size, color}) => {
  return <div className="density-mark">
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 945.71064 201.92023' height={size || 100}>
        <g transform='matrix(1.33333 0 0 -1.33333 0 201.919)' id='g10'>
            <g transform='scale(.1)' id='g12' fill={color || '#211d1d'}>
                <path id='path14' d='m 3516.9,1152.67 h 140.51 c 14.71,0 26.63,-11.94 26.63,-26.64 v -75.85 c 53.63,72.53 138.75,118.24 242.83,118.24 198.7,0 320.1,-129.28 320.1,-343.721 V 364.23 h -143.65 c -14.72,0 -26.64,11.942 -26.64,26.641 v 419.621 c 0,129.285 -64.65,211.298 -190.79,211.298 -113.55,0 -200.25,-85.145 -200.25,-223.907 V 364.23 h -142.09 c -14.72,0 -26.65,11.942 -26.65,26.641 v 761.799'
                />
                <path id='path16' d='m 4414.11,531.391 129.99,67.695 c 11.8,6.141 26.24,2.559 33.94,-8.281 43.07,-60.657 107.75,-95.696 189.27,-95.696 94.62,0 143.49,48.887 143.49,104.075 0,63.066 -91.45,77.265 -190.79,97.773 -134.02,28.379 -272.78,72.527 -272.78,234.949 0,124.554 118.27,239.684 304.33,238.114 146.63,0 255.43,-58.36 316.93,-152.98 l -120,-66.071 c -11.36,-6.25 -25.34,-3.223 -33.63,6.75 -37.14,44.661 -93.39,71.951 -163.3,71.951 -89.89,0 -135.61,-44.15 -135.61,-94.607 0,-56.778 72.53,-72.54 186.07,-97.766 129.29,-28.387 275.94,-70.965 275.94,-234.969 0,-108.789 -94.62,-255.43 -318.53,-253.859 -164,0 -279.09,66.242 -345.32,182.922'
                />
                <path id='path18' d='m 5285.05,1152.67 h 143.65 c 14.71,0 26.64,-11.94 26.64,-26.64 v -761.8 h -143.65 c -14.72,0 -26.64,11.942 -26.64,26.641 z m 0,329.54 h 143.65 c 14.71,0 26.64,-11.93 26.64,-26.64 v -154.15 h -143.65 c -14.72,0 -26.64,11.92 -26.64,26.64 v 154.15'
                />
                <path id='path20' d='m 5953.76,1431.75 -2.16,-1.18 h 2.16 z' />
                <path id='path22' d='m 6115.97,503.988 c -6.84,-0.457 -14.13,-0.738 -22,-0.738 -24.35,0 -47.34,3.969 -67.11,13.238 -29.03,13.594 -50.87,38.907 -59.27,80.848 -2.6,18.711 -3.66,41.105 -3.77,67.988 l 1.36,335.956 h 165.74 v 13.95 110.8 c 0,14.7 -11.93,26.64 -26.64,26.64 h -140.52 v 277.9 h -2.16 l -154.27,-84.28 c -6.32,-3.45 -10.86,-9.29 -12.79,-16.02 -0.68,-2.37 -1.07,-4.83 -1.07,-7.36 v -159.01 -11.23 h -134.03 v -124.75 c 0,-4.62 1.28,-8.9 3.35,-12.69 4.52,-8.27 13.2,-13.95 23.29,-13.95 h 107.39 v -14.753 l -1.43,-383.652 c -1.51,-177.797 105.76,-254.414 260.56,-254.414 1.61,0 3.01,0.039 4.58,0.051 30.99,0.148 54.2,1.769 73.74,4 13.36,1.527 25.14,3.328 36.62,5.136 V 479.73 c 0,16.161 -14.2,28.481 -30.22,26.391 -6.57,-0.859 -13.66,-1.621 -21.35,-2.133'
                />
                <path id='path24' d='m 6611.9,405.238 -326.39,747.432 h 163.64 c 10.72,0 20.4,-6.44 24.55,-16.32 l 223.35,-530.858 210.93,530.378 c 4.03,10.14 13.85,16.8 24.75,16.8 h 160.1 L 6623.44,16.4688 C 6619.33,6.5 6609.6,-0.0117188 6598.82,-0.0117188 H 6443.19 L 6611.9,405.238'
                />
                <path id='path26' d='m 2920.14,1020.2 c 121.42,0 208.16,-63.059 233.39,-176.595 h -473.06 c 31.53,104.075 123,176.595 239.67,176.595 z m 6.32,-521.93 c -137.19,0 -231.81,80.406 -253.87,201.839 h 626.17 c 14.72,0 26.65,11.934 26.65,26.641 v 28.957 c 0,226.426 -182.46,414.543 -408.88,412.703 -230.02,-1.87 -406.37,-182.519 -406.37,-413.105 0,-228.621 167.15,-406.836 416.3,-406.836 155.79,0 271.55,67.5 344.05,173.476 l -118.96,66.262 c -10.99,6.121 -24.69,3.652 -32.96,-5.832 -45.38,-52.031 -111.61,-84.105 -192.13,-84.105'
                />
                <path id='path28' d='M 1576,1356.96 V 524.25 h 162.02 c 264.48,0 440.78,179.453 440.78,415.586 0,240.834 -159,417.124 -447.06,417.124 z m -171.58,130.79 c 0,14.71 11.93,26.64 26.64,26.64 h 317.99 c 373.08,0 602.89,-248.7 602.89,-574.554 0,-327.449 -251.87,-574.566 -596.59,-574.566 h -350.93 v 1122.48'
                />
                <path id='path30' d='m 606.734,1107.66 v 233.05 c 0,12.75 -10.328,23.07 -23.074,23.07 H 458.219 c -12.746,0 -23.071,-10.32 -23.071,-23.07 v -233.05 c 0,-166.926 -92.222,-312.297 -228.476,-388 L 11.5391,607.004 C 0.503906,600.637 -3.27734,586.527 3.09766,575.492 L 65.8203,466.852 c 6.3672,-11.043 20.4805,-14.821 31.5156,-8.454 L 294.043,571.977 c 66.391,39.597 143.98,62.371 226.895,62.371 82.933,0 160.531,-22.782 226.929,-62.391 l 196.68,-113.559 c 11.035,-6.367 25.144,-2.589 31.512,8.454 l 62.721,108.64 c 6.38,11.035 2.6,25.145 -8.44,31.512 L 835.188,719.68 C 698.945,795.383 606.734,940.746 606.734,1107.66'
                />
            </g>
        </g>
    </svg>
  </div>;
}

DensityLogo.displayName = 'DensityLogo';
DensityLogo.propTypes = {
  size: propTypes.number,
  color: propTypes.string,
};
