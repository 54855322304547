import React from "react";

const Freehand = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 20 20">
    <path
      fill={color}
      fillRule="evenodd"
      d="M5.416 3.68c-.839.418-1.665 1.168-2.214 2.028L1.797 4.81C2.5 3.712 3.547 2.75 4.673 2.188c1.106-.552 2.466-.79 3.618-.088.912.556 1.35 1.36 1.352 2.28.003.836-.356 1.675-.785 2.41-.654 1.121-1.656 2.283-2.421 3.172-.232.268-.442.512-.614.722a7.45 7.45 0 00-.768 1.105c-.247.449-.387.87-.382 1.218.003.293.106.59.51.883.448.326 1.284.654 2.769.823a8.352 8.352 0 002.234-.026 5.863 5.863 0 01-.918-1.814c-.35-1.182-.285-2.483.33-3.833 1.137-2.493 2.696-3.685 4.29-3.72 1.553-.033 2.806 1.06 3.166 2.377.197.72.145 1.67-.099 2.624a8.55 8.55 0 01-1.447 3 7.783 7.783 0 01-2.205 1.993c1.34.862 2.944 1.352 4.197 1.352v1.667c-1.886 0-4.232-.832-5.963-2.244-1.087.322-2.342.444-3.775.28-1.61-.184-2.776-.561-3.559-1.13-.827-.602-1.186-1.39-1.197-2.21-.01-.765.282-1.485.589-2.043.313-.57.69-1.054.94-1.359a48.8 48.8 0 01.722-.853c.75-.871 1.593-1.85 2.162-2.824.38-.65.559-1.176.558-1.566-.001-.308-.109-.59-.554-.86-.455-.278-1.15-.272-2.007.156zm6.5 10.487a6.034 6.034 0 002.262-1.85 6.881 6.881 0 001.162-2.41c.204-.796.2-1.43.106-1.77-.176-.643-.798-1.166-1.522-1.15-.683.015-1.802.537-2.809 2.744-.456 1-.48 1.888-.25 2.669.187.63.551 1.226 1.051 1.767z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default Freehand;
