import * as PIXI from 'pixi.js';
import { FederatedPointerEvent } from '@pixi/events';
import { ViewportCoordinates, FloorplanCoordinates } from 'lib/geometry';
import { addDragHandler, toRawHex } from './utils';
import { FloorplanLayerContextData } from './types';

import { Purple400 } from '@density/dust/dist/tokens/dust.tokens';

type ResizeHandleOptions = {
  color?: number; // Note: raw hex color format that pixi.js requires
  onPress?: (
    position: FloorplanCoordinates,
    rawMouseDownEvent: FederatedPointerEvent
  ) => void;
  onRelease?: () => void;
  onDelete?: () => void;
};

const RESIZE_HANDLE_SIZE_PX = 8;
export default class ResizeHandle extends PIXI.Graphics {
  context: FloorplanLayerContextData;
  color: number;
  onPress: ResizeHandleOptions['onPress'];
  onDrag: (
    newPosition: FloorplanCoordinates,
    rawMousePosition: ViewportCoordinates,
    event: MouseEvent
  ) => void;
  onRelease: ResizeHandleOptions['onRelease'];
  onDelete: ResizeHandleOptions['onDelete'];

  constructor(
    context: FloorplanLayerContextData,
    onDrag: (
      newPosition: FloorplanCoordinates,
      rawMousePosition: ViewportCoordinates,
      event: MouseEvent
    ) => void,
    opts: ResizeHandleOptions = {}
  ) {
    super();
    this.context = context;
    this.onDrag = onDrag;

    const { color = toRawHex(Purple400), onPress, onRelease, onDelete } = opts;
    this.onPress = onPress;
    this.onRelease = onRelease;
    this.onDelete = onDelete;
    this.color = color;

    // Add properties to underlying PIXI.Graphics:
    this.interactive = true;
    this.on('rightdown', this.onRightDown.bind(this));
    this.on('mousedown', this.onMouseDown.bind(this));
    this.redraw();
  }

  redraw() {
    this.beginFill(0xffffff);
    this.lineStyle({ width: 1, color: this.color, join: PIXI.LINE_JOIN.ROUND });
    this.drawRect(
      (-1 * RESIZE_HANDLE_SIZE_PX) / 2,
      (-1 * RESIZE_HANDLE_SIZE_PX) / 2,
      RESIZE_HANDLE_SIZE_PX,
      RESIZE_HANDLE_SIZE_PX
    );
  }

  setColor(color: number) {
    if (this.color !== color) {
      this.color = color;
      this.redraw();
    }
  }

  onRightDown = () => {
    if (this.onDelete) {
      this.onDelete();
    }
  };

  onMouseDown = (evt: any) => {
    evt.stopPropagation();
    if (!this.context.viewport.current) {
      return;
    }

    const boundingRectangle = this.getBounds();

    const initialPosition = ViewportCoordinates.toFloorplanCoordinates(
      ViewportCoordinates.create(
        boundingRectangle.x + RESIZE_HANDLE_SIZE_PX / 2,
        boundingRectangle.y + RESIZE_HANDLE_SIZE_PX / 2
      ),
      this.context.viewport.current,
      this.context.floorplan
    );

    if (this.onPress) {
      this.onPress(initialPosition, evt);
    }

    addDragHandler(
      this.context,
      initialPosition,
      evt,
      this.onDrag,
      this.onRelease
    );
  };
}
