// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-appPane-_qTgWwEC{display:flex;flex-direction:column;width:100%;overflow:hidden}\n", ""]);
// Exports
exports.locals = {
	"appPane": "dui-styles-module-appPane-_qTgWwEC"
};
module.exports = exports;
