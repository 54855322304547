import * as React from 'react';

const Outdoor = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C8.13401 2 5 5.13401 5 9V10H11V13H6V15H11V22H13V15H18V13H13V10H19V9C19 5.13401 15.866 2 12 2ZM12 4C14.419 4 16.4367 5.71776 16.9 8H7.10002C7.56329 5.71776 9.58104 4 12 4Z"
      fill={color}
    />
    <path d="M16 17V22H18V19H20V22H22V13H20V17H16Z" fill={color} />
    <path d="M2 22V13H4V17H8V22H6V19H4V22H2Z" fill={color} />
  </svg>
);

export default Outdoor;
