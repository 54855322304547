import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import * as PIXI from 'pixi.js';
import { FederatedPointerEvent } from '@pixi/events';
import {
  ViewportCoordinates,
  FloorplanCoordinates,
  computeBoundingRegionExtents,
  computePolygonEdges,
} from 'lib/geometry';
import { distanceToLineSegment } from 'lib/algorithm';
import { distance } from 'lib/math';
import Space, { SpaceValidation } from 'lib/space';
import { SensorValidation } from 'lib/sensor';
import WallSegment, {
  WALL_SEGMENT_SNAP_THRESHOLD_PIXELS,
} from 'lib/wall-segment';

import {
  ObjectLayer,
  ResizeHandle,
  useFloorplanLayerContext,
  isWithinViewport,
  addDragHandler,
  drawPolygon,
  toRawHex,
} from 'components/floorplan';
import { SPLITS } from 'lib/treatments';
import { useTreatment } from 'contexts/treatments';

import {
  Purple400,
  Purple700,
  Red400,
  Red700,
  Yellow400,
  Yellow700,
  White,
} from '@density/dust/dist/tokens/dust.tokens';

const FOCUSED_OUTLINE_WIDTH_PX = 4;

// The spaces layer renders a number of box, circle, and polygonal spaces to the floorplan
const SpacesLayer: React.FunctionComponent<{
  spaces: Array<Space>;
  locked?: boolean;
  validations?: Map<
    string,
    'empty' | 'loading' | Array<SensorValidation | SpaceValidation>
  >;
  walls?: Array<WallSegment>;
  highlightedObject?: {
    type:
      | 'sensor'
      | 'areaofconcern'
      | 'space'
      | 'photogroup'
      | 'reference'
      | 'layer';
    id: string;
  } | null;
  focusedObject?: null | {
    type: 'sensor' | 'areaofconcern' | 'space' | 'layer';
    id: string;
  };
  spaceOccupancy?: ReadonlyMap<Space['id'], { occupied: boolean }>;
  onMouseEnter?: (space: Space, event: FederatedPointerEvent) => void;
  onMouseLeave?: (space: Space, event: FederatedPointerEvent) => void;
  onMouseDown?: (space: Space, event: FederatedPointerEvent) => void;
  onDragMove?: (space: Space, newCoordinates: FloorplanCoordinates) => void;
  onResizeBoxSpace?: (
    space: Space,
    newPosition: FloorplanCoordinates,
    newWidth: number,
    newHeight: number
  ) => void;
  onResizeCircleSpace?: (
    space: Space,
    newPosition: FloorplanCoordinates,
    newRadius: number
  ) => void;
  onResizePolygonSpace?: (
    space: Space,
    newPosition: FloorplanCoordinates,
    newVertices: Array<FloorplanCoordinates>
  ) => void;
  onDuplicateSpace?: (space: Space) => void;
}> = ({
  spaces,
  locked = false,
  walls = [],
  validations = new Map<
    string,
    'empty' | 'loading' | Array<SensorValidation | SpaceValidation>
  >(),
  highlightedObject = null,
  focusedObject = null,
  spaceOccupancy = new Map(),
  onMouseEnter = null,
  onMouseLeave = null,
  onMouseDown = null,
  onDragMove = null,
  onResizeBoxSpace = null,
  onResizeCircleSpace = null,
  onResizePolygonSpace = null,
  onDuplicateSpace = null,
}) => {
  const context = useFloorplanLayerContext();

  const selectedSpace = useRef<Space | null>(null);
  const isSelectedSpaceMoving = useRef<boolean>(false);

  const isValidationEnabled = useTreatment(SPLITS.VALIDATION);
  const isSpaceSnappingEnabled = useTreatment(SPLITS.SPACE_SNAPPING);

  // FIXME: this probably is not the best approach to this, but because `onCreate` is not updated
  // within ObjectLayer due to dependency issues in the useEffect, it's possible that invocations of
  // `onDragMove` and friends within `onCreate` might be holding onto older references of these
  // functions from a previous render. So, cache the latest versions here in a ref so that the
  // latest version can always be called.
  //
  // The proper way to address this is by fixing the dependency issues within the `ObjectLayer`, but
  // this is a larger problem because the `onCreate` / `onUpdate` / `onRemove` function references
  // change every render and moving away from this is a large project across all layers.
  const latestOnMouseEnter = useRef(onMouseEnter);
  useEffect(() => {
    latestOnMouseEnter.current = onMouseEnter;
  }, [onMouseEnter]);

  const latestOnMouseLeave = useRef(onMouseLeave);
  useEffect(() => {
    latestOnMouseLeave.current = onMouseLeave;
  }, [onMouseLeave]);

  const latestOnMouseDown = useRef(onMouseDown);
  useEffect(() => {
    latestOnMouseDown.current = onMouseDown;
  }, [onMouseDown]);

  const latestOnDragMove = useRef(onDragMove);
  useEffect(() => {
    latestOnDragMove.current = onDragMove;
  }, [onDragMove]);

  const latestOnResizeBoxSpace = useRef(onResizeBoxSpace);
  useEffect(() => {
    latestOnResizeBoxSpace.current = onResizeBoxSpace;
  }, [onResizeBoxSpace]);

  const latestOnResizeCircleSpace = useRef(onResizeCircleSpace);
  useEffect(() => {
    latestOnResizeCircleSpace.current = onResizeCircleSpace;
  }, [onResizeCircleSpace]);

  const latestOnResizePolygonSpace = useRef(onResizePolygonSpace);
  useEffect(() => {
    latestOnResizePolygonSpace.current = onResizePolygonSpace;
  }, [onResizePolygonSpace]);

  const latestOnDuplicateSpace = useRef(onDuplicateSpace);
  useEffect(() => {
    latestOnDuplicateSpace.current = onDuplicateSpace;
  }, [onDuplicateSpace]);

  const latestLocked = useRef(locked);
  useEffect(() => {
    latestLocked.current = locked;
  }, [locked]);

  const spaceEdges = useRef<
    Array<[Space['id'], [FloorplanCoordinates, FloorplanCoordinates]]>
  >([]);
  useEffect(() => {
    const edges: Array<
      [Space['id'], [FloorplanCoordinates, FloorplanCoordinates]]
    > = [];
    for (const space of spaces) {
      for (const edge of Space.computeEdgesOfSpace(space, true)) {
        edges.push([space.id, edge]);
      }
    }
    spaceEdges.current = edges;
  }, [spaces]);

  // When the selected space changes, call this to update the space overlap data
  const selectedSpaceValidations = useRef<Array<SpaceValidation>>([]);
  const selectedSpaceOverlaps = useRef<
    | { enabled: false }
    | {
        enabled: true;
        spaceIds: Array<Space['id']>;
        intersectionPoints: Array<FloorplanCoordinates>;
      }
  >({ enabled: false });
  const selectedSpaceNearbySpaces = useRef<
    | { enabled: false }
    | {
        enabled: true;
        nearbySpaces: Array<{
          nearbySpaceId: Space['id'];
          distanceBetweenSpacesMeters: number;
          positionA: FloorplanCoordinates;
          positionB: FloorplanCoordinates;
        }>;
      }
  >({ enabled: false });
  const updateSelectedSpaceValidation = useCallback(() => {
    if (!isValidationEnabled) {
      selectedSpaceValidations.current = [];
      selectedSpaceOverlaps.current = { enabled: false };
      selectedSpaceNearbySpaces.current = { enabled: false };
      return;
    }
    if (!selectedSpace.current) {
      selectedSpaceValidations.current = [];
      selectedSpaceOverlaps.current = { enabled: false };
      selectedSpaceNearbySpaces.current = { enabled: false };
      return;
    }

    const validations = Space.validate(selectedSpace.current, spaces);
    selectedSpaceValidations.current = validations;

    const overlappingSpaceValidation = validations.find(
      (v) => v.validationType === 'space.intersectsAnotherSpace'
    );
    if (
      overlappingSpaceValidation &&
      overlappingSpaceValidation.validationType ===
        'space.intersectsAnotherSpace' &&
      overlappingSpaceValidation.intersectedSpaceIds.length > 0
    ) {
      selectedSpaceOverlaps.current = {
        enabled: true,
        spaceIds: overlappingSpaceValidation.intersectedSpaceIds,
        intersectionPoints: overlappingSpaceValidation.intersectionPoints,
      };
    } else {
      selectedSpaceOverlaps.current = { enabled: false };
    }

    const nearbySpaceValidation = validations.find(
      (v) => v.validationType === 'space.spaceTooClose'
    );
    if (
      nearbySpaceValidation &&
      nearbySpaceValidation.validationType === 'space.spaceTooClose' &&
      nearbySpaceValidation.nearbySpaces.length > 0
    ) {
      selectedSpaceNearbySpaces.current = {
        enabled: true,
        nearbySpaces: nearbySpaceValidation.nearbySpaces,
      };
    } else {
      selectedSpaceNearbySpaces.current = { enabled: false };
    }
  }, [isValidationEnabled, spaces]);

  // Set this data when a user's cursor is hovering over the border of a polygonal space. This is
  // used to facilutate clicking to create new vertices.
  const polygonSpaceVertexPosition = useRef<{
    vertexAIndex: number;
    vertexBIndex: number;
    position: FloorplanCoordinates;
  } | null>(null);

  // The `duplicatedSpace` state stores a copy of a space that is being duplicated so that this
  // new space (which is not yet in the `spaces` prop) can be rendered via the ObjectLayer
  const [duplicatedSpace, setDuplicatedSpace] = useState<Space | null>(null);

  const focusedSpace =
    focusedObject && focusedObject.type === 'space'
      ? spaces.find((space) => space.id === focusedObject.id)
      : null;
  useEffect(() => {
    if (!focusedSpace) {
      selectedSpace.current = null;
      selectedSpaceValidations.current = [];
      selectedSpaceOverlaps.current = { enabled: false };
      selectedSpaceNearbySpaces.current = { enabled: false };
      return;
    }

    const space =
      duplicatedSpace || spaces.find((space) => space.id === focusedSpace.id);
    if (!space) {
      return;
    }
    selectedSpace.current = space;

    // Ensure the space is not overlapping other spaces
    updateSelectedSpaceValidation();
  }, [spaces, focusedSpace, duplicatedSpace, updateSelectedSpaceValidation]);

  function shouldRenderSpace(
    space: Space,
    spaceViewportCoords: ViewportCoordinates
  ): boolean {
    if (!context.viewport.current) {
      return false;
    }

    let keyDimension: number;
    if (space.shape.type === 'box') {
      keyDimension = Math.max(space.shape.width, space.shape.height);
    } else if (space.shape.type === 'polygon') {
      // Find the vertex furthest from the center of the polygon
      const vertexDistanceApproximations = space.shape.vertices.map((v) =>
        Math.max(
          Math.abs(v.x - space.position.x),
          Math.abs(v.y - space.position.y)
        )
      );
      keyDimension = Math.max(...vertexDistanceApproximations);
    } else {
      keyDimension = space.shape.radius;
    }

    const keyDimensionInPx =
      keyDimension * context.floorplan.scale * context.viewport.current.zoom;

    return isWithinViewport(
      context,
      spaceViewportCoords,
      -1 * keyDimensionInPx
    );
  }

  function createResizeHandlesForPolygonalSpace(
    space: Space
  ): PIXI.Container | null {
    if (space.shape.type !== 'polygon') {
      return null;
    }

    const onResizeHandlePressed = () => {
      isSelectedSpaceMoving.current = true;
    };

    const onResizeHandleReleased = () => {
      if (!selectedSpace.current) {
        return;
      }
      if (selectedSpace.current.shape.type !== 'polygon') {
        return;
      }
      if (!latestOnResizePolygonSpace.current) {
        return;
      }
      isSelectedSpaceMoving.current = false;
      latestOnResizePolygonSpace.current(
        space,
        selectedSpace.current.position,
        selectedSpace.current.shape.vertices
      );
    };

    const onResizeHandleDeleted = (index: number) => {
      if (!selectedSpace.current) {
        return;
      }
      if (selectedSpace.current.shape.type !== 'polygon') {
        return;
      }
      if (selectedSpace.current.shape.vertices.length <= 3) {
        // Don't allow deleting a vertex if the resulting shape won't have three points
        return;
      }
      if (!latestOnResizePolygonSpace.current) {
        return;
      }

      // Remove the given resize handle
      const position = selectedSpace.current.position;
      const vertices = selectedSpace.current.shape.vertices.slice();
      vertices.splice(index, 1);
      selectedSpace.current = {
        ...selectedSpace.current,
        shape: {
          ...selectedSpace.current.shape,
          vertices,
        },
      };

      latestOnResizePolygonSpace.current(space, position, vertices);
    };

    const resizeHandles = new PIXI.Container();
    resizeHandles.name = 'resize-handles';
    for (let index = 0; index < space.shape.vertices.length; index += 1) {
      const resizeHandle = new ResizeHandle(
        context,
        (newPosition) => {
          if (!context.viewport.current) {
            return;
          }
          if (
            !selectedSpace.current ||
            selectedSpace.current.shape.type !== 'polygon'
          ) {
            return;
          }
          if (!latestOnResizePolygonSpace.current) {
            return;
          }

          // Snap to walls if they exist
          newPosition = snapPolygonSpaceToWallsAndOtherSpaces(
            selectedSpace.current,
            newPosition
          );

          const vertices = selectedSpace.current.shape.vertices.slice();
          vertices[index] = newPosition;
          selectedSpace.current = {
            ...selectedSpace.current,
            shape: { ...selectedSpace.current.shape, vertices },
          };

          // Ensure the space is not overlapping other spaces
          updateSelectedSpaceValidation();
        },
        {
          onPress: onResizeHandlePressed,
          onRelease: onResizeHandleReleased,
          onDelete: () => onResizeHandleDeleted(index),
        }
      );
      resizeHandle.cursor = 'move';
      resizeHandles.addChild(resizeHandle);
    }
    return resizeHandles;
  }

  const snapPolygonSpaceToWallsAndOtherSpaces = useCallback(
    (space: Space, newPosition: FloorplanCoordinates): FloorplanCoordinates => {
      if (!context.viewport.current) {
        return newPosition;
      }

      if (space.shape.type !== 'polygon') {
        return newPosition;
      }

      // Snap to walls if they exist
      if (walls.length === 0 && spaces.length === 0) {
        return newPosition;
      }

      // Get all wall segments that should be checked for snapping
      const vertices = Space.computePolygonBoundaryForSpace(space);
      let [upperLeft, lowerRight] = computeBoundingRegionExtents([
        ...vertices,
        newPosition,
      ]);
      if (!upperLeft || !lowerRight) {
        return newPosition;
      }

      // Pad the bounding box a little on each side so that wall segments within the snap
      // threshold outside of the regular bounding region will be included
      const paddingInPixels =
        WALL_SEGMENT_SNAP_THRESHOLD_PIXELS *
        context.floorplan.scale *
        context.viewport.current.zoom;
      upperLeft = FloorplanCoordinates.create(
        upperLeft.x - paddingInPixels,
        upperLeft.y - paddingInPixels
      );
      lowerRight = FloorplanCoordinates.create(
        lowerRight.x + paddingInPixels,
        lowerRight.y + paddingInPixels
      );

      const wallSegmentsInRegion =
        WallSegment.computeWallSegmentsInBoundingRegion(
          [
            ...walls,
            // FIXME: converting each space into "wallsegments" like this seems like a bit of
            // a bad idea. Come up with a better way of doing this? Like create a more
            // abstract notion of a "Segment" where a "WallSegment" is a more specific
            // version?
            ...spaceEdges.current.map(([_spaceId, [a, b]]) =>
              WallSegment.create(a, b)
            ),
          ],
          upperLeft,
          lowerRight
        );

      const [snapPoint] = WallSegment.computeNearestSnapPointInBoundingRegion(
        wallSegmentsInRegion,
        newPosition,
        upperLeft,
        lowerRight,
        context.floorplan,
        context.viewport.current,
        WALL_SEGMENT_SNAP_THRESHOLD_PIXELS
      );

      if (snapPoint) {
        return snapPoint;
      } else {
        return newPosition;
      }
    },
    [context.floorplan, context.viewport, spaces.length, walls]
  );

  const snapBoxSpaceToWallsAndOtherSpaces = useCallback(
    (
      space: Space,
      position: FloorplanCoordinates,
      mousePosition: FloorplanCoordinates | null = null
    ): FloorplanCoordinates => {
      if (space.shape.type !== 'box') {
        return position;
      }
      if (!isSpaceSnappingEnabled) {
        return position;
      }

      if (!context.viewport.current) {
        return position;
      }

      if (walls.length === 0 && spaces.length === 0) {
        return position;
      }

      // Get all wall segments that should be checked for snapping
      const vertices = Space.computePolygonBoundaryForSpace(space);
      let [upperLeft, lowerRight] = computeBoundingRegionExtents([
        ...vertices,
        position,
      ]);
      if (!upperLeft || !lowerRight) {
        return position;
      }
      // Pad the bounding box a little on each side so that wall segments within the snap
      // threshold outside of the regular bounding region will be included
      const paddingInPixels =
        WALL_SEGMENT_SNAP_THRESHOLD_PIXELS *
        context.floorplan.scale *
        context.viewport.current.zoom;
      upperLeft = FloorplanCoordinates.create(
        upperLeft.x - paddingInPixels,
        upperLeft.y - paddingInPixels
      );
      lowerRight = FloorplanCoordinates.create(
        lowerRight.x + paddingInPixels,
        lowerRight.y + paddingInPixels
      );

      const wallSegmentsInRegion =
        WallSegment.computeWallSegmentsInBoundingRegion(
          [
            ...walls,
            // FIXME: converting each space into "wallsegments" like this seems like a bit of
            // a bad idea. Come up with a better way of doing this? Like create a more
            // abstract notion of a "Segment" where a "WallSegment" is a more specific
            // version?
            ...spaceEdges.current.map(([_spaceId, [a, b]]) =>
              WallSegment.create(a, b)
            ),
          ],
          upperLeft,
          lowerRight
        );

      // Compute a list of all possible snaps that could occur given the segments around this space
      const snaps: Array<[FloorplanCoordinates, null | 'line' | 'point']> =
        vertices.flatMap((vertex) => {
          if (!context.viewport.current) {
            return [];
          }
          if (!upperLeft || !lowerRight) {
            return [];
          }

          const [snappedVertex, snapType] =
            WallSegment.computeNearestSnapPointInBoundingRegion(
              wallSegmentsInRegion,
              vertex,
              upperLeft,
              lowerRight,
              context.floorplan,
              context.viewport.current,
              WALL_SEGMENT_SNAP_THRESHOLD_PIXELS
            );

          if (!snappedVertex) {
            return [];
          }
          if (snappedVertex.x === vertex.x && snappedVertex.y === vertex.y) {
            return [];
          }

          const differenceX = snappedVertex.x - vertex.x;
          const differenceY = snappedVertex.y - vertex.y;
          const snappedPosition = FloorplanCoordinates.create(
            position.x + differenceX,
            position.y + differenceY
          );

          return [[snappedPosition, snapType]];
        });

      if (snaps.length === 0) {
        return position;
      }

      // Sort the snap points according to which is closest to the mouse position.
      //
      // This ensures that if there are two "competing" snaps, then the one closer to the cursor (and
      // therefore, where the user's attention is) will get priority.
      const sortedSnaps = snaps.sort(
        ([snapPositionA, _a], [snapPositionB, _b]) => {
          if (!mousePosition) {
            return 0;
          }
          return distance(snapPositionA, mousePosition) >
            distance(snapPositionB, mousePosition)
            ? 1
            : -1;
        }
      );

      // Point snaps generally take priority over line snaps.
      const pointSnap = sortedSnaps.find(
        ([_coord, snapType]) => snapType === 'point'
      );
      if (pointSnap) {
        return pointSnap[0];
      }

      const firstSnap = sortedSnaps[0];
      return firstSnap[0];
    },
    [
      context.floorplan,
      context.viewport,
      isSpaceSnappingEnabled,
      spaces.length,
      walls,
    ]
  );

  const spacesPlusDuplicatedSpace = useMemo(
    () => (duplicatedSpace ? [...spaces, duplicatedSpace] : spaces),
    [spaces, duplicatedSpace]
  );

  return (
    <ObjectLayer
      objects={spacesPlusDuplicatedSpace}
      extractId={(space) => {
        switch (space.shape.type) {
          case 'box':
            return `${space.id},box`;
          case 'circle':
            return `${space.id},circle`;
          case 'polygon':
            return `${space.id},polygon,${space.shape.vertices.length}`;
        }
      }}
      onCreate={(getSpace) => {
        if (!context.viewport.current) {
          return null;
        }

        const spaceGraphic = new PIXI.Container();

        const shape = new PIXI.Graphics();
        shape.name = 'shape';
        shape.interactive = true;
        spaceGraphic.addChild(shape);

        shape.on('mousedown', (evt) => {
          if (!context.viewport.current) {
            return;
          }
          if (latestOnMouseDown.current) {
            latestOnMouseDown.current(getSpace(), evt);
          }

          if (latestLocked.current || getSpace().locked) {
            return;
          }

          // If a user clicks on a polygon space when near the edge, create a new vertex
          if (
            selectedSpace.current &&
            getSpace().id === selectedSpace.current.id &&
            selectedSpace.current.shape.type === 'polygon' &&
            latestOnResizePolygonSpace.current &&
            polygonSpaceVertexPosition.current
          ) {
            const position = selectedSpace.current.position;
            const vertices = selectedSpace.current.shape.vertices.slice();
            vertices.splice(
              polygonSpaceVertexPosition.current.vertexBIndex,
              0,
              polygonSpaceVertexPosition.current.position
            );
            selectedSpace.current = {
              ...selectedSpace.current,
              shape: { ...selectedSpace.current.shape, vertices },
            };
            latestOnResizePolygonSpace.current(getSpace(), position, vertices);
            return;
          }

          // If a user alt/option clicked, then duplicate the space instead
          //
          // the `duplicatedSpace` state stores the space that is being duplicated so that this
          // space (which is not yet in the `spaces` prop) can be rendered via the ObjectLayer
          let isDuplicating = evt.data.originalEvent.altKey;
          if (latestOnDuplicateSpace.current && isDuplicating) {
            const newSpace = Space.duplicateSpace(getSpace());
            newSpace.name = Space.generateName(spaces);
            setDuplicatedSpace(newSpace);
          }

          // Otherwise, the user's trying to move the space
          isSelectedSpaceMoving.current = true;
          addDragHandler(
            context,
            getSpace().position,
            evt,
            (newPosition) => {
              if (!selectedSpace.current) {
                return;
              }
              const oldPosition = selectedSpace.current.position;

              selectedSpace.current = {
                ...selectedSpace.current,
                position: newPosition,
              };

              // Snap to walls if they exist
              selectedSpace.current.position =
                snapBoxSpaceToWallsAndOtherSpaces(
                  selectedSpace.current,
                  selectedSpace.current.position,
                  newPosition
                );

              // Translate all vertices when moving polygonal spaces
              if (selectedSpace.current.shape.type === 'polygon') {
                const positionDeltaX = newPosition.x - oldPosition.x;
                const positionDeltaY = newPosition.y - oldPosition.y;

                const vertices = selectedSpace.current.shape.vertices.slice();
                for (let index = 0; index < vertices.length; index += 1) {
                  vertices[index] = FloorplanCoordinates.create(
                    selectedSpace.current.shape.vertices[index].x +
                      positionDeltaX,
                    selectedSpace.current.shape.vertices[index].y +
                      positionDeltaY
                  );
                }
                selectedSpace.current = {
                  ...selectedSpace.current,
                  shape: { ...selectedSpace.current.shape, vertices },
                };
              }

              updateSelectedSpaceValidation();
            },
            () => {
              isSelectedSpaceMoving.current = false;
              if (!selectedSpace.current) {
                return;
              }

              // Skip calling onDragMove if the space hasn't actually moved
              if (
                selectedSpace.current.position.x === getSpace().position.x &&
                selectedSpace.current.position.y === getSpace().position.y
              ) {
                return;
              }

              // If the space was duplicated, then call a different method once the drag has
              // completed
              if (latestOnDuplicateSpace.current && isDuplicating) {
                latestOnDuplicateSpace.current(selectedSpace.current);
                setDuplicatedSpace(null);
                return;
              }

              if (latestOnDragMove.current) {
                latestOnDragMove.current(
                  getSpace(),
                  selectedSpace.current.position
                );
              }
            }
          );
        });
        shape.on('mouseover', (evt) => {
          if (latestOnMouseEnter.current) {
            latestOnMouseEnter.current(getSpace(), evt);
          }
        });
        shape.on('mouseout', (evt) => {
          if (latestOnMouseLeave.current) {
            latestOnMouseLeave.current(getSpace(), evt);
          }
        });
        shape.on('mousemove', (event) => {
          if (!context.viewport.current) {
            return;
          }
          if (space.shape.type !== 'polygon') {
            return;
          }

          const isFocused =
            selectedSpace.current && selectedSpace.current.id === getSpace().id;
          if (!isFocused) {
            return;
          }

          const positionViewport = ViewportCoordinates.create(
            event.data.global.x,
            event.data.global.y
          );

          // Figure out the vertices that the mouse position is in between
          let vertexAIndex = -1;
          let vertexBIndex = -1;
          let minDistance = Infinity;
          for (const [a, b] of computePolygonEdges(space.shape.vertices)) {
            const aViewport = FloorplanCoordinates.toViewportCoordinates(
              a,
              context.floorplan,
              context.viewport.current
            );
            const bViewport = FloorplanCoordinates.toViewportCoordinates(
              b,
              context.floorplan,
              context.viewport.current
            );

            const result = distanceToLineSegment(
              positionViewport,
              aViewport,
              bViewport
            );
            if (result < minDistance) {
              vertexAIndex = space.shape.vertices.indexOf(a);
              vertexBIndex = space.shape.vertices.indexOf(b);
              minDistance = result;
            }
          }

          // Only place vertices if the mouse is near an edge of the polygon
          if (minDistance > FOCUSED_OUTLINE_WIDTH_PX) {
            polygonSpaceVertexPosition.current = null;
            return;
          }

          const position = ViewportCoordinates.toFloorplanCoordinates(
            positionViewport,
            context.viewport.current,
            context.floorplan
          );

          polygonSpaceVertexPosition.current = {
            vertexAIndex,
            vertexBIndex,
            position,
          };
        });

        const space = getSpace();
        if (space.shape.type === 'box') {
          let initialX: number,
            initialY: number,
            initialWidth: number,
            initialHeight: number;
          const onResizeHandlePressed = () => {
            if (!selectedSpace.current) {
              return;
            }
            if (selectedSpace.current.shape.type !== 'box') {
              return;
            }
            if (!latestOnResizeBoxSpace.current) {
              return;
            }
            isSelectedSpaceMoving.current = true;
            initialX = selectedSpace.current.position.x;
            initialY = selectedSpace.current.position.y;
            initialWidth = selectedSpace.current.shape.width;
            initialHeight = selectedSpace.current.shape.height;
          };

          const onResizeHandleReleased = () => {
            if (!selectedSpace.current) {
              return;
            }
            if (selectedSpace.current.shape.type !== 'box') {
              return;
            }
            if (!latestOnResizeBoxSpace.current) {
              return;
            }
            isSelectedSpaceMoving.current = false;
            latestOnResizeBoxSpace.current(
              getSpace(),
              selectedSpace.current.position,
              selectedSpace.current.shape.width,
              selectedSpace.current.shape.height
            );
          };

          const topLeftResizeHandle = new ResizeHandle(
            context,
            (newPosition) => {
              if (!selectedSpace.current) {
                return;
              }
              if (!latestOnResizeBoxSpace.current) {
                return;
              }

              const leftSideOfWidth = initialX - newPosition.x;
              const rightSideOfWidth = initialWidth / 2;
              let newWidth = leftSideOfWidth + rightSideOfWidth;

              // Make sure space isn't too small
              if (newWidth < Space.MIN_WIDTH) {
                newWidth = Space.MIN_WIDTH;
              }

              const newX = initialX + initialWidth / 2 - newWidth / 2;

              const topSideOfHeight = initialY - newPosition.y;
              const bottomSideOfHeight = initialHeight / 2;
              let newHeight = topSideOfHeight + bottomSideOfHeight;

              // Make sure space isn't too small
              if (newHeight < Space.MIN_HEIGHT) {
                newHeight = Space.MIN_HEIGHT;
              }

              const newY = initialY + initialHeight / 2 - newHeight / 2;

              if (
                !selectedSpace.current ||
                selectedSpace.current.shape.type !== 'box'
              ) {
                return;
              }

              selectedSpace.current = {
                ...selectedSpace.current,
                position: FloorplanCoordinates.create(newX, newY),
                shape: {
                  type: 'box',
                  width: newWidth,
                  height: newHeight,
                },
              };

              const spaceSnappedPosition = snapBoxSpaceToWallsAndOtherSpaces(
                selectedSpace.current,
                selectedSpace.current.position,
                newPosition
              );

              // If the snapping was enabled, then read just the space's center, width, and height so
              // that the UPPER LEFT handle only is effected by the snapping (the other vertices of
              // the box should stay the same)
              if (
                spaceSnappedPosition.x !== selectedSpace.current.position.x ||
                spaceSnappedPosition.y !== selectedSpace.current.position.y
              ) {
                // NOTE: because this is the UPPER LEFT handle, use the LOWER RIGHT handle as that
                // handle's position should not ever change due to resize events from the UPPER LEFT
                // handle
                const lowerRight = FloorplanCoordinates.create(
                  initialX + initialWidth / 2,
                  initialY + initialHeight / 2
                );
                const newLowerRight = FloorplanCoordinates.create(
                  spaceSnappedPosition.x + newWidth / 2,
                  spaceSnappedPosition.y + newHeight / 2
                );

                selectedSpace.current = {
                  ...selectedSpace.current,
                  position: FloorplanCoordinates.create(
                    spaceSnappedPosition.x +
                      (lowerRight.x - newLowerRight.x) / 2,
                    spaceSnappedPosition.y +
                      (lowerRight.y - newLowerRight.y) / 2
                  ),
                  shape: {
                    type: 'box',
                    width: newWidth + (lowerRight.x - newLowerRight.x),
                    height: newHeight + (lowerRight.y - newLowerRight.y),
                  },
                };
              }

              // Ensure the space is not overlapping other spaces
              updateSelectedSpaceValidation();
            },
            {
              onPress: onResizeHandlePressed,
              onRelease: onResizeHandleReleased,
            }
          );
          topLeftResizeHandle.name = 'resize-top-left';
          topLeftResizeHandle.cursor = 'nwse-resize';
          spaceGraphic.addChild(topLeftResizeHandle);

          const bottomLeftResizeHandle = new ResizeHandle(
            context,
            (newPosition) => {
              if (!selectedSpace.current) {
                return;
              }
              if (!latestOnResizeBoxSpace.current) {
                return;
              }

              const leftSideOfWidth = initialX - newPosition.x;
              const rightSideOfWidth = initialWidth / 2;
              let newWidth = leftSideOfWidth + rightSideOfWidth;

              // Make sure space isn't too small
              if (newWidth < Space.MIN_WIDTH) {
                newWidth = Space.MIN_WIDTH;
              }

              const newX = initialX + initialWidth / 2 - newWidth / 2;

              const bottomSideOfHeight = newPosition.y - initialY;
              const topSideOfHeight = initialHeight / 2;
              let newHeight = bottomSideOfHeight + topSideOfHeight;

              // Make sure space isn't too small
              if (newHeight < Space.MIN_HEIGHT) {
                newHeight = Space.MIN_HEIGHT;
              }

              const newY = initialY - initialHeight / 2 + newHeight / 2;

              if (
                !selectedSpace.current ||
                selectedSpace.current.shape.type !== 'box'
              ) {
                return;
              }

              selectedSpace.current = {
                ...selectedSpace.current,
                position: FloorplanCoordinates.create(newX, newY),
                shape: {
                  type: 'box',
                  width: newWidth,
                  height: newHeight,
                },
              };

              const spaceSnappedPosition = snapBoxSpaceToWallsAndOtherSpaces(
                selectedSpace.current,
                selectedSpace.current.position,
                newPosition
              );

              // If the snapping was enabled, then read just the space's center, width, and height so
              // that the LOWER LEFT handle only is effected by the snapping (the other vertices of
              // the box should stay the same)
              if (
                spaceSnappedPosition.x !== selectedSpace.current.position.x ||
                spaceSnappedPosition.y !== selectedSpace.current.position.y
              ) {
                // NOTE: because this is the LOWER LEFT handle, use the UPPER RIGHT handle as that
                // handle's position should not ever change due to resize events from the LOWER LEFT
                // handle
                const upperRight = FloorplanCoordinates.create(
                  initialX + initialWidth / 2,
                  initialY - initialHeight / 2
                );
                const newUpperRight = FloorplanCoordinates.create(
                  spaceSnappedPosition.x + newWidth / 2,
                  spaceSnappedPosition.y - newHeight / 2
                );

                selectedSpace.current = {
                  ...selectedSpace.current,
                  position: FloorplanCoordinates.create(
                    spaceSnappedPosition.x +
                      (upperRight.x - newUpperRight.x) / 2,
                    spaceSnappedPosition.y -
                      (newUpperRight.y - upperRight.y) / 2
                  ),
                  shape: {
                    type: 'box',
                    width: newWidth + (upperRight.x - newUpperRight.x),
                    height: newHeight - (upperRight.y - newUpperRight.y),
                  },
                };
              }

              // Ensure the space is not overlapping other spaces
              updateSelectedSpaceValidation();
            },
            {
              onPress: onResizeHandlePressed,
              onRelease: onResizeHandleReleased,
            }
          );
          bottomLeftResizeHandle.name = 'resize-bottom-left';
          bottomLeftResizeHandle.cursor = 'nesw-resize';
          spaceGraphic.addChild(bottomLeftResizeHandle);

          const topRightResizeHandle = new ResizeHandle(
            context,
            (newPosition) => {
              if (!selectedSpace.current) {
                return;
              }
              if (!latestOnResizeBoxSpace.current) {
                return;
              }

              const rightSideOfWidth = newPosition.x - initialX;
              const leftSideOfWidth = initialWidth / 2;
              let newWidth = leftSideOfWidth + rightSideOfWidth;

              // Make sure space isn't too small
              if (newWidth < Space.MIN_WIDTH) {
                newWidth = Space.MIN_WIDTH;
              }

              const newX = initialX - initialWidth / 2 + newWidth / 2;

              const topSideOfHeight = initialY - newPosition.y;
              const bottomSideOfHeight = initialHeight / 2;
              let newHeight = topSideOfHeight + bottomSideOfHeight;

              // Make sure space isn't too small
              if (newHeight < Space.MIN_HEIGHT) {
                newHeight = Space.MIN_HEIGHT;
              }

              const newY = initialY + initialHeight / 2 - newHeight / 2;

              if (
                !selectedSpace.current ||
                selectedSpace.current.shape.type !== 'box'
              ) {
                return;
              }

              selectedSpace.current = {
                ...selectedSpace.current,
                position: FloorplanCoordinates.create(newX, newY),
                shape: {
                  ...selectedSpace.current.shape,
                  width: newWidth,
                  height: newHeight,
                },
              };

              const spaceSnappedPosition = snapBoxSpaceToWallsAndOtherSpaces(
                selectedSpace.current,
                selectedSpace.current.position,
                newPosition
              );

              // If the snapping was enabled, then read just the space's center, width, and height so
              // that the UPPER RIGHT handle only is effected by the snapping (the other vertices of
              // the box should stay the same)
              if (
                spaceSnappedPosition.x !== selectedSpace.current.position.x ||
                spaceSnappedPosition.y !== selectedSpace.current.position.y
              ) {
                // NOTE: because this is the UPPER RIGHT handle, use the LOWER LEFT handle as that
                // handle's position should not ever change due to resize events from the UPPER RIGHT
                // handle
                const lowerLeft = FloorplanCoordinates.create(
                  initialX - initialWidth / 2,
                  initialY + initialHeight / 2
                );
                const newLowerLeft = FloorplanCoordinates.create(
                  spaceSnappedPosition.x - newWidth / 2,
                  spaceSnappedPosition.y + newHeight / 2
                );

                selectedSpace.current = {
                  ...selectedSpace.current,
                  position: FloorplanCoordinates.create(
                    spaceSnappedPosition.x - (newLowerLeft.x - lowerLeft.x) / 2,
                    spaceSnappedPosition.y + (lowerLeft.y - newLowerLeft.y) / 2
                  ),
                  shape: {
                    type: 'box',
                    width: newWidth - (lowerLeft.x - newLowerLeft.x),
                    height: newHeight + (lowerLeft.y - newLowerLeft.y),
                  },
                };
              }

              // Ensure the space is not overlapping other spaces
              updateSelectedSpaceValidation();
            },
            {
              onPress: onResizeHandlePressed,
              onRelease: onResizeHandleReleased,
            }
          );
          topRightResizeHandle.name = 'resize-top-right';
          topRightResizeHandle.cursor = 'nesw-resize';
          spaceGraphic.addChild(topRightResizeHandle);

          const bottomRightResizeHandle = new ResizeHandle(
            context,
            (newPosition) => {
              if (!selectedSpace.current) {
                return;
              }
              if (!latestOnResizeBoxSpace.current) {
                return;
              }

              const rightSideOfWidth = newPosition.x - initialX;
              const leftSideOfWidth = initialWidth / 2;
              let newWidth = leftSideOfWidth + rightSideOfWidth;

              // Make sure space isn't too small
              if (newWidth < Space.MIN_WIDTH) {
                newWidth = Space.MIN_WIDTH;
              }

              const newX = initialX - initialWidth / 2 + newWidth / 2;

              const bottomSideOfHeight = newPosition.y - initialY;
              const topSideOfHeight = initialHeight / 2;
              let newHeight = bottomSideOfHeight + topSideOfHeight;

              // Make sure space isn't too small
              if (newHeight < Space.MIN_HEIGHT) {
                newHeight = Space.MIN_HEIGHT;
              }

              const newY = initialY - initialHeight / 2 + newHeight / 2;

              if (
                !selectedSpace.current ||
                selectedSpace.current.shape.type !== 'box'
              ) {
                return;
              }

              selectedSpace.current = {
                ...selectedSpace.current,
                position: FloorplanCoordinates.create(newX, newY),
                shape: {
                  ...selectedSpace.current.shape,
                  width: newWidth,
                  height: newHeight,
                },
              };

              const spaceSnappedPosition = snapBoxSpaceToWallsAndOtherSpaces(
                selectedSpace.current,
                selectedSpace.current.position,
                newPosition
              );

              // If the snapping was enabled, then read just the space's center, width, and height so
              // that the LOWER RIGHT handle only is effected by the snapping (the other vertices of
              // the box should stay the same)
              if (
                spaceSnappedPosition.x !== selectedSpace.current.position.x ||
                spaceSnappedPosition.y !== selectedSpace.current.position.y
              ) {
                // NOTE: because this is the LOWER RIGHT handle, use the UPPER LEFT handle as that
                // handle's position should not ever change due to resize events from the LOWER RIGHT
                // handle
                const upperLeft = FloorplanCoordinates.create(
                  initialX - initialWidth / 2,
                  initialY - initialHeight / 2
                );
                const newUpperLeft = FloorplanCoordinates.create(
                  spaceSnappedPosition.x - newWidth / 2,
                  spaceSnappedPosition.y - newHeight / 2
                );

                selectedSpace.current = {
                  ...selectedSpace.current,
                  position: FloorplanCoordinates.create(
                    spaceSnappedPosition.x - (newUpperLeft.x - upperLeft.x) / 2,
                    spaceSnappedPosition.y - (newUpperLeft.y - upperLeft.y) / 2
                  ),
                  shape: {
                    type: 'box',
                    width: newWidth - (upperLeft.x - newUpperLeft.x),
                    height: newHeight - (upperLeft.y - newUpperLeft.y),
                  },
                };
              }

              // Ensure the space is not overlapping other spaces
              updateSelectedSpaceValidation();
            },
            {
              onPress: onResizeHandlePressed,
              onRelease: onResizeHandleReleased,
            }
          );
          bottomRightResizeHandle.name = 'resize-bottom-right';
          bottomRightResizeHandle.cursor = 'nwse-resize';
          spaceGraphic.addChild(bottomRightResizeHandle);
        } else if (space.shape.type === 'polygon') {
          const resizeHandles = createResizeHandlesForPolygonalSpace(space);
          if (resizeHandles) {
            spaceGraphic.addChild(resizeHandles);
          }
        } else {
          let initialX: number, initialY: number;
          const onResizeHandlePressed = () => {
            if (!selectedSpace.current) {
              return;
            }
            if (!latestOnResizeCircleSpace.current) {
              return;
            }
            isSelectedSpaceMoving.current = true;
            initialX = selectedSpace.current.position.x;
            initialY = selectedSpace.current.position.y;
          };

          const onResizeHandleReleased = () => {
            if (!selectedSpace.current) {
              return;
            }
            if (selectedSpace.current.shape.type !== 'circle') {
              return;
            }
            if (!latestOnResizeCircleSpace.current) {
              return;
            }
            isSelectedSpaceMoving.current = false;
            latestOnResizeCircleSpace.current(
              getSpace(),
              selectedSpace.current.position,
              selectedSpace.current.shape.radius
            );
          };

          const topResizeHandle = new ResizeHandle(
            context,
            (newPosition) => {
              if (
                !selectedSpace.current ||
                selectedSpace.current.shape.type !== 'circle'
              ) {
                return;
              }
              if (!latestOnResizeCircleSpace.current) {
                return;
              }
              let newRadius = initialY - newPosition.y;

              // Make sure space isn't too small
              if (newRadius < Space.MIN_RADIUS) {
                newRadius = Space.MIN_RADIUS;
              }
              selectedSpace.current = {
                ...selectedSpace.current,
                shape: {
                  ...selectedSpace.current.shape,
                  radius: newRadius,
                },
              };

              // Ensure the space is not overlapping other spaces
              updateSelectedSpaceValidation();
            },
            {
              onPress: onResizeHandlePressed,
              onRelease: onResizeHandleReleased,
            }
          );
          topResizeHandle.name = 'resize-top';
          topResizeHandle.cursor = 'ns-resize';
          spaceGraphic.addChild(topResizeHandle);

          const bottomResizeHandle = new ResizeHandle(
            context,
            (newPosition) => {
              if (
                !selectedSpace.current ||
                selectedSpace.current.shape.type !== 'circle'
              ) {
                return;
              }
              if (!latestOnResizeCircleSpace.current) {
                return;
              }

              let newRadius = newPosition.y - initialY;

              // Make sure space isn't too small
              if (newRadius < Space.MIN_RADIUS) {
                newRadius = Space.MIN_RADIUS;
              }
              selectedSpace.current = {
                ...selectedSpace.current,
                shape: {
                  ...selectedSpace.current.shape,
                  radius: newRadius,
                },
              };

              // Ensure the space is not overlapping other spaces
              updateSelectedSpaceValidation();
            },
            {
              onPress: onResizeHandlePressed,
              onRelease: onResizeHandleReleased,
            }
          );
          bottomResizeHandle.name = 'resize-bottom';
          bottomResizeHandle.cursor = 'ns-resize';
          spaceGraphic.addChild(bottomResizeHandle);

          const leftResizeHandle = new ResizeHandle(
            context,
            (newPosition) => {
              if (
                !selectedSpace.current ||
                selectedSpace.current.shape.type !== 'circle'
              ) {
                return;
              }
              if (!latestOnResizeCircleSpace.current) {
                return;
              }
              let newRadius = initialX - newPosition.x;

              // Make sure space isn't too small
              if (newRadius < Space.MIN_RADIUS) {
                newRadius = Space.MIN_RADIUS;
              }
              selectedSpace.current = {
                ...selectedSpace.current,
                shape: {
                  ...selectedSpace.current.shape,
                  radius: newRadius,
                },
              };

              // Ensure the space is not overlapping other spaces
              updateSelectedSpaceValidation();
            },
            {
              onPress: onResizeHandlePressed,
              onRelease: onResizeHandleReleased,
            }
          );
          leftResizeHandle.name = 'resize-left';
          leftResizeHandle.cursor = 'ew-resize';
          spaceGraphic.addChild(leftResizeHandle);

          const rightResizeHandle = new ResizeHandle(
            context,
            (newPosition) => {
              if (
                !selectedSpace.current ||
                selectedSpace.current.shape.type !== 'circle'
              ) {
                return;
              }
              if (!latestOnResizeCircleSpace.current) {
                return;
              }
              let newRadius = newPosition.x - initialX;

              // Make sure space isn't too small
              if (newRadius < Space.MIN_RADIUS) {
                newRadius = Space.MIN_RADIUS;
              }
              selectedSpace.current = {
                ...selectedSpace.current,
                shape: {
                  ...selectedSpace.current.shape,
                  radius: newRadius,
                },
              };

              // Ensure the space is not overlapping other spaces
              updateSelectedSpaceValidation();
            },
            {
              onPress: onResizeHandlePressed,
              onRelease: onResizeHandleReleased,
            }
          );
          rightResizeHandle.name = 'resize-right';
          rightResizeHandle.cursor = 'ew-resize';
          spaceGraphic.addChild(rightResizeHandle);
        }

        return spaceGraphic;
      }}
      onUpdate={(s: Space, spaceContainer: PIXI.Container) => {
        if (!context.viewport.current) {
          return;
        }

        const isFocused =
          focusedObject &&
          focusedObject.type === 'space' &&
          focusedObject.id === s.id;
        const isHighlighted =
          highlightedObject &&
          highlightedObject.type === 'space' &&
          highlightedObject.id === s.id;

        const space: Space =
          isFocused && selectedSpace.current ? selectedSpace.current : s;

        const viewportCoords = FloorplanCoordinates.toViewportCoordinates(
          space.position,
          context.floorplan,
          context.viewport.current
        );

        spaceContainer.renderable = shouldRenderSpace(space, viewportCoords);
        if (!spaceContainer.renderable) {
          return;
        }

        spaceContainer.x = viewportCoords.x;
        spaceContainer.y = viewportCoords.y;

        // Figure out if the space has accregated points in it
        const occupancy = spaceOccupancy.get(space.id);
        const spaceOccupied = occupancy && occupancy.occupied;

        const spaceShape = spaceContainer.getChildByName(
          'shape'
        ) as PIXI.Graphics;
        if (!latestOnMouseDown.current) {
          spaceShape.cursor = 'default';
        } else if (!latestOnDragMove.current) {
          spaceShape.cursor = 'pointer';
        } else if (latestLocked.current || space.locked) {
          spaceShape.cursor = 'pointer';
        } else if (isFocused && polygonSpaceVertexPosition.current) {
          // When a new point can be added, show a special cursor
          spaceShape.cursor = 'copy';
        } else {
          spaceShape.cursor = 'grab';
        }

        let spaceColor = toRawHex(Purple400);
        let spaceColorDark = toRawHex(Purple700);

        let spaceValidations: Array<SpaceValidation>;
        if (isFocused && isSelectedSpaceMoving.current) {
          spaceValidations = selectedSpaceValidations.current;
        } else {
          const raw = validations.get(space.id) || null;
          switch (raw) {
            case 'empty':
            case 'loading':
            case null:
              spaceValidations = [];
              break;
            default:
              spaceValidations = raw.flatMap(
                (
                  v: SensorValidation | SpaceValidation
                ): Array<SpaceValidation> => {
                  // Filter out non space validations... this is really just to make typescript happy
                  if (v.objectType !== 'space') {
                    return [];
                  }

                  const selectedAndBeingMovedSpaceId =
                    selectedSpace.current && isSelectedSpaceMoving.current
                      ? selectedSpace.current.id
                      : null;

                  // If this space is too close to the selected space, then remove the selected space
                  // because it's currently being moved and its position isn't stable
                  if (v.validationType === 'space.spaceTooClose') {
                    const newNearbySpaces = v.nearbySpaces.filter((s) =>
                      selectedAndBeingMovedSpaceId
                        ? s.nearbySpaceId !== selectedAndBeingMovedSpaceId
                        : true
                    );
                    if (newNearbySpaces.length > 0) {
                      return [{ ...v, nearbySpaces: newNearbySpaces }];
                    } else {
                      return [];
                    }
                  }

                  // If this space is intersecting to the selected space, then remove the selected space
                  // because it's currently being moved and its position isn't stable
                  if (v.validationType === 'space.intersectsAnotherSpace') {
                    const newIntersectedSpaceIds = v.intersectedSpaceIds.filter(
                      (id) =>
                        selectedAndBeingMovedSpaceId
                          ? id !== selectedAndBeingMovedSpaceId
                          : true
                    );
                    if (newIntersectedSpaceIds.length > 0) {
                      return [
                        { ...v, intersectedSpaceIds: newIntersectedSpaceIds },
                      ];
                    } else {
                      return [];
                    }
                  }

                  return [v];
                }
              );
              break;
          }
        }

        if (
          isValidationEnabled &&
          spaceValidations.find((v) => v.severity === 'error')
        ) {
          spaceColor = toRawHex(Red400);
          spaceColorDark = toRawHex(Red700);
        } else if (
          isValidationEnabled &&
          spaceValidations.find((v) => v.severity === 'warning')
        ) {
          spaceColor = toRawHex(Yellow400);
          spaceColorDark = toRawHex(Yellow700);
        }

        spaceShape.clear();
        spaceShape.beginFill(spaceColor, spaceOccupied ? 0.5 : 0.12);

        if (isHighlighted || isFocused) {
          spaceShape.lineStyle({
            width: 1,
            color: spaceColor,
            join: PIXI.LINE_JOIN.ROUND,
          });
        } else if (spaceOccupied) {
          spaceShape.lineStyle({
            width: 1,
            color: spaceColorDark,
            join: PIXI.LINE_JOIN.ROUND,
          });
        } else {
          spaceShape.lineStyle({
            width: 1,
            color: spaceColor,
            join: PIXI.LINE_JOIN.ROUND,
            alpha: 0.1,
          });
        }

        switch (space.shape.type) {
          case 'box': {
            const widthPixels =
              space.shape.width *
              context.floorplan.scale *
              context.viewport.current.zoom;
            const heightPixels =
              space.shape.height *
              context.floorplan.scale *
              context.viewport.current.zoom;

            const upperLeftX = (-1 * widthPixels) / 2;
            const upperLeftY = (-1 * heightPixels) / 2;

            // Render filled box shape for space
            spaceShape.drawRect(
              upperLeftX,
              upperLeftY,
              widthPixels,
              heightPixels
            );

            const topLeftResizeHandle = spaceContainer.getChildByName(
              'resize-top-left'
            ) as ResizeHandle | null;
            const bottomLeftResizeHandle = spaceContainer.getChildByName(
              'resize-bottom-left'
            ) as ResizeHandle | null;
            const topRightResizeHandle = spaceContainer.getChildByName(
              'resize-top-right'
            ) as ResizeHandle | null;
            const bottomRightResizeHandle = spaceContainer.getChildByName(
              'resize-bottom-right'
            ) as ResizeHandle | null;
            if (
              !topLeftResizeHandle ||
              !topRightResizeHandle ||
              !bottomLeftResizeHandle ||
              !bottomRightResizeHandle
            ) {
              break;
            }

            // Disable resize handles if the space is locked
            topLeftResizeHandle.renderable =
              !latestLocked.current && !space.locked;
            bottomLeftResizeHandle.renderable =
              !latestLocked.current && !space.locked;
            topRightResizeHandle.renderable =
              !latestLocked.current && !space.locked;
            bottomRightResizeHandle.renderable =
              !latestLocked.current && !space.locked;

            if (isFocused) {
              // Add outline
              spaceShape.lineStyle({
                width: FOCUSED_OUTLINE_WIDTH_PX,
                color: spaceColor,
                alpha: 0.2,
                alignment: 0,
                join: PIXI.LINE_JOIN.ROUND,
              });
              spaceShape.drawRect(
                upperLeftX,
                upperLeftY,
                widthPixels,
                heightPixels
              );

              // Reposition resize handles
              topLeftResizeHandle.visible = true;
              topLeftResizeHandle.x = upperLeftX;
              topLeftResizeHandle.y = upperLeftY;
              topLeftResizeHandle.setColor(spaceColor);

              bottomLeftResizeHandle.visible = true;
              bottomLeftResizeHandle.x = upperLeftX;
              bottomLeftResizeHandle.y = upperLeftY + heightPixels;
              bottomLeftResizeHandle.setColor(spaceColor);

              topRightResizeHandle.visible = true;
              topRightResizeHandle.x = upperLeftX + widthPixels;
              topRightResizeHandle.y = upperLeftY;
              topRightResizeHandle.setColor(spaceColor);

              bottomRightResizeHandle.visible = true;
              bottomRightResizeHandle.x = upperLeftX + widthPixels;
              bottomRightResizeHandle.y = upperLeftY + heightPixels;
              bottomRightResizeHandle.setColor(spaceColor);
            } else {
              topLeftResizeHandle.visible = false;
              bottomLeftResizeHandle.visible = false;
              topRightResizeHandle.visible = false;
              bottomRightResizeHandle.visible = false;
            }
            break;
          }
          case 'circle': {
            const radiusPixels =
              space.shape.radius *
              context.floorplan.scale *
              context.viewport.current.zoom;

            const upperLeftX = -1 * radiusPixels;
            const upperLeftY = -1 * radiusPixels;

            // Render filled circle shape for space
            spaceShape.drawCircle(0, 0, radiusPixels);
            spaceShape.endFill();

            const topResizeHandle = spaceContainer.getChildByName(
              'resize-top'
            ) as ResizeHandle | null;
            const bottomResizeHandle = spaceContainer.getChildByName(
              'resize-bottom'
            ) as ResizeHandle | null;
            const leftResizeHandle = spaceContainer.getChildByName(
              'resize-left'
            ) as ResizeHandle | null;
            const rightResizeHandle = spaceContainer.getChildByName(
              'resize-right'
            ) as ResizeHandle | null;
            if (
              !topResizeHandle ||
              !bottomResizeHandle ||
              !leftResizeHandle ||
              !rightResizeHandle
            ) {
              break;
            }

            // Disable resize handles if the space is locked
            topResizeHandle.renderable = !latestLocked.current && !space.locked;
            bottomResizeHandle.renderable =
              !latestLocked.current && !space.locked;
            leftResizeHandle.renderable =
              !latestLocked.current && !space.locked;
            rightResizeHandle.renderable =
              !latestLocked.current && !space.locked;

            if (isFocused) {
              // Add outline
              spaceShape.lineStyle({
                width: FOCUSED_OUTLINE_WIDTH_PX,
                color: spaceColor,
                alpha: 0.2,
                alignment: 0,
                join: PIXI.LINE_JOIN.ROUND,
              });
              spaceShape.drawCircle(0, 0, radiusPixels);

              // Reposition resize handles
              topResizeHandle.visible = true;
              topResizeHandle.x = 0;
              topResizeHandle.y = upperLeftY;
              topResizeHandle.setColor(spaceColor);

              bottomResizeHandle.visible = true;
              bottomResizeHandle.x = 0;
              bottomResizeHandle.y = -1 * upperLeftY;
              bottomResizeHandle.setColor(spaceColor);

              leftResizeHandle.visible = true;
              leftResizeHandle.x = upperLeftX;
              leftResizeHandle.y = 0;
              leftResizeHandle.setColor(spaceColor);

              rightResizeHandle.visible = true;
              rightResizeHandle.x = -1 * upperLeftX;
              rightResizeHandle.y = 0;
              rightResizeHandle.setColor(spaceColor);
            } else {
              topResizeHandle.visible = false;
              bottomResizeHandle.visible = false;
              leftResizeHandle.visible = false;
              rightResizeHandle.visible = false;
            }
            break;
          }
          case 'polygon': {
            // Render polygon
            const verticesViewport = space.shape.vertices.map((v) => {
              if (!context.viewport.current) {
                throw new Error('This is impossible');
              }

              return ViewportCoordinates.create(
                (v.x - space.position.x) *
                  context.floorplan.scale *
                  context.viewport.current.zoom,
                (v.y - space.position.y) *
                  context.floorplan.scale *
                  context.viewport.current.zoom
              );
            });
            drawPolygon(spaceShape, verticesViewport);

            const resizeHandles = spaceContainer.getChildByName(
              'resize-handles'
            ) as PIXI.Container | undefined;
            if (!resizeHandles) {
              return;
            }

            // Disable resize handles if the space is locked
            resizeHandles.renderable = !latestLocked.current && !space.locked;

            if (isFocused) {
              // Add outline
              spaceShape.lineStyle({
                width: FOCUSED_OUTLINE_WIDTH_PX,
                color: spaceColor,
                alpha: 0.2,
                alignment: 1,
                join: PIXI.LINE_JOIN.ROUND,
              });
              drawPolygon(spaceShape, verticesViewport);

              // Ensure resize handles are positioned in the right spots
              resizeHandles.visible = true;
              for (
                let vertexIndex = 0;
                vertexIndex < verticesViewport.length;
                vertexIndex += 1
              ) {
                const handle = resizeHandles.children[vertexIndex] as
                  | ResizeHandle
                  | undefined;
                if (!handle) {
                  continue;
                }

                handle.x = verticesViewport[vertexIndex].x;
                handle.y = verticesViewport[vertexIndex].y;
                handle.setColor(spaceColor);
              }
            } else {
              resizeHandles.visible = false;
            }
          }
        }
        spaceShape.endFill();

        if (selectedSpace.current && selectedSpace.current.id === space.id) {
          if (selectedSpaceOverlaps.current.enabled) {
            for (const intersectionPoint of selectedSpaceOverlaps.current
              .intersectionPoints) {
              const intersectionPointViewport =
                FloorplanCoordinates.toViewportCoordinates(
                  intersectionPoint,
                  context.floorplan,
                  context.viewport.current
                );

              // Offset the intersection point coordinates because this is being rendered on
              // spaceShape (which is inside spaceContainer, which itself has a position)
              const offsetIntersectionPointViewportX =
                intersectionPointViewport.x - spaceContainer.x;
              const offsetIntersectionPointViewportY =
                intersectionPointViewport.y - spaceContainer.y;

              spaceShape.beginFill(toRawHex(Red400));
              spaceShape.lineStyle({ width: 0 });
              spaceShape.drawCircle(
                offsetIntersectionPointViewportX,
                offsetIntersectionPointViewportY,
                8
              );
              spaceShape.endFill();

              spaceShape.lineStyle({ width: 2, color: toRawHex(White) });
              spaceShape.moveTo(
                offsetIntersectionPointViewportX - 4,
                offsetIntersectionPointViewportY - 4
              );
              spaceShape.lineTo(
                offsetIntersectionPointViewportX + 4,
                offsetIntersectionPointViewportY + 4
              );
              spaceShape.moveTo(
                offsetIntersectionPointViewportX - 4,
                offsetIntersectionPointViewportY + 4
              );
              spaceShape.lineTo(
                offsetIntersectionPointViewportX + 4,
                offsetIntersectionPointViewportY - 4
              );
            }
          }

          if (selectedSpaceNearbySpaces.current.enabled) {
            for (const nearbySpace of selectedSpaceNearbySpaces.current
              .nearbySpaces) {
              spaceShape.lineStyle({ width: 2, color: toRawHex(Red400) });

              const positionAViewport =
                FloorplanCoordinates.toViewportCoordinates(
                  nearbySpace.positionA,
                  context.floorplan,
                  context.viewport.current
                );
              // Offset the intersection point coordinates because this is being rendered on
              // spaceShape (which is inside spaceContainer, which itself has a position)
              const positionAViewportOffsetX =
                positionAViewport.x - spaceContainer.x;
              const positionAViewportOffsetY =
                positionAViewport.y - spaceContainer.y;

              const positionBViewport =
                FloorplanCoordinates.toViewportCoordinates(
                  nearbySpace.positionB,
                  context.floorplan,
                  context.viewport.current
                );
              // Offset the intersection point coordinates because this is being rendered on
              // spaceShape (which is inside spaceContainer, which itself has a position)
              const positionBViewportOffsetX =
                positionBViewport.x - spaceContainer.x;
              const positionBViewportOffsetY =
                positionBViewport.y - spaceContainer.y;

              spaceShape.moveTo(
                positionAViewportOffsetX,
                positionAViewportOffsetY
              );
              spaceShape.lineTo(
                positionBViewportOffsetX,
                positionBViewportOffsetY
              );

              const midpointViewportOffsetX =
                (positionAViewportOffsetX + positionBViewportOffsetX) / 2;
              const midpointViewportOffsetY =
                (positionAViewportOffsetY + positionBViewportOffsetY) / 2;
              spaceShape.beginFill(toRawHex(Red400));
              spaceShape.lineStyle({ width: 0 });
              spaceShape.drawCircle(
                midpointViewportOffsetX,
                midpointViewportOffsetY,
                8
              );
              spaceShape.endFill();

              spaceShape.lineStyle({ width: 2, color: toRawHex(White) });
              spaceShape.moveTo(
                midpointViewportOffsetX - 4,
                midpointViewportOffsetY - 4
              );
              spaceShape.lineTo(
                midpointViewportOffsetX + 4,
                midpointViewportOffsetY + 4
              );
              spaceShape.moveTo(
                midpointViewportOffsetX - 4,
                midpointViewportOffsetY + 4
              );
              spaceShape.lineTo(
                midpointViewportOffsetX + 4,
                midpointViewportOffsetY - 4
              );
            }
          }
        }
      }}
      onRemove={(space: Space, spaceContainer) => {
        spaceContainer.destroy(true);
      }}
    />
  );
};

export default SpacesLayer;
