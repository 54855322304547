import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector, useDispatch } from 'react-redux';
import { enableMapSet, enablePatches } from 'immer';
import authReducer from './features/auth/auth-slice';
import userReducer from './features/user/user-slice';
import { NODE_ENV } from 'config';
import { devToolsConfig } from './dev-tools-config';
import { crashReporterMiddleware } from './middlewares/crash-reporter';
import { OALiveWebsocketMiddleware } from './middlewares/web-socket';

// allow usage of map and set inside the redux state
// not exactly kosher because its not serializable but we don't have to cross that bridge yet
// https://redux.js.org/style-guide/style-guide#do-not-put-non-serializable-values-in-state-or-actions
// https://immerjs.github.io/immer/map-set/
enableMapSet();

// support patches in immer
enablePatches();

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
  },

  // NOTE: disable dev tools in prod builds
  devTools: NODE_ENV === 'production' ? false : devToolsConfig,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([OALiveWebsocketMiddleware, crashReporterMiddleware]),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

// this is used for createAsyncThunk type parameters
export type AppThunkApiConfig = {
  dispatch: AppDispatch;
  state: RootState;
  serializedErrorType: unknown;
};

// Use instead of plain `useDispatch`
export const useAppDispatch = () => useDispatch<AppDispatch>();

// Use instead of plain `useSelector`
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
