// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-pagerButtonGroup-2b8vbnoS{display:flex}.dui-styles-module-pagerButton-39JRA7MX{flex-grow:0;flex-shrink:0;display:flex;align-items:center;justify-content:center;box-sizing:border-box;height:40px;min-width:40px;padding-left:16px;padding-right:16px;background-color:#fff;border:1px solid rgba(13,24,58,0.2);border-radius:4px;font-family:\"Aeonik\",\"Helvetica\",\"Arial\",sans-serif;font-size:14px;font-weight:bold;color:#0D183A;cursor:pointer;user-select:none}.dui-styles-module-pagerButton-39JRA7MX:hover{color:#1A79FF;background-color:#DAE6FF;border-color:#DAE6FF}.dui-styles-module-pagerButton-39JRA7MX:active,.dui-styles-module-pagerButton-39JRA7MX:focus{color:#687380;border-color:#1A79FF;box-shadow:0 0 0 1px #1A79FF}.dui-styles-module-pagerButtonPrevStart-ZR9c9Kkj{margin-right:2px}.dui-styles-module-pagerButtonNext-2F9O9Vcj{margin-left:8px;margin-right:2px}.dui-styles-module-pagerButtonDisabled-SQhMrR-t{background-color:#E0E3E6;border-color:#E0E3E6;color:#929BA6;opacity:0.5;cursor:not-allowed}.dui-styles-module-pagerButtonDisabled-SQhMrR-t:hover{background-color:#E0E3E6;color:#929BA6}.dui-styles-module-pagerButtonDisabled-SQhMrR-t:active{color:#929BA6}\n", ""]);
// Exports
exports.locals = {
	"pagerButtonGroup": "dui-styles-module-pagerButtonGroup-2b8vbnoS",
	"pagerButton": "dui-styles-module-pagerButton-39JRA7MX",
	"pagerButtonPrevStart": "dui-styles-module-pagerButtonPrevStart-ZR9c9Kkj",
	"pagerButtonNext": "dui-styles-module-pagerButtonNext-2F9O9Vcj",
	"pagerButtonDisabled": "dui-styles-module-pagerButtonDisabled-SQhMrR-t"
};
module.exports = exports;
