import * as React from 'react';

const Parking = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12ZM13.0714 7C13.7857 7 14.4167 7.13571 14.9643 7.40714C15.5119 7.67857 15.9357 8.06666 16.2357 8.57143C16.5357 9.07619 16.6857 9.6619 16.6857 10.3286C16.6857 10.9952 16.5357 11.5786 16.2357 12.0786C15.9357 12.5786 15.5119 12.9643 14.9643 13.2357C14.4167 13.5071 13.7857 13.6429 13.0714 13.6429H11.1429V17H9L9 7L13.0714 7ZM11.1429 8.78571H12.8714C13.3762 8.78571 13.7762 8.92381 14.0714 9.2C14.3667 9.47619 14.5143 9.85238 14.5143 10.3286C14.5143 10.7952 14.3667 11.1667 14.0714 11.4429C13.7762 11.719 13.3762 11.8571 12.8714 11.8571H11.1429V8.78571Z"
      fill={color}
    />
  </svg>
);

export default Parking;
