import * as React from 'react';
import { Fragment, useEffect, useRef, useState, useCallback } from 'react';
import classnames from 'classnames';
import { Icons } from '@density/dust';
import styles from './styles.module.scss';

import Button from 'components/button';
import TextField from 'components/text-field';
import HorizontalForm from 'components/horizontal-form';

// A component to render a name for something and allow it to be edited by clicking on it
const EditableName: React.FunctionComponent<{
  name: string;
  leadingIcon?: React.ReactNode;
  placeholder: string;
  'data-cy'?: string;
  loading: boolean;
  // A custom activation target allows this component to look different prior to being activated.
  //
  // This is used in the planning view for allowing a user to click on the plan name to show a
  // dropdown of related plans, but also still allowing the user to rename the floor
  customActivationTarget?: (
    activateEdit: () => void,
    hovered: boolean
  ) => React.ReactNode;
  onEditName?: (newName: string) => void;
  onEditCancel?: () => void;
}> = ({
  name,
  leadingIcon,
  placeholder,
  'data-cy': dataCy,
  loading,
  customActivationTarget,
  onEditName,
  onEditCancel,
}) => {
  const nameTextFieldRef = useRef<HTMLInputElement | null>(null);

  const [nameEditHovered, setNameEditHovered] = useState(false);
  const [nameEditVisible, setNameEditVisible] = useState(false);

  // Keep a local copy of the building name cached locally for handling the in progress edits
  const [workingName, setWorkingName] = useState('');
  useEffect(() => setWorkingName(name), [name]);

  // Focus and select the the building name text field when it becomes visible
  useEffect(() => {
    if (!nameEditVisible) {
      return;
    }
    if (!nameTextFieldRef.current) {
      return;
    }
    nameTextFieldRef.current.focus();
    nameTextFieldRef.current.select();
  }, [nameEditVisible]);

  const isNameEditable = typeof onEditName !== 'undefined';

  const onSubmit = useCallback(() => {
    setNameEditVisible(false);
    setNameEditHovered(false);

    if (!onEditName) {
      return;
    }

    // Only actually call onEditName if the user changed the name
    if (workingName === name) {
      return;
    }

    onEditName(workingName);
  }, [name, workingName, onEditName]);

  let activationTarget: React.ReactNode = (
    <Fragment>
      {name}
      {nameEditHovered ? (
        <span className={styles.editableNameEditHint}>
          <div className={styles.editableNameEditHintIcon}>
            <Icons.PencilOutlineEditor size={14} />
          </div>
          Click to edit name
        </span>
      ) : null}
    </Fragment>
  );

  if (customActivationTarget) {
    activationTarget = customActivationTarget(
      () => setNameEditVisible(true),
      nameEditHovered
    );
  }

  return (
    <Fragment>
      {leadingIcon || null}

      <div
        className={classnames(styles.editableName, {
          [styles.showEllipsis]: !customActivationTarget,
          [styles.clickable]: isNameEditable,
        })}
        // Only show and hide the building name edit workflow if the name is editable
        onMouseEnter={() => {
          if (isNameEditable) {
            setNameEditHovered(true);
          }
        }}
        onMouseLeave={() => {
          if (isNameEditable) {
            setNameEditHovered(false);
          }
        }}
        onClick={() => {
          if (!customActivationTarget && isNameEditable) {
            setNameEditVisible(true);
          }
        }}
        data-cy={dataCy}
      >
        {nameEditVisible || loading ? (
          <HorizontalForm size="medium">
            <TextField
              value={workingName}
              onChange={(e) => setWorkingName(e.currentTarget.value)}
              size="medium"
              width={300}
              ref={nameTextFieldRef}
              placeholder={placeholder}
              onKeyDown={(event) => {
                switch (event.key) {
                  case 'Escape':
                    setNameEditVisible(false);
                    setNameEditHovered(false);
                    break;
                  case 'Enter':
                    onSubmit();
                    break;
                }
              }}
              disabled={loading}
              data-cy={`${dataCy}-text-field`}
            />
            <Button
              size="medium"
              type="hollow"
              disabled={loading}
              onClick={(e) => {
                // NOTE: if stopPropagation is not called here, the onClick on the parent
                // .editableName div will be called, which will show the edit name state again
                e.stopPropagation();

                setNameEditVisible(false);
                setNameEditHovered(false);

                if (onEditCancel) {
                  onEditCancel();
                }
              }}
              data-cy={`${dataCy}-cancel`}
            >
              Cancel
            </Button>
            <Button
              size="medium"
              disabled={loading || workingName.length === 0}
              onClick={(e) => {
                // NOTE: if stopPropagation is not called here, the onClick on the parent
                // .editableName div will be called, which will show the edit name state again
                e.stopPropagation();

                onSubmit();
              }}
              data-cy={`${dataCy}-submit`}
            >
              {loading ? 'Saving...' : 'Rename'}
            </Button>
          </HorizontalForm>
        ) : (
          activationTarget
        )}
      </div>
    </Fragment>
  );
};

export default EditableName;
