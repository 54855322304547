import React from "react";

const Floor = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0624 2.02502L20.9281 7.94902C20.9491 7.96302 20.9681 7.97802 20.996 7.99802L18.0022 9.99802L21 12.001L18.0012 14.005L20.999 16.007L12.0763 21.97C12.0205 22.008 11.9825 22.011 11.9257 21.972L3.07185 16.056L3.002 16.006L5.99784 14.005L3 12.001L5.99684 9.99902L3.00299 7.99902L3.06387 7.95202L11.9376 2.02302C11.9835 1.99102 12.0155 1.99302 12.0624 2.02502ZM7.73626 15.255L6.61657 16.005L6.67844 16.05C8.42784 17.218 10.1772 18.386 11.9257 19.557C11.9825 19.595 12.0215 19.592 12.0763 19.554L17.3794 16.007L16.2627 15.256C16.2108 15.221 16.1759 15.221 16.124 15.256L12.0783 17.963C12.0175 18.004 11.9785 18.001 11.9197 17.962L7.87398 15.256C7.82309 15.221 7.78816 15.218 7.73626 15.255ZM7.73826 11.249L6.68243 11.957C6.66147 11.97 6.64451 11.986 6.62056 12.003L6.68243 12.047L11.9386 15.56C11.9855 15.592 12.0175 15.588 12.0624 15.557L17.3206 12.043L17.3774 12L17.3116 11.953L16.2657 11.252C16.2108 11.214 16.1759 11.216 16.122 11.252L12.0005 14.007L7.86999 11.248C7.82009 11.214 7.78716 11.216 7.73826 11.249ZM11.9267 4.44302L6.68043 7.95102C6.66048 7.96502 6.64151 7.98002 6.62056 7.99602L6.64351 8.01502L11.9506 11.561C11.9925 11.59 12.0185 11.579 12.0554 11.555L17.3814 7.99602L17.3146 7.94802L12.0714 4.44402C12.0185 4.40702 11.9815 4.40602 11.9267 4.44302Z"
      fill={color}
    />
  </svg>
);

export default Floor;
