// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-toast-oAwIMyt8{display:flex;flex-direction:row;justify-content:space-between;align-items:center;height:40px;padding-left:16px;padding-right:16px;border-radius:4px;box-shadow:2px 2px 0px #E6E7EB;line-height:1;user-select:none;opacity:0;pointer-events:none;transition:all 100ms ease-in-out}.dui-styles-module-toast-oAwIMyt8.dui-styles-module-visible-2KcSIXOh{pointer-events:auto;opacity:1}.dui-styles-module-toastText-32-LGOQq{display:inline-block;height:18px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}.dui-styles-module-toastDismiss-2A7U90xE{color:#fff;text-decoration:underline;font-weight:400;cursor:pointer;margin-left:24px}.dui-styles-module-toastDismiss-2A7U90xE:hover{opacity:0.95}.dui-styles-module-toastDismiss-2A7U90xE:active{opacity:1}.dui-styles-module-default-3SAYoTvP{background-color:#0D183A}.dui-styles-module-default-3SAYoTvP .dui-styles-module-toastText-32-LGOQq{color:#B7D7CF}.dui-styles-module-error-2GYHprH0{background-color:#FF5548}.dui-styles-module-error-2GYHprH0 .dui-styles-module-toastText-32-LGOQq{color:#FFF}.dui-styles-module-multiline-1uUwpZ6U{padding-top:8px;padding-bottom:8px;height:auto}.dui-styles-module-multiline-1uUwpZ6U .dui-styles-module-toastText-32-LGOQq{height:auto;line-height:20px;white-space:normal}\n", ""]);
// Exports
exports.locals = {
	"toast": "dui-styles-module-toast-oAwIMyt8",
	"visible": "dui-styles-module-visible-2KcSIXOh",
	"toastText": "dui-styles-module-toastText-32-LGOQq",
	"toastDismiss": "dui-styles-module-toastDismiss-2A7U90xE",
	"default": "dui-styles-module-default-3SAYoTvP",
	"error": "dui-styles-module-error-2GYHprH0",
	"multiline": "dui-styles-module-multiline-1uUwpZ6U"
};
module.exports = exports;
