import * as React from 'react';

const MeetingRoom = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 14L13 22H11L11 14L7 14V12L17 12V14H13ZM4 8C2.89543 8 2 7.10457 2 6C2 4.89543 2.89543 4 4 4C5.10457 4 6 4.89543 6 6C6 7.10457 5.10457 8 4 8ZM12 6C10.8954 6 10 5.10457 10 4C10 2.89543 10.8954 2 12 2C13.1046 2 14 2.89543 14 4C14 5.10457 13.1046 6 12 6ZM10 7L14 7C14.5523 7 15 7.44772 15 8V11H9L9 8C9 7.44772 9.44772 7 10 7ZM20 8C18.8954 8 18 7.10457 18 6C18 4.89543 18.8954 4 20 4C21.1046 4 22 4.89543 22 6C22 7.10457 21.1046 8 20 8ZM7 17.7808L3 16.7808L3 9H5V15.2192L9 16.2192V22H7L7 17.7808ZM17 17.7808L17 22H15L15 16.2192L19 15.2192L19 9H21L21 16.7808L17 17.7808Z"
      fill={color}
    />
  </svg>
);

export default MeetingRoom;
