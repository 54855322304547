import mixpanel, { Dict } from 'mixpanel-browser';
import Perfume from 'perfume.js';

import { MIXPANEL_TOKEN, NODE_ENV } from '../config';
import { StorageKeys } from './storage';

// "Do Not Track" is a browser setting that disabled analytics for users that request it at a
// browser level.
// ref: https://developer.mozilla.org/en-US/docs/Web/API/navigator/doNotTrack
export const isDoNotTrackOn = () => {
  return navigator.doNotTrack === '1' || navigator.doNotTrack === 'yes';
};

export const isDoNotTrackOverrideOn = () => {
  return localStorage.getItem(StorageKeys.DO_NOT_TRACK_OVERRIDE) === 'true';
};

export const isDoNotTrackOverrideOff = () => {
  return localStorage.getItem(StorageKeys.DO_NOT_TRACK_OVERRIDE) === 'false';
};

export class Analytics {
  static init() {
    if (NODE_ENV === 'development') {
      return;
    }

    if (!MIXPANEL_TOKEN) {
      return;
    }

    const analyticsDebug =
      localStorage.getItem(StorageKeys.ANALYTICS_DEBUG) === 'true';

    if (isDoNotTrackOverrideOn()) {
      mixpanel.init(MIXPANEL_TOKEN, {
        ignore_dnt: true,
        debug: analyticsDebug,
      });
    } else {
      if (isDoNotTrackOn()) {
        return;
      }

      mixpanel.init(MIXPANEL_TOKEN, { debug: analyticsDebug });
    }
  }

  static identify(id: string) {
    if (NODE_ENV === 'development') {
      return;
    }

    if (!MIXPANEL_TOKEN) {
      return;
    }

    if (isDoNotTrackOn() && !isDoNotTrackOverrideOn()) {
      return;
    }

    mixpanel.identify(id);
  }

  static register(properties: Dict) {
    if (NODE_ENV === 'development') {
      return;
    }

    if (!MIXPANEL_TOKEN) {
      return;
    }

    if (isDoNotTrackOn() && !isDoNotTrackOverrideOn()) {
      return;
    }

    mixpanel.register(properties);
  }

  static track(name: string, properties?: Dict) {
    if (NODE_ENV === 'development') {
      return;
    }

    if (!MIXPANEL_TOKEN) {
      return;
    }

    if (isDoNotTrackOn() && !isDoNotTrackOverrideOn()) {
      return;
    }

    mixpanel.track(`Planner ${name}`, properties);
  }
}

Analytics.init();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const perfume = new Perfume({
  analyticsTracker: (options) => {
    const { metricName, data } = options;
    switch (metricName) {
      case 'navigationTiming':
        // @ts-ignore
        if (data && data.timeToFirstByte && typeof data !== 'number') {
          Analytics.track('navigationTiming', data);
        }
        break;
      case 'networkInformation':
        // @ts-ignore
        if (data && data.effectiveType && typeof data !== 'number') {
          Analytics.track('networkInformation', data);
        }
        break;
      case 'fp':
        Analytics.track('firstPaint', { duration: data });
        break;
      case 'fcp':
        Analytics.track('firstContentfulPaint', { duration: data });
        break;
      case 'fid':
        Analytics.track('firstInputDelay', { duration: data });
        break;
      case 'lcp':
        Analytics.track('largestContentfulPaint', { duration: data });
        break;
      case 'lcpFinal':
        Analytics.track('largestContentfulPaintFinal', { duration: data });
        break;
      case 'cls':
        Analytics.track('cumulativeLayoutShift', { duration: data });
        break;
      case 'clsFinal':
        Analytics.track('cumulativeLayoutShiftFinal', { duration: data });
        break;
      case 'tbt':
        Analytics.track('totalBlockingTime', { duration: data });
        break;
      case 'tbt5S':
        Analytics.track('totalBlockingTime5S', { duration: data });
        break;
      case 'tbt10S':
        Analytics.track('totalBlockingTime10S', { duration: data });
        break;
      case 'tbtFinal':
        Analytics.track('totalBlockingTimeFinal', { duration: data });
        break;
      default:
        Analytics.track(metricName, { duration: data });
        break;
    }
  },
});
