// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-dateRangePicker-1ieghIvq.dui-styles-module-mouseMode-206BHvuJ :focus{outline:none}.dui-styles-module-dateRangePicker-1ieghIvq .dui-styles-module-commonRangeList-nUemGARH{width:140px;padding:1em 8px}.dui-styles-module-dateRangePicker-1ieghIvq .dui-styles-module-commonRangeItem-3P3X7vrK{color:#3F465F;padding:4px 12px;font-size:14px;cursor:pointer}.dui-styles-module-dateRangePicker-1ieghIvq .dui-styles-module-commonRangeItem-3P3X7vrK:hover{background-color:#EFF0F2}.dui-styles-module-dateRangePicker-1ieghIvq .DayPicker-Caption{color:#3F465F}.dui-styles-module-dateRangePicker-1ieghIvq .DayPicker-Weekday{color:#9FA3AF}.dui-styles-module-dateRangePicker-1ieghIvq .DayPicker-Day{border-radius:0 !important;font-weight:500 !important;color:#3F465F}.dui-styles-module-dateRangePicker-1ieghIvq .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover{background-color:#EFF0F2 !important}.dui-styles-module-dateRangePicker-1ieghIvq .DayPicker-Day--disabled{color:#CFD1D7}.dui-styles-module-dateRangePicker-1ieghIvq .DayPicker-Day--today{font-weight:bold !important;color:#1A79FF}.dui-styles-module-dateRangePicker-1ieghIvq .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside){background-color:#DAE6FF !important;color:#1A79FF}.dui-styles-module-dateRangePicker-1ieghIvq .DayPicker-Day--start:not(.DayPicker-Day--outside){border-top-left-radius:4px !important;border-bottom-left-radius:4px !important;background-color:#1A79FF !important;color:#fff !important}.dui-styles-module-dateRangePicker-1ieghIvq .DayPicker-Day--end:not(.DayPicker-Day--outside){border-top-right-radius:4px !important;border-bottom-right-radius:4px !important;background-color:#1A79FF !important;color:#fff !important}\n", ""]);
// Exports
exports.locals = {
	"dateRangePicker": "dui-styles-module-dateRangePicker-1ieghIvq",
	"mouseMode": "dui-styles-module-mouseMode-206BHvuJ",
	"commonRangeList": "dui-styles-module-commonRangeList-nUemGARH",
	"commonRangeItem": "dui-styles-module-commonRangeItem-3P3X7vrK"
};
module.exports = exports;
