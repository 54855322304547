import React from "react";

const Control2 = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M6,2 C7.86392711,2 9.43008706,3.27489272 9.87404517,5.00024347 L13,5 C15.3597236,5 17.5145536,6.17821282 18.800921,8.08085437 C20.6260923,8.45098318 22,10.0650449 22,12 C22,13.9353112 20.6255865,15.5496109 18.79959,15.9200687 C17.5116774,17.8247483 15.3569411,19 13,19 L9.87378648,19.0007613 C9.42947895,20.7256022 7.86356525,22 6,22 C3.790861,22 2,20.209139 2,18 C2,16.1360729 3.27489272,14.5699129 5.00024347,14.1259548 L5.00024347,9.87404517 C3.27489272,9.43008706 2,7.86392711 2,6 C2,3.790861 3.790861,2 6,2 Z M6,16 C4.8954305,16 4,16.8954305 4,18 C4,19.1045695 4.8954305,20 6,20 C7.1045695,20 8,19.1045695 8,18 C8,16.8954305 7.1045695,16 6,16 Z M7.00076134,9.87378648 L7.00076134,14.1262135 C8.40618726,14.4882419 9.51255353,15.5949306 9.87412177,17.0005412 L13,17 C14.2896966,17 15.4936962,16.5081823 16.4006345,15.6663859 C14.9873443,15.050017 14,13.6403118 14,12 C14,10.3600751 14.9868785,8.95064809 16.3991465,8.333208 C15.4941858,7.49251953 14.2903618,7 13,7 L9.87386215,7.00046755 C9.51202415,8.4055989 8.40585103,9.51184473 7.00076134,9.87378648 Z M18,10 C16.8954305,10 16,10.8954305 16,12 C16,13.1045695 16.8954305,14 18,14 C19.1045695,14 20,13.1045695 20,12 C20,10.8954305 19.1045695,10 18,10 Z M6,4 C4.8954305,4 4,4.8954305 4,6 C4,7.1045695 4.8954305,8 6,8 C7.1045695,8 8,7.1045695 8,6 C8,4.8954305 7.1045695,4 6,4 Z"
      />
    </g>
  </svg>
);

export default Control2;
