import React from "react";

const HeartBeat = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M19.2610637,15 L15.3636364,15 L14.5084385,14.5183017 L13.4959654,12.847721 L11.6444092,15.5633368 L9.96298399,15.5183017 L8.14480217,12.5183017 L9.85519783,11.4816983 L10.867671,13.152279 L12.7192272,10.4366632 L14.4006524,10.4816983 L15.9268958,13 L18.2275603,13 C19.3930532,11.2596922 20,9.72188542 20,8.5 C20,6.56700338 18.4329966,5 16.5,5 C14.5670034,5 13,6.56700338 13,8.5 L11,8.5 C11,6.56700338 9.43299662,5 7.5,5 C5.56700338,5 4,6.56700338 4,8.5 C4,11.0178948 6.65549852,14.8012654 12.0196187,19.6733498 C13.0760236,18.7650637 14.0344332,17.870692 14.8906802,17 L17.6311657,17 C16.2627371,18.5415643 14.5906132,20.1428639 12.6401844,21.7682213 L11.9789535,22.319247 L11.3356362,21.7474093 C5.13175648,16.2328496 2,11.8831878 2,8.5 C2,5.46243388 4.46243388,3 7.5,3 C9.36015777,3 11.0046326,3.92344845 12,5.33692087 C12.9953674,3.92344845 14.6398422,3 16.5,3 C19.5375661,3 22,5.46243388 22,8.5 C22,10.3620326 21.0501904,12.5804524 19.2610637,15 Z"
      />
    </g>
  </svg>
);

export default HeartBeat;
