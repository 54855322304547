export const COVERAGE_INTERSECTION_ANGLE_START_ANGLE_DEGREES = 0;
export const COVERAGE_INTERSECTION_ANGLE_END_ANGLE_DEGREES = 360;
export const COVERAGE_INTERSECTION_VOID_MIN_SIZE = 40;

// Because of the inaccuracy of the polygon-clipping library, when doing unions / intersectinos /
// etc with sensor coverage areas, the data can't be as highly granular as it normally could be:
// - COURSE mode is meant to be used when doing geometry with coverage itnersectino shapes
// - FINE mode should be used when generating coverage regions to display to the user directly
export enum CoverageIntersectionSampleRate {
  FINE = 'fine',
  COURSE = 'course',
}

export const COVERAGE_INTERSECTION_FINE_SAMPLE_INTERVAL_METERS = 0.05;
export const COVERAGE_INTERSECTION_FINE_ANGLE_INTERVAL_DEGREES = 2;

export const COVERAGE_INTERSECTION_COURSE_SAMPLE_INTERVAL_METERS = 0.1;
export const COVERAGE_INTERSECTION_COURSE_ANGLE_INTERVAL_DEGREES = 10;
