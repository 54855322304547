import * as React from 'react';

const Desk = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9993 9V4L11.9996 4V9H14.9995L14.9995 11L2 11V13H4.00075L4.00075 22H5.99983L5.99983 19.5H17.9993L17.9993 22H19.9993L19.9993 13H22V11H16.9994V9H19.9993ZM5.99983 17.5V13L17.9993 13V17.5H5.99983Z"
      fill={color}
    />
  </svg>
);

export default Desk;
