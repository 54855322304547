import { useEffect, useRef, useMemo } from 'react';
import * as PIXI from 'pixi.js';
import { FederatedPointerEvent } from '@pixi/events';
import {
  ViewportCoordinates,
  FloorplanCoordinates,
  computeBoundingRegionExtents,
} from 'lib/geometry';
import { distanceToLineSegment } from 'lib/algorithm';
import WallSegment, {
  WALL_SEGMENT_SNAP_THRESHOLD_PIXELS,
} from 'lib/wall-segment';
import { AreaOfConcern } from 'components/editor/state';

import {
  ObjectLayer,
  ResizeHandle,
  useFloorplanLayerContext,
  isWithinViewport,
  addDragHandler,
  drawPolygon,
  toRawHex,
} from 'components/floorplan';

import {
  Teal400,
  Blue700,
  Blue400,
  Green400,
  Gray300,
  Yellow400,
  White,
} from '@density/dust/dist/tokens/dust.tokens';

const FOCUSED_OUTLINE_WIDTH_PX = 4;

// The areasOfConcern layer renders a number of box, circle, and polygonal areasOfConcern to the floorplan
const AreasOfCoverageLayer: React.FunctionComponent<{
  areasOfConcern: Array<AreaOfConcern>;
  walls: Array<WallSegment>;
  highlightedObject: {
    type:
      | 'sensor'
      | 'areaofconcern'
      | 'space'
      | 'photogroup'
      | 'reference'
      | 'layer';
    id: string;
  } | null;
  focusedObject: null | {
    type: 'sensor' | 'areaofconcern' | 'space' | 'layer';
    id: string;
  };
  onMouseEnter: (
    areaOfConcern: AreaOfConcern,
    event: FederatedPointerEvent
  ) => void;
  onMouseLeave: (
    areaOfConcern: AreaOfConcern,
    event: FederatedPointerEvent
  ) => void;
  onMouseDown: (
    areaOfConcern: AreaOfConcern,
    event: FederatedPointerEvent
  ) => void;
  onDragMove: (
    areaOfConcern: AreaOfConcern,
    newCoordinates: FloorplanCoordinates
  ) => void;
  onDragOrigin: (
    areaOfConcern: AreaOfConcern,
    newOrigin: FloorplanCoordinates
  ) => void;
  onResize: (
    areaOfConcern: AreaOfConcern,
    newPosition: FloorplanCoordinates,
    newVertices: Array<FloorplanCoordinates>
  ) => void;
}> = ({
  areasOfConcern,
  walls,
  highlightedObject,
  focusedObject,
  onMouseEnter,
  onMouseLeave,
  onMouseDown,
  onDragMove,
  onDragOrigin,
  onResize,
}) => {
  const context = useFloorplanLayerContext();

  const originSpriteTexture = useMemo(() => {
    const gr = new PIXI.Graphics();
    gr.lineStyle({ width: 2, color: toRawHex(Blue400) });

    // Draw endpoint
    gr.drawCircle(0, 0, 8);

    // Draw crosshairs in endpoint
    gr.moveTo(-16, 0);
    gr.lineTo(16, 0);
    gr.moveTo(0, -16);
    gr.lineTo(0, 16);

    return context.app.renderer.generateTexture(gr);
  }, [context.app]);

  const selectedAreaOfConcern = useRef<AreaOfConcern | null>(null);

  // Set this data when a user's cursor is hovering over the border of a polygonal areaOfConcern. This is
  // used to facilutate clicking to create new vertices.
  const areaOfConcernVertexPosition = useRef<{
    vertexAIndex: number;
    vertexBIndex: number;
    position: FloorplanCoordinates;
  } | null>(null);

  const focusedAreaOfConcern =
    focusedObject && focusedObject.type === 'areaofconcern'
      ? areasOfConcern.find(
          (areaOfConcern) => areaOfConcern.id === focusedObject.id
        )
      : null;
  useEffect(() => {
    if (!focusedAreaOfConcern) {
      selectedAreaOfConcern.current = null;
      return;
    }
    const areaOfConcern = areasOfConcern.find(
      (areaOfConcern) => areaOfConcern.id === focusedAreaOfConcern.id
    );
    if (!areaOfConcern) {
      return;
    }
    selectedAreaOfConcern.current = areaOfConcern;
  }, [areasOfConcern, focusedAreaOfConcern]);

  function shouldRenderAreaOfConcern(
    areaOfConcern: AreaOfConcern,
    areaOfConcernViewportCoords: ViewportCoordinates
  ): boolean {
    if (!context.viewport.current) {
      return false;
    }

    // Find the vertex furthest from the center of the polygon
    const vertexDistanceApproximations = areaOfConcern.vertices.map((v) =>
      Math.max(
        Math.abs(v.x - areaOfConcern.position.x),
        Math.abs(v.y - areaOfConcern.position.y)
      )
    );
    const keyDimension = Math.max(...vertexDistanceApproximations);

    const keyDimensionInPx =
      keyDimension * context.floorplan.scale * context.viewport.current.zoom;

    return isWithinViewport(
      context,
      areaOfConcernViewportCoords,
      -1 * keyDimensionInPx
    );
  }

  return (
    <ObjectLayer
      objects={areasOfConcern}
      extractId={(areaOfConcern) =>
        `${areaOfConcern.id},${areaOfConcern.vertices.length}`
      }
      onCreate={(getAreaOfConcern) => {
        if (!context.viewport.current) {
          return null;
        }

        const container = new PIXI.Container();

        const shape = new PIXI.Graphics();
        shape.name = 'shape';
        shape.interactive = true;
        shape.cursor = 'grab';
        container.addChild(shape);

        shape.on('mousedown', (evt) => {
          if (!context.viewport.current) {
            return;
          }
          onMouseDown(getAreaOfConcern(), evt);

          if (getAreaOfConcern().locked) {
            return;
          }

          // If a user clicks on an area of concern when near the edge, create a new vertex
          if (
            selectedAreaOfConcern.current &&
            getAreaOfConcern().id === selectedAreaOfConcern.current.id &&
            areaOfConcernVertexPosition.current
          ) {
            selectedAreaOfConcern.current.vertices =
              selectedAreaOfConcern.current.vertices.slice();
            selectedAreaOfConcern.current.vertices.splice(
              areaOfConcernVertexPosition.current.vertexBIndex,
              0,
              areaOfConcernVertexPosition.current.position
            );
            onResize(
              selectedAreaOfConcern.current,
              selectedAreaOfConcern.current.position,
              selectedAreaOfConcern.current.vertices
            );
            return;
          }

          // Otherwise, the user's trying to move the areaOfConcern
          addDragHandler(
            context,
            getAreaOfConcern().position,
            evt,
            (newPosition) => {
              if (!selectedAreaOfConcern.current) {
                return;
              }
              const oldPosition = selectedAreaOfConcern.current.position;

              const vertices = selectedAreaOfConcern.current.vertices.slice();

              // Translate all vertices when moving areas of concern
              const positionDeltaX = newPosition.x - oldPosition.x;
              const positionDeltaY = newPosition.y - oldPosition.y;
              for (let index = 0; index < vertices.length; index += 1) {
                vertices[index] = FloorplanCoordinates.create(
                  vertices[index].x + positionDeltaX,
                  vertices[index].y + positionDeltaY
                );
              }

              selectedAreaOfConcern.current = {
                ...selectedAreaOfConcern.current,
                position: newPosition,
                vertices,

                // Translate origin position too
                originPosition: FloorplanCoordinates.create(
                  selectedAreaOfConcern.current.originPosition.x +
                    positionDeltaX,
                  selectedAreaOfConcern.current.originPosition.y +
                    positionDeltaY
                ),
              };
            },
            () => {
              if (!selectedAreaOfConcern.current) {
                return;
              }
              onDragMove(
                getAreaOfConcern(),
                selectedAreaOfConcern.current.position
              );
            }
          );
        });
        shape.on('mouseover', (evt) => onMouseEnter(getAreaOfConcern(), evt));
        shape.on('mouseout', (evt) => onMouseLeave(getAreaOfConcern(), evt));
        shape.on('mousemove', (event) => {
          if (!context.viewport.current) {
            return;
          }

          const isFocused =
            selectedAreaOfConcern.current &&
            selectedAreaOfConcern.current.id === getAreaOfConcern().id;
          if (!isFocused) {
            return;
          }

          const positionViewport = ViewportCoordinates.create(
            event.data.global.x,
            event.data.global.y
          );

          // Figure out the vertices that the mouse position is in between
          let vertexAIndex = -1;
          let vertexBIndex = -1;
          let minDistance = Infinity;
          for (const [a, b] of AreaOfConcern.edges(
            getAreaOfConcern().vertices
          )) {
            const aViewport = FloorplanCoordinates.toViewportCoordinates(
              a,
              context.floorplan,
              context.viewport.current
            );
            const bViewport = FloorplanCoordinates.toViewportCoordinates(
              b,
              context.floorplan,
              context.viewport.current
            );

            const result = distanceToLineSegment(
              positionViewport,
              aViewport,
              bViewport
            );
            if (result < minDistance) {
              vertexAIndex = getAreaOfConcern().vertices.indexOf(a);
              vertexBIndex = getAreaOfConcern().vertices.indexOf(b);
              minDistance = result;
            }
          }

          // Only place vertices if the mouse is near an edge of the polygon
          if (minDistance > FOCUSED_OUTLINE_WIDTH_PX) {
            areaOfConcernVertexPosition.current = null;
            return;
          }

          const position = ViewportCoordinates.toFloorplanCoordinates(
            positionViewport,
            context.viewport.current,
            context.floorplan
          );

          areaOfConcernVertexPosition.current = {
            vertexAIndex,
            vertexBIndex,
            position,
          };
        });

        const sensorPlacements = new PIXI.Graphics();
        sensorPlacements.name = 'sensor-placements';
        container.addChild(sensorPlacements);

        // Render a resize handle at every polygon vertex around the perimeter of the area of
        // coverage
        const resizeHandles = new PIXI.Container();
        resizeHandles.name = 'resize-handles';
        container.addChild(resizeHandles);

        const onResizeHandleReleased = () => {
          if (!selectedAreaOfConcern.current) {
            return;
          }
          onResize(
            selectedAreaOfConcern.current,
            selectedAreaOfConcern.current.position,
            selectedAreaOfConcern.current.vertices
          );
        };

        const onResizeHandleDeleted = (index: number) => {
          if (!selectedAreaOfConcern.current) {
            return;
          }
          if (selectedAreaOfConcern.current.vertices.length <= 3) {
            // Don't allow deleting a vertex if the resulting shape won't have three points
            return;
          }

          // Remove the given resize handle
          selectedAreaOfConcern.current.vertices =
            selectedAreaOfConcern.current.vertices.slice();
          selectedAreaOfConcern.current.vertices.splice(index, 1);

          onResize(
            selectedAreaOfConcern.current,
            selectedAreaOfConcern.current.position,
            selectedAreaOfConcern.current.vertices
          );
        };

        for (
          let index = 0;
          index < getAreaOfConcern().vertices.length;
          index += 1
        ) {
          const resizeHandle = new ResizeHandle(
            context,
            (newPosition) => {
              if (!context.viewport.current) {
                return;
              }
              if (!selectedAreaOfConcern.current) {
                return;
              }

              // Snap to walls if they exist
              if (walls.length > 0) {
                // Get all wall segments that should be checked for snapping
                const verticesPlusPosition = [
                  ...selectedAreaOfConcern.current.vertices,
                  newPosition,
                ];
                let [upperLeft, lowerRight] =
                  computeBoundingRegionExtents(verticesPlusPosition);
                if (upperLeft && lowerRight) {
                  // Pad the bounding box a little on each side so that wall segments within the snap
                  // threshold outside of the regular bounding region will be included
                  const paddingInPixels =
                    WALL_SEGMENT_SNAP_THRESHOLD_PIXELS *
                    context.floorplan.scale *
                    context.viewport.current.zoom;
                  upperLeft = FloorplanCoordinates.create(
                    upperLeft.x - paddingInPixels,
                    upperLeft.y - paddingInPixels
                  );
                  lowerRight = FloorplanCoordinates.create(
                    lowerRight.x + paddingInPixels,
                    lowerRight.y + paddingInPixels
                  );

                  const wallSegmentsInRegion =
                    WallSegment.computeWallSegmentsInBoundingRegion(
                      walls,
                      upperLeft,
                      lowerRight
                    );

                  const [snapPoint] =
                    WallSegment.computeNearestSnapPointInBoundingRegion(
                      wallSegmentsInRegion,
                      newPosition,
                      upperLeft,
                      lowerRight,
                      context.floorplan,
                      context.viewport.current,
                      WALL_SEGMENT_SNAP_THRESHOLD_PIXELS
                    );
                  if (snapPoint) {
                    newPosition = snapPoint;
                  }
                }
              }

              selectedAreaOfConcern.current.vertices =
                selectedAreaOfConcern.current.vertices.slice();
              selectedAreaOfConcern.current.vertices[index] = newPosition;
            },
            {
              color: toRawHex(Teal400),
              onRelease: onResizeHandleReleased,
              onDelete: () => onResizeHandleDeleted(index),
            }
          );
          resizeHandle.cursor = 'move';
          resizeHandles.addChild(resizeHandle);
        }

        // Render a marker to indicate the origin position
        const originHandleSprite = new PIXI.Sprite(originSpriteTexture);
        originHandleSprite.name = 'origin-handle';
        originHandleSprite.anchor.set(0.5, 0.5);
        originHandleSprite.interactive = true;
        originHandleSprite.cursor = 'grab';
        originHandleSprite.on('mousedown', (evt) => {
          originHandleSprite.cursor = 'grabbing';
          addDragHandler(
            context,
            getAreaOfConcern().originPosition,
            evt,
            (newPosition) => {
              if (!selectedAreaOfConcern.current) {
                return;
              }
              selectedAreaOfConcern.current = {
                ...selectedAreaOfConcern.current,
                originPosition: newPosition,
              };
            },
            () => {
              if (!selectedAreaOfConcern.current) {
                return;
              }
              onDragOrigin(
                getAreaOfConcern(),
                selectedAreaOfConcern.current.originPosition
              );
              originHandleSprite.cursor = 'grab';
            }
          );
        });
        container.addChild(originHandleSprite);

        return container;
      }}
      onUpdate={(s: AreaOfConcern, areaOfConcernContainer: PIXI.Container) => {
        if (!context.viewport.current) {
          return;
        }

        const isFocused =
          focusedObject &&
          focusedObject.type === 'areaofconcern' &&
          focusedObject.id === s.id;
        const isHighlighted =
          highlightedObject &&
          highlightedObject.type === 'areaofconcern' &&
          highlightedObject.id === s.id;

        const areaOfConcern: AreaOfConcern =
          isFocused && selectedAreaOfConcern.current
            ? selectedAreaOfConcern.current
            : s;

        const viewportCoords = FloorplanCoordinates.toViewportCoordinates(
          areaOfConcern.position,
          context.floorplan,
          context.viewport.current
        );

        areaOfConcernContainer.renderable = shouldRenderAreaOfConcern(
          areaOfConcern,
          viewportCoords
        );
        if (!areaOfConcernContainer.renderable) {
          return;
        }

        areaOfConcernContainer.x = viewportCoords.x;
        areaOfConcernContainer.y = viewportCoords.y;

        const areasOfConcernShape = areaOfConcernContainer.getChildByName(
          'shape'
        ) as PIXI.Graphics | null;
        if (!areasOfConcernShape) {
          return;
        }

        if (areaOfConcern.locked) {
          // When locked, only show a pointer
          areasOfConcernShape.cursor = 'pointer';
        } else if (isFocused && areaOfConcernVertexPosition.current) {
          // When a new point can be added, show a special cursor
          areasOfConcernShape.cursor = 'copy';
        } else {
          areasOfConcernShape.cursor = 'grab';
        }
        areasOfConcernShape.clear();
        areasOfConcernShape.beginFill(
          areaOfConcern.sensorsEnabled ? toRawHex(Gray300) : toRawHex(Teal400),
          0.2
        );

        if (isHighlighted || isFocused) {
          areasOfConcernShape.lineStyle({
            width: 1,
            color: toRawHex(Teal400),
            join: PIXI.LINE_JOIN.ROUND,
          });
        }

        // Render polygon
        const verticesViewport = areaOfConcern.vertices.map((v) => {
          if (!context.viewport.current) {
            throw new Error('This is impossible');
          }

          return ViewportCoordinates.create(
            (v.x - areaOfConcern.position.x) *
              context.floorplan.scale *
              context.viewport.current.zoom,
            (v.y - areaOfConcern.position.y) *
              context.floorplan.scale *
              context.viewport.current.zoom
          );
        });
        drawPolygon(areasOfConcernShape, verticesViewport);
        areasOfConcernShape.endFill();

        const resizeHandles = areaOfConcernContainer.getChildByName(
          'resize-handles'
        ) as PIXI.Container | undefined;
        if (!resizeHandles) {
          return;
        }

        if (isFocused) {
          // Add outline
          areasOfConcernShape.lineStyle({
            width: FOCUSED_OUTLINE_WIDTH_PX,
            color: toRawHex(Teal400),
            alpha: 0.2,
            alignment: 1,
            join: PIXI.LINE_JOIN.ROUND,
          });
          drawPolygon(areasOfConcernShape, verticesViewport);

          // Ensure resize handles are positioned in the right spots
          resizeHandles.visible =
            areaOfConcern.sensorPlacements.type === 'done' &&
            !areaOfConcern.locked;
          for (
            let vertexIndex = 0;
            vertexIndex < verticesViewport.length;
            vertexIndex += 1
          ) {
            const handle = resizeHandles.children[vertexIndex];
            if (!handle) {
              continue;
            }

            handle.x = verticesViewport[vertexIndex].x;
            handle.y = verticesViewport[vertexIndex].y;
          }
        } else {
          resizeHandles.visible = false;
        }

        const sensorPlacements = areaOfConcernContainer.getChildByName(
          'sensor-placements'
        ) as PIXI.Graphics | null;
        if (!sensorPlacements) {
          return;
        }
        sensorPlacements.clear();

        // Draw the position of each sensor placement
        if (
          areaOfConcern.sensorsEnabled &&
          (areaOfConcern.sensorPlacements.type === 'loading' ||
            areaOfConcern.sensorPlacements.type === 'done')
        ) {
          for (const sensorPlacement of areaOfConcern.sensorPlacements.data) {
            const pointViewportOffsetX =
              sensorPlacement.positionOffset[0] *
              context.floorplan.scale *
              context.viewport.current.zoom;
            const pointViewportOffsetY =
              sensorPlacement.positionOffset[1] *
              context.floorplan.scale *
              context.viewport.current.zoom;

            const isActualCoverage =
              (areaOfConcern.coverageIntersectionWallsEnabled ||
                areaOfConcern.coverageIntersectionHeightMapEnabled) &&
              sensorPlacement.coveragePolygon.length > 0;

            sensorPlacements.lineStyle({
              width: 1,
              color: isActualCoverage ? toRawHex(Green400) : toRawHex(Blue400),
              join: PIXI.LINE_JOIN.ROUND,
            });
            sensorPlacements.beginFill(
              isActualCoverage ? toRawHex(Green400) : toRawHex(Blue400),
              0.5
            );

            let firstX: number | null = null;
            let firstY: number | null = null;
            for (const [x, y] of sensorPlacement.coveragePolygon) {
              const pointViewportOffsetX =
                x * context.floorplan.scale * context.viewport.current.zoom;
              const pointViewportOffsetY =
                y * context.floorplan.scale * context.viewport.current.zoom;

              if (firstX === null && firstY === null) {
                sensorPlacements.moveTo(
                  pointViewportOffsetX,
                  pointViewportOffsetY
                );
                firstX = pointViewportOffsetX;
                firstY = pointViewportOffsetY;
              } else {
                sensorPlacements.lineTo(
                  pointViewportOffsetX,
                  pointViewportOffsetY
                );
              }
            }
            if (firstX !== null && firstY !== null) {
              sensorPlacements.lineTo(firstX, firstY);
            }
            sensorPlacements.endFill();

            sensorPlacements.beginFill(toRawHex(Blue700), 1);
            sensorPlacements.lineStyle({
              width: 3,
              color: toRawHex(White),
              join: PIXI.LINE_JOIN.ROUND,
            });
            sensorPlacements.drawRoundedRect(
              pointViewportOffsetX - 5,
              pointViewportOffsetY - 5,
              10,
              10,
              2
            );
            sensorPlacements.endFill();
          }
          for (const autodetectedRoom of areaOfConcern.sensorPlacements
            .autodetectedRooms) {
            // Draw center point of room
            const centerViewportOffsetX =
              (autodetectedRoom.centerPoint.x - areaOfConcern.position.x) *
              context.floorplan.scale *
              context.viewport.current.zoom;
            const centerViewportOffsetY =
              (autodetectedRoom.centerPoint.y - areaOfConcern.position.y) *
              context.floorplan.scale *
              context.viewport.current.zoom;

            sensorPlacements.lineStyle({
              width: 2,
              color: toRawHex(Yellow400),
              join: PIXI.LINE_JOIN.ROUND,
            });

            sensorPlacements.beginFill(toRawHex(Yellow400), 0.75);
            sensorPlacements.drawCircle(
              centerViewportOffsetX,
              centerViewportOffsetY,
              5
            );
            sensorPlacements.endFill();

            // Draw sensor placements within room
            let first = true;
            for (const point of autodetectedRoom.sensorPlacements) {
              const pointViewportOffsetX =
                (point.x - areaOfConcern.position.x) *
                context.floorplan.scale *
                context.viewport.current.zoom;
              const pointViewportOffsetY =
                (point.y - areaOfConcern.position.y) *
                context.floorplan.scale *
                context.viewport.current.zoom;

              sensorPlacements.drawCircle(
                pointViewportOffsetX,
                pointViewportOffsetY,
                5
              );

              if (first) {
                sensorPlacements.moveTo(
                  pointViewportOffsetX,
                  pointViewportOffsetY
                );
                first = false;
              } else {
                sensorPlacements.lineTo(
                  pointViewportOffsetX,
                  pointViewportOffsetY
                );
              }
            }

            // Draw polygon border of room
            let firstX: number | null = null;
            let firstY: number | null = null;
            for (const point of autodetectedRoom.polygon) {
              const pointViewportOffsetX =
                (point.x - areaOfConcern.position.x) *
                context.floorplan.scale *
                context.viewport.current.zoom;
              const pointViewportOffsetY =
                (point.y - areaOfConcern.position.y) *
                context.floorplan.scale *
                context.viewport.current.zoom;

              if (firstX === null && firstY === null) {
                sensorPlacements.moveTo(
                  pointViewportOffsetX,
                  pointViewportOffsetY
                );
                firstX = pointViewportOffsetX;
                firstY = pointViewportOffsetY;
              } else {
                sensorPlacements.lineTo(
                  pointViewportOffsetX,
                  pointViewportOffsetY
                );
              }
            }
            if (firstX !== null && firstY !== null) {
              sensorPlacements.lineTo(firstX, firstY);
            }
          }
        }

        // Update the position of the origin position sprite to reflext the origin position of the
        // sensors
        const originHandleSprite = areaOfConcernContainer.getChildByName(
          'origin-handle'
        ) as PIXI.Sprite | undefined;
        if (!originHandleSprite) {
          return;
        }
        if (
          areaOfConcern.originPosition &&
          !areaOfConcern.locked &&
          areaOfConcern.sensorsEnabled &&
          areaOfConcern.sensorPlacements.type !== 'loading'
        ) {
          originHandleSprite.renderable = true;
          const originPositionViewport =
            FloorplanCoordinates.toViewportCoordinates(
              areaOfConcern.originPosition,
              context.floorplan,
              context.viewport.current
            );
          originHandleSprite.x = originPositionViewport.x - viewportCoords.x;
          originHandleSprite.y = originPositionViewport.y - viewportCoords.y;
        } else {
          originHandleSprite.renderable = false;
        }
      }}
      onRemove={(areaOfConcern: AreaOfConcern, container) => {
        container.getChildByName('shape').destroy();
        container.getChildByName('sensor-placements').destroy();
        container.getChildByName('resize-handles').destroy();

        // NOTE: The origin texture is shared between all area of concern instances, so don't
        // destroy the underlying texture
        container
          .getChildByName('origin-handle')
          .destroy({ texture: false, baseTexture: false });
      }}
    />
  );
};

export default AreasOfCoverageLayer;
