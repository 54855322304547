import { Fragment, useEffect, useState, useRef } from 'react';
import * as React from 'react';
import * as PIXI from 'pixi.js';
import styles from './styles.module.scss';
import * as dust from '@density/dust/dist/tokens/dust.tokens';
import { Checkbox } from '@density/ui';

import { Icons } from '@density/dust';
import AppBar from 'components/app-bar';
import { DarkTheme } from 'components/theme';
import Tooltip from 'components/tooltip';
import Button from 'components/button';
import FillCenter from 'components/fill-center';
import Floorplan, {
  Layer,
  useFloorplanLayerContext,
} from 'components/floorplan';
import FloorplanZoomControls from 'components/floorplan-zoom-controls';
import FormLabel from 'components/form-label';
import FloorplanCoordinatesField from 'components/floorplan-coordinates-field';
import HorizontalForm from 'components/horizontal-form';
import Panel, { PanelTitle, PanelBody } from 'components/panel';
import TextField from 'components/text-field';
import RotationField from 'components/rotation-field';
import OpacityField from 'components/opacity-field';
import PercentField from 'components/percent-field';
import RangeExtentsSlider from 'components/range-extents-slider';

import { FloorplanCoordinates, ViewportCoordinates } from 'lib/geometry';
import HeightMap from 'lib/heightmap';
import parseGeoTiff, {
  GEOTIFF_NO_DATA,
  ParsedGeoTiff,
  GeoTiffTiepoint,
} from 'lib/geotiff';
import { round } from 'lib/math';
import { LengthUnit } from 'lib/units';
import FloorplanType from 'lib/floorplan';
import { FixMe } from 'types/fixme';

import HeightMapRegistrationLayer from 'components/editor/floorplan-layers/height-map-registration-layer';

const HeightMapExtents: React.FunctionComponent<{
  smallestHeightMeters: number;
  largestHeightMeters: number;
  minMeters: number;
  maxMeters: number;
  onChange: (minMeters: number, maxMeters: number) => void;

  activeExtent: 'max' | 'min' | null;
  onPickExtent: (extent: 'max' | 'min' | null) => void;
}> = ({
  smallestHeightMeters,
  largestHeightMeters,
  minMeters,
  maxMeters,
  onChange,
  activeExtent,
  onPickExtent,
}) => {
  const [minMetersText, setMinMetersText] = useState('');
  useEffect(() => {
    setMinMetersText(`${round(minMeters, 2)}`);
  }, [minMeters]);
  const [minMetersInvalid, setMinMetersInvalid] = useState(false);

  const [maxMetersText, setMaxMetersText] = useState('');
  useEffect(() => {
    setMaxMetersText(`${round(maxMeters, 2)}`);
  }, [maxMeters]);
  const [maxMetersInvalid, setMaxMetersInvalid] = useState(false);

  return (
    <Fragment>
      <HorizontalForm size="medium">
        <TextField
          value={minMetersText}
          error={minMetersInvalid}
          onChange={(e) => setMinMetersText(e.currentTarget.value)}
          min={smallestHeightMeters}
          max={maxMeters}
          width={85}
          size="medium"
          onBlur={() => {
            const newMinMeters = parseFloat(minMetersText);
            if (isNaN(newMinMeters)) {
              setMinMetersInvalid(true);
              return;
            }
            if (minMeters === newMinMeters) {
              return;
            }
            setMinMetersInvalid(false);
            onChange(newMinMeters, maxMeters);
          }}
          trailingSuffix="m"
          data-cy="height-map-minimum-extent"
        />
        <Tooltip
          contents="Pick Lowest Obstruction"
          enterDelay={0}
          target={
            <Button
              onClick={() =>
                onPickExtent(activeExtent !== 'min' ? 'min' : null)
              }
              type={activeExtent === 'min' ? 'filled' : 'outlined'}
              trailingIcon={<Icons.Eyedropper size={18} />}
              size="medium"
              data-cy="height-map-minimum-extent-eyedropper"
            />
          }
        />

        <TextField
          value={maxMetersText}
          error={maxMetersInvalid}
          onChange={(e) => setMaxMetersText(e.currentTarget.value)}
          min={minMeters}
          max={largestHeightMeters}
          width={85}
          size="medium"
          onBlur={() => {
            const newMaxMeters = parseFloat(maxMetersText);
            if (isNaN(newMaxMeters)) {
              setMaxMetersInvalid(true);
              return;
            }
            if (newMaxMeters === maxMeters) {
              return;
            }
            setMaxMetersInvalid(false);
            onChange(minMeters, newMaxMeters);
          }}
          trailingSuffix="m"
          data-cy="height-map-maximum-extent"
        />
        <Tooltip
          contents="Pick Ceiling"
          enterDelay={0}
          target={
            <Button
              onClick={() =>
                onPickExtent(activeExtent !== 'max' ? 'max' : null)
              }
              type={activeExtent === 'max' ? 'filled' : 'outlined'}
              trailingIcon={<Icons.Eyedropper size={18} />}
              size="medium"
              data-cy="height-map-maximum-extent-eyedropper"
            />
          }
        />
      </HorizontalForm>

      <RangeExtentsSlider
        rangeMinValue={smallestHeightMeters}
        rangeMaxValue={largestHeightMeters}
        minRangeWidth={0.05}
        minValue={minMeters}
        maxValue={maxMeters}
        data-cy="height-map-extents"
        onChange={onChange}
      >
        <div className={styles.heightMapExtentsSliderInner} />
      </RangeExtentsSlider>
    </Fragment>
  );
};

const HeightMapHeightRangeHeightPickerLayer: React.FunctionComponent<{
  heightMap: HeightMap;
  onPickHeight: (heightMeters: number) => void;
}> = ({ heightMap, onPickHeight }) => {
  const context = useFloorplanLayerContext();
  const geotiffParseResults = useParseGeoTiff(heightMap.url);

  useEffect(() => {
    const backdropSprite = new PIXI.Sprite(PIXI.Texture.WHITE);
    backdropSprite.name = 'height-picker-backdrop';
    backdropSprite.alpha = 0;
    backdropSprite.interactive = true;
    backdropSprite.cursor = 'crosshair';
    backdropSprite.on('mousedown', (event) => {
      if (!context.viewport.current) {
        return;
      }
      if (geotiffParseResults.status !== 'complete') {
        return;
      }

      const mousePosition = ViewportCoordinates.create(
        event.data.global.x,
        event.data.global.y
      );

      const heightMapPosition = ViewportCoordinates.toHeightMapCoordinates(
        mousePosition,
        context.viewport.current,
        context.floorplan,
        heightMap,
        geotiffParseResults.scale
      );

      geotiffParseResults
        .getHeightAtPoint(
          Math.round(heightMapPosition.x),
          Math.round(heightMapPosition.y)
        )
        .then((heightMeters) => {
          // If the user clicks outside the geotiff, disregard that point
          if (heightMeters === GEOTIFF_NO_DATA) {
            return;
          }

          // If the request was aborted for some reason, disregard
          if (heightMeters === null) {
            return;
          }

          onPickHeight(heightMeters);
        });
    });

    context.app.stage.addChild(backdropSprite);
    return () => {
      context.app.stage.removeChild(backdropSprite);
    };
  }, [context, heightMap, geotiffParseResults, onPickHeight]);

  return (
    <Layer
      onAnimationFrame={() => {
        if (!context.viewport.current) {
          return;
        }

        const backdropSprite = context.app.stage.getChildByName(
          'height-picker-backdrop'
        ) as PIXI.Sprite | null;

        if (!backdropSprite) {
          return;
        }

        backdropSprite.width = context.viewport.current.width;
        backdropSprite.height = context.viewport.current.height;
      }}
    />
  );
};

export const useParseGeoTiff = (heightMapUrl: HeightMap['url'] | null) => {
  const [geotiffParseResults, setGeotiffParseResults] = useState<
    | { status: 'pending' }
    | { status: 'loading' }
    | ({ status: 'complete' } & ParsedGeoTiff)
    | { status: 'error'; error: Error }
  >({ status: 'pending' });

  // Parse the raw geotiff to get a representation that can be used for rendering
  useEffect(() => {
    let active = false;

    if (!heightMapUrl) {
      return;
    }

    setGeotiffParseResults({ status: 'loading' });

    active = true;

    parseGeoTiff(heightMapUrl)
      .then((results) => {
        if (active) {
          setGeotiffParseResults({ ...results, status: 'complete' as const });
        }
      })
      .catch((error) => {
        setGeotiffParseResults({ status: 'error' as const, error });
      });

    return () => {
      active = false;
    };
  }, [heightMapUrl]);

  return geotiffParseResults;
};

const HeightMapImport: React.FunctionComponent<{
  floorplanImage: HTMLImageElement;
  floorplan: FloorplanType;
  displayUnit: LengthUnit;
  heightMap: HeightMap;
  onGeoTiffLoaded?: (tiePoint: GeoTiffTiepoint, scale: number) => void;
  // NOTE: this scale value is in addition to the intrinsic geotiff scale, and allows the heightmap
  // editor to be used to scale the floorplan image. When in doubt, this should default to zero
  heightMapAdditionalScale?: number | null;
  onChangeHeightMapAdditionalScale?: (additionalScale: number | null) => void;
  submitInProgress?: boolean;
  onChangeRegistration: (
    position: FloorplanCoordinates,
    rotation: number
  ) => void;
  onChangeBounds: (minMeters: number, maxMeters: number) => void;
  onRotateRight90: () => void;
  onChangeOpacity: (opacity: number) => void;
  onCancel?: () => void;
  onSubmit: (heightMap: HeightMap, additionalScale: number | null) => void;
}> = ({
  floorplanImage,
  floorplan,
  displayUnit,
  heightMap,
  onGeoTiffLoaded,
  heightMapAdditionalScale = null,
  onChangeHeightMapAdditionalScale,
  submitInProgress,
  onChangeRegistration,
  onChangeBounds,
  onRotateRight90,
  onChangeOpacity,
  onCancel,
  onSubmit,
}) => {
  const geotiffParseResults = useParseGeoTiff(heightMap.url);

  const [heightPickerExtent, setHeightPickerExtent] = useState<
    'min' | 'max' | null
  >(null);

  const floorplanRef = useRef<FixMe | null>(null);

  const isHeightMapAdditionalScaleEnabled = heightMapAdditionalScale !== null;

  if (!floorplanImage) {
    return null;
  }

  return (
    <Fragment>
      <DarkTheme>
        <AppBar
          title="Edit Height Map"
          actions={
            <HorizontalForm size="medium">
              {onCancel ? (
                <Button
                  size="medium"
                  data-cy="height-map-import-cancel"
                  type="cleared"
                  disabled={submitInProgress}
                  onClick={onCancel}
                >
                  Cancel
                </Button>
              ) : null}
              <Button
                size="medium"
                data-cy="height-map-import-submit"
                disabled={
                  submitInProgress || geotiffParseResults.status !== 'complete'
                }
                onClick={() => onSubmit(heightMap, heightMapAdditionalScale)}
              >
                {submitInProgress ? 'Loading...' : 'Save Height Map'}
              </Button>
            </HorizontalForm>
          }
        />
      </DarkTheme>

      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
          <Floorplan
            ref={floorplanRef}
            image={floorplanImage}
            floorplan={floorplan}
            width="100%"
            height="100%"
            lengthUnit={displayUnit}
          >
            <HeightMapRegistrationLayer
              url={heightMap.url}
              position={heightMap.position}
              additionalScale={
                heightMapAdditionalScale === null
                  ? undefined
                  : heightMapAdditionalScale
              }
              rotationInDegrees={heightMap.rotation}
              onGeoTiffLoaded={onGeoTiffLoaded}
              limits={heightMap.limits}
              opacity={heightMap.opacity}
              onDragMove={(position, additionalScale, rotation) => {
                onChangeRegistration(position, rotation);
                if (
                  isHeightMapAdditionalScaleEnabled &&
                  onChangeHeightMapAdditionalScale
                ) {
                  onChangeHeightMapAdditionalScale(additionalScale);
                }
              }}
            />
            {heightPickerExtent !== null ? (
              <HeightMapHeightRangeHeightPickerLayer
                heightMap={heightMap}
                onPickHeight={(heightMeters) => {
                  if (geotiffParseResults.status !== 'complete') {
                    return;
                  }

                  let minMeters = heightMap.limits.enabled
                    ? heightMap.limits.minMeters
                    : geotiffParseResults.smallestValue;
                  let maxMeters = heightMap.limits.enabled
                    ? heightMap.limits.maxMeters
                    : geotiffParseResults.largestValue;

                  switch (heightPickerExtent) {
                    case 'min':
                      minMeters = heightMeters;
                      break;
                    case 'max':
                      maxMeters = heightMeters;
                      break;
                  }

                  onChangeBounds(minMeters, maxMeters);

                  setHeightPickerExtent(null);
                }}
              />
            ) : null}
          </Floorplan>
        </div>

        <Panel position="top-left" width={300}>
          <PanelTitle icon={<Icons.CogGearSettings size={18} />}>
            Adjustments
          </PanelTitle>

          {geotiffParseResults.status === 'loading' ? (
            <PanelBody>
              <div style={{ height: 200 }}>
                <FillCenter>Loading geotiff...</FillCenter>
              </div>
            </PanelBody>
          ) : null}
          {geotiffParseResults.status === 'error' ? (
            <PanelBody>
              <div style={{ height: 200, color: dust.Red400 }}>
                <FillCenter>Error parsing geotiff!</FillCenter>
              </div>
            </PanelBody>
          ) : null}
          {geotiffParseResults.status === 'complete' ? (
            <PanelBody>
              <FormLabel
                label="Origin"
                input={
                  <FloorplanCoordinatesField
                    value={heightMap.position}
                    computeDefaultValue={() =>
                      FloorplanCoordinates.create(0, 0)
                    }
                    data-cy="height-map-position"
                    onChange={(position) =>
                      onChangeRegistration(position, heightMap.rotation)
                    }
                  />
                }
              />

              <FormLabel
                label="Rotation"
                input={
                  <RotationField
                    value={heightMap.rotation}
                    onChange={(rotation) =>
                      onChangeRegistration(heightMap.position, rotation)
                    }
                    onRotateRight90={onRotateRight90}
                    rotationSVGGlyph={
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="none"
                        strokeWidth="1"
                      >
                        <g
                          stroke={dust.Blue400}
                          strokeWidth="2"
                          transform="translate(4,5)"
                        >
                          <g transform="translate(4.648,6)">
                            <path d="M1.0042334 5.68434189e-14L1.0042334 20 21.3521024 20 21.3521024 5.34801195 17.8868814 5.34801195"></path>
                            <path d="M4.308 9.661L1.004 9.661"></path>
                            <path d="M10.9131311 5.34801195L10.9131311 9.54738553 7.67833682 9.54738553"></path>
                            <path d="M0 3.81149139e-14L10.9131311 0 10.9131311 5.34801195 14.1551468 5.36419725"></path>
                          </g>
                        </g>
                        <path
                          d="M15.6665 4.50022L19.9998 1.61133L24.3332 4.50022"
                          stroke={dust.Blue400}
                          strokeWidth="2"
                          fill="transparent"
                          transform="translate(0,2)"
                        />
                      </g>
                    }
                    data-cy="height-map-rotation"
                  />
                }
              />

              <FormLabel
                label="Opacity"
                input={
                  <OpacityField
                    value={heightMap.opacity}
                    onChange={(opacity: number) => onChangeOpacity(opacity)}
                    data-cy="height-map-opacity"
                  />
                }
              />

              <FormLabel
                label="Bounds"
                input={
                  <HeightMapExtents
                    smallestHeightMeters={geotiffParseResults.smallestValue}
                    largestHeightMeters={geotiffParseResults.largestValue}
                    minMeters={
                      heightMap.limits.enabled
                        ? heightMap.limits.minMeters
                        : geotiffParseResults.smallestValue
                    }
                    maxMeters={
                      heightMap.limits.enabled
                        ? heightMap.limits.maxMeters
                        : geotiffParseResults.largestValue
                    }
                    onChange={onChangeBounds}
                    activeExtent={heightPickerExtent}
                    onPickExtent={setHeightPickerExtent}
                  />
                }
              />

              {onChangeHeightMapAdditionalScale ? (
                <FormLabel
                  label="Scale"
                  input={
                    <div>
                      <Checkbox
                        checked={heightMapAdditionalScale !== null}
                        onChange={(e) =>
                          onChangeHeightMapAdditionalScale(
                            e.target.checked ? 1 : null
                          )
                        }
                        label="Adjust Floorplan Scale"
                      />
                      {heightMapAdditionalScale === null ? (
                        <PercentField disabled value={100} />
                      ) : (
                        <PercentField
                          value={heightMapAdditionalScale * 100}
                          onChange={(percent) =>
                            onChangeHeightMapAdditionalScale(percent / 100)
                          }
                          maxValue={Infinity}
                        />
                      )}
                    </div>
                  }
                />
              ) : null}
            </PanelBody>
          ) : null}
        </Panel>

        <FloorplanZoomControls
          onZoomToFitClick={() => {
            if (floorplanRef.current) {
              floorplanRef.current.zoomToFit();
            }
          }}
        />
      </div>
    </Fragment>
  );
};

export default HeightMapImport;
