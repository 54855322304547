// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dui-styles-module-commonTimeMenu-22LAOKNm{border:1px solid #C0C6CC;border-radius:4px;background-color:#fff;padding:8px;margin-top:8px;height:157px;overflow-x:hidden;overflow-y:auto;box-shadow:0px 2px 4px #E6E7EB}.dui-styles-module-commonTimeMenuItem-3PY0nzde{width:92px;padding:8px 0;text-align:center;cursor:pointer;outline:none;color:#3A434D}.dui-styles-module-commonTimeMenuItem-3PY0nzde:hover,.dui-styles-module-commonTimeMenuItem-3PY0nzde:focus{background-color:#EFF0F2}.dui-styles-module-commonTimeMenuItem-3PY0nzde:active,.dui-styles-module-commonTimeMenuItem-3PY0nzde:focus{text-decoration:underline}.dui-styles-module-timeFilterPicker-2xSrhJuI{display:flex;padding-bottom:24px;border-bottom:1px dashed #E0E3E6;font-variant-numeric:tabular-nums}.dui-styles-module-timeFilterPickerToText-1pRpUXz-{line-height:40px;padding:0px 16px}.dui-styles-module-timeFilterPickerDayOfWeekContainer-3AkNp8uL{height:40px;display:flex;align-items:center}.dui-styles-module-contextTimeRangeControlBar-3YP3k617.dui-styles-module-timeFilterPicker-2xSrhJuI{height:40px;align-items:center;padding-bottom:0;border-bottom:none}.dui-styles-module-contextTimeRangeControlBar-3YP3k617.dui-styles-module-timeFilterPickerDayOfWeekContainer-3AkNp8uL{height:25px}.dui-styles-module-contextTimeRangeControlBar-3YP3k617.dui-styles-module-commonTimeMenu-22LAOKNm{margin-top:6px;padding:4px;box-sizing:content-box}.dui-styles-module-contextTimeRangeControlBar-3YP3k617.dui-styles-module-commonTimeMenuItem-3PY0nzde{width:78px;padding:4px 0;font-size:14px}\n", ""]);
// Exports
exports.locals = {
	"commonTimeMenu": "dui-styles-module-commonTimeMenu-22LAOKNm",
	"commonTimeMenuItem": "dui-styles-module-commonTimeMenuItem-3PY0nzde",
	"timeFilterPicker": "dui-styles-module-timeFilterPicker-2xSrhJuI",
	"timeFilterPickerToText": "dui-styles-module-timeFilterPickerToText-1pRpUXz-",
	"timeFilterPickerDayOfWeekContainer": "dui-styles-module-timeFilterPickerDayOfWeekContainer-3AkNp8uL",
	"contextTimeRangeControlBar": "dui-styles-module-contextTimeRangeControlBar-3YP3k617"
};
module.exports = exports;
