import { v4 as uuidv4 } from 'uuid';

import {
  FloorplanV2ReferenceRuler,
  FloorplanV2ReferenceHeight,
  isFloorplanReferenceRulerId,
  isFloorplanReferenceHeightId,
  Unsaved,
} from 'lib/api';
import { FloorplanCoordinates } from 'lib/geometry';
import Floorplan from 'lib/floorplan';
import { Viewport } from 'lib/viewport';

const REFRENCE_DISTANCE_LABEL_CENTER_SNAP_PX = 32;

interface ReferenceBase {
  id: string;
  enabled: boolean;
}

export interface ReferenceRuler extends ReferenceBase {
  type: 'ruler';
  positionA: FloorplanCoordinates;
  positionB: FloorplanCoordinates;

  distanceLabelPosition: FloorplanCoordinates;

  currentIsDistanceLockedToCenter?: boolean;
}

export const ReferenceRuler = {
  duplicate(oldRuler: ReferenceRuler): ReferenceRuler {
    const id = uuidv4();
    return { ...oldRuler, id };
  },
  calculateCenterPoint(
    referenceRuler: Pick<ReferenceRuler, 'positionA' | 'positionB'>
  ): FloorplanCoordinates {
    return FloorplanCoordinates.create(
      (referenceRuler.positionA.x + referenceRuler.positionB.x) / 2,
      (referenceRuler.positionA.y + referenceRuler.positionB.y) / 2
    );
  },

  // Returns a boolean indicating if the label showing the length of a reference line should snap to
  // the center of the reference line when moving it around the plan.
  isDistanceLockedToCenter(
    floorplan: Floorplan,
    viewport: Viewport,
    distanceLabelPosition: FloorplanCoordinates,
    centerPoint: FloorplanCoordinates
  ): boolean {
    const centerPointViewport = FloorplanCoordinates.toViewportCoordinates(
      centerPoint,
      floorplan,
      viewport
    );
    const distanceLabelPositionViewport =
      FloorplanCoordinates.toViewportCoordinates(
        distanceLabelPosition,
        floorplan,
        viewport
      );

    const distanceFromCenter = Math.hypot(
      Math.abs(centerPointViewport.y - distanceLabelPositionViewport.y),
      Math.abs(centerPointViewport.x - distanceLabelPositionViewport.x)
    );

    return distanceFromCenter < REFRENCE_DISTANCE_LABEL_CENTER_SNAP_PX;
  },

  toFloorplanReferenceRuler(
    reference: ReferenceRuler
  ): FloorplanV2ReferenceRuler | Unsaved<FloorplanV2ReferenceRuler> {
    return {
      id: isFloorplanReferenceRulerId(reference.id) ? reference.id : undefined,
      position_a_x_meters: reference.positionA.x,
      position_a_y_meters: reference.positionA.y,
      position_b_x_meters: reference.positionB.x,
      position_b_y_meters: reference.positionB.y,
      position_label_x_meters: reference.distanceLabelPosition.x,
      position_label_y_meters: reference.distanceLabelPosition.y,
      enabled: reference.enabled,
    };
  },

  createFromFloorplanReferenceRuler(
    apiReference: FloorplanV2ReferenceRuler
  ): ReferenceRuler {
    return {
      id: apiReference.id,
      type: 'ruler',
      positionA: FloorplanCoordinates.create(
        apiReference.position_a_x_meters,
        apiReference.position_a_y_meters
      ),
      positionB: FloorplanCoordinates.create(
        apiReference.position_b_x_meters,
        apiReference.position_b_y_meters
      ),
      distanceLabelPosition: FloorplanCoordinates.create(
        apiReference.position_label_x_meters,
        apiReference.position_label_y_meters
      ),
      enabled: apiReference.enabled,
    };
  },
};

export interface ReferenceHeight extends ReferenceBase {
  type: 'height';
  position: FloorplanCoordinates;
  heightMeters:
    | { step: 'empty' }
    | { step: 'loading' }
    | { step: 'complete'; value: number | null };
}

export const ReferenceHeight = {
  toFloorplanReferenceHeight(
    reference: ReferenceHeight
  ): FloorplanV2ReferenceHeight | Unsaved<FloorplanV2ReferenceHeight> {
    return {
      id: isFloorplanReferenceHeightId(reference.id) ? reference.id : undefined,
      position_x_meters: reference.position.x,
      position_y_meters: reference.position.y,
      enabled: reference.enabled,
    };
  },

  createFromFloorplanReferenceHeight(
    apiReference: FloorplanV2ReferenceHeight
  ): ReferenceHeight {
    return {
      id: apiReference.id,
      type: 'height',
      heightMeters: { step: 'empty' },
      position: FloorplanCoordinates.create(
        apiReference.position_x_meters,
        apiReference.position_y_meters
      ),
      enabled: apiReference.enabled,
    };
  },
};

type Reference = ReferenceRuler | ReferenceHeight;

const Reference = {
  createHeight(
    position: FloorplanCoordinates,
    heightMeters?: number | null
  ): ReferenceHeight {
    const id = uuidv4();
    return {
      id,
      enabled: true,
      type: 'height',
      position,
      heightMeters:
        typeof heightMeters !== 'undefined'
          ? { step: 'complete' as const, value: heightMeters }
          : { step: 'empty' as const },
    };
  },
  createRuler(
    positionA: FloorplanCoordinates,
    positionB: FloorplanCoordinates,
    distanceLabelPosition?: FloorplanCoordinates
  ): Reference {
    const id = uuidv4();
    return {
      id,
      type: 'ruler',
      positionA,
      positionB,
      enabled: true,

      // By default, position the dimension in between the endpoints
      distanceLabelPosition:
        distanceLabelPosition ||
        ReferenceRuler.calculateCenterPoint({ positionA, positionB }),
    };
  },
  toggle(reference: Reference): Reference {
    return {
      ...reference,
      enabled: !reference.enabled,
    };
  },
};

export default Reference;
