import * as React from 'react';

const UtilityRoom = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      d="M10.875 15H11.25L15 11.6667V11.3333H12.375L13.125 9H12.75L9 12.3241V12.6667H11.625L10.875 15Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 2L6 2L6 20H3V22L21 22V20H18L18 2ZM16 20L16 4L8 4L8 20L16 20Z"
      fill={color}
    />
  </svg>
);

export default UtilityRoom;
