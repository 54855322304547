import { useRef, useMemo, useState, forwardRef } from 'react';
import * as React from 'react';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import styles from './styles.module.scss';

import { useTheme } from 'components/theme';

const SELECT_FIELD_SIZE_CLASSES = {
  large: styles.sizeLarge,
  medium: styles.sizeMedium,
  small: styles.sizeSmall,
};

const SELECT_FIELD_THEME_CLASSES = {
  dark: styles.dark,
  light: styles.light,
};

type NativeInputProps = React.HTMLProps<HTMLSelectElement>;

type SelectFieldChoice = {
  id: any;
  label: React.ReactNode;
  disabled?: boolean;
};

type SelectFieldPropsBase = {
  size?: 'large' | 'medium' | 'small';
  disabled?: boolean;
  width?: React.ReactText;
  error?: boolean;
  helperSelect?: React.ReactNode;

  value: SelectFieldChoice['id'];
  choices: Array<SelectFieldChoice>;
  onChange: (choice: SelectFieldChoice) => void;

  leadingIcon?: React.ReactNode;
  leadingPrefix?: React.ReactNode;
  trailingIcon?: React.ReactNode;
  trailingSuffix?: React.ReactNode;

  'data-cy'?: string;
};

type SelectFieldPropsLeading =
  | { leadingIcon?: React.ReactNode }
  | { leadingPrefix?: React.ReactNode };

type SelectFieldPropsTrailing =
  | { trailingIcon?: React.ReactNode }
  | { trailingSuffix?: React.ReactNode };

type SelectFieldPropsCombined = SelectFieldPropsBase &
  SelectFieldPropsLeading &
  SelectFieldPropsTrailing;

// Allow passing native input props, but Omit those that we define in ButtonPropsBase so they don't conflict
type SelectFieldProps = Omit<NativeInputProps, keyof SelectFieldPropsCombined> &
  SelectFieldPropsCombined;

const SelectFieldRaw: React.FunctionComponent<
  SelectFieldProps & { forwardedRef: any }
> = (props) => {
  const {
    forwardedRef,

    error = false,
    disabled = false,
    leadingIcon,
    leadingPrefix,
    trailingIcon,
    trailingSuffix,
    width,
    size = 'large',
    helperSelect = null,

    choices,
    onChange,

    placeholder,
    onFocus,
    onBlur,
    ...restProps
  } = props;
  const dataCy = props['data-cy'];

  const [focus, setFocus] = useState(false);
  const defaultInputRef = useRef<HTMLInputElement | null>(null);
  const inputRef = forwardedRef || defaultInputRef;

  // Give an id to the raw input element so it can be targeted for accessibility reasons
  const inputId = useMemo(() => uuidv4(), []);

  const theme = useTheme();

  return (
    <div
      className={classnames(
        styles.selectfield,
        SELECT_FIELD_SIZE_CLASSES[size],
        SELECT_FIELD_THEME_CLASSES[theme],
        {
          [styles.focused]: focus,
          [styles.error]: error,
          [styles.disabled]: disabled,
        }
      )}
      style={{ width }}
      // Clicking on anything in the wrapping div that isn't the input should focus the input
      onMouseDown={(e) => {
        if (e.target === inputRef.current) {
          return;
        }

        e.preventDefault();
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }}
    >
      <div className={styles.left}>
        {leadingIcon ? (
          <div className={classnames(styles.leading, styles.icon)}>
            {leadingIcon}
          </div>
        ) : null}
        {leadingPrefix ? (
          <div className={classnames(styles.leading, styles.prefix)}>
            {leadingPrefix}
          </div>
        ) : null}
        <select
          {...restProps}
          ref={inputRef}
          id={inputId}
          disabled={disabled}
          placeholder={placeholder}
          onFocus={(...args) => {
            setFocus(true);
            if (onFocus) {
              onFocus(...args);
            }
          }}
          onBlur={(...args) => {
            setFocus(false);
            if (onBlur) {
              onBlur(...args);
            }
          }}
          onChange={(e) => {
            const choice = choices.find(
              (choice) => choice.id === e.target.value
            );
            if (choice) {
              setFocus(false);
              onChange(choice);
            }
          }}
          data-cy={dataCy}
        >
          {choices.map((choice) => (
            <option
              key={choice.id}
              value={choice.id}
              disabled={choice.disabled}
            >
              {choice.label}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.right}>
        {trailingIcon ? (
          <div className={classnames(styles.trailing, styles.icon)}>
            {trailingIcon}
          </div>
        ) : null}
        {trailingSuffix ? (
          <div className={classnames(styles.trailing, styles.suffix)}>
            {trailingSuffix}
          </div>
        ) : null}
      </div>
      {helperSelect ? (
        <div className={styles.helperSelect}>{helperSelect}</div>
      ) : null}
    </div>
  );
};

const SelectField = forwardRef((props: SelectFieldProps, ref) => (
  <SelectFieldRaw {...props} forwardedRef={ref} />
));

export default SelectField;
