import * as React from 'react';

const EnclosedWorkspace = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      d="M12 5C13.1046 5 14 5.89543 14 7C14 8.10457 13.1046 9 12 9C10.8954 9 10 8.10457 10 7C10 5.89543 10.8954 5 12 5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2V22H22V2H2ZM20 4H4V13H9.1905L9.0178 11.5137C8.90037 10.8055 9.37634 10.1357 10.0809 10.0177C10.1512 10.0059 10.2223 10 10.2935 10H13.7067C14.421 10 15 10.582 15 11.3C15 11.3716 14.9941 11.4431 14.9824 11.5137L14.8285 13H20V4ZM20 15H4V20H20V15Z"
      fill={color}
    />
  </svg>
);

export default EnclosedWorkspace;
