import React from "react";

const DoNotEnter = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <rect width={24} height={24} />
      <path
        fill={color}
        fillRule="nonzero"
        d="M12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 Z M18.3207287,7.09547725 L7.09547725,18.3207287 C8.44997896,19.3732643 10.1517985,20 12,20 C16.418278,20 20,16.418278 20,12 C20,10.1517985 19.3732643,8.44997896 18.3207287,7.09547725 Z M12,4 C7.581722,4 4,7.581722 4,12 C4,13.8482015 4.62673571,15.550021 5.67927125,16.9045228 L16.9045228,5.67927125 C15.550021,4.62673571 13.8482015,4 12,4 Z"
      />
    </g>
  </svg>
);

export default DoNotEnter;
