import * as React from 'react';

const Reception = ({ width, height, color }) => (
  <svg width={width || 24} height={height || 24} viewBox="0 0 24 24">
    <path
      d="M7 6C8.10457 6 9 5.10457 9 4C9 2.89543 8.10457 2 7 2C5.89543 2 5 2.89543 5 4C5 5.10457 5.89543 6 7 6Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 8C4 7.44772 4.44772 7 5 7H9C9.55228 7 10 7.44772 10 8V11H15V9H12L12 4L20 4V9H17V11L22 11L22 22L2 22L2 11H4L4 8ZM20 20L20 13L4 13L4 20L20 20Z"
      fill={color}
    />
  </svg>
);

export default Reception;
